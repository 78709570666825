import {
  getActivitiesList,
  getActivitiesStatusList,
  getPermitActivities,
  getAllActivities,
  getCustomActivities,
} from '../api/activities';
import { getProjects } from '../api/projects';
import {
  DATETIME_FORMAT,
  DEFAULT_PARAMS_ALL,
  DEFAULT_PARAMS_CALLS,
  DEFAULT_PARAMS_CONDITIONS,
  DEFAULT_PARAMS_FEES,
  DEFAULT_PARAMS_INSPECTIONS,
  DEFAULT_PARAMS_PERMITTING,
  DEFAULT_PARAMS_PROPOSAL,
  DEFAULT_PARAMS_REVIEWS,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import { getUsers } from '../api/users';
import { USER_TYPES_VALUES } from '../constants/forms';
import { getVocabulariesDataList } from '../api/vocabulary';
import { formatDateTime } from './formatDate';
import store from '../redux/store';
import { setAllData, setCallsData, setConditionsData, setFeesData, setInspectionsData, setIsUpdateTable, setPermittingData, setProposalData, setReviewsData, setUserEmployeeData, setUserInspectorData, setUseTaskSheet, setVocabulariesData } from '../redux/slices/allActivitiesSlice';
import _ from 'lodash';
import { setAllActivitiesCount, setCallsCount, setConditionCount, setFeeCount, setInspectionCount, setPermittingCount, setProposalCount, setReviewsCount } from '../redux/slices/employeeActivitySlice';

export const modifiedGetAllActivities = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.allData;
  const employeesResponse = store.getState().allActivities.userEmployeeData;
  const typesResponse = store.getState().allActivities.vocabulariesData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_ALL) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getAllActivities({
        // ...GET_ALL_QUERY_PARAMS,
        ...params,
        is_deleted: params?.is_deleted,
        limit: params?.limit,
        offset: params?.offset,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        sort_by: params?.ordering,
        ordering: null,
        isEmergency: params?.isEmergency
      }),
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: USER_TYPES_VALUES.EMPLOYEE,
      }),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 3,
        ordering: 'title',
      }),
    ]).then(
      ([
        activitiesResponse,
        employeesResponse,
        typesResponse,
        // allUsersResponse,
        // inspectionTypesResponse,
        // permitsResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_ALL) === JSON.stringify(params))) {
          store.dispatch(setAllData(activitiesResponse));
        }
        store.dispatch(setUserEmployeeData(employeesResponse));
        store.dispatch(setVocabulariesData(typesResponse));
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setAllActivitiesCount(activitiesResponse.data.highestCount));
        return transformGetAllactivities(params, activitiesResponse, employeesResponse, typesResponse);
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetAllactivities(params, activitiesResponse, employeesResponse, typesResponse)); });
  }
}

const transformGetAllactivities = (params, activitiesResponse, employeesResponse, typesResponse) => {
  // EA-192
  // mritunjoy
  if (params?.isEmergency === 'True') {
    const emergencyActivitiesResponse = activitiesResponse.data.results.filter(
      item => item?.isEmergency === true,
    );
    return {
      ...activitiesResponse,
      data: {
        ...activitiesResponse.data,
        results: emergencyActivitiesResponse.map(
          item => {
            // const assigned = employeesResponse.data.results?.find(
            //   user =>
            //     user?.email === item?.assignedToEmail,
            // );
            // const permit = permitsResponse.data.results?.find(
            //   el => el?.name === item?.permitName,
            // );

            // const inspectionType = inspectionTypesResponse.data.results?.find(
            //   el => el?.id === item?.inspectionType,
            // )?.name;

            // const contact = allUsersResponse.data.results?.find(
            //   el => el?.id === item?.contact,
            // );
            const type = typesResponse.data.results
              ?.filter(el => el.isActive)
              ?.find(
                el => el?.title === item?.typeName,
              );
            return {
              ...item,
              projectName: item?.projectName,
              contactId: item?.contact || item?.contactId,
              jurisdiction: item?.jurisdictionName,
              followUp:
                // eslint-disable-next-line no-nested-ternary
                item?.type === 'inspection'
                  // item.followUp !== '01/01/2050'
                  ? item.followUp
                  : item?.type === 'inspection'
                    // item.followUp === '01/01/2050'
                    ? ''
                    : formatDateTime(
                      item.followUp,
                      DATETIME_FORMAT,
                    ),
              assignedTo: item?.assignedToFirstName + " " + item?.assignedToLastName,
              title: item?.titleName,
              status: item?.statusName,
              // activityType: type
              //   ? type?.title
              //   : 'Inspection',
              activityType:
                // eslint-disable-next-line no-nested-ternary
                item?.type === 'inspection'
                  ? 'Inspection'
                  : type?.title
                    ? type?.title
                    : '',
              permit: item?.permitName,
              permitId: item?.permitId,
              period:
                item?.period === 0
                  ? 'AM'
                  : item?.period === 1
                    ? 'PM'
                    : '',
              inspectionType: item?.type === 'inspection' ? item?.typeName : "",
              permitNumber: item?.permitNumber,
              lastComment: item?.lastComment?.replace(
                /(<([^>]+)>)/gi,
                ' ',
              ),
              // contact:
              //   // eslint-disable-next-line no-nested-ternary
              //   contact?.companyName && item?.contact
              //     ? `${contact?.companyName}`
              //     : contact?.firstName && item?.contact
              //     ? `${contact?.firstName} ${contact?.lastName}`
              //     : '',
            };
          },
        ),
      },
    };
  } else if (params?.isEmergency === 'False') {
    const nonEmergencyActivitiesResponse = activitiesResponse.data.results.filter(
      item => item.isEmergency === false,
    );
    return {
      ...activitiesResponse,
      data: {
        ...activitiesResponse.data,
        results: nonEmergencyActivitiesResponse.map(
          item => {
            // const assigned = employeesResponse.data.results?.find(
            //   user =>
            //     user?.email === item?.assignedToEmail,
            // );
            // const inspectionType = inspectionTypesResponse.data.results?.find(
            //   el => el?.id === item?.inspectionType,
            // )?.name;
            // const permit = permitsResponse.data.results?.find(
            //   el => el?.name === item?.permitName,
            // );
            // const contact = allUsersResponse.data.results?.find(
            //   el => el?.id === item?.contact,
            // );
            const type = typesResponse.data.results
              ?.filter(el => el.isActive)
              ?.find(
                el => el?.title === item?.typeName,
              );
            return {
              ...item,
              projectName: item?.projectName,
              contactId: item?.contact || item?.contactId,
              jurisdiction: item?.jurisdictionName,
              followUp:
                // eslint-disable-next-line no-nested-ternary
                item?.type === 'inspection'
                  // item.followUp !== '01/01/2050'
                  ? item.followUp
                  : item?.type === 'inspection'
                    // item.followUp === '01/01/2050'
                    ? ''
                    : formatDateTime(
                      item.followUp,
                      DATETIME_FORMAT,
                    ),
              assignedTo: item?.assignedToFirstName + " " + item?.assignedToLastName,
              title: item?.titleName,
              status: item?.statusName,
              // activityType: type
              //   ? type?.title
              //   : 'Inspection',
              activityType:
                // eslint-disable-next-line no-nested-ternary
                item?.type === 'inspection'
                  ? 'Inspection'
                  : type?.title
                    ? type?.title
                    : '',
              permit: item?.permitName,
              permitId: item?.permitId,
              period:
                item?.period === 0
                  ? 'AM'
                  : item?.period === 1
                    ? 'PM'
                    : '',
              inspectionType: item?.type === 'inspection' ? item?.typeName : "",
              permitNumber: item?.permitNumber,
              lastComment: item?.lastComment?.replace(
                /(<([^>]+)>)/gi,
                ' ',
              ),
              // contact:
              //   // eslint-disable-next-line no-nested-ternary
              //   contact?.companyName && item?.contact
              //     ? `${contact?.companyName}`
              //     : contact?.firstName && item?.contact
              //     ? `${contact?.firstName} ${contact?.lastName}`
              //     : '',
            };
          },
        ),
      },
    };
  } else {
    return {
      ...activitiesResponse,
      data: {
        ...activitiesResponse.data,
        results: activitiesResponse.data.results.map(
          item => {
            // const assigned = employeesResponse.data.results?.find(
            //   user =>
            //     user?.email === item?.assignedToEmail,
            // );
            // const permit = permitsResponse.data.results?.find(
            //   el => el?.name === item?.permitName,
            // );
            // const inspectionType = inspectionTypesResponse.data.results?.find(
            //   el => el?.id === item?.inspectionType,
            // )?.name;

            // const contact = allUsersResponse.data.results?.find(
            //   el => el?.id === item?.contact,
            // );

            const type = typesResponse.data.results
              ?.filter(el => el.isActive)
              ?.find(
                el => el?.title === item?.typeName,
              );

            return {
              ...item,
              projectName: item?.projectName,
              contactId: item?.contact || item?.contactId,
              jurisdiction: item?.jurisdictionName,
              followUp:
                // eslint-disable-next-line no-nested-ternary
                item?.type === 'inspection'
                  // item.followUp !== '01/01/2050'
                  ? item.followUp
                  : item?.type === 'inspection'
                    // item.followUp === '01/01/2050'
                    ? ''
                    : formatDateTime(
                      item.followUp,
                      DATETIME_FORMAT,
                    ),
              assignedTo: item?.assignedToFirstName + " " + item?.assignedToLastName,
              // assigned?.firstName
              //   ? (assigned?.lastName ? `${assigned?.firstName} ${assigned?.lastName}` : `${assigned?.firstName}`)
              //   : '',
              title: item?.titleName,
              status: item?.statusName,
              // activityType: type
              //   ? type?.title
              //   : 'Inspection',
              activityType:
                // eslint-disable-next-line no-nested-ternary
                item?.type === 'inspection'
                  ? 'Inspection'
                  : type?.title
                    ? type?.title
                    : '',
              permit: item?.permitName,
              permitId: item?.permitId,
              period:
                item?.period === 0
                  ? 'AM'
                  : item?.period === 1
                    ? 'PM'
                    : '',
              // permitId: permit?.id,
              inspectionType: item?.type === 'inspection' ? item?.typeName : "",
              permitNumber: item?.permitNumber,
              lastComment: item?.lastComment?.replace(
                /(<([^>]+)>)/gi,
                ' ',
              ),
              // contact:
              //   // eslint-disable-next-line no-nested-ternary
              //   contact?.companyName && item?.contact
              //     ? `${contact?.companyName}`
              //     : contact?.firstName && item?.contact
              //     ? `${contact?.firstName} ${contact?.lastName}`
              //     : '',
            };
          },
        ),
      },
    };
  }
  // #########
}

export const modifiedGetInspections = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const inspectionsResponse = store.getState().allActivities.inspectionsData;
  const inspectorsResponse = store.getState().allActivities.userInspectorData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_INSPECTIONS) === JSON.stringify(params)) || Object.keys(inspectionsResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitInspections({
      //   ...params,
      //   // ordering: '-is_emergency,follow_up',
      // }),
      // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      // getPermits({ ...GET_ALL_QUERY_PARAMS }),
      // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
      // getInspectionTypes({ ...GET_ALL_QUERY_PARAMS }),
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: USER_TYPES_VALUES.INSPECTOR,
      }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
    ]).then(
      ([
        inspectionsResponse,
        // projectsResponse,
        // permitsResponse,
        // statusesResponse,
        // inspectionTypesResponse,
        inspectorsResponse,
        // employeesResponse,
        // allUsersResponse,
        // typesResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_INSPECTIONS) === JSON.stringify(params))) {
          store.dispatch(setInspectionsData(inspectionsResponse));
        }
        store.dispatch(setUserInspectorData(inspectorsResponse));
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setInspectionCount((inspectionsResponse.data.highestCount)));
        return transformGetInspections(inspectionsResponse, inspectorsResponse)
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetInspections(inspectionsResponse, inspectorsResponse)); });
  }
}

const transformGetInspections = (inspectionsResponse, inspectorsResponse) => {
  return {
    ...inspectionsResponse,
    data: {
      ...inspectionsResponse.data,
      results: inspectionsResponse.data.results.map(
        item => {
          // const permit = permitsResponse.data.results?.find(
          //   el => el?.id === item?.permit,
          // );
          // const project = projectsResponse.data.results?.find(
          //   el => el?.id === permit?.project,
          // );
          // const inspectionType = inspectionTypesResponse.data.results?.find(
          //   el => el?.id === item?.inspectionType,
          // )?.name;

          const inspector = inspectorsResponse.data.results?.find(
            el => el?.id === item?.inspector,
          );
          // const assignee = employeesResponse.data.results?.find(
          //   el => el?.id === item?.assignedTo,
          // );
          // const contact = allUsersResponse.data.results?.find(
          //   el => el?.id === item?.contact,
          // );

          return {
            ...item,
            projectName: item?.projectName,
            permit: item?.permitName,
            permitId: item?.permitId,
            projectAddress: item?.projectAddress,
            jurisdiction: item?.jurisdictionName,
            inspectionNumber: item?.id,
            permitNumber: item?.permitNumber,
            status: item?.statusName,
            inspectionType: item?.typeName,
            inspectionDate: item.inspectionDate
              ? item.inspectionDate
              : '',
            period:
              // eslint-disable-next-line no-nested-ternary
              item?.period == 0
                ? 'AM'
                : item?.period == 1
                  ? 'PM'
                  : '',
            followUp:
              item.followUp
                // item.followUp !== '01/01/2050'
                ? item.followUp
                : '',
            inspector: inspector?.firstName
              ? (inspector?.lastName ? `${inspector?.firstName} ${inspector?.lastName}` : `${inspector?.firstName}`)
              : '',
            assignedTo: item?.assignedToFirstName
              ? (item?.assignedToLastName ? `${item?.assignedToFirstName} ${item?.assignedToLastName}` : `${item?.assignedToFirstName}`)
              : '',
            contact: item?.contactFirstName
              ? (item?.contactLastName ? `${item?.contactFirstName} ${item?.contactLastName}` : `${item?.contactFirstName}`)
              : '',
            activityType: item?.activityTitleName,
          };
        },
      ),
    },
  };
}

export const modifiedGetCalls = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.callsData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_CALLS) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitActivities({
      //   ...params,
      //   ordering: '-is_emergency,follow_up',
      // }),
      // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      // getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
      // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
      // getPermits({ ...GET_ALL_QUERY_PARAMS }),
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
    ]).then(
      ([
        activitiesResponse,
        // projectsResponse,
        // employeesResponse,
        // allUsersResponse,
        // titlesResponse,
        // statusesResponse,
        // permitsResponse,
        // typesResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_CALLS) === JSON.stringify(params))) {
          store.dispatch(setCallsData(activitiesResponse));
        }
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setCallsCount((activitiesResponse.data.highestCount)));
        return transformGetCalls(activitiesResponse)
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetCalls(activitiesResponse)); });
  }
}

const transformGetCalls = (activitiesResponse) => {
  return {
    ...activitiesResponse,
    data: {
      ...activitiesResponse.data,
      results: activitiesResponse.data.results.map(
        item => {
          // const assigned = employeesResponse.data.results?.find(
          //   user => user?.id === item?.assignedTo,
          // );

          const assigned = item?.assignedToFirstName
            ? (item?.assignedToLastName ? `${item?.assignedToFirstName} ${item?.assignedToLastName}` : `${item?.assignedToFirstName}`)
            : '';
          // const permit = permitsResponse.data.results?.find(
          //   el => el?.id === item?.permit,
          // );

          // const project = projectsResponse.data.results?.find(
          //   el => el?.id === permit?.project,
          // );
          // const contact = allUsersResponse.data.results?.find(
          //   el => el?.id === item?.contact,
          // );
          const contact = item?.company_name ? item?.company_name : (item?.contactFirstName ? (item?.contactLastName ? `${item?.contactFirstName} ${item?.contactLastName}` : `${item?.contactFirstName}`) : '')


          return {
            ...item,
            projectName: item?.projectName,
            permit: item?.permitName,
            permitId: item?.permitId,
            jurisdiction: item?.projectJurisdiction,
            permitNumber: item?.permitNumber,
            title: item?.activityTitleName,
            status: item?.statusName,
            contact: contact,
            followUp: formatDateTime(
              item.followUp,
              DATETIME_FORMAT,
            ),
            assignedTo: assigned,
            lastComment: item?.lastComment?.replace(
              /(<([^>]+)>)/gi,
              ' ',
            ),
            activityType: item?.activityTypeName,
          };
        },
      ),
    },
  };
}

export const modifiedGetFees = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.feesData;
  const selectedFranchise = store.getState().userAuth.info.selectedFranchise;
  const useTaskSheet = store.getState().allActivities.useTaskSheet;
  const task_sheet = useTaskSheet ? true: undefined;
  store.dispatch(setUseTaskSheet(false));
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_FEES) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        task_sheet,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        franchise_id: selectedFranchise,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitActivities({
      //   ...params,
      //   ordering: '-is_emergency,follow_up',
      // }),
      // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      // getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
      // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
      // getPermits({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
    ]).then(
      ([
        activitiesResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_FEES) === JSON.stringify(params))) {
          store.dispatch(setFeesData(activitiesResponse));
          
        }
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setFeeCount(activitiesResponse.data.highestCount))
        
        return transformGetFees(activitiesResponse);
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetFees(activitiesResponse)); });
  }
}

const transformGetFees = (activitiesResponse) => {
  return {
    ...activitiesResponse,
    data: {
      ...activitiesResponse.data,
      results: activitiesResponse.data.results.map(
        item => {
          const assigned = item?.assignedToFirstName
            ? (item?.assignedToLastName ? `${item?.assignedToFirstName} ${item?.assignedToLastName}` : `${item?.assignedToFirstName}`)
            : '';

          const contact = item?.company_name ? item?.company_name : (item?.contactFirstName ? (item?.contactLastName ? `${item?.contactFirstName} ${item?.contactLastName}` : `${item?.contactFirstName}`) : '')


          return {
            ...item,
            projectName: item?.projectName,
            permit: item?.permitName,
            permitId: item?.permitId,
            jurisdiction: item?.projectJurisdiction,
            permitNumber: item?.permitNumber,
            title: item?.activityTitleName,
            status: item?.statusName,
            contact: contact,
            followUp: formatDateTime(
              item.followUp,
              DATETIME_FORMAT,
            ),
            assignedTo: assigned,
            lastComment: item?.lastComment?.replace(
              /(<([^>]+)>)/gi,
              ' ',
            ),
            activityType: item?.activityTypeName,
          };
        },
      ),
    },
  };
}

export const modifiedGetReviews = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.reviewsData;
  const inspectorsResponse = store.getState().allActivities.userInspectorData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_REVIEWS) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitActivities({
      //   ...params,
      //   ordering: '-is_emergency,follow_up',
      // }),

      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: USER_TYPES_VALUES.INSPECTOR,
      }),

    ]).then(
      ([
        activitiesResponse,
        inspectorsResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_REVIEWS) === JSON.stringify(params))) {
          store.dispatch(setReviewsData(activitiesResponse));
        }
        store.dispatch(setUserInspectorData(inspectorsResponse));
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setReviewsCount(activitiesResponse.data.highestCount));
        return transformGetReviews(activitiesResponse, inspectorsResponse);
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetReviews(activitiesResponse, inspectorsResponse)); });
  }
}

const transformGetReviews = (activitiesResponse, inspectorsResponse) => {
  return {
    ...activitiesResponse,
    data: {
      ...activitiesResponse.data,
      results: activitiesResponse.data.results.map(
        item => {

          const assigned = item?.assignedToFirstName
            ? (item?.assignedToLastName ? `${item?.assignedToFirstName} ${item?.assignedToLastName}` : `${item?.assignedToFirstName}`)
            : '';

          const inspector = inspectorsResponse.data.results?.find(
            el => el?.id === item?.inspector,
          );
          const contact = item?.company_name ? item?.company_name : (item?.contactFirstName ? (item?.contactLastName ? `${item?.contactFirstName} ${item?.contactLastName}` : `${item?.contactFirstName}`) : '')

          return {
            ...item,
            projectName: item?.projectName,
            permit: item?.permitName,
            permitId: item?.permitId,
            jurisdiction: item?.projectJurisdiction,
            permitNumber: item?.permitNumber,
            reviewType: item?.reviewType,
            title: item?.activityTitleName,
            status: item?.statusName,
            reviewer:
              item?.inspector && inspector
                ? (inspector?.firstName ? (inspector?.lastName ? `${inspector?.firstName} ${inspector?.lastName}` : `${inspector.firstName}`) : '')
                : '',
            followUp: formatDateTime(
              item.followUp,
              DATETIME_FORMAT,
            ),
            assignedTo: assigned,
            lastComment: item?.lastComment?.replace(
              /(<([^>]+)>)/gi,
              ' ',
            ),
            contact: contact,
            activityType: item?.activityTypeName,
          };
        },
      ),
    },
  };
}

export const modifiedGetConditions = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.conditionsData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_CONDITIONS) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitActivities({
      //   ...params,
      //   ordering: '-is_emergency,follow_up',
      // }),
      // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      // getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
      // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
      // getPermits({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
    ]).then(
      ([
        activitiesResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_CONDITIONS) === JSON.stringify(params))) {
          store.dispatch(setConditionsData(activitiesResponse));
        }
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setConditionCount((activitiesResponse.data.highestCount)));
        return transformGetFees(activitiesResponse);
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetFees(activitiesResponse)); });
  }
}

export const modifiedGetProposal = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.proposalData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_PROPOSAL) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitActivities({
      //   ...params,
      //   ordering: '-is_emergency,follow_up',
      // }),
      // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      // getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
      // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
      // getPermits({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
    ]).then(
      ([
        activitiesResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_PROPOSAL) === JSON.stringify(params))) {
          store.dispatch(setProposalData(activitiesResponse));
        }
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setProposalCount(activitiesResponse.data.highestCount));
        return transformGetFees(activitiesResponse);
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetFees(activitiesResponse)); });
  }
}

export const modifiedGetPermitting = (params) => {
  const isUpdateTable = store.getState().allActivities.isUpdateTable;
  const activitiesResponse = store.getState().allActivities.permittingData;
  if (isUpdateTable || !(JSON.stringify(DEFAULT_PARAMS_PERMITTING) === JSON.stringify(params)) || Object.keys(activitiesResponse).length === 0) {
    return Promise.all([
      getCustomActivities({
        ...params,
        sort_by: params?.ordering,
        ordering: null,
        search: params?.search || params?.searchColumn,
        search_column: null,
        follow_up: params?.follow_up,
        // project_name: params?.search,
        // permit_name: params?.search,
        // jurisdiction_name: params?.search,
        // permit_number: params?.search,
        // title_name: params?.search,
        // status_name: params?.search,
        // assigned_to_email: params?.search,
        // information: params?.search,
        // phone_number: params?.search,
        // cell_phone: params?.search,
        // inspection_type_name: params?.search,
        // event_type: 'Activity',
        // activity_type_name: 'Calls'
        // ordering: '-is_emergency,follow_up',
      }),
      // getPermitActivities({
      //   ...params,
      //   ordering: '-is_emergency,follow_up',
      // }),
      // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      // getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
      // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
      // getPermits({ ...GET_ALL_QUERY_PARAMS }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      // }),
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
    ]).then(
      ([
        activitiesResponse,
      ]) => {
        if (isUpdateTable || (JSON.stringify(DEFAULT_PARAMS_PERMITTING) === JSON.stringify(params))) {
          store.dispatch(setPermittingData(activitiesResponse));
        }
        store.dispatch(setIsUpdateTable(false));
        store.dispatch(setPermittingCount(activitiesResponse.data.highestCount))
        return transformGetFees(activitiesResponse);
      },
    );
  } else {
    return new Promise((resolve) => { resolve(transformGetFees(activitiesResponse)); });
  }
}

