import React from 'react';
import styled from 'styled-components';

const StaticField = ({label,value}) => {
    return (
        <Wrapper>
            <Label>{label}</Label>
            <div>{value}</div>
        </Wrapper>
    );
};

export default StaticField;
const Wrapper = styled.div`
  display: flex;
  gap:10px;
  font-size:20px;
  margin-bottom: 6px;
  width: 50%;
`;

const Label = styled.div`
  font-weight: bold;
`;