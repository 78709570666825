import React, { useEffect } from 'react';
import { InputBaseContainer, InputSelectWithLabel } from '../../../../../molecules';
import { Controller } from 'react-hook-form';
import { labels } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

const ActivityTitle = ({ control, errors, setValue }) => {
  const { titlesData, selectedInspection, disabledGlobalSearch } = useSelector((s: RootState) => s.inspections);
  useEffect(() => {
      const  activityTitle = titlesData.find(title => title.id === selectedInspection.title);
      setValue('activityTitle', activityTitle);
  }, [titlesData]);
  return (
    <InputBaseContainer>
      <Controller
        name="activityTitle"
        defaultValue=""
        control={control}
        render={props => (
          <InputSelectWithLabel
            showInRow={true}
            placeholder=""
            menuPlacement="bottom"
            options={titlesData}
            isLoading={false}
            isDisabled={disabledGlobalSearch}
            label={labels.activityTitle}
            error={errors.activityTitle}
            getOptionValue={(item: any) => item.id || Math.random()}
            getOptionLabel={(item: any) => item.name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
    </InputBaseContainer>
  );
};

export default ActivityTitle;
