import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../constants/routes';

import {
  addErrorsForEmptyFields,
  isValidationSucceed,
} from '../utils/validation';

import { PasswordInput, SuccessModal } from '../molecules';

import {
  ButtonMain,
  Paper,
  UnderlinedLink,
} from '../atoms';

const initialErrorMessages: FormErrors = {
  oldPassword: null,
  newPassword1: null,
  newPassword2: null,
};

const ChangePasswordForm = ({
  values,
  setValues,
  apiErrors,
  setApiErrors,
  isModalOpen,
  onPasswordChange,
}: Props) => {
  const [errors, setErrors] = React.useState(
    initialErrorMessages,
  );

  const history = useHistory();

  const handleModalClose = () =>
    history.push(ROUTES.ACCOUNT);

  const handleChangeValues = (
    key1: string,
    key2: string,
  ) => e => {
    setValues({ ...values, [key1]: e.target.value });
    setErrors({ ...errors, [key1]: '' });
    setApiErrors({ ...apiErrors, [key2]: '' });
  };

  const validateForm = () => {
    const newErrors = addErrorsForEmptyFields(
      values,
      errors,
      'This field can not be empty',
    );

    setErrors(newErrors);

    return isValidationSucceed(newErrors);
  };

  const handleSubmit = () => {
    const isValid = validateForm();

    if (isValid) onPasswordChange();
  };

  React.useEffect(() => {
    setErrors({
      oldPassword: apiErrors.oldPasswordMessage,
      newPassword1: apiErrors.newPassword1Message,
      newPassword2: apiErrors.newPassword2Message,
    });
  }, [apiErrors]);

  return (
    <Wrapper>
      <span>Change Password</span>

      <PaperBox>
        <PasswordInput
          id="oldPassword"
          label="Current Password"
          value={values.oldPassword}
          onChange={handleChangeValues(
            'oldPassword',
            'oldPasswordMessage',
          )}
          isValid={!errors.oldPassword}
          helperText={errors.oldPassword}
        />

        <PasswordInput
          id="newPassword1"
          label="New Password"
          value={values.newPassword1}
          onChange={handleChangeValues(
            'newPassword1',
            'newPassword1Message',
          )}
          isValid={!errors.newPassword1}
          helperText={errors.newPassword1}
        />

        <PasswordInput
          id="newPassword2"
          label="Confirm Password"
          value={values.newPassword2}
          onChange={handleChangeValues(
            'newPassword2',
            'newPassword2Message',
          )}
          isValid={!errors.newPassword2}
          helperText={errors.newPassword2}
        />

        <ButtonMain onClick={handleSubmit}>
          Update information
        </ButtonMain>

        <CancelAction
          onClick={() => history.push(ROUTES.ACCOUNT)}
        >
          Cancel
        </CancelAction>
      </PaperBox>

      {isModalOpen && (
        <SuccessModal
          title="Changes have been saved"
          onClose={handleModalClose}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > span {
    color: var(--secondaryText);
  }
`;

const PaperBox = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  background: var(--secondary);
  padding: 24px 24px 28px;
  margin-top: 7px;

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 24px;
  }

  .MuiButton-root {
    width: 250px;

    &.MuiButton-contained {
      box-shadow: none;
    }
  }
`;

const CancelAction = styled(UnderlinedLink).attrs({
  as: 'span',
})`
  display: inline-block;
  margin-top: 24px;
`;

interface Props {
  values: FormData;
  setValues: (arg: FormData) => void;
  apiErrors: ApiErrors;
  setApiErrors: (arg: ApiErrors) => void;
  isModalOpen: boolean;
  onPasswordChange: () => void;
}

interface FormErrors {
  [s: string]: string;
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}

interface FormData {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}

interface ApiErrors {
  oldPasswordMessage: string;
  newPassword1Message: string;
  newPassword2Message: string;
}

export default ChangePasswordForm;
