import * as React from 'react';
import styled from 'styled-components';
import UsersForm from '../forms/UsersForm';
import { ModalDefaultDraggable } from '../molecules';
import useTablePage from '../hooks/useTablePage';
import { getEmployeesUsers, getUserByID, updatePermitUser, updateUser } from '../api/users';
import Table from './Table';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import {
  convertTableBoolean,
  convertTableUserRole,
} from '../utils/convertTableValues';
import ModalInfo from '../molecules/ModalInfo';
import {HomeWorkOutlined} from '@material-ui/icons';
import { getContactProjects, getProjects } from '../api/projects';
import { getVocabulariesDataList } from '../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { generatePath } from 'react-router';
import { ROUTES } from '../constants/routes';
import ActionsPanel from './ActionsPanel';
import { getContactTypeList } from '../api/contactType';
import ContactsTable from './Tables/ContactsTable';


const PermitViewContacts = ({
  permit,
  setContactUpdated,
  noEdit,
  cameFromSearch,
  selectedPermit,
}: Props) => {
  const {
    createOrUpdate,
    isModalOpen,
    modalTitle,
    modalError,
    modalSuccess,
    modalErrorTitle,
    handleCloseModal,
    handleCloseModalInfo,
    values,
    errors,
    handleEdit,
    setSearch,
    search,
    tableManuallyUpdate,
    updateTable,
  } = useTablePage({
    updateEndpoint: updateUser,
    modalUpdateTitle: 'Contact Details',
  });
  const [contactTypesData, setContactTypesData] = React.useState(null)

  React.useEffect(() => {
    getContactTypeList({
      ...GET_ALL_QUERY_PARAMS,
    }).then(contactTypesResponse => {
      const activeContactTypes = contactTypesResponse?.data?.results?.filter(type => type?.isActiveStatus === "True")
      setContactTypesData(activeContactTypes)
    })
  }, [])

  const onTicketStatusChange = (selected, row) => {
    if (selected) {
      updatePermitUser({
        newContactType: selected?.id,
        id: row,
        permitId: permit
      })
        .catch(err => console.log(err))
        // .finally(() => setIsLoading(false));
    }
  };

  

  React.useEffect(() => {
    if (modalSuccess) {
      updateTable();
      setContactUpdated(true);
    }
  }, [modalSuccess]);

  const columnsOrder = [
    'companyName',
    'firstName',
    'lastName',
    'contactType',
    'phoneNumber',
    'email',
  ];

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'address',
        'city',
        'state',
        'street',
        'zipCode',
        'prNumber',
        'clientType',
        'licenses',
        'mobilePhoneNumber',
        'parentCompany',
        'tradesNames',
        'franchiseNames',
        'franchise',
        'trade',
        'avatar',
        'userRole',
        'isInvitationSent',
        'isEpsuperadmin',
        'createdAt',
        'isActive',
        'contactPerson',
      ].includes(column)
    ) {
      return null;
    }

    return tableColumnsFormatter(column);
  };

  const formatValue = (value: any, column: string) =>
    convertTableBoolean(
      convertTableUserRole(value, column),
    );

    const modifiedGetContacts = params =>
    Promise.all([getEmployeesUsers({ user_role:3,permit_id:permit }), selectedPermit?.contractor && getUserByID(selectedPermit?.contractor)])
      .then((response) => {      
        const contacts = response[0]?.data?.filter(
          el => el.isActive,
        )  
        let contractor = {}
        if(selectedPermit?.contractor) {
          contractor = response[1]?.data
        }
        
        return {
          ...response[0],
          data: {
            ...response[0]?.data || [],
            results: (Object.keys(contractor)?.length > 0 ? [...contacts, contractor] : contacts) || [],
          },
        };
      })
      .catch(err => console.log(err))
      .finally(() => {});

  const handleCloseUserForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const handleCloseSuccessModal = () => {
    setContactUpdated(false);
    handleCloseModalInfo();
  };
const [permitUserId,setPermitUserId] = React.useState(null)
  const [showUserPermitsModal, setShowUserPermitsModal] = React.useState(false)
const viewUserPermits = (row) => {
  setPermitUserId(row?.id)
  setShowUserPermitsModal(true)
}

const handleCloseUserPermitsModal = () => {
  setShowUserPermitsModal(false)
}
  const additionalActions = (row) => {
      return [
        {
          title: 'View Permits',
          handler: () => viewUserPermits(row),
          Icon: HomeWorkOutlined,
        },
      ];
    
  };

  const permitColumnsOrder = [
    'name',
    'permitsNames',
    'permitsNumbers',
    'permitsStatuses',
    'address',
    'jurisdictionName',
    'franchiseName',
  ];

  const permitColumns = column => {
    if (
      [
        'id',
        'isDeleted',
        'franchise',
        'jurisdiction',
        'street',
        'city',
        'state',
        'zipCode',
        'serviceType',
        'contacts',
        'manager',
        'createdAt',
        'franchiseName',
        'permits',
        'numPermits'
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Project Name';
      case 'jurisdictionName':
        return 'Jurisdiction';
      case 'managerName':
        return 'Project Manager';
      case 'permitsNames':
        return 'Permit Name';
      case 'permitsNumbers':
        return 'Permit Number';
      case 'permitsStatuses':
        return 'Permit Status';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const getStatuses = (permits, statuses) =>
  permits.map(
    permit =>
      statuses.find(status => permit.status === status.id)
        ?.title,
  );
  const modifiedGetProjectsWithPermits = params =>
  Promise.all([
    getContactProjects({...params, contact_id: permitUserId}),
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 6,
      ordering: 'title',
    }), // statuses
  ]).then(
    ([
      projectsResponse,
      statusesResponse,
    ]) => {      
      
      return {
        ...projectsResponse,
        data: {
          ...projectsResponse.data,
          results: projectsResponse.data.map(
            project => {
              const projectPermits = project?.permits
              
              return {
                ...project,
                permitsNames: projectPermits.map(
                  permit => permit.name,
                ),
                permits: projectPermits,
                permitsNumbers: projectPermits.map(permit =>
                  permit.number ? permit.number : '',
                ),
                permitsStatuses: getStatuses(
                  projectPermits,
                  statusesResponse.data.results,
                ),
                address: `${project.street}, ${project.city}, ${project.state}, ${project.zipCode}`,
              };
            },
          ),
        },
      };
    },
  );
  const handlePermitClick = (data, column) => {
   
      if (data.permitItemId) {
        const path = generatePath(ROUTES.PERMIT, {
          projectId: data.projectId,
          permitId: data.permitItemId,
        });
        // history.push(path);
        window.location.assign(path)
        // window.location.reload()

      }
      if (data.permits?.length === 1) {
        const path = generatePath(ROUTES.PERMIT, {
          projectId: data.id,
          permitId: data.permits[0].id,
        });
        // history.push(path);
        window.location.assign(path)
        // window.location.reload()
      }
    
  };
  
  return (
    <ContactsWrapper>
      {noEdit ? (
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          columnsOrder={columnsOrder}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={additionalActions}
          rows={modifiedGetContacts}
          formatValue={formatValue}
          manuallyUpdate={tableManuallyUpdate}
          
        />
      ) : null}
      {(!noEdit && contactTypesData) ? (
        <div style={{minHeight: '400px'}}>
          {/* This is the contact table to replace */}
        {/* <Table
          customSearch
          searchQuery={search}
          columns={columns}
          columnsOrder={columnsOrder}
          onEdit={handleEdit}
          onDelete={null}
          onConfigure={null}
          rows={modifiedGetContacts}
          additionalActions={additionalActions}
          formatValue={formatValue}
          manuallyUpdate={tableManuallyUpdate}
          useViewModeInsteadEdit={cameFromSearch}
          ticketStatuses={contactTypesData}
          onTicketStatusChange={onTicketStatusChange}
         
        />  */}
        <ContactsTable setShowUserPermitsModal={setShowUserPermitsModal} dataRows={modifiedGetContacts} handleEdit={handleEdit}/>
        </div>
      ) : null}

      {isModalOpen && (
        <StyledModalDraggable
          onClose={handleCloseUserForm}
          title={modalTitle}
          wide
        >
          <UsersForm
            // setIsContactFormSubmitting={null}
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
            updateButtonText="Update Contact"
            cameFromSearch={cameFromSearch}
          />
        </StyledModalDraggable>
      )}

    {showUserPermitsModal && (
            <StyledModalDraggable
              onClose={handleCloseUserPermitsModal}
              title="User Permits"
              wide
            >
            <ActionsPanel
              isBreadCrumbsVisible={false}
              onSearch={setSearch}
              isActionPanelMoreVisible={false}
              className='action-container'
            />
              <Table
                customSearch
                searchQuery={search}
                columnsOrder={permitColumnsOrder}
                columns={permitColumns}
                rows={modifiedGetProjectsWithPermits}
                permitUserId={permitUserId}
                onEdit={null}
                onDelete={null}
                onConfigure={null}
                initialSortBy={[{ id: 'createdAt', desc: true }]}
                additionalActions={null}
                formatValue={convertTableBoolean}
                manuallyUpdate={tableManuallyUpdate}
                cellCallbackFnIsOn={['permitsNames']}
                handleCellClick={(data, column) =>
                  handlePermitClick(data, column)
          }
        />
            </StyledModalDraggable>
          )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseSuccessModal}
          errorMsg={modalErrorTitle}
        />
      )}
    </ContactsWrapper>
  );
};

interface Props {
  permit: number;
  setContactUpdated: (data: boolean) => void;
  noEdit?: boolean;
  cameFromSearch?: boolean;
}

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

const ContactsWrapper = styled.div`
  min-height: 200px;
`;

export default PermitViewContacts;
