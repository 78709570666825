import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Logo_Blue, Logo_Sky } from '../../../../../constants/menu';

type Props = {
  label: string;
  disabled: boolean;
  checked: boolean;
  name: string;
  value: string;
  title: string;
  onChange: Function;
  isRadio: boolean;
};
const SwitchCheckbox: FC<Props> = ({ label, disabled, checked, name, value, title, onChange, isRadio }) => {
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    if (disabled) {
      setCheck(false);
    }
  }, [disabled]);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  const handleOnClick = e => {
    if (disabled) {
      return;
    }

    if (isRadio && check) {
      return;
    }
    const payload = {
      target: {
        name,
        value,
        checked: !check,
      },
    };
    onChange(payload);
    setCheck(!check);
  };
  return (
    <div>
      <WrapperWithLabel title={title} onClick={handleOnClick} name={name} value={value} checked={check}>
        <Wrapper disabled={disabled} isChecked={check}>
          <RoundButton isChecked={check} disabled={disabled} />
        </Wrapper>
        <Label title={title} disabled={disabled}>
          {label}
        </Label>
      </WrapperWithLabel>
    </div>
  );
};

export default SwitchCheckbox;

const Wrapper = styled.div<{ disabled: boolean, isChecked: boolean }>`
  width: 56px;
  margin: 11px;
  height: 31px;
  border-radius: 15px;
  border: 1px solid grey;
  background-color: ${({ disabled, isChecked }) => {
    const _color = isChecked ? Logo_Sky : '#ccc';
    return disabled ? '#eee' : _color;
  }};
  z-index: 3;
`;

const WrapperWithLabel = styled.div<Partial<Props>>`
  display: flex;
`;

const Label = styled.div<{ disabled: boolean }>`
  font-size: 17px;
  margin-top: 12px;
  [data-title]:hover {
    font-size: 30px;
  }
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit')};
`;

const RoundButton = styled.div<{
  isChecked: boolean;
  disabled: boolean;
}>`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${({ isChecked, disabled }) => {
    if (disabled) {
      return '#ccc';
    }
    return isChecked ? '#D2B96D' : '#7E8D8C';
  }};

  position: relative;
  top: 2px;
  left: ${({ isChecked }) => (isChecked ? '27px' : '2px')};
  transition: all 0.3s ease-in-out;
`;
