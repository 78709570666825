import formatDate from './formatDate';

const setInspectionData = (
  data: any,
  initialValues: any,
  submitFn: (data) => void,
  permit: any,
  contacts: any,
  type?: any,
) => {
  if (data.toDate && data.followUp && !type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: data.inspectionType.id,
      status: data.activityStatus?.id,
      inspectionDate: `${formatDate(data.toDate)}`,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      followUp: `${formatDate(data.followUp)}`,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (data.toDate && !type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: data.inspectionType.id,
      status: data.activityStatus?.id,
      inspectionDate: `${formatDate(data.toDate)}`,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (data.followUp && !type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: data.inspectionType.id,
      status: data.activityStatus?.id,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      followUp: `${formatDate(data.followUp)}`,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (data.toDate && data.followUp && type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: type.id,
      status: data.activityStatus?.id,
      inspectionDate: `${formatDate(data.toDate)}`,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      followUp: `${formatDate(data.followUp)}`,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (data.toDate && type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: type.id,
      status: data.activityStatus?.id,
      inspectionDate: `${formatDate(data.toDate)}`,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (data.followUp && type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: type.id,
      status: data.activityStatus?.id,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      followUp: `${formatDate(data.followUp)}`,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (!data.toDate && !data.followUp && !type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: data.inspectionType.id,
      status: data.activityStatus?.id,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  if (!data.toDate && !data.followUp && type) {
    return {
      permit,
      id: initialValues?.id,
      title: data.activityTitle?.id,
      inspectionType: type.id,
      status: data.activityStatus?.id,
      period: data.period?.id,
      inspector: data.inspector?.id,
      contact: data.activityContact?.id,
      information: data.information,
      assignedTo: data?.assignedTo?.id,
      isEmergency: data.emergency,
      isCompleted: data.isCompleted,
      toActivityContact: data.activityContactCheckbox,
      toJurisdiction: data.jurisdictionContacts,
      toProjectContacts: data.projectContacts,
      additionalContacts: contacts,
    };
  }
  return null;
};

export default setInspectionData;

export const getContactString = (el:any) => {
  const {contactFirstName, contactLastName, companyName,parentCompany} = el;
  const firstName = contactFirstName ? contactFirstName : "";
  const lastName = contactLastName ? contactLastName : "";
  const fullName = `${firstName} ${lastName}`.trim();

  let _companyName = companyName ? companyName : "";
  if(_companyName === ""){
    _companyName = parentCompany;
  }
  
  if(fullName && _companyName){
    return `${fullName} - ${_companyName}`
  }

  if(fullName){
    return fullName;
  }

  return "";
}