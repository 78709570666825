import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonMain from '../../Common/Controls/ButtonMain';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { setShowDrawerContent } from '../../../../../redux/slices/generalSlice';
import { useHistory } from 'react-router';
import { ROUTES } from '../../Routes/ClientRoutes';

type Props = {
  caption?: string;
};
const NewProjectButton: FC<Props> = ({ caption }) => {
  const history = useHistory();
  const [isDashboard, setIsDashboard] = useState<boolean>(true);
  const showDrawerContent = useSelector((s: RootState) => s.general.showDrawerContent);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsDashboard(history.location.pathname === ROUTES.DASHBOARD);
  }, [history]);
  const onNewProject = () => {
    history.push(`${ROUTES.DASHBOARD}?newProject=true`);
    dispatch(setShowDrawerContent(true));
  };
  const NEW_PROJECT = caption ?? 'New Project';
  return (
    <ButtonWrapper>
      {isDashboard ? (
        <>
          {showDrawerContent ? (
            <ButtonMain label="Back" onClick={() => dispatch(setShowDrawerContent(false))} />
          ) : (
            <ButtonMain label={NEW_PROJECT} onClick={onNewProject} />
          )}
        </>
      ) : (
        <ButtonMain label={NEW_PROJECT} onClick={onNewProject} />
      )}
    </ButtonWrapper>
  );
};

export default NewProjectButton;

const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 38px;
  position: absolute;
  top: -16px;
`;
