import * as React from 'react';
import styled from 'styled-components';
import ButtonMain from '../atoms/ButtonMain';

const PermitMenu = ({ tabs, activeTab }: Props) => {
  return (
    <MenuWrapper>
      {tabs.map(tab => (
        <ButtonWrapper key={tab.id}>
          <ButtonMain
            variant={
              tab.id === activeTab ? 'contained' : 'text'
            }
            onClick={tab.action}
          >
            {tab.title}
          </ButtonMain>
        </ButtonWrapper>
      ))}
    </MenuWrapper>
  );
};

interface Props {
  tabs: Array<Tab>;
  activeTab: number;
}

interface Tab {
  id: number;
  title: string;
  action: () => void;
}

const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 24px 0px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 14.2%;
  justify-content: center;
`;

export default PermitMenu;
