import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FeesSearchBar } from '../../molecules';

import { RootState } from '../../redux/root';
import { FilterEntry, setFilters } from '../../redux/slices/filterSlice';

export type FilterMetaEntry = {
  label: string,
  name: string,
}

type MyProps = {
  filterMeta: FilterMetaEntry[]
}
const MobileFilter:FC<MyProps> = ({filterMeta}) => {
  const dispatch = useDispatch();
  const filters:FilterEntry[] = useSelector((state: RootState) => state.filters.filters);
  const updateFilters = (name: string,value: string) => {
  
    const foundItem = filters.find(f => f.filterName === name);
    let newArr;
    if (!foundItem) {
      newArr = [...filters, {
        filterName: name,
        filterValue: value.toLowerCase(),
      }];
    } else {
      newArr = filters.map(f => {
        if (f.filterName === name) {
          return {
            filterName: name,
            filterValue: value.toLowerCase(),
          };
        }
        return f;
      });
    }
    dispatch(setFilters(newArr));
  };

    return (
      <FilterWrapper>
        { filterMeta.map((item, index) => (
          <FilterField key={index}>
            <FilterLabel>{item.label}</FilterLabel>
            <FeesSearchBar
              columnName={item.name}
              text=""
              onKeyChanged={()=>{}}
              onChange={updateFilters}
              debounceDelay={1000}
            />
          </FilterField>
        ))}
      </FilterWrapper>
    );
};

const FilterWrapper = styled.div`
  width: 100%;
  height: fit-content;
  border: 1px solid gray;
  border-radius: 4px;
  margin: 5px 0 5px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (min-width: 841px) {
    display: none;
  }
`;

const FilterField = styled.div`
  display: grid;
  grid-template-rows: 18px 1fr;
  margin:5px;
  height: fit-content;

`;

const FilterLabel = styled.div`
  font-size: 11px;
  font-weight: bold;
`;
export default MobileFilter;
