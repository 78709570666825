import * as React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import useDevice from '../hooks/useDevice';

const ButtonModalClose = ({
  onClick,
  onTouchStart,
  className,
}: ButtonModalCloseTypes) => {
  const [isIpad] = useDevice()
  return (
    <CloseButton onPointerEnter={isIpad ? onClick : null} onClick={onClick} onTouchStart={onClick} className={className}>
      <CloseIcon />
    </CloseButton>
  )
};

const CloseButton = styled.button`
  border: 1px solid var(--linkText);
  outline: none;
  position: absolute;
  border-radius: 50%;
  background: transparent;
  height: 30px;
  width: 30px;

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: var(--linkText);

    svg {
      filter: invert(1);
    }
  }
`;

interface ButtonModalCloseTypes {
  onClick: () => void;
  onTouchStart: () => void;
  className?: string;
}

export default ButtonModalClose;
