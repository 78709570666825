import * as React from 'react';
import styled from 'styled-components';
import { ModalDefaultDraggable } from '../molecules';
import {
  editFormSetup,
  FormSetupType,
} from '../api/formSetup';
import FormSetupPlaceholders from './FormSetupPlaceholders';
import { getActiveFieldsFromMarkup } from '../utils/formSetupUtils';

const EditFormSetupPlaceholders = ({
  data,
  setData,
  updateTable,
  setSelectedFontSize,
  selectedFontSize,
}: Props) => {
  const [isInitData, setInitData] = React.useState(false);
  const [
    initialActiveFields,
    setInitialActiveFields,
  ] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      const result = [];
      data?.formMarkup.forEach(field => {
        getActiveFieldsFromMarkup(
          field,
          ['fields'],
          result,
        );
        setInitialActiveFields([
          ...initialActiveFields,
          ...result,
        ]);
      });
      setInitData(true);
    }
  }, [data]);

  const handleSubmitPlaceholders = ({ createdMarkup }) => {
    editFormSetup({
      formMarkup: JSON.stringify(createdMarkup),
      id: data.id,
      fontSize:
        +data?.fontSize === selectedFontSize
          ? data?.fontSize
          : selectedFontSize,
    })
      .then(() => {
        updateTable();
        setInitData(false);
        setData(null);
        setInitialActiveFields([]);
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      {isInitData && (
        <StyledModalDraggable
          onClose={() => {
            setData(false);
            setInitData(false);
            setInitialActiveFields([]);
          }}
          title="Set Placeholders"
          wide
        >
          <FormSetupPlaceholders
            url=""
            type={data.type}
            setPlaceholders={setData}
            initialValues={data}
            initialActiveFields={initialActiveFields}
            handleSubmitPlaceholders={
              handleSubmitPlaceholders
            }
            setSelectedFontSize={setSelectedFontSize}
          />
        </StyledModalDraggable>
      )}
    </>
  );
};

interface Props {
  data: FormSetupType;
  setData: (data: boolean) => void;
  setSelectedFontSize: (data: any) => void;
  selectedFontSize: number;
  updateTable: () => void;
}

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default EditFormSetupPlaceholders;
