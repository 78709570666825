export default {
  Alabama: 'AL',
  Alaska: 'AK',
  Alberta: 'AB',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  'Armed Forces (AE)': 'AE',
  'Armed Forces Americas': 'AA',
  'Armed Forces Pacific': 'AP',
  'British Columbia': 'BC',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Manitoba: 'MB',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Brunswick': 'NB',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  Newfoundland: 'NF',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Ontario: 'ON',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Prince Edward Island': 'PE',
  'Puerto Rico': 'PR',
  Quebec: 'QC',
  'Rhode Island': 'RI',
  Saskatchewan: 'SK',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'Yukon Territory': 'YT',
};
