import React, { FC } from 'react';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import { Controller } from 'react-hook-form';

type Props = {
  control: any;
  register: any;
  errors: any;
  name: string;
  label?: string;
};
const ClientTextFieldController: FC<Props> = ({
  control,
  register,
  errors,
  name,
  label
}) => {
  return (
    <Controller
      as={TextField}
      control={control}
      id="filled-error-helper-text"
      name={name}
      variant="outlined"
      label={label ? label: _.upperFirst(name)}      
      style={{ width: '100%', marginTop: '25px' }}
      helperText={errors[name] ? errors[name].message : ''}
      inputRef={register}
      error={errors[name] !== undefined}
    />
  );
};

export default ClientTextFieldController;
