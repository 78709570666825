import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { ROUTES } from '../constants/routes';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';

import { FormServerError } from '../types';

import { UserCodeData } from '../api/userSettings';

import {
  ButtonMain,
  Paper,
  UnderlinedLink,
} from '../atoms';

import {
  InputTextWithLabel,
  InputBaseContainer,
} from '../molecules';

const schema = yup.object().shape({
  code: yup.string().required().label('code'),
});

const VerifyPasswordForm = ({
  onSubmit,
  initialErrors,
}: ChangeEmailFormTypes) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    setError,
  } = useForm<UserCodeData>({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<UserCodeData>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  return (
    <PaperBox>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="code"
            error={errors.code}
            inputRef={register}
            label="Enter verify code please"
            isRequired={isYupFieldRequired('code', schema)}
          />
        </InputBaseContainer>
        <ButtonContainer>
          <ButtonMain type="submit">submit</ButtonMain>

          <CancelAction
            onClick={() => history.push(ROUTES.ACCOUNT)}
          >
            Cancel
          </CancelAction>
        </ButtonContainer>
      </FormContainer>
    </PaperBox>
  );
};

const PaperBox = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  background: var(--secondary);
  padding: 24px 24px 28px;
  margin-top: 7px;

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 24px;
  }

  .MuiButton-root {
    width: 250px;

    &.MuiButton-contained {
      box-shadow: none;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const CancelAction = styled(UnderlinedLink).attrs({
  as: 'span',
})`
  display: block;
  margin-top: 24px;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface ChangeEmailFormTypes {
  onSubmit: (data: UserCodeData) => void;
  initialErrors?: FormServerError<UserCodeData>;
}

export default VerifyPasswordForm;
