import { path } from 'ramda';
import {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';

import * as auth from '../auth';
import {
  objectKeysToCamelCase,
  objectKeysToSnakeCase,
  renameSpecialParams,
} from '../utils';
import { api, apiPublic } from './api';
import { dispatch } from '../redux/store';
import { setRequestError } from '../redux/ducks/requestApiError';

// **refreshPromise used as singleton for all token expired requests
// "null" mean there was no token expiration event yet
let refreshPromise: Promise<string> = null;

const refreshAccessToken = async (refreshToken: string) => {
  if (!refreshPromise) {
    refreshPromise = auth.refreshToken(refreshToken);
  }

  const accessToken = await refreshPromise;

  if (!accessToken) {
    throw new Error('Can not update token');
  }

  refreshPromise = null;

  return accessToken;
};

async function onRequestInterceptor(
  request: AxiosRequestConfig,
) {
  const refreshToken = auth.getRefreshToken();
  let accessToken = auth.getAccessToken();

  if (!refreshToken) {
    throw new Error('No refresh token provided');
  }

  if (auth.refreshTokenExpired()) {
    throw new Error('Refresh token expired');
  }

  if (auth.accessTokenExpired()) {
    accessToken = await refreshAccessToken(refreshToken);
  }

  request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
}

function onRequestTransformParams(
  request: AxiosRequestConfig,
) {
  if (/(http|https)/.test(request.url)) {
    // eslint-disable-next-line prefer-destructuring
    request.url = request.url.split(request.baseURL)[1];
  }

  if (
    request.headers['Content-Type'] ===
    'multipart/form-data'
  ) {
    return request;
  }

  if (request.data) {
    request.data = objectKeysToSnakeCase(request.data);
  }

  if (request.params) {
    request.params = renameSpecialParams(
      objectKeysToSnakeCase(request.params),
    );
  }

  return request;
}

function onPrivateRequestErrorInterceptor(
  error: AxiosError,
) {
  auth.logOutWithHistory();

  return Promise.reject(error);
}

export function onResponseInterceptor(
  response: AxiosResponse,
) {
  if (response.data) {
    response.data = objectKeysToCamelCase(response.data);
  }

  return response;
}

export async function onResponseErrorInterceptor(
  error: AxiosError,
) {
  if (path(['response', 'data'], error)) {
    error.response.data = objectKeysToCamelCase(
      error.response.data,
    );
  }

  if (
    error?.response?.status >= 400 &&
    error?.response?.status !== 401
  ) {
    const { data, status } = error.response;

    dispatch(setRequestError({ data, status }));
  }

  if (error.response.status === 401) {
    if (
      error.config.url === '/authentication/token/refresh/'
    ) {
      auth.logOutWithHistory();
    }

    if (error.response.data.code === 'token_not_valid') {
      const refreshToken = auth.getRefreshToken();

      if (!refreshToken) {
        throw new Error('No refresh token provided');
      }

      const accessToken = await refreshAccessToken(
        refreshToken,
      );

      if (accessToken) {
        return api(error.config);
      }
    }
  }

  return Promise.reject(error);
}

api.interceptors.request.use(
  onRequestTransformParams,
  onPrivateRequestErrorInterceptor,
);

api.interceptors.request.use(onRequestInterceptor);

api.interceptors.response.use(
  onResponseInterceptor,
  onResponseErrorInterceptor,
);

apiPublic.interceptors.request.use(
  onRequestTransformParams,
);

apiPublic.interceptors.response.use(
  onResponseInterceptor,
  onResponseErrorInterceptor,
);
