import * as React from 'react';
import styled from 'styled-components';
import { List } from '@material-ui/core';
import CollapsableList from '../molecules/CollapsableList';
import CollapsableListItem from '../molecules/CollapsableListItem';
import {
  InputBaseContainer,
  InputSelectWithLabel,
} from '../molecules';
import { FORM_SETUP_FONT_SIZE } from '../__DATA__';

const FormSetupFields = ({
  fields,
  setActiveFields,
  activeFields,
  setSelectedFontSize,
  initialFont,
}: Props) => {
  const renderFormSetupMenu = (data, parent?: string) => {
    const array = Object.entries(data);
    let parentString = '';
    return array.map(pair => {
      parentString = parent
        ? `${parent}>${pair[0]}`
        : pair[0];
      const parentsArray = parentString.split('>');
      const padding = `${
        parentsArray?.length === 1
          ? 0
          : parentsArray?.length * 3
      }px`;
      if (typeof pair[1] !== 'string') {
        return (
          <CollapsableList
            label={pair[0]}
            padding={padding}
            key={`${parentString}${pair[0]}${pair[1]}`}
          >
            {renderFormSetupMenu(pair[1], parentString)}
          </CollapsableList>
        );
      }
      return (
        <CollapsableListItem
          key={`${parentString}${pair[0]}${pair[1]}`}
          label={pair[0]}
          padding={padding}
          parents={parentsArray}
          value={pair[1]}
          setActiveFields={setActiveFields}
          activeFields={activeFields}
        />
      );
    });
  };

  return (
    <Wrapper>
      <InputBaseContainer>
        <InputSelectWithLabel
          label="Font Size"
          options={FORM_SETUP_FONT_SIZE}
          getOptionLabel={(item: any) => `${item.label} px`}
          getOptionValue={(item: any) => item.value}
          defaultValue={
            initialFont
              ? FORM_SETUP_FONT_SIZE.find(
                  el => el?.value === +initialFont,
                )
              : FORM_SETUP_FONT_SIZE[2]
          }
          onChange={selected =>
            setSelectedFontSize(selected?.value)
          }
        />
      </InputBaseContainer>
      <List component="nav">
        {fields && renderFormSetupMenu(fields)}
      </List>
    </Wrapper>
  );
};

interface Props {
  fields: any;
  setActiveFields: (data: any) => void;
  setSelectedFontSize: (data: any) => void;
  activeFields: Array<any>;
  initialFont?: number | string;
}

const Wrapper = styled.section`
  width: 100%;
  padding-left: 10px;
`;

export default FormSetupFields;
