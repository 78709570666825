import React, { FC } from 'react';
import ModalDefaultDraggable from '../../../molecules/ModalDefaultDraggable';
import { ButtonWithLoader } from '../../../molecules';
import { sendInspectionMessage, updatePermitInspection } from '../../../api/inspections';
import useTablePage from '../../../hooks/useTablePage';

type MyProps = {
    open: boolean;
    setOpen: Function;
    messageData: any;
}
const DailyInspectionsPopup:FC<MyProps> = ({open, setOpen, messageData}) => {
    const [modalSuccess1, setModalSuccess] = React.useState(false);
    const [modalErr, setModalErr] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
      };
    const handleInspectionEdit = (params) => {
        const param = {id: params?.id}
        Promise.all([getPermitInspectionById(param)]).then(([inspection]) => {
          setValues(inspection?.data)
          setModalTitle(inspection?.data?.isDeleted ? '' : 'Update');
          openModal(true);
        })
      };

      const handleSendMessage = () => {
        const cellPhone = parseInt(messageData?.cellPhone.split("-").join(""), 0);
        sendInspectionMessage({
          phone_number: cellPhone,
          inspector_id: messageData?.inspector,
          address: messageData?.projectAddress,
          report_status: "null",
          inspection_status: messageData?.information,
          inspection_id: messageData?.id,
        })
          .then(() => {
            handleClose();
            setModalSuccess(true);
          })
          .catch(() => {
            handleClose();
            setModalErr(true);
          });
      };
    const {
        isModalOpen,
        modalError,
        modalSuccess,
        modalTitle,
        modalErrorTitle,
        values,
        createOrUpdate,
        handleEdit,
        handleCloseModalInfo,
        handleCloseModal,
        setValues,
        updateTable,
        setModalTitle,
        openModal,
       } = useTablePage({
         updateEndpoint: updatePermitInspection,
         modalUpdateTitle: 'Update Inspection',
      });
    return (
      <div>
        {open && (
        <ModalDefaultDraggable
              // title="Are you sure you want to send Message to Client?"
          title="SMS Confirmation"
          onClose={handleClose}
        >
          <>
            <p>
              Are you sure you want to message the
              contact?
            </p>
            <br />
            <ButtonWithLoader
              type="submit"
              onClick={handleSendMessage}
              loading={false}
            >
              Send
            </ButtonWithLoader>
          </>
        </ModalDefaultDraggable>
          )}
      </div>
    );
};

export default DailyInspectionsPopup;
