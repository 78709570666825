import React, { FC } from 'react';
import ModalActionDialog from '../../../../../../molecules/ModalActionDialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styled from 'styled-components';
import ButtonMain from '../../../Common/Controls/ButtonMain';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';
import useGetNewProjectData from '../../../../hooks/useGetNewProjectData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';

type Props = {
  showModal: boolean;
  setShowModal: Function;
};
const ProjectCreatedModal: FC<Props> = ({
  showModal,
  setShowModal,
}) => {
    const history = useHistory();
    const vocabulary = useSelector((s:RootState) => s.general.vocabulary)
    const { loadProjects } = useGetNewProjectData();
    const actionFirst = () => {
        setShowModal(false);
        loadProjects(vocabulary,true);
        history.push(ROUTES.PROJECTS);
    }
  return (
    <>
      {showModal && (
        <ModalActionDialog
          title="Create New Project"
          onCancel={() => {}}
          actionFirst={actionFirst}
          actionSecond={null}
          buttonFirstTitle="OK"
          className="over"
          noCancel={true}
        >
          <BodyWrapper>
            <Label>
              Project request successfully submitted.
            </Label>
            <IconWrapper>
              <CheckCircleIcon />
            </IconWrapper>
          </BodyWrapper>
        </ModalActionDialog>
      )}
    </>
  );
};

export default ProjectCreatedModal;

const BodyWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
`;

const Label = styled.div`
  font-size: 23px;
  margin-right: 20px;
`;

const IconWrapper = styled.div`
  color: green;
  zoom: 2;
  position: relative;
  top: -3px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
