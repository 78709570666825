import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { Loader } from '../../../../../atoms';

const DropdownList = ({ data, label, value, onChange, isLoading }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<{ name: string }>({ name: '' });

  useEffect(() => {
    if (value && data?.length > 0) {
      setSelected(value);
    }
  }, [value]);
  const onClick = item => {
    setSelected(item);
    onChange(item);
    setOpen(false);
  };
  return (
    <Wrapper>
      <LabelWrapper>
        <LoadingBlock>
          {isLoading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </LoadingBlock>
        {label}
      </LabelWrapper>
      <SelectedItem onClick={e => setOpen(!open)} isLoading={isLoading}>
        <span>{selected.name}</span>
        {!open && <ArrowDropDown />}
        {open && <ArrowDropUp />}
      </SelectedItem>
      {open && (
        <Items onMouseLeave={e => setOpen(false)}>
          {data.map(item => (
            <Item onClick={e => onClick(item)}>{item.name}</Item>
          ))}
        </Items>
      )}
    </Wrapper>
  );
};

export default DropdownList;

const width = '80%';

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const LabelWrapper = styled.div`
  margin-right: 25px;
  display: flex;
  align-items: center;
  position: relative;
`;

const LoadingBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.4);
`;

const LoaderWrapper = styled.div`
  zoom: 0.4;
  position: relative;
  top: 32px;
  left: 250px;
`;

const SelectedItem = styled.div<{ isLoading: boolean }>`
  display: flex;
  width: ${width};
  border: 1px solid #d2d2d2;
  padding: 8px;
  justify-content: space-between;
  background-color: ${({ isLoading }) => (isLoading ? 'rgba(180,180,180,0.4)' : 'inherit')};
`;

const Items = styled.div`
  width: ${width};
  height: 98px;
  border: 1px solid #ddd;
  border-radius: 6px;
  position: absolute;
  left: 123px;
  top: 43px;
  z-index: 5;
  background-color: #fff;
  overflow: auto;
`;

const Item = styled.div`
  width: 100%;
  padding: 10px;
  :hover {
    background-color: #a7e0f4;
  }
`;
