import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonMain, Loader } from '../../atoms';
import { useDispatch, useSelector } from 'react-redux';
import { setMergingFormsArray, setShowMergingForms } from '../../redux/slices/inspectionsSlice';
import { RootState } from '../../redux/root';
import { getCustomActivities } from '../../api/activities';

const DownloadAllPermitsButton = ({ permit }) => {
  const { pdfReport, mergingFormsArray } = useSelector((s: RootState) => s.inspections);
  const { pageIndex, pageSize } = useSelector((s: RootState) => s.pagination);
  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      const results = await getCustomActivities({
        is_deleted: false,
        limit: pageSize,
        offset: pageIndex,
        permit_id: permit,
        ordering: null,
        event_type: 'Inspection',
      });

      const _reports = results.data.results.reduce((arr, item) => {
        if (item.report) {
          arr.push(item);
        }
        return arr;
      }, []);
     dispatch(setMergingFormsArray(_reports));
    };
    load();
  }, []);
  return (
      <WideButton>
        <LoaderWrapper>{/*{false && <Loader />}*/}</LoaderWrapper>
        <Part>
          <ButtonWrapper>
            <ButtonMain disabled={mergingFormsArray.length === 0} onClick={()=>dispatch(setShowMergingForms(true))}>
              Download Inspection Reports
            </ButtonMain>
          </ButtonWrapper>
        </Part>
      </WideButton>
  );
};

export default DownloadAllPermitsButton;

const WideButton = styled.div`
  display: flex;
  width: 385px;
`;

const ButtonWrapper = styled.div`
  width: 300px;
`;

const LoaderWrapper = styled.div`
  zoom: 0.4;
  width: 30px;
  position: relative;
  top: 14px;
  left: -20px;
`;

const Part = styled.div`
  width: 200px;
`;
