import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setPageCount, setPageIndex, setRowsCount } from '../../redux/slices/paginationSlice';
import { Pagination } from '../../modules/clientModule/components/Common/Table/types';
import { useGetProjectsQuery, useGetVocabularyQuery } from '../../redux/rtkQuery/apiSlice';
import { RootState } from '../../redux/root';
import { Loader } from '../../atoms';
import { Radio, RadioGroup } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HomeWork from '@material-ui/icons/HomeWork';
import PaginationMobile from '../../pages/Responsive/PaginationMobile';
import ContactTypeSelect from './ContactTypeSelect';
import { updateUsersAccountingFlag } from '../../api/users';
import { useParams } from 'react-router';
import { setPermitContacts } from '../../redux/slices/projectSlice';

const ContactsTable = ({ dataRows, handleEdit, setShowUserPermitsModal }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState<any>();
  const { data, isFetching } = useGetProjectsQuery(params, { skip: params === undefined });
  const { pageCount, pageIndex, pageSize } = useSelector((s: RootState) => s.pagination);
  //@ts-ignore
  const { permitId } = useParams();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let _params: any = { limit: pageSize };
    if (pageIndex > 0) {
      _params.offset = pageIndex * pageSize;
    }
    setParams(_params);
  }, [pageSize, pageIndex, pageCount]);

  useEffect(() => {
    dataRows().then(d => {
      setRows(d.data.results);
    });
  }, [data]);

  const updateAccounting = async (userId: number) => {
    const _rows = rows.map(r => {
      return {
        ...r,
        isAccounting: r.id === userId,
      };
    });
    setRows(_rows);
    dispatch(setPermitContacts(_rows));
    const _params = {
      userId,
      permitId,
      userRole: 3,
    };
    await updateUsersAccountingFlag(_params);
  };

  return (
    <div>
      {false ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <GridBase>
            <HeaderCell>Company Name</HeaderCell>
            <HeaderCell>Fisrt Name</HeaderCell>
            <HeaderCell>Last Name</HeaderCell>
            <HeaderCell>Contact Type</HeaderCell>
            <HeaderCell>Accounting</HeaderCell>
            <HeaderCell>Phone Number</HeaderCell>
            <HeaderCell>Email</HeaderCell>
            <HeaderCell>Actions</HeaderCell>
          </GridBase>
          <Rows>
            {rows.map((row, index) => {
              return (
                <RowBase key={index}>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>
                    <ContactTypeSelect contactType={row.contactType} userId={row.id} />
                  </TableCell>
                  <TableCell>
                    <input
                      type="radio"
                      checked={row.isAccounting || false}
                      onChange={() => updateAccounting(row.id)}
                      value={row.isAccounting || false}
                    />
                  </TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Icons>
                      <EditIcon onClick={() => handleEdit(row)} />
                      <HomeWork onClick={() => setShowUserPermitsModal(true)} />
                    </Icons>
                  </TableCell>
                </RowBase>
              );
            })}
          </Rows>
        </>
      )}
    </div>
  );
};

export default ContactsTable;

const Icons = styled.div`
  color: grey;
  cursor: pointer;
  width: 56px;
  display: flex;
  justify-content: space-between;
`;

const GridBase = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 200px 230px 99px 1fr 270px 100px;
  width: 90%;
  margin: auto;
`;

const RowBase = styled(GridBase)`
  border: 1px solid var(--border);
  border-radius: 6px;
  border-bottom: 4px double var(--border);
`;

const Rows = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const Underlined = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const HeaderCell = styled.div`
  font-weight: bold;
  padding: 15px;
  border-radius: 6px;
  color: var(--defaultTableCellTitle);
  background-color: var(--defaultTableCell);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const TableCell = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  text-align: left;
  padding: 8px 16px;
  border-bottom: 1px solid var(--border);
  max-width: 230px;
  overflow: auto;
  color: var(--mainText);
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; ;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
