import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';

import theme from '../../theme';

import { getFranchiseProposals } from '../../api/dashboard';

import { MONTH_FULL_LIST } from '../../constants';
import { convertDataKeysToLabels } from '../../utils/convertWidgetValues';

const FAProposalGraph = () => {
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    getFranchiseProposals().then(({ data }) => {
      setChartData(data);
    });
  }, []);

  return (
    <Container>
      <Header>Monthly Proposal Graph</Header>

      {chartData?.length ? (
        <Bar>
          <ResponsiveBar
            data={convertDataKeysToLabels(chartData)}
            enableLabel={false}
            keys={['Proposals Approved', 'Proposals Sent']}
            indexBy="Month"
            colors={[theme.marker1, theme.marker2]}
            padding={0.5}
            valueScale={{ type: 'symlog' }}
            borderRadius={5}
            innerPadding={10}
            groupMode="grouped"
            margin={{
              top: 0,
              right: 0,
              bottom: 50,
              left: -100,
            }}
            enableGridY={false}
            axisBottom={{
              format: (v: any) =>
                v && `${MONTH_FULL_LIST[v - 1]?.slice(
                  0,
                  3,
                )}'${chartData
                  .find(item => item.month === v)
                  ?.year?.toString()
                  .substr(-2)}`,
            }}
          />
        </Bar>
      ): ''}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  flex: 1;
  margin-top: 10px;
  margin-right: 10px;
  padding: 6px;
  width: 770px;
  min-height: 300px;
  border: 1px solid var(--border);
  border-radius: 5px;
  @media (max-width: 840px) {
    width: 100%;
  }
  `;

const Bar = styled.div`
  height: 400px;
  width: 500px;
`;

const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;

export default FAProposalGraph;
