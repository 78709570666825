import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SortingDirection } from '../../modules/clientModule/components/Common/Table/types';
import { SchedulingType } from '../../api/scheduling';

export type VocabularyItem = {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  title: string;
  vocabularyEntity: number;
};
export interface GeneralState {
  vocabulary: VocabularyItem[];
  tableSortingDirection: SortingDirection;
  tableSortingKey: string;
  showDrawerContent: boolean;
  schedules: SchedulingType[];
  bottomDrawerOpened: boolean;
  allEmployees: any[]
}

const initialState: GeneralState = {
  vocabulary: [],
  tableSortingDirection: 'NONE',
  tableSortingKey: '',
  showDrawerContent: false,
  schedules: [],
  bottomDrawerOpened: false,
  allEmployees: []
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setVocabulary: (state, action: PayloadAction<any>) => {
      state.vocabulary = action.payload;
    },
    setTableSortingDirection: (state, action: PayloadAction<SortingDirection>) => {
      state.tableSortingDirection = action.payload;
    },
    setTableSortingKey: (state, action: PayloadAction<string>) => {
      state.tableSortingKey = action.payload;
    },
    setShowDrawerContent: (state, action: PayloadAction<boolean>) => {
      state.showDrawerContent = action.payload;
    },
    setSchedules: (state, action: PayloadAction<SchedulingType[]>) => {
      state.schedules = action.payload;
    },
    setBottomDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.bottomDrawerOpened = action.payload;
    },
    setAllEmployees: (state, action: PayloadAction<any[]>) => {
      state.allEmployees = action.payload;
    },
    resetGeneral: state => {
      state.vocabulary = [];
      state.tableSortingDirection = 'NONE';
      state.tableSortingKey = '';
      state.showDrawerContent = false;
      state.schedules = [];
      state.bottomDrawerOpened = false;
      state.allEmployees = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setVocabulary,
  setTableSortingKey,
  setTableSortingDirection,
  setShowDrawerContent,
  setSchedules,
  setBottomDrawerOpened,
  resetGeneral,
  setAllEmployees,
} = generalSlice.actions;

export default generalSlice.reducer;
