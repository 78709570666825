import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MeState {
  currentUserId: number;
}

const initialState: MeState = {
  currentUserId: -1,
};

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setCurrentUserId: (state, action: PayloadAction<number>) => {
      state.currentUserId = action.payload;
    },
    resetMe: state => {
      state.currentUserId = -1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentUserId, resetMe } = meSlice.actions;

export default meSlice.reducer;
