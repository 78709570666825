import * as React from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = (props: ReactDatePickerProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ReactDatePicker {...props} />
);

export default DatePicker;
