import * as React from 'react';
import styled from 'styled-components';

import { SelectOption } from '../types';
import { BreadCrumbs, InputSelect } from '../molecules';
import {
  invoiceDates,
  INVOICE_STATUS,
} from '../constants/forms';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '240px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};

const ActionsPanelInvoices = ({
  onSelectedAdditionalParamsChange,
  isLoading,
}: Props) => {
  const INVOICE_DATES = invoiceDates();

  const [selectedDate, setSelectedDate] = React.useState(
    INVOICE_DATES[2],
  );

  const [
    selectedStatus,
    setSelectedStatus,
  ] = React.useState(INVOICE_STATUS[0]);
  const getTimeRange = (label) => {
    switch (label) {
      case "This month":
        return "this_month"    
      case "Last month":
        return "last_month"    
      case "Last 3 months":
        return "last_three_months"    
      case "Last 6 months":
        return "last_six_months"    
      case "Last 12 months":
        return "last_twelve_months"
      case "Year to date":
        return "year_to_date"    
      default:
        return "last_three_months"
    }
  }

  React.useEffect(() => {
    onSelectedAdditionalParamsChange({
      createdAtDate: `${selectedDate?.value?.fromDate}`,
      isPaid: selectedStatus.value,
      timeRange: getTimeRange(selectedDate?.label)
    });
  }, [selectedDate, selectedStatus]);

  return (
    <Container>
      <Part>
        <BreadCrumbs />
      </Part>
      <Part>
        {onSelectedAdditionalParamsChange && (
          <>
            <InputSelect
              placeholder=""
              isDisabled={isLoading}
              isSearchable={false}
              styles={selectStyles}
              value={selectedStatus}
              options={INVOICE_STATUS}
              onChange={(selected: SelectOption) => {
                return setSelectedStatus(selected);
              }}
            />
            <InputSelect
              placeholder=""
              isDisabled={isLoading}
              isSearchable={false}
              styles={selectStyles}
              value={selectedDate as any}
              options={INVOICE_DATES as any}
              onChange={(selected: SelectOption) =>
                setSelectedDate(selected)
              }
            />
          </>
        )}
      </Part>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
`;

const Part = styled.div`
  display: flex;

  > * {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiTextField-root,
  .MuiInputBase-root {
    border-radius: 5px;
  }

  .MuiButton-root,
  .MuiInputBase-root {
    height: 30px;
  }
`;

interface Props {
  onSelectedAdditionalParamsChange?: (data: any) => void;
  isLoading?: boolean;
}

export default ActionsPanelInvoices;
