import axios from 'axios';
import { api } from './api';
import {
  PaginatedList,
  PaginatedRequest,
  SelectOption,
} from '../types';
import { JurisdictionType } from './jurisdiction';
import { getAccessToken } from '../auth';
import {
  onResponseInterceptor,
  onResponseErrorInterceptor,
} from './interceptors';
import { FranchiseType } from './franchise';

export const getDocumentList = (params: PaginatedRequest) =>
  api.request<DocumentListType>({
    method: 'GET',
    url: '/documents/',
    params,
  });

export const createDocument = (data: FormData) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  return axios.request<DocumentType>({
    method: 'POST',
    url: '/api/v1/documents/',
    data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const saveClientDocuments = (data) => {  
  return api.post(
    '/client_projects/documents/attachments/',
    data,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

export const sendClientDocument = (data: FormData) => {
  return api.patch(
    '/client_projects/documents/attachments/multiple_attachments/',
    data,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}

export const deleteClientDocument = (id) => {
  return api.request({
    method:'DELETE',
    url: `/client_projects/documents/attachments/delete/?id=${id}`
});
}

export const sendReviewComments = (permitActivityId: number,reviewComments: any[]) => {
  //client_review_comments/111111/add_review_comment/
  ///api/v1/client_projects/client_review_comments/111111/add_review_comment/
  return api.request({
    method: "POST",
    url: `/client_projects/client_review_comments/${permitActivityId}/send_review_comment/`,
    data: {reviewer_comment_form: reviewComments}
  })
}

export const updateDocument = (data: FormData) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  return axios.request<DocumentType>({
    method: 'PATCH',
    url: `/api/v1/documents/${data.get('id')}/`,
    data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const deleteDocument = (id: number) =>
  api.request<DocumentType>({
    method: 'DELETE',
    url: `/documents/${id}/`,
  });

type DocumentListType = PaginatedList<DocumentTableType>;

export interface FormSetupType {
  id?: string;
  name: string;
  description: string;
  jurisdictions: Array<number>;
  type: number;
  reportScope?: Array<number>;
  documents?: Array<number>;
  visibleFilename?: string;
  formMarkup: any;
  isDefault: boolean;
}

export interface DocumentType {
  id?: number;
  forms: Array<FormSetupType>;
  requirement: string;
  template: boolean;
  signature: boolean;
  franchise: number[] | FranchiseType[];
  franchiseNames: string;
  documentFile?: Blob | string;
  visibleFilename?: string;
  building: number[] | number | string | SelectOption;
  work: number[] | number | string | SelectOption;
  jurisdiction:
    | number
    | JurisdictionType[]
    | JurisdictionType;
  jurisdictions:
    | number
    | JurisdictionType[]
    | JurisdictionType;
  isDeleted: boolean;
  jurisdictionName: string;
  buildingName: string;
  fromEpsuperadmin: boolean;
  workName: string;
  isDefault?: boolean;
}

export interface DocumentTableType {
  id?: number;
  requirement: string;
  template: boolean;
  signature: boolean;
  franchise: number[];
  franchiseNames: string;
  documentFile?: Blob | string;
  visibleFilename?: string;
  buildingId: number;
  buildingName: string;
  workId: number;
  workName: string;
  jurisdictionId: number;
  jurisdictionName: string;
  fromEpsuperadmin: boolean;
  isDeleted: boolean;
}

export type DocumentFormType = Omit<
  DocumentType,
  'building' | 'work' | 'jurisdictions'
> & {
  jurisdictions: JurisdictionType[];
  building: DocumentSelectType;
  work: WorkSelectType;
};

export interface DocumentSelectType {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  title: string;
  vocabularyEntity: number;
}

export interface WorkSelectType {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  title: string;
  vocabularyEntity: number;
}
