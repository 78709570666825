import React, { FC, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import SwitchCheckbox from '../../../Common/Controls/SwitchCheckbox';
import { Required, RequiredWrapper } from './ProjectControlsArea';

interface ICheckboxType {
  id: number;
  title: string;
  vocabulary_entity?: number;
  is_deleted?: boolean;
  is_active?: boolean;
}

type Props = {
  name: string;
  setValue: Function;
  errors: unknown;
  itemsArr: ICheckboxType[];
  trigger: Function;
  register: Function;
  header: string;
  isRadio?: boolean;
  updateSelectedItem?: Function;
  isRequired?: boolean;
  requiredLocation?: number;
};
const MultiCheckbox: FC<Props> = ({
  name,
  errors,
  setValue,
  itemsArr,
  trigger,
  register,
  header,
  isRadio,
  updateSelectedItem,
  isRequired,
  requiredLocation,
}) => {
  const [typeItems, setTypeItems] = useState<ICheckboxType[]>([]);
  const [initLoad, setInitLoad] = useState<boolean>(true);

  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
      return;
    }
    register(name);
    setValue(name, typeItems);
    const performTrigger = async () => {
      await trigger(name);
    };

    performTrigger();
  }, [typeItems]);

  const onChecked = e => {
    const value = Number(e.target.value);
    if (e.target.checked) {
      const foundItem = typeItems.find(item => item.id === value);
      if (!foundItem) {
        const _items = [...typeItems];
        const _itemFromData = itemsArr.find(item => item.id === value);
        _items.push(_itemFromData);
        setTypeItems(_items);
      }
    } else {
      //uncheck - removing from the list
      const _ids = typeItems.reduce((itemsArr: ICheckboxType[], item: ICheckboxType) => {
        if (item.id !== value) {
          itemsArr.push(item);
        }
        return itemsArr;
      }, []);
      setTypeItems(_ids);
    }
  };

  const onRadioChecked = e => {
    const value = e.target.value;
    if (e.target.checked) {
      const _items = [];
      const _itemFromData = itemsArr.find(item => {
        if (isNaN(value)) {
          return item.title === value;
        }
        return item.id === Number(value);
      });
      _items.push(_itemFromData);
      setTypeItems(_items);
      if (updateSelectedItem) {
        updateSelectedItem(_items);
      }
    }
  };

  const isChecked = item => {
    return typeItems.find(i => i.id === item.id) !== undefined;
  };
  const handleCheck = isRadio ? onRadioChecked : onChecked;
  return (
    <Card error={errors[name]}>
      {isRequired && (
        <RequiredWrapper>
          <Required left={requiredLocation} top={24}>
            (Required)
          </Required>
          <ServiceTypeLabel error={errors[name]}>{header}</ServiceTypeLabel>
        </RequiredWrapper>
      )}
      {errors[name] !== undefined && <ErrorLable>{errors[name].message}</ErrorLable>}
      <BoxesWrapper>
        {itemsArr.map((item, index) => (
          <SwitchCheckbox
            key={index}
            isRadio={isRadio}
            label={item.title}
            disabled={false}
            checked={isChecked(item)}
            name={item.title}
            value={item.title}
            title=""
            onChange={handleCheck}
          />
        ))}
      </BoxesWrapper>
    </Card>
  );
};

export default MultiCheckbox;

export const Card = styled.div<{ error: boolean }>`
  border: ${({ error }) => (error ? '1px solid red' : '1px solid #ccc')};
  margin-top: 26px;
  border-radius: 4px;
`;
export const BoxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 30px;
`;

export const ServiceTypeLabel = styled.div<{ error: boolean }>`
  margin: 20px;
  color: ${({ error }) => (error ? 'red' : '#316B99')};
`;

export const ErrorLable = styled.div`
  color: red;
  padding-left: 20px;
`;
