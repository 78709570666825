import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { RootState } from '../../../redux/root';
import { ButtonMain, Loader } from '../../../atoms';
import FormReportItem from '../../../molecules/FormReportItem';
import { setReportsFormLoader, setShowFormPopup } from '../../../redux/slices/InspectionsReportSlice';
import { useReactToPrint } from 'react-to-print';

const InspectionFormPopup = () => {
  const dispatch = useDispatch();
  const { showFormPopup, records, reportFormLoader } = useSelector((s: RootState) => s.inspectionReports);
  const [selectedReport, setSelectedReport] = useState([]);
  const printRef = useRef<HTMLDivElement>();

  const handlePrintDailyTable = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    dispatch(setReportsFormLoader(true));
  }, []);
  return (
    <>
      {showFormPopup && (
        <Backdrop>
          <PopupWrapper>
            <Buttons>
              <ButtonMain variant="outlined" onClick={handlePrintDailyTable}>
                PRINT
              </ButtonMain>
              <HighlightOffIcon onClick={() => dispatch(setShowFormPopup(false))} />
            </Buttons>
            <Recrods>
              {reportFormLoader && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              {records.length === 0 && !reportFormLoader && <InfoText>No project was found with selected Jurisdiction.</InfoText>}
              {records.map((item, index) => {
                return <FormReportItem form={item} setSelectedFormsReports={setSelectedReport} selectedFormsReports={selectedReport} />;
              })}
              <PrintPad ref={printRef}>
                {selectedReport.sort().map((item, index) => {
                  if (selectedReport.indexOf(item) > -1) {
                    return (
                      <FormReportItem
                        key={index}
                        form={records[item]}
                        setSelectedFormsReports={setSelectedReport}
                        selectedFormsReports={selectedReport}
                      />
                    );
                  } else {
                    return <div></div>;
                  }
                })}
              </PrintPad>
            </Recrods>
          </PopupWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default InspectionFormPopup;

const InfoText = styled.div`
  font-size: 24px;
  color: var(--mainText);
  text-align: center;
  margin-top: 61px;
`;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.6);
  z-index: 3;
  top: 0px;
  left: 0;
`;

const PrintPad = styled.div`
  display: none;
  @media print {
    display: flex;
    flex-direction: column;
  }
`;

const PopupWrapper = styled.div`
  width: 95%;
  padding: 10px;
  margin: 2%;
  /* height: 750px; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border: 3px solid grey;
  border-radius: 6px;
  box-shadow: 5px 5px 0 1px hsla(0, 0%, 0%, 0.3);
  p {
    margin: 15px 80px;
    line-height: 30px;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 18px;
  svg {
    zoom: 1.5;
  }
`;

const Recrods = styled.div`
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  border: 1px solid grey;
`;

const PaginationWrapper = styled.div`
  height: 67px;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  text-align: center;
`;
