import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import ModalActionDialog from '../../molecules/ModalActionDialog';
import ModalInfo from '../../molecules/ModalInfo';
import TableWithDynamicCell from '../../organisms/TableWithDynamicCell';
import { franchiseInfoSelector } from '../../redux/ducks/franchiseSettings';

import {
  getFranchiseUsersList,
  updateFranchiseRelations,
  deleteFranchiseUsers,
} from '../../api/franchise';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableFranchiseBoolean } from '../../utils/convertTableValues';
import useTableWithDynamicCell from '../../hooks/useTableWithDynamicCell';

const FranchiseConfigurationUsersPage = () => {
  const {
    setSearch,
    handleAdd,
    isModalOpen,
    handleCloseModal,
    tableManuallyUpdate,
    search,
    setTargetId,
    modalSuccess,
    handleListChange,
    handleCancelUpdate,
    updateDataRequest,
    modalError,
    handleCloseModalInfo,
    updateTable,
    modalErrorTitle,
  } = useTableWithDynamicCell<any>({
    deleteAllEndpoint: deleteFranchiseUsers,
    updateEndpoint: updateFranchiseRelations,
    updateTarget: 'connectedUsers',
  });

  const { id, name } = useSelector(franchiseInfoSelector);

  React.useEffect(() => {
    setTargetId(id);
  }, []);

  const columns = column => {
    if (['id'].includes(column)) {
      return null;
    }

    return tableColumnsFormatter(column);
  };

  const handleCloseErrorModal = () => {
    handleCloseModal();
    updateTable();
    handleCloseModalInfo();
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        buttonTitle="Save"
        onAdd={handleAdd}
        onSearch={setSearch}
        isActionPanelMoreVisible={false}
      />
      <StyledTitle>
        {' '}
        Configure <span>{name}</span> Branch
      </StyledTitle>

      <Container>
        <TableWithDynamicCell
          customSearch
          searchQuery={search}
          rows={getFranchiseUsersList}
          additionalRequestParams={{ franchiseId: id }}
          columns={columns}
          manuallyUpdate={tableManuallyUpdate}
          handleListChanged={handleListChange}
          onSelectRowHeader="Change status"
          endPositionOfSelectionColumn
          formatValue={convertTableFranchiseBoolean}
          bindingCheckboxesTo={{
            field: 'status',
            value: 'link',
          }}
          initialSortBy={[{ id: 'status', desc: true }]}
        />
      </Container>

      {isModalOpen && (
        <ModalActionDialog
          title="Do you want to save changes?"
          onCancel={handleCloseModal}
          actionFirst={updateDataRequest}
          actionSecond={handleCancelUpdate}
        >
          <p>
            If you don’t save the document, all changes will
            be lost!
          </p>
        </ModalActionDialog>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseErrorModal}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
  width: 50%;
`;

const StyledTitle = styled.p`
  margin-bottom: 0;

  span {
    font-weight: bold;
  }
`;

export default FranchiseConfigurationUsersPage;
