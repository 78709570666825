import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { hex2rgba } from '../utils';

const ButtonInverted = styled(Button).attrs({
  variant: 'outlined',
})`
  border-color: var(--primary);
  background-color: var(--defaultBackground);
  color: var(--primary);
  text-transform: uppercase;
  letter-spacing: 1.25px;
  opacity: 0.9;
  border-radius: 4px;
  width: 112px;

  &:hover {
    background: ${props =>
      hex2rgba(props.theme.primary, 0.1)};
  }
`;

export default ButtonInverted;
