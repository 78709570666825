import * as React from 'react';
import styled from 'styled-components';
import { LibraryBooks } from '@material-ui/icons';
import useTablePage from '../../hooks/useTablePage';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getFormSetupList,
  createFormSetup,
  editFormSetup,
  deleteFormSetup,
} from '../../api/formSetup';
import ActionsPanel from '../../organisms/ActionsPanel';
import Table from '../../organisms/Table';
import { convertTableBoolean } from '../../utils/convertTableValues';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import FormSetupForm from '../../forms/FormSetupForm';
import { getJurisdictions } from '../../api/jurisdiction';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import EditFormSetupPlaceholders from '../../organisms/EditFormSetupPlaceholders';

const FormSetupPage = () => {
  const {
    search,
    setSearch,
    isModalOpen,
    updateTable,
    modalTitle,
    values,
    errors,
    modalErrorTitle,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    selectedItem,
    setSelectedItem,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    // handleCloseModalInfo,
  } = useTablePage({
    createEndpoint: createFormSetup,
    updateEndpoint: editFormSetup,
    deleteEndpoint: deleteFormSetup,
    modalCreateTitle: 'Add New Form',
  });

  const [
    editPlaceholdersData,
    setEditPlaceholdersData,
  ] = React.useState(null);

  const [
    selectedFontSize,
    setSelectedFontSize,
  ] = React.useState(12);

  const modifiedGetFormSetupRequest = params =>
    Promise.all([
      getFormSetupList(params),
      getJurisdictions({ ...GET_ALL_QUERY_PARAMS }),
    ]).then(
      ([formSetupResponse, jurisdictionsResponse]) => {
        return {
          ...formSetupResponse,
          data: {
            ...formSetupResponse.data,
            results: formSetupResponse.data.results.map(
              form => ({
                ...form,
                jurisdiction: jurisdictionsResponse.data.results
                  ?.filter(jur =>
                    form.jurisdictions.includes(jur.id),
                  )
                  .map(el => el.name)
                  .join(', '),
              }),
            ),
          },
        };
      },
    );

  const columns = column => {
    if (
      [
        'reportScope',
        'type',
        'documents',
        'formMarkup',
        'file',
        'jurisdictions',
        'isDeleted',
        'fontSize',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Form Name';
      case 'isDefault':
        return 'Default';
      case 'visibleFilename':
        return 'Path';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'id',
    'name',
    'description',
    'jurisdiction',
    'isDefault',
    'file',
  ];

  const downloadFile = row => {
    if (row.file) {
      window.open(row.file);
    }
  };

  const additionalActions = row => {
    return [
      {
        title: 'Edit Placeholders',
        handler: () => setEditPlaceholdersData(row),
        Icon: LibraryBooks,
      },
    ];
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={handleAdd}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          columnsOrder={columnsOrder}
          rows={modifiedGetFormSetupRequest}
          onEdit={handleEdit}
          onDelete={handleDelete}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
          cellCallbackFnIsOn={['visibleFilename']}
          handleCellClick={downloadFile}
          additionalActions={additionalActions}
        />
      </Container>
      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}
      <EditFormSetupPlaceholders
        data={editPlaceholdersData}
        setData={setEditPlaceholdersData}
        updateTable={updateTable}
        setSelectedFontSize={setSelectedFontSize}
        selectedFontSize={selectedFontSize}
      />
      {isModalOpen && (
        <StyledModalDraggable
          onClose={handleCloseModal}
          title={modalTitle}
        >
          <FormSetupForm
            onSubmit={createOrUpdate}
            initialValues={values}
            initialErrors={errors}
            modalError={modalErrorTitle.length > 0}
            setSelectedFontSize={setSelectedFontSize}
            selectedFontSize={selectedFontSize}
          />
        </StyledModalDraggable>
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default FormSetupPage;
