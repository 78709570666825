import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { ROUTES } from '../../constants/routes';
import { SubMenuLink } from '../../constants/menu';
import { Loader } from '../../atoms';
import isRoutePathExact from '../../utils/isRoutePathExact';
import { getVocabulariesList } from '../../api/vocabulary';
import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import SubMenuHeadLine from '../../organisms/SubMenuHeadLine';
import VocabularyTable from '../../organisms/Vocabulary/VocabularyTable';
import convertObjToRouteObj from '../../utils/convertObjToRouteObj';

const VocabularyPage = () => {
  const { pathname } = useLocation();
  const { push }: History = useHistory();

  const [routes, setRoutes] = React.useState<SubMenuLink[]>(
    null,
  );

  const [vocabulary, setVocabulary] = React.useState<
    SubMenuLink
  >(null);

  React.useEffect(() => {
    getVocabulariesList()
      .then(response => {
        setRoutes(
          convertObjToRouteObj(response.data.results),
        );
      })
      // eslint-disable-next-line
      .catch(e => console.error(e));
  }, []);

  React.useEffect(() => {
    if (routes && ROUTES.SETTINGS_VOCABULARY === pathname) {
      setVocabulary(routes[0]);
      return push(`${pathname}/${routes[0].linkTo}`);
    }
    if (
      routes &&
      isRoutePathExact(pathname, ROUTES.SETTINGS_VOCABULARY)
    ) {
      const pathArr = pathname.split('/');
      const searchPathObj = routes.find(
        el => el.linkTo === pathArr[pathArr.length - 1],
      );
      return setVocabulary(searchPathObj);
    }
    return setVocabulary(null);
  }, [routes, pathname]);

  return (
    <DashboardLayout>
      {routes && vocabulary ? (
        <>
          <ActionsPanel />
          <StyledSubMenuHeadLine
            menuLinks={routes}
            basePath={ROUTES.SETTINGS_VOCABULARY}
          />
          <Container>
            <VocabularyTable activitiesQuery={vocabulary} />
          </Container>
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSubMenuHeadLine = styled(SubMenuHeadLine)`
  padding: 16px 0;
  zoom: 0.9;
`;

export default VocabularyPage;
