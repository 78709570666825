import React, { useRef, useState } from 'react';
import { Cell } from './TicketsItems';
import { Wrapper, HeaderCell, ItemsContainer } from '../Fees/FeesItems';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../../constants/menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { format } from 'date-fns';
import CommentBox from './CommentBox';
import TicketMobileCommentRow from './TicketMobileCommentRow';

const TicketComments = () => {
  const [scrollOn, setScrollOn] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>();
  const comments = useSelector((s: RootState) => s.tickets.comments);

  return (
    <Wrapper>
      <CommentBox />

      <Header>Conversation Summery</Header>
      <SingleRow isOdd={true} scrollOn={scrollOn}>
        <HeaderCell>Conversation</HeaderCell>
        <HeaderCell>Sender</HeaderCell>
        <HeaderCell>Received</HeaderCell>
      </SingleRow>
      <ItemsContainer ref={scrollRef}>
        {comments.length === 0 && <NoComments>No Comments.</NoComments>}
        {comments.map((ticket, index) => (
          <>
            <TicketMobileCommentRow isOdd={index % 2 > 0} key={index} ticket={ticket} />
            <SingleRow isOdd={index % 2 > 0} key={index}>
              <Cell>{ticket.text}</Cell>
              <Cell>{ticket.creatorUsername}</Cell>
              <Cell>{format(new Date(ticket.createdAt), 'MM/dd/yy')}</Cell>
            </SingleRow>
          </>
        ))}
      </ItemsContainer>
    </Wrapper>
  );
};

export default TicketComments;

const NoComments = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: ${Logo_Sky};
  width: 100%;
  text-align: center;
  padding-top: 30px;
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: ${Logo_Sky};
  padding-bottom: 8px;
  @media only screen and (max-width: 840px) {
    font-size: 16px;
  }
`;

const SingleRow = styled.div<{ isOdd: boolean; scrollOn?: boolean }>`
  display: grid;
  grid-template-columns: ${({ scrollOn }) => (scrollOn ? '1fr 1fr 137px' : '1fr 1fr 120px')};
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  color: ${Logo_Sky};
  background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};

  @media only screen and (max-width: 840px) {
    grid-template-columns: repeat(3, 1fr);
    display: none;
  }
  @media only screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
    display: none;
  }
  :hover {
    background-color: #efefef;
  }
`;
