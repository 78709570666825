import * as React from 'react';
import styled from 'styled-components';

import {
    getPaidFeesReport,
  ReportTableType,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const PaidFeesReport = React.forwardRef(
  (
    {
        fromDate,toDate, projectManager,
      setIsValidForExport,
    }: ReportTableType,
    ref,
  ) => {
    const [
      isNullReport,
      setIsNullReport,
    ] = React.useState(false);
    const [additionalRowData, setAdditionalRowData] = React.useState([])    

    const data = {
      from_date: fromDate, to_date: toDate, manager_id: projectManager.id
    };
    const getModifiedPaidFeesReport = () =>
      Promise.all([getPaidFeesReport(data)])
        .then(([reports]) => {                                             
          if (reports.data?.length === 0) {
            setIsNullReport(true);
            setIsValidForExport(false);
          } else {
            setIsNullReport(false);
            setIsValidForExport(true);
        }
        const permitDetailsArr = []
       
        let grandTotal = 0 
          reports.data.map((proj, projIdx) => {
            proj.permitDetails.map((prmt, prmtIdx) => {
                prmt.feeDetails.map((fee, feeIdx) => {                    
                   grandTotal += fee.amountPaid
                    permitDetailsArr.push({projectNamePermitName: `${proj?.projectName} | ${prmt?.permitName}`,
                    permitNumber: prmt?.permitNumber,
                    feeType: fee?.feeName,
                    datePaid: formatDateTime(fee?.datePaid, DATETIME_FORMAT),
                    projectManager: prmt?.managerName,
                    amountPaid: fee?.amountPaid,
                    comment: fee?.comments ? <div
                    dangerouslySetInnerHTML={{__html: fee.comments}}
                  /> : ""
                })
                })
            })
          })
          
          setAdditionalRowData([{title: "Total Permits", value: permitDetailsArr.length}, {title: "Total Amount", value: grandTotal.toFixed(2)}])          
          return {
              ...reports,
              data: {
                  ...reports.data,
                  results: permitDetailsArr
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});
        

    const columns = column => {
      if (
        [
          'franchiseName',
          'projectAddress',
          'totalFees',
          'permitDetails',
          'inspectorName',
          'lastComment',
          'projectName',
          'permitName',
        ].includes(column)
      ) {
        return null;
      }

      switch (column) {
        case 'permitNumber':
          return 'Permit Number';
        case 'projectManager':
          return 'Project Manager';
        case 'feeType':
          return 'Fee Type';
        case 'datePaid':
            return 'Date Paid';
        case 'amountPaid':
            return 'Amount Paid';
        case 'comment':
            return 'Comment';
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';

        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
        'projectNamePermitName',
        'permitNumber',
        'projectManager',
      'feeType',
      'datePaid',
      'amountPaid',
      'information'
    ];

    if (isNullReport) {
      return (
        <InfoText>
          No project was found with selected Project Manager and Date Range.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Paid Fees Report</Title>
        <List>
          <li>
            <Bold>Paid Date From: </Bold> {fromDate} <Bold>To: </Bold> {toDate}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedPaidFeesReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={null}
        //   additionalFeesTotalRow={totalFeesData}
          additionalBottomRow={additionalRowData}
        />
      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default PaidFeesReport;
