import { api } from './api';
import { PaginatedList, PaginatedRequest } from '../types';

export const getPermits = (params: PaginatedRequest) =>
  api.request<PermitsList>({
    method: 'GET',
    url: '/projects/permits/',
    params,
  });

export const getProjectPermits = (projectId) =>
  api.request({
    method: 'GET',
    url: `/projects/project/permit_details/?project_id=${projectId}`,
  });

export const getPermitNames = (review_report = false) => {
  const body = { review_report }
  return api.request<PermitsList>({
    method: 'POST',
    url: 'projects/project/permits/',
    data: body
  });
}


export const getPermitStatuses = () =>
  api.request<PermitsList>({
    method: 'GET',
    url: '/projects/permits/statuses/',
  });

export const getPermitByID = (id: number) =>
  api.request<PermitType>({
    method: 'GET',
    url: `/projects/permits/${id}/`,
  });

export const getPermitActivitiesInspectionStatus = (id: number) =>
  api.request<PermitType>({
    method: 'GET',
    url: `/projects/permit/status/?permit_id=${id}`,
  });

export const createPermit = (data: PermitType) =>
  api.request<PermitType>({
    method: 'POST',
    url: '/projects/permits/',
    data,
  });

export const updatePermit = (data: PermitType) =>
  api.request<PermitType>({
    method: 'PATCH',
    url: `/projects/permits/${data.id}/`,
    data,
  });

export const deletePermit = (id: number) =>
  api.request<PermitType>({
    method: 'DELETE',
    url: `/projects/permits/${id}/`,
  });

type PermitsList = PaginatedList<PermitType>;

export interface PermitType {
  id?: number;
  name: string;
  description: string;
  contractor?: number;
  building?: number;
  numberOfInspections?: number;
  amount: string;
  serviceType: any[];
  work: any | number;
  project?: number;
  number?: number;
  status?: number;
}
