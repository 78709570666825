import * as R from 'ramda';

import history from './history';
import { ROUTES } from './constants/routes';
import { objectKeysToCamelCase } from './utils';
import apiUserAuth, { UserType } from './api/userAuth';

export const ACCESS = 'accessToken';
const REFRESH = 'refreshToken';
const ACCESS_EXPIRES_IN = 'accessExpiresIn';
const REFRESH_EXPIRES_IN = 'refreshExpiresIn';
const LAST_TOKEN_REFRESHMENT = 'lastTokenRefreshment';
const USER_INFO = 'userInfo';
export const FRANCHISE_INFO = 'franchiseInfo';

export function parseJwt(token: string): ParsedToken {
  const base64Url = token.split('.')[1];
  const base64 = base64Url
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(
        c =>
          `%${`00${c.charCodeAt(0).toString(16)}`.slice(
            -2,
          )}`,
      )
      .join(''),
  );

  const info = JSON.parse(jsonPayload);

  return objectKeysToCamelCase(info);
}

export const cleanOutUser = () => {
  localStorage.removeItem(USER_INFO);
  localStorage.removeItem(FRANCHISE_INFO);
};

export const cleanOutTokens = () => {
  localStorage.removeItem(ACCESS);
  localStorage.removeItem(REFRESH);
  localStorage.removeItem(ACCESS_EXPIRES_IN);
  localStorage.removeItem(REFRESH_EXPIRES_IN);
  localStorage.removeItem(LAST_TOKEN_REFRESHMENT);
};

export function getExpiredIn(token: string): number {
  const { exp } = parseJwt(token);

  return exp * 1000;
}

const isTokenAlive = (
  tokenExpiresIn: number | void,
): boolean => tokenExpiresIn && Date.now() < tokenExpiresIn;

export const getAccessToken = (): string | void =>
  localStorage.getItem(ACCESS);

export const getRefreshToken = (): string | void =>
  localStorage.getItem(REFRESH);

export const getAccessExpiresIn = (): number | void =>
  +localStorage.getItem(ACCESS_EXPIRES_IN);

export const getRefreshExpiresIn = (): number | void =>
  +localStorage.getItem(REFRESH_EXPIRES_IN);

export const getStoredUserInfo = (): UserType | void => {
  try {
    const info = localStorage.getItem(USER_INFO);
    if (!info) {
      return null;
    }

    return JSON.parse(info);
  } catch (e) {
    return null;
  }
};

export const setTokenData = (
  access: string,
  refresh: string,
) => {
  localStorage.setItem(ACCESS, access);
  localStorage.setItem(REFRESH, refresh);
  localStorage.setItem(
    LAST_TOKEN_REFRESHMENT,
    String(Date.now()),
  );
  localStorage.setItem(
    ACCESS_EXPIRES_IN,
    String(getExpiredIn(access)),
  );
  localStorage.setItem(
    REFRESH_EXPIRES_IN,
    String(getExpiredIn(refresh)),
  );
};

export const setUserData = (userInfo: UserType) => {
  const _existUser = localStorage.getItem(USER_INFO);
  if(_existUser){
    const existingUser = JSON.parse(_existUser);
    localStorage.setItem(USER_INFO, JSON.stringify({...userInfo,selectedFranchise:existingUser.selectedFranchise}));
  } else {
    localStorage.setItem(USER_INFO, JSON.stringify({...userInfo,selectedFranchise:userInfo.defaultFranchiseId}));
  }
  
};

export const setAuthData = (
  access: string,
  refresh: string,
  userInfo: UserType,
) => {
  setUserData(userInfo);
  setTokenData(access, refresh);
};

export const isTokensInStore = () =>
  !!getAccessToken() && !!getRefreshToken();

export const accessTokenAlive = () =>
  isTokenAlive(getAccessExpiresIn());

export const refreshTokenAlive = () =>
  isTokenAlive(getRefreshExpiresIn());

export const accessTokenExpired = R.complement(
  accessTokenAlive,
);

export const refreshTokenExpired = R.complement(
  refreshTokenAlive,
);

export async function refreshToken(
  refresh: string,
): Promise<string | null> {
  try {
    const { data } = await apiUserAuth.refreshToken(
      refresh,
    );

    const newAccess = data.access;

    setTokenData(newAccess, refresh);

    return newAccess;
  } catch (e) {
    return null;
  }
}

const serverLogout = async () => {
  try {
    await apiUserAuth.userLogout();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const logOut = async () => {
  await serverLogout();
  cleanOutTokens();
  cleanOutUser();

  history.push(ROUTES.LOG_IN);
};

export const logOutWithHistory = async () => {
  cleanOutTokens();
  cleanOutUser();

  history.push(ROUTES.LOG_IN, {
    from: history.location,
  });
};

export const verifyUserAuth = () => {
  const verify = () => {
    const hasTokens = isTokensInStore();

    if (hasTokens && refreshTokenExpired()) {
      logOutWithHistory();
    }
  };

  verify();

  history.listen(location => {
    if (location.pathname !== ROUTES.LOG_IN) {
      verify();
    }
  });
};

interface ParsedToken {
  userId: number;
  username: string;
  exp: number;
  email: string;
  origIat: number;
}
