import React from 'react'
import styled from 'styled-components';

const ReportsTable = ({ from, to, reimbursables, notReimbursables, types, total }) => {
  
  return (
    <TableContainer>
      {reimbursables !== null && reimbursables !== undefined && <tr>
        
      {to ? (
          <StyledTableData>
            Paid Fees from {from} to {to}
          </StyledTableData>
        ) : (
          <StyledTableData>Paid Fees for {from}</StyledTableData>
        )}
        <StyledTableData>Reimbursables</StyledTableData>
        <StyledTableData>{reimbursables}</StyledTableData>
      </tr>}
      <tr>
      {to ? (
          <StyledTableData>
            Paid Fees from {from} to {to}
          </StyledTableData>
        ) : (
          <StyledTableData>Paid Fees for {from}</StyledTableData>
        )}
        <StyledTableData>Amount without Reimbursables</StyledTableData>
        <StyledTableData>{notReimbursables}</StyledTableData>
      </tr>
      {Object.keys(types)?.map((el) => (
        <tr>
          <StyledTableData>{el} Total</StyledTableData>
          <StyledTableData>{types[el]}</StyledTableData>
        </tr>
      ))}

      <tr>
        <StyledTableData></StyledTableData>
        <StyledTableData>Grand Total</StyledTableData>
        <StyledTableData>{total}</StyledTableData>
      </tr>
    </TableContainer>
  )
}

const TableContainer = styled.table`
width: 85%;
border: 1px solid #000;
border-collapse: collapse;
`;

const StyledTableData = styled.td`
text-align: center;
border: 1px solid #000;
`;


export default ReportsTable