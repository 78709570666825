import * as React from 'react';
import styled from 'styled-components';
import { ListItem } from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';

const CollapsableListItem = ({
  label,
  padding,
  parents,
  value,
  setActiveFields,
  activeFields,
}: Props) => {
  const [added, setAdded] = React.useState(false);
  const [addedIndex, setAddedIndex] = React.useState(null);

  React.useEffect(() => {
    if (activeFields.length) {
      const placedItem = activeFields?.find((el, index) => {
        const isEqualParents = el?.parents?.every(
          (parent, idx) => parent === parents[idx],
        );
        if (el?.value === value && isEqualParents) {
          setAddedIndex(index);
        }
        return el?.value === value && isEqualParents;
      });
      if (placedItem) {
        setAdded(true);
      }
    } else {
      setAdded(false);
    }
  }, [activeFields]);

  const deselectItem = (index, selectedArray) => {
    const newStateSelected = [...selectedArray];
    newStateSelected.splice(index, 1);
    setActiveFields(newStateSelected);
    setAdded(false);
  };

  return (
    <ItemWrapper
      padding={padding}
      onClick={
        added
          ? () => deselectItem(addedIndex, activeFields)
          : () =>
              setActiveFields([
                ...activeFields,
                {
                  parents,
                  value,
                  x: 100,
                  y: 100,
                  width: 400,
                  height: 30,
                },
              ])
      }
    >
      <ListItem button>
        {label
          .replace(/([A-Z][a-z])/g, ' $1')
          .replace(/(\d)/g, ' $1')}
        <Icon added={added}>
          {added ? <DeleteOutline /> : <Add />}
        </Icon>
      </ListItem>
    </ItemWrapper>
  );
};

interface Props {
  label: string;
  padding: string;
  value: string;
  parents: Array<string>;
  activeFields: Array<any>;
  setActiveFields: (data: any) => void;
}

interface Wrapper {
  padding: string;
}

interface IconProps {
  added: boolean;
}

const ItemWrapper = styled.div<Wrapper>`
  text-transform: capitalize;
  padding-left: ${({ padding }) => padding};
`;

const Icon = styled.div`
  margin-left: 10px;
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
    ${({ added }: IconProps) => (added ? 'fill: red;' : '')}
  }
`;

export default CollapsableListItem;
