import React, { useEffect, useState } from 'react';
import PopupMessage from './PopupMessage';
import { Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { closeInspection, getFieldsForInspection, persistInspectionData, updateInspectionFields } from '../../Utils';
import styled from 'styled-components';
import { Loader, SuccessCheckmark } from '../../../../../atoms';
import { sendInspectionMessage, updatePermitInspection } from '../../../../../api/inspections';
import { setPermitInspectionsList } from '../../../../../redux/slices/inspectionsSlice';
import { InspectionItemType } from '../../../../../modules/clientModule/types';

const InspectionSaving = ({ inspectionReport, reportPdf, pdfObject }) => {
  const { formData, selectedInspection, pdfReport,permitInspectionsList } = useSelector((s: RootState) => s.inspections);
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    const load = async () => {
      if (reportPdf) {
        const payload = getFieldsForInspection(selectedInspection, formData) 
        //Updating the inspection fields
        
        const result = await updatePermitInspection({...payload/*,report:null*/});
        await persistInspectionData(payload, reportPdf);
        const refreshList = permitInspectionsList.reduce((inspArr,item)=>{
          if(item.id !== result.data.id){
            inspArr.push(item);
          }
          return inspArr;
        },[]);
        dispatch(setPermitInspectionsList(refreshList));
        

        //sending SMS
        const smsPayload = {
          address: selectedInspection.projectAddress,
          inspection_id: selectedInspection.id,
          inspection_status: formData.activityStatus.name,
          inspector_id: selectedInspection.inspector,
          phone_number: selectedInspection.cellPhone,
          information: formData.information,
          report_status: 'Attached',
        };
        const SMS = sendInspectionMessage(smsPayload);
        //3.  POST  /api/v1/inspections/trigger-message
      }
      setShowSuccess(true);
    };
    load();
  }, []);
  const dispatch = useDispatch();
 
  const saveReport = (event: any): void => {
    if (pdfObject) {
      pdfObject.save(pdfReport);
    }
  };

  return (
    <PopupMessage caption="Saving">
      {!showSuccess && (
        <MessageLine>
          <Message>Saving...</Message>
          <div>
            <Loader />
          </div>
        </MessageLine>
      )}
      {showSuccess && (
        <>
          <MessageLine>
            <Message>Success !!!</Message>
            <CheckWrapper>
              <SuccessCheckmark />
            </CheckWrapper>
          </MessageLine>
          {reportPdf && (
            <PrintLine>
              <MessageLink onClick={saveReport}>Print Report </MessageLink>
              <PrintIconWrapper>
                <PrintIcon onClick={saveReport} />
              </PrintIconWrapper>
            </PrintLine>
          )}
        </>
      )}
      <Buttons>
        <Button
          disabled={!showSuccess}
          variant="contained"
          color="primary"
          style={{ width: '200px' }}
          onClick={() => closeInspection(dispatch)}
        >
          Close
        </Button>
      </Buttons>
    </PopupMessage>
  );
};

export default InspectionSaving;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
`;

const MessageLine = styled.div`
  display: flex;
  margin: 0 220px;
  font-size: 25px;
`;

const PrintLine = styled.div`
  display: flex;
  margin: 0 220px;
  font-size: 20px;
`;

const Message = styled.div`
  flex-grow: 1;
`;

const CheckWrapper = styled.div`
  zoom: 0.4;
`;

const MessageLink = styled.div`
  text-decoration: underline;
  color: blue;
  flex-grow: 1;
`;

const PrintIconWrapper = styled.div`
  color: var(--primary);
  svg {
    zoom: 1.8;
  }
`;
