import React, { useState } from 'react';
import styled from 'styled-components';
import { SearchBar } from '../../../molecules';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { setGlobalSearch } from '../../../redux/slices/filterSlice';

const DailyInspectionsSearchBox = () => {
  const dispatch = useDispatch();
  const globalFilter = useSelector((s: RootState) => s.filters.globalSearch);
  return (
    <SearchContainer>
      <SearchBar text={globalFilter} onChange={e => dispatch(setGlobalSearch(e))} />
    </SearchContainer>
  );
};

export default DailyInspectionsSearchBox;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 0 30px 0;
  justify-content: flex-end;
`;
