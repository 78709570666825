import { api } from './api';

import {
  PaginatedList,
  PaginatedRequest,
  SelectOption,
} from '../types';
import { FranchiseType } from './franchise';

export const getReportsTypes = (params: PaginatedRequest) =>
  api.request<ReportTypeList>({
    method: 'GET',
    url: '/reports/',
    params,
  });

export const createReportsType = (data: ReportType) =>
  api.request<ReportType>({
    method: 'POST',
    url: '/reports/',
    data,
  });

export const updateReportsType = (data: ReportType) =>
  api.request<ReportType>({
    method: 'PATCH',
    url: `/reports/${data.id}/`,
    data,
  });

export const deleteReportsType = (id: number) =>
  api.request<ReportType>({
    method: 'DELETE',
    url: `/reports/${id}/`,
  });

type ReportTypeList = PaginatedList<ReportType>;

export interface ReportType {
  id: number;
  name: string | SelectOption;
  isDeleted: boolean;
  description: string;
  franchise: number[] | FranchiseType[];
  franchiseNames: string;
  isFranchiseAll: boolean;
  userModule: number | SelectOption;
  userModuleReadable: string | SelectOption;
}
