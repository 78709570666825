import * as React from 'react';
import styled from 'styled-components';

import { ButtonMain } from '../atoms';
import ModalDefaultDraggable from './ModalDefaultDraggable';

const ModalDeleteConfirmation = ({
  onConfirm,
  onCancel,
  children,
}: Props) => (
  <ModalDefaultDraggable
    title="Are you sure you want to delete?"
    onClose={onCancel}
  >
    <Container>
      {children && <div>{children}</div>}
      <ButtonContainer>
        <ButtonMain color="secondary" onClick={onConfirm}>
          Yes, delete
        </ButtonMain>
        <ButtonMain color="default" onClick={onCancel}>
          No
        </ButtonMain>
      </ButtonContainer>
    </Container>
  </ModalDefaultDraggable>
);

const Container = styled.div`
  text-align: center;

  > div {
    margin: 0 0 40px 0;
    &:last-child {
      margin: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  > .MuiButton-root {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

interface Props {
  children?: React.ReactNode | React.ReactNodeArray;
  onConfirm: () => void;
  onCancel: () => void;
}

export default ModalDeleteConfirmation;
