import * as React from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import { FormSetupFieldType } from '../types';
import findFormSetupField from '../utils/findFormSetupField';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 1px #ddd',
  background: '#f0f0f0',
};

const ResizableField = ({
  label,
  field,
  setActiveFields,
  activeFields,
}: Props) => {
  const replaceItem = (index, selectedArray, newItem) => {
    const newStateSelected = [...selectedArray];
    newStateSelected.splice(index, 1, newItem);
    setActiveFields(newStateSelected);
  };

  const setNewPosition = (e, d) => {
    const {
      currentIndex,
      currentField,
    } = findFormSetupField(
      activeFields,
      field.parents,
      field.value,
    );

    replaceItem(currentIndex, activeFields, {
      ...currentField,
      x: d.x,
      y: d.y,
    });
  };

  const setNewSize = (
    e,
    direction,
    ref,
    delta,
    position,
  ) => {
    const {
      currentIndex,
      currentField,
    } = findFormSetupField(
      activeFields,
      field.parents,
      field.value,
    );
    replaceItem(currentIndex, activeFields, {
      ...currentField,
      x: position.x,
      y: position.y,
      width: parseInt(ref.style.width, 10),
      height: parseInt(ref.style.height, 10),
    });
  };

  return (
    <Rnd
      style={style}
      size={{ width: field.width, height: field.height }}
      position={{ x: +field.x, y: +field.y }}
      onDragStop={setNewPosition}
      onResizeStop={setNewSize}
      bounds="parent"
    >
      <Label>{label}</Label>
    </Rnd>
  );
};

interface Props {
  label: string;
  field: FormSetupFieldType;
  setActiveFields: (data: any) => void;
  activeFields: Array<FormSetupFieldType>;
}

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default ResizableField;
