import { PDFDocument } from 'pdf-lib';

export const handleMerge = async (permitName, urlsData) => {
  const mergedPdfBytes = await mergePDFs(urlsData);
  const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${permitName}-inspections.pdf`;

  return link;
};

const mergePDFs = async pdfArray => {
  const mergedPdf = await PDFDocument.create();

  for (const pdfBytes of pdfArray) {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());

    for (const page of pages) {
      mergedPdf.addPage(page);
    }
  }

  const mergedPdfBytes = await mergedPdf.save();
  return mergedPdfBytes;
};
