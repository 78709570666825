import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import isYupFieldRequired from '../utils/isYupFieldRequired';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import parseUTCDate from '../utils/parseUTCDate';
import formatDate, {
  formatDateTime,
} from '../utils/formatDate';

import { FormServerError } from '../types';

import {
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  InputDatePickerWithLabel,
  ButtonWithLoader,
  CheckboxWithLabel,
  InputCurrencyWithLabel,
  InputFileContainer,
  ModalDefaultDraggable,
} from '../molecules';
import CommentsChat from '../organisms/CommentsChat';

import {
  COMMENTS_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';

import {
  getActivitiesList,
  getActivitiesStatusList,
  getPermitActivityComments,
  createPermitActivityComment,
  createAttachmentCondition,
  getCustomActivities,
  getPermitDocuments,
} from '../api/activities';
import { getPermitUsers, getUserByID, getUsers,getUsersCustom } from '../api/users';
import { getTrades } from '../api/trades';
import { getPermitByID } from '../api/permits';
import { getVocabulariesDataList } from '../api/vocabulary';
import {
  createPermitInspectionsComment,
  getPermitInspectionsComments,
} from '../api/inspections';
import { getProjectByID, getProjects } from '../api/projects';
import InspectionsInfoBlock from '../organisms/InspectionsInfoBlock';
import useDidUpdateEffect from '../hooks/useDidUpdate';
import useUserRole from '../hooks/useUserRole';
import { getCurrentUser } from '../api/userSettings';
import { PERMIT_MENU_ACTIVE_TAB } from '../constants/permitMenu';
import { Chip, IconButton, InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ButtonInverted, ButtonMain, TextInput } from '../atoms';
import { Check } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ModalDefaultDraggableExact from '../molecules/ModalDefaultDraggableExact';
import { useRef } from 'react';

interface EditAttachment {
  id: number;
  index: number;
  name: string;
  extension: string;
  file: string;
}

const labels = {
  activityTitle: 'Activity Title',
  activityStatus: 'Activity Status',
  activityContact: 'Activity Contact',
  assignee: 'Assigned To',
  information: 'Information',
  toDate: 'Follow Up Date',
  emergency: 'Emergency',
  amount: 'Proposal Amount',
  inspector: 'Inspector',
  reviewType: 'Review Type',
  isCompleted: 'Activity completed',
  activityType: 'Activity Type',
  dateCreated: "Date Created",
  createdBy: "Created By",
  dateCompleted: "Date Completed",
  completedBy: "Completed By",
  attachment: "Attachment",
  attachementFileName: "Attachment Name",
  conditionName: "Condition Name"
};

const conditionNameSchema = yup.object().shape({
  conditionName: yup.string().required().typeError('Condtion name should not empty.').label(labels.conditionName)
})

const schema = yup.object().shape({
  activityType: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Activity Type from the list')
    .label(labels.activityType),
  activityTitle: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Activity Title from the list')
    .label(labels.activityTitle),
  activityStatus: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Activity Status from the list')
    .label(labels.activityStatus),
  activityContact: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Activity Contact from the list')
    .label(labels.activityContact),
  assignee: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Assignee from the list')
    .label(labels.assignee),
  information: yup.string().label(labels.information),
  // conditionName: yup.string().typeError('Condtion name should not empty.').label(labels.conditionName),
  // conditionName: yup.string().when('$conditionsMode', {
  //   is: true,
  //   then: yup.string().typeError('Condtion name should not empty.').label(labels.conditionName),
  //   otherwise:yup.string(),
  // }),
  toDate: yup
    .date()
    .required()
    .typeError('Invalid or absent date')
    .label(labels.toDate),
  emergency: yup
    .boolean()
    .required()
    .label(labels.emergency),
  isCompleted: yup.boolean().label(labels.isCompleted),
  amount: yup
    .string()
    .label(labels.amount)
    .nullable()
    .typeError('Invalid amount value'),
  inspector: yup
    .object()
    .when('activityTitle', {
      is: activityTitle =>
        activityTitle?.activityType === 7,
      then: yup
        .object().nullable()
    })
    .nullable(),
  reviewType: yup
    .array()
    .when('activityTitle', {
      is: activityTitle =>
        activityTitle?.activityType === 7,
      then: yup
        .array()
        .required()
        .typeError('Select Review Type from the list'),
    })
    .nullable(),
  documentFile: yup.mixed().nullable(),
  attachment: yup
    .string()
    .label(labels.amount)
    .nullable()
    .typeError('Invalid attachment name')
});


const PermitActivitiesForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new Activity',
  updateButtonText = 'Update the Activity',
  initialErrors,
  permit,
  setActivityCreated,
  conditionsMode,
  isReadOnly,
  cameFromSearch,
  selectedPermit,
  activeTab
}: Props) => {

  const toDateUTC = parseUTCDate(
    initialValues?.toDate as string,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );
  const [isReviewDocumentsModalOpen, setIsReviewDocumentsModalOpen] = React.useState<boolean>(
    false,
  );
  const [documents, setDocuments] = React.useState(null);
  const scrollRef = useRef<HTMLDivElement>();

  const {
    handleSubmit,
    setError,
    errors,
    register,
    control,
    formState,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues
      ? {
          ...initialValues,
          toDate: toDateUTC,
        }
      : {},
  });

  const selectedType = watch('activityType');
  const selectedTitle = watch('activityTitle');
  const selectedInspector = watch('inspector');
  const selectedSatus = watch('activityStatus')
  const completedStatus = watch('isCompleted');
  const selectedReviewTypes = watch('reviewType');
  
  
  const {
    isSuperAdmin,
    isFranchiseAdmin,
    isEmployee,
    isInspector,
  } = useUserRole();

  React.useEffect(() => {
    if(initialValues && isInspector &&
      (initialValues?.status === "Pending" || initialValues?.status === 130 || initialValues?.statusName === "Pending") &&
      selectedSatus?.name !== "Pending"
      ) {
        let today = new Date()        
        setValue('toDate', today)
    }
  },[selectedSatus])
  

  const [
    activityTypeDisabled,
    setActivityTypeDisabled,
  ] = React.useState(false);
  const [
    activityTypesData,
    setActivityTypesData,
  ] = React.useState([]);

  React.useEffect(() => {
    activityTypesData.map(activity => {
      const activityTypeIds = [7, 9, 10, 11, 12, 13];
      if (initialValues) {
        if (activityTypeIds.includes(selectedType.id)) {
          setActivityTypeDisabled(true);
        }
      }
    });
  }, [selectedType]);

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);
  const [commentCreated, setCommentCreated] = React.useState(null);

  const [
    activityTitlesData,
    setActivityTitlesData,
  ] = React.useState([]);

  const [
    activityStatusData,
    setActivityStatusData,
  ] = React.useState([]);
  const [employeesData, setEmployeesData] = React.useState(
    [],
  );
  const [usersData, setUsersData] = React.useState([]);

  const [
    inspectorsData,
    setInspectorsData,
  ] = React.useState([]);

  const [tradesData, setTradesData] = React.useState(null);

  const [readOnlyInfo, setReadOnlyInfo] = React.useState(
    [],
  );
  
  const [tempInitialValues, setInitialValues] = React.useState(null);
  const [editAttachment, setEditAttachment] = React.useState<EditAttachment>();
  const [tempAttachmentData,setTempAttachmentData] = React.useState([]);
  const [documentStatusData, setDocumentStatusData] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 3,
        ordering: 'title',
      }),
        getVocabulariesDataList({
          ...GET_ALL_QUERY_PARAMS,
          vocabularyEntity: 10,
        })
      
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: USER_TYPES_VALUES.EMPLOYEE,
      }),
      getUsersCustom({
        userRole: USER_TYPES_VALUES.INSPECTOR,
      })
    ])
      .then(
        ([
          activityTypesList,
          documentStatusList,
          employeesList,
          inspectorsList,
        ]) => {
          setDocumentStatusData(documentStatusList?.data.results)
          
          const employees = employeesList.data?.results.filter(
            el => el?.isActive,
          );
          const inspectors = inspectorsList.data?.filter(
            el => el?.isActive,
          );
            
          if (initialValues?.assignedTo) {
            
            const assignee = employees?.find(

              el => {
                
                return typeof initialValues.assignedTo ===

                  'number'

                  ? el.id === initialValues.assignedTo : (`${el.firstName} ${el.lastName}` === initialValues.assignedTo || el?.email === initialValues?.assignedTo)

              },

            );
              setValue('assignee', assignee);
          }

          if (initialValues?.inspector || initialValues?.inspectorEmail) {

            const inspectors = inspectorsList.data?.filter(

              el => el?.isActive,

            );
              
            const initialInspector = inspectors?.find(

              el => el?.id === initialValues.inspector || el?.email === initialValues.inspectorEmail,

            );            
            setValue('inspector', initialInspector);
          }
          
          const types = activityTypesList.data.results?.filter(
            el => {
              if(el?.isActive && el?.title !== 'Inspection'){
                  if(activeTab === PERMIT_MENU_ACTIVE_TAB.ACTIVITIES){
                    if(el?.title !== "Conditions")
                      return el;
                  }else if(conditionsMode){
                    if(el?.title === "Conditions"){       
                      setValue('activityType', el);               
                      return el;
                    }
                  }else{
                    return el;
                  }
              }
            }
          );
          setActivityTypesData(types);
          setEmployeesData(employees);
          setInspectorsData(inspectors);
        },
      )
      .catch(() => {})
      .finally(() => {
        // setCallInitial(true)
        setIsLoading(false);
        if (setActivityCreated) {
          setActivityCreated(false);
        }
      });
  }, []);

  useDidUpdateEffect(() => {
    Promise.all([getPermitUsers({
      user_role: 3,
      permit_id: +initialValues?.permitId || +permit,
    })]).then(([allUsersList]) => {
      const allUsers = allUsersList?.data
      setUsersData(allUsers);
    })
  }, [selectedType])

  React.useEffect(() => {
    if (selectedTitle?.activityType === 7) {
      setIsLoading(true);
      Promise.all([
        getTrades({
          ...GET_ALL_QUERY_PARAMS,
          // inspector: selectedInspector?.id,
        }),
      ])
        .then(([tradesResponse]) => {
          const trades = tradesResponse.data.results?.filter(
            el => el?.isActiveStatus === 'True',
          );
          setTradesData(trades);

          if (
            typeof initialValues?.reviewType === 'string'
          ) {
            if (
              initialValues &&
              trades
                .map(el => el?.name)
                ?.includes(initialValues?.reviewType)
            ) {
              setValue(
                'reviewType',
                [trades.find(
                  el =>
                    el?.name === initialValues.reviewType,
                )],
              );
            } else {
              setValue('reviewType', null);
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (
              initialValues &&
              trades
                .map(el => el?.id)
                ?.includes(initialValues?.reviewType)
            ) {
              setValue(
                'reviewType',
                [trades.find(
                  el => el?.id === initialValues.reviewType,
                )],
              );
            } else {
              setValue('reviewType', null);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedTitle?.activityType === 7]);  

  React.useEffect(() => {
    if (selectedType?.id) {
      setIsLoading(true);
      setValue('activityTitle', '');

      Promise.all([
        getActivitiesList({
          ...GET_ALL_QUERY_PARAMS,
          activityType: selectedType?.id,
        }),
      ])
        .then(([activityTitlesResponse]) => {
          let activitiesTitles = activityTitlesResponse.data.results?.filter(
            el => el?.isActiveStatus === 'True',
          );

          if (
            initialValues?.activityType === selectedType?.id
          ) {
            const initialTitle = activitiesTitles?.find(
              el =>
                el?.name === initialValues?.activityTitleName,
            );
            setValue('activityTitle', initialTitle);
          } else {
            const initialTitle = activitiesTitles?.find(
              el => (el?.name === initialValues?.title) || (el?.name === initialValues?.activityTitleName) || (el?.name === initialValues?.titleName),
            );
            setValue('activityTitle', initialTitle);
          }
          if (selectedType.title === 'Fee') {
            activitiesTitles = activitiesTitles.reduce(
              (acc, item) => {
                if (item.name === 'Invoice') {
                  acc.push({
                    ...item,
                    isDisabled: true,
                  });
                } else {
                  acc.push(item);
                }
                return acc;
              },
              [],
            );
          }
          setActivityTitlesData(activitiesTitles);
        })
        .catch(err => {
          setValue('activityTitle', '');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedType]);

  // React.useEffect(() => {
  //   if (initialValues) {
  //     setIsLoading(true);

  //     setIsLoading(false);
  //   }
  // }, [inspectorsData]);

  React.useEffect(() => {
    if (initialValues) {
      setInitialValues(initialValues);
      setIsLoading(true);
      setValue('documentFile',null);
      Promise.all([
        // getUsers({
        //   ...GET_ALL_QUERY_PARAMS,
        //   userRole: USER_TYPES_VALUES.INSPECTOR,
        // }),
        // getUsers({
        //   ...GET_ALL_QUERY_PARAMS,
        //   userRole: USER_TYPES_VALUES.EMPLOYEE,
        // }),
        // getUsers({
        //   ...GET_ALL_QUERY_PARAMS,
        // }),
        getPermitByID(permit),
        getVocabulariesDataList({
          ...GET_ALL_QUERY_PARAMS,
          vocabularyEntity: 3,
          ordering: 'title',
        }),
        // getProjects({ ...GET_ALL_QUERY_PARAMS }),
      ])
        .then(
          ([
            // inspectorsResult,
            // employeesResult,
            // allUsersResult,
            permitResult,
            activityTypesResult,
            // projectsResponse,
          ]) => {
            // ###########
            Promise.all([getProjectByID(permitResult?.data?.project)]).then(([projectRes]) => {
              
              // setProject(projectRes)


              // if (initialValues.inspector) {
              //   const inspectors = inspectorsResult.data.results?.filter(
              //     el => el?.isActive,
              //   );
              //   const initialInspector = inspectors?.find(
              //     el => el?.id === initialValues.inspector,
              //   );
              //   setValue('inspector', initialInspector);
              // }
  
              if (initialValues.followUp) {
                
                const date = Date.parse(
                  initialValues.followUp,
                );
                setValue('toDate', new Date(date));
              }

              if(initialValues?.contactId || (initialValues?.contact && initialValues?.contact !== " ")){                
                getUserByID(initialValues?.contactId ? initialValues?.contactId : initialValues?.contact).then((contactRes) => setValue('activityContact', contactRes?.data))
              }
  
  
              if (initialValues.activityType) {
                const types = activityTypesResult.data.results?.filter(
                  el => el?.isActive,
                );
                const initialType = types?.find(el => {
                  return typeof initialValues.activityType ===
                    'number'
                    ? el?.id === initialValues.activityType
                    : (el?.title ===
                        initialValues.activityType || el?.title ===
                        initialValues.activityTypeName);
                });
                setValue('activityType', initialType);                
              }
  
              setValue(
                'emergency',
                initialValues?.isEmergency,
              );
             
              if (
                projectRes?.data &&
                permitResult?.data &&
                initialValues
              ) {
                setReadOnlyInfo([
                  {
                    label: 'Project Name',
                    value: projectRes?.data?.name,
                  },
                  {
                    label: 'Permit Name',
                    value: permitResult?.data?.name,
                  },
                  {
                    label: 'Permit Number',
                    value: initialValues?.permitNumber,
                  },
                  {
                    label: 'Permit Address',
                    value: projectRes?.data?.address,
                  },
                  {
                    label: 'Jurisdiction',
                    value: projectRes?.data?.jurisdictionName,
                  },
                  {
                    label: 'Project Manager',
                    value: projectRes?.data?.managerName,
                  },
                ]);
              }

            })

            // ################
            
          },
        )
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [initialValues]);  
  
  React.useEffect(() => {
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 10,
    })
  }, [])

  React.useEffect(() => {
    if (!selectedTitle) {
      setValue('activityStatus', '');
    }
    if (selectedTitle) {
      setIsLoading(true);
      Promise.all([
        getActivitiesStatusList({
          ...GET_ALL_QUERY_PARAMS,
          activity: selectedTitle.id,
        }),
      ])
        .then(([activitiesStatusList]) => {
          const activitiesStatus = activitiesStatusList.data.results?.filter(
            el => el?.isActiveStatus === 'True',
          );
          setActivityStatusData(activitiesStatus);

          if (initialValues?.status || initialValues?.statusName) {
            if (
              initialValues?.title ===
                selectedTitle?.name ||
              selectedTitle?.id
            ) {
              const initialStatus = activitiesStatus?.find(
                el =>
                  el?.name === initialValues.status ||
                  el?.name === initialValues.statusName ||
                  el?.id === initialValues.status,
              );
              setValue('activityStatus', initialStatus);
            } else {
              setValue('activityStatus', '');
            }
          }
          if (!initialValues) {
            setValue(
              'activityStatus',
              activitiesStatus.find(
                status => status.name === 'Pending',
              ),
            );
          }
        })
        .catch(() => {
          setValue('activityStatus', '');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedTitle]);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }),
      );
    }
    if (initialErrors?.title) {
      setError('activityTitle', {
        type: 'Permitting activity is already exist.',
        message: initialErrors?.title[0],
      });
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const onCurrencyChange = (value, name) => {
    setValue(name, value);
  };

  const modifiedGetCommentsRequest = (isSystemMsg) => {
    return Promise.all([
      initialValues?.type === 'inspection'
        ? getPermitInspectionsComments(
            {...GET_ALL_QUERY_PARAMS,system_message:isSystemMsg},
            initialValues?.id
          )
        : getPermitActivityComments(
            {...GET_ALL_QUERY_PARAMS,system_message:isSystemMsg},
            initialValues?.id            
          ),
    ]).then(([response]) => {
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(comment => {
            return {
              ...comment,
              creator: comment.username,
              createdAt: formatDateTime(
                comment?.createdAt,
                COMMENTS_DATETIME_FORMAT,
              ),
            };
          }),
        },
      };
    });
  };

  const sendAttachmentFiles = data => {
   createAttachmentCondition({
      documentFile: data?.documentFile,
      id: initialValues?.id,
    });    
  };

  const middleware = (submitFn: (data) => void) => data => {        
    if (
      initialValues &&
      data &&
      data?.documentFile
    ) {
      
      sendAttachmentFiles(data);
    }    
    
    let completedBy;
    Promise.all([getCurrentUser()]).then(([{data}]) => {
      completedBy = data.id
    }).then(() => {      
      
      const today = new Date();
      if(data?.reviewType) {        
        data?.reviewType?.forEach(type => {
          submitFn({
            ...data,
            documentFile: (initialValues?.documentFile?.length && data?.documentFile) ? data?.documentFile : !initialValues ? data?.documentFile : data?.documentFile ? data?.documentFile[0] : null,
            permit,
            id: initialValues?.id,
            title: data.activityTitle?.id,
            status: data.activityStatus?.id,
            activityType: data.activityTitle?.activityType,
            activityContact: data.activityContact?.value,
            assignedTo: data.assignee?.id,
            contact: data.activityContact.id,
            followUp: data.toDate
              ? `${formatDate(
                  data.toDate,
                )}`
              : new Date().toISOString(),
            isEmergency: data.emergency,
            isCompleted: data.isCompleted,
            information: data.information || ' ',
            inspector: data.inspector?.id,
            reviewType: type?.id,
            amount: data.amount ? data.amount : 0,
            completedAt: (initialValues && initialValues?.isCompleted === false && completedStatus === true) ? `${today.toLocaleDateString()}` : null,
            completedBy: (initialValues && initialValues?.isCompleted === false && completedStatus === true) ? completedBy : null,
            attachments: JSON.stringify(tempAttachmentData.length >0 ? tempAttachmentData : null),
            conditionName: data?.conditionName,
            selectedDocuments: documents?.length ? documents?.filter(el => el?.checked)?.map(el => el?.id) : null
          });
        })
      } else {
        submitFn({
          ...data,
          documentFile: (initialValues?.documentFile?.length && data?.documentFile) ? data?.documentFile : !initialValues ? data?.documentFile : data?.documentFile ? data?.documentFile[0] : null,
          permit,
          id: initialValues?.id,
          title: data.activityTitle?.id,
          status: data.activityStatus?.id,
          activityType: data.activityTitle?.activityType,
          activityContact: data.activityContact?.value,
          assignedTo: data.assignee?.id,
          contact: data.activityContact.id,
          followUp: data.toDate
            ? `${formatDate(
                data.toDate,
              )}`
            : new Date().toISOString(),
          isEmergency: data.emergency,
          isCompleted: data.isCompleted,
          information: data.information || ' ',
          inspector: data.inspector?.id,
          // reviewType: data.reviewType?.map(el => el?.id),
          amount: data.amount ? data.amount : 0,
          completedAt: (initialValues && initialValues?.isCompleted === false && completedStatus === true) ? `${today.toLocaleDateString()}` : null,
          completedBy: (initialValues && initialValues?.isCompleted === false && completedStatus === true) ? completedBy : null,
          attachments: JSON.stringify(tempAttachmentData.length >0 ? tempAttachmentData : null),
          conditionName: data?.conditionName
        });
      }      
    
    })
    
  };

  const handleChangeFile = e => {    
    if (initialValues?.documentFile?.length !== 0 || initialValues?.attachments?.length !== 0) {
      setValue('documentFile', e.target.files[0]);
    } else {
      setValue('documentFile', e.target.files);
    }
  };

  const handleAttachmentEdit = file => event =>  {
    const index = initialValues?.attachments.findIndex(doc=>doc === file);
    setEditAttachment({
      id:initialValues?.attachmentsId[index],
      index:index,
      name:file.split('.')[0],
      extension:file.split('.')[1],
      file: initialValues?.attachmentsFile[index]
    });
  }

  const handleOnChangeAttachment = event =>  {
    event.preventDefault();
    setEditAttachment({
      ...editAttachment,
      name:event.target.value
    });
  }

  const handleUpdateAttachment = () =>  {  
    if(editAttachment?.name){      
      const request = {
        id: editAttachment?.id,
        visible_filename: editAttachment?.name+"."+editAttachment?.extension,
        old_visible_filename: initialValues?.attachments[editAttachment?.index],
        file: editAttachment?.file
      }
      if(tempAttachmentData.findIndex(file => file.id === request.id) < 0 ){    
        setTempAttachmentData([
            ...tempAttachmentData,
            request
        ]);
      }else{
        setTempAttachmentData(tempAttachmentData.map((file) => {
          if(file.id === editAttachment?.id){
            file.visible_filename = editAttachment?.name+"."+editAttachment?.extension;
          }
          return file;
        }));
      }
      setInitialValues({
        ...tempInitialValues,
        attachments:tempInitialValues?.attachments?.map((file,index) => {
          if(index === editAttachment?.index){
            return file = editAttachment?.name+"."+editAttachment?.extension;
          }else{
            return file;
          }
        })
      });
    }
    setEditAttachment(null);
  }

  
  
  React.useEffect(() => {
    if(isReviewDocumentsModalOpen) {
      getPermitDocuments({
        permit:permit,
      }).then(documentsRes => {
        
        const filteredDocs = documentsRes?.data?.map(doc => {
          return {
            ...doc,
            id: doc?.pk,
            name: doc?.name,
            checked: initialValues ? initialValues?.selectedDocuments?.find(el => el?.pk === doc?.pk) : false
          }
        })
        
        setDocuments(filteredDocs)
      })
    }

  }, [isReviewDocumentsModalOpen === true])

  React.useEffect(() => {
    if(isReviewDocumentsModalOpen) {
      //setting the top popup to scroll to the top
      let maxIterations = 10;
      let top = 0;
      let element = scrollRef.current.parentElement;
      //this try/catch is a safety in case that the iteration over the DOM elements
      //doesn't yield any element values
      try {
        while( true ){
          if(element.scrollTop > 0 || maxIterations < 1){
            element.scrollTop = 0;
            break;
          }
          element = element.parentElement;
          maxIterations--;
        }
        
        
        // setting the files popup scroll to the top.
        maxIterations = 10;
        element = scrollRef.current.childNodes[0];
        while( true ){
          let style = window.getComputedStyle(element) || {};
          if(style.top !== '0px' || maxIterations < 1) {
            element.style.top=0;
            break;
          }
          maxIterations--;
          element = element.childNodes[0];
        }
      } catch {
        //This is a safety catch - read comment above
      }
      
    }

  },[isReviewDocumentsModalOpen])
  const openReviewDocumentsModals = () => {
    setIsReviewDocumentsModalOpen(true)
  }

  const handleCloseReviewDocumentsModal = () => {
    setIsReviewDocumentsModalOpen(false)
  }

  const handleCheckboxChange = (flag, id, name) => {
      const newDocs = documents?.map(doc => {
        if(doc?.id === id) {
          return {...doc, id, name, checked: flag}
        } else {
          return doc
        }
      })
      
      setDocuments([...newDocs])
  }

  const ReviewDocuments = ({documents}) => {
    const docs = documents?.map(doc => {
      return {
        ...doc,
        status: documentStatusData?.find(el => el?.id === doc?.status)?.title
      }
    })
    return <div>
      {!documents?.length ? <h3>No uploaded documents exist for selection</h3> : <>
      {docs?.map(doc => <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
        <CheckboxWithLabel
                    // error={errors.isCompleted}
                    labelPlacement="end"
                    label={doc?.name}
                    checked={doc?.checked}
                    // onBlur={onBlur}
                    disabled={initialValues?.isCompleted}
                    onChange={(e, flag) => handleCheckboxChange(flag, doc?.id, doc?.name)}
               
              />
  <p style={{width: "50%"}}>{doc?.status}</p>
      </div>)}
              <ButtonsWrapper>
              <StyledButtonMain disabled={initialValues?.isCompleted} onClick={() => setIsReviewDocumentsModalOpen(false)}>
                Add
              </StyledButtonMain>
              <StyledButtonInverted
                onClick={() => setIsReviewDocumentsModalOpen(false)}
              >
                Cancel
              </StyledButtonInverted>
            </ButtonsWrapper></>}
</div>
  }

  return (
    <Container
      ref={scrollRef}
      disabled={isDeleted || (isSubmitting && !modalError)}
    > 
    {isReviewDocumentsModalOpen && <ModalDefaultDraggableExact
    centered
    disabledDraggable
    // exactPosition={initialValues ? true : false}
          onClose={handleCloseReviewDocumentsModal}
          title="Select Documents"
        >
          <ReviewDocuments documents={documents} />
          </ModalDefaultDraggableExact>}  
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        {initialValues && (
        <InspectionsInfoBlock info={readOnlyInfo} />
      )}
        <InputBaseContainer>
          <Controller
            name="activityType"
            control={control}
            defaultValue=""
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.activityType}
                error={errors.activityType}
                isDisabled={isLoading || isReadOnly || activityTypeDisabled || (initialValues?.activityType === "Fee" && initialValues?.title === "Invoice") || cameFromSearch || isInspector}
                isOptionDisabled={option =>  (!selectedPermit?.serviceType?.find(type => type?.title?.toLowerCase() === "reviews") && option?.title?.toLowerCase() === "reviews")}
                options={activityTypesData}
                isRequired
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) => item?.title}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="activityTitle"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.activityTitle}
                error={errors.activityTitle}
                isDisabled={isLoading || isReadOnly || (initialValues?.activityType === "Fee" && initialValues?.title === "Invoice") || completedStatus || cameFromSearch || isInspector}
                options={activityTitlesData}
                isRequired={isYupFieldRequired(
                  'activityTitle',
                  schema,
                )}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) => item.name}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {conditionsMode ? <InputBaseContainer>
          <InputTextWithLabel
            name="conditionName"
            error={errors.conditionName}
            inputRef={register}
            label={labels.conditionName}
            isRequired={isYupFieldRequired(
              'conditionName',
              conditionNameSchema,
            )}
            disabled={completedStatus || isReadOnly || cameFromSearch}
          />
        </InputBaseContainer> : null}
        <InputBaseContainer>
          <Controller
            name="activityStatus"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.activityStatus}
                error={errors.activityStatus}
                isDisabled={isLoading || (initialValues?.activityType === "Fee" && initialValues?.title === "Invoice") || completedStatus || cameFromSearch}
                options={activityStatusData}
                isRequired={isYupFieldRequired(
                  'activityStatus',
                  schema,
                )}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) => item.name}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="activityContact"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                showCompany
                placeholder=""
                menuPlacement="bottom"
                label={labels.activityContact}
                error={errors.activityContact}
                isDisabled={isLoading || isReadOnly || completedStatus || cameFromSearch || isInspector}
                options={usersData}
                isRequired={isYupFieldRequired(
                  'activityContact',
                  schema,
                )}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  `${item?.firstName} ${item?.lastName}${
                    item.companyName || item.parentCompany
                      ? ` - ${
                          item.companyName ||
                          item.parentCompany
                        }`
                      : ''
                  }`
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="toDate"
            defaultValue=""
            valueName="selected"
            onChange={([selected]) => selected}
            control={control}
            menuPlacement="top"
            render={props => (
              <InputDatePickerWithLabel
                label={labels.toDate}
                error={errors.toDate}
                dateFormat={DEFAULT_DATE_FORMAT}
                disabled={isReadOnly || completedStatus || cameFromSearch || isInspector}
                isRequired={isYupFieldRequired(
                  'toDate',
                  schema,
                )}
                // eslint-disable-next-line react/prop-types
                selected={props.value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <MobileRemove>
          <Controller
            name="assignee"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.assignee}
                error={errors.assignee}
                isDisabled={isLoading || isReadOnly || (initialValues?.activityType === "Fee" && initialValues?.title === "Invoice")  || completedStatus || cameFromSearch || isInspector}
                options={employeesData}
                isRequired={isYupFieldRequired(
                  'assignee',
                  schema,
                )}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  `${item?.firstName} ${item?.lastName}`
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
          </MobileRemove>
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="information"
            error={errors.information}
            inputRef={register}
            label={labels.information}
            isRequired={isYupFieldRequired(
              'information',
              schema,
            )}
            disabled={completedStatus || isReadOnly || cameFromSearch}
          />
        </InputBaseContainer>

        {/* EA-390 */}
        {initialValues && !isInspector && <>
          <InputBaseContainer>
            <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.9}}
            name="createdAt"
            error={errors.dateCreated}
            inputRef={register}
            label={labels.dateCreated}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        <InputBaseContainer>
          <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.9}}
            name="createdBy"
            error={errors.createdBy}
            inputRef={register}
            label={labels.createdBy}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        {initialValues?.isCompleted && <>
          <InputBaseContainer>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.9}}
            name="completedAt"
            error={errors.dateCompleted}
            inputRef={register}
            label={labels.dateCompleted}
            isRequired={false}
            disabled={true}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
            name="completedBy"
            error={errors.completedBy}
            inputRef={register}
            label={labels.completedBy}
            isRequired={false}
            disabled={true}
          />
        </InputBaseContainer>
        </>}
        </>}

        <InputBaseContainer
          hidden={selectedTitle?.activityType !== 9}
        >
          <Controller
            name="amount"
            control={control}
            defaultValue=""
            render={props => (
              <InputCurrencyWithLabel
                allowDecimals
                error={errors.amount}
                label={labels.amount}
                onCurrencyChange={onCurrencyChange}
                isRequired={isYupFieldRequired(
                  'amount',
                  schema,
                )}
                disabled={completedStatus || isReadOnly}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={selectedTitle?.activityType !== 7}
        >
          <Controller
            name="reviewType"
            control={control}
            defaultValue=""
            render={props => (
              <InputSelectWithLabel
                isMulti
                placeholder=""
                menuPlacement="bottom"
                label={labels.reviewType}
                error={errors.reviewType}
                isDisabled={initialValues || isLoading || isReadOnly || initialValues?.isCompleted || cameFromSearch || isInspector}
                options={tradesData}
                isRequired
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  item.title ? item.title : item.name
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer
          hidden={selectedTitle?.activityType !== 7}
        >
          <Controller
            name="inspector"
            control={control}
            defaultValue=""
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.inspector}
                error={errors.inspector}
                isDisabled={isLoading || isReadOnly || completedStatus || cameFromSearch || isInspector}
                options={inspectorsData}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  `${item?.firstName} ${item?.lastName}`
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        
        {conditionsMode ? (
          <InputBaseContainer>
            <Controller
              name="documentFile"
              control={control}
              defaultValue={null}
              render={() => (
                <InputFileContainer
                  onChange={handleChangeFile}
                  error={errors.documentFile}
                  accept="application/pdf"
                  multiple={!initialValues}
                  isRequired={isYupFieldRequired(
                    'documentFile',
                    schema,
                  )}
                  disabled={cameFromSearch || completedStatus}
                />
              )}
            />
          </InputBaseContainer>
        ) : null}
        {conditionsMode && tempInitialValues?.attachments?.length >0 ? (
          <InputBaseContainer> 
            <div style={{textAlign:'left'}}>
              <p>Attachment(s)</p>
              <div>                
                {tempInitialValues?.attachments?.map((file,index) => (
                  (editAttachment?.index !== index) ?
                    <Chip
                    label={file}
                    variant="outlined"
                    onDelete={handleAttachmentEdit(file)}
                    deleteIcon={<EditIcon />}
                  /> : ''                  
                ))}
              </div>
              { editAttachment?.id ? 
                    <TextInput
                    label={labels.attachementFileName}
                    value={editAttachment?.name}
                    onChange={handleOnChangeAttachment}
                    error={errors.attachementFileName}
                    variant = "standard"
                    InputProps={{
                      endAdornment: 
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="update name"
                          onClick={handleUpdateAttachment}
                          edge="end"
                        >
                          <Check />
                        </IconButton>
                      </InputAdornment>
                    }}
                  /> 
              : ''}
            </div>              
        </InputBaseContainer>
        ) : null }
        <ReviewDocumentsBlock>
          {isEmployee && selectedTitle?.activityType === 7 && <a style={{cursor: 'pointer'}} onClick={openReviewDocumentsModals}>Select Documents for Review</a>}
        <CheckboxBlock style={selectedTitle?.activityType === 7 ? {marginLeft: "30%", width: "60%"} : {marginLeft: "56%", width: "60%"}}>
          <InputBaseContainer>
            <Controller
              name="emergency"
              control={control}
              valueName="checked"
              defaultValue={false}
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.emergency}
                  label={labels.emergency}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                  disabled={isReadOnly || completedStatus || cameFromSearch || isInspector}
                />
              )}
            />
          </InputBaseContainer>
          {initialValues && (
            <InputBaseContainer>
              <Controller
                name="isCompleted"
                control={control}
                valueName="checked"
                defaultValue={
                  initialValues?.isCompleted ?? false
                }
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.isCompleted}
                    label={labels.isCompleted}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}
                    disabled={isReadOnly || cameFromSearch || (initialValues?.isCompleted && initialValues?.title==="Invoice") || (initialValues?.activityTypeName === "Fee" && initialValues?.activityTitleName === "Invoice") || isInspector}                                      />
                )}
              />
            </InputBaseContainer>
          )}
        </CheckboxBlock>
        </ReviewDocumentsBlock>

        {!isDeleted && !isReadOnly && (
          <ButtonWithLoader
            disabled={(isSubmitting && !modalError) || cameFromSearch}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>

      {initialValues && (
        <CommentsChat
          commentsRequest={modifiedGetCommentsRequest}
          entityData={initialValues}
          postRequest={
            initialValues?.type === 'inspection'
              ? createPermitInspectionsComment
              : createPermitActivityComment
          }
          title={'Comments List'}
          buttonText='Add Comment'
          isReadOnly={isReadOnly ||initialValues?.isCompleted || cameFromSearch}
          commentCreated={commentCreated}
          setCommentCreated={setCommentCreated}
        />
      )}
    </Container>
  );
};

const MobileRemove = styled.div`
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;

  & button {
    height: 36px;
  }
`;
const StyledButtonMain = styled(ButtonMain)`
  margin-right: 16px;
  width: 112px;
`;
const StyledButtonInverted = styled(ButtonInverted)`
  width: 112px;
`;

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;
const CheckboxBlock = styled.div`
  display: flex;
  @media only screen and (max-width: 840px) {
    display:block;
    margin-left:0;
  }
`;

const ReviewDocumentsBlock = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 840px) {
    display:block;
    margin-left:0;
  }
`;

const FormContainer = styled.form`
  width: 100%;
  @media only screen and (max-width: 840px) {
    width: 75vw !important;
  }
  .MuiButton-root {
    margin-top: 24px;
  }
  .MuiChip-outlined {
    margin-right:5px;
    margin-top: 5px;
  }
`;


interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: any;
  onSubmit: (data) => void;
  updateTable?: (data) => void;
  setActivityCreated?: (data: boolean) => void;
  initialErrors?: FormServerError<any>;
  modalError: boolean;
  conditionsMode?: boolean;
  permit: number;
  isReadOnly :boolean;
  cameFromSearch?: boolean;
  activeTab?: number;
  setInitialValues: (data: any) => void;
}

export default PermitActivitiesForm;
