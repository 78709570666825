import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import ReactInputMask from 'react-input-mask';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import useUserRole from '../hooks/useUserRole';

import {
  InputAutocompletePlaces,
  InputTextWithLabel,
  InputBaseContainer,
  SuccessModal,
  TextInputWithAdornment,
  QuickbooksRenewButton,
} from '../molecules';
import {
  successfulRequestSelector,
  setResponseHandled,
} from '../redux/ducks/userAuth';
import { ROUTES } from '../constants/routes';
import UserAvatar from '../organisms/Settings/UserAvatar';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import { UserDataPayload } from '../api/userSettings';
import { FormServerError } from '../types';
import { phoneRegex } from '../regex';

import {
  ButtonMain,
  Paper,
  UnderlinedLink,
} from '../atoms';
import SignatureWidget from './SignatureWidget/SignatureWidget';

const labels = {
  firstName: 'First name',
  lastName: 'Last name',
  phoneNumber: 'Phone number',
  address: 'Address',
  email: 'Email',
};

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label(labels.firstName),
  lastName: yup.string().required().label(labels.lastName),
  phoneNumber: yup
    .string()
    .matches(
      phoneRegex,
      'Invalid phone number. The phone number must be in format 2XX-XXX-XXXX',
    )
    .required()
    .label(labels.phoneNumber),
  address: yup.string().required().label(labels.address),
});

const UserAccountForm = ({
  onSubmit,
  initialValues,
  initialErrors,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isEmployee,isInspector } = useUserRole();

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
  } = useForm<UserDataPayload>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || {},
  });

  const userInfoWasUpdated = useSelector(
    successfulRequestSelector,
  );

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<UserDataPayload>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  const handleConfirmModalClose = React.useCallback(() => {
    dispatch(setResponseHandled());
  }, []);

  const handleChangePassword = () => {
    history.push(ROUTES.CHANGE_PASSWORD);
  };

  const handleChangeEmail = () => {
    history.push(ROUTES.CHANGE_EMAIL);
  };

  const handlePlaceSelect = (address, isSelect) => {
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        return setValue(key, value);
      });
    }
    return setValue('address', address);
  };

  return (
    <Wrapper>
      <span>Account</span>

      <PaperBox
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DataWrapper>
          <UserAvatar />

          <InputBaseContainer>
            <InputTextWithLabel
              name="firstName"
              error={errors.firstName}
              inputRef={register}
              label={labels.firstName}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <InputTextWithLabel
              name="lastName"
              error={errors.lastName}
              inputRef={register}
              label={labels.lastName}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <Controller
              control={control}
              defaultValue=""
              name="phoneNumber"
              render={props => (
                <ReactInputMask
                  mask="999-999-9999"
                  maskChar=" "
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                >
                  {() => (
                    <InputTextWithLabel
                      error={errors.phoneNumber}
                      label={labels.phoneNumber}
                    />
                  )}
                </ReactInputMask>
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <Controller
              name="address"
              defaultValue=""
              control={control}
              render={props => (
                <InputAutocompletePlaces
                  error={errors.address}
                  label={labels.address}
                  handleFieldChange={handlePlaceSelect}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <TextInputWithAdornment
              name="email"
              error={errors.email}
              value={initialValues.email}
              label={labels.email}
              disabled
            >
              {!isEmployee && (
                <IconButton onClick={handleChangeEmail}>
                  <SettingsIcon />
                </IconButton>
              )}
            </TextInputWithAdornment>
          </InputBaseContainer>

          <ActionLink onClick={handleChangePassword}>
            Change Password
          </ActionLink>
          {isInspector && <SignatureWidget />}
        </DataWrapper>

        <Submit>Update information</Submit>

        {initialValues.isEpsuperadmin && (
          <QuickbooksButtonContainer>
            <QuickbooksRenewButton />
          </QuickbooksButtonContainer>
        )}
      </PaperBox>

      {userInfoWasUpdated && (
        <SuccessModal
          title="Changes have been saved"
          onClose={handleConfirmModalClose}
        />
      )}
    </Wrapper>
  );
};

interface Props {
  initialValues?: UserDataPayload;
  onSubmit: (data: UserDataPayload) => void;
  initialErrors?: FormServerError<UserDataPayload>;
}

const Wrapper = styled.div`
  & > span {
    color: var(--secondaryText);
  }
`;

const PaperBox = styled(Paper).attrs({
  as: 'form',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 580px;
  background: var(--secondary);
  padding: 24px 24px 28px;
  margin-top: 7px;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 24px 0;
`;

const Submit = styled(ButtonMain).attrs({
  type: 'submit',
})`
  width: 250px;
  margin: 0 auto;

  &.MuiButton-contained {
    box-shadow: none;
  }
`;

const QuickbooksButtonContainer = styled.div`
  margin: 32px 0 0 0;
`;

const ActionLink = styled(UnderlinedLink).attrs({
  as: 'span',
})`
  align-self: flex-start;
`;

export default UserAccountForm;
