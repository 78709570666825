import * as R from 'ramda';

export const addErrorsForEmptyFields = <F, E>(
  fields: F,
  errors: E,
  message: string,
): E => {
  const updateErrors = R.pipe(
    R.toPairs,
    R.reduce((acc, [fieldName, fieldValue]) => {
      if (fieldValue) {
        return acc;
      }

      return {
        ...acc,
        [fieldName]: message,
      };
    }, errors),
  );
  return updateErrors(fields);
};

type FormErrors = { [s: string]: string };

type isValidationSucceedType = (
  formErrors: FormErrors,
) => boolean;

const hasErrorMessage = (value: string) => !!value;

export const isValidationSucceed: isValidationSucceedType = R.pipe(
  R.values,
  R.none(hasErrorMessage),
);
