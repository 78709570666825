import * as React from 'react';
import styled from 'styled-components';

import Table from '../Table';
import ActionsPanel from '../ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import {
  getFeesList,
  createFees,
  deleteFees,
  updateFees,
  FeesType,
  FeesButtonTypeLink,
} from '../../api/fees';
import FeesForm from '../../forms/FeesForm';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableBoolean } from '../../utils/convertTableValues';
import useUserRole from '../../hooks/useUserRole';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import { getVocabulariesDataList } from '../../api/vocabulary';

const modifiedGetFeesRequest = params => {
  return Promise.all([
    getFeesList(params),
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 11,
      ordering: 'title',
    }),
  ]).then(([feesListResponse, vocabularyList]) => {
    return {
      ...feesListResponse,
      data: {
        ...feesListResponse.data,
        results: feesListResponse.data.results.map(item => {
          const feeServiceTypeName = vocabularyList.data.results.find(
            vocab => vocab.id === item.feeServiceType,
          );

          if (item.isFranchiseAll) {
            return {
              ...item,
              franchiseNames: 'All',
              feeServiceType: feeServiceTypeName.title,
            };
          }

          return {
            ...item,
            feeServiceType: feeServiceTypeName.title,
          };
        }),
      },
    };
  });
};

const FeesTable = ({
  feesQuery,
}: ActivitiesTitleTableTypes) => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage<FeesType>({
    createEndpoint: createFees,
    updateEndpoint: updateFees,
    deleteEndpoint: deleteFees,
    modalCreateTitle: `Add ${feesQuery.title} Fees`,
    modalUpdateTitle: `Update ${feesQuery.title} Fees`,
  });

  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'isDeleted',
        'franchise',
        'jurisdiction',
        'description',
        'feeType',
        'isFranchiseAll',
        'employeeCanEdit',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }
    switch (column) {
      case 'franchiseNames':
        return 'Branches';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <>
      <StyledActionsPanel
        onSearch={setSearch}
        onAdd={isSuperAdmin && handleAdd}
        isBreadCrumbsVisible={false}
        isActionPanelMoreVisible={false}
      />
      <Container>
        {feesQuery && (
          <Table
            customSearch
            searchQuery={search}
            columns={columns}
            rows={modifiedGetFeesRequest}
            onEdit={handleEdit}
            onDelete={isSuperAdmin && handleDelete}
            formatValue={convertTableBoolean}
            manuallyUpdate={tableManuallyUpdate}
            additionalRequestParams={{
              feeType: feesQuery.id,
            }}
          />
        )}
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <FeesForm
            initialValues={values}
            initialErrors={errors}
            modalError={modalErrorTitle.length > 0}
            feesQuery={feesQuery}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

const Container = styled.div`
  margin: 16px 0;
`;

const StyledActionsPanel = styled(ActionsPanel)`
  margin: 0;
  width: 100%;
  height: 50px;
`;

interface ActivitiesTitleTableTypes {
  feesQuery: FeesButtonTypeLink;
}

export default FeesTable;
