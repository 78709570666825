import React, { FC } from 'react';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../constants/menu';

type Props = {
    names: string;
    address: string;
}
const DetailsHeaderComponent:FC<Props> = ({names,address}) => {
  return (
    <>
      <DetailsRow>
        <DetailsHeader>Project Name / Permit Name</DetailsHeader>
        <DetailsHeader>Address</DetailsHeader>
      </DetailsRow>
      <DetailsRow>
        <Card>{names}</Card>
        <Card>{address}</Card>
      </DetailsRow>
    </>
  );
};

export default DetailsHeaderComponent;

const Card = styled.div`
  font-size: 22px;
  box-shadow: 7px 7px 13px darkgrey;
  padding: 3px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:95%;
  @media only screen and (max-width: 840px) {
    font-size: 15px;
  }
`;

const DetailsRow = styled.div`
  height: 100%;
  display: grid;
  color: ${Logo_Sky};
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
  @media only screen and (max-width: 840px) {
    grid-gap: 35px;
    margin-top: 20px;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.4em;
  font-weight: bold;
  color: ${Logo_Sky};
  flex-wrap: wrap;
  @media only screen and (max-width: 840px) {
    font-size: 12px;
  }
`;