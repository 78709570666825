/* eslint-disable react/jsx-props-no-spreading,react/prop-types,no-nested-ternary */
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import usePrevious from 'react-use-previous-hook';
import { differenceInCalendarDays } from 'date-fns';
import {
  Column,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { AxiosResponse } from 'axios';
import { snakeCase } from 'change-case';
import { tableColumns } from './TableColumns';

import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

import {
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
  INVOICES_SEARCH_DATE_FORMAT,
  TABLE_DEFAULT_PAGE_SIZES,
} from '../constants';
import { TABLE_LOAD_DATA_MODE } from '../constants/settings';

import {
  CheckboxRounded,
  IconButton,
  Pagination,
  Tooltip,
  DatePicker,
} from '../atoms';
import {
  LoaderWithContainer,
  SearchBar,
  FeesSearchBar,
  InputSelect,
} from '../molecules';

import formatDate, {
  formatDateTime,
} from '../utils/formatDate';

import { PaginatedList } from '../types';

import { tableLoadModeSelector } from '../redux/ducks/userSettings';

import useManuallyUpdate from '../hooks/useManuallyUpdate';
import useUserRole from '../hooks/useUserRole';
import useDevice from '../hooks/useDevice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getUsers } from '../api/users';
import { USER_TYPES_VALUES } from '../constants/forms';
import { getContactTypeList } from '../api/contactType';
import { getContactProjects } from '../api/projects';
import { getVocabulariesDataList } from '../api/vocabulary';
import RecordData from './RecordData';

const Checkbox = React.forwardRef((props, ref) => (
  <CheckboxRounded inputRef={ref as any} {...props} />
));

const MetaMessage = ({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) => (
  <MetaMessageContainer>
    <td style={{ maxWidth: 'none' }}>{children}</td>
  </MetaMessageContainer>
);

const SELECTION_ID = 'selection';
const ACTIONS_ID = 'actions';
const MAP_COLUMNS_PLACEHOLDER = '-';

const Table = <T extends object, P extends object>({
  columns = [] as any,
  rows = [] as any,
  columnsOrder,
  onEdit,
  onSelect,
  onDelete,
  onConfigure,
  formatValue,
  initialSelectedRows,
  searchQuery,
  customSearch,
  cellClassName,
  manuallyUpdate,
  handleCellClick,
  handleCellClickClientFile,
  onLoadGetData,
  cellCallbackFnIsOn,
  onChangeLoadingStatus,
  additionalRequestParams,
  hightLightSelectedRow,
  requestParamsIsCanceled,
  initialUseSortBy = false,
  isActivityTable =false,
  permitFeesTable=false,
  useViewModeInsteadEdit,
  initialUseSortBySelectedColumns,
  initialSortBy,
  additionalActions = () => undefined,
  ticketStatuses,
  onTicketStatusChange,
  currentUserName,
  isPermitManager,
  isMultipleDocuments,
  isMultipleDocumentsClientFiles,
  handleFeeSelect,
  handleAllFeesSelect,
  allFeesSelected,
  handleQuantityChange,
  handleAmountChange,
  feesTable,
  colorSchema,
  activityType,
  italic,
  docs,
  additionalRow,
  isRunnerCheckbox,
  additionalBottomRow,
  additionalTopRow,
  searchGlobal,
  isPermitActivitiesPage = false,
  // activityTable,
  inspectorCompletedInspectionPage = false,
  addContactView = false,
  permitUserId,
  addFeesTable,
  permitActivityTable = false,
  permitInspectionTable=false,
  permitConditionTable=false,
  permitDocumentTable=false,
  isLocked = false,
  showPageManualSize = true,
  reviewerCommentData,
  invoicePage= false,
  smallPrintHeader = false
}: Props<T, P>) => {
  const isAsync = typeof rows === 'function';
  const isNeedToMapColumns = typeof columns === 'function';

  const isFirstRun = React.useRef(true);

  const tableLoadDataMode = useSelector(
    tableLoadModeSelector,
  );

  // Local table updater
  const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();

  //  Main table updater
  const [updater, setUpdater] = React.useState<number>(
    manuallyUpdate,
  );
  const [error, setError] = React.useState<boolean>(false);
  // const [
  //   isActivityTableLoaded,
  //   setIsActivityTableLoaded,
  // ] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(
    isAsync,
  );

  const [data, setData] = React.useState(
    isAsync ? ([] as any) : rows,
  );
const [moreDataPage, setMoreDataPage] = React.useState(25)
const [contactTypes, setContactTypes] = React.useState(null)

React.useEffect(() => {
  if(addContactView) {
    getContactTypeList({
      ...GET_ALL_QUERY_PARAMS,
    }).then(contactTypesRes => setContactTypes(contactTypesRes?.data?.results))
  }
}, [contactTypes === true])


const fetchMoreData = () => {
  setMoreDataPage(prevPage => prevPage + 25)
  Promise.all([getUsers({...GET_ALL_QUERY_PARAMS,
    limit: 25,
    offset: moreDataPage,
    userRole: USER_TYPES_VALUES.CLIENT})]).then(([userRes]) => {
      const userData = userRes.data?.results?.map(item => {
        return {
          ...item,
          contactType: item.contactType
          ? +item.contactType
          : contactTypes[0]?.id,
          name: `${item?.firstName} ${item?.lastName}`,
          email: item?.email,
          companyName: item?.companyName,
        };
      })
      setData(data.concat([...userData]))})
};
const getStatuses = (permits, statuses) =>
  permits.map(
    permit =>
      statuses.find(status => permit.status === status.id)
        ?.title,
  );
const fetchContactProjectsMoreData = () => {
  setMoreDataPage(prevPage => prevPage + 25)
  Promise.all([
    getContactProjects({limit: 25,
      offset: moreDataPage, contact_id: permitUserId}),
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 6,
      ordering: 'title',
    }),
  ]).then(
    ([
      projectsResponse,
      statusesResponse,
    ]) => {
      const projRes = projectsResponse.data.map(
            project => {
              const projectPermits = project?.permits

              return {
                ...project,
                permitsNames: projectPermits.map(
                  permit => permit.name,
                ),
                permits: projectPermits,
                permitsNumbers: projectPermits.map(permit =>
                  permit.number ? permit.number : '',
                ),
                permitsStatuses: getStatuses(
                  projectPermits,
                  statusesResponse.data.results,
                ),
                address: `${project.street}, ${project.city}, ${project.state}, ${project.zipCode}`,
              };
            },
          )
        setData(data.concat([...projRes]))
    },
  );
};

const newData = React.useMemo(() => data, [data])

  const [columnsList, setColumnsList] = React.useState<
    Column<T>[]
  >(
    isNeedToMapColumns
      ? [
          {
            Header: MAP_COLUMNS_PLACEHOLDER,
            accessor: MAP_COLUMNS_PLACEHOLDER,
          },
        ]
      : (columns as any),
  );

  const {
    isSuperAdmin,
    isFranchiseAdmin,
    isEmployee,
    isInspector,
  } = useUserRole();
  // adding different page size for all activities page
  const [
    pageManualSize,
    setPageManualSize,
  ] = React.useState<number>(
   (activityType && !isInspector) ?  TABLE_DEFAULT_PAGE_SIZES[4] : TABLE_DEFAULT_PAGE_SIZES[3] || 5,
  );

  const [manualTotal, setManualTotal] = React.useState<
    number
  >(0);

  const [
    pageManualCount,
    setPageManualCount,
  ] = React.useState<number>(0);

  const [
    feeInvoicedDate,
    setFeeInvoicedDate,
  ] = React.useState('');
  const [feePaidDate, setFeePaidDate] = React.useState('');
  const [
    activityFollowUp,
    setActivityFollowUp,
  ] = React.useState('');
  const [
    dateCreated,
    setDateCreated,
  ] = React.useState('');
  const [
    dateCompleted,
    setDateCompleted,
  ] = React.useState('');
  const [
    inspectionFollowUp,
    setInspectionFollowUp,
  ] = React.useState('');
  const [
    inspectionDate,
    setInspectionDate,
  ] = React.useState('');
  const [isIpad] = useDevice();


  const [columnFilter,setColumnFilter]=React.useState([])
  const columnFilterUi=(id,value)=>{

     let tempFilter=[...columnFilter]
     const index=columnFilter?.findIndex(val=>val?.id===id)
   if(value!==""){
   if(index===-1){
     tempFilter.push({id:id,value:value})
   }else{
     tempFilter.splice(index,1,{id:id,value:value})
   }
  }else if(index>-1){
   tempFilter.splice(index,1)
  }
  const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
            && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
  const arraysEqual = (a1, a2) =>
   a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
  if(!arraysEqual(columnFilter,tempFilter)){
    setColumnFilter(tempFilter)
  }
 }
  const [
    ipadColumnList,
    setIpadColumnList,
  ] = React.useState([]);

  const prevFilter=(col)=>{
    const filterData=isActivityTable||addContactView|| permitActivityTable || permitInspectionTable ||permitConditionTable || permitFeesTable ||permitDocumentTable || addFeesTable?columnFilter:filters
    if(filterData.length>0){
      const column = filterData.findIndex(item=>item.id===col);
      if(column>-1){
        return filterData[column]?.value||""
      }else{
        return ""
      }
    }
    return ""
  }
  React.useEffect(() => {
    if (isIpad) {
      const notRequiredColumns = [
        'jurisdiction',
        'projectManager',
        'contact',
        'contactPhone',
      ];
      const ipadFilteredColumns = columnsList.filter(
        column =>
          !notRequiredColumns.includes(column.accessor),
      );
      setIpadColumnList(ipadFilteredColumns);
    }
  }, [columnsList]);

  const hasActions =
    onConfigure || onEdit || onDelete || additionalActions;

  const actionsList = row => {
    let customActions = additionalActions(row) || [];
    const ToggleButton = () => {
      return <ToggleOnIcon style={{color: '#1976d2'}}  />
    }
    
    if(permitDocumentTable && row?.isClientAvailable) {
      const toogleAction = {...customActions[1], Icon: ToggleButton}
      customActions = [customActions[0], toogleAction]
    }
    

    return row?.isDeleted || useViewModeInsteadEdit
      ? [
          onEdit && {
            title: 'View',
            handler: onEdit,
            Icon: VisibilityIcon,
          },
          ...customActions,
        ].filter(Boolean)
      : row?.canOnlyEdit
      ? [
          onEdit && {
            title: 'Edit',
            handler: onEdit,
            Icon: EditIcon,
          },
        ].filter(Boolean)
      : [
          onConfigure && {
            title: 'Configure',
            handler: onConfigure,
            Icon: SettingsIcon,
          },
          onEdit && {
            title: 'Edit',
            handler: onEdit,
            Icon: EditIcon,
          },
          onDelete && {
            title: 'Delete',
            handler: onDelete,
            Icon: DeleteIcon,
          },
          ...customActions,
        ].filter(Boolean);
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (pureRows, id, filterValue) =>
        pureRows.filter(row => {
          const rowValue = row.values[id];

          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(
                  String(filterValue).toLowerCase(),
                )
            : true;
        }),
    }),
    [],
  );

  const isCustomClassName = cellColumn => {
    let cellClass = null;
    cellClassName.forEach(cl => {
      cellClass =
        cl.column === cellColumn ? cl.className : null;
    });
    return cellClass;
  };

  const selectionColumn = {
    id: SELECTION_ID,
    Header: ({ getToggleAllPageRowsSelectedProps }: any) =>
      feesTable ? (
        <td style={{ border: 'none' }} />
      ) : (
        <td>
          <Checkbox
            {...getToggleAllPageRowsSelectedProps()}
          />
        </td>
      ),
    Cell: ({ row }: any) => (
      <td>
        <Checkbox {...row.getToggleRowSelectedProps()} />
      </td>
    ),
    width: 60,
  };

  const actionsColumn = {
    id: ACTIONS_ID,
    Header: 'Actions',
    Cell: ({ row: { original } }) => (
      <td>
        <ActionsContainer>
          {actionsList(original).map(
            ({ title, handler, Icon }) => (
              <IconButton
                style={(reviewerCommentData && reviewerCommentData?.includes(original?.id) && title === 'View Documents') ? {color: '#cccccc'} : {}}
                disabled={(permitDocumentTable && (title === "Download files") && !original?.adminFile?.length && !original?.clientFiles?.length) || (!original?.invoiceNumber && invoicePage)}
                className="table-actions"
                key={String(Math.random())}
                onClick={() => handler(original)}
              >
                <Tooltip title={title} placement="left">
                  <Icon />
                </Tooltip>
              </IconButton>
            ),
          )}
        </ActionsContainer>
      </td>
    ),
    width: 140,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    setPageSize,
    setColumnOrder,
    setGlobalFilter,
    setFilter,
    selectedFlatRows,
    state: {
      globalFilter,
      pageIndex,
      pageSize,
      filters,
      // commented by mritunjoy
      // JIRA ticket number - EA-41
      // we don't need this sortby method anymore, as per the JIRA ticket
      sortBy,
      selectedRowIds,
    },
  }: any = useTable(
    {
      columns:
        isIpad && isInspector
          ? ipadColumnList
          : (columnsList as any),
      data: data as object[],
      // @ts-ignore
      filterTypes,
      pageCount: pageManualCount,
      manualGlobalFilter: isAsync,
      manualPagination:  isAsync,
      autoResetPage:  false,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      manualSortBy: isAsync,
      disableSortBy: !initialUseSortBy,
      // commented by mritunjoy
      // JIRA ticket number - EA-41
      // for removing the sorting ability in the daily inspection table this was one of the dependency
      initialState: {
        pageSize: pageManualSize,
        selectedRowIds:initialSelectedRows || {},
        sortBy: initialSortBy || [],
      } as any,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    hooks => {
      hooks.visibleColumns.push(colmns =>
        [
          onSelect && selectionColumn,
          ...colmns,
          hasActions && actionsColumn,
        ].filter(Boolean),
      );
    },
  );

  const selectedFlatRowsCount = selectedFlatRows.length;
  const previousSelectedFlatRowsCount = usePrevious<number>(
    selectedFlatRowsCount,
  );
  const previousSelectedRows = usePrevious<any>(
    selectedFlatRows,
  );

  const fillColumns = row => {
    if (
      row
      // && columnsList[0].accessor === MAP_COLUMNS_PLACEHOLDER - TODO check table correct view
    ) {

      const filteredColumns = Object.keys(row)
        .map(column => {          
          return {
            Header:
              column === 'checkBox' ? (
                <Checkbox
                  onChange={handleAllFeesSelect}
                  checked={allFeesSelected}
                />
              ) : (
                columns(column as keyof T)
              ),
            accessor: column,
            // commented by mritunjoy
            // JIRA ticket number - EA-41
            // for removing the sorting ability in the daily inspection table this was one of the dependency

            disableSortBy:
              column === 'checkBox'
                ? true
                : column==="attachments"?true:initialUseSortBySelectedColumns
                ? !initialUseSortBySelectedColumns.includes(
                    column,
                  )
                : false,
            Cell: ({ value, cell }) => {
              if (
                column === 'statusTitle' &&
                ticketStatuses &&
                ticketStatuses?.length
              ) {
                const defaultTicketStatus = ticketStatuses.find(
                  el => el.title === value,
                );
                return (
                  <td
                    style={{
                      padding: 0,
                      overflow: 'visible',
                    }}
                    className={
                      cellClassName &&
                      Array.isArray(cellClassName)
                        ? isCustomClassName(column)
                        : null
                    }
                  >
                    <InputSelect
                      tableStyles
                      options={ticketStatuses}
                      getOptionValue={(item: any) =>
                        item.id
                      }
                      getOptionLabel={(item: any) =>
                        item.title
                      }
                      defaultValue={defaultTicketStatus}
                      onChange={selected =>
                        onTicketStatusChange(
                          selected,
                          cell?.row?.original?.id,
                        )
                      }
                      isDisabled={
                        defaultTicketStatus?.title ===
                          'Completed' ||
                        (isPermitManager
                          ? !isPermitManager
                          : currentUserName !==
                            cell?.row?.original
                              ?.creatorUsername)
                      }
                    />
                  </td>
                );
              }
              if (
                column === 'contactType' &&
                ticketStatuses &&
                ticketStatuses?.length
              ) {                
                const defaultTicketStatus = ticketStatuses.find(
                  el => el.name === value,
                );
                return (
                  <td
                    style={{
                      padding: 0,
                      overflow: 'visible',
                    }}
                    className={
                      cellClassName &&
                      Array.isArray(cellClassName)
                        ? isCustomClassName(column)
                        : null
                    }
                  >
                    <InputSelect
                      // tableStyles
                      options={ticketStatuses}
                      getOptionValue={(item: any) =>
                        item.id
                      }
                      getOptionLabel={(item: any) =>
                        item.name
                      }
                      defaultValue={defaultTicketStatus}
                      onChange={selected =>
                        onTicketStatusChange(
                          selected,
                          cell?.row?.original?.id,
                        )
                      }
                      isDisabled={
                        defaultTicketStatus?.title ===
                          'Completed' ||
                        (isPermitManager
                          ? !isPermitManager
                          : currentUserName !==
                            cell?.row?.original
                              ?.creatorUsername)
                      }
                    />
                  </td>
                );
              }
              if (
                column === 'followUp' &&
                colorSchema &&
                !cell?.row?.original?.isEmergency &&
                !cell?.row?.original?.isCompleted //EA-275
              ) {
                const followUp = new Date(
                  cell?.row?.original?.followUp,
                );

                const difference = differenceInCalendarDays(
                  new Date(),
                  followUp,
                );

                const isDateRed = difference >= 0;

                const isDateYellow =
                  difference === -1 || difference === -2;

                return (
                  <td
                    style={{
                      borderRadius: 5,
                      backgroundColor: `${
                        !cell?.row?.original?.followUp
                          ? ''
                          : isDateRed
                          ? '#FAB5A8'
                          : isDateYellow
                          ? '#FFFBB5'
                          : '#D2EDB9'
                      }`,
                    }}
                  >
                    {cell?.row?.original?.followUp}
                  </td>
                );
              }
              if (column === 'feeQuantity') {
                const [qty, setQty] = React.useState(
                  cell.row.original?.feeQuantity,
                );
                return (
                  <td>
                    <input
                      style={{ width: 50 }}
                      type="number"
                      min={1}
                      step={1}
                      disabled={
                        !cell.row.original?.feeChecked
                      }
                      onChange={e => {
                        setQty(e.target.value);
                        handleQuantityChange(
                          e,
                          cell,
                          e.target.value,
                        );
                      }}
                      value={qty}
                    />
                  </td>
                );
              }
              if (
                column === 'amount' &&
                handleAmountChange
              ) {
                const [amt, setAmt] = React.useState(
                  cell.row.original?.amount,
                );
                return (
                  <td>
                    <input
                      style={{ width: 60 }}
                      type="number"
                      min={1}
                      step={1}
                      disabled={
                        !cell.row.original?.feeChecked
                      }
                      onChange={e => {
                        setAmt(e.target.value);
                        handleAmountChange(
                          e,
                          cell,
                          e.target.value,
                        );
                      }}
                      value={amt}
                    />
                  </td>
                );
              }

              if (
                docs &&
                column === 'name' &&
                cell?.row?.original?.submitted
              ) {
                return (
                  <td style={{ color: 'red' }}>
                    {cell?.row?.original?.name}
                  </td>
                );
              }

              if (column === 'checkBox') {
                return (
                  <td style={{ textAlign: 'center' }}>
                    <Checkbox
                      onChange={e =>
                        handleFeeSelect(e, cell)
                      }
                      checked={
                        !isRunnerCheckbox &&
                        cell.row.original?.feeChecked
                      }
                    />
                  </td>
                );
              }

              if (Array.isArray(value)) {
                if (value.length >= 2) {
                  return (
                    <td
                      style={{ padding: '8px 0 0 16px' }}
                      className={
                        cellClassName &&
                        Array.isArray(cellClassName)
                          ? isCustomClassName(column)
                          : null
                      }
                    >
                      {cellCallbackFnIsOn &&
                      cellCallbackFnIsOn.includes(
                        column,
                      ) ? (
                        value.map((permitName, index) => (
                          <div
                            style={{ marginBottom: '8px' }}
                            key={permitName}
                          >
                            {isMultipleDocuments && !isMultipleDocumentsClientFiles && (
                              <button
                                style={{ padding: 0 }}
                                onClick={() => {                                  
                                  return handleCellClick({
                                    documentFile:
                                      cell.row.original
                                        .documentFile[
                                        index
                                      ],
                                  })
                                }
                                  
                                }
                                type="button"
                              >
                                {permitName}
                              </button>
                            )}
                            {!isMultipleDocuments && isMultipleDocumentsClientFiles && cell?.column?.id === "clientFiles" && (
                              <button
                                style={{ padding: 0 }}
                                onClick={() => {                                  
                                  return handleCellClickClientFile({
                                    documentFile:
                                      cell.row.original
                                        .documentFile[
                                        index
                                      ],
                                      column: column ? column : cell?.column?.id
                                  })
                                }
                                }
                                type="button"
                              >
                                {permitName}
                              </button>
                            )}
                            {!isMultipleDocuments && isMultipleDocumentsClientFiles && cell?.column?.id !== "clientFiles" && (
                              <button
                                style={{ padding: 0 }}
                                onClick={() => {                                  
                                  return handleCellClick({
                                    data:
                                      cell.row?.original,
                                    permitItemId:
                                      cell.row?.original
                                        .permits[index].id,
                                    projectId:
                                      cell.row?.original
                                        ?.id,
                                  })
                                }
                                }
                                type="button"
                              >
                                {permitName}
                              </button>
                            )}
                            {!isMultipleDocuments && !isMultipleDocumentsClientFiles && (
                              <button
                                style={{ padding: 0 }}
                                onClick={() => {
                                 return handleCellClick({
                                    data:
                                      cell.row?.original,
                                    permitItemId:
                                      cell.row?.original
                                        .permits[index].id,
                                    projectId:
                                      cell.row?.original
                                        ?.id,
                                  })
                                }
                                  
                                }
                                type="button"
                              >
                                {permitName}
                              </button>
                            )}
                          </div>
                        ))
                      ) : (
                        <>
                          {value.map(el => (
                            <div
                              key={Math.random()}
                              style={{
                                marginBottom: '8px',
                              }}
                            >
                              {el}
                            </div>
                          ))}
                        </>
                      )}
                    </td>
                  );
                }
              }
              return (
                <td
                  className={
                    cellClassName &&
                    Array.isArray(cellClassName)
                      ? isCustomClassName(column)
                      : null
                  }
                >
                  {cellCallbackFnIsOn &&
                  cellCallbackFnIsOn.includes(column) ? (
                    <button
                      style={{ padding: 0 }}
                      onClick={() =>
                        handleCellClick(
                          cell.row?.original,
                          column,
                        )
                      }
                      type="button"
                    >
                      {formatValue
                        ? formatValue(value, column)
                        : value}
                    </button>
                  ) : (
                    <>
                      {formatValue
                        ? formatValue(value, column)
                        : value}
                    </>
                  )}
                </td>
              );
            },
          };
        })
        .filter(column => Boolean(column.Header));

      setColumnsList(filteredColumns as any);

      if (columnsOrder) {
        setColumnOrder(
          typeof columnsOrder === 'function'
            ? filteredColumns
                .map(column => column.accessor)
                .sort(columnsOrder)
            : columnsOrder,
        );
      }
    }
  };

  // Combine updaters
  React.useEffect(() => {
    setUpdater(prevUpdater =>
      prevUpdater === manuallyUpdate
        ? tableManuallyUpdate
        : manuallyUpdate,
    );
  }, [manuallyUpdate, tableManuallyUpdate]);

  const previousGlobalFilter = usePrevious(globalFilter);
  const prevTableLoadDataMode = usePrevious(
    tableLoadDataMode,
  );

  const sortByColumn=(columnName)=>{

    switch(columnName){
      case "permit":
      case "title":
      case "status":
      case "project":
        return `${columnName}__name`
      case "contact_name":
        return "contact"
      case "status_title":
        return "status"
      case "project_name":
        return "permit__project__name"
      case "permit_number":
        return "permit__number"
      case "activity_type":
        return "activity_type__title"
      case "phone_number":
      case "mobile_phone_number":
        return `contact__${columnName}`
      case "assigned_to":
        return "assigned_to__first_name"
      case "assigned_to_email":
        return "assigned_to__email"
      case "jurisdiction":
        return "permit__jurisdiction__name"
      case "project_address":
        return "permit__project__address"
      case "inspection_number":
        return "id"
      case "inspection_type":
        return "inspection_type__name"
      case "contact":
        return "contact__first_name"
      case "reviewer":
      case "inspector":
        return "inspector__first_name"
      case "last_comment":
        return "last_comment"
      default:
       return columnName
    }
  }

  React.useEffect(() => {
    if (
      isAsync &&
      (isFirstRun.current ||
        !isLoading ||
        previousGlobalFilter !== globalFilter)
    ) {
      isFirstRun.current = false;
      // if (activityTable) {
      //   setIsActivityTableLoaded(true);
      // }

      setError(false);
      setIsLoading(true);

      const isDeleted =
        // eslint-disable-next-line no-nested-ternary
        tableLoadDataMode === TABLE_LOAD_DATA_MODE.ACTIVE
          ? false
          : tableLoadDataMode ===
            TABLE_LOAD_DATA_MODE.INACTIVE
          ? true
          : null;

      const additional = additionalRequestParams || {};

      const pageMax = Math.ceil(
        manualTotal / pageManualSize,
      );

      if (
        (manualTotal && pageIndex + 1 > pageMax) ||
        (prevTableLoadDataMode &&
          tableLoadDataMode !== prevTableLoadDataMode)
      ) {
        gotoPage(0);
      }
      let followUp=""
      let period=""
      let status=""
      let permitName=""
      let projectName=""
      let projectNamePermitName = ""
      let contactPhone = ""
      let projectManager = ""
      let permitNumber = ""
      let jurisdiction = ""
      let activityType = ""
      let activityTitle = ""
      let assignedTo = ""
      let phoneNumber = ""
      let cellPhone = ""
      let inspectionType = ""
      let contactName = ""
      let inspectorName = ""
      let inspectionNumber = ""
      let projectAddress = ""
      let reviewType = ""
      let reviewer = ""
      let inspectionDate = ""
      let name = ""
      let contactType = ""
      let email = ""
      let companyName = ""
      let lastComment = ""
      let amount = ""
      let feeServiceType = ""
      let invoiceNumber = ""
      let quantity = ""
      let total = ""
      let dateInvoiced = ""
      let datePaid = ""
      let invoice = ""
      let attachments = ""
      let contactId = ""
      let adminFileName = ""
      let clientFileName = ""
      let settingDoc = ""
      let completed = ""
      let id = ""
      let createdAt = ""
      let completedBy = ""
      let completedAt = ""
      let createdBy = ""
      let visibleFilename = ""
      let information = ""

      const searchParam=columnFilter.reduce((acc,val)=>{
                
        switch(val?.id) {
          case "followUp":
            followUp = val?.value||""
            return acc;
          case "period":
            period = val?.value||""
            return acc;
          case "status":
            status = val?.value || ""
            return acc;
            case "lastComment":
              lastComment = val?.value || ""
              return acc;
          case "permit":
            permitName = val?.value || ""
            return acc;
          case "projectName":
            projectName = val?.value || ""
            return acc;
          case "projectNamePermitName":
            projectNamePermitName = val?.value || ""
            return acc;
          case "contactPhone":
            contactPhone = val?.value || ""
            return acc;
          case "projectManager":
            projectManager = val?.value || ""
            return acc;
          case "permitNumber":
            permitNumber = val?.value || ""
            return acc;
          case "jurisdiction":
            jurisdiction = val?.value || ""
            return acc;
          case "activityType":
            activityType = val?.value || ""
            return acc;
            case "activityTypeName":
              activityType = val?.value || ""
              return acc;
          case "title":
            activityTitle = val?.value || ""
            return acc;
          case "assignedTo":
            assignedTo = val?.value || ""
            return acc;
          case "phoneNumber":
            phoneNumber = val?.value || ""
            return acc;
          case "cellPhone":
            cellPhone = val?.value || ""
            return acc;
          case "inspectionType":
            inspectionType = val?.value || ""
            return acc;
          case "contact":
            contactName = val?.value || ""
            return acc;
          case "inspector":
            inspectorName = val?.value || ""
            return acc;
          case "inspectionNumber":
            inspectionNumber = val?.value || ""
            return acc;
          case "projectAddress":
            projectAddress = val?.value || ""
            return acc;
          case "reviewType":
            reviewType = val?.value || ""
            return acc;
          case "reviewer":
            reviewer = val?.value || ""
            return acc;
          case "inspectionDate":
            inspectionDate = val?.value || ""
            return acc;
          case "name":
            name = val?.value || ""
            return acc;
          case "email":
            email = val?.value || ""
            return acc;
          case "contactType":
            contactType = val?.value || ""
            return acc;
          case "companyName":
            companyName = val?.value || ""
            return acc;
          case "amount":
            amount = val?.value || ""
           return acc;
          case "feeServiceType":
            feeServiceType = val?.value || ""
            return acc;
            case "invoiceNumber":
              invoiceNumber = val?.value || ""
              return acc;
          case "quantity":
            quantity = val?.value || ""
            return acc;
          case "total":
            total = val?.value || ""
            return acc;
          case "dateInvoiced":
            dateInvoiced = val?.value || ""
            return acc;
          case "datePaid":
            datePaid = val?.value || ""
            return acc;
          case "invoice":
            invoice = val?.value || ""
            return acc;
          case "attachments":
            attachments = val?.value || ""
            return acc;
          case "contactId":
            contactId = val?.value || ""
            return acc;
          case "adminFileName":
            adminFileName = val?.value || ""
            return acc;
          case "clientFileName":
            clientFileName = val?.value || ""
            return acc;
          case "settingDoc":
            settingDoc = val?.value || ""
            return acc;
          case "completed":
            completed = val?.value || ""
            return acc;
          case "id":
            id = val?.value || ""
            return acc;
          case "createdAt":
            createdAt = val?.value || ""
            return acc;
          case "completedAt":
            completedAt = val?.value || ""
            return acc;
          case "createdBy":
            createdBy = val?.value || ""
            return acc;
          case "completedBy":
            completedBy = val?.value || ""
            return acc;
          case "visibleFilename":
            visibleFilename = val?.value || ""
            return acc;  
          case "information":
            information = val?.value || ""
            return acc;  
          default:
            return `${val.value} ${acc || ""}`
        }

      },"")
      // const searchObj=columnFilter.reduce((acc,val)=>{
      //   if(val.id!=="activityType"){
      //     acc[snakeCase(val.id)]=val.value
      //   }else{
      //     acc["activity_type_name"]=val.value
      //   }
      //   return acc
      // },{})
      const params = requestParamsIsCanceled
        ? null
        : {
            isDeleted,
            search: globalFilter,
            limit: pageManualSize,
            offset:
              (prevTableLoadDataMode &&
                tableLoadDataMode !==
                  prevTableLoadDataMode) ||
              globalFilter !== previousGlobalFilter ||
              (manualTotal && pageIndex + 1 > pageMax)
                ? 0
                : pageIndex * pageManualSize,
                ordering:
                sortBy.length > 0
                  ? (sortBy[0].desc ? '-' : '') +
                  sortByColumn(snakeCase(sortBy[0].id))
                  : null,
                  searchColumn:isActivityTable?searchParam||null:null,
                  follow_up:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&followUp?followUp:null,
                  period: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&period ? period.trim() : null,
                  activity_status: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&status ? status.trim() : null,
                  permit_name: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&permitName ? permitName.trim() : null,
                  project_name: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&projectName ? projectName.trim() : null,
                  project_name_permit_name : (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && projectNamePermitName ? projectNamePermitName.trim() : null,
                  contact_phone : (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && contactPhone ? contactPhone.trim() : null,
                  project_manager : (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && projectManager ? projectManager.trim() : null,
                  permit_number: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && permitNumber ? permitNumber.trim() : null,
                  jurisdiction_name: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && jurisdiction ? jurisdiction.trim() : null,
                  activity_type: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&activityType ? activityType.trim() : null,
                  activity_title: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&activityTitle ? activityTitle.trim() : null,
                  assigned_to: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&assignedTo ? assignedTo.trim() : null,
                  phone_number: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&phoneNumber ? phoneNumber.trim() : null,
                  mobile_phone_number: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&cellPhone ? cellPhone.trim() : null,
                  inspection_type: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&inspectionType ? inspectionType.trim() : null,
                  contact_name: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&contactName ? contactName.trim() : null,
                  inspector_name: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&inspectorName ? inspectorName.trim() : null,
                  inspection_number: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&inspectionNumber ? inspectionNumber.trim() : null,
                  project_address: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&projectAddress ? projectAddress.trim() : null,
                  review_type: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&reviewType ? reviewType.trim() : null,
                  reviewer_name: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&reviewer ? reviewer.trim() : null,
                  inspection_date:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&inspectionDate?inspectionDate:null,
                  invoice_number:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&invoiceNumber?invoiceNumber:null,
                  date_invoiced:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&dateInvoiced?dateInvoiced:null,
                  date_paid:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&datePaid?datePaid:null,
                  invoice:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&invoice?invoice:null,
                  attachments:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&attachments?attachments:null,
                  email:addContactView&&email?email:null,
                  user_name:addContactView&&name?name:null,
                  company_name:addContactView&&companyName?companyName:null,
                  contact_type:addContactView&&contactType?contactType:null,
                  last_comment:(isActivityTable ||permitActivityTable||permitInspectionTable||permitConditionTable||permitFeesTable || permitDocumentTable) && lastComment?lastComment:null,
                  fee_name:(addFeesTable || permitFeesTable )&&name?name:null,
                  amount:(addFeesTable || permitFeesTable)&&amount?amount:null,
                  fee_service_type:addFeesTable&&feeServiceType?feeServiceType:null,
                  quantity:permitFeesTable&&quantity?quantity:null,
                  total:permitFeesTable&&total?total:null,
                  contact_id:permitConditionTable&&contactId?contactId:null,
                  admin_file_name:permitDocumentTable&&adminFileName?adminFileName:null,
                  client_file_name:permitDocumentTable&&clientFileName?clientFileName:null,
                  setting_doc:permitDocumentTable&&settingDoc?settingDoc:null,
                  document_name:permitDocumentTable&&name?name:null,
                  completed:permitDocumentTable&&completed?completed:null,
                  id:permitFeesTable&&id?id:null,
                  createdAt:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && createdAt ? createdAt : null,
                  createdBy:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && createdBy ? createdBy : null,
                  completedAt:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && completedAt ? completedAt : null,
                  completedBy:(isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable) && completedBy ? completedBy : null,
                  visible_filename: (isActivityTable||permitActivityTable||permitInspectionTable||permitConditionTable||permitDocumentTable||permitFeesTable)&&visibleFilename ? visibleFilename.trim() : null,
                  information: isActivityTable && information ? information.trim() : null,
                  // fee_quantity:addFeesTable&&feeQuantity?feeQuantity:null,
                  // ...searchObj,
                  // activity_type_name:permitActivityTable&&activityType?activityType:null,
              ...additional,
          };

      (rows(params) as Promise<any>)
        .then(({ data: response }) => {
          if (response && response.results) {
            if (onLoadGetData) {
              onLoadGetData(response.results);
            }
            fillColumns(response.results[0]);
            let sorted = response.results.slice();
            if(sortBy.length && (sortBy[0].id === "followUp" || sortBy[0].id === "inspectionDate")){
              sorted.sort((a, b) => {
                let dateA=a[sortBy[0].id]?a[sortBy[0].id]: sortBy[0].desc?"01/01/1970":"01/01/2050"
                let dateB=b[sortBy[0].id]?b[sortBy[0].id]: sortBy[0].desc?"01/01/1970":"01/01/2050"
                if (new Date(dateA.split(",")[0]) > new Date(dateB.split(",")[0]))
                    return sortBy[0].desc ? -1 : 1;
                  if (new Date(dateA.split(",")[0]) < new Date(dateB.split(",")[0]))
                    return sortBy[0].desc ? 1 : -1;
                return 0;
              });
            }
            setData(sorted);
            setManualTotal(
              response.count || response.highestCount,
            );
            setPageManualCount(
              Math.ceil(
                (response.count || response.highestCount) /
                  pageManualSize,
              ),
            );
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setIsLoading(false));
    }
  }, [
    pageIndex,
    pageManualSize,
    globalFilter,
    sortBy,
    tableLoadDataMode,
    updater,
    JSON.stringify(columnFilter),
    isLocked
  ]);

  const prevAdditionalRequestParams = usePrevious(
    additionalRequestParams,
  );

  React.useEffect(() => {
    if (
      !R.equals(
        prevAdditionalRequestParams,
        additionalRequestParams,
      )
    ) {
      gotoPage(0);
      updateTable();
    }
  }, [additionalRequestParams]);

  React.useEffect(() => {
    if (searchQuery !== globalFilter) {
      setGlobalFilter(searchQuery);
      updateTable();
    }
  }, [searchQuery]);

  React.useEffect(() => {
    setGlobalFilter(searchGlobal);
  }, [searchGlobal]);

  React.useEffect(() => {
    fillColumns(rows[0]);
  }, [rows]);


  // React.useEffect(() => {
  //   setPageSize(25);
  // }, []);

  React.useEffect(() => {
    if (
      onSelect &&
      typeof onSelect === 'function' &&
      (selectedFlatRowsCount ||
        (!selectedFlatRowsCount &&
          previousSelectedFlatRowsCount))
    ) {
    // const uniqData = [...selectedFlatRows,...previousSelectedRows].filter((v,i,a)=>a.findIndex(v2=>(v2.id!==v.id))!==i)
    const datas=[...selectedFlatRows,...previousSelectedRows].reduce((acc,val)=>{
      acc[val.id]=(acc[val.id]||0)+1;
      return acc
    },{})
    let index
    for(let key in datas){
      if(datas[key]==1){
       index=key
      }
    }
      onSelect(
        selectedFlatRows?.map(
          selectedRow => selectedRow.original,
        ),
        selectedRowIds,
        data[index]
      );
    }
  }, [selectedRowIds, selectedFlatRows]);

  React.useEffect(() => {
    if (onChangeLoadingStatus) {
      onChangeLoadingStatus(isLoading);
    }
  }, [isLoading]);

  // Creating the table skeleton
  const TableSkeleton = () => <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (column: any) => {
                      if (column.Header === '-') {
                        return null;
                      }

                      const isSpecialColumn = [
                        SELECTION_ID,
                        ACTIONS_ID,
                      ].includes(column.id);

                      const computedProps = {
                        style: null,
                        ...column.getHeaderProps(column.getSortByToggleProps()),
                      };

                      if (
                        isSpecialColumn ||
                        column?.id === 'checkBox'
                      ) {
                        computedProps.style = {
                          width:
                            column?.id === 'checkBox'
                              ? '60px'
                              : column.width,
                          textAlign:
                            column.id === ACTIONS_ID ||
                            column?.id === 'checkBox'
                              ? 'center'
                              : 'left',
                        };
                      }
                      // commented by mritunjoy
                      // JIRA ticket number - EA-41
                      // for removing the sorting icon in the daily inspection table

                      const sortDirection = column.isSortedDesc ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      );

                      const isSortIcon = column.id==="attachments"?false:initialUseSortBySelectedColumns
                        ? initialUseSortBySelectedColumns.includes(
                            column.id,
                          )
                        : true;
                      // if (!page.length && feesTable) {
                      //   return <th {...computedProps} />;
                      // }
                      return (
                        <th {...computedProps}>
                          {column.render('Header')}
                          {/* commented by mritunjoy
                          JIRA ticket number - EA-41
                          for removing the sorting icon in reverse way when clicked in the daily inspection table this was one of the dependency */}

                          {!isSpecialColumn &&
                            initialUseSortBy &&
                            isSortIcon &&
                            (column.isSorted ? (
                              sortDirection
                            ) : (
                              <UnfoldMoreIcon />
                            ))}
                        </th>
                      );
                    },
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {permitFeesTable && (data?.length || columnFilter?.length) ? (
                <tr>
                  <td />
                  <SearchTD>
                  <FeesSearchBar
                    columnName="id"
                    text={prevFilter("id")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                      debounceDelay={permitFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                  <SearchTD>
                  <FeesSearchBar
                    columnName="invoiceNumber"
                    text={prevFilter("invoiceNumber")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                      debounceDelay={permitFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="name"
                    text={prevFilter("name")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                      debounceDelay={permitFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="amount"
                    text={prevFilter("amount")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                      debounceDelay={permitFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="quantity"
                    text={prevFilter("quantity")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                      debounceDelay={permitFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="total"
                    text={prevFilter("total")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                      debounceDelay={permitFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                  <StyledSelectTd>
                    <InputSelect
                      options={[
                        // @ts-ignore
                        {
                          value: 'Pending',
                        },
                        // @ts-ignore
                        { value: 'Sent' },
                        // @ts-ignore
                        { value: 'Paid' },
                        // @ts-ignore
                        { value: 'Paid by Another Method' },
                        { value: 'Void' },
                        { value: 'Invoiced in QB'}
                      ]}
                      getOptionValue={(item: any) =>
                        item.value
                      }
                      getOptionLabel={(item: any) =>
                        item.value
                      }
                      // @ts-ignore
                      onChange={({ value }) =>
                        columnFilterUi(
                          'status',
                          value || undefined,
                        )
                      }
                    />
                  </StyledSelectTd>
                  <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={feeInvoicedDate}
                        onChange={value => {
                          setFeeInvoicedDate(value);
                          columnFilterUi(
                            'dateInvoiced',
                            formatDate(
                              value,
                              DEFAULT_DATE_FORMAT,
                            ),
                          );
                        }}
                      />
                      {feeInvoicedDate && (
                        <RemoveDateButton
                          onClick={() => {
                            setFeeInvoicedDate('');
                            columnFilterUi('dateInvoiced', '');
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                  <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={feePaidDate}
                        onChange={value => {
                          setFeePaidDate(value);
                          columnFilterUi(
                            'datePaid',
                            formatDate(
                              value,
                              DEFAULT_DATE_FORMAT,
                            ),
                          );
                        }}
                      />
                      {feePaidDate && (
                        <RemoveDateButton
                          onClick={() => {
                            setFeePaidDate('');
                            columnFilterUi('datePaid', '');
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                  <SearchTD>
                  <FeesSearchBar
                    columnName="invoice"
                    text={prevFilter("invoice")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                {/* Search bar for comments */}
                <SearchTD>
                  <FeesSearchBar
                    columnName="lastComment"
                    text={prevFilter("lastComment")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                {/* search bar for date created */}
                {/* <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCreated}
                        onChange={value => {
                          setDateCreated(value);
                          if(permitFeesTable){
                            columnFilterUi(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCreated && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCreated('');
                            if(permitFeesTable){
                              columnFilterUi(
                                'createdAt',
                                 ""
                              );
                            }else{
                              setFilter('createdAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for created by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="createdBy"
                    text={prevFilter("createdBy")}
                    onChange={permitFeesTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                </tr>
              ) : null}

              {colorSchema && !feesTable &&
              !isPermitActivitiesPage &&
              // data?.length &&
              activityType !== 'inspections' ? (
                <tr>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="projectName"
                      text={prevFilter("projectName")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="permit"
                      text={prevFilter("permit")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="jurisdiction"
                      text={prevFilter("jurisdiction")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="permitNumber"
                      text={prevFilter("permitNumber")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  {activityType === 'All' && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="activityType"
                        text={prevFilter("activityType")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {(activityType === 'Reviews' || activityType === 'InspectorReviews') && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="reviewType"
                        text={prevFilter("reviewType")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {/* {activityType === 'InspectorReviews' && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="reviewType"
                        text={prevFilter("type")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )} */}
                  <SearchTD>
                    <FeesSearchBar
                      columnName="title"
                      text={prevFilter("title")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>

                  <SearchTD>
                    <FeesSearchBar
                      columnName="status"
                      text={prevFilter("status")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>



                  {activityType === 'Reviews' && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="reviewer"
                        text={prevFilter("reviewer")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {activityType === 'Calls' && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="contact"
                        text={prevFilter("contact")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {activityType === 'Proposal' && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="contact"
                        text={prevFilter("contact")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {activityType === 'All' && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="information"
                        text={prevFilter("information")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={activityFollowUp}
                        onChange={value => {
                          setActivityFollowUp(value);
                          if(isActivityTable){
                            columnFilterUi(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {activityFollowUp && (
                        <RemoveDateButton
                          onClick={() => {
                            setActivityFollowUp('');
                            if(isActivityTable){
                              columnFilterUi(
                                'followUp',
                                 ""
                              );
                            }else{
                              setFilter('followUp', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="assignedTo"
                      text={prevFilter("assignedTo")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="lastComment"
                      text={prevFilter("lastComment")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  {activityType === 'All' && (
                    <>
                      <SearchTD>
                        <FeesSearchBar
                          columnName="phoneNumber"
                          text={prevFilter("phoneNumber")}
                          onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                          tableSearch
                        />
                      </SearchTD>
                      <SearchTD>
                        <FeesSearchBar
                          columnName="cellPhone"
                          text={prevFilter("cellPhone")}
                          onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                          tableSearch
                        />
                      </SearchTD>
                      {/* <SearchTD>
                        <FeesSearchBar
                          columnName="period"
                          text={prevFilter("period")}
                          onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                          tableSearch
                        />
                      </SearchTD>
                      <SearchTD>
                        <FeesSearchBar
                          columnName="inspectionType"
                          text={prevFilter("inspectionType")}
                          onChange={isActivityTable?columnFilterUi:setFilter}
                          debounceDelay={isActivityTable && 1000}
                          tableSearch
                        />
                      </SearchTD> */}
                      {/* <StyledDatePickerTd>
                        <DatePickerWrapper>
                          <DatePicker
                            error={false}
                            isRequired={false}
                            dateFormat={DEFAULT_DATE_FORMAT}
                            selected={inspectionDate}
                            onChange={value => {
                              setInspectionDate(value);
                              setFilter(
                                'inspectionDate',
                                formatDate(
                                  value,
                                  DEFAULT_DATE_FORMAT,
                                ),
                              );
                            }}
                          />
                          {inspectionDate && (
                            <RemoveDateButton
                              onClick={() => {
                                setInspectionDate('');
                                setFilter(
                                  'inspectionDate',
                                  '',
                                );
                              }}
                            >
                              X
                            </RemoveDateButton>
                          )}
                        </DatePickerWrapper>
                      </StyledDatePickerTd> */}

                    </>
                  )}

                </tr>
              ) : null}

              {/* removing conditions and adding debounce delay time */}
              {activityType === 'inspectorInspections' ? (
                <tr>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="projectNamePermitName"
                      text={prevFilter("projectNamePermitName")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="permitNumber"
                      text={prevFilter("permitNumber")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>

                  <SearchTD>
                    <FeesSearchBar
                      columnName="projectAddress"
                      text={prevFilter("projectAddress")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="inspectionNumber"
                      text={prevFilter("inspectionNumber")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="inspectionType"
                      text={prevFilter("inspectionType")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>
                  {!(isIpad && isInspector) && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="projectManager"
                        text={prevFilter("projectManager")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {!(isIpad && isInspector) && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="jurisdiction"
                        text={prevFilter("jurisdiction")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  {!inspectorCompletedInspectionPage && <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionDate}
                        onChange={value => {
                          setInspectionDate(value);
                          if(isActivityTable){
                            columnFilterUi(
                              'inspectionDate',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'inspectionDate',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionDate && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionDate('');
                            if(isActivityTable){
                              columnFilterUi(
                                'inspectionDate',
                                 ""
                              );
                            }else{
                              setFilter('inspectionDate', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>}
                  <SearchTD>
                    <FeesSearchBar
                      columnName="period"
                      text={prevFilter("period")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>
                  {/* {!(isIpad && isInspector) && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="contact"
                        text={prevFilter("contact")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                        tableSearch
                      />
                    </SearchTD>
                  )} */}
                  {!(isIpad && isInspector) && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="contact"
                        text={prevFilter("contact")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                  <SearchTD>
                    <FeesSearchBar
                      columnName="cellPhone"
                      text={prevFilter("cellPhone")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                      tableSearch
                    />
                  </SearchTD>
                  {!(isIpad && isInspector) && (
                    <SearchTD>
                      <FeesSearchBar
                        columnName="contactPhone"
                        text={prevFilter("contactPhone")}
                        onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={1000}
                        tableSearch
                      />
                    </SearchTD>
                  )}
                </tr>
              ) : null}

              {((data?.length || columnFilter?.length) && permitActivityTable) ? (
                <tr>
                <SearchTD>
                  <FeesSearchBar
                    columnName="activityType"
                    text={prevFilter("activityType")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                      debounceDelay={permitActivityTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="title"
                    text={prevFilter("title")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                      debounceDelay={permitActivityTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="status"
                    text={prevFilter("status")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="contact"
                    text={prevFilter("contact")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionFollowUp}
                        onChange={value => {
                          setInspectionFollowUp(value);
                          if(permitActivityTable){
                            columnFilterUi(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionFollowUp && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionFollowUp('');
                            if(permitActivityTable){
                              columnFilterUi(
                                'followUp',
                                 ""
                              );
                            }else{
                              setFilter('followUp', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                <SearchTD>
                  <FeesSearchBar
                    columnName="assignedTo"
                    text={prevFilter("assignedTo")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>

                {/* search bar for date created */}
                {/* <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCreated}
                        onChange={value => {
                          setDateCreated(value);
                          if(permitActivityTable){
                            columnFilterUi(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCreated && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCreated('');
                            if(permitActivityTable){
                              columnFilterUi(
                                'createdAt',
                                 ""
                              );
                            }else{
                              setFilter('createdAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for created by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="createdBy"
                    text={prevFilter("createdBy")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}

                  {/* search bar for date completed */}
                {/* <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCompleted}
                        onChange={value => {
                          setDateCompleted(value);
                          if(permitActivityTable){
                            columnFilterUi(
                              'completedAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'completedAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCompleted && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCompleted('');
                            if(permitActivityTable){
                              columnFilterUi(
                                'completedAt',
                                 ""
                              );
                            }else{
                              setFilter('completedAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for completed by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="completedBy"
                    text={prevFilter("completedBy")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                {/* <SearchTD>
                  <FeesSearchBar
                    columnName="attachments"
                    text={prevFilter("attachments")}
                    onChange={permitActivityTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>  */}
                </tr> 
              ): null }
              {((data?.length || columnFilter?.length) && permitInspectionTable) ? (
                <tr>
                <SearchTD>
                  <FeesSearchBar
                    columnName="title"
                    text={prevFilter("title")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                      debounceDelay={permitInspectionTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="status"
                    text={prevFilter("status")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="inspectionNumber"
                    text={prevFilter("inspectionNumber")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                      debounceDelay={permitInspectionTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="inspectionType"
                    text={prevFilter("inspectionType")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionDate}
                        onChange={value => {
                          setInspectionDate(value);
                          if(permitInspectionTable){
                            columnFilterUi(
                              'inspectionDate',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'inspectionDate',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionDate && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionDate('');
                            if(permitInspectionTable){
                              columnFilterUi(
                                'inspectionDate',
                                 ""
                              );
                            }else{
                              setFilter('inspectionDate', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                <SearchTD>
                  <FeesSearchBar
                    columnName="inspector"
                    text={prevFilter("inspector")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                 <SearchTD>
                  <FeesSearchBar
                    columnName="contact"
                    text={prevFilter("contact")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionFollowUp}
                        onChange={value => {
                          setInspectionFollowUp(value);
                          if(permitInspectionTable){
                            columnFilterUi(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionFollowUp && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionFollowUp('');
                            if(permitInspectionTable){
                              columnFilterUi(
                                'followUp',
                                 ""
                              );
                            }else{
                              setFilter('followUp', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                <SearchTD>
                  <FeesSearchBar
                    columnName="assignedTo"
                    text={prevFilter("assignedTo")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="visibleFilename"
                    text={prevFilter("visibleFilename")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                {/* search bar for date created */}
                {/* {!isPermitActivitiesPage && <><StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCreated}
                        onChange={value => {
                          setDateCreated(value);
                          if(permitInspectionTable){
                            columnFilterUi(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCreated && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCreated('');
                            if(permitInspectionTable){
                              columnFilterUi(
                                'createdAt',
                                 ""
                              );
                            }else{
                              setFilter('createdAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for created by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="createdBy"
                    text={prevFilter("createdBy")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}

                  {/* search bar for date completed */}
                {/* <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCompleted}
                        onChange={value => {
                          setDateCompleted(value);
                          if(permitInspectionTable){
                            columnFilterUi(
                              'completedAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'completedAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCompleted && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCompleted('');
                            if(permitInspectionTable){
                              columnFilterUi(
                                'completedAt',
                                 ""
                              );
                            }else{
                              setFilter('completedAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for completed by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="completedBy"
                    text={prevFilter("completedBy")}
                    onChange={permitInspectionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD></>} */}
                </tr>
              ): null }
               {((data?.length || columnFilter?.length) && permitConditionTable) ? (
                <tr>
                   <SearchTD>
                  <FeesSearchBar
                    columnName="activityType"
                    text={prevFilter("activityType")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                      debounceDelay={permitConditionTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="title"
                    text={prevFilter("title")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                      debounceDelay={permitConditionTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="status"
                    text={prevFilter("status")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                {/* <SearchTD>
                  <FeesSearchBar
                    columnName="contact"
                    text={prevFilter("contact")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                      debounceDelay={permitConditionTable && 1000}
                    tableSearch
                  />
                </SearchTD> */}
                <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionFollowUp}
                        onChange={value => {
                          setInspectionFollowUp(value);
                          if(permitConditionTable){
                            columnFilterUi(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionFollowUp && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionFollowUp('');
                            if(permitConditionTable){
                              columnFilterUi(
                                'followUp',
                                 ""
                              );
                            }else{
                              setFilter('followUp', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                <SearchTD>
                  <FeesSearchBar
                    columnName="assignedTo"
                    text={prevFilter("assignedTo")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="attachments"
                    text={prevFilter("attachments")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> 
                {/* search bar for date created */}
                {/* <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCreated}
                        onChange={value => {
                          setDateCreated(value);
                          if(permitConditionTable){
                            columnFilterUi(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'createdAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCreated && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCreated('');
                            if(permitConditionTable){
                              columnFilterUi(
                                'createdAt',
                                 ""
                              );
                            }else{
                              setFilter('createdAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for created by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="createdBy"
                    text={prevFilter("createdBy")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}

                  {/* search bar for date completed */}
                {/* <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={dateCompleted}
                        onChange={value => {
                          setDateCompleted(value);
                          if(permitConditionTable){
                            columnFilterUi(
                              'completedAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'completedAt',
                              formatDate(
                                value,
                                INVOICES_SEARCH_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {dateCompleted && (
                        <RemoveDateButton
                          onClick={() => {
                            setDateCompleted('');
                            if(permitConditionTable){
                              columnFilterUi(
                                'completedAt',
                                 ""
                              );
                            }else{
                              setFilter('completedAt', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd> */}
                  {/* search bar for completed by */}
                  {/* <SearchTD>
                  <FeesSearchBar
                    columnName="completedBy"
                    text={prevFilter("completedBy")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                <td></td>
                {/* <SearchTD>
                  <FeesSearchBar
                    columnName="contactId"
                    text={prevFilter("contactId")}
                    onChange={permitConditionTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                </tr>
              ): null }
              {((data?.length || columnFilter?.length) && permitDocumentTable) ? (
                <tr>
                  <StyledSelectTd>
                    <InputSelect
                      options={[
                        // @ts-ignore
                        {
                          value: 'Yes',
                        },
                        // @ts-ignore
                        { value: 'No' },
                        // @ts-ignore
                      ]}
                      getOptionValue={(item: any) =>
                        item.value
                      }
                      getOptionLabel={(item: any) =>
                        item.value
                      }
                      // @ts-ignore
                      onChange={({ value }) =>
                        columnFilterUi(
                          'completed',
                          value || undefined,
                        )
                      }
                    />
                  </StyledSelectTd>
                <SearchTD>
                  <FeesSearchBar
                    columnName="name"
                    text={prevFilter("name")}
                    onChange={permitDocumentTable?columnFilterUi:setFilter}
                      debounceDelay={permitDocumentTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="adminFileName"
                    text={prevFilter("adminFileName")}
                    onChange={permitDocumentTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="clientFileName"
                    text={prevFilter("clientFileName")}
                    onChange={permitDocumentTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="status"
                    text={prevFilter("status")}
                    onChange={permitDocumentTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                 <SearchTD>
                  <FeesSearchBar
                    columnName="lastComment"
                    text={prevFilter("lastComment")}
                    onChange={permitDocumentTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                </tr>
              ): null }
              {((data?.length || columnFilter?.length) && addContactView) ? (
                <tr>
                  <td></td>
                <SearchTD>
                  <FeesSearchBar
                    columnName="name"
                    text={prevFilter("name")}
                    onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                {/* <SearchTD>
                  <FeesSearchBar
                    columnName="contactType"
                    text={prevFilter("contactType")}
                    onChange={addContactView?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                <SearchTD>
                  <FeesSearchBar
                    columnName="email"
                    text={prevFilter("email")}
                    onChange={addContactView?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="companyName"
                    text={prevFilter("companyName")}
                    onChange={addContactView?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                </tr>
              ): null}
               {((data?.length || columnFilter?.length)&& addFeesTable) ? (
                <tr>
                  <td></td>
                <SearchTD>
                  <FeesSearchBar
                    columnName="name"
                    text={prevFilter("name")}
                    onChange={addFeesTable?columnFilterUi:setFilter}
                      debounceDelay={addFeesTable && 1000}
                    tableSearch
                  />
                </SearchTD>
                {/* <SearchTD>
                  <FeesSearchBar
                    columnName="contactType"
                    text={prevFilter("contactType")}
                    onChange={addContactView?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                <SearchTD>
                  <FeesSearchBar
                    columnName="amount"
                    text={prevFilter("amount")}
                    onChange={addFeesTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                <SearchTD>
                  <FeesSearchBar
                    columnName="feeServiceType"
                    text={prevFilter("feeServiceType")}
                    onChange={addFeesTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD>
                {/* <SearchTD>
                  <FeesSearchBar
                    columnName="feeQuantity"
                    text={prevFilter("feeQuantity")}
                    onChange={addFeesTable?columnFilterUi:setFilter}
                    debounceDelay={1000}
                    tableSearch
                  />
                </SearchTD> */}
                </tr>
              ): null}
              {colorSchema &&
              // data?.length &&
              activityType === 'inspections' ? (
                <tr>
                  <SearchTD />
                  <SearchTD>
                    <FeesSearchBar
                      columnName="projectName"
                      text={prevFilter("projectName")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="permit"
                      text={prevFilter("permit")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="projectAddress"
                      text={prevFilter("projectAddress")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="jurisdiction"
                      text={prevFilter("jurisdiction")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="inspectionNumber"
                      text={prevFilter("inspectionNumber")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="permitNumber"
                      text={prevFilter("permitNumber")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="status"
                      text={prevFilter("status")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="inspectionType"
                      text={prevFilter("inspectionType")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionDate}
                        onChange={value => {
                          setInspectionDate(value);
                          if(isActivityTable){
                            columnFilterUi(
                              'inspectionDate',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'inspectionDate',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionDate && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionDate('');
                            if(isActivityTable){
                              columnFilterUi(
                                'inspectionDate',
                                 ""
                              );
                            }else{
                              setFilter('inspectionDate', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                  {/* <SearchTD>
                    <FeesSearchBar
                      columnName="period"
                      text={prevFilter("period")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD> */}
                  <SearchTD>
                    <FeesSearchBar
                      columnName="inspector"
                      text={prevFilter("inspector")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <StyledDatePickerTd>
                    <DatePickerWrapper>
                      <DatePicker
                        error={false}
                        isRequired={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        selected={inspectionFollowUp}
                        onChange={value => {
                          setInspectionFollowUp(value);
                          if(isActivityTable){
                            columnFilterUi(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }else{
                            setFilter(
                              'followUp',
                              formatDate(
                                value,
                                DEFAULT_DATE_FORMAT,
                              ),
                            );
                          }
                        }}
                      />
                      {inspectionFollowUp && (
                        <RemoveDateButton
                          onClick={() => {
                            setInspectionFollowUp('');
                            if(isActivityTable){
                              columnFilterUi(
                                'followUp',
                                 ""
                              );
                            }else{
                              setFilter('followUp', '');
                            }
                          }}
                        >
                          X
                        </RemoveDateButton>
                      )}
                    </DatePickerWrapper>
                  </StyledDatePickerTd>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="assignedTo"
                      text={prevFilter("assignedTo")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="phoneNumber"
                      text={prevFilter("phoneNumber")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD>
                    <FeesSearchBar
                      columnName="cellPhone"
                      text={prevFilter("cellPhone")}
                      onChange={isActivityTable?columnFilterUi:setFilter}
                      debounceDelay={isActivityTable && 1000}
                      tableSearch
                    />
                  </SearchTD>
                  <SearchTD />
                </tr>
              ) : null}
              {page.length ? (
                page.map((row, idx) => {
                  prepareRow(row);
                  return (
                    <>
                    {additionalTopRow && <AdditionalRow>
                              {additionalTopRow?.data?.map(
                                el => el.datePaid ==
                                  row.original.datePaid && el?.datePaid != page[idx - 1]?.original?.datePaid && (
                                  <td
                                    key={el?.id}
                                    colSpan={
                                      row.cells.length
                                    }
                                  >
                                    <b>Date: </b>

                                    {el.datePaid && (
                                      <>
                                        {el.datePaid}
                                      </>
                                    )}
                                  </td>
                                )                         )}
                            </AdditionalRow>}
                                  
                      <tr
                        {...row.getRowProps()}
                        className={
                          !!row.original?.id &&
                          hightLightSelectedRow?.find(
                            item =>
                              item?.id === row.original?.id,
                          )
                            ? 'highlight-row'
                            : 'table-row'
                        }
                        style={
                          colorSchema &&
                          row?.original?.isEmergency
                            ? { backgroundColor: '#FAB5A8' }
                            : italic &&
                              row?.original?.isCompleted
                            ? { fontStyle: 'italic' }
                            : {}
                        }
                      >
                        {row.cells.map(cell => {
                          return cell.render('Cell', {
                            ...cell.getCellProps(),
                          });
                        })}
                      </tr>                    
                      <AdditionalRow>
                        <td colSpan={row.cells.length}>
                          <RecordData data={additionalRow?.recordData} row={row} />                                                                                                                                                 
                        </td>        
                      </AdditionalRow>                   
                      {additionalRow ? (
                        additionalRow?.isDailyInspection ? (
                          <>
                            <AdditionalRow>
                              {/* EA-176
                            mritunjoy */}                             
                              {additionalRow?.informationData?.map(
                                el =>
                                  el.id ===
                                    row.values.id && (
                                    <td
                                      key={el.id}
                                      colSpan={
                                        row.cells.length
                                      }
                                    >
                                      <b>Information: </b>

                                      {el.information && (
                                        <>
                                          {el.information}
                                        </>
                                      )}
                                    </td>
                                  ),
                              )}
                            </AdditionalRow>
                            <AdditionalRow>
                              {additionalRow?.commentData.map(
                                el =>
                                  el.id ===
                                    row.values.id && (
                                    <td
                                      key={el.id}
                                      colSpan={
                                        row.cells.length
                                      }
                                    >
                                      <b>Comment: </b>
                                      {el.lastComment &&
                                        formatDateTime(
                                          el.lastComment
                                            ?.createdAt,
                                          DEFAULT_DATE_FORMAT,
                                        )}{' '}
                                      {el.lastComment && (
                                        <>
                                          {el.lastComment?.text.replace(
                                            /<[^>]*>?/gm,
                                            '',
                                          )}{' '}
                                          |{' '}
                                          {el.lastComment &&
                                            el.lastComment
                                              ?.username}
                                          |{' '}
                                        </>
                                      )}
                                    </td>
                                  ),
                              )}
                            </AdditionalRow>
                          </>
                        ) : (
                          <>
                            <AdditionalRow>
                              {additionalRow?.data.map(
                                el =>
                                  el.id ===
                                    row.original.id && (
                                    <td
                                      key={el.id}
                                      colSpan={
                                        row.cells.length
                                      }
                                    >
                                      <b>Information: </b>
                                      {el.information}
                                    </td>
                                  ),
                              )}
                            </AdditionalRow>
                            <AdditionalRow>
                              {additionalRow?.data.map(
                                el =>
                                  el.id ===
                                    row.original.id && (
                                    <td
                                      key={el.id}
                                      colSpan={
                                        row.cells.length
                                      }
                                    >
                                      {el.lastComment ? (
                                        <>
                                          <b>
                                            Last Comment:{' '}
                                          </b>
                                          {formatDateTime(
                                            el.lastComment
                                              ?.createdAt,
                                            DEFAULT_DATE_FORMAT,
                                          )}{' '}
                                          |{' '}
                                          {el.lastComment?.text.replace(
                                            /<[^>]*>?/gm,
                                            '',
                                          )}{' '}
                                          |{' '}
                                          {
                                            el.lastComment
                                              ?.username
                                          }
                                        </>
                                      ) : (
                                        <b>Last Comment:</b>
                                      )}
                                    </td>
                                  ),
                              )}
                            </AdditionalRow>
                          </>
                        )
                      ) : null}
                    </>
                  );
                })
              ) : (
                <MetaMessage>
                  No matching records found
                </MetaMessage>
              )}
              {/* EA-86
              creating additional bottom row
              mritunjoy */}
              {additionalBottomRow?.length > 0
                ? additionalBottomRow.map(el => (
                    <div style={{ padding: '1rem' }}>
                      <b>{el.title}: </b>
                      <b style={{ marginLeft: '1rem' }}>
                        {el.value}{' '}
                      </b>
                    </div>
                  ))
                : null}
            </tbody>
          </table>



  return (
    <Container
    smallPrintHeader={smallPrintHeader}
    inspectorCompletedInspectionPage={inspectorCompletedInspectionPage}
      feesTable={feesTable}
      colorSchema={colorSchema}
    >
      {!isLoading && !error ? (
        <>
          {!customSearch && (
            <SearchContainer>
              <SearchBar
                text={globalFilter}
                onChange={setGlobalFilter}
              />
            </SearchContainer>
          )}
          {(addContactView || permitUserId) ? <InfiniteScrollContainer><InfiniteScroll
            dataLength={newData.length}
            next={addContactView ? fetchMoreData : permitUserId ? fetchContactProjectsMoreData : null}
            height={250}
            style={addContactView ? {height:"calc(97vh - 300px)"} : permitUserId ? {height:"calc(110vh - 300px)"} : {}}
            hasMore={data.length>24}
            loader={<h4>Loading...</h4>}
          >
          <TableSkeleton />
          </InfiniteScroll></InfiniteScrollContainer> : <TableSkeleton />}
          <MetaContainer>
            {(pageCount > 1 || pageManualCount > 1) && (
              <Pagination
                pageSize={
                  isAsync
                    ? pageManualSize
                    : pageSize
                }
                current={pageIndex + 1}
                total={
                  isAsync
                    ? pageManualCount * pageManualSize
                    : pageCount * pageSize
                }
                onChange={pageNumber =>
                  gotoPage(pageNumber - 1)
                }
              />
            )}
            {showPageManualSize && <select
              value={
                isAsync
                  ? pageManualSize
                  : pageSize
              }
              onChange={e => {
                if (isAsync ) {
                  setPageManualSize(Number(e.target.value));
                } else {
                  setPageSize(Number(e.target.value));
                }
              }}
            >
              {TABLE_DEFAULT_PAGE_SIZES.map(ps => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>}
          </MetaContainer>
        </>
      ) : error ? (
        <ErrorContainer>
          Error. Something went wrong. Please, contact
          support.
        </ErrorContainer>
      ) : (
        <LoaderWithContainer />
      )}
    </Container>
  );
};

const Container = styled.div<{smallPrintHeader:boolean}>`
  position: relative;
  min-height: 100px;
  ${({ feesTable, colorSchema }) =>
    feesTable || colorSchema ? 'overflow: visible;' : ''}

  table {
    color: var(--mainText);
    font-size: 0.875rem;
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: separate;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    ${({ inspectorCompletedInspectionPage }) => inspectorCompletedInspectionPage ? 'table-layout:fixed;' : ''}

    thead {
      color: var(--defaultTableCellTitle);
      background-color: var(--defaultTableCell);

      @media print {
        font-size: ${({smallPrintHeader}) => smallPrintHeader ? '7px' : 'inherit'} ;
      }
      th {
        position: relative;

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    th {
      user-select: none;

      > svg {
        width: 1rem;
        height: auto;
        font-size: 1rem;
      }
    }

    tr {
      &:nth-child(even) {
        background-color: var(--defaultTableCell);
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      text-align: left;
      padding: 18px 16px;
      border-bottom: 1px solid var(--border);
      max-width: ${({ crawlingColumn }) =>
        crawlingColumn ? '120px' : '200px'};
      overflow: ${({ crawlingColumn }) =>
        crawlingColumn ? 'auto' : 'auto'};
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left: 1px solid var(--border);
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid var(--border);
      }

      button {
        background: transparent;
        outline: none;
        border: none;
        text-decoration: underline;
        text-align: left;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .highlight-row {
      background-color: rgba(11, 107, 155, 0.1) !important;

      &:nth-child(odd) {
        background-color: rgba(
          11,
          107,
          155,
          0.08
        ) !important;
      }

      td {
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
`;

const SearchTD = styled.td`
  max-width: 137px !important;
  min-width:  120px;
`;

const ActionsContainer = styled.div`
  text-align: center;

  .MuiButtonBase-root {
    margin: 0 6px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 0 30px 0;
  justify-content: flex-end;
`;

const InfiniteScrollContainer = styled.div`
 th{
  td {
    padding: 0px !important;
   }
 }
`;

export const MetaContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 30px 0 0 0;

  > select {
    outline: none;
    padding: 2px 4px;
    margin-left: auto;
    border-radius: 6px;
    border: 2px solid var(--border);
  }
`;

const MetaMessageContainer = styled.tr`
  border: none;
  width: 100%;
  height: 54px;
  position: relative;

  > td {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--secondaryText);
  }
`;

const ErrorContainer = styled.div`
  text-align: center;
  font-weight: bold;
`;

const StyledSelectTd = styled.td`
  max-width: 150px !important;
  min-width:  120px;
  overflow: visible !important;
  .react-select__control {
    border-radius: 4px;
  }
  .react-select__placeholder {
    font-size: 16px;
    color: #afafaf;
  }
`;

const StyledDatePickerTd = styled.td`
  max-width: 150px !important;
  min-width: 120px;
  input {
    width: 90%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 6px 2px;
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker__navigation--previous {
    left: 10px;
    border: 0.45rem solid transparent;
    border-right-color: #ccc;
  }

  .react-datepicker__navigation--next {
    border: 0.45rem solid transparent;
    border-left-color: #ccc;
  }
`;

const RemoveDateButton = styled.div`
  font-weight: bold;
  padding: 2px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
`;

const AdditionalRow = styled.tr`
  & td {
    padding: 4px 8px !important;
    background-color: #fff;
  }
`;

type RowData<T> = {
  [key in keyof T]: any;
} & {
  [key: string]: any;
};

interface Props<T extends object, P extends object> {
  columns: (column: keyof T) => string | Column<T>[];
  docs?: boolean;
  rows: (
    params: object,
  ) =>
    | Promise<AxiosResponse<PaginatedList<RowData<P>>>>
    | RowData<T>[];
  columnsOrder?: any;
  onEdit?: (data: T) => void;
  onSelect?: (
    data: T[],
    selectedIndexes: { [index: number]: boolean },
    selectedData,
  ) => void;
  onDelete?: (data: T) => void;
  onConfigure?: (data: T) => void;
  searchQuery?: string;
  customSearch?: boolean;
  manuallyUpdate?: number;
  formatValue?: (value: any, column?: string) => any;
  handleCellClick?: (rowValue, column?: string) => any;
  cellClassName?: {
    column: string;
    className: string;
  }[];
  additionalRequestParams?: object;
  cellCallbackFnIsOn?: string[];
  onLoadGetData?: (data: T) => void;
  hightLightSelectedRow?: {
    id: any;
    [key: string]: string;
  }[];
  requestParamsIsCanceled?: boolean;
  initialUseSortBy?: boolean;
  useViewModeInsteadEdit?: boolean;
  initialUseSortBySelectedColumns?: string[];
  initialSortBy?: {
    id: string;
    desc: boolean;
  }[];
  onChangeLoadingStatus?: (isLoading: boolean) => void;
  additionalActions?: (
    data: T,
  ) =>
    | {
        title: string;
        handler: (data: T) => void;
        Icon: React.ReactNode | any;
      }[]
    | undefined;
  ticketStatuses?: Array<any>;
  onTicketStatusChange?: (data: any) => void;
  currentUserName?: string;
  isPermitManager?: boolean;
  isMultipleDocuments?: boolean;
  handleFeeSelect?: (e: any, data: any) => void;
  handleQuantityChange?: (e: any, data: any) => void;
  handleAllFeesSelect?: (e: any) => void;
  allFeesSelected?: boolean;
  feesTable?: boolean;
  colorSchema?: boolean;
  italic?: boolean;
  activityType?: string;
  additionalRow?: object;
  isRunnerCheckbox?: boolean;
  additionalBottomRow?: Array<any>;
  isPermitActivitiesPage?: boolean;
  inspectorCompletedInspectionPage?: boolean;
  addContactView?: boolean;
  isLocked?: boolean;
  showPageManualSize?: boolean;
  reviewerCommentData?: Array<object>;
  invoicePage?: boolean;
}

const ReactMemo: <T>(C: T) => T = React.memo;

export default ReactMemo(Table);
