import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputTextWithLabel,
  InputBaseContainer,
  CheckboxWithLabel,
  InputSelectWithLabel,
  ButtonWithLoader,
} from '../molecules';

import {
  getFranchiseList,
  FranchiseType,
} from '../api/franchise';

import { ActivitiesType } from '../api/activities';

import { FormServerError } from '../types';
import { GET_ALL_QUERY_PARAMS } from '../constants';

import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import useUserRole from '../hooks/useUserRole';

const labels = {
  name: 'Title',
  active: 'Active',
  franchise: 'Branch',
  isDefault: 'Set as default in new Branch',
};

const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  franchise: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a franchise from the list')
    .nullable()
    .label(labels.franchise),
  isActive: yup.boolean().label(labels.active),
  isDefault: yup.boolean().label(labels.isDefault),
});

const ActivitiesTitleForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new activity title',
  updateButtonText = 'Update activity title',
  initialErrors,
  activitiesQuery,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    formState,
  } = useForm<ActivitiesType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as ActivitiesType),
  });

  const { isSuperAdmin } = useUserRole();

  const [isLoading, setIsLoading] = React.useState(false);
  const [
    franchisesData,
    setFranchisesData,
  ] = React.useState([]);

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<ActivitiesType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  React.useEffect(() => {
    setIsLoading(true);

    getFranchiseList({
      ...GET_ALL_QUERY_PARAMS,
      ordering: 'name',
    })
      .then(response =>
        setFranchisesData(response.data.results))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (initialValues?.id && franchisesData.length) {
      setValue(
        'franchise',
        franchisesData.filter(item =>
          initialValues.franchise.includes(item.id)),
      );
    }
  }, [franchisesData]);

  const middleware = (
    submitFn: (data: ActivitiesType) => void,
  ) => (data: ActivitiesType) => {
    submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      activityType: activitiesQuery?.id,
      franchise: (data.franchise as FranchiseType[])
        ?.filter(item => item.id)
        .map(item => item.id),
    });
  };

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="franchise"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                selectAllActive
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading || !isSuperAdmin}
                label={labels.franchise}
                error={errors.franchise}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'franchise',
                  schema,
                )}
                options={franchisesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('name', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="isActive"
            control={control}
            valueName="checked"
            defaultValue={
              initialValues
                ? initialValues.isActiveStatus.toLowerCase() ===
                  'true'
                : true
            }
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.isActive}
                label={labels.active}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        {isSuperAdmin && (
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: ActivitiesType;
  onSubmit: (data: ActivitiesType) => void;
  initialErrors?: FormServerError<ActivitiesType>;
  activitiesQuery?: ActivitiesType;
  modalError: boolean;
}

export default ActivitiesTitleForm;
