import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { getSignature } from '../../api/userAuth';
import { setUserInfo } from '../../redux/ducks/userAuth';

const SignatureImage = ({ setShowEditor }) => {
  const info = useSelector((s: RootState) => s.userAuth.info);
  const dispatch = useDispatch();
  useEffect(() => {
    if (info && info.id) {
      const loadImg = async () => {
        const result = await getSignature(info.id);
        dispatch(setUserInfo({...info,signature: result}));
      };
      loadImg();
    }
  }, [info.id,info.signature]);
  return (
    <div>
      Your Signature
      <ImgWrapper>
       {info.signature && <img src={`data:image\/png;base64,${info.signature}`} />}
      </ImgWrapper>
      <ButtonWrapper>
        <Button color="primary" variant="contained" onTouchEnd={() => setShowEditor(true)} onMouseUp={() => setShowEditor(true)}>
          Change Signature
        </Button>
      </ButtonWrapper>
    </div>
  );
};

export default SignatureImage;

const ButtonWrapper = styled.div`
  padding-top: 20px;
`;

const ImgWrapper = styled.div`
  border: 1px solid grey;
  border-radius: 6px;
  padding: 3px;
  width: 407px;
  height: 108px;
`;
