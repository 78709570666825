import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';

const HelpPage = () => {
  return (
    <ClientContainer>
      <BannerStrip label="HELP"></BannerStrip>
      <WrapperWithRightDrawer>HELP</WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default HelpPage;
