export const labels = {
  dailyInspectionReport: 'dailyInspectionReport',
  runnerReport: 'runnerReport',
  formsReport: 'formsReport',
  inspectionByPermitReport: 'inspectionByPermitReport',
  businessToBeInvoicedReport: 'businessToBeInvoicedReport',
  companyOutstandingFeesReport:'companyOutstandingFeesReport',
  notInvoicedPermittingFeesReport: 'notInvoicedPermittingFeesReport',
  paidFeesReport: 'paidFeesReport',
  inspectorTimeTrackingReport: 'inspectorTimeTrackingReport',
  planReviewerTimeTrackingReport: 'planReviewerTimeTrackingReport',
  permitReviewCommand:'permitReviewCommand'
};

 export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '180px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};