import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  register: any;
  errors: any;
  name: string;
  label: string;
};
const GeneralTextField: FC<Props> = ({
  register,
  errors,
  name,
  label,
}) => {
  return (
    <AddressFieldWrapper>
      <Label error={errors[name] !== undefined}>{label}</Label>
      <Input type="text" ref={register} name={name} error={errors[name] !== undefined} />
      {errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
    </AddressFieldWrapper>
  );
};

export default GeneralTextField;

const AddressFieldWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const Input = styled.input<{error: boolean}>`
  width: 100%;
  border: ${({error}) => error ? '1px solid red' : '1px solid #ccc'} ;
  &:focus-visible {
   border:  ${({error}) => error ? '2px solid red' : '2px solid #ccc'} ;
   outline: none;
  }
  border-radius: 4px;
  height: 48px;
  padding-left: 19px;
  font-size: 16px;
  color: #555;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
`;

const Label = styled.div<{error: boolean}>`
  font-size: 17px;
  color: ${({error}) => error ? 'red' : '#316B99'}
`;

