import React, { FC } from 'react';
import styled from 'styled-components';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch } from 'react-redux';
import { setShowDrawerContent } from '../../../../../redux/slices/generalSlice';

const DrawerContent: FC = ({ children }) => {    
  const dispatch = useDispatch();
    const CloseButton = HighlightOffIcon;
    const handleOnClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setShowDrawerContent(false));
      };
  return (
    <DetailsContainer>
      <CloseButtonWrapper onClick={handleOnClick}>
        <CloseButton />
      </CloseButtonWrapper>
      {children}
    </DetailsContainer>
  );
};

export default DrawerContent;

const DetailsContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 3.5em;
  border-radius: 12px;
  box-shadow: 7px 7px 13px darkgrey;
  @media only screen and (max-width: 840px) {
    margin:3px;
  }
`;

const CloseButtonWrapper = styled.div`
  zoom: 1.6;
  top: -25px;
  right: -25px;
  position: absolute;
  width: min-content;
  height: min-content;
  cursor: pointer;
  @media only screen and (max-width: 840px) {
    top: 5px;
  right: 5px;
  }
`;