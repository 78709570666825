import { useEffect, useRef, useState } from 'react';
import { differenceInMinutes, format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import useUserRole from './hooks/useUserRole';
import { logOut } from './auth';
import { clearStore } from './modules/clientModule/utils';
import { setUsers } from './redux/slices/clientSlice';
import { RootState } from './redux/root';
import { sessionLogin } from './api/userAuth';
import { useGetVocabularyQuery } from './redux/rtkQuery/apiSlice';
import { getVocabulariesDataList } from './api/vocabulary';

const useAppTimeout = () => {
  const employeeKey = 'employeeTimeoutMinutes';
  const regularKey = 'inspectorTImoutMinutes';
  const regularMinutes = localStorage.getItem(regularKey);
  const employeeMinutes = localStorage.getItem(employeeKey);
  const interval = 2000;
  const regularTimeout = regularMinutes ? Number(regularMinutes) : 59;
  const employeeTimeout = employeeMinutes ? Number(employeeMinutes) : 8 * 59;
  const timeOutValue = useRef<number>(regularTimeout);
  const dispatch = useDispatch();
  const latest = useRef<number>(0);
  const keyboardTime = useRef<string>('');
  const mouseMoveTime = useRef<string>('');
  const mouseDownTime = useRef<string>('');
  const scrollTime = useRef<string>('');
  const touchStartTime = useRef<string>('');
  const {isSuperAdmin,isFranchiseAdmin,isInspector,isClient, isEmployee } = useUserRole();  
  const info = useSelector((s:RootState) => s.userAuth.info);

  const validateTimeout = (source: string) => {
    const latestTime = new Date();
    if (source === 'mouseMove') {
      mouseMoveTime.current = format(latestTime, 'yyyy-MM-dd HH:mm:ss');
    }
    if (source === 'mouseDown') {
      mouseDownTime.current = format(latestTime, 'yyyy-MM-dd HH:mm:ss');
    }

    if (source === 'keyDown') {
      keyboardTime.current = format(latestTime, 'yyyy-MM-dd HH:mm:ss');
    }

    if (source === 'touchStart') {
      touchStartTime.current = format(latestTime, 'yyyy-MM-dd HH:mm:ss');
    }

    if (source === 'scroll') {
      scrollTime.current = format(latestTime, 'yyyy-MM-dd HH:mm:ss');
    }
    latest.current = latestTime.getTime();
  };
  const timeOutFunction = async () => {
    const latestDate = new Date(latest.current);
    const now = new Date();
    const diff = differenceInMinutes(now, latestDate);
    if (latest.current > 0 && diff > timeOutValue.current - 1 && diff !== 0) {
      const logData = {
        lastKeyboardTime: keyboardTime.current,
        lastmouseMoveTime: mouseMoveTime.current,
        lastmouseDownTime: mouseDownTime.current,
        lastScrollTime: scrollTime.current,
        lasttouchDownTime: touchStartTime.current,
        latest: format(latestDate, 'yyyy-MM-dd HH:mm:ss'),
        diff: diff,
        timeoutValue: timeOutValue.current,
        now: format(now, 'yyyy-MM-dd HH:mm:ss'),
      };
      const result = await getVocabulariesDataList({vocabulary_entity:12});
      const data: any = result.data;
      if(data && data.results && data.results.length > 0){
        const item = data.results.find(v => v.title === 'Session Log');
        if(item && item.isActive){
          await sessionLogin(JSON.stringify(logData),info.id);
        }
      }      
      
      dispatch(setUsers([]));
      logOut();
      clearStore(dispatch);
      
    } else {
      setTimeout(timeOutFunction, interval);
    }
  };

  useEffect(() => {
    if (isEmployee && timeOutValue.current === regularTimeout) {
      timeOutValue.current = employeeTimeout;
    } else {
      timeOutValue.current = regularTimeout;
    }
  }, [isSuperAdmin,isFranchiseAdmin,isInspector,isClient, isEmployee]);
  useEffect(() => {
    if (window.location.pathname !== '/login') {
      setTimeout(() => {
        timeOutFunction();
      }, interval);
    }
    latest.current = new Date().getTime();
  }, []);

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      setTimeout(() => {
        timeOutFunction();
      }, interval);
    }
  }, [window.location.pathname]);
  return {
    validateTimeout,
  };
};

export default useAppTimeout;
