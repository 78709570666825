import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import SubMenuHeadLine from '../../organisms/SubMenuHeadLine';
import { ActivitiesTypeLink } from '../../api/activities';
import { getVocabulariesDataList } from '../../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import convertObjToRouteObj from '../../utils/convertObjToRouteObj';
import { ROUTES } from '../../constants/routes';
import isRoutePathExact from '../../utils/isRoutePathExact';
import AllActivitiesTable from '../../organisms/Activities/AllActivitiesTable';
import { sendInspectionRequestEmail } from '../../api/inspections';
import useTablePage from '../../hooks/useTablePage';
import ModalInfo from '../../molecules/ModalInfo';
import {
  LoaderWithContainer,
  SearchBar,
  FeesSearchBar,
  InputSelect,
} from '../../molecules';

const emergencyOptions = [
  { value: null, title: 'All' },
  { value: 'False', title: 'Non-Emergency' },
  { value: 'True', title: 'Emergency' },
];

const AllActivitiesPage = () => {
  const { pathname } = useLocation();
  const { push }: History = useHistory();

  const {
    setSearch,
    search,
    showSuccessModal,
    showErrorModal,
    modalSuccess,
    modalError,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage({});
  const [inputSearch,setInputSearch]=React.useState("")

  const [routes, setRoutes] = React.useState<
    ActivitiesTypeLink[]
  >(null);

  const [activity, setActivity] = React.useState<
    ActivitiesTypeLink
  >(null);

  const [emergencySort, setEmergencySort] = React.useState(
    emergencyOptions[0],
  );

  const [
    selectedInspections,
    setSelectedInspections,
  ] = React.useState(null);

  const [
    isInspectionsPath,
    setInspectionsPath,
  ] = React.useState(false);

  React.useEffect(() => {
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 3,
      ordering: 'title',
    }).then(response => {
      setRoutes([
        {
          id: Math.random(),
          isActive: true,
          isDeleted: false,
          linkTo: 'all',
          name: 'All',
          title: 'All',
          vocabularyEntity: 3,
        },
        ...convertObjToRouteObj(response.data.results),
      ]);
    });
  }, []);

  React.useEffect(() => {
    setInputSearch("")
    const pathSplit = pathname.split('/');
    if (
      pathSplit?.length &&
      pathSplit[2] === 'inspection'
    ) {
      setInspectionsPath(true);
    }
    if (
      pathSplit?.length &&
      pathSplit[2] !== 'inspection'
    ) {
      setInspectionsPath(false);
    }
    if (routes && ROUTES.ALL_ACTIVITIES === pathname) {
      setActivity(routes[0]);
      return push(`${pathname}/${routes[0].linkTo}`);
    }
    if (
      routes &&
      isRoutePathExact(pathname, ROUTES.ALL_ACTIVITIES)
    ) {
      const pathArr = pathname.split('/');
      const searchPathObj = routes.find(
        el => el.linkTo === pathArr[pathArr.length - 1],
      );
      return setActivity(searchPathObj);
    }
    return setActivity(null);
  }, [routes, pathname]);
  React.useEffect(()=>{
  setInputSearch("")
  },[activity])

  const onEmergencySortChange = value => {
    setEmergencySort(value);
  };

  const selectInspections = inspections => {
    setSelectedInspections(inspections);
  };

  const sendInspectionsEmail = () => {
    if (selectedInspections?.length) {
      const inspectionsIds = selectedInspections?.map(
        el => el?.id,
      );
      sendInspectionRequestEmail({
        inspections: inspectionsIds,
      })
        .then(() => {
          showSuccessModal(true);
        })
        .catch(() => {
          showErrorModal(true);
        });
    }
  };

  return (
    <DashboardLayout>
      <div style={{ width: '100%' }}>
        <ActionsPanel
        // text={inputSearch}
          onSearch={setSearch}
          isActionPanelMoreVisible={false}
          isAllActivities
          emergencyOptions={emergencyOptions}
          onEmergencySortChange={onEmergencySortChange}
          emergencySort={emergencySort}
          selectedInspections={selectedInspections}
          isInspectionsPath={isInspectionsPath}
          sendInspectionsEmail={sendInspectionsEmail}
        />
      </div>
      <StyledSubMenuHeadLine
        menuLinks={routes}
        basePath={ROUTES.ALL_ACTIVITIES}
      />
      <AllActivitiesTable
      searchQuery={search}
        typeQuery={activity}
        emergencyQuery={emergencySort}
        selectInspections={selectInspections}
      />

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const StyledSubMenuHeadLine = styled(SubMenuHeadLine)`
  padding: 16px 0;
  zoom: 0.9;
`;

export default AllActivitiesPage;
