export const camelCaseToTitle = camelCase => {
  if (camelCase) {
    return camelCase
      .replace(/([A-Z])/g, match => ` ${match}`)
      .replace(/^./, match => match.toUpperCase())
      .trim();
  }
  return camelCase;
};

export const titleToCamelCase = str => {
  return str
    .split(' ')
    .map((word, index) => {
      // If it is the first word make sure to lowercase all the chars.
      if (index === 0) {
        return word.toLowerCase();
      }
      // If it is not the first word only upper case the first char and lowercase the rest.
      return (
        word.charAt(0).toUpperCase() +
        word.slice(1).toLowerCase()
      );
    })
    .join('');
};

export const titleToSnakeCase = string => {
  return string
    .replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

export const camelCaseToFieldName = camelCase => {
  if (camelCase) {
    const resultArray = [];
    const array = camelCase
      .replace(/([A-Z])/g, match => ` ${match}`)
      .trim()
      .split(' ');
    array.forEach((el, index) => {
      if (index === 0) {
        resultArray.push(
          `${el.charAt(0).toUpperCase()}${el.slice(1)}`,
        );
      } else {
        resultArray.push(
          `${el.charAt(0).toLowerCase()}${el.slice(1)}`,
        );
      }
    });
    return resultArray.join(' ');
  }
  return camelCase;
};

const createMarkupFromFields = (
  model,
  markup,
  parents,
  data,
  isModel = true,
) => {
  const mutableParents = [...parents];
  let isNeedToAdd = true;
  const newObject = {
    field: camelCaseToFieldName(mutableParents[0]),
    nested_fields: [],
    coordinates: [],
    box_size: [],
  };
  markup.forEach(node => {
    if (
      node?.field ===
      camelCaseToFieldName(mutableParents[0])
    ) {
      isNeedToAdd = false;
      mutableParents.shift();
      createMarkupFromFields(
        model,
        node.nested_fields,
        mutableParents,
        data,
        false,
      );
    }
  });
  if (isNeedToAdd) {
    if (isModel) {
      newObject.model = model;
    }
    if (parents.length > 1) {
      mutableParents.shift();
      createMarkupFromFields(
        model,
        newObject.nested_fields,
        mutableParents,
        data,
        false,
      );
    } else {
      const keys = Object.entries(data);
      keys.forEach(key => {
        newObject[key[0]] = data[key[0]];
      });
    }
    markup.push(newObject);
  }
};

export const createResult = (activeFields, model) => {
  const result = [];
  activeFields.forEach(field => {
    const parents = [...field.parents];
    parents.shift();
    createMarkupFromFields(model, result, parents, {
      coordinates: [
        Math.round(field.x * 2.7),
        Math.round(field.y * 2.7),
      ],
      box_size: [
        Math.round(field.width * 2.7),
        Math.round(field.height * 2.7),
      ],
    });
  });
  return result;
};

export const getActiveFieldsFromMarkup = (
  field,
  parents = ['fields'],
  result,
) => {
  const parentsArray = parents.map(el =>
    titleToCamelCase(el));
  if (field?.nestedFields?.length) {
    parentsArray.push(field?.field);
    field?.nestedFields.forEach(el => {
      getActiveFieldsFromMarkup(el, parentsArray, result);
    });
  } else {
    parentsArray.push(field?.field);
    result.push({
      parents: parentsArray.map(el => titleToCamelCase(el)),
      x: Math.round(field?.coordinates[0] / 2.7),
      y: Math.round(field?.coordinates[1] / 2.7),
      width: Math.round(field?.boxSize[0] / 2.7),
      height: Math.round(field?.boxSize[1] / 2.7),
      value: titleToSnakeCase(
        parentsArray[parentsArray?.length - 1],
      ),
    });
  }
};
