import React from 'react';
import { saveAs } from 'file-saver';
import styled from 'styled-components';

import PrintIcon from '@material-ui/icons/Print';
import { AxiosResponse } from 'axios';

import Table from '../organisms/Table';
import ModalInfo from '../molecules/ModalInfo';
import useTablePage from '../hooks/useTablePage';
import {
  ModalDefaultDraggable,
  LoaderWithContainer,
} from '../molecules';
import DashboardLayout from '../layouts/DashboardLayout';
import InvoicePreviewForm from '../forms/InvoicePreviewForm';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import {
  getInvoicesList,
  InvoiceTableType,
  getPDFInvoiceByID,
  // getAllInvoicesList,
} from '../api/invoices';
import ActionsPanelInvoices from '../organisms/ActionsPanelInvoices';
import { convertTableAmountToCurrency } from '../utils/convertTableValues';
import { formatDateTime } from '../utils/formatDate';
import { DATETIME_FORMAT } from '../constants';
import { getCurrentUser } from '../api/userSettings';

const InvoicesPage = () => {
  const {
    values,
    tableManuallyUpdate,
    isModalOpen,
    handleEdit,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
    modalError,
    modalSuccess,
    modalTitle,
    // updateTable,
  } = useTablePage<InvoiceTableType>({
    modalUpdateTitle: 'Invoice',
  });

  const [
    selectedAdditionalParams,
    setSelectedAdditionalParams,
  ] = React.useState<any>(undefined);

  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  const [
    isTableLoading,
    setIsTableLoading,
  ] = React.useState<boolean>(false);

  const [franchise, setFranchise] = React.useState(null)

  React.useEffect(() => {
    getCurrentUser().then(currentUser => {
      setFranchise(currentUser?.data?.franchise[0]?.id)      
    })
  }, [])

  const columns = column => {
    if (
      [
        'balance',
        'deposit',
        'dueDate',
        'lastUpdateTime',
        'customerStripeId',
        'updatedAt',
        'paidAt',
        'invoicePdf',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'docNumber':
        return 'Number';

      case 'createdAt':
        return 'Date';

      case 'invoiceNumber':
        return 'Invoice Number';

      case 'isPaid':
        return 'Paid';

      case 'total':
        return 'Amount';
        case 'franchise':
          return 'Branch';

      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'id',
    'stripeId',
    'invoiceNumber',
    'createdAt',
    'docNumber',
    'customer',
    'totalAmt',
    // 'invoiceStatus',
    'isPaid',
  ];

  const useSortBySelectedColumns = [
    'id',
    'docNumber',
    // 'createdAt',
    'isPaid',
  ];

  const initialSortBy = [
    {
      id: 'id',
      desc: true,
    },
  ];

  const modifiedGetInvoices = params => {
    return Promise.all([getInvoicesList(params)]).then(
      ([invoicesResponse]) => {
        return {
          ...invoicesResponse,
          data: {
            ...invoicesResponse.data,
            results: invoicesResponse.data.results.map(
              invoice => ({
                ...invoice,
                createdAt: formatDateTime(
                  invoice.createdAt,
                  DATETIME_FORMAT,
                ),
              }),
            ),
          },
        };
      },
    );
  };

  // const getAllInvoices = () => {
  //   setIsLoading(true);
  //   getAllInvoicesList()
  //     .then(() => {
  //       updateTable();
  //       setIsLoading(false);
  //     })
  //     .catch(() => setIsLoading(false));
  // };

  const getInvoicePdf = invoice => {
    setIsLoading(true);
    return getPDFInvoiceByID(invoice.id)
      .then((response: AxiosResponse) => {
        const file = new Blob([response.data], {
          type: 'application/pdf',
        });

        saveAs(file, 'invoice.pdf');
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const additionalActions = ({ invoiceStatus }) => {
    if (invoiceStatus?.toUpperCase() === 'PAID') {
      return [
        {
          title: 'Print',
          handler: getInvoicePdf,
          Icon: PrintIcon,
        },
      ];
    }

    return undefined;
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <DashboardLayout>
      {isLoading && <StyledLoaderWithContainer />}
      <ActionsPanelInvoices
        isLoading={isLoading || isTableLoading}
        onSelectedAdditionalParamsChange={
          setSelectedAdditionalParams
        }
      />

      <Container>
        {selectedAdditionalParams && (
          <Table
            customSearch
            useViewModeInsteadEdit
            initialUseSortBySelectedColumns={
              useSortBySelectedColumns
            }
            columns={columns}
            onEdit={handleEdit}
            rows={modifiedGetInvoices}
            columnsOrder={columnsOrder}
            manuallyUpdate={tableManuallyUpdate}
            additionalActions={additionalActions}
            formatValue={convertTableAmountToCurrency}
            additionalRequestParams={
              selectedAdditionalParams
            }
            initialSortBy={initialSortBy}
            onChangeLoadingStatus={setIsTableLoading}
            invoicePage
          />
        )}
      </Container>

      {isModalOpen && franchise && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <InvoicePreviewForm values={values} franchise={franchise} />
        </ModalDefaultDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

const StyledLoaderWithContainer = styled(
  LoaderWithContainer,
)`
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 30;
`;
export default InvoicesPage;
