import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { ROUTES } from '../../constants/routes';
import { Loader } from '../../atoms';
import isRoutePathExact from '../../utils/isRoutePathExact';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import convertObjToRouteObj from '../../utils/convertObjToRouteObj';

import { getVocabulariesDataList } from '../../api/vocabulary';
import {
  ActivitiesStatusType,
  ActivitiesTypeLink,
} from '../../api/activities';

import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import SubMenuHeadLine from '../../organisms/SubMenuHeadLine';
import ActivitiesTitleTable from '../../organisms/Activities/ActivitiesTitleTable';
import ActivitiesStatusTable from '../../organisms/Activities/ActivitiesStatusTable';
// import {
//   TablePageRequestName,
//   TablePageRequestStatus,
// } from '../../hooks/useTablePage';
// import useUserRole from '../../hooks/useUserRole';

const ActivitiesPage = () => {
  const { pathname } = useLocation();
  const { push }: History = useHistory();
  // const { isSuperAdmin } = useUserRole();

  const [routes, setRoutes] = React.useState<
    ActivitiesTypeLink[]
  >(null);

  const [activity, setActivity] = React.useState<
    ActivitiesTypeLink
  >(null);

  // const [showLoader, setShowLoader] = React.useState<
  //   boolean
  // >(false);

  const [
    selectedActivity,
    setSelectedActivity,
  ] = React.useState<ActivitiesStatusType>(null);

  React.useEffect(() => {
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 3,
      ordering: 'title',
    }).then(response => {
      setRoutes(
        convertObjToRouteObj(response.data.results),
      );
    });
  }, []);

  React.useEffect(() => {
    setSelectedActivity(null);
    if (routes && ROUTES.SETTINGS_ACTIVITIES === pathname) {
      setActivity(routes[0]);
      return push(`${pathname}/${routes[0].linkTo}`);
    }
    if (
      routes &&
      isRoutePathExact(pathname, ROUTES.SETTINGS_ACTIVITIES)
    ) {
      const pathArr = pathname.split('/');
      const searchPathObj = routes.find(
        el => el.linkTo === pathArr[pathArr.length - 1],
      );
      return setActivity(searchPathObj);
    }
    return setActivity(null);
  }, [routes, pathname]);

  // const onActivitiesStatusTableRequestStatusChange = ({
  //   status,
  //   name,
  // }) => {
  //   if (!isSuperAdmin) {
  //     if (name === TablePageRequestName.Update) {
  //       switch (status) {
  //         case TablePageRequestStatus.Request:
  //           setShowLoader(true);
  //           break;

  //         default:
  //           setShowLoader(false);
  //       }
  //     }
  //   }
  // };

  return (
    <DashboardLayout>
      {routes && activity ? (
        <>
          <ActionsPanel />
          <StyledSubMenuHeadLine
            menuLinks={routes}
            basePath={ROUTES.SETTINGS_ACTIVITIES}
          />
          <Container>
            <ActivitiesTitleTable
              activitiesQuery={activity}
              setSelectedActivity={setSelectedActivity}
              hightLightSelectedRow={selectedActivity}
            />
          </Container>
          <Container>
            {selectedActivity && (
              <ActivitiesStatusTable
                activitiesQuery={
                  selectedActivity &&
                  selectedActivity.length
                    ? selectedActivity[0]
                    : {}
                }
                // onRequestStatusChange={
                //   onActivitiesStatusTableRequestStatusChange
                // }
              />
            )}
          </Container>
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSubMenuHeadLine = styled(SubMenuHeadLine)`
  padding: 16px 0;
  zoom: 0.9;
`;

export default ActivitiesPage;
