import * as React from 'react';
import styled from 'styled-components';

import {
  convertTableBoolean,
  convertTableUserRole,
} from '../utils/convertTableValues';
import Table from '../organisms/Table';
import UsersForm from '../forms/UsersForm';
import useTablePage from '../hooks/useTablePage';
import DashboardLayout from '../layouts/DashboardLayout';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import {
  createUser,
  UserTableType,
  updateUser,
  getUsers,
  getEmployeesUsers,
} from '../api/users';
import { ModalDefaultDraggable } from '../molecules';
import ModalInfo from '../molecules/ModalInfo';
import ActionsPanelClients, {
  SelectedAdditionalParams,
} from '../organisms/ActionsPanelClients';
import { HomeWorkOutlined } from '@material-ui/icons';
import { getContactProjects, getProjects } from '../api/projects';
import { getVocabulariesDataList } from '../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { generatePath } from 'react-router';
import { ROUTES } from '../constants/routes';
import ActionsPanel from '../organisms/ActionsPanel';

const ContactsPage = () => {
  const {
    setSearch,
    handleAdd,
    search,
    tableManuallyUpdate,
    updateTable,
    isModalOpen,
    values,
    handleEdit,
    errors,
    createOrUpdate,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
    modalError,
    modalTitle,
    modalSuccess,
  } = useTablePage<UserTableType>({
    createEndpoint: createUser,
    updateEndpoint: updateUser,
    modalCreateTitle: 'Add User',
  });

  const [
    selectedPermit,
    setSelectedPermit,
  ] = React.useState(null);

  const modifiedGetClientsRequest = params => {
    
    const parameters = selectedPermit
      ? {
          ...params,
          userRole: 3,
          permit_id: selectedPermit?.id,

        }
      : {...params, user_role: 3};
      
    return getEmployeesUsers(parameters).then(response => {
      
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data
            .filter(
              item =>
                (item.userRole === 3 &&
                  item.clientType === 1) ||
                (item.userRole === 3 &&
                  item.clientType === 2)
            )
            // .filter(el =>  {
            //   if(selectedPermit){
            //     const permitContactIds = selectedPermit?.permitContacts?.length > 0 && selectedPermit?.permitContacts?.map(user => user?.user)
            //     return permitContactIds?.includes(el?.id)
            //   } else {
            //     return el
            //   }
            // }
            // )
            .map(client => {
              return {
                name: client?.firstName + " " + client?.lastName,
                company: client?.companyName,
                ...client,
                // isDeleted: client.isDeleted,
              };
            }),
        },
      };
    });
  };

  React.useEffect(() => {
    updateTable();
  }, [selectedPermit]);

  const [
    selectedAdditionalParams,
    setSelectedAdditionalParams,
  ] = React.useState<SelectedAdditionalParams>(undefined);

  const [isCompanyView, setIsCompanyView] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      selectedAdditionalParams &&
      selectedAdditionalParams.clientType === 2 &&
      selectedAdditionalParams.userRole === 3
    ) {
      setIsCompanyView(true);
    } else {
      setIsCompanyView(false);
    }
  }, [selectedAdditionalParams]);

  const columnsOrder = [
    'name',
    'email',
    'company',
    'companyName',
    'phoneNumber',
    'address',
  ];

  const columns = React.useCallback(
    column => {
      if (
        [
          'id',
          'isDeleted',
          // 'email',
          // 'address',
          'city',
          'state',
          'street',
          'zipCode',
          'prNumber',
          'clientType',
          'licenses',
          'mobilePhoneNumber',
          'parentCompany',
          'tradesNames',
          'franchiseNames',
          'franchise',
          'trade',
          'avatar',
          'userRole',
          'isInvitationSent',
          'isEpsuperadmin',
          'createdAt',
          'firstName',
          'lastName',
          isCompanyView ? null : 'companyName',
        ].includes(column)
      ) {
        return null;
      }

      return tableColumnsFormatter(column);
    },
    [isCompanyView],
  );

  const permitColumnsOrder = [
    'name',
    'permitsNames',
    'permitsNumbers',
    'permitsStatuses',
    'address',
    'jurisdictionName',
    'franchiseName',
  ];

  const permitColumns = column => {
    if (
      [
        'id',
        'isDeleted',
        'franchise',
        'jurisdiction',
        'street',
        'city',
        'state',
        'zipCode',
        'serviceType',
        'contacts',
        'manager',
        'createdAt',
        'franchiseName',
        'permits',
        'numPermits'
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Project Name';
      case 'jurisdictionName':
        return 'Jurisdiction';
      case 'managerName':
        return 'Project Manager';
      case 'permitsNames':
        return 'Permit Name';
      case 'permitsNumbers':
        return 'Permit Number';
      case 'permitsStatuses':
        return 'Permit Status';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const formatValue = (value: any, column: string) =>
    convertTableBoolean(
      convertTableUserRole(value, column),
    );

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const onPermitChange = (data: any) => {
    if (data?.value === 'Clear') {
      setSelectedPermit(null);
      return;
    }
    setSelectedPermit(data);
  };
  const [permitUserId, setPermitUserId] = React.useState(null)
  const [showUserPermitsModal, setShowUserPermitsModal] = React.useState(false)

  const handlePermitClick = (data, column) => {
   
    if (data.permitItemId) {
      const path = generatePath(ROUTES.PERMIT, {
        projectId: data.projectId,
        permitId: data.permitItemId,
      });
      window.location.assign(path)
    }
    if (data.permits?.length === 1) {
      const path = generatePath(ROUTES.PERMIT, {
        projectId: data.id,
        permitId: data.permits[0].id,
      });
      window.location.assign(path)
    }
  
};

const viewUserPermits = (row) => {
  setPermitUserId(row?.id)
  setShowUserPermitsModal(true)
}

const handleCloseUserPermitsModal = () => {
  setShowUserPermitsModal(false)
}
  const additionalActions = (row) => {
      return [
        {
          title: 'View Permits',
          handler: () => viewUserPermits(row),
          Icon: HomeWorkOutlined,
        },
      ];
    
  };

  const getStatuses = (permits, statuses) =>
  permits.map(
    permit =>
      statuses.find(status => permit.status === status.id)
        ?.title,
  );

  const modifiedGetProjectsWithPermits = params =>
  Promise.all([
    getContactProjects({...params, contact_id: permitUserId}),
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 6,
      ordering: 'title',
    }),
  ]).then(
    ([
      projectsResponse,
      statusesResponse,
    ]) => {            
      return {
        ...projectsResponse,
        data: {
          ...projectsResponse.data,
          results: projectsResponse.data.map(
            project => {
              const projectPermits = project?.permits
              
              return {
                ...project,
                permitsNames: projectPermits.map(
                  permit => permit.name,
                ),
                permits: projectPermits,
                permitsNumbers: projectPermits.map(permit =>
                  permit.number ? permit.number : '',
                ),
                permitsStatuses: getStatuses(
                  projectPermits,
                  statusesResponse.data.results,
                ),
                address: `${project.street}, ${project.city}, ${project.state}, ${project.zipCode}`,
              };
            },
          ),
        },
      };
    },
  );

  return (
    <DashboardLayout>
      <ActionsPanelClients
        onAdd={handleAdd}
        onSearch={setSearch}
        onSelectedAdditionalParamsChange={
          setSelectedAdditionalParams
        }
        onPermitChange={onPermitChange}
      />

      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={modifiedGetClientsRequest}
          onEdit={handleEdit}
          onDelete={null}
          columnsOrder={columnsOrder}
          formatValue={formatValue}
          manuallyUpdate={tableManuallyUpdate}
          additionalRequestParams={selectedAdditionalParams}
          additionalActions={additionalActions}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <UsersForm
            disabledRole
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {showUserPermitsModal && (
            <StyledModalDraggable
              onClose={handleCloseUserPermitsModal}
              title="User Permits"
              wide
            >
              <ActionsPanel
              isBreadCrumbsVisible={false}
              onSearch={setSearch}
              isActionPanelMoreVisible={false}
              className='action-container'
              />
              <Table
                customSearch
                searchQuery={search}
                columnsOrder={permitColumnsOrder}
                columns={permitColumns}
                rows={modifiedGetProjectsWithPermits}
                permitUserId={permitUserId}
                onEdit={null}
                onDelete={null}
                onConfigure={null}
                initialSortBy={[{ id: 'createdAt', desc: true }]}
                additionalActions={null}
                formatValue={convertTableBoolean}
                manuallyUpdate={tableManuallyUpdate}
                cellCallbackFnIsOn={['permitsNames']}
                handleCellClick={(data, column) =>
                  handlePermitClick(data, column)
          }
        />
            </StyledModalDraggable>
          )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          title="Success"
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default ContactsPage;
