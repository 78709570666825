/* eslint-disable react/no-array-index-key,no-console */
import * as React from 'react';
import { Grid } from '@material-ui/core';
import { capitalCase } from 'change-case';
import styled from 'styled-components';

import { Loader, StatisticBlock } from '../atoms';
import { getFranchiseList } from '../api/franchise';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import DashboardLayout from '../layouts/DashboardLayout';
import {
  getFranchiseSales,
  getFranchiseMonthRevenue,
  getFranchiseIncome,
} from '../api/dashboard';
import FranchiseSalesGraphWidget from '../organisms/Dashboard/FranchiseSalesGraphWidget';
import FranchiseIncomeChartWidget from '../organisms/Dashboard/FranchiseIncomeChartWidget';

const DashboardPage = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  const [
    franchisesData,
    setFranchisesData,
  ] = React.useState(null);

  const [
    franchiseMonthRevenueData,
    setFranchiseMonthRevenueData,
  ] = React.useState(null);

  const [
    franchiseSalesData,
    setFranchiseSalesData,
  ] = React.useState(null);

  const [
    franchiseIncomeData,
    setFranchiseIncomeData,
  ] = React.useState(null);

  const [
    franchiseSalesGraphSelected,
    setFranchiseSalesGraphSelected,
  ] = React.useState(null);

  const [
    franchiseIncomeChartSelected,
    setFranchiseIncomeChartSelected,
  ] = React.useState(null);

  React.useEffect(() => {
    if (franchiseSalesGraphSelected) {
      getFranchiseSales(franchiseSalesGraphSelected)
        .then(response => {
          const newResponse = response.data.map(el => {
            return {
              ...el,
              projectedSales: Number(
                el.projectedSales,
              ).toFixed(),
              sales: Number(el.sales).toFixed(1),
            };
          });
          return setFranchiseSalesData(newResponse);
        })
        .catch(e => console.error(e));
    }
  }, [franchiseSalesGraphSelected]);

  React.useEffect(() => {
    if (franchiseIncomeChartSelected) {
      getFranchiseIncome(franchiseIncomeChartSelected)
        .then(response =>
          setFranchiseIncomeData(response.data))
        .catch(e => console.error(e));
    }
  }, [franchiseIncomeChartSelected]);

  React.useEffect(() => {
    setIsLoading(true);

    // Show all franchises on initial load
    setFranchiseIncomeChartSelected([]);
    setFranchiseSalesGraphSelected([]);

    Promise.all([
      getFranchiseList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      getFranchiseMonthRevenue(),
    ])
      .then(([franchises, franchiseMonthRevenue]) => {
        setFranchisesData(franchises.data.results);

        const monthlyEarning =
          franchiseMonthRevenue.data.monthly * 0.1;

        setFranchiseMonthRevenueData({
          income: monthlyEarning * 0.7,
          marketing: monthlyEarning * 0.2,
          donation: monthlyEarning * 0.1,
          monthlyEarning,
          yearlyEarning: franchiseMonthRevenue.data.yearly,
        });
      })
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <DashboardLayout>
      <Container>
        {!isLoading ? (
          franchiseMonthRevenueData && (
            <>
              <Header style={{ textAlign: 'center' }}>
                Current month revenues
              </Header>
              <TopContent>
                {Object.entries(
                  franchiseMonthRevenueData,
                ).map(([label, value], index) => {
                  const formattedValue = new Intl.NumberFormat(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    },
                  ).format(+value);

                  return (
                    <StatisticBlock
                      label={capitalCase(label)}
                      value={formattedValue}
                      key={index}
                    />
                  );
                })}
              </TopContent>
            </>
          )
        ) : (
          <LoaderContainer>
            <Loader size={26} />
          </LoaderContainer>
        )}

        <Content>
          {franchisesData && (
            <>
              <StyledGrid item xs={6}>
                <FranchiseSalesGraphWidget
                  data={franchiseSalesData}
                  franchises={franchisesData}
                  onSelectChange={(selected, isCanceled) =>
                    !isCanceled &&
                    setFranchiseSalesGraphSelected(
                      selected?.map(item => item.id),
                    )
                  }
                />
              </StyledGrid>

              <StyledGrid item xs={6}>
                <FranchiseIncomeChartWidget
                  data={franchiseIncomeData}
                  franchises={franchisesData}
                  onSelectChange={(selected, isCanceled) =>
                    !isCanceled &&
                    setFranchiseIncomeChartSelected(
                      selected?.map(item => item.id),
                    )
                  }
                />
              </StyledGrid>
            </>
          )}
        </Content>
      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: var(--defaultBackground);
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -16px;

  > div {
    flex: 1;
    margin: 0 16px 32px 16px;
  }
`;

const Content = styled.div`
  display: flex;
  margin: 0 -16px;
`;

const StyledGrid = styled(Grid)`
  margin: 0 16px;
  padding: 16px 32px;
  border-radius: 5px;
  border: 1px solid var(--disabledButton);
`;

const Header = styled.div`
  font-weight: bold;
  margin: 0 0 16px 0;
  //font-size: 1.125rem;
  text-transform: uppercase;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110px;
  position: relative;
  text-align: center;
  margin: 0 0 32px 0;
`;

export default DashboardPage;
