import React, { useEffect } from 'react';
import { InputBaseContainer, InputDatePickerWithLabel, InputSelectWithLabel } from '../../../../../molecules';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { DEFAULT_DATE_FORMAT } from '../../../../../constants';
import styled from 'styled-components';

const DatePicker = ({ name, control, errors, label }) => {
  const { selectedInspection } = useSelector((s: RootState) => s.inspections);

  return (
    <>
      {selectedInspection.inspectionDate && (
        <InputBaseContainer>
          <Controller
            name={name}
            defaultValue=""
            valueName="selected"
            onChange={([selected]) => selected}
            control={control}
            style={true ? { backgroundColor: '#F2F2F2', opacity: 0.7 } : {}}
            render={props => (
              <InputDatePickerWithLabel
                showInRow={true}
                label={label}
                error={errors.inspectionDate}
                dateFormat={DEFAULT_DATE_FORMAT}
                disabled={true}
                // eslint-disable-next-line react/prop-types
                selected={props.value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
      )}
      {!selectedInspection.inspectionDate && (
        <EmptyDate>
          <Label>Inspection Date</Label>
          <EmptyField></EmptyField>
        </EmptyDate>
      )}
    </>
  );
};

export default DatePicker;

const EmptyDate = styled.div`
  display: flex;
`;

const EmptyField = styled.div`
  flex-grow: 1;
  border: 1px solid #ccc;
  height: 38px;
  margin-bottom: 10px;
`;

const Label = styled.div`
  width: 135px;
  display: flex;
  align-items: center;
  padding-bottom: 13px;
`;
