import * as yup from 'yup';

const schema = yup.object().shape(
  {
    subject: yup.string().required().label("Subject"),
    description: yup.string().required().label("Description"),
  },
);

export default schema;
