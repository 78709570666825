import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ClientTextFieldController from '../../../Common/ClientTextFieldController';
import StatesDropdown from '../../../Common/StatesDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import Dropdown from '../../../Common/Controls/Dropdown';
import { Controller } from 'react-hook-form';
import { InputAutocompletePlaces } from '../../../../../../molecules';
import MultiCheckbox from './MultiCheckbox';
import { TextField } from '@material-ui/core';
import UploadFiles from '../../../Common/UploadFiles';
import { setSelectedFranchise } from '../../../../../../redux/slices/projectSlice';
import ServiceType from './ServiceType';
import useGetFranchiseUsers from '../../../../hooks/useGetFranchiseUsers';
import GeneralTextField from '../../../Common/Controls/GeneralTextField';
import { Logo_Sky } from '../../../../../../constants/menu';

type Props = {
  control: any;
  register: any;
  trigger: Function;
  errors: any;
  setValue: Function;
  getValues: Function;
  clearErrors: Function;
  reset: Function;
};
const ProjectControlsArea: FC<Props> = ({ control, register, errors, setValue, trigger, getValues, reset, clearErrors }) => {
  const { loadFranchiseUsers } = useGetFranchiseUsers();
  const vocabularies = useSelector((state: RootState) => state.general.vocabulary);
  const [selectedFranchiseUsers, setSelectedFranchiseUsers] = useState([]);
  const { franchises, franchiseUsers, selectedFranchise } = useSelector((s: RootState) => s.projectsSlice);

  useEffect(() => {
    if (selectedFranchise.id) {
      loadFranchiseUsers(selectedFranchise.id.toString());
    }
  }, [selectedFranchise]);

  useEffect(() => {
    const franchaseUsersList = franchiseUsers.find(u => Number(u.id) === selectedFranchise.id);
    if (selectedFranchise.id && franchaseUsersList) {
      setSelectedFranchiseUsers(franchaseUsersList.value);
    }
  }, [selectedFranchise, franchiseUsers]);

  const dispatch = useDispatch();
  const handlePlaceSelect = (address, isSelect) => {
    if (address || isSelect) {
      clearErrors('address');
    }
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        setValue(key, value);
        clearErrors(key);
      });
    }
    return setValue('address', address);
  };

  const updateSelectedItem = items => {
    dispatch(setSelectedFranchise(items[0]));
  };

  return (
    <ProjectFields>
      <GroupWrapper>
        <GroupLabel>TELL US THE LOCATION OF THE PROJECT</GroupLabel>
        <RequiredWrapper>
          <Required left={64} top={4}>
            (Required)
          </Required>
          <Controller
            as={InputAutocompletePlaces}
            control={control}
            id="filled-error-helper-text"
            name="address"
            label="Address"
            defaultValue=""
            blueFont={true}
            style={{ width: '100%', marginTop: '25px', color: `${Logo_Sky}` }}
            helperText={errors.address ? errors.address.message : ''}
            inputRef={register}
            error={errors.address !== undefined}
            handleFieldChange={handlePlaceSelect}
          />
        </RequiredWrapper>

        <DoubleControllsWrapper>
          <RequiredWrapper>
            <Required left={48}>(Required)</Required>
            <GeneralTextField name="street" label="Street" errors={errors} register={register} />
          </RequiredWrapper>
          <RequiredWrapper>
            <Required left={33}>(Required)</Required>
            <GeneralTextField name="city" label="City" errors={errors} register={register} />
          </RequiredWrapper>
        </DoubleControllsWrapper>
        <DoubleControllsWrapper>
          <StatesWrapper>
            <RequiredWrapper>
              <Required left={0}>(Required)</Required>
              <StatesDropdown register={register} errors={errors} setValue={setValue} control={control} />
            </RequiredWrapper>
          </StatesWrapper>
          <RequiredWrapper>
            <Required left={69}>(Required)</Required>
            <GeneralTextField name="zipCode" label="Zip code" errors={errors} register={register} />
          </RequiredWrapper>
        </DoubleControllsWrapper>
      </GroupWrapper>

      <GroupWrapper style={{ marginTop: '30px' }}>
        <GroupLabel>ASSIGN A MEMORABLE NAME TO YOUR PROJECT (THIS MAY BE ADJUSTED BY STAFF WHEN PROCESSED)</GroupLabel>
        <RequiredWrapper>
          <Required left={0} top={8}>
            (Required)
          </Required>
          <ClientTextFieldController name="projectName" label="Project Name" errors={errors} control={control} register={register} />
        </RequiredWrapper>
      </GroupWrapper>
      <GroupWrapper style={{ marginTop: '30px' }}>
        <GroupLabel>DESCRIBE IN DETAILS WHAT IS THE SCOPE OF YOUR PROJECT, TELL US AS MUCH INFORMATION AS YOU CAN.</GroupLabel>
        <RequiredWrapper>
          <Required left={0} top={8}>
            (Required)
          </Required>
          <Controller
            as={TextField}
            control={control}
            id="filled-error-helper-text"
            name="description"
            variant="outlined"
            label={'Description'}
            defaultValue=""
            style={{ width: '100%', marginTop: '25px' }}
            helperText={errors.description ? errors.description.message : ''}
            inputRef={register}
            error={errors.description !== undefined}
            multiline={true}
            rowsMax={4}
          />
        </RequiredWrapper>
      </GroupWrapper>

      <ServiceType errors={errors} setValue={setValue} trigger={trigger} register={register} />

      <MultiCheckbox
        name="applicantType"
        header={'Are you applying as a contractor or a do it yourselfer?'.toUpperCase()}
        errors={errors}
        setValue={setValue}
        trigger={trigger}
        register={register}
        isRadio={true}
        itemsArr={[
          { id: 1, title: 'Owner Builder' },
          { id: 2, title: 'Contractor' },
        ]}
        isRequired={true}
        requiredLocation={495}
      />

      <DoubleControllsWrapper>
        <DropdownRequiredWrapper>
          <Required left={0} top={18}>
            (Required)
          </Required>
          <Dropdown
            register={register}
            errors={errors}
            control={control}
            dropdownItems={vocabularies.filter(item => item.vocabularyEntity === 2)}
            name="work"
            label="What kind of work is this"
          />
        </DropdownRequiredWrapper>
        <DropdownRequiredWrapper>
          <Required left={0} top={18}>
            (Required)
          </Required>
          <Dropdown
            register={register}
            errors={errors}
            control={control}
            dropdownItems={vocabularies.filter(item => item.vocabularyEntity === 1)}
            name="building"
            label="What type of building is this"
          />
        </DropdownRequiredWrapper>
      </DoubleControllsWrapper>

      <DoubleControllsWrapper>
        <MultiCheckbox
          name="franchise"
          header={'Elite Permits processing office that you would like to work with?'.toUpperCase()}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
          register={register}
          isRadio={true}
          itemsArr={franchises.map(fr => ({
            id: fr.id,
            title: fr.name,
          }))}
          updateSelectedItem={updateSelectedItem}
          isRequired={true}
          requiredLocation={575}
        />
        <ManagerWrapper>
          <Prompt>
            If you are working with someone at our office please list their name here so we can promptly pass the information directly to
            them. if not we will assign (Optional)
          </Prompt>
          <Dropdown
            register={register}
            errors={errors}
            control={control}
            dropdownItems={selectedFranchiseUsers.map((m: any) => ({
              title: `${m.firstName} ${m.lastName}`,
              id: m.id,
            }))}
            name="manager"
            label=" "
            externalValue={selectedFranchise}
            setValue={setValue}
            trigger={trigger}
          />
        </ManagerWrapper>
      </DoubleControllsWrapper>
      <UploadFiles setValue={setValue} register={register} />
    </ProjectFields>
  );
};

export default ProjectControlsArea;

const Prompt = styled.div`
  color: ${Logo_Sky};
`;

const ProjectFields = styled.div`
  padding: 20px;
`;

export const RequiredWrapper = styled.div`
  display: flex;
  position: relative;
`;

const DropdownRequiredWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const Required = styled.div<{ left: number; top?: number }>`
  font-size: 11px;
  color: red;
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : '15px')};
  left: ${({ left }) => `${left}px`};
`;

export const DoubleControllsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 65px;
  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const ContractorWrapper = styled.div`
  display: flex;
`;

const ContractorLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  width: 90%;
  @media only screen and (max-width: 840px) {
    width: 85%;
  }
`;

export const TextLabel = styled.div<{ isError: boolean }>`
  color: ${({ isError }) => (isError ? 'red' : 'grey')};
`;

const GroupWrapper = styled.div`
  border: 1px solid #ccc;
  padding: 21px;
  border-radius: 4px;
`;

const GroupLabel = styled.div`
  padding-bottom: 30px;
  color: ${Logo_Sky};
`;

const StatesWrapper = styled.div`
  position: relative;
  top: -11px;
`;

const ManagerWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  margin-top: 27px;
`;
