import styled from "styled-components";

export type TableHeader = {
  label: string;
  itemName: string;
  hideFilter?: boolean;
  sortingKey?: string;
  theKey?: string;
};

export type TableColumnsData = {
  collection: any[];
  headers: TableHeader[];
  gridTemplateCols: string;
  setSelectedItemInSlice: Function;
  filterMeta: any[];
}

export type SortingDirection = "ASC" | "DESC" | "NONE"

export const commonHeaders: TableHeader[] = [
  {
    label: 'Project Name/Permit Name',
    sortingKey: 'namePermitName',
    itemName: 'namePermitName',
  },
  {
    label: 'Address',
    sortingKey: 'address',
    itemName: 'address',
  },
  {
    label: 'Status',
    sortingKey: 'status',
    itemName: 'status',
  },
  { label: 'Detail', hideFilter: true, itemName: '' },
];

export const commonFilterMeta = [
  { label: "Project Name/Permit Name", name: "namePermitName" },
  { label: "Address", name: "address" },
];

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Pagination = styled.div`
  margin: 15px;
`;