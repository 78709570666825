import * as React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import HeadLine from '../organisms/HeadLine';
import useUserRole from '../hooks/useUserRole';
import ResponsiveSidebar from './ResponsiveSidebar/ResponsiveSidebar';
import { ROUTES } from '../constants/routes';
import { getEpicVersion } from '../api/dashboard';

const DashboardLayout = ({
  children,
  isCentered,
  overY,
  NoSubMenu=false,
}: Props) => {
  const [version, setVersion] = React.useState(null)
  const { isEmployee, isInspector } = useUserRole();

  React.useEffect(() => {
    getEpicVersion().then(versionRes => setVersion(versionRes?.data[0]?.version))
    if(window.location.pathname !== ROUTES.SEARCH && window.location.pathname.split("/")[1] !== 'permit') {
      localStorage.removeItem('search')
    }
  }, [])

  return (
    <Wrapper>
      <StyledGrid
        container
        direction="row"
        justify="space-between"
      >
        <ResponsiveSidebar
          isEmployee={isEmployee}
          isInspector={isInspector}
        />
        <MainSection>
          <HeadLine NoSubMenu={NoSubMenu}/>
          <Content isCentered={isCentered} overY={overY}>
            {children}
            <p
              style={{
                position: 'absolute',
                bottom: '-10px',
                right: '1rem',
                fontSize: '0.875rem',
              }}
            >
              Version {version}
            </p>
          </Content>
        </MainSection>
      </StyledGrid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: var(--primary);
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;  
`;

const StyledGrid = styled(Grid)`
  min-height: 100vh;
`;

const MainSection = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--defaultBackground);
  height: 100vh;
`;
// eslint-disable-next-line
const Content = styled(
  ({ isCentered, overY, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} />
  ),
).attrs({
  className: 'scrollable',
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px 24px 24px;
  overflow: auto;
  ${({ isCentered }) =>
    isCentered &&
    `
    justify-content: center;
  `}

  @media print {
    margin: 0;
    padding: 0;
  }
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  isCentered?: boolean;
  overY?: boolean;
  NoSubMenu?: boolean;
}

export default DashboardLayout;
