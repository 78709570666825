import React from 'react';
import styled from 'styled-components';
import logo from '../../../resources/logo.png';
import { format } from 'date-fns';

const TopHeader = () => {
  return (
    <ReportHeader>
      <img src={logo} />
      <CenterLabel>
        <LabelTopPart>A/R Aging Detail</LabelTopPart>
        <LabelBottomPart>{`As of ${format(new Date(),'MMMM d, yyyy')}`}</LabelBottomPart>
      </CenterLabel>
    </ReportHeader>
  );
};

export default TopHeader;

const ReportHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

const CenterLabel = styled.div`
  text-align: center;
`;

const LabelTopPart = styled.div`
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const LabelBottomPart = styled.div`
  font-size: 18px;
`;
