import * as React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core';
import CurrencyInput from 'react-currency-input-field';

import { LabelForm } from '../atoms';

const InputCurrencyWithLabel = ({
  isRequired,
  label,
  error,
  allowDecimals = true,
  onCurrencyChange,
  disabled = false,
  ...props
}: Props) => {
  const { id, name, value } = props;

  return (
    <InputContainer>
      <LabelForm
        htmlFor={id || name}
        isRequired={isRequired}
      >
        {label}
      </LabelForm>
      <StyledCurrencyInput
        id={id || name}
        name={name}
        error={!!error}
        required={isRequired}
        onChange={onCurrencyChange}
        allowDecimals={allowDecimals}
        prefix="$"
        value={value}
        disabled={disabled}
      />
      {error && (
        <span
          style={{
            color: '#f44336',
            fontSize: '0.75rem',
            margin: '3px 14px 0 14px',
          }}
        >
          {error?.message ? error?.message : error}
        </span>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
  }
`;

const StyledCurrencyInput = styled(({ error, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CurrencyInput {...rest} />
))`
  text-align: right;
  border-radius: 1px;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  color: var(--mainText);
  font-size: 16px;
  box-shadow: none;
  border: 0;
  outline: solid 1px rgba(0, 0, 0, 0.23);

  &:focus {
    outline: solid 1px var(--primary);
    border: solid 1px var(--primary);
  }

  ${props =>
    props.error &&
    `
    outline: solid 1px var(--mainRequired);
  `}
`;

type Props = Omit<TextFieldProps, 'error' | 'variant'> & {
  isRequired?: boolean;
  error?: FieldError;
  allowDecimals?: boolean;
  onCurrencyChange: (value: string, name: string) => void;
};

export default InputCurrencyWithLabel;
