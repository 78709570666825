import * as React from 'react';

const useManuallyUpdate: useManuallyUpdateType = (
  useCounter = false,
) => {
  const update = (prev?: number) =>
    useCounter ? prev + 1 : +new Date();

  const [
    manuallyUpdate,
    setManuallyUpdate,
  ] = React.useState<number>(update(0));

  return [
    manuallyUpdate,
    () => setManuallyUpdate(update(manuallyUpdate)),
  ];
};

type useManuallyUpdateType = (
  useCounter?: boolean,
) => [number, () => void];

export default useManuallyUpdate;
