import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Logo_Blue, Logo_Sky } from '../../constants/menu';
import HorizontalBar from './HorizontalBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { getInspectionStats } from '../../api/dashboard';
import BarRecordsPopup from './BarRecordsPopup';
import { setBarsData, setInspections, setShowCompleteInspectionsPopup } from '../../redux/slices/dashboardSlice';
import useInspectionGraphUtil from './useInspectionGraphUtil';

const MobileInspectionGraph = () => {
  const userId = useSelector((s: RootState) => s.userAuth.info.id);
  const barData = useSelector((s:RootState) => s.dashboard.barsData);
  const filters = useSelector((s:RootState) => s.filters.filters);
  const { pageSize, pageIndex } = useSelector((s: RootState) => s.pagination);
  const [maxCount, setMaxCount] = useState(0);
  const [yearMonth, setYearMonth] = useState<{year:number, month:number}>({year:0,month:0})
  const { loadRecords } = useInspectionGraphUtil();
  const dispatch = useDispatch();

 
  useEffect(() => {
    if (userId) {
      const load = async () => {
        const result = await getInspectionStats(userId);
        let max = 0;
        const sorted = result.data.sort((a, b) => {
          const dateA = new Date(`${a.month}/01/${a.year}`);
          const dateB = new Date(`${b.month}/01/${b.year}`);
          if (a.inspectionCount > max) {
            max = a.inspectionCount;
          }
          if (dateA > dateB) return 1;
          if (dateA < dateB) return -1;
          return 0;
        });
        setMaxCount(max);
        dispatch(setBarsData(sorted));
      };
      load();
    }
  }, [userId]);
  useEffect(() => {
    if(yearMonth.year !== 0){
      loadRecords(yearMonth);
    }
  }, [pageIndex, pageSize,yearMonth, filters]);

  return (
    <Wrapper>
      <BarRecordsPopup />
      <Header>Completed Inspections</Header>
      {barData.map((item, index) => (
        <HorizontalBar setYearMonth={setYearMonth} key={index} month={item.month} year={item.year} inspectionCount={item.inspectionCount} max={maxCount} />
      ))}
    </Wrapper>
  );
};

export default MobileInspectionGraph;

const Wrapper = styled.div`
  display: none;
  gap: 15px;
  flex-direction: column;
  background-color: white;
  width: 100%;

  padding: 5px;
  border: 1px solid #ddd;
  margin-top: 9px;
  border-radius: 6px;

  @media only screen and (max-width: 840px) {
    display: flex;
  }
`;

const Header = styled.div`
  background-color: ${Logo_Sky};
  color: white;
  text-align: center;
  padding: 9px;
  border-radius: 6px;
`;
