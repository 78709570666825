import * as React from 'react';
import styled from 'styled-components';
import Table from './Table';
import {
  getFormSetupList,
  getFilledForm,
} from '../api/formSetup';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import useTablePage from '../hooks/useTablePage';
import {
  ModalDefaultDraggable,
  LoaderWithContainer,
} from '../molecules';
import ViewFormSetup from './ViewFormSetup';

const PermitDocumentFormsTable = ({
  settingsDoc,
  id,
}: Props) => {
  const {
    handleEdit,
    isModalOpen,
    handleCloseModal,
    values,
  } = useTablePage({});

  const [base64Image, setBase64Image] = React.useState('');

  React.useEffect(() => {
    if (values && Object.keys(values)?.length) {
      Promise.all([
        getFilledForm({
          documentId: id,
          formId: values?.id,
        }),
      ]).then(([formResponse]) => {
        setBase64Image(formResponse.data.image);
      });
    }
  }, [values]);

  const columns = column => {
    if (['description'].includes(column)) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Form Name';
      case 'jurisdictionNames':
        return 'Jurisdiction';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'id',
    'name',
    'jurisdictionNames',
    'clientFileName',
    'status',
    'lastComment',
  ];

  const modifiedGetDocForms = params =>
    Promise.all([
      getFormSetupList({
        ...params,
        documents: settingsDoc,
      }),
    ])
      .then(([formsResponse]) => {
        return {
          ...formsResponse,
          data: {
            ...formsResponse.data,
            results: formsResponse.data.results.map(
              form => form,
            ),
          },
        };
      })
      .catch(err => console.log(err))
      .finally(() => {});

  const closeModal = () => {
    handleCloseModal();
    setBase64Image('');
  };

  return (
    <TableWrapper>
      <h2>Document Forms</h2>
      <Table
        useViewModeInsteadEdit
        customSearch
        columns={columns}
        columnsOrder={columnsOrder}
        rows={modifiedGetDocForms}
        onEdit={handleEdit}
        onDelete={null}
        onConfigure={null}
      />
      {isModalOpen && (
        <StyledModalDraggable
          onClose={closeModal}
          title={values?.name || 'Form'}
        >
          {!base64Image && (
            <LoaderWrapper>
              <LoaderWithContainer />
            </LoaderWrapper>
          )}
          {base64Image && (
            <ViewFormSetup
              initialValues={
                {
                  file: `data:image/png;base64,${base64Image}`,
                } as any
              }
            />
          )}
        </StyledModalDraggable>
      )}
    </TableWrapper>
  );
};

interface Props {
  settingsDoc: number;
  id: number;
}

const TableWrapper = styled.div`
  margin-top: 28px;
`;

const LoaderWrapper = styled.div`
  width: 612px;
  height: 792px;
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default PermitDocumentFormsTable;
