import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../../constants/menu';
 
export type Props = {
  label: string;
  value: string;
};

const OtherField: FC<Props & {invalidMessage?: string}> = ({ label, value, invalidMessage, children }) => {
  const [readOnly, setReadOnly] = useState<boolean>(children === undefined);
  const error = invalidMessage !== null && invalidMessage !== undefined;
  return (
    <Wrapper>
      <Label error={error}>{label}</Label>
      <InputWrapper error={error} readOnly={readOnly}>
        {children}
        {!children && <Value>{value}</Value>}
        {error && <Error>{invalidMessage}</Error>}
      </InputWrapper>
    </Wrapper>
  );
};

export default OtherField;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
`;

const Error = styled.div`
 color: red;
`;

const Label = styled.div<{error:boolean}>`
  font-size: 18px;
  color: ${({error})=> error? 'red':'#316B99'}
`;

const InputWrapper = styled.div<{ readOnly: boolean, error?: boolean }>`
  border: ${({error})=> error ? '1px solid red' : ' 1px solid grey'};
  background-color: ${({ readOnly }) => (readOnly ? '#efefef' : '#fff')};
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  input{
     border: none;
  }
  .react-datepicker-ignore-onclickoutside {
    outline: none;
    border: none;
    &:focus-visible {
      outline: none;
      border: none;
    }
  }
`;

const Value = styled.div`
  color: grey;
  height: 20px;
`;
