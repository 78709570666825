import * as React from 'react';
import styled from 'styled-components';
import {
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';

import ButtonMain from '../atoms/ButtonMain';

const ButtonWithLoader = ({ loading, ...props }: Props) => (
  <Container>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <ButtonMain disabled={loading} {...props} />
    {loading && <CircularProgress size={16} />}
  </Container>
);

const Container = styled.span`
  position: relative;

  > .MuiCircularProgress-root {
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;

type Props = ButtonProps & { loading: boolean };

export default ButtonWithLoader;
