import React, { useEffect } from 'react';
import { InputBaseContainer, InputSelectWithLabel } from '../../../../../molecules';
import { Controller } from 'react-hook-form';
import { labels } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import DropdownList from './DropdownList';

const ActivityStatus = ({ control, setValue }) => {
  const { selectedInspection, statusData } = useSelector((s: RootState) => s.inspections);
  useEffect(() => {
    if (selectedInspection.inspectionType && statusData.length > 0) {
      const value = statusData.find(item => item.name === selectedInspection.statusName);
      setValue('activityStatus', value);
    }
  }, [statusData, selectedInspection]);
  return (
    <InputBaseContainer>
      <Controller
        name="activityStatus"
        defaultValue=""
        control={control}
        render={props => (
          <DropdownList data={statusData} label={labels.activityStatus} isLoading={statusData.length === 0}
            {...props}
          />
        )}
      />
    </InputBaseContainer>
  );
};

export default ActivityStatus;
