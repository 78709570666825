import * as React from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  getFormSetupFields,
  FormSetupType,
} from '../api/formSetup';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import FormSetupFields from './FormSetupFields';
import ResizableField from './ResizableField';
import { FormSetupFieldType } from '../types';
import { ButtonWithLoader } from '../molecules';
import {
  createResult,
  camelCaseToTitle,
} from '../utils/formSetupUtils';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FormSetupPlaceholders = ({
  url,
  type,
  setPlaceholders,
  setMarkup,
  initialValues,
  initialActiveFields,
  handleSubmitPlaceholders,
  setSelectedFontSize,
}: Props) => {
  const [fields, setFields] = React.useState(null);
  const [activeFields, setActiveFields] = React.useState<
    Array<FormSetupFieldType>
  >([]);

  const [model, setModel] = React.useState('');

  React.useEffect(() => {
    if (initialActiveFields?.length) {
      setActiveFields(initialActiveFields);
    }
  }, [initialActiveFields]);

  React.useEffect(() => {
    getFormSetupFields(GET_ALL_QUERY_PARAMS)
      .then(fieldsResponse => {
        if (type === 0) {
          setFields({
            fields: fieldsResponse.data.documents[0].fields,
          });
          setModel('Permit Documents');
        }
        if (type === 1) {
          setFields({
            fields: fieldsResponse.data.reports[0].fields,
          });
          setModel('User');
        }
      })
      .catch(err => console.log(err))
      .finally(() => {});
  }, []);
  return (
    <Container>
      <PdfWrapper>
        <FieldsWrapper>
          {initialValues && !url && (
            <StyledImage src={initialValues?.file} />
          )}
          {(!initialValues || url) && (
            <Document file={url}>
              <Page pageNumber={1} />
            </Document>
          )}
          {activeFields?.length
            ? activeFields?.map(field => {
                const parents = field?.parents?.map(
                  parent => camelCaseToTitle(parent),
                );
                const label =
                  parents?.length > 3
                    ? `${parents[parents?.length - 3]} > ${
                        parents[parents?.length - 2]
                      } > ${parents[parents?.length - 1]}`
                    : `${parents[parents?.length - 2]} > ${
                        parents[parents?.length - 1]
                      }`;
                return (
                  <ResizableField
                    label={label}
                    field={field}
                    setActiveFields={setActiveFields}
                    activeFields={activeFields}
                    key={`${parents[parents?.length - 1]}${
                      parents[parents?.length - 2]
                    }${parents[parents?.length - 3]}${
                      field?.value
                    }`}
                  />
                );
              })
            : null}
        </FieldsWrapper>
        <ButtonWrapper>
          <ButtonWithLoader
            type="button"
            loading={false}
            disabled={!activeFields?.length}
            onClick={
              handleSubmitPlaceholders
                ? () =>
                    handleSubmitPlaceholders({
                      createdMarkup: createResult(
                        activeFields,
                        model,
                      ),
                    })
                : () => {
                    setMarkup(
                      createResult(activeFields, model),
                    );
                    setPlaceholders(false);
                  }
            }
          >
            Save
          </ButtonWithLoader>
        </ButtonWrapper>
      </PdfWrapper>
      <FormSetupFields
        fields={fields}
        setActiveFields={setActiveFields}
        activeFields={activeFields}
        setSelectedFontSize={setSelectedFontSize}
        initialFont={initialValues?.fontSize}
      />
    </Container>
  );
};

interface Props {
  url: string;
  type: number;
  setPlaceholders: (data: boolean) => void;
  setMarkup?: (data: any) => void;
  initialValues: FormSetupType;
  initialActiveFields?: any;
  handleSubmitPlaceholders?: (data: any) => void;
  setSelectedFontSize?: (data: any) => void;
}

const Container = styled.section`
  display: flex;
`;

const PdfWrapper = styled.div`
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  height: 100%;

  .annotationLayer {
    display: none;
  }
`;

const FieldsWrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const StyledImage = styled.img`
  width: 612px;
  height: 792px;
`;

export default FormSetupPlaceholders;
