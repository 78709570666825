const hex2rgba = (hex, alpha = 1) => {
  if (!hex) {
    return hex;
  }
  const [r, g, b] = hex
    .match(/\w\w/g)
    .map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export default hex2rgba;
