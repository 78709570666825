import * as React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const AuthLayout = ({
  children,
  mainText,
  companyText,
  secondaryText,
}: AuthLayoutProps) => {
  return (
    <Root>
      <Grid item xs={12}>
        <Wrapper item xs={12}>
          <ContainerPosition>
            <Container item lg={4} sm={6} xs={11}>
              <DescriptionWrapper>
                <MainText>{mainText}</MainText>
                <CompanyText>
                  {companyText}
                </CompanyText>
                <SecondaryText>
                  {secondaryText}
                </SecondaryText>
              </DescriptionWrapper>
              {children}
            </Container>
          </ContainerPosition>
        </Wrapper>
      </Grid>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  background: transparent;
  align-items: center;
`;

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 483px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const MainText = styled.h1`
  color: var(--mainText);
  margin: 0;
  background-repeat: no-repeat;
  background-image: 'url("https://www.elitepermits.com/wp-content/uploads/2021/02/cropped-best-elite-permits-logo.png")';    
`;

const CompanyText = styled.h2`
  color: var(--mainText);
  margin: 0;
`;

const SecondaryText = styled.h3`
  color: var(--secondaryText);
  font-size: 1rem;
  font-weight: normal;
  margin: 20px 0 0 0;
`;

const Wrapper = styled(Grid).attrs({
  className: 'scrollable',
})`
  background: var(--defaultBackground);
  z-index: 1;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const ContainerPosition = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 20px;
`;

interface AuthLayoutProps {
  children: React.ReactNode | React.ReactNodeArray;
  mainText: string;
  companyText: string;
  secondaryText?: string;
}

export default AuthLayout;
