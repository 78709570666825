import store from '../redux/store';
import { MODULE_NAME } from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';

const getModuleName = (): string => {
  const state = store.getState();

  const { userRole, isEpsuperadmin } = state.userAuth.info;

  return isEpsuperadmin
    ? MODULE_NAME.SUPER_ADMIN
    : MODULE_NAME[USER_TYPES_VALUES[userRole]];
};

export default getModuleName;
