import * as React from 'react';
import styled from 'styled-components';
import { FieldError, FieldErrors } from 'react-hook-form';
import { Props as SelectProps } from 'react-select';

import { SelectInput, LabelForm } from '../atoms';

const InputSelectWithLabel = ({
  isRequired,
  label,
  error,
  theme,
  showInRow=false,
  ...props
}: Props) => {
  const { name } = props;
  return (
    <InputContainer showInRow={showInRow}>
      <LabelForm htmlFor={name} isRequired={isRequired}>
        {label}
      </LabelForm>
      <StyledSelectInput
        required={isRequired}
        error={!!error}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {error && (
        <span
          style={{
            color: '#f44336',
            fontSize: '0.75rem',
            margin: '3px 14px 0 14px',
          }}
        >
          {error.message
            ? error.message
            : console.log(error)}
        </span>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div<{showInRow:boolean}>`
  display: flex;
  flex-direction: ${({showInRow}) => showInRow ? 'row' : 'column'} ;
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
    width:  ${({showInRow}) => showInRow ? '159px' : 'inherit'};
    height:  ${({showInRow}) => showInRow ? '40px' : 'inherit'};
    align-items: center;
    display: ${({showInRow}) => showInRow ? 'flex' : 'inherit'};
  }
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
  height: fit-content;
`;

type Props = SelectProps & {
  showCompany?: boolean;
  isRequired?: boolean;
  error?: FieldError | FieldErrors;
  showInRow?: boolean;
};

export default InputSelectWithLabel;
