import * as React from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import openInNewTab from '../../../../../../utils/openInNewTab';

const Chat = ({ messages }: Props) => {
  const onImageClick = url => {
    openInNewTab(url);
  };

  if (messages?.length) {
    return (
      <CommentsContainer>
        {messages.map((el, index) => {
          return (
            <CommentItem
              key={el.id}
              isLast={messages.length === index + 1}
            >
              <div dangerouslySetInnerHTML={{ __html: el.text || el.body }} />
              {/* {parse(el?.text || el?.body)} */}
              {el.file && (
                <Preview
                  onClick={() => onImageClick(el.file)}
                  image={el.file}
                />
              )}
              <CommentInfo>{`${el?.username ? el?.username : el?.creatorUsername} ${el.createdAt}`}</CommentInfo> {/*Changed from el.creator to el.username by Sandeep 09/19/22*/}
            </CommentItem>
          );
        })}
      </CommentsContainer>
    );
  }
  return <NoComments>No comments found</NoComments>;
};

interface Props {
  messages?: any;
}

interface ItemProps {
  isLast: boolean;
}

interface PreviewProps {
  image: string;
}

const CommentsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const CommentItem = styled.div<ItemProps>`
  width: 100%;
  max-width: 680px;
  overflow: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #0b6b9b;
  text-align: left;
  margin-bottom: 10px;
  ${({ isLast }) => (isLast ? 'margin-bottom: 0;' : '')}

  .ql-size-small {
    font-size: 12px;
  }
  .ql-size-large {
    font-size: 24px;
  }
  .ql-size-huge {
    font-size: 32px;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .ql-align-right {
    text-align: right;
  }

  img {
    max-width: 658px;
    max-height: 411px;
  }
`;

const CommentInfo = styled.p`
  margin-bottom: 0;
  color: #0b6b9b;
  font-size: 14px;
`;

const Preview = styled.div<PreviewProps>`
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${({ image }) => image});
  margin-top: 14px;
  cursor: pointer;
`;

const NoComments = styled.p`
  font-size: 18px;
`;

export default Chat;
