import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Logo_Blue, Logo_Grey, Logo_Sky } from '../../../../../constants/menu';

type Props = {
  label: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  isSubmit?: boolean
};
const ButtonMain: FC<Props> = ({ label, onClick, isSubmit }) => {
  return (
    isSubmit ? 
    <ButtonWrapperInput type="submit" name={label} /> 
    :
    <div onClick={onClick}>
      <ButtonWrapper>{label}</ButtonWrapper>
    </div>
  );
};

export default ButtonMain;

const ButtonWrapper = styled.div`
  box-shadow: inset 10px 10px 10px ${Logo_Grey};
  padding: 6px;
  border: 1px solid grey;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  cursor: pointer;
  width: 145px;
  color: ${Logo_Sky};
  text-align: center;
  &:active {
    box-shadow: none;
  }
`;

const ButtonWrapperInput = styled.input`
  box-shadow: inset 10px 10px 10px ${Logo_Grey};
  padding: 6px;
  border: 1px solid grey;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  cursor: pointer;
  width: 145px;
  color: ${Logo_Sky};
  text-align: center;
  &:active {
    box-shadow: none;
  }
`;
