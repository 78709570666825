import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import DrawerContent from '../../Common/Containers/DrawerContent';
import TicketsTable from './TicketsTable';
import TicketsItems from './TicketsItems';
import useCloseDrawers from '../../../hooks/useCloseDrawers';
import useShowDetailsFromDashboard from '../../../hooks/useShowDetailsFromDashboard';

const Tickets = () => {
  useCloseDrawers();
  useShowDetailsFromDashboard();
  return (
    <ClientContainer>
      <BannerStrip label="TICKETS"></BannerStrip>
      <WrapperWithRightDrawer>
        <TicketsTable />
        <Drawer>
          <DrawerContent>
            <TicketsItems />
          </DrawerContent>
        </Drawer>
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default Tickets;
