import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { reduxBatch } from '@manaflair/redux-batch';

import { MODE } from '../constants';
import rootReducer, { rootSaga, RootState } from './root';
import { setTableLoadDataMode } from '../settings';
import { apiSlice } from './rtkQuery/apiSlice';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware(),
  sagaMiddleware,
  apiSlice.middleware,
];

const preloadedState = {};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: MODE !== 'production',
  preloadedState,
  enhancers: [reduxBatch],
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  const state: RootState = store.getState();

  setTableLoadDataMode(
    state.userSettings.tableLoadDataMode,
  );
});

export const { dispatch } = store;

export default store;

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were automatically composed together
