import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setInspections, setSelectedMonthYear } from '../../redux/slices/dashboardSlice';
import { format } from 'date-fns';

const HorizontalBar = ({ month, max, year, inspectionCount, setYearMonth }) => {
  const dispatch = useDispatch();

  const date_from = new Date(`${month}/01/${year}`);

  const monthAbbr = format(date_from, 'MMM');
  const width = Math.round((inspectionCount / max) * 100);

  
  const onClick = () => {
    setYearMonth({year,month});
    dispatch(setSelectedMonthYear(`${format(date_from, 'MMM')} ${year}`));
  };
  return (
    <Wrapper onClick={onClick}>
      <Month>{`${monthAbbr}/${year}`}</Month>
      <BarWrapper>
        <Bar width={width}>{inspectionCount}</Bar>
      </BarWrapper>
    </Wrapper>
  );
};

export default HorizontalBar;
const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  margin: 0;
  background-color: white;
  display: flex;
`;

const BarWrapper = styled.div`
  flex: 1;
`;

const Bar = styled.div<{ width: number }>`
  background-color: #00a2e8;
  color: white;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${({ width }) => `${width}%`};
  height: 40px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const Month = styled.div`
  width: 66px;
  margin-top: 11px;
  font-size: 13px;
  font-weight: bold;
`;
