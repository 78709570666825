import * as React from 'react';
import styled from 'styled-components';

import Table from '../Table';
import ActionsPanel from '../ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import ActivitiesStatusForm from '../../forms/ActivitiesStatusForm';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableBoolean } from '../../utils/convertTableValues';
import {
  ActivitiesStatusType,
  getActivitiesStatusList,
  createActivitiesStatus,
  deleteActivitiesStatus,
  updateActivitiesStatus,
} from '../../api/activities';
import useUserRole from '../../hooks/useUserRole';

const ActivitiesStatusTable = ({
  activitiesQuery,
}: // onRequestStatusChange,
ActivitiesTitleTableTypes) => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage<ActivitiesStatusType>({
    createEndpoint: createActivitiesStatus,
    updateEndpoint: updateActivitiesStatus,
    deleteEndpoint: deleteActivitiesStatus,
    modalCreateTitle: 'Add activity status',
    modalUpdateTitle: 'Update activity status',
    // onRequestStatusChange,
  });

  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'isDeleted',
        'activity',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    if (column === 'isActiveStatus') {
      return 'Is Active';
    }

    return tableColumnsFormatter(column);
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <>
      <h2>Activity {activitiesQuery.name} status</h2>
      <StyledActionsPanel
        onSearch={setSearch}
        onAdd={isSuperAdmin && handleAdd}
        isBreadCrumbsVisible={false}
        isActionPanelMoreVisible={false}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={getActivitiesStatusList}
          onEdit={handleEdit}
          onDelete={isSuperAdmin && handleDelete}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
          additionalRequestParams={{
            activity: activitiesQuery.id,
          }}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <ActivitiesStatusForm
            initialValues={values}
            initialErrors={errors}
            activitiesQuery={activitiesQuery}
            modalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
            addButtonText="Add new activity status"
            updateButtonText="Update activity status"
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

const Container = styled.div`
  margin: 16px 0;
`;

const StyledActionsPanel = styled(ActionsPanel)`
  margin: 0;
  width: 100%;
  height: 50px;
`;

interface ActivitiesTitleTableTypes {
  activitiesQuery: ActivitiesStatusType;
  // onRequestStatusChange: ({
  //   status: TablePageRequestStatus,
  //   name: TablePageRequestName,
  // }) => void;
}

export default ActivitiesStatusTable;
