import React from 'react';
import styled from 'styled-components';

const RecordData = ({data,row}) => {
    const rowId = row.values.id;
    const rowData = data?.find(d => d.id === rowId);
    return (
        <>
        {rowData && <Wrapper>
            <DataItem>
                <b>Name:&nbsp;&nbsp;</b>
                {rowData.name}
            </DataItem>
            <Separator>&nbsp;</Separator>
            <DataItem>
                <b>Address:&nbsp;&nbsp;</b>
                {rowData.address}
            </DataItem>
        </Wrapper>
        }
        </>
    );
};

export default RecordData;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DataItem = styled.div`
    width: 100%;
    margin-right: 8px;
`;

const Separator = styled.div`
  border-left: 1px solid #ddd;
  height: 100%;
`;