import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterEntry } from '../../redux/slices/filterSlice';
import { RootState } from '../../redux/root';
import { setPageIndex } from '../../redux/slices/paginationSlice';

const useFilterActions = (rows: any[]) => {
    const [filteredRows, setFilteredRows] = useState([]);
    const dispatch = useDispatch();
    const filters:FilterEntry[] = useSelector((state: RootState) => state.filters.filters);
    useEffect(() => {
        setFilteredRows(rows);
      }, [rows]);
    useEffect(() => {
        const filteredRows2 = rows.reduce((rowsToCheck, row) => {
          const willKeepRow = filters.reduce((keepRow, item) => {
            const foundField = row[item.filterName] && `${row[item.filterName]}`.toLowerCase().indexOf(item.filterValue) > -1;
            return keepRow && foundField;
          }, true);
          if (willKeepRow) {
            rowsToCheck.push(row);
          }
          return rowsToCheck;
        }, []);
        setFilteredRows(filteredRows2);
        dispatch(setPageIndex(0));
      }, [filters]);

    return filteredRows;
};

export default useFilterActions;
