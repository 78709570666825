import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputTextWithLabel,
  InputBaseContainer,
  CheckboxWithLabel,
  InputSelectWithLabel,
  InputCurrencyWithLabel,
  ButtonWithLoader,
} from '../molecules';

import {
  getFranchiseList,
  FranchiseType,
} from '../api/franchise';

import {
  getJurisdictions,
  JurisdictionType,
} from '../api/jurisdiction';

import { FeesType, FeesButtonType } from '../api/fees';

import { FormServerError } from '../types';
import { GET_ALL_QUERY_PARAMS } from '../constants';

import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import useUserRole from '../hooks/useUserRole';
import { getVocabulariesDataList } from '../api/vocabulary';

const labels = {
  name: 'Name',
  active: 'Active',
  franchise: 'Branch',
  jurisdiction: 'Jurisdiction',
  amount: 'Amount',
  feeServiceType: 'Fee Service Type',
  skuCode: 'Sku code',
  description: 'Description',
  reimburse: 'Reimburse',
  employeeCanEdit: 'Employee can edit',
  isDefault: 'Set as default in new Branch',
};

const initialSchema = {
  name: yup.string().required().label(labels.name),
  franchise: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a franchise from the list')
    .nullable()
    .label(labels.franchise),
  jurisdiction: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a jurisdiction from the list')
    .nullable()
    .label(labels.jurisdiction),
  feeServiceType: yup
    .object()
    .required()
    .label(labels.feeServiceType),
  amount: yup
    .number()
    .required()
    .label(labels.amount)
    .max(99999.99, 'Max value is $99,999.99'),
  skuCode: yup.string().required().label(labels.skuCode),
  isActive: yup.boolean().label(labels.active),
  description: yup.string().label(labels.amount),
  reimburse: yup.boolean().label(labels.reimburse),
  employeeCanEdit: yup
    .boolean()
    .label(labels.employeeCanEdit),
  isDefault: yup.boolean().label(labels.isDefault),
};

const FeesForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new item',
  updateButtonText = 'Update item',
  initialErrors,
  feesQuery,
}: Props) => {
  const [schema, setSchema] = React.useState(
    yup.object().shape(initialSchema),
  );

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    formState,
    watch,
  } = useForm<FeesType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as FeesType),
  });

  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const watchJurisdictions = watch('jurisdiction', []);

  const [isLoading, setIsLoading] = React.useState(false);

  const [
    franchisesData,
    setFranchisesData,
  ] = React.useState([]);

  const [
    jurisdictionData,
    setJurisdictionData,
  ] = React.useState([]);

  const [
    feeServiceTypeData,
    setFeeServiceTypeData,
  ] = React.useState([]);

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<FeesType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getJurisdictions({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
    ])
      .then(([jurisdictionsResponse]) => {
        setJurisdictionData(
          jurisdictionsResponse.data.results,
        );
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);
  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 11,
        ordering: 'title',
      }),
    ])
      .then(([feeServiceTypeResponse]) => {
        setFeeServiceTypeData(
          feeServiceTypeResponse.data.results,
        );
        if (initialValues?.feeServiceType) {
          const initialType = feeServiceTypeResponse.data.results.find(
            el => el.title === initialValues.feeServiceType,
          );
          setValue('feeServiceType', initialType);
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (!watchJurisdictions || !watchJurisdictions.length) {
      setFranchisesData([]);
      setValue('franchise', []);
    }

    if (
      watchJurisdictions &&
      watchJurisdictions.length > 0
    ) {
      Promise.all([
        getFranchiseList({
          ...GET_ALL_QUERY_PARAMS,
          ordering: 'name',
        }),
      ])
        .then(([franchisesResponse]) => {
          const filteredFranchises = [];

          watchJurisdictions.forEach(jurisdiction => {
            if (jurisdiction.name === 'Select all') {
              let allFranchisesList = [];
              jurisdictionData.forEach(juri => {
                juri.franchise.forEach(franchiseId => {
                  allFranchisesList = [
                    ...allFranchisesList,
                    ...franchisesResponse.data.results.filter(
                      item => item.id === franchiseId,
                    ),
                  ];
                });
              });
              const uniqueListOfAllFranchises = allFranchisesList.filter(
                (obj, index, self) => {
                  return (
                    index ===
                    self.findIndex(t => t.id === obj.id)
                  );
                },
              );

              setFranchisesData(uniqueListOfAllFranchises);
              setValue(
                'franchise',
                uniqueListOfAllFranchises,
              );
            }
            jurisdiction.franchise.forEach(franchiseId => {
              const filtered = franchisesResponse.data.results.filter(
                item => item.id === franchiseId,
              );

              filteredFranchises.push(...filtered);
            });
          });

          const uniqueItemsList = filteredFranchises.filter(
            (obj, index, self) => {
              return (
                index ===
                self.findIndex(t => t.id === obj.id)
              );
            },
          );
          setFranchisesData([...uniqueItemsList]);

          if (!initialValues.franchise) {
            setValue('franchise', uniqueItemsList);
          }
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, [watchJurisdictions]);

  React.useEffect(() => {
    if (isFranchiseAdmin) {
      setSchema(
        yup.object().shape({
          ...initialSchema,
          franchise: yup
            .array()
            .nullable()
            .label(labels.franchise),
          jurisdiction: yup
            .array()
            .nullable()
            .label(labels.jurisdiction),
        }),
      );
    } else {
      yup.object().shape(initialSchema);
    }
  }, [isFranchiseAdmin]);

  React.useEffect(() => {
    if (initialValues?.franchise) {
      Promise.all([
        getFranchiseList({
          ...GET_ALL_QUERY_PARAMS,
          ordering: 'name',
        }),
      ]).then(([franchisesResponse]) => {
        const initialFranchises = [];

        initialValues.franchise.forEach(item => {
          const franchises = franchisesResponse.data.results.find(
            el => el.id === item,
          );
          initialFranchises.push(franchises);
        });

        setValue('franchise', initialFranchises);
      });
    }
  }, [initialValues]);

  const onCurrencyChange = (value, name) => {
    setValue(name, value);
  };

  React.useEffect(() => {
    if (!jurisdictionData.length) {
      setValue('jurisdiction', []);
    }

    if (initialValues?.id && jurisdictionData.length) {
      setValue(
        'jurisdiction',
        jurisdictionData.filter(item =>
          initialValues.jurisdiction.includes(item.id)),
      );
    }
  }, [jurisdictionData]);

  const middleware = (
    submitFn: (data: FeesType) => void,
  ) => (data: FeesType) => {
    submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      feeType: feesQuery?.id,
      franchise: (data.franchise as FranchiseType[])
        ?.filter(item => item?.id)
        .map(item => item.id),
      jurisdiction: (data.jurisdiction as JurisdictionType[])
        ?.filter(item => item.id)
        .map(item => item.id),
      feeServiceType: data.feeServiceType.id,
    });
  };

  const getErrorServer = () => {
    return {
      ...errors.franchise,
      message: errors.franchise.types.server0,
    };
  };

  return (
    <Container
      disabled={
        isLoading ||
        isDeleted ||
        (isSubmitting && !modalError)
      }
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('name', schema)}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="amount"
            defaultValue=""
            control={control}
            render={props => (
              <InputCurrencyWithLabel
                allowDecimals
                error={errors.amount}
                label={labels.amount}
                onCurrencyChange={onCurrencyChange}
                isRequired={isYupFieldRequired(
                  'amount',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="jurisdiction"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                selectAllActive
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                isDisabled={isLoading || !isSuperAdmin}
                label={labels.jurisdiction}
                error={errors.jurisdiction}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'jurisdiction',
                  schema,
                )}
                options={jurisdictionData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="franchise"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                isDisabled={
                  isLoading ||
                  !isSuperAdmin ||
                  !franchisesData.length
                }
                label={labels.franchise}
                error={errors.franchise && getErrorServer()}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'franchise',
                  schema,
                )}
                options={franchisesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="feeServiceType"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                isLoading={isLoading}
                isDisabled={isLoading || isFranchiseAdmin}
                label={labels.feeServiceType}
                error={errors.feeServiceType}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.title}
                options={feeServiceTypeData}
                isRequired={isYupFieldRequired(
                  'feeServiceType',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="skuCode"
            error={errors.skuCode}
            inputRef={register}
            label={labels.skuCode}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired(
              'skuCode',
              schema,
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <InputTextWithLabel
            name="description"
            error={errors.description}
            inputRef={register}
            label={labels.description}
            isRequired={isYupFieldRequired(
              'description',
              schema,
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="isActive"
            control={control}
            valueName="checked"
            defaultValue={initialValues?.isActive ?? true}
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.isActive}
                label={labels.active}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />

          <Controller
            name="reimburse"
            control={control}
            valueName="checked"
            defaultValue={initialValues?.reimburse ?? true}
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.reimburse}
                label={labels.reimburse}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />

          <Controller
            name="employeeCanEdit"
            control={control}
            valueName="checked"
            defaultValue={
              initialValues?.employeeCanEdit ?? true
            }
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.employeeCanEdit}
                label={labels.employeeCanEdit}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        {isSuperAdmin && (
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
        )}

        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: FeesType;
  onSubmit: (data: FeesType) => void;
  initialErrors?: FormServerError<FeesType>;
  feesQuery?: FeesButtonType;
  modalError: boolean;
}

export default FeesForm;
