import React, { useState } from 'react';
import styled from 'styled-components';

const InspectionTabs = ({value, setValue}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <div>
        <Tabs>
          <Tab value={value} onClick={e => setValue(0)} index={0}>Details</Tab>
          <Tab value={value} onClick={e => setValue(1)} index={1}>Comments</Tab>
        </Tabs>
      </div>
    </Wrapper>
  );
};

export default InspectionTabs;

const Wrapper = styled.div`
  margin: 15px;
`;

const Tabs = styled.div`
  width: 100%;
  display: flex;
  gap: 3px;
`;
const Tab = styled.div<{ index: number, value: number }>`
  width: 200px;
  border: 1px solid lightblue;
  background-color: ${({index, value}) => index === value ? '#A4C7ED' : '#fff'} ;
  border-radius: 6px;
  padding:10px;
`;
