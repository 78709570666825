import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useManuallyUpdate from './useManuallyUpdate';
import { RootState } from '../redux/root';
import { setSearchToken } from '../redux/slices/filterSlice';

export enum TablePageRequestStatus {
  Request = 'REQUEST',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export enum TablePageRequestName {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

const defaultEndpointPlaceholder = data =>
  new Promise(resolve => resolve(data));

const useTablePage = <T extends object>({
  createEndpoint = defaultEndpointPlaceholder,
  updateEndpoint = defaultEndpointPlaceholder,
  deleteEndpoint = defaultEndpointPlaceholder,
  modalCreateTitle = 'Add',
  modalUpdateTitle = 'Update',
  onRequestStatusChange = () => {},
}: useTablePageSettings): useTablePageReturnType<T> => {
  const dispatch = useDispatch();
  const search = useSelector((state: RootState) => state.filters.searchToken);
  const setSearch = token => {
   dispatch(setSearchToken(token));
  };
  const [modalSuccess, setModalSuccess] = React.useState<
    boolean
  >(false);
  const [modalError, setModalError] = React.useState<
    boolean
  >(false);

  const [isModalOpen, setIsModalOpen] = React.useState<
    boolean
  >(false);

  const [isApiSuccess, setIsApiSuccess] = React.useState<boolean>(false);
  const [successResponse,setSuccessResponse] = React.useState(null)
  const [selectedItem, setSelectedItem] = React.useState<T>(
    null,
  );

  const [
    modalErrorTitle,
    setModalErrorTitle,
  ] = React.useState<string>('');

  const [modalTitle, setModalTitle] = React.useState<
    string
  >('');

  const [values, setValues] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
  const [
    innerModalErrors,
    setInnerModalErrors,
  ] = React.useState(null);

  const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();

  const createOrUpdate = fields => {
    const id =
      fields instanceof FormData
        ? fields.get('id')
        : fields.id;
    const endpoint = id ? updateEndpoint : createEndpoint;

    const requestStatusName = id
      ? TablePageRequestName.Update
      : TablePageRequestName.Create;

    onRequestStatusChange({
      name: requestStatusName,
      status: TablePageRequestStatus.Request,
    });

    return endpoint(fields)
      .then((res) => {
        setSuccessResponse(res)
        updateTable();
        setIsModalOpen(false);
        if(fields.userRole !=="3")
        setModalSuccess(true);
        setValues(null);
        setErrors(null);
        setInnerModalErrors(null);
        setModalErrorTitle('');
        setIsApiSuccess(true);
        onRequestStatusChange({
          name: requestStatusName,
          status: TablePageRequestStatus.Success,
        });
      })
      .catch(error => {
        const { data, status } = error.response;
        if (status > 500) {
          setModalError(true);
          return;
        }

        if (
          status <= 500 &&
          !data &&
          Object.keys(data).length === 0
        ) {
          setModalError(true);
          setModalErrorTitle(
            'Something went wrong. Please, contact support',
          );
          return;
        }

        if (
          status <= 500 &&
          data.nonFieldErrors &&
          typeof data.nonFieldErrors !== 'string'
        ) {
          setModalError(true);
          setModalErrorTitle(
            data.nonFieldErrors.join(', '),
          );
          return;
        }

        if (
          status <= 500 &&
          data.nonFieldErrors &&
          typeof data.nonFieldErrors === 'string'
        ) {
          setModalError(true);
          setModalErrorTitle(data.nonFieldErrors);
          return;
        }

        if (
          status === 400 &&
          data &&
          data?.nonFieldErrors
        ) {
          setModalError(true);
          setModalErrorTitle(data?.nonFieldErrors);
          return;
        }

        setErrors(data);
        setInnerModalErrors(data);

        onRequestStatusChange({
          name: requestStatusName,
          status: TablePageRequestStatus.Failure,
        });
      });
  };

  const handleAdd = () => {
    setModalTitle(modalCreateTitle);
    setIsModalOpen(true);
    setIsApiSuccess(false)
  };

  const handleEdit = data => {
    setValues(data);
    setModalTitle(data.isDeleted ? '' : modalUpdateTitle);
    setIsModalOpen(true);
  };

  const handleDelete = item => setSelectedItem(item);

  const deleteById = id => {
    onRequestStatusChange({
      name: TablePageRequestName.Delete,
      status: TablePageRequestStatus.Request,
    });

    return deleteEndpoint(id)
      .then(() => {
        updateTable();
        setModalSuccess(true);
        setSelectedItem(null);

        onRequestStatusChange({
          name: TablePageRequestName.Delete,
          status: TablePageRequestStatus.Success,
        });
      })
      .catch(() => {
        // Disable for now
        // setModalError(true);
        // setModalErrorTitle(e.response?.data?.detail);
        setSelectedItem(null);

        onRequestStatusChange({
          name: TablePageRequestName.Delete,
          status: TablePageRequestStatus.Failure,
        });
      });
  };

  const handleCloseModal = () => {
    setModalTitle('');
    setValues(null);
    setErrors(null);
    setInnerModalErrors(null);
    setIsModalOpen(false);
  };

  const handleCloseModalInfo = () => {
    setModalSuccess(false);
    setModalError(false);
    setIsApiSuccess(false)
  };

  return {
    search,
    setSearch,
    modalError,
    modalSuccess,
    showErrorModal: setModalError,
    showSuccessModal: setModalSuccess,
    isModalOpen,
    openModal: setIsModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    setModalTitle,
    values,
    setValues,
    errors,
    innerModalErrors,
    setErrors,
    tableManuallyUpdate,
    updateTable,
    createOrUpdate,
    deleteById,
    handleAdd,
    isApiSuccess,
    successResponse,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
  };
};

interface useTablePageSettings {
  createEndpoint?: (data: any) => Promise<any>;
  updateEndpoint?: (data: any) => Promise<any>;
  deleteEndpoint?: (id: number) => Promise<any>;
  modalCreateTitle?: string;
  modalUpdateTitle?: string;
  onRequestStatusChange?: ({
    status: TablePageRequestStatus,
    name: TablePageRequestName,
  }) => void;
}

interface useTablePageReturnType<T extends object> {
  search: string;
  setSearch: (search: string) => void;
  modalError: boolean;
  modalSuccess: boolean;
  showErrorModal: (status: boolean) => void;
  showSuccessModal: (status: boolean) => void;
  isModalOpen: boolean;
  openModal: (status: boolean) => void;
  selectedItem: T;
  setSelectedItem: (item: T) => void;
  modalTitle: string;
  setModalTitle: (title: string) => void;
  values: any;
  setValues: (values: any) => any;
  innerModalErrors: any;
  errors: any;
  setErrors: (values: any) => any;
  tableManuallyUpdate: number;
  updateTable: () => void;
  createOrUpdate: (fields: any) => Promise<any>;
  deleteById: (id: number) => void;
  handleAdd: () => void;
  handleEdit: (data: T) => void;
  handleDelete: (data: T) => void;
  handleCloseModal: () => void;
  handleCloseModalInfo: () => void;
  modalErrorTitle: string;
  isPaidManuallySubmit: boolean;
  setIsPaidManuallySubmit: (data: boolean) => void;
}

export default useTablePage;
