import React, { FC } from 'react';
import styled from 'styled-components';

const TableCell: FC<{
  header: string;
  value?: string;
  hideLabel?: boolean;
}> = ({ header, value, hideLabel = false, children }) => {
  return (
    <TableCellWrapper>
        <div title={value} style={{wordWrap: 'break-word'}}>
              <TableCellLabel hideLabel={hideLabel}>
        {header}
      </TableCellLabel>
      <CellValue>{value}</CellValue>
      {children}
        </div>
    
    </TableCellWrapper>
  );
};

export default TableCell;

const CellValue = styled.div`
  font-size: 17px;
    margin: 4px;
    text-wrap: wrap;
    @media only screen and (max-width: 540px) {
      font-size: 14px;
    }
`;

const TableCellWrapper = styled.div`
  border-right: 1px solid grey;
  padding: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  @media only screen and (max-width: 840px) {
    grid-template-columns: repeat(3, 1fr);
    border-right: none;
  }
`;

const TableCellLabel = styled.div<{ hideLabel: boolean }>`
  font-weight: bold;
  font-size: 0.7em;
  display: none;

  @media only screen and (max-width: 840px) {
    display: ${({ hideLabel }) =>
      hideLabel ? 'none' : 'block'};
    font-size:12px;
  }
`;
