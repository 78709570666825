import * as React from 'react';
import styled from 'styled-components';

import { ReportTableType } from '../../../api/reports';
import { DATETIME_FORMAT } from '../../../constants';
import Table from '../../../organisms/Table';
import { formatDateTime } from '../../../utils/formatDate';
import {
  inspectorColumns,
  inspectorColumnsOrder,
} from '../../../utils/allActivitiesPageColumns';
import {
  getPermitInspectionById,
  InspectionsType,
  getPermitInspections,
  sendInspectionMessage,
  updatePermitInspection,
} from '../../../api/inspections';
import { getProjects } from '../../../api/projects';
import { getPermits } from '../../../api/permits';
import { getInspectionTypes } from '../../../api/inspectionTypes';
import { getUsers } from '../../../api/users';
import { USER_TYPES_VALUES } from '../../../constants/forms';
import { getVocabulariesDataList } from '../../../api/vocabulary';
import { getCurrentUser } from '../../../api/userSettings';
import SmsIcon from '@material-ui/icons/Sms';
import useTablePage from '../../../hooks/useTablePage';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalInfo from '../../../molecules/ModalInfo';
import {
  ButtonWithLoader,
  ModalDefaultDraggable,
} from '../../../molecules';
import useDevice from '../../../hooks/useDevice';
import PermitInspectionsForm from '../../../forms/permitInspectionForm/PermitInspectionsForm';
import useManuallyUpdate from '../../../hooks/useManuallyUpdate';
import { useState } from 'react';
import DailyInspections from '../../Responsive/DailyInspections/DailyInspections';
import Wrapper from '../print-mixin';
import { useForm } from 'react-hook-form';
import DailyInspectionRecords from '../DailyInspectionReport/DailyInspectionRecords';
import DailyInspectionsSearchBox from '../DailyInspectionReport/DailyInspectionSearchBox';
import { calculatePeriod } from '../DailyInspectionReport/Utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { setInspectorName } from '../../../redux/slices/inspectionsSlice';

const StyledRefreshIcon = styled(SmsIcon)`
  fill: #ff8c00;
`;

const DailyInspectorReport = React.forwardRef(
  (
    {
      fromDate,
      toDate,
      inspector,
      setIsValidForExport,
      manuallyUpdate,
      updateTable,
      inspectionStatus,
    }: ReportTableType,
    ref,
  ) => {
    const [lastComment, setLastComment] = React.useState(
      null,
    );
    const {
      errors,
      control,
      handleSubmit,
      } = useForm<ReportTableType>({
      mode: 'onChange',
    });
    const data = {
      fromDate,
      toDate,
      inspector,
    };
    const [currentUser, setCurrentUser] = React.useState(
      null,
    );

    const [tableData, setTableData] = useState<InspectionsType[]>([]);
    React.useEffect(() => {
      Promise.all([getCurrentUser()]).then(
        ([currentUserResponse]) => {
          setCurrentUser(currentUserResponse.data.id);
        },
      );
    }, []);

    // new code

    const [open, setOpen] = React.useState(false);
    const [messageData, setMessageData] = React.useState(
      {},
    );

    const handleClickOpen = row => {
      setOpen(true);
      setMessageData(row);
    };

    const handleClose = () => {
      setOpen(false);
    };
    const [modalSuccess1, setModalSuccess] = React.useState(
      false,
    );

    const [additionalRowData, setAdditionalRowData] = React.useState({})
    const [modalErr, setModalErr] = React.useState(false);
    const handleSendMessage = () => {
      const cellPhone = parseInt(messageData?.cellPhone.split("-").join(""))
      sendInspectionMessage({
        phone_number: cellPhone,
        inspector_id: messageData?.inspector,
        address: messageData?.projectAddress,
        report_status: "null",
        inspection_status: messageData?.information,
        inspection_id: messageData?.id
      })
        .then(() => {
          handleClose();
          setModalSuccess(true);
        })
        .catch(() => {
          handleClose();
          setModalErr(true);
        });
    };

    const onModalClose = () => {
      setModalErr(false);
      setModalSuccess(false);
      updateTable()
    };

    let isReuseFirstRequest = true;

    const {
      isModalOpen,
      modalError,
      modalSuccess,
      modalTitle,
      modalErrorTitle,
      values,
      createOrUpdate,
      handleEdit,
      handleCloseModalInfo,
      handleCloseModal,
      setValues,
      // updateTable,
      setModalTitle,
      openModal
     } = useTablePage({
       updateEndpoint: updatePermitInspection,
       modalUpdateTitle: 'Update Inspection',
    });
    const inspectorName = useSelector((s:RootState) => s.inspections.inspectorName)
    const [inspectionCount, setInspectionCount] = React.useState(null)
    const additionalActions = row =>
      !row.isDeleted && [
        {
          title: 'Send Message',
          handler: () => {
            if (isReuseFirstRequest) {
              handleClickOpen(row);
            }
          },
          Icon: row.isInvitationSent ? SmsIcon : SmsIcon,
        },
      ];

    // new code
    const getInspections = params =>
      Promise.all([
        getPermitInspections({
          ...params,
          inspector: currentUser,
          // inspectionDate: formatDateTime(
          //   data?.fromDate,
          //   'yyyy-MM-dd',
          //   // '2023-01-06',
          // ),
          dateFrom: formatDateTime(
            data?.fromDate,
            'yyyy-MM-dd',
          ),
          dateTo: formatDateTime(
            data?.fromDate,
            'yyyy-MM-dd',
          ),
          isCompleted: false,
          // ordering: "-is_emergency,follow_up",
          // limit: 25,
          // offset: 0,
          // status: inspectionStatus?.toString(),
          // ordering: ' period, distance',
        }),
        // getProjects({ ...GET_ALL_QUERY_PARAMS }),
        // getPermits({ ...GET_ALL_QUERY_PARAMS }),
        // getInspectionTypes({ ...GET_ALL_QUERY_PARAMS }),
        // getUsers({
        //   ...GET_ALL_QUERY_PARAMS,
        //   userRole: USER_TYPES_VALUES.INSPECTOR,
        // }),
        // getUsers({
        //   ...GET_ALL_QUERY_PARAMS,
        //   userRole: USER_TYPES_VALUES.EMPLOYEE,
        // }),
        // getUsers({
        //   ...GET_ALL_QUERY_PARAMS,
        // }),
        // getVocabulariesDataList({
        //   ...GET_ALL_QUERY_PARAMS,
        //   vocabularyEntity: 3,
        //   ordering: 'title',
        // }),
      ]).then(
        ([
          inspectionsResponse,
          // projectsResponse,
          // permitsResponse,
          // inspectionTypesResponse,
          // inspectorsResponse,
          // employeesResponse,
          // allUsersResponse,
          // typesResponse,
        ]) => {
          if (inspectionsResponse.data?.results?.length === 0) {
            setIsValidForExport(false);
            setInspectorName(null)
          } else {
            const inspectorName = inspectionsResponse.data.results[0]?.inspectorFirstName + " " + inspectionsResponse.data.results[0]?.inspectorLastName;
            dispatch(setInspectorName(inspectorName));
            setInspectionCount(inspectionsResponse?.data?.count)
            setIsValidForExport(true);
            dispatch(setInspectionsTypeData(inspectionsResponse.data.results));
            const commentData = inspectionsResponse.data.results.map(
              el => ({
                id: el.id,
                lastComment: el.lastComment,
              }),
            );
            setLastComment({
              commentData,
              isDailyInspection: true,
            });
            const informationData = inspectionsResponse.data.results.map(
              el => ({
                id: el.id,
                information: el.information,
              }),
            );

            const recordData = inspectionsResponse.data.results.map(
              el => ({
                id: el.id,
                name:  `${el?.projectName} | ${el?.permitName}`,
                address: el?.projectAddress
              }),
            );


            setAdditionalRowData({
              commentData,
              informationData,
              recordData,
              isDailyInspection: true,
            })
          }
          return {
            ...inspectionsResponse,
            data: {
              ...inspectionsResponse.data,
              results: inspectionsResponse.data.results.map(
                item => {                  
                  const _item = {...item};
                  delete(_item['projectAddress']);
                  // delete(_item['inspectionDate']);
                  return {
                    ..._item,
                    // projectNamePermitName: `${item?.projectName} | ${item?.permitName}`,
                    permitNumber: _item?.permitNumber,
                    // projectAddress: item?.projectAddress,
                    projectManager: `${_item?.managerFirstName} ${_item?.managerLastName}`,
                    jurisdiction: _item?.projectJurisdiction,
                    inspectionType: _item?.activityTypeName,
                    id: _item?.id,
                    // inspectionDate: item.inspectionDate
                    //   ? item.inspectionDate
                    //   : '',
                    period: calculatePeriod(_item),
                      
                    contact: `${_item?.contactFirstName} ${_item?.contactLastName}`,
                      //  contact?.firstName && item?.contact
                      //  ? `${contact?.firstName} ${contact?.lastName}`
                      //  : '',
                    contactPhone: _item?.phoneNumber,
                    //  contact?.firstName && item?.contact
                    //   ? `${contact.phoneNumber}`
                    //   : '',
                      cellPhone: _item?.cellPhone,
                    //  contact?.firstName && item?.contact
                    //   ? `${contact.mobilePhoneNumber}`
                    //   : '',


                  };
                },
              ),
            },
          };
        },
      );

      const handleInspectionEdit = (params) => {
        const param = {id: params?.id}
        Promise.all([getPermitInspectionById(param)]).then(([inspection]) => {
          setValues(inspection?.data)
          setModalTitle(inspection?.data?.isDeleted ? '' : 'Update');
          openModal(true);
        })
      }
      const closeInfoModal = () => {
        // getInspections({})
        updateTable()
        handleCloseModalInfo();
      };
      
    return (
      <Wrapper ref={ref}>
        <Container>
          {(modalSuccess1 || modalErr) && (
            <ModalInfo
              timeToClose={5000}
              isError={modalErr}
              onClose={() => onModalClose()}
              errorMsg="Cannot send the message"
            />
          )}
          {open && (
            <ModalDefaultDraggable
              // title="Are you sure you want to send Message to Client?"
              title="SMS Confirmation"
              onClose={handleClose}
            >
              <>
                <p>
                  Are you sure you want to message the
                  contact?
                </p>
                <br />
                <ButtonWithLoader
                  type="submit"
                  onClick={handleSendMessage}
                >
                  Send
                </ButtonWithLoader>
              </>
            </ModalDefaultDraggable>
          )}
        </Container>
        {currentUser && <DailyInspections data={additionalRowData} tableData={tableData} onClick={handleClickOpen} />}
        {currentUser && <TableWrapper>
          {inspectorName && <><Title>Daily Inspection Report</Title>

        <List>
          <li>
            <Bold>Inspector: </Bold> {inspectorName}
          </li>
          <li>
            <Bold>Inspection Date: </Bold>
            {formatDateTime(fromDate, "MM/dd/yyy")}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
          <li>
            <Bold>Total Inspections: </Bold>{inspectionCount}
          </li>
        </List></>}
        <DailyInspectionsSearchBox />
        <DailyInspectionRecords 
            setTableData={setTableData} 
            fromDate={fromDate} 
            setInspectorName={setInspectorName} 
            setInspectionCount={setInspectionCount} 
            handleEdit={handleEdit}
            openSMS={handleClickOpen}
            setIsValidForExport={setIsValidForExport}
            />
        </TableWrapper>}
        

{isModalOpen &&(
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseModal}
        >
          <PermitInspectionsForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={values?.permit}
            // isReadOnly={true}
            setInitialValues={setValues}
            updateTable={updateTable}
          />
        </ModalDefaultDraggable>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={closeInfoModal}
          errorMsg={modalErrorTitle}
        />
      )}

      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;
const TableWrapper = styled.div`
   @media only screen and (max-width: 840px) {
      display: none;
    }
`;

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

export default DailyInspectorReport;
