import * as React from 'react';
import styled from 'styled-components';
import RefreshIcon from '@material-ui/icons/Refresh';

import {
  convertTableBoolean,
  convertTableUserRole,
} from '../../utils/convertTableValues';
import Table from '../../organisms/Table';
import ActionsPanelUsers, {
  SelectedAdditionalParams,
} from '../../organisms/ActionsPanelUsers';
import UsersForm from '../../forms/UsersForm';
import useTablePage from '../../hooks/useTablePage';
import DashboardLayout from '../../layouts/DashboardLayout';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import {
  createUser,
  updateUser,
  deleteUser,
  UserTableType,
  getUsers,
  resendInvitation,
} from '../../api/users';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useUserRole from '../../hooks/useUserRole';

const StyledRefreshIcon = styled(RefreshIcon)`
  fill: #ff8c00;
`;

const UsersPage = () => {
  const {
    setSearch,
    handleAdd,
    search,
    tableManuallyUpdate,
    isModalOpen,
    values,
    handleEdit,
    handleDelete,
    errors,
    createOrUpdate,
    selectedItem,
    deleteById,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
    setSelectedItem,
    modalError,
    modalTitle,
    modalSuccess,
    updateTable,
  } = useTablePage<UserTableType>({
    updateEndpoint: updateUser,
    deleteEndpoint: deleteUser,
    createEndpoint: createUser,
    modalCreateTitle: 'Add User',
    modalUpdateTitle: 'Update User',
  });

  const { isSuperAdmin } = useUserRole();

  const [isCreate, setIsCreate] = React.useState<boolean>(
    false,
  );

  React.useEffect(() => {
    if (modalTitle && modalTitle !== '') {
      if (modalTitle.toLowerCase().indexOf('add') !== -1) {
        setIsCreate(true);
      } else {
        setIsCreate(false);
      }
    }
  }, [modalTitle]);

  const [
    selectedAdditionalParams,
    setSelectedAdditionalParams,
  ] = React.useState<SelectedAdditionalParams>(undefined);

  const [isCompanyView, setIsCompanyView] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      selectedAdditionalParams &&
      selectedAdditionalParams.clientType === 2 &&
      selectedAdditionalParams.userRole === 3
    ) {
      setIsCompanyView(true);
    } else {
      setIsCompanyView(false);
    }
  }, [selectedAdditionalParams]);

  const columnsOrder = [
    'companyName',
    'firstName',
    'lastName',
    'phoneNumber',
    'userRole',
    'isActive',
  ];

  const columns = React.useCallback(
    column => {
      if (
        [
          'id',
          'isDeleted',
          'email',
          'address',
          'city',
          'state',
          'street',
          'zipCode',
          'prNumber',
          'clientType',
          'licenses',
          'mobilePhoneNumber',
          'parentCompany',
          'tradesNames',
          'franchiseNames',
          'franchise',
          'trade',
          'avatar',
          'userRole',
          'isInvitationSent',
          'isEpsuperadmin',
          'createdAt',
          isCompanyView ? null : 'companyName',
        ].includes(column)
      ) {
        return null;
      }

      return tableColumnsFormatter(column);
    },
    [isCompanyView],
  );

  const formatValue = (value: any, column: string) =>
    convertTableBoolean(
      convertTableUserRole(value, column),
    );

  let isReuseFirstRequest = true;

  const [reuseModal, setReuseModal] = React.useState(false);

  const additionalActions = row =>
    !row.isDeleted && [
      {
        title: 'Resend invitation',
        handler: () => {
          if (isReuseFirstRequest) {
            resendInvitation(row.id).then(() => {
              updateTable();
              setReuseModal(true);
              isReuseFirstRequest = false;
            });
          }
        },
        Icon: row.isInvitationSent
          ? RefreshIcon
          : StyledRefreshIcon,
      },
    ];

  const handleCloseReuseSuccessModalInfo = () => {
    setReuseModal(false);
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const onSubmit = (data:any) => {
    const _data = {
      ...data,
      franchise:[data.defaultFranchiseId]
    }
    createOrUpdate(_data);
  }
  return (
    <DashboardLayout>
      <ActionsPanelUsers
        onAdd={handleAdd}
        onSearch={setSearch}
        onSelectedAdditionalParamsChange={
          setSelectedAdditionalParams
        }
      />

      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={getUsers}
          onEdit={handleEdit}
          onDelete={isSuperAdmin && handleDelete}
          columnsOrder={columnsOrder}
          formatValue={formatValue}
          manuallyUpdate={tableManuallyUpdate}
          additionalRequestParams={selectedAdditionalParams}
          additionalActions={additionalActions}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <UsersForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={onSubmit}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.firstName}</strong>{' '}
          {selectedItem.lastName && (
            <strong>{selectedItem.lastName}</strong>
          )}{' '}
          / {selectedItem.email}
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError || reuseModal) && (
        <ModalInfo
          title={
            isCreate
              ? 'Success! A message to set a password has been sent to your email.'
              : 'Success'
          }
          timeToClose={5000}
          isError={modalError}
          onClose={
            reuseModal
              ? handleCloseReuseSuccessModalInfo
              : handleCloseModalInfo
          }
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

export default UsersPage;
