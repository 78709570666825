import * as React from 'react';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import useTablePage from '../hooks/useTablePage';
import { updateProject } from '../api/projects';
import { checkValidStateName } from '../utils/parseGoogleApiAddress';
import ActionsPanel from '../organisms/ActionsPanel';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import {
  InputAutocompletePlaces,
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  ProjectDetails,
  ButtonWithLoader,
  ModalDefaultDraggable,
} from '../molecules';
import { zipCode } from '../regex';
import ModalInfo from '../molecules/ModalInfo';
import {
  createUser,
  getUsers,
  getUsersByProject,
  getUsersCustom,
  UserType,
} from '../api/users';
import { ButtonMain } from '../atoms';
import UsersForm from './UsersForm';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';
import Table from '../organisms/Table';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import { getContactTypeList } from '../api/contactType';
import { none } from 'ramda';

const labels = {
  address: 'Address',
  street: 'Street',
  city: 'City',
  state: 'State',
  zipCode: 'Zip',
  jurisdiction: 'Jurisdiction',
  franchise: 'Franchise',
  manager: 'Project Manager',
  contacts: 'Contacts',
  name: 'Project Name',
};

const validationSchema = yup.object().shape({
  address: yup.string().required().label(labels.address),
  state: yup.string().required().label(labels.state),
  street: yup.string().required().label(labels.street),
  city: yup.string().required().label(labels.city),
  zipCode: yup
    .string()
    .matches(
      zipCode,
      'Invalid Code number. The code number must be in format 55555 or 55555-5555',
    )
    .required()
    .label(labels.zipCode),
  name: yup.string().required().label(labels.name),
  jurisdiction: yup
    .object()
    .required()
    .typeError('Select a Jurisdiction from the list')
    .label(labels.jurisdiction),
  manager: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Project Manager from the list')
    .nullable()
    .label(labels.manager),
  contacts: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a Contact Names from the list')
    .nullable()
    .label(labels.contacts),
});

const ProjectEditForm = ({
  editMode,
  initialValues,
  changeMode,
  jurisdiction,
  handleCloseEditProject,
  HandleAddContactModal,
  updateTable,
  manager,
  contacts,
  hideButtons,
}: Props) => {
  const {
    createOrUpdate,
    modalSuccess,
    modalError,
    isApiSuccess,
    successResponse,
    handleCloseModalInfo,
    modalErrorTitle,
    handleAdd,
    isModalOpen,
    modalTitle,
    handleCloseModal,
    values,
    innerModalErrors,
    setSearch,
    search,
    // tableManuallyUpdate,
  } = useTablePage({
    createEndpoint: createUser,
    modalCreateTitle: 'Add Contact',
    updateEndpoint: updateProject,
  });
  

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    formState,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  // Add user functionality starts from here
  const [addUserModal, setAddUserModal] = React.useState(false)
  const [clientType,setClientType]=React.useState()
 const [initialSelectedRows,setInitialSelectedRows] =React.useState({})
const userColumns = column => {
    if (
      [
        'id',
       'firstName',
       'lastName',
       'phoneNumber',
       'address',
       'city',
       'state',
       'clientType',
       'phoneNumber',
       'parentCompany',
       'street',
       'mobilePhoneNumber',
       'userRole',
       'zipCode',
       'isEpsuperadmin',
       'isInvitationSent',
       'licenses',
       'prNumber',
       'isActive',
       'isDeleted',
       'trade',
       'tradesNames',
       'franchise',
       'franchiseNames',
       'avatar',
       'createdAt'
      ].includes(column)
    ) {
      return null;
    }
  
    switch (column) {
      case 'companyName':
        return 'Company Name';
      case 'email':
        return 'Email';
      case 'name':
        return 'Name';
      // case 'contactType':
      //   return 'Contact Type';
      default:
        return tableColumnsFormatter(column);
    }
  };

 const userColumnOrder = [
    'name',
    // 'contactType',
    'email',
    'companyName',
  ];
  const [isAddContactBtnLoading, setIsAddContactBtnLoading] = React.useState(false)
  const [initialContact,setInitialContact] = React.useState(getValues("contacts"))
  const [addNewUserModalOpen, setAddNewUserModalOpen] = React.useState(false)
  // const newData = React.useMemo(() => data, [data])
  const clientTypeChange=(client)=>{
    setClientType(client)
  }
   const modifiedGetUsers = (params) =>
  Promise.all([
    getUsersCustom({
      // limit: 25,
      ...GET_ALL_QUERY_PARAMS,
      limit: 25,
      userRole: USER_TYPES_VALUES.CLIENT,
      search:search || null,
      ...params
    }),
    getContactTypeList({
      ...GET_ALL_QUERY_PARAMS,
    }),
  ]).then(
    ([
      userRes,
     contactTypesRes
    ]) => {
      // if(successResponse?.data){
      //   userRes.data.unshift(successResponse.data)
      // }
      const contactsId= userRes?.data?.map(item=>item.id)||[]
      const contactsData = getValues("contacts")?.map(contact => contact?.id)||[]
      const tempInitial = contactsId.reduce((acc,val,i)=>{
        if(contactsData.includes(val)){
          //  acc = {...acc,i:true}
          acc[i]=true
        }
        return acc
      },{})
      setInitialSelectedRows(tempInitial)
      
      return {
        ...userRes,
        data: {
          // ...userRes.data,
          results: userRes.data.map(
            item => {
              // setData((old) => [...old, {
              //   ...item,
              //   name: `${item?.firstName} ${item?.lastName}`,
              //   email: item?.email,
              //   companyName: item?.companyName,
              // }])
              return {
                ...item,
                // contactType: item.contactType
                // ? +item.contactType
                // : contactTypesRes?.data?.results[0]?.id,
                name: `${item?.firstName} ${item?.lastName}`,
                email: item?.email,
                companyName: item?.companyName || item?.parentCompany?.companyName || "",
              };
            },
          ),
        },
      };
    },
  );


  const [users, setUsers] = React.useState([]);
const [selectedContact, setSelectedContact] = React.useState(null)
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isContactFormSubmitting,
    setIsContactFormSubmitting,
  ] = React.useState(false);

  React.useEffect(() => {
    setUsers(contacts);
  }, [contacts]);

  React.useEffect(() => {
    HandleAddContactModal(addUserModal && !addNewUserModalOpen);
  }, [addNewUserModalOpen,addUserModal]);

  React.useEffect(()=>{
if(isApiSuccess){
  setAddNewUserModalOpen(false)
}

  },[isApiSuccess])

// creating the onSelect conatct function
const handleContactSelect = (newContacts,ids,data) => {
  setSelectedContact(newContacts)
  const contacts=getValues("contacts")
  let tempContact
  if(data){
    if(contacts.findIndex(contact=>contact.id===data.id)===-1){
      tempContact=[...contacts]
      tempContact.push(data)
      setValue("contacts",tempContact)
          }else{
            if(newContacts.findIndex(contact=>contact.id===data.id)===-1){
              tempContact=contacts.filter(contact=>contact.id!==data.id)
              setValue("contacts",tempContact)
            }
          }
  }
}

const handleAddContact = () => {
  setIsAddContactBtnLoading(true)
  // setValue("contacts",[...selectedContact, ...users])
  // const data=getValues("contacts")
  const _data=getValues("contacts")
  const uniqData = _data.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  const contactsData = uniqData.map(contact => contact?.id)

  // Promise.all([updateProject({id: initialValues?.id, contacts: contactsData}), getUsersByProject(initialValues?.id)]).then(([projectUsers, ]))

  updateProject({id: initialValues?.id, contacts: contactsData}).then(res => {
    // getUsersByProject(initialValues?.id).then((projectUsers) => {
      setIsAddContactBtnLoading(false)
      setAddUserModal(false)
      setValue("contacts",uniqData)
      setInitialContact(getValues("contacts"))
  }
  )
  
}


  // Calling the user data api on click of contacts dropdown
  // const handleContactsDropdownClick = () => {
  //   Promise.all([getUsersCustom({userRole: USER_TYPES_VALUES.CLIENT,})]).then(([allClients]) => {
  //     setUsers(allClients?.data)
  //   })
  // }
  React.useEffect(() => {
    if (modalSuccess) {
      Promise.all([
        getUsersCustom({
          ...GET_ALL_QUERY_PARAMS,
          userRole: USER_TYPES_VALUES.CLIENT,
          clientType: 1,
        }),
        getUsersCustom({
          ...GET_ALL_QUERY_PARAMS,
          userRole: USER_TYPES_VALUES.CLIENT,
          clientType: 2,
        }),
      ])
        .then(
          ([
            individualClientsResponse,
            companyClientsResponse,
          ]) =>
            setUsers([
              ...individualClientsResponse.data.results?.filter(
                el => el?.isActive,
              ),
              ...companyClientsResponse.data.results?.filter(
                el => el?.isActive,
              ),
            ]),
        )
        .catch(err => console.log(err))
        .finally(() => {
          setIsContactFormSubmitting(false);
        });
    }
  }, [modalSuccess]);

  React.useEffect(() => {
    if (
      formState.isSubmitted &&
      !Object.keys(errors).length
    ) {
      handleCloseEditProject();
      updateTable();
    }
  }, [formState.isSubmitSuccessful]);

  React.useEffect(() => {
    setValue('manager', initialValues?.manager?.id);
  }, []);

  const handlePlaceSelect = (address, isSelect) => {
    if (address || isSelect) {
      clearErrors('address');
    }
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        return setValue(key, value);
      });
    }
    return setValue('address', address);
  };

  const middleware = (submitFn: (data) => void) => data => {
    return submitFn({
      id: initialValues?.id,
      address: data.address,
      city: data.city,
      state: data.state,
      street: data.street,
      zipCode: data.zipCode,
      jurisdiction: data.jurisdiction.id,
      manager: data.manager.id,
      contacts: data.contacts
        // .filter(el => !el?.name)
        .map(el => el?.id),
    });
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  if (!editMode) {
    return (
      <ProjectDetails
        initial={initialValues}
        changeEditMode={changeMode}
        hideButtons={hideButtons}
      />
    );
  }
  return (
    <>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          InputProps={{
            readOnly: true,
          }}
          name="name"
          control={control}
          helperText={errors.name?.message}
          error={errors.name?.message}
          label={labels.name}
          isRequired={isYupFieldRequired(
            'name',
            validationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="address"
          as={InputAutocompletePlaces}
          defaultValue=""
          control={control}
          helperText={errors.address?.message}
          error={errors.address?.message}
          label={labels.address}
          handleFieldChange={handlePlaceSelect}
          isRequired={isYupFieldRequired(
            'address',
            validationSchema,
          )}
        />
      </InputBaseContainer>

      <InputBaseContainer hidden>
        <Controller
          name="hidden"
          as={InputTextWithLabel}
          control={control}
          defaultValue=""
          isRequired={false}
        />
      </InputBaseContainer>

      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="street"
          control={control}
          helperText={errors.street?.message}
          error={errors.street?.message}
          label={labels.street}
          isRequired={isYupFieldRequired(
            'street',
            validationSchema,
          )}
        />
        <Controller
          as={InputTextWithLabel}
          name="city"
          control={control}
          helperText={errors.city?.message}
          error={errors.city?.message}
          label={labels.city}
          isRequired={isYupFieldRequired(
            'city',
            validationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="state"
          defaultValue=""
          control={control}
          render={props => (
            <InputTextWithLabel
              name="state"
              error={errors.state?.message}
              helperText={errors.state?.message}
              label={labels.state}
              isRequired={isYupFieldRequired(
                'state',
                validationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              // eslint-disable-next-line react/prop-types
              value={checkValidStateName(props.value)}
            />
          )}
        />
        <Controller
          as={InputTextWithLabel}
          name="zipCode"
          control={control}
          helperText={errors.zipCode?.message}
          error={errors.zipCode?.message}
          label={labels.zipCode}
          isRequired={isYupFieldRequired(
            'zipCode',
            validationSchema,
          )}
        />
      </InputBaseContainer>
      <StyledInputContainer>
        <Controller
          name="contacts"
          defaultValue=""
          control={control}
          render={props => {
            return (
              <InputSelectWithLabel
                isMulti
                // onFocus={() => handleContactsDropdownClick()}
                showCompany
                selectAllActive
                placeholder=""
                label={labels.contacts}
                helperText={errors.contacts?.message}
                error={errors.contacts?.message}
                getOptionValue={(item: any) =>
                  item.value
                    ? item.value
                    : item.id || Math.random()
                }
                getOptionLabel={(item: any) =>
                  // eslint-disable-next-line no-nested-ternary
                  item.name
                    ? item.name
                    : !item.companyName
                    ? `${item.firstName} ${item.lastName}`
                    : item.companyName
                }
                isRequired={isYupFieldRequired(
                  'contacts',
                  validationSchema,
                )}
                options={users}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          }}
        />
        <ButtonContainer>
          <StyledButtonMain
            variant="outlined"
            // @ts-ignore
            // onClick={() => handleAdd(true)}
            onClick={() => {
              setAddUserModal(true)
              setInitialContact(getValues("contacts"))
            }}
          >
            Add Contact
          </StyledButtonMain>
        </ButtonContainer>
      </StyledInputContainer>
      <InputBaseContainer>
        <Controller
          name="jurisdiction"
          defaultValue=""
          control={control}
          render={props => {
            return (
              <InputSelectWithLabel
                placeholder=""
                // isLoading={isLoading}
                // isDisabled={isLoading}
                label={labels.jurisdiction}
                helperText={errors.jurisdiction?.message}
                error={errors.jurisdiction?.message}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'jurisdiction',
                  validationSchema,
                )}
                options={jurisdiction}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          }}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="manager"
          defaultValue=""
          control={control}
          render={props => {
            return (
              <InputSelectWithLabel
                label={labels.manager}
                helperText={errors.manager?.message}
                error={errors.manager?.message}
                // @ts-ignore
                options={manager}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) =>
                  `${item?.firstName} ${item?.lastName}`
                }
                isRequired={isYupFieldRequired(
                  'manager',
                  validationSchema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          }}
        />
      </InputBaseContainer>
      <ButtonContainer>
        <ButtonWithLoader
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit(middleware(createOrUpdate))}
          disabled={!!Object.keys(errors).length}
          loading={
            formState.isSubmitting &&
            !Object.keys(errors).length
          }
        >
          Save
        </ButtonWithLoader>
      </ButtonContainer>

      {addUserModal && (
        <StyledModalDraggable
          onClose={() => {
            if(addNewUserModalOpen){
              setAddNewUserModalOpen(false)
            }else{
              setAddUserModal(false)
              setValue("contacts",initialContact)
            }
            }}
          title="Add Users"
          disabledDraggable
          clientType={clientType}
          addContactModal={addUserModal && !addNewUserModalOpen}
        >
          <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
          {!addNewUserModalOpen && <>
          <ActionsPanel
          isBreadCrumbsVisible={false}
          onSearch={setSearch}
          isActionPanelMoreVisible={false}
          className='action-container'
        />
      </>}
          {!addNewUserModalOpen && <>
            <ButtonWithLoader style={{float:"right"}}
                  variant="contained"
                  color="primary"
                  
                  onClick={() => setAddNewUserModalOpen(true)}
                  
                  loading={
                    false
                  }
                >
                  New
                </ButtonWithLoader>
                <br />
          </>
          }
             </div>
          {addNewUserModalOpen && <UsersForm
            setIsContactFormSubmitting={
              setIsContactFormSubmitting
            }
            initialValues={values}
            searchForm
            modalError={modalErrorTitle.length > 0}
            initialErrors={innerModalErrors}
            onSubmit={createOrUpdate}
            clientTypeChange={clientTypeChange}
          />}
           {(isApiSuccess || modalError) && (
        <ModalInfo
          title="Success"
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
      
        {/* <InfiniteScroll
      dataLength={newData.length}
      next={fetchMoreData}
      height={800}
      hasMore={true}
      loader={<h4>Loading...</h4>}
    > */}
          {!addNewUserModalOpen && <><Table
            // initialUseSortBy
            customSearch
            initialSelectedRows = {initialSelectedRows}
            searchQuery={search}
            rows={modifiedGetUsers}
            columns={userColumns}
            isActivityTable
            addContactView={true}
            columnsOrder={userColumnOrder}
            // colorSchema
            // activityType="inspections"
            // additionalRequestParams={{
            //   eventType: "Inspection",
            // }}
            onSelect={(el,ids,data) => handleContactSelect(el,ids,data)}
            onConfigure={null}
          onEdit={null}
          onDelete={null}
          additionalActions={null}
            // manuallyUpdate={tableManuallyUpdate}
        />
        <br />
        <ButtonWithLoader
                variant="outlined"
                // color="primary"
                onClick={() => setAddUserModal(false)}
                loading={
                  false
                }
              >
                Cancel
              </ButtonWithLoader>{" "}
        <ButtonWithLoader
                variant="contained"
                color="primary"
                // type="submit"
                // style={{marginLeft: ''}}
                onClick={() => handleAddContact()}
                // className={classes.button}
                // disabled={
                //   !formState.isDirty ||
                //   !!Object.keys(errors).length
                // }
                loading={
                  false
                }
              >
                Ok
              </ButtonWithLoader> </>}
        {/* </InfiniteScroll> */}
        </StyledModalDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  editMode: boolean;
  initialValues: any;
  changeMode: (data: boolean) => void;
  jurisdiction: any;
  manager: Array<UserType>;
  contacts: any;
  handleCloseEditProject: () => void;
  updateTable: () => void;
  hideButtons?: boolean;
}

const StyledButtonMain = styled(ButtonMain)`
  &.MuiButton-root {
    line-height: 1.15;
  }
`;

// height: 100%;
// overflow: inherit;
const StyledModalDraggable = styled(ModalDefaultDraggable)`
  overflow-y: hidden; 
  height:${props=>props.addContactModal?"110vh": props.clientType?.label === "Individual"?"130%":props.clientType?.label === "Company"?"120%":"110%"};
  text-align: center;
  background-color: white;
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export default ProjectEditForm;
