import React, { useEffect } from 'react';
import { getActivitiesList, getActivitiesStatusList } from '../../../../api/activities';
import { GET_ALL_QUERY_PARAMS } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setComments,
  setInspectionsTypeData,
  setPermitUsers,
  setProject,
  setStatusData,
  setTitlesData,
  setTrades,
} from '../../../../redux/slices/inspectionsSlice';
import { RootState } from '../../../../redux/root';
import { getInspectionTypesCustom } from '../../../../api/inspectionTypes';
import { getProjectByID } from '../../../../api/projects';
import {
  InspectionsTypeList,
  createPermitInspectionsComment,
  getPermitInspectionById,
  getPermitInspectionsComments,
} from '../../../../api/inspections';
import { getPermitUsers, getUsersCustom } from '../../../../api/users';
import { USER_TYPES_VALUES } from '../../../../constants/forms';
import { setAllEmployees } from '../../../../redux/slices/generalSlice';

const useLoadInspectionEditor = ({ selectedTitle }) => {
  const dispatch = useDispatch();
  const allEmployees = useSelector((s: RootState) => s.general.allEmployees);
  const { titlesData, selectedInspection, project, isSystemMessages, newComment } = useSelector((s: RootState) => s.inspections);

  useEffect(() => {
    if (titlesData.length === 0) {
      loadActivityList();
    }
    if (allEmployees.length === 0) {
      loadAllEmployees();
    }
  }, []);

  useEffect(() => {
    if (selectedInspection.projectId) {
      loadProject(selectedInspection.projectId);
    }
    if (selectedInspection.id) {
      loadInspectionTrades(selectedInspection.id);
      loadComments(selectedInspection.id);
    }
    if (selectedInspection.permit) {
      loadPermitUsers(selectedInspection.permit);
    }
  }, [selectedInspection.id, isSystemMessages]);

  useEffect(() => {
    if (project.jurisdiction) {
      loadInspectionTypes(project.jurisdiction);
    }
  }, [project.id]);

  useEffect(() => {
    if (selectedTitle) {
      loadStatusData();
    }
  }, [selectedTitle]);

  const loadActivityList = async () => {
    const results = await getActivitiesList({
      ...GET_ALL_QUERY_PARAMS,
      activityType: 8,
    });
    const activitiesTitles = results.data.results?.filter(title => title?.isActiveStatus === 'True');
    dispatch(setTitlesData(activitiesTitles));
  };

  const loadInspectionTypes = async jurisdiction => {
    const results = await getInspectionTypesCustom(jurisdiction);
    //@ts-ignore
    dispatch(setInspectionsTypeData(results.data));
  };

  const loadProject = async projectId => {
    const results = await getProjectByID(projectId);
    dispatch(setProject(results.data));
  };

  const loadStatusData = async () => {
    if (selectedTitle) {
      const activitiesStatusList = await getActivitiesStatusList({
        ...GET_ALL_QUERY_PARAMS,
        activity: selectedTitle.id,
      });
      const activitiesStatus = activitiesStatusList.data.results?.filter(status => {
        if (status?.isActiveStatus === 'True') {
          switch (status.name) {
            case 'By Threshold':
            case 'Not Scheduled':
            case 'Ready For Scheduling':
              return false;
            default:
              return true;
          }
        }
        return false;
      });
      dispatch(setStatusData(activitiesStatus));
    }
  };

  const loadInspectionTrades = async inspectionId => {
    const result = await getPermitInspectionById({
      ...GET_ALL_QUERY_PARAMS,
      id: inspectionId,
    });
    dispatch(setTrades(result.data));
  };

  const loadPermitUsers = async permitId => {
    const results = await getPermitUsers({
      permit_id: permitId,
      userRole: USER_TYPES_VALUES.CLIENT,
    });
    dispatch(setPermitUsers(results.data));
  };

  const loadAllEmployees = async () => {
    const employeeList = await getUsersCustom({
      userRole: USER_TYPES_VALUES.EMPLOYEE,
    });

    //@ts-ignore
    const allEmployees = employeeList.data.filter(employee => employee?.isActive);
    dispatch(setAllEmployees(allEmployees));
  };

  const loadComments = async id => {
    const comments = await getPermitInspectionsComments({ ...GET_ALL_QUERY_PARAMS, system_message: isSystemMessages }, id);
    dispatch(setComments(comments.data.results));
  };

  return null;
};

export default useLoadInspectionEditor;
