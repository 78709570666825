import * as React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  Collapse,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const CollapsableList = ({
  children,
  label,
  padding,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <ListWrapper padding={padding}>
      <ListItem button onClick={handleClick}>
        <span
          style={{
            fontWeight: 700,
            textTransform: 'capitalize',
          }}
        >
          {label
            .replace(/([A-Z][a-z])/g, ' $1')
            .replace(/(\d)/g, ' $1')}
        </span>
        {open ? (
          <Icon>
            <ExpandLess />
          </Icon>
        ) : (
          <Icon>
            <ExpandMore />
          </Icon>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </ListWrapper>
  );
};

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  label: string;
  padding: string;
}

interface Wrapper {
  padding: string;
}

const ListWrapper = styled.div<Wrapper>`
  padding-left: ${({ padding }) => padding};
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
`;

export default CollapsableList;
