import { api } from './api';
import { FranchiseType } from './franchise';
import { PaginatedList, PaginatedRequest } from '../types';

export const getJurisdictions = (
  params: PaginatedRequest,
) =>
  api.request<JurisdictionList>({
    method: 'GET',
    url: '/jurisdictions/',
    params,
  });

export const getJurisdictionByID = (id: number) =>
  api.request<JurisdictionType>({
    method: 'GET',
    url: `/jurisdictions/${id}/`,
  });

export const createJurisdiction = (
  data: JurisdictionType,
) =>
  api.request<JurisdictionType>({
    method: 'POST',
    url: '/jurisdictions/',
    data,
  });

export const updateJurisdiction = (
  data: JurisdictionType,
) =>
  api.request<JurisdictionType>({
    method: 'PATCH',
    url: `/jurisdictions/${data.id}/`,
    data,
  });

export const deleteJurisdiction = (id: number) =>
  api.request<JurisdictionType>({
    method: 'DELETE',
    url: `/jurisdictions/${id}/`,
  });

type JurisdictionList = PaginatedList<JurisdictionType>;

export interface JurisdictionType {
  id: number;
  name: string;
  phoneNumber: string;
  building: string;
  email: string;
  isFranchiseAll: boolean;
  address: string;
  isDeleted: boolean;
  franchise: FranchiseType[] | number[];
}

export interface JurisdictionTableType {
  id: number;
  name: string;
  phoneNumber: string;
  building: string;
  email: string;
  address: string;
  isDeleted: boolean;
  isFranchiseAll: boolean;
  franchises: string;
  city: string;
  street: string;
  state: string;
  zipCode: string;
  franchise?: FranchiseType[] | number[];
  isDefault?: boolean;
}
