import React from 'react';
import { Pagination, TableWrapper, commonFilterMeta } from '../../Common/Table/types';
import Table from '../../Common/Table/Table';
import { headers } from '../Inspections/InspectionsTable';
import PaginationMobile from '../../../../../pages/Responsive/PaginationMobile';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import useOnCommonTableActivity from '../../../hooks/useOnCommonTableActivity';
import { PermitTicketItem, TicketItem } from '../../../types';
import { setSelectedPermitTicket } from '../../../../../redux/slices/ticketsSlice';

const TicketsTable = () => {
  useGetNewProjectData();
  const dispatch = useDispatch();
  const permitTicketsList = useSelector((s: RootState) => s.tickets.permitTicketsList);
  const { visibleItems } = useOnCommonTableActivity(permitTicketsList);

  const onPermitTicketSelected = (item: PermitTicketItem) => {
    dispatch(setSelectedPermitTicket(item));
  }
  return (
    <TableWrapper>
      <Table
        collection={visibleItems}
        headers={headers}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 175px 138px 90px"
        setSelectedItemInSlice={onPermitTicketSelected}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default TicketsTable;
