import * as React from 'react';
import styled from 'styled-components';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Table from '../../organisms/Table';
// import ActionsPanel from '../../organisms/ActionsPanel';
import useTablePage from '../../hooks/useTablePage';
import { convertTableBoolean } from '../../utils/convertTableValues';
import {
  LoaderWithContainer,
  ModalDefaultDraggable,
} from '../../molecules';
import {
  getTickets,
  createTicketComment,
  updateTicket,
  TicketType,
  createTicket
} from '../../api/tickets';
import ModalInfo from '../../molecules/ModalInfo';
import TicketsForm from '../../forms/TicketsForm';
import { getVocabulariesDataList } from '../../api/vocabulary';
import {
  DATETIME_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../../constants';
import { getCurrentUser } from '../../api/userSettings';
import { getPermitByID } from '../../api/permits';
import { formatDateTime } from '../../utils/formatDate';

const TicketsPage = ({ isAddTicket, setAddTicket, permit, projectId }: Props) => {
  const {
    search,
    // setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    modalTitle,
    values,
    errors,
    modalErrorTitle,
    tableManuallyUpdate,
    createOrUpdate,
    handleEdit,
    handleAdd,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<TicketType>({
    modalCreateTitle: 'Add Ticket',
    modalUpdateTitle: 'Update Ticket',
    createEndpoint: createTicket,
    updateEndpoint: updateTicket,
  });

  const [statusData, setStatusData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    currentUserName,
    setCurrentUserName,
  ] = React.useState('');
  const [
    isPermitManager,
    setPermitManager,
  ] = React.useState(false);
  
  React.useEffect(() => {
    setAddTicket(false)
  }, [modalSuccess === true])

  React.useEffect(() => {
    if (isAddTicket) {
      handleAdd();
    }
  }, [isAddTicket]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getPermitByID(permit),
      getCurrentUser(),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 9,
        ordering: 'title',
      }),
    ])
      .then(
        ([
          currentPermitResponse,
          currentUserResponse,
          statusesResponse,
        ]) => {
          setCurrentUserName(
            currentUserResponse.data?.companyName
              ? currentUserResponse.data?.companyName
              : `${currentUserResponse.data?.firstName} ${currentUserResponse.data?.lastName}`,
          );
          setStatusData(statusesResponse.data.results);
          setPermitManager(
            currentPermitResponse.data.manager ===
              currentUserResponse.data.id,
          );
        },
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  const modifiedGetTicketsRequest = params =>
    getTickets({ ...params, contact: params?.contactName, status: params?.statusTitle, permit }).then(response => ({
      ...response,
      data: {
        ...response.data,
        results: response.data.results.map(item => {          
          if (statusData) {
            return {
              ...item,
              statusTitle: statusData.find(
                title => title.id === item.status,
              )?.title,
              lastUpdated: formatDateTime(
                item?.lastUpdated,
                DATETIME_FORMAT,
              ),
              contactName: item?.contact ? `${item?.contactFirstName} ${item?.contactLastName}` : "",
            };
          }

          return {
            ...item,
            lastUpdated: formatDateTime(
              item?.lastUpdated,
              DATETIME_FORMAT,
            ),
          };
        }),
      },
    }));

  const onTicketStatusChange = (selected, row) => {
    
    if (selected) {
      setIsLoading(true);
      updateTicket({
        status: selected?.id,
        id: row,
        statusTitle: selected?.title,
      })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const columnsOrder = [
    'id',
    'description',
    'statusTitle',
    'lastUpdated',
  ];

  const columns = column => {
    if (
      [
        'isDeleted',
        'subject',
        'permit',
        'status',
        'creatorUsername',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'contactFirstName',
        'contactLastName',
        'contact',
        'projectName',
        'permitName',
        'statusName',
        'projectId',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'id':
        return 'Ticket Number';
      case 'statusTitle':
        return 'Status'
      case 'contactName':
        return 'Contact'
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {      
      handleCloseModal();
      setAddTicket(false)
    }
  };

  if (isLoading) {
    return <LoaderWithContainer />;
  }

  return (
    <>
      {/* <ActionsPanel onSearch={setSearch} onAdd={null} /> */}
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columnsOrder={columnsOrder}
          columns={columns}
          rows={modifiedGetTicketsRequest}
          onEdit={handleEdit}
          onDelete={null}
          onConfigure={null}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
          // additionalActions={null}
          ticketStatuses={statusData}
          onTicketStatusChange={onTicketStatusChange}
          currentUserName={currentUserName}
          isPermitManager={isPermitManager}
          initialUseSortBy
        />
      </Container>

      {isModalOpen  && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <TicketsForm
            permit={permit}
            projectId={projectId}
            initialValues={values}
            initialErrors={errors}
            isModalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  permit: number;
  projectId: number;
  setAddTicket: (data: boolean) => void;
  isAddTicket: boolean;
}

const Container = styled.div`
  margin: 24px 0 0 0;
  width: 100%;
`;

export default TicketsPage;
