/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { components, Props } from 'react-select';

import SelectInput from './SelectInput';
import Checkbox from './Checkbox';
import ButtonMain from './ButtonMain';

const SELECT_ALL_OPTION = {
  label: 'Select all',
  value: `--- ${Math.random()} ---`,
};

const SelectContainer = props => {
  const {
    value,
    placeholder,
    menuIsOpen,
    onMenuOpen,
    onMenuClose,
  } = props.selectProps;

  const buttonContainerText =
    props.options.length - 1 === value?.length
      ? `Selected all (${value.length}) items`
      : `Selected ${value?.length} items`;

  return (
    <SelectContainerWrapper>
      <SelectContainerContent
        onClick={menuIsOpen ? onMenuClose : onMenuOpen}
      >
        {value && value.length
          ? buttonContainerText
          : placeholder}
      </SelectContainerContent>
      <components.SelectContainer {...props} />
    </SelectContainerWrapper>
  );
};

let menuSelectedOptions = [];
let menuExitByCanceling = false;

const Menu = props => {
  const closeMenu = (saveValues: boolean = true) => {
    menuExitByCanceling = false;

    if (!saveValues) {
      if (menuSelectedOptions?.length) {
        props.setValue(menuSelectedOptions);
      } else {
        props.clearValue();
      }

      menuExitByCanceling = true;
    }

    props.selectProps.onMenuClose();

    menuSelectedOptions = props.selectProps.value;
  };

  return (
    <MenuContainer>
      <components.Menu {...props}>
        {props.children}
      </components.Menu>
      <MenuMetaContainer>
        <ButtonMain
          variant="outlined"
          onClick={() => closeMenu(false)}
        >
          Cancel
        </ButtonMain>
        <ButtonMain onClick={() => closeMenu()}>
          Apply
        </ButtonMain>
      </MenuMetaContainer>
    </MenuContainer>
  );
};

const Option = props => {
  const isSelectAllOption =
    props.value === SELECT_ALL_OPTION.value;

  if (isSelectAllOption) {
    const isAllSelected =
      props.options.length - 1 ===
      props.selectProps.value?.length;

    return (
      <OptionContainer isSelectAllOption>
        <Checkbox
          checked={isAllSelected}
          indeterminate={
            !isAllSelected &&
            !!props.selectProps.value?.length
          }
        />
        <components.Option {...props} />
      </OptionContainer>
    );
  }

  return (
    <OptionContainer>
      <Checkbox checked={props.isSelected} />
      <components.Option {...props} />
    </OptionContainer>
  );
};

const SelectInputWithSelectAll = ({
  data,
  valueKey = 'value',
  labelKey = 'label',
  placeholder,
  onMenuClose = () => {},
}: SelectProps) => {
  const [
    selectedOptions,
    setSelectedOptions,
  ] = React.useState(null);

  return (
    <SelectInput
      isMulti
      value={selectedOptions}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      getOptionValue={(item: any) =>
        item[valueKey] || item.value || Math.random()
      }
      getOptionLabel={(item: any) =>
        item[labelKey] || item.label
      }
      onMenuClose={() =>
        onMenuClose(selectedOptions, menuExitByCanceling)
      }
      components={{ Menu, Option, SelectContainer }}
      options={[SELECT_ALL_OPTION, ...data]}
      onChange={(selected: any[]) => {
        if (
          selected !== null &&
          selected.length > 0 &&
          selected.find(
            item => item.value === SELECT_ALL_OPTION.value,
          )
        ) {
          return setSelectedOptions(
            selectedOptions?.length === data.length
              ? null
              : data,
          );
        }

        return setSelectedOptions(selected);
      }}
    />
  );
};

interface SelectProps {
  data: Props['options'] | any[];
  valueKey?: string;
  labelKey?: string;
  placeholder?: string;
  onMenuClose?: (
    selected: any[] | null,
    isCanceled: boolean,
  ) => void;
}

const SelectContainerWrapper = styled.div`
  .react-select__control {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
`;

const SelectContainerContent = styled.div`
  border: 1px solid var(--border);
  background-color: var(--defaultTableCell);
  border-radius: 5px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: 10px;
`;

const MenuContainer = styled.div`
  width: 100%;
  top: 100%;
  position: absolute;
  margin: 8px 0;
  z-index: 1;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 4px 11px hsla(0, 0%, 0%, 0.1);

  .react-select__menu {
    position: static;
    box-shadow: none;
    background-color: transparent;
  }
`;

const MenuMetaContainer = styled.div`
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid var(--disabledButton);

  > button {
    margin: 0 32px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiButton-root,
  .MuiInputBase-root {
    height: 30px;
  }
`;

const OptionContainer = styled.div<{
  isSelectAllOption?: boolean;
}>`
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  font-weight: ${({ isSelectAllOption }) =>
    isSelectAllOption ? 'bold' : 'normal'};

  &:hover {
    background-color: #deebff;
  }

  > .MuiCheckbox-root {
    position: absolute;
    user-select: none;
    pointer-events: none;
    z-index: 1;
  }

  > .react-select__option {
    position: relative;
    padding-left: 40px;
    z-index: 2;

    &--is-selected {
      color: var(--mainText);
      background-color: transparent !important;
    }

    &--is-focused {
      background-color: transparent !important;
    }
  }
`;

export default SelectInputWithSelectAll;
