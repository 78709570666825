import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import styled from 'styled-components';
import isRoutePathExact from '../utils/isRoutePathExact';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root';

const SidebarSubMenuItem = ({
  to,
  title,
  basePath,
}: Readonly<Props>): React.ReactElement => {
  const { pathname } = useLocation();
  const { push }: History = useHistory();
  const activityCounts = useSelector((s:RootState) => s.employeeActivities);

  const handleClick = () => {
    if (basePath) {
      return push(`${basePath}/${to}`);
    }
    return push(to);
  };
  const isActive = isRoutePathExact(
        pathname,
        basePath ? `${basePath}/${to}` : to,
      )
  return (
    <Wrapper
      onClick={handleClick}
      isActive={isActive}
    >
      <Title>
        <p>{title}</p>
        {isActive && <Count>{activityCounts[title]}</Count>}
      </Title>
      
    </Wrapper>
  );
};

interface WrapperProps {
  isActive: boolean;
}

const Title = styled.div`
 display: flex;
 gap: 5px;
`;

const Count = styled.div`
  font-size: 14px;
  font-weight: bold;
  align-content: center;
`;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  max-width: 143px;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
  border-radius: 5px;
  margin: 0 5px;
  text-align: center;
  padding: 5px;
  p {
    margin: 0;
    font-size: 1em;
    line-height: 21px;
  }

  &:hover {
    color: var(--defaultBackground);
    background: var(--primary);
  }

  color: ${({ isActive }) =>
    isActive
      ? 'var(--defaultBackground)'
      : 'var(--linkText)'};
  background: ${({ isActive }) =>
    isActive ? 'var(--primary)' : 'transparent'}; ;
`;

interface Props {
  to: string;
  title: string;
  basePath?: string;
}

export default SidebarSubMenuItem;
