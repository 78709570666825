import React, { useState } from 'react';
import { Pagination, TableWrapper, commonFilterMeta } from '../../Common/Table/types';
import Table from '../../Common/Table/Table';
import PaginationMobile from '../../../../../pages/Responsive/PaginationMobile';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import useFilterActions from '../../../../../pages/Responsive/useFilterActions';
import { headers } from '../Documents/DocumentsTable';
import useOnCommonTableActivity from '../../../hooks/useOnCommonTableActivity';
import { getCustomActivities } from '../../../../../api/activities';
import { InspectionItemType } from '../../../types';
import { setPermitConditions, setSelectedCondition } from '../../../../../redux/slices/conditionsSlice';

const ConditionsTable = () => {
  useGetNewProjectData();
  const dispatch = useDispatch();
  //   const { pageSize, pageIndex } = useSelector((state: RootState) => state.pagination);
  const conditionDocumentsList = useSelector((s: RootState) => s.conditions.AllPermitsWithConditions);
  const { visibleItems } = useOnCommonTableActivity(conditionDocumentsList);
  const onConditionSelected = item => {
    onConditionSelectedWithDispatch(item,dispatch);
  }

  return (
    <TableWrapper>
      <Table
        collection={visibleItems}
        headers={headers}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 138px 138px 90px"
        setSelectedItemInSlice={onConditionSelected}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default ConditionsTable;

export const onConditionSelectedWithDispatch = async (item,dispatch) => {
  const params = {
    is_deleted: false,
    limit: 1000000,
    offset: 0,
    permit_id: item.permitId,
    event_type: 'Activity',
    activity_type_name: 'Conditions',
  };
  const payload = await getCustomActivities(params);
  const results: InspectionItemType[] = payload.data.results;
  dispatch(setPermitConditions(results));
  dispatch(setSelectedCondition(item));
};