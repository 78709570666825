import * as React from 'react';
import styled from 'styled-components';

import {
 InspectorTimeTrackingReports,
  ReportTableType,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const InspectorTimeTrackingReport = React.forwardRef(
  (
    {
      fromDate,
      toDate,
      inspector,
      setIsValidForExport,
    }: ReportTableType,
    ref,
  ) => {
    const [
      isNullInspections,
      setIsNullInspections,
    ] = React.useState(false);
    const [lastComment, setLastComment] = React.useState(
      null,
    );

    const data = {
      fromDate,
      toDate,
      inspector: inspector.id,
    };
const [totalPermits, setTotalPermits] = React.useState([])
    const getModifiedDailyInspectionReport = () =>
      Promise.all([InspectorTimeTrackingReports(data)])
        .then(([reports]) => {
         
          
          if (reports.data?.length === 0) {
            setIsNullInspections(true);
            setIsValidForExport(false);
          } else {
            setIsNullInspections(false);
            setIsValidForExport(true);
                        
            const commentData = reports.data.map(el => {
              return {
                id: el.id,
                lastComment: el.lastComment,
              };
            });

            setLastComment({
              commentData,
              isDailyInspection: true,
            });
            

              setTotalPermits((prevState) => [...prevState, {
                title:"Total Permits",
                value: reports.data?.length
              }])
          }
          
         
          return {
            ...reports,
            data: {
              ...reports.data,
              results: reports.data.map(el => {
                return {
                  projectNamePermitName: `${el.projectName} | ${el.permitName}`,
                  permitNumber: el.permitNumber,
                  projectAddress: el.projectAddress,
                  inspectionNumber: el.id,
                  inspectionType: el.inspectionTypeName,
                  status: el.status,
                  inspectionDate:el.inspectionDate
                };
              }),
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});

    const columns = column => {
      switch (column) {
        case 'contactDetails':
          return 'Contact Details';
        case 'contactName':
          return 'Contact';
        case 'inspectionNumber':
          return 'Inspection Number';
        case 'inspectionDate':
          return 'Inspection Date';
        case 'inspectionTypeName':
          return 'Inspection Type';
        case 'jurisdiction':
          return 'Jurisdiction';
        case 'permitNumber':
          return 'Permit Number';
        case 'projectAddress':
          return 'Project Address';
        case 'projectManager':
          return 'Project Manager';
        case 'period':
          return 'AM/PM';
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';
        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
      'projectNamePermitName',
      'permitNumber',
      'projectAddress',
      'inspectionNumber',
      'inspectionType',
      'status',
      'inspectionDate'
    ];

    if (isNullInspections) {
      return (
        <InfoText>
          No project was found with selected date or
          Inspector.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Inspector Time Tracking Report</Title>

        <List>
          <li>
            <Bold>Inspector Name: </Bold>
            {`${inspector.firstName || ""} ${inspector.lastName || ""}`}
          </li>
          <li>
            <Bold>Date: </Bold>From: {fromDate} To: {toDate}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedDailyInspectionReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={lastComment}
          additionalBottomRow={totalPermits}
        />
      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default InspectorTimeTrackingReport;