import { Paper } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setDisableSave } from '../../../../redux/slices/projectSlice';
import { RootState } from '../../../../redux/root';
import { setFilesToUpload } from '../../../../redux/slices/documentsSlice';

const maxFilesSize = 5 * 1048576; //5 MegaBytes

type Props = {
  setValue: Function;
  register: Function;
};
const UploadFiles: FC<Props> = ({ setValue, register }) => {
  const files = useSelector((s: RootState) => s.documents.filesToUpload);
  const [localFiles, setLocalFiles] = useState<any[]>([]);
  const disableSave = useSelector((s: RootState) => s.projectsSlice.disableSave);
  const name = 'documentFile';
  const ref = useRef<HTMLDivElement>();
  const dispatch = useDispatch();

  useEffect(() => {
    if(files.length === 0){
      setLocalFiles([]);
    }
  },[files])
  const onDrop = acceptedFiles => {
    const uniquePaths = new Set();
    files.forEach(f => {
      uniquePaths.add(f.path);
    });

    const _files = [
      ...files,
      ...acceptedFiles.reduce((newUniqueFiles, currentFile) => {
        if (!uniquePaths.has(currentFile.path)) {
          newUniqueFiles.push(currentFile);
        }
        return newUniqueFiles;
      }, []),
    ];
    dispatch(setFilesToUpload(_files.map(stripFiles)));
    setLocalFiles(_files);
  };

  const stripFiles = f => ({
    name: f.name,
    path: f.path,
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
    },
  });
  const { ...rootProps } = getRootProps();

  useEffect(() => {
    if (localFiles.length > 0) {
      ref.current.scrollIntoView();
      register(name);
      setValue(name, localFiles);
    }
    const totalSize = localFiles.reduce((totalBytes, item) => {
      totalBytes += item.size;
      return totalBytes;
    }, 0);

    if (totalSize === 0) {
      return;
    }

    dispatch(setDisableSave(totalSize > maxFilesSize));
  }, [localFiles]);

  const removeFileFromSelectedFiles = (e, path) => {
    stopPropagation(e);
    const _files = files.reduce((newFiles, currentFile) => {
      if (currentFile.path !== path) {
        newFiles.push(currentFile);
      }
      return newFiles;
    }, []);
    dispatch(setFilesToUpload(_files.map(stripFiles)));
    setLocalFiles(_files);
  };

  const stopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Wrapper>
      <Paper {...rootProps}>
        <input {...getInputProps()} />
        <ButtonsWrapper>
          <LoadButton>Browse</LoadButton>

          <p onClick={stopPropagation}>Drag 'n' drop project files files here or click the button to select files.</p>

          {disableSave && <SizeError>Total files size exceed 5MB!</SizeError>}
        </ButtonsWrapper>
        <FileContainer ref={ref} onClick={stopPropagation}>
          {localFiles.map((file, index) => {
            return (
              <FileItem key={index}>
                {file.path}
                <IconContainer>
                  <DeleteForeverIcon
                    onClick={e => {
                      removeFileFromSelectedFiles(e, file.path);
                    }}
                  />
                </IconContainer>
              </FileItem>
            );
          })}
        </FileContainer>
      </Paper>
    </Wrapper>
  );
};

export default UploadFiles;

const Wrapper = styled.div`
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 30px;
  padding: 10px;
`;

const LoadButton = styled.div`
  background-color: #ccc;
  border: 1px solid grey;
  border-radius: 4px;
  width: fit-content;
  height: 39px;
  padding: 9px;
  margin-right: 12px;
  position: relative;
  top: 8px;
  box-shadow: 2px 2px 5px gray;
  cursor: pointer;
  &:active {
    box-shadow: 0px 0px 5px gray;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 840px) {
    flex-direction: column;
  }
`;

const FileItem = styled.div`
  border: 1px solid grey;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  padding: 7px;
  background-color: #eeeeee;
  border-radius: 4px;
  margin-bottom: 5px;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const FileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`;

const SizeError = styled.div`
  color: red;
  font-size: 11px;
`;
