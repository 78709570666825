import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { FormServerError } from '../types';
import {
  ButtonWithLoader,
  InputBaseContainer,
  InputFileContainer,
  InputTextWithLabel,
  InputSelectWithLabel,
  CheckboxWithLabel,
} from '../molecules';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import CommentsChat from '../organisms/CommentsChat';
import PermitDocumentFormsTable from '../organisms/PermitDocumentFormsTable';
import {
  getPermitDocumentComments,
  createPermitDocumentComment,
  createPermitDocument,
  updatePermitDocument,
  deletePermitDocument,
  updatePermitDocumentComment,
} from '../api/projectDocuments';
import {
  COMMENTS_DATETIME_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import { formatDateTime } from '../utils/formatDate';
import {convertTableBoolean, convertTableFranchiseBoolean} from "../utils/convertTableValues";
import Table from "../organisms/Table";
import tableColumnsFormatter from "../utils/tableColumnsFormatter";
import useTablePage from "../hooks/useTablePage";
import parse from "html-react-parser";
import TableWithDynamicCell from "../organisms/TableWithDynamicCell";
import {deleteFranchiseJurisdictions, getFranchiseJurisdictionsList, updateFranchiseRelations} from "../api/franchise";
import useTableWithDynamicCell from "../hooks/useTableWithDynamicCell";

const DocumentCommentsPage = ({
  initialValues,
  postRequest,
  isViewMode,
}: Props) => {

  const {
    modalError,
    tableManuallyUpdate,
    updateTable,
    handleListChangeForComment,
    values,
   } = useTableWithDynamicCell<any>({
    updateEndpoint: updatePermitDocumentComment,
  });

  const [isSubmitting, setIsSubmitting] = React.useState(
    false,
  );

  const columns = column => {
    if (
      [
        'id',
        'creator',
        'permitDocumentId',
        'visibleFilename',
        'file',
        'text',
        'isResolved',
        'isSystemMessage',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'isResolved':
        return 'Resolved';
      case 'username':
        return 'User';
      case 'createdAt':
        return 'Created';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'username',
    'createdAt',

  ];

  const successRequest = () => {
    updateTable();
   };

  const updateCommentDataRequest = () => {
    if (values.length) {
      values.forEach(item => {
        Promise.all([
          postRequest({
            isResolved: item?.isResolved,
            id: initialValues?.id || initialValues?.pk,
            rowid: item?.id,
          })
              .then(() => {
                successRequest();
              })
          ,
        ]);
      })
      }
    };

  const modifiedGetCommentsRequest = params => {
    return Promise.all([
      getPermitDocumentComments(
        initialValues?.pk,
        params,
      ),
    ]).then(([response]) => {
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(comment => ({
            ...comment,
            notes: parse(comment?.text || comment?.body),
            createdAt: formatDateTime(
              comment?.createdAt,
              COMMENTS_DATETIME_FORMAT,
            ),
            creator: comment.username,
          })),
        },
      };
    });
  };

  return (
    <Container>
      <TableWithDynamicCell
        customSearch
        rows={modifiedGetCommentsRequest}
        columns={columns}
        columnsOrder={columnsOrder}
        manuallyUpdate={tableManuallyUpdate}
        handleListChanged={handleListChangeForComment}
        onSelectRowHeader="Resolved"
        endPositionOfSelectionColumn
        formatValue={convertTableBoolean}
        bindingCheckboxesTo={{
            field: 'isResolved',
            value: 'true',
          }}
        additionalRequestParams={{
                isSystemMessage : false
              }}
        isCommentCheckbox
        initialSortBy={[{ id: 'isResolved', desc: false }]}
      />
      {!isViewMode && (
      <ButtonWrapper>
      <ButtonWithLoader
          disabled={isSubmitting && !modalError}
          loading={isSubmitting && !modalError}
          type="submit"
          onClick={updateCommentDataRequest}

        >
          Save
        </ButtonWithLoader>
       </ButtonWrapper>
      )}
    </Container>
  );
};

interface Props {
  initialValues?: any;
  postRequest: (data: any) => Promise<any>;
  isViewMode?: boolean;
}

const Container = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 16px;
  display: flex;
  width: 280px;
  justify-content: center;
`;

export default DocumentCommentsPage;
