import React, { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../../../../redux/root';
import { loadDocumentsData } from '../DocumentsTable';
import ProgressBar from '../ProgressBar';
import DetailsHeaderComponent from '../../../Common/DetailsHeaderComponent';
import FilesSection from './FilesSection';
import AvailableActions from './AvailableActions';
import { Logo_Sky } from '../../../../../../constants/menu';
import ReviewComments from './ReviewComments/ReviewComments';
import { setOpenedTabIndex, setPermitDocuments, setSelectedDocument } from '../../../../../../redux/slices/documentsSlice';
import { setShowDrawerContent } from '../../../../../../redux/slices/generalSlice';
import { DocumentFileItem } from '../../../../types';
import ButtonMain from '../../../Common/Controls/ButtonMain';
import { sendClientDocument } from '../../../../../../api/document';

type Props = {
  onPermitItemSelected: Function;
};
const DocumentsItems: FC<Props> = ({ onPermitItemSelected }) => {
  const permitDocuments = useSelector((s: RootState) => s.documents.PermitDocuments);
  const OpenedTabIndex = useSelector((s: RootState) => s.documents.OpenedTabIndex);
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  const ref1 = useRef<HTMLDivElement>();
  const ref2 = useRef<HTMLDivElement>();
  const ref3 = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState<boolean>(false);
  const selectedDocument = useSelector((s: RootState) => s.documents.SelectedDocument);
  const selectedDocumentPermit = useSelector((s: RootState) => s.documents.SelectedDocumentPermit);
  const showDrawerContent = useSelector((s: RootState) => s.general.showDrawerContent);
  useEffect(() => {
    loadingDocData();
  }, [permitDocuments, selectedDocument]);
  useEffect(() => {
    ref1.current.scrollTop = 0;
    ref2.current.scrollTop = 0;
    ref3.current.scrollTop = 0;
  }, [OpenedTabIndex]);
  useEffect(() => {
    if (showDrawerContent) {
      dispatch(setSelectedDocument({} as DocumentFileItem));
      dispatch(setSelectedDocument({} as DocumentFileItem));
    }
  }, [showDrawerContent]);

  const sendDocuments = async (event: MouseEvent) => {
    const idsToSend = permitDocuments.reduce((ids, item) => {
      if (item.statusTitle == 'Waiting on client' && item.clientFiles.length > 0) {
        ids.push(item.pk);       
      }
      return ids;
    }, []);
    if (idsToSend.length === 0) {
      setShowError(true);
      return;
    }

    const formData = new FormData();
    formData.append('permit_document_ids', idsToSend.join(','));
    formData.append('is_deleted', 'false');
    const result = await sendClientDocument(formData);
    if(result.status === 200)
    {
      const _permitDocuments = permitDocuments.reduce((docs,doc)=>{
        if(idsToSend.indexOf(doc.pk) === -1){
          docs.push(doc);
        }
        return docs;
      },[]);
      dispatch(setPermitDocuments(_permitDocuments));
      dispatch(setSelectedDocument({} as DocumentFileItem));
     // loadingDocData(true);
    }
  };

  const loadingDocData = (forceLoad?: boolean) => {
    if (forceLoad || permitDocuments.length === 0 && Object.keys(selectedDocument).length > 0) {
      loadDocumentsData(selectedDocument, dispatch, vocabulary);
    }
  }
  
  return (
    <Wrapper>
      <DetailsHeaderComponent names={selectedDocumentPermit.namePermitName} address={selectedDocumentPermit.address} />
      <ProgressBar />
      <ThreeColumns>
        <Section ref={ref1} boxIndex={1} currIndex={OpenedTabIndex} onClick={() => dispatch(setOpenedTabIndex(1))}>
          <div>
            <SubHeader>Documents</SubHeader>
            <ButtonWrapper onMouseLeave={()=> setShowError(false)}>
              <ButtonMain label="Send" onClick={sendDocuments} />
            </ButtonWrapper>

            {showError && <Error>There are no files with "Waiting on client" status that have attachments!</Error>}
          </div>

          <SectionBody>
            <FilesSection />
          </SectionBody>
        </Section>
        <Section ref={ref2} boxIndex={2} currIndex={OpenedTabIndex} onClick={() => dispatch(setOpenedTabIndex(2))}>
          <SubHeader>Available Actions</SubHeader>
          <SectionBody>
            <AvailableActions />
          </SectionBody>
        </Section>
        <Section ref={ref3} boxIndex={3} currIndex={OpenedTabIndex} onClick={() => dispatch(setOpenedTabIndex(3))}>
          <SubHeader>Review comments</SubHeader>
          <SectionBody>
            <ReviewComments />
          </SectionBody>
        </Section>
      </ThreeColumns>
    </Wrapper>
  );
};

export default DocumentsItems;

const SubHeader = styled.div`
  color: ${Logo_Sky};
  font-size: 18px;
  margin-left: 5px;
  font-weight: bold;
  margin-bottom: 7px;
  border-bottom: 2px solid;
  width: fit-content;
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
`;

const Section = styled.div<{ boxIndex: number; currIndex: number }>`
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  height: 52vh;
  background-color: rgba(200, 200, 200, 0.2);
  border-right: 1px solid grey;
  overflow: auto;
  transition: height 0.4s ease-in-out;
  @media only screen and (max-width: 540px) {
    height: ${({ boxIndex, currIndex }) => {
      if (boxIndex === currIndex) {
        return '306px';
      }
      return '43px';
    }};
    overflow: ${({ boxIndex, currIndex }) => {
      if (boxIndex === currIndex) {
        return 'auto';
      }
      return 'hidden';
    }};
    border: 1px solid grey; //306px  overflow:auto
  }
`;

const SectionBody = styled.div`
  /* height: 100%;
  @media only screen and (max-width: 540px) {
    height: 0;
    overflow: hidden;
  } */
`;

const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: hidden;
  @media only screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;
const Wrapper = styled.div`
  margin: 50px;
  margin-top: 5px;
  overflow: auto;
  max-height: 90%;
  display: grid;
  grid-template-rows: 43px 4vh 106px 1fr;
  @media only screen and (max-width: 840px) {
    margin: 3px;
    grid-template-rows: 55px 84px 120px 1fr;
  }
`;
export const PaperWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 50px;
  overflow: auto;
  max-height: 90%;
  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
    margin: 10px;
    margin-top: 50px;
  }
`;

