import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { FormServerError } from '../types';
import {
  ButtonWithLoader,
  InputBaseContainer,
  InputFileContainer,
  InputTextWithLabel,
  InputSelectWithLabel,
  CheckboxWithLabel,
} from '../molecules';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import CommentsChat from '../organisms/CommentsChat';
import PermitDocumentFormsTable from '../organisms/PermitDocumentFormsTable';
import {
  getPermitDocumentComments,
  createPermitDocumentComment,
} from '../api/projectDocuments';
import {
  COMMENTS_DATETIME_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import { formatDateTime } from '../utils/formatDate';

const labels = {
  documentName: 'Document Name',
  documentFile: 'File Attachment',
  status: 'Status',
  isCompleted: 'Completed',
  name: 'Name',
  forClient: 'For Client'
};

const schema = yup.object().shape({
  documentName: yup
    .string()
    .matches(/^[^-\s]+/, 'Document Name is required')
    .required()
    .label(labels.documentName),
  documentFile: yup
    .mixed()
    .required('A file is required')
    .label(labels.documentFile),
});

const schemaEdit = yup.object().shape({
  documentFile: yup.mixed().label(labels.documentFile),
  status: yup
    .object()
    .required('A status is required')
    .label(labels.status),
  isCompleted: yup.boolean().label(labels.isCompleted),
  name: yup.string().label(labels.name),
});

const PermitDocumentForm = ({
  onSubmit,
  initialErrors,
  modalError,
  addButtonText,
  updateButtonText,
  permit,
  statuses,
  initialValues,
  isReadOnly,
  isSimulateDeleted,
  cameFromSearch,
}: Props) => {
  const {
    handleSubmit,
    formState,
    errors,
    control,
    setValue,
    register,
  } = useForm({
    resolver: yupResolver(
      initialValues ? schemaEdit : schema,
    ),
    defaultValues: initialValues || ({} as any),
  });

  const [isSubmitting, setIsSubmitting] = React.useState(
    false,
  );
  const [commentCreated, setCommentCreated] = React.useState(null);
  const [fileSizeError, setFileSizeError] = React.useState(false);
  const [hasFile, setHasFile] = React.useState(false);

  React.useEffect(() => {
    if (initialValues && statuses) {
      if (initialValues.status) {
        const status = statuses?.find(
          el => (el?.title === initialValues.status || el?.title === initialValues?.statusName),
        );
        
        setValue('status', status);
      }
    }
  }, [initialValues, statuses]);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);
  const middleware = (submitFn: (data: any) => void) => (
    data: any,
  ) => {    
    submitFn({
      id: initialValues?.pk,
      permit: +permit,
      name: data.documentName,
      status: statuses?.find(
        el => el?.title === 'Under review', // changing this as per the EA-24 requirement ---> mritunjoy
      )?.id,
      adminFile: !data?.forClient ? data.documentFile : null,
      clientFile: data?.forClient ? data.documentFile : null,
      isCompleted: data.isCompleted,
    });
  };

  const middlewareEdit = (
    submitFn: (data: any) => void,
  ) => (data: any) => {
    
    submitFn({
      id: initialValues?.pk,
      status: data.status?.id,
      adminFile: (!data?.forClient && !Array.isArray(data?.documentFile)) ? data.documentFile : null,
      clientFile: data?.forClient ? data.documentFile : null,
      isCompleted: data.isCompleted,
    });
  };

  const handleChange = e => {
    const _5MB = 5242880;
    const _70MB = 73400320;
    if(e.target.files?.length > 0) {
      setHasFile(true)
    } else {
      setHasFile(false)
    }
    if(e.target.files[0]?.size > _70MB ) {
      setFileSizeError(true)
    } else {
      setFileSizeError(false)
      setValue('documentFile', e.target.files[0]);
    }
  };

  const modifiedGetCommentsRequest = (isSystemMsg) => {
    return Promise.all([
      getPermitDocumentComments(
        initialValues?.pk,
        {...GET_ALL_QUERY_PARAMS,system_message:isSystemMsg},
       ),
    ]).then(([response]) => {
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(comment => ({
            ...comment,
            createdAt: formatDateTime(
              comment?.createdAt,
              COMMENTS_DATETIME_FORMAT,
            ),
            creator: comment.username,
          })),
        },
      };
    });
  };

  return (
    <Container
      disabled={isSimulateDeleted}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(
          initialValues
            ? middlewareEdit(onSubmit)
            : middleware(onSubmit),
        )}
      >
        {initialValues && (
          <InputTextWithLabel
            InputProps={{ readOnly: true }}
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            isRequired={isYupFieldRequired(
              'name',
              schemaEdit,
            )}
          />
        )}
        {initialValues && (
          <InputBaseContainer>
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={props => (
                <InputSelectWithLabel
                  label={labels.status}
                  error={errors.status}
                  getOptionValue={(item: any) => item?.id}
                  getOptionLabel={(item: any) =>
                    item?.title
                  }
                  isRequired={isYupFieldRequired(
                    'status',
                    schemaEdit,
                  )}
                  isDisabled={cameFromSearch}
                  options={statuses}
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {!initialValues && (
          <InputBaseContainer>
            <InputTextWithLabel
              name="documentName"
              error={errors.documentName}
              inputRef={register}
              label={labels.documentName}
              isRequired={isYupFieldRequired(
                'documentName',
                schema,
              )}
              disabled={isReadOnly}
            />
          </InputBaseContainer>
        )}
        {initialValues && (
          <p style={{ textAlign: 'left' }}>
            Replace Document
          </p>
        )}
        <InputBaseContainer>
          <Controller
            name="documentFile"
            control={control}
            defaultValue=""
            render={() => (
              <InputFileContainer
                onChange={handleChange}
                error={errors.documentFile}
                accept="application/pdf,text/plain,text"
                isRequired={isYupFieldRequired(
                  'documentFile',
                  initialValues ? schemaEdit : schema,
                )}
                disabled={isReadOnly || cameFromSearch}
              />
            )}
          />
        </InputBaseContainer>
        {fileSizeError && <p style={{ textAlign: 'left', color: 'red' }}>
            File size exceeds 5MB!
          </p>}
          <InputBaseContainer>
            <Controller
              name="forClient"
              control={control}
              valueName="checked"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.forClient}
                  label={labels.forClient}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                  disabled={!hasFile || isReadOnly || cameFromSearch}
                />
              )}
            />
          </InputBaseContainer>
        {initialValues && (
          <InputBaseContainer>
            <Controller
              name="isCompleted"
              control={control}
              valueName="checked"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isCompleted}
                  label={labels.isCompleted}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                  disabled={isReadOnly || cameFromSearch}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {!isSimulateDeleted && (
        <ButtonWithLoader
          disabled={(isSubmitting && !modalError) || cameFromSearch || fileSizeError}
          loading={isSubmitting && !modalError}
          type="submit"
        >
          {initialValues ? updateButtonText : addButtonText}
        </ButtonWithLoader>
          )}
      </FormContainer>
      {initialValues?.settingDoc && initialValues?.pk && (
        <PermitDocumentFormsTable
          settingsDoc={initialValues.settingDoc}
          id={initialValues.pk}
        />
      )}
      {initialValues && (
        <CommentsChat
          commentsRequest={modifiedGetCommentsRequest}
          entityData={initialValues}
          postRequest={createPermitDocumentComment}
          title={'Notes'}
          buttonText='Add Note'
          isButtonHidden={isSimulateDeleted}
          isReadOnly={cameFromSearch}
          commentCreated={commentCreated}
          setCommentCreated={setCommentCreated}
        />
      )}
    </Container>
  );
};

interface Props {
  onSubmit: (data) => void;
  initialErrors?: FormServerError<any>;
  modalError: boolean;
  addButtonText: string;
  updateButtonText: string;
  permit: number;
  statuses: Array<any>;
  initialValues?: any;
  isReadOnly :boolean;
  isSimulateDeleted? :boolean;
  cameFromSearch?:boolean;
}

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

export default PermitDocumentForm;
