import * as React from 'react';
import {
  getPermitDocuments,
  createPermitDocument,
  updatePermitDocument,
  downloadAllPermitDocuments,
  deletePermitDocument,
  getReviewerComments,
  downloadAllPermitClientDocuments,
} from '../../api/projectDocuments';
import useTablePage from '../../hooks/useTablePage';

import Table from '../../organisms/Table';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import {
    ButtonWithLoader,
  InputBaseContainer,
  InputSelectWithLabel,
  InputTextWithLabel,
  ModalDefaultDraggable,
  TextAreaWithLabel,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import { convertTableBoolean } from '../../utils/convertTableValues';
import { getVocabulariesDataList } from '../../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import styled from "styled-components";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import isYupFieldRequired from '../../utils/isYupFieldRequired';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { createReviewerComment, updatePermitActivity, updateReviewerComment } from '../../api/activities';
import ReviewerCommentForm from '../../forms/ReviewerCommentForm';
import openInNewTab from '../../utils/openInNewTab';

type ReviewerComment = {
    reviewerComment: string;
    reference: string;
    status: number;
}
const labels = {
    reviewerComment: 'Reviewer Comments',
    status: 'Status',
    reference: 'Reference',
  };

const schema = yup.object().shape({
    reviewerComment: yup.string().label(labels.reviewerComment),
    status: yup.object().label(labels.status),
    reference: yup
      .string()
      
      .label(labels.reference),
  });

const DocumentsDetailedPage = ({
  onSubmit,
  permit,
  work,
  building,
  setAddDocument,
  setDocsAvailable,
  setDownloadAllDocs,
  setDocsWaitingOnClient,
  isViewMode = false,
  initialValues
}: Props) => {

    const {
        handleSubmit,
        formState,
        errors,
        control,
        setValue,
        register,
        } = useForm({
        resolver: yupResolver(schema),
        defaultValues:{test: []},
    });


  const [documents, setDocuments] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors]);
  
  const inspectorGetPermitDocuments = params => {
    return Promise.all([
      getPermitDocuments(params, permit?.toString()),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 10,
      }),
    ]).then(([documentsResponse, statusesResponse]) => {
      if (documentsResponse.data.results?.length) {
        setDocsAvailable(true);
        setDocuments(documentsResponse.data.results);
        // var emptyRow = {
        //     reviewerComment: null,
        //     reference: null,
        //     status: undefined
        // }
        // setReviewerCommentsList(emptyRow);
      }
      return {
        ...documentsResponse,
        data: {
          ...documentsResponse.data,
            results: initialValues?.selectedDocuments?.map(
            document => {
              const currentStatus = statusesResponse.data.results?.find(
                el => el?.id === document.status,
              );
              if (
                currentStatus?.title === 'Waiting on client'
              ) {
                setDocsWaitingOnClient(true);
              }
              return {
                name: document?.name,
                clientFiles: document?.clientFiles?.map(el => el?.fileName),
                documentFile: document?.clientFiles?.map(el => el?.file),
                status: currentStatus?.title,
                submitted:
                  currentStatus?.title === 'Submitted',
              };
            },
          ),
        },
      };
    });
  };

 
  const columnsForInspector = column => {
    if (
      [
        'pk',
        'permit',
        'jurisdiction',
        'work',
        'building',
        'adminFile',
        'clientFile',
        'submitted',
        'lastComment',
        'settingDoc',
        'isCompleted',
        'documentFile',
        'isForClient',
        // 'clientFiles',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Document Name';
      case 'clientFiles':
        return 'File';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrderForInspector = [
    'name',
    // 'adminFileName',
    // 'clientFileName',
    'clientFiles',
    'status',
  ];

  const cellCallbackFnIsOn = [
    // 'adminFileName',
    // 'clientFileName',
    'clientFiles'
  ];  

  const downloadDocuments = response => {
    const byteCharacters = atob(response.data.file);
    const type = response.data.contentType.split(";")[0]
    const fileName = response.data.fileName
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: type,
    });
    saveAs(blob, fileName);
  };

    const handleDownloadAllDocs = () => {
      const docPk = initialValues?.selectedDocuments?.map(el => el?.pk)
      Promise.all([downloadAllPermitClientDocuments({docPk, client: true})])
        .then(([response]) => {
          downloadDocuments(response);
        })
        .catch(() => {})
    }

    const handleCellClick = (rowValue, clickedColumn) => {      
      if (
        rowValue.documentFile &&
        Array.isArray(rowValue.documentFile)
      ) {
        const link = rowValue.documentFile[0];
        openInNewTab(link);
      } else {
        openInNewTab(rowValue.documentFile);
      }
    }

  return (
    <Container>
    <StyledDocumentsSection>
    <ButtonWithLoader
            disabled={isSubmitting || !initialValues?.selectedDocuments?.length}
            loading={false}
            onClick={() => handleDownloadAllDocs()}
            >Download All
          </ButtonWithLoader>
          <br />
        <Table
            customSearch
            isMultipleDocumentsClientFiles
            useViewModeInsteadEdit={true}
            rows={inspectorGetPermitDocuments}
            columns={columnsForInspector}
            columnsOrder={columnsOrderForInspector}
            formatValue={convertTableBoolean}
            onEdit={null}
            onSelect={null}
            onConfigure={null}
            onDelete={null}
            additionalActions={null}
            cellCallbackFnIsOn={cellCallbackFnIsOn}
            handleCellClickClientFile={handleCellClick}
            handleCellClick={handleCellClick}
            docs
        />
    </StyledDocumentsSection>
    <StyledReviewComments>
      {formSubmitted && <ReviewerCommentForm isViewMode={isViewMode} setFormSubmitted={setFormSubmitted} reviewId={initialValues?.id} formSubmitted={formSubmitted} />}
      {!formSubmitted && <ReviewerCommentForm isViewMode={isViewMode} setFormSubmitted={setFormSubmitted} reviewId={initialValues?.id} formSubmitted={formSubmitted} />}
    </StyledReviewComments>      
    </Container>  
  );
};

const Container = styled.fieldset`
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;
  display:flex;
  justify-content: space-evenly;
  .MuiButton-root {
    margin-top: 40px;
    margin-left: 10px;
  }  
`;

const StyledDocumentsSection = styled.div`
    width:33%;
    display: flex;
    flex-direction:column;
    align-items: start;
`;

const StyledReviewComments = styled.div`
    width:77%;
    padding-left:20px;
    border-left: 1px solid #cacaca
`;

interface Props {
  permit: number;
  work?: number;
  building?: number;
  isAddDocument: boolean;
  isSendDocs: boolean;
  setAddDocument: (data: boolean) => void;
  isDownloadAllDocs: boolean;
  setDocsAvailable: (data: boolean) => void;
  setDownloadAllDocs: (data: boolean) => void;
  setDocsWaitingOnClient: (data: boolean) => void;
  setSendDocs: (data: boolean) => void;
  isInspector: boolean;
  isViewMode?: boolean;
  initialValues?: any;
  cameFromSearch?:boolean;
  handleSubmit?: any;
  onSubmit: (data) => void;
  control?: any
}

export default DocumentsDetailedPage;
