import * as React from 'react';
import styled from 'styled-components';
import { FormSetupType } from '../api/formSetup';
import { getActiveFieldsFromMarkup } from '../utils/formSetupUtils';
import { Tooltip } from '../atoms';

const ViewFormSetup = ({ initialValues }: Props) => {
  const [
    activeMarkupFields,
    setActiveMarkupFields,
  ] = React.useState([]);

  React.useEffect(() => {
    if (initialValues?.formMarkup?.length) {
      const result = [];
      initialValues?.formMarkup.forEach(field => {
        getActiveFieldsFromMarkup(
          field,
          ['fields'],
          result,
        );
        setActiveMarkupFields([
          ...activeMarkupFields,
          ...result,
        ]);
      });
    }
  }, [initialValues?.formMarkup]);

  return (
    <ImageWrapper>
      <FieldsWrapper>
        <StyledImage src={initialValues?.file} />
        {activeMarkupFields?.length
          ? activeMarkupFields?.map(el => {
              const tooltip = el.parents
                .filter(parentEl => parentEl !== 'fields')
                .join(' > ');

              const parents =
                // eslint-disable-next-line no-nested-ternary
                el?.parents.length > 3
                  ? el.parents.splice(el.parents.length - 3)
                  : el?.parents.length > 2
                  ? el.parents.splice(el.parents.length - 2)
                  : [el.parents[el.parents.length - 1]];

              const label = `${parents.join(' > ')}`;
              return (
                <FieldBox
                  x={el.x}
                  y={el.y}
                  width={el.width}
                  height={el.height}
                >
                  <Tooltip title={tooltip} placement="top">
                    <Label width={el.width}>{label}</Label>
                  </Tooltip>
                </FieldBox>
              );
            })
          : null}
      </FieldsWrapper>
    </ImageWrapper>
  );
};

interface Props {
  initialValues: FormSetupType;
}

interface FieldProps {
  width: number;
  height?: number;
  x?: number;
  y?: number;
}

const ImageWrapper = styled.div`
  width: 632px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin: 0 auto;
`;

const FieldsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const FieldBox = styled.div<FieldProps>`
  position: absolute;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  background-color: #f5f5f5;
`;

const Label = styled.p<FieldProps>`
  width: ${({ width }) => width}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  margin: 0;
  font-size: 14px;
  padding: 0 3px;
`;

const StyledImage = styled.img`
  width: 612px;
  height: 792px;
`;

export default ViewFormSetup;
