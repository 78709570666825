
import * as React from 'react';
import styled from 'styled-components';
import DashboardLayout from '../layouts/DashboardLayout';
import DashboardInspectionsPage from './DashboardInspectionsPage';

const DashboardPageInspector = () => {
  return (
    <DashboardLayout>
      <Container>
        <DashboardInspectionsPage />
      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: var(--defaultBackground);
`;

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export default DashboardPageInspector;
