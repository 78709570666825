import * as React from 'react';
import styled from 'styled-components';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getJurisdictions,
  createJurisdiction,
  updateJurisdiction,
  JurisdictionTableType,
  deleteJurisdiction,
} from '../../api/jurisdiction';
import Table from '../../organisms/Table';
import ActionsPanel from '../../organisms/ActionsPanel';
import JurisdictionForm from '../../forms/JurisdictionForm';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import useUserRole from '../../hooks/useUserRole';
import { convertTableBoolean } from '../../utils/convertTableValues';

const modifiedGetJurisdictionsRequest = params =>
  getJurisdictions(params).then(response => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results.map(item => {
        if (item.isFranchiseAll) {
          return {
            ...item,
            franchiseNames: 'All',
          };
        }

        return item;
      }),
    },
  }));

const JurisdictionPage = () => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage<JurisdictionTableType>({
    createEndpoint: createJurisdiction,
    updateEndpoint: updateJurisdiction,
    deleteEndpoint: deleteJurisdiction,
    modalCreateTitle: 'Add Jurisdiction',
    modalUpdateTitle: 'Update Jurisdiction',
  });
  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'franchise',
        'isFranchiseAll',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'phoneNumber':
        return 'Phone';
      case 'franchiseNames':
        return 'Branches';
      case 'zipCode':
        return 'Zip';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={isSuperAdmin && handleAdd}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={modifiedGetJurisdictionsRequest}
          onEdit={handleEdit}
          onDelete={isSuperAdmin && handleDelete}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <JurisdictionForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong> /{' '}
          {selectedItem.email} / {selectedItem.phoneNumber}{' '}
          / {selectedItem.address} / {selectedItem.building}
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

export default JurisdictionPage;
