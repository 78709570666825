// eslint-disable-next-line import/prefer-default-export
export enum PERMIT_MENU_ACTIVE_TAB {
  ACTIVITIES = 11,
  INSPECTIONS = 12,
  FEES = 13,
  NOTES = 14,
  TICKETS = 15,
  DOCUMENTS = 16,
  CONDITIONS = 17,
}
