import React, { FC, useEffect, useState } from 'react';
import Table from '../../Common/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { Pagination, TableWrapper, commonFilterMeta, commonHeaders } from '../../Common/Table/types';
import { setActiveProjects, setSelectedPermit } from '../../../../../redux/slices/projectSlice';
import PaginationMobile from '../../../../../pages/Responsive/PaginationMobile';
import { setRowsCount } from '../../../../../redux/slices/paginationSlice';
import { ProjectPermit } from '../../../types';
import { filterActiveRecords, updatePagination } from '../../../utils';
import useFilterActions from '../../../../../pages/Responsive/useFilterActions';
import { setShowDrawerContent } from '../../../../../redux/slices/generalSlice';

type Props = {
  showActiveOnly: boolean;
};
const projectsTable: FC<Props> = ({ showActiveOnly }) => {
  const allProjects = useSelector((state: RootState) => state.projectsSlice.projects);
  const projectsCollection = useSelector((state: RootState) => state.projectsSlice.activeProjects);

  const filteredRows = useFilterActions(projectsCollection);
  const dispatch = useDispatch();
  const [visibleProjects, setVisibleProjects] = useState(projectsCollection);
  const { pageSize, pageIndex } = useSelector((state: RootState) => state.pagination);

  useEffect(() => {
    const _projects = updatePagination(pageIndex, pageSize, filteredRows);

    dispatch(setRowsCount(filteredRows.length));
    setVisibleProjects(_projects);
  }, [pageIndex, pageSize, projectsCollection, filteredRows]);

  useEffect(() => {
    const _activeProjects = filterActiveRecords(allProjects, showActiveOnly);
    dispatch(setActiveProjects(_activeProjects));
  }, [showActiveOnly, allProjects]);

  const setSelectedItemInSlice = (project: ProjectPermit) => {
    dispatch(setSelectedPermit(project));
    dispatch(setShowDrawerContent(true));
  };
  return (
    <TableWrapper>
      <Table
        collection={visibleProjects}
        headers={commonHeaders}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 195px 75px"
        setSelectedItemInSlice={setSelectedItemInSlice}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default projectsTable;
