import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

const MenuLinkWithIcon = ({
  icon,
  text,
  onClick,
}: Props) => {
  return (
    <MenuItemStyled onClick={onClick}>
      {icon && (
        <ListItemIconStyled>{icon}</ListItemIconStyled>
      )}
      <ListItemTextStyled primary={text} />
    </MenuItemStyled>
  );
};

const MenuItemStyled = styled(MenuItem)`
  padding: 8px 25px;
  color: var(--mainText);

  &:hover {
    background-color: var(--secondary);
  }
`;

const ListItemIconStyled = styled(ListItemIcon)`
  min-width: unset;
  margin-right: 8px;
  color: inherit;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const ListItemTextStyled = styled(ListItemText)`
  margin: 0;
  color: inherit;
`;

interface Props {
  icon?: JSX.Element;
  text: string;
  onClick: () => any;
}

export default MenuLinkWithIcon;
