import * as React from 'react';
import styled from 'styled-components';
import { FieldError, FieldErrors } from 'react-hook-form';

const ErrorMessages = ({ error }: ErrorMessagesTypes) => (
  <>
    {error.types ? (
      Object.values(error.types).map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ErrorMessage key={index}>{message}</ErrorMessage>
      ))
    ) : (
      <ErrorMessage>{error.message}</ErrorMessage>
    )}
  </>
);

interface ErrorMessagesTypes {
  error: FieldError | FieldErrors;
}

const ErrorMessage = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  color: var(--mainRequired);
`;

export default ErrorMessages;
