import {
  addDays,
  addMonths,
  startOfMonth,
  subMonths,
  subYears,
} from 'date-fns';

import { SelectOption } from '../types';
import formatDate from '../utils/formatDate';
import { INVOICES_SEARCH_DATE_FORMAT } from './index';

export enum PERIODIC_VALUES {
  ONETIME = 'O',
  YEAR = 'Y',
}

export const PERIODIC: SelectOption[] = [
  {
    label: 'One time',
    value: PERIODIC_VALUES.ONETIME,
  },
  {
    label: 'Yearly',
    value: PERIODIC_VALUES.YEAR,
  },
];

export const SUPER_ADMIN_ROLE = 'superAdmin';

export enum USER_TYPES_VALUES {
  FRANCHISE_ADMIN,
  EMPLOYEE,
  INSPECTOR,
  CLIENT,
}

export const USER_TYPES_REPORT_SETUP: SelectOption[] = [
  {
    label: 'Branch admin',
    value: 0,
  },
  {
    label: 'Employee',
    value: 1,
  },
  {
    label: 'Inspector',
    value: 2,
  },
];

export const USER_TYPES: SelectOption[] = [
  {
    label: 'Client',
    value: USER_TYPES_VALUES.CLIENT,
  },
  {
    label: 'Employee',
    value: USER_TYPES_VALUES.EMPLOYEE,
  },
  {
    label: 'Inspector',
    value: USER_TYPES_VALUES.INSPECTOR,
  },
  {
    label: 'Branch admin',
    value: USER_TYPES_VALUES.FRANCHISE_ADMIN,
  },
  {
    label: 'Super admin',
    value: SUPER_ADMIN_ROLE,
  },
];

export enum CLIENT_TYPES_VALUES {
  NOT_CLIENT,
  INDIVIDUAL,
  COMPANY,
}

export const CLIENT_TYPES: SelectOption[] = [
  {
    label: 'Individual',
    value: CLIENT_TYPES_VALUES.INDIVIDUAL,
  },
  {
    label: 'Company',
    value: CLIENT_TYPES_VALUES.COMPANY,
  },
];

export const INVOICE_STATUS: SelectOption[] = [
  { label: 'All', value: undefined },
  { label: 'Paid', value: true },
  { label: 'Unpaid', value: false },
];

export interface InvoiceDate {
  label: string;
  value: {
    fromDate: string;
    toDate: string;
  };
}

export const invoiceDates: (
  date?: Date,
) => InvoiceDate[] = (date: Date = new Date()) => {
  const monthAfter = addMonths(date, 1);
  const monthBefore = subMonths(date, 1);
  const monthBefore3 = subMonths(date, 3);
  const monthBefore6 = subMonths(date, 6);
  const monthBefore12 = subYears(date, 1);

  return [
    {
      label: 'This month',
      value: {
        fromDate: formatDate(
          startOfMonth(date),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
        toDate: formatDate(
          startOfMonth(monthAfter),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
      },
    },
    {
      label: 'Last month',
      value: {
        fromDate: formatDate(
          startOfMonth(monthBefore),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
        toDate: formatDate(
          startOfMonth(date),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
      },
    },
    {
      label: 'Last 3 months',
      value: {
        fromDate: formatDate(
          startOfMonth(monthBefore3),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
        toDate: formatDate(
          startOfMonth(monthAfter),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
      },
    },
    {
      label: 'Last 6 months',
      value: {
        fromDate: formatDate(
          startOfMonth(monthBefore6),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
        toDate: formatDate(
          startOfMonth(monthAfter),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
      },
    },
    {
      label: 'Last 12 months',
      value: {
        fromDate: formatDate(
          startOfMonth(monthBefore12),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
        toDate: formatDate(
          startOfMonth(monthAfter),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
      },
    },
    {
      label: 'Year to date',
      value: {
        fromDate: formatDate(
          subYears(addDays(date, 1), 1),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
        toDate: formatDate(
          startOfMonth(monthAfter),
          INVOICES_SEARCH_DATE_FORMAT,
        ),
      },
    },
  ];
};
