import * as React from 'react';
import styled from 'styled-components';

import {
  getCompanyToBeInvoiceReport,
  ReportTableType,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const BusinessToBeInvoicedReport = React.forwardRef(
  (
    {
      company_name,
      setIsValidForExport,
    }: ReportTableType,
    ref,
  ) => {
    const [
      isNullReport,
      setIsNullReport,
    ] = React.useState(false);
   const [grandTotalData, setGrandTotalData] = React.useState([])
    

    const data = {
      company_name,
    };
    let currentPermit = ""
    const getModifiedCompanyInvoiceReport = () =>
      Promise.all([getCompanyToBeInvoiceReport(data)])
        .then(([reports]) => {         
          if (reports.data?.length === 0) {
            setIsNullReport(true);
            setIsValidForExport(false);
          } else {
            setIsNullReport(false);
            setIsValidForExport(true);
            currentPermit = reports.data[0].permitDetails[0]
            
        }
        const permitDetailsArr = []
       
        let grandTotal = 0 
          reports.data.map((proj, projIdx) => {
            proj.permitDetails.map((prmt, prmtIdx) => {
                grandTotal += prmt.permitAmount
                prmt.feeDetails.map((fee, feeIdx) => {
                                        
                    if(currentPermit.permitName !== prmt.permitName) {
                        permitDetailsArr.push({projectNamePermitName: null, 
                        amount: <b>{currentPermit.permitAmount}</b>,
                        fee: <b>Total: </b>,
                        address: null
                    })
                        currentPermit = prmt
                    }
                    permitDetailsArr.push({projectNamePermitName: `${proj.projectName} | ${prmt.permitName}`, 
                            amount: fee.amount,
                            fee: fee.feeName,
                            address: proj.projectAddress
                })
                if(reports.data.length - 1 === projIdx && proj.permitDetails.length - 1 === prmtIdx && prmt.feeDetails.length - 1 === feeIdx) {
                    permitDetailsArr.push({projectNamePermitName: null, 
                        amount: <b>{currentPermit.permitAmount}</b>,
                        fee: <b>Total: </b>,
                        address: null
            })
                }
                })
            })
          })
           setGrandTotalData([...grandTotalData, {title: "Grand Total", value: Number(grandTotal.toFixed(2))}])          
          return {
              ...reports,
              data: {
                  ...reports.data,
                  results: permitDetailsArr
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});

    const columns = column => {
      if (
        [
          'franchiseName',
          'projectAddress',
          'totalFees',
          'permitDetails',
          'inspectorName',
          'lastComment',
          'projectName',
          'permitName',
        ].includes(column)
      ) {
        return null;
      }

      switch (column) {
        case 'address':
          return 'Address';
        case 'fee':
          return 'Fee';
        case 'amount':
          return 'Amount';
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';

        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
      'projectNamePermitName',
      'address',
      'fee',
      'amount'
    ];

    if (isNullReport) {
      return (
        <InfoText>
          No project was found with selected Company Name.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Business to be Invoiced Report</Title>

        <List>
          <li>
            <Bold>Company Name: </Bold> {company_name}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedCompanyInvoiceReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={null}
        //   additionalFeesTotalRow={totalFeesData}
          additionalBottomRow={grandTotalData}
        />
      </Wrapper>
    );
  },
);


const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default BusinessToBeInvoicedReport;
