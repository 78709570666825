import { api } from './api';

export const getSearchCategoriesList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-brief/',
    params,
  });

export const getSearchAllList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-all/',
    params,
  });

export const getSearchClientsList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-users/',
    params,
  });

export const getSearchEmployeeList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-employee/',
    params,
  });

export const getSearchInspectionsList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-inspections/',
    params,
  });

export const getSearchPermitsList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-permits/',
    params,
  });

  export const getSearchInvoiceList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-invoice/',
    params,
  });

export const getSearchPmList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-pm/',
    params,
  });

export const getSearchTicketsList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-tickets/',
    params,
  });

export const getSearchActivitiesList = params =>
  api.request<SearchTypeList>({
    method: 'GET',
    url: '/search/search-permit-activity/',
    params,
  });

export interface SearchTypeList {
  all: number;
  clients: number;
  employee: number;
  inspections: number;
  permit: number;
  projectManager: number;
  tickets: number;
}
