const convertLink = string => {
  return typeof string === 'string'
    ? string.toLowerCase().replace(' ', '-')
    : string;
};

export default (arr: { [key: string]: any }[]) => {
  if (Array.isArray(arr)) {
    return arr.map(el => {
      if (el.title) {
        return {
          ...el,
          linkTo: convertLink(el.title),
          name: el.title,
        };
      }
      if (el.name) {
        return {
          ...el,
          linkTo: convertLink(el.name),
        };
      }
      return el;
    });
  }
  return arr;
};
