import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';

import theme from '../../theme';
import { MONTH_FULL_LIST } from '../../constants';
import { FranchiseType } from '../../api/franchise';
import { SelectInputWithSelectAll } from '../../atoms';
import { convertDataKeysToLabels } from '../../utils/convertWidgetValues';

const FranchiseSalesGraphWidget = ({
  data,
  franchises,
  onSelectChange,
}: Props) => (
  <Container>
    <Header>Branch Sales Graph</Header>
    {franchises && franchises.length && (
      <SelectContainer>
        <SelectInputWithSelectAll
          data={franchises}
          valueKey="id"
          labelKey="name"
          onMenuClose={onSelectChange}
          placeholder="Select branches from the list..."
        />
      </SelectContainer>
    )}

    {data && data.length ? (
      <Bar>
        <ResponsiveBar
          data={convertDataKeysToLabels(data)}
          keys={['Sales', 'Projected Sales']}
          indexBy="Month"
          isInteractive={false}
          colors={[theme.marker1, theme.marker2]}
          padding={0.3}
          valueScale={{ type: 'symlog' }}
          borderRadius={5}
          innerPadding={10}
          groupMode="grouped"
          height={600}
          margin={{
            top: 30,
            right: 0,
            bottom: 100,
            left: 0,
          }}
          enableGridY={false}
          axisBottom={{
            format: (v: any) =>
              `${MONTH_FULL_LIST[v - 1]?.slice(0, 3)}'${data
                .find(item => item.month === v)
                ?.year.toString()
                .substr(-2)}`,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom',
              direction: 'row',
              itemWidth: 100,
              itemHeight: 20,
              translateY: 80,
              symbolSize: 30,
            },
          ]}
        />
      </Bar>
    ) : (
      <div style={{ textAlign: 'center' }}>
        No sales with selected branches
      </div>
    )}
  </Container>
);

const Container = styled.div``;

const SelectContainer = styled.div`
  margin: 16px 0;
`;

const Bar = styled.div`
  height: 500px;
`;

const Header = styled.div`
  font-size: 1.125rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
`;

interface Props {
  data: any;
  franchises: FranchiseType[];
  onSelectChange?: (
    selected: any[] | null,
    isCanceled: boolean,
  ) => void;
}

export default FranchiseSalesGraphWidget;
