import * as React from 'react';
import styled, {
  createGlobalStyle,
} from 'styled-components';
import Draggable from 'react-draggable';
import CloseIcon from '@material-ui/icons/Close';
import { ClickAwayListener } from '@material-ui/core';

import { ButtonModalClose } from '../atoms';
import { ButtonWithLoader } from './index';
import { useReactToPrint } from 'react-to-print';
import FormsReport from '../pages/Reports/FormsReport';

const ModalDefaultDraggable = ({
  children,
  title,
  onClose,
  className = '',
  addContactModal,
  disabledDraggable,
  centered,
  wide,
  extraWide,
  showPrintButton,
  printRef,
  onClickAwayClose = true,
}: Props) => {
  const containerRef = React.useRef(null)
  const printableRef = printRef ? printRef : React.useRef();
  const handlePrintInspectionForm = useReactToPrint({
    content: () => printableRef.current,
    pageStyle: `
      @page {
        size: auto;
        margin-top: 20mm;
      }
      @media print {
        body {
          margin: 0;
          padding: 0;
        }
      }
    `,
  });
  const formExportToPdf = () => {
      handlePrintInspectionForm();
  };
React.useEffect(()=>{  
  if(addContactModal && containerRef && containerRef.current){
    containerRef.current.scrollTo(0,0)
    }
},[addContactModal])


  return (
    <ModalContainer ref={containerRef} className={className} addContactModal={addContactModal}>
      <GlobalStyles />
      <ClickAwayListener
        onClickAway={onClickAwayClose ? onClose : null}
        mouseEvent="onMouseDown"
      >
        <Draggable
        disabled={disabledDraggable}
          cancel=".react-draggable-cancel"
          positionOffset={{ x: '-50%', y: '0%' }}
        >
          <ModalContent
            centered={centered}
            wide={wide}
            extraWide={extraWide}
          >
            <StyledButtonModalClose onClick={onClose} onTouchStart={onClose}/>
            <StyledTitle>{title}</StyledTitle>
            {showPrintButton && (
              <ButtonWithLoader
                variant="outlined"
                disabled={false}
                onTouchStart={formExportToPdf}
                onClick={formExportToPdf}
              >
                Print
              </ButtonWithLoader>
            )}
            <ModalContentInner ref={ printRef ? printRef : printableRef}>
              <div>{children}</div>
            </ModalContentInner>
          </ModalContent>
        </Draggable>
      </ClickAwayListener>
    </ModalContainer>
  );
};

const GlobalStyles = createGlobalStyle`
  body {
    max-height: 100vh;
    overflow: hidden;
    padding-right: 17px;
    
    @media print {
      table[role="table"] {
        display: none;
      }
    }
  }
`;

const MobileClose = styled.button`
 width: 32px;
 height: 32px;
 border-radius:50%;
 z-index: 300;
 position: absolute;
 right:32px;
 top:32px;
 background-color:#fff;
 @media only screen and (min-width: 840px) {
   display:none;
 }
`;

const CloseWrapper = styled.div`  ;
zoom: 1.2;
position: relative;
left: -5px;
top: -2px;
`;
const ModalContainer = styled.div`
  position: fixed;
  z-index: var(--modalZIndex);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px 20px;
  overflow-x: hidden;
  overflow:${props=>(props.addContactModal?`hidden`:`auto`)};
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  ${({ className }) =>
    className === 'over' && 'overflow: visible;'}
`;

const ModalContent = styled.div<Content>`
  background-color: var(--defaultBackground);
  width: ${({ wide, extraWide }) =>
    // eslint-disable-next-line no-nested-ternary
    wide ? '1000px' : extraWide ? '95%' : '100%'};
  min-width: 280px;
  max-width: ${({ wide, extraWide }) =>
    // eslint-disable-next-line no-nested-ternary
    wide ? '1000px' : extraWide ? '95%' : '800px'};
  padding: 35px;
  border-radius: 5px;
  position: absolute;
  top: ${({ centered }) => (centered ? '50%' : '32px')};
  left: 50%;
  margin-bottom: 100px;
  overflow-x: scroll;
`;

const ModalContentInner = styled.div.attrs({
  className: 'react-draggable-cancel',
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`;

const StyledTitle = styled.div`
  width: 90%;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0 auto 30px auto;
`;

const StyledButtonModalClose = styled(ButtonModalClose)`
  top: 32px;
  right: 32px;

  @media print {
    display: none;
  }
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  title?: string;
  onClose: () => void;
  className?: string;
  centered?: boolean;
  wide?: boolean;
  extraWide?: boolean;
  showPrintButton?: boolean;
}

interface Content {
  centered?: boolean;
  wide?: boolean;
  extraWide?: boolean;
}

export default ModalDefaultDraggable;
