import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AllActivitiesState {
  allData: any;
  userEmployeeData: any;
  userInspectorData: any;
  vocabulariesData: any;
  callsData: any;
  feesData: any;
  inspectionsData: any;
  reviewsData: any;
  conditionsData: any;
  proposalData: any;
  permittingData: any;
  isUpdateTable: boolean;
  useTaskSheet: boolean;
}

const initialState: AllActivitiesState = {
  allData: {},
  userEmployeeData: {},
  userInspectorData: {},
  vocabulariesData: {},
  callsData: {},
  feesData: {},
  inspectionsData: {},
  reviewsData: {},
  conditionsData: {},
  proposalData: {},
  permittingData: {},
  isUpdateTable: false,
  useTaskSheet: false
};

export const allActivitiesSlice = createSlice({
  name: 'allActivities',
  initialState,
  reducers: {
    setAllData: (state, action: PayloadAction<any>) => {
      state.allData = action.payload;
    },
    setUserEmployeeData: (state, action: PayloadAction<any>) => {
      state.userEmployeeData = action.payload;
    },
    setUserInspectorData: (state, action: PayloadAction<any>) => {
      state.userInspectorData = action.payload;
    },
    setVocabulariesData: (state, action: PayloadAction<any>) => {
      state.vocabulariesData = action.payload;
    },
    setFeesData: (state, action: PayloadAction<any>) => {
      state.feesData = action.payload;
    },
    setCallsData: (state, action: PayloadAction<any>) => {
      state.callsData = action.payload;
    },
    setInspectionsData: (state, action: PayloadAction<any>) => {
      state.inspectionsData = action.payload;
    },
    setReviewsData: (state, action: PayloadAction<any>) => {
      state.reviewsData = action.payload;
    },
    setConditionsData: (state, action: PayloadAction<any>) => {
      state.conditionsData = action.payload;
    },
    setProposalData: (state, action: PayloadAction<any>) => {
      state.proposalData = action.payload;
    },
    setPermittingData: (state, action: PayloadAction<any>) => {
      state.permittingData = action.payload;
    },
    setIsUpdateTable: (state, action: PayloadAction<any>) => {
      state.isUpdateTable = action.payload;
    },
    setUseTaskSheet: (state, action: PayloadAction<boolean>) => {
      state.useTaskSheet = action.payload;
    },
    resetActivities: state => {
      state.allData = {};
      state.userEmployeeData = {};
      state.userInspectorData = {};
      state.vocabulariesData = {};
      state.callsData = {};
      state.feesData = {};
      state.inspectionsData = {};
      state.reviewsData = {};
      state.conditionsData = {};
      state.proposalData = {};
      state.permittingData = {};
      state.isUpdateTable = false;
      state.useTaskSheet = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllData,
  setUserEmployeeData,
  setUserInspectorData,
  setVocabulariesData,
  setCallsData,
  setFeesData,
  setInspectionsData,
  setReviewsData,
  setConditionsData,
  setProposalData,
  setPermittingData,
  setIsUpdateTable,
  resetActivities,
  setUseTaskSheet
} = allActivitiesSlice.actions;

export default allActivitiesSlice.reducer;
