import * as React from 'react';
import styled from 'styled-components';

import { SvgIconProps } from '@material-ui/core/SvgIcon';

const MenuIcon = ({ icon: Icon, title }: Props) => {
  return (
    <Wrapper title={title}>
      <Icon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & svg {
    fill: var(--defaultBackground);
  }
`;

interface Props {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
}

export default MenuIcon;
