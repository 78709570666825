import * as React from 'react';
import styled from 'styled-components';

import {
  getCompanyOutstandingFeesReport,
  ReportTableType,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';
import { currencyFormatCents } from './DailyInspectionReport/Utils';

const CompanyOutstandingFeesReport = React.forwardRef(
  (
    {
      company_name,
      setIsValidForExport,
    }: ReportTableType,
    ref,
  ) => {
    const [
      isNullReport,
      setIsNullReport,
    ] = React.useState(false);
    const [additionalRowData, setAdditionalRowData] = React.useState([])


    const data = {
      company_name,
    };
    let currentPermit = ""
    const getModifiedCompanyInvoiceReport = () =>
      Promise.all([getCompanyOutstandingFeesReport(data)])
        .then(([reports]) => {       
          if (reports.data?.length === 0) {
            setIsNullReport(true);
            setIsValidForExport(false);
          } else {
            setIsNullReport(false);
            setIsValidForExport(true);
            currentPermit = reports.data[0].permitDetails[0]
            
        }
        const permitDetailsArr = []
       
        let grandTotal = 0 
        let count=0
          reports.data.map((proj) => {
            grandTotal += proj.totalFees
            proj.permitDetails.map((prmt) => {
                count += 1
                    permitDetailsArr.push({projectNamePermitName: `${proj.projectName} | ${prmt.permitName}`, 
                    permitNo: prmt.permitNumber,
                    projectManager: prmt.managerName,
                    dateInvoiced:  formatDateTime(Array.isArray(prmt.feeDetails) ? prmt.feeDetails[0].invoiceDate : prmt.feeDetails.invoiceDate, DATETIME_FORMAT),
                    amountDue:prmt.permitQuantity * prmt.permitAmount
                })
            })
          })
          setAdditionalRowData([{title: "Count", value: count}, {title: "Total", value: currencyFormatCents(grandTotal)}])
          return {
              ...reports,
              data: {
                  ...reports.data,
                  results: permitDetailsArr
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});

    const columns = column => {
      switch (column) {
        case 'dateInvoiced':
          return 'Date Invoiced';
        case 'projectManager':
          return 'Project Manager';
        case 'permitNo':
          return 'Permit #';
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';
          case 'amountDue':
            return 'Amount Due';  

        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
      'projectNamePermitName',
      'Permit #',
      'Project Manager',
      'Date Invoiced',
      'Amount Due'
    ];

    if (isNullReport) {
      return (
        <InfoText>
         No details found for the selected company.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Company Outstanding Fees Report</Title>

        <List>
          <li>
            <Bold>Company Name: </Bold> {company_name}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedCompanyInvoiceReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={null}
          additionalBottomRow={additionalRowData}
        />
       
      </Wrapper>
    );
  },
);


const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default CompanyOutstandingFeesReport;