import * as React from 'react';
import styled from 'styled-components';

import { SidebarSubMenuItem } from '../molecules';
import {
  SubMenuLink,
  settingsSetupLinks,
} from '../constants/menu';
import DropdownButton from '../molecules/DropdownButton';

const SubMenuHeadLine = ({
  menuLinks,
  className,
  basePath,
  isSuperAdmin,
}: SubMenuHeadLineTypes) => {
  return (
    <Container className={className}>
      {menuLinks?.map(element => {
        if (
          isSuperAdmin &&
          element.linkTo === 'report-setup'
        ) {
          return (
            <DropdownButton
              links={settingsSetupLinks}
              basePath={basePath}
            />
          );
        }
        return (
          <SidebarSubMenuItem
            key={element.id}
            to={element.linkTo}
            title={element.name}
            basePath={basePath}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 10px);
  display: flex;
  margin: 0 -5px;
`;

interface SubMenuHeadLineTypes {
  menuLinks: SubMenuLink[];
  className?: string;
  basePath?: string;
  isSuperAdmin?: boolean;
}

export default SubMenuHeadLine;
