import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DocumentFileItem, DocumentConditionItem, InspectionItemType, CommentItem, ConditionItemType } from '../../modules/clientModule/types';

export interface DocumentsState {
  AllPermitsWithConditions: DocumentConditionItem[];
  PermitConditions: InspectionItemType[];
  SelectedCondition: InspectionItemType;
  Comments: CommentItem[];
  ConditionName: string;
  selectedConditionItem: ConditionItemType;
}

const initialState: DocumentsState = {
  AllPermitsWithConditions: [],
  PermitConditions: [],
  SelectedCondition: {} as InspectionItemType,
  Comments: [],
  ConditionName: '',
  selectedConditionItem: {} as ConditionItemType
};

export const conditionsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setConditions: (state, action: PayloadAction<DocumentConditionItem[]>) => {
      state.AllPermitsWithConditions = action.payload;
    },
    setPermitConditions: (state, action: PayloadAction<InspectionItemType[]>) => {
      state.PermitConditions = action.payload;
    },
    setSelectedCondition: (state, action: PayloadAction<InspectionItemType>) => {
      state.SelectedCondition = action.payload;
    },
    setConditionComments: (state, action: PayloadAction<CommentItem[]>) => {
      state.Comments = action.payload;
    },
    setConditionName: (state, action: PayloadAction<string>) => {
      state.ConditionName = action.payload;
    },
    setSelectedConditionItem: (state, action: PayloadAction<ConditionItemType>) => {
      state.selectedConditionItem = action.payload;
    },
    resetConditions: state => {
      state.AllPermitsWithConditions = [];
      state.PermitConditions = [];
      state.SelectedCondition = {} as InspectionItemType;
      state.Comments = [];
      state.ConditionName = '';
      state.selectedConditionItem = {} as ConditionItemType;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConditions,
  setPermitConditions,
  resetConditions,
  setSelectedCondition,
  setConditionName,
  setConditionComments,
  setSelectedConditionItem,
} = conditionsSlice.actions;

export default conditionsSlice.reducer;
