import * as React from 'react';
import styled, {
  createGlobalStyle,
} from 'styled-components';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const ModalBlockError = ({ children }: Props) => (
  <ModalContainer>
    <GlobalStyles />
    <ModalContent>
      <ErrorIcon />
      <Content>{children}</Content>
    </ModalContent>
  </ModalContainer>
);

const GlobalStyles = createGlobalStyle`
  body {
    max-height: 100vh;
    overflow: hidden;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px 20px;
  overflow: auto;
  display: flex;
  background-color: rgba(0, 0, 0, 0.85);
`;

const ModalContent = styled.div.attrs({
  className: 'modal-window__modal-content',
})`
  position: relative;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 680px;
  justify-content: center;
  padding: 72px 100px;
  border-radius: 10px;
  background-color: var(--defaultBackground);
`;

const ErrorIcon = styled(WarningRoundedIcon)`
  color: var(--mainRequired);
  font-size: 124px;
  margin: 0 auto 32px auto;
`;

const Content = styled.div`
  background-color: var(--secondary);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  padding: 32px;
  font-size: 1.125rem;
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

export default ModalBlockError;
