import * as React from 'react';
import styled from 'styled-components';

import { ButtonMain } from '../atoms';
import {
  ActionPanelMore,
  BreadCrumbs,
  SearchBar,
  InputSelect,
} from '../molecules';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root';

const ActionsPanel = ({
  onSearch,
  onAdd,
  buttonTitle = 'Add',
  isBreadCrumbsVisible = true,
  isActionPanelMoreVisible = true,
  className,
  addTicket,
  isAllActivities,
  isSaveDisabled,
  emergencyOptions,
  onEmergencySortChange,
  emergencySort,
  selectedInspections,
  isInspectionsPath,
  sendInspectionsEmail,
}: Props) => {
  const searchToken = useSelector((s:RootState) => s.filters.searchToken)
  return (
  <Container className={className}>
    <Part>{isBreadCrumbsVisible && <BreadCrumbs />}</Part>
    <Part>
      {onSearch && <SearchContainer><SearchBar text={searchToken} onChange={onSearch} /></SearchContainer>}
      {onAdd && !addTicket && (
        <ButtonMain onClick={onAdd} disabled={isSaveDisabled}>
          {buttonTitle}
        </ButtonMain>
      )}
      {onAdd && addTicket && (
        <StylesButtonMain onClick={onAdd}>
          {buttonTitle}
        </StylesButtonMain>
      )}
      {isAllActivities && (
        <>
          {isInspectionsPath && (
            <ButtonWrapper>
              <StylesButtonMain
                disabled={!selectedInspections?.length}
                onClick={sendInspectionsEmail}
              >
                Send Inspection Request
              </StylesButtonMain>
            </ButtonWrapper>
          )}
          <SelectWrapper>
            <InputSelect
              isSearchable={false}
              defaultValue={emergencyOptions[0]}
              onChange={onEmergencySortChange}
              value={emergencySort}
              options={emergencyOptions}
              getOptionValue={(item: any) => item.value}
              getOptionLabel={(item: any) => item.title}
            />
          </SelectWrapper>
        </>
      )}
      {isActionPanelMoreVisible && <ActionPanelMore />}
    </Part>
  </Container>
);
} 

const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
  &.action-container {
    background: none;
    justify-content: left;
  }
`;

const SelectWrapper = styled.div`
  width: 200px;
`;

const Part = styled.div`
  display: flex;

  > * {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiButton-root,
  .MuiInputBase-root {
    height: 30px;
  }
`;

const StylesButtonMain = styled(ButtonMain)`
  margin: 0 !important;
  .MuiButton-root {
    margin: 0 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchContainer= styled.div`
div {height: 40px!important}`; //Sandeep EA-323
interface Props {
  onAdd?: () => void;
  onSearch?: (value: string) => void;
  onEmergencySortChange?: (value: string) => void;
  sendInspectionsEmail?: () => void;
  buttonTitle?: string;
  isBreadCrumbsVisible?: boolean;
  isActionPanelMoreVisible?: boolean;
  className?: string;
  addTicket?: boolean;
  isAllActivities?: boolean;
  isInspectionsPath?: boolean;
  emergencySort?: any;
  emergencyOptions?: Array<Object>;
  selectedInspections?: Array<Object>;
}

export default ActionsPanel;
