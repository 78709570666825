import React from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { DashboardTableRecord } from '../../../../types';
import Table2, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';
import { setSelectedPermitTicket } from '../../../../../../redux/slices/ticketsSlice';

const TicketsTable = () => {
  const records = useSelector((s: RootState) => s.tickets.permitTicketsList);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClick = (id: number) => {
    const _selected = records.find(r => r.permitId === id);
    dispatch(setSelectedPermitTicket(_selected));
    history.push(`${ROUTES.TICKETS}?openDrawer=true`);
  }
  return (
    <Table2 headers={DashboardTableHeaders('Tickets')} records={records.slice(0, 5)} iconTitle="Tickets" onClick={onClick}>
      <ReceiptIcon />
    </Table2>
  );
};

export default TicketsTable;
