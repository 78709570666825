import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

const Link = styled(ReactRouterLink)`
  letter-spacing: 0.021px;
  width: max-content;
  cursor: pointer;
`;

export default Link;
