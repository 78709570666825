import React, { useState } from 'react';
import AddComment from '@material-ui/icons/AddComment';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import GeneralTextField from '../../Common/Controls/GeneralTextField';
import { useForm } from 'react-hook-form';
import schema from './schema';
import { Button } from '@material-ui/core';
import { Logo_Sky } from '../../../../../constants/menu';
import { RootState } from '../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { createTicket } from '../../../../../api/tickets';
import { appendTicket, setPermitTicketsList } from '../../../../../redux/slices/ticketsSlice';

type FormValue = {
  subject: string;
  description: string;
};
const AddTicketBox = () => {
  const [showControls, setShowControls] = useState<boolean>(true);
  const permitTicketsList = useSelector((s:RootState) => s.tickets.permitTicketsList);
  const selectedPermitTicket = useSelector((s: RootState) => s.tickets.selectedPermitTicket);
  const dispatch = useDispatch();
  const info = useSelector((s: RootState) => s.userAuth.info);
  const { handleSubmit, register, setValue, errors } = useForm<FormValue>({
    resolver: yupResolver(schema),
    defaultValues: {} as FormValue,
  });

  const onSubmit = async (data: FormValue) => {
    const payload = {
      contact: info.id,
      description: data.description,
      subject: data.subject,
      permit: selectedPermitTicket.permitId,
    };
    //@ts-ignore
    const result = await createTicket(payload);

    //@ts-ignore
    dispatch(appendTicket(result.data));

    const _permitTicketsList = permitTicketsList.map(item => {
      if(item.permitId === selectedPermitTicket.permitId) {
        return {
          ...item,
          quantity: item.quantity + 1
        }
      }
      return item; 
    })
    dispatch(setPermitTicketsList(_permitTicketsList))
    // setShowControls(false);
    setValue('subject','');
    setValue('description','');
  };
  return (
    <>
      {false && <AddCommentWrapper onClick={e => setShowControls(true)}>
        <AddComment />
        <span>Add Ticket</span>
      </AddCommentWrapper>}
      <NewCommentWrapper showControls={showControls}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralTextField name="subject" label="Subject" errors={errors} register={register} />
          <GeneralTextField name="description" label="Description" errors={errors} register={register} />
          <Buttons>
            <Button variant="contained" type="submit">
              Add
            </Button>
            {false && <Button variant="contained" onClick={() => setShowControls(false)}>
              Close
            </Button>}
          </Buttons>
        </form>
      </NewCommentWrapper>
    </>
  );
};

export default AddTicketBox;

const NewCommentWrapper = styled.div<{ showControls: boolean }>`
  width: 60%;
  height: ${({ showControls }) => (showControls ? '255px' : '0')};
  border: ${({ showControls }) => (showControls ? '1px solid grey' : 'none')};
  border-radius: 8px;
  padding: 15px;
  margin-top: ${({ showControls }) => (showControls ? '21px' : '0')};
  margin-bottom: ${({ showControls }) => (showControls ? '21px' : '0')};
  overflow: hidden;
  transition: all 0.8s ease-in-out;
  @media only screen and (max-width: 840px) {
     width: 100%;
  }
`;

const AddCommentWrapper = styled.div`
  display: flex;
  width: 109px;
  justify-content: space-between;
  margin-top: 17px;
  span {
    color: ${Logo_Sky};
  }
  cursor: pointer;
`;

const Buttons = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  width: 160px;
`;
