import * as React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core';

import { TextInput, LabelForm } from '../atoms';
import ErrorMessages from './ErrorMessages';

const TextInputWithAdornment = ({
  isRequired,
  label,
  error,
  children,
  ...props
}: Props) => {
  const { id, name } = props;

  return (
    <InputContainer>
      <LabelForm
        htmlFor={id || name}
        isRequired={isRequired}
      >
        {label}
      </LabelForm>
      <TextAdornmentInputContainer>
        <StyledTextInput
          id={id || name}
          fullWidth
          required={isRequired}
          error={!!error}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <AdornmentContainer>{children}</AdornmentContainer>
      </TextAdornmentInputContainer>
      {error && <ErrorMessages error={error} />}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
  }
`;

const TextAdornmentInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const AdornmentContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
`;

const StyledTextInput = styled(TextInput)`
  fieldset {
    height: 40px;
    top: 0;
  }

  input {
    border-radius: 1px;
    padding: 0 16px;
    top: 0;
    height: 40px;
  }

  .PrivateNotchedOutline-root-2 {
    top: 0;
  }
`;

type Props = Omit<TextFieldProps, 'error' | 'variant'> & {
  isRequired?: boolean;
  error?: FieldError;
  children?: React.ReactNode;
};

export default TextInputWithAdornment;
