import styled from 'styled-components';

import { Avatar } from '@material-ui/core';

const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
`;

export default StyledAvatar;
