import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/root';
import { setFormData, setSelectedInspection, setShowPopup } from '../../../../redux/slices/inspectionsSlice';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { labels } from './constants';
import useLoadInspectionEditor from './useLoadInspectionEditor';
import ActivityTitle from './Controls/ActivityTitle';
import ActivityStatus from './Controls/ActivityStatus';
import Contact from './Controls/Contacts';
import ReportRequiredMessage from './Controls/ReportRequiredMessage';
import InspectionCompleteConfirmation from './MessagePopups/InspectionCompleteConfirmation';
import MaskedPhone from './Controls/MaskedPhone';
import InspectionTabs from './Controls/InspectionTabs';
import StaticField from './Controls/StaticField';
import InspectionType from './Controls/InspectionType';
import DatePicker from './Controls/DatePicker';
import Period from './Controls/Period';
import Information from './Controls/Information';
import ReportCreator from './Controls/ReportCreator';
import CommentsChat from './Controls/CommentsChat';
import InspectionSaving from './MessagePopups/InspectionSaving';
import { closeInspection, getFieldsForInspection } from '../Utils';
import ContactsPopup from './MessagePopups/ContactsPopup';
import { format } from 'date-fns';
import { updatePermitInspection } from '../../../../api/inspections';

const InspectionEditor:FC<{loadData?:Function}> = ({loadData}) => {
  const modalOpen = useSelector((s: RootState) => s.inspections.modalOpen);
  const { selectedInspection, showPopup, disabledGlobalSearch } = useSelector((s: RootState) => s.inspections);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [reportPdf, setReportPdf] = useState<any>();
  const [pdfObject, setPdfObject] = useState<any>();
  const [inspectionReport, setInspectionReport] = useState<{ name?: string }>({});
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [originalStatusName, setOriginalStatusName] = useState('');
  const [manager, setManager] = useState('');
  const [showReportError, setShowReportError] = useState(false);

  const toDateUTC = selectedInspection.id ? new Date(selectedInspection.inspectionDate) : '';
  const followUpUTC = selectedInspection.id ? new Date(selectedInspection?.followUp) : '';
  const { register, handleSubmit, errors, setError, clearErrors, control, setValue, watch, getValues } = useForm({
    defaultValues: {
      ...selectedInspection,
      toDate: toDateUTC, // ? format(toDateUTC,'MM/dd/yyyy') : '',
      followUp: followUpUTC, // ? format(followUpUTC,'MM/dd/yyyy') : '',
    },
  });
  const selectedTitle = watch('activityTitle');
  const information = watch('information');
  const activityStatus: { name: string } = watch('activityStatus');
  useEffect(() => {
    if (selectedInspection.managerFirstName) {
      setManager(`${selectedInspection.managerFirstName} ${selectedInspection.managerLastName}`);
    }
  }, [selectedInspection]);

  useEffect(() => {
    if (activityStatus) {
      clearErrors('information');
      setShowReportError(false);

      switch (activityStatus.name) {
        case 'Partial Pass':
        case 'Cancelled':
        case 'Failed':
          if (!information.trim()) {
            setDisabledSubmit(true);
            setError('information', { message: '*' });
          }
          if (!inspectionReport?.name) {
            setDisabledSubmit(true);
            setShowReportError(true);
          } else {
            setDisabledSubmit(false);
          }
          break;
        default:
          setDisabledSubmit(false);
          break;
      }
      if (!originalStatusName) {
        setOriginalStatusName(selectedInspection.statusName);
      }
      dispatch(
        setSelectedInspection({
          ...selectedInspection,
          statusName: activityStatus.name,
        }),
      );
    }
  }, [activityStatus, information, inspectionReport]);

  useLoadInspectionEditor({ selectedTitle });

  const onSubmit = async (data: any) => {
    if (originalStatusName !== selectedInspection.statusName) {
      dispatch(setFormData(data));
      if (data.activityStatus.name === 'Scheduled') {
        dispatch(setShowPopup('saving'));
      } else {
        dispatch(setShowPopup('confirmation'));
      }
    } else {
      const payload = getFieldsForInspection(selectedInspection, data);
      const result = await updatePermitInspection(payload);
      closeInspection(dispatch);
      if(loadData){
            loadData();
      }
  
    }
  };
  return (
    <div>
      <Dialog open={modalOpen} fullWidth={true} maxWidth={'lg'} keepMounted aria-describedby="alert-dialog-slide-description">
        <PopupWrapper>
          {showPopup === 'confirmation' && <InspectionCompleteConfirmation />}
          {showPopup === 'saving' && <InspectionSaving inspectionReport={inspectionReport} reportPdf={reportPdf} pdfObject={pdfObject} />}
          {showPopup === 'contacts' && <ContactsPopup />}
          <InspectionTabs value={tab} setValue={setTab} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Update Inspection</DialogTitle>
            <DialogContent>
              <TabPage tab={tab} index={0}>
                <ControlsWrapper>
                  <DoubleRow>
                    <StaticField label={'Project Name'} value={selectedInspection.projectName} />
                    <StaticField label={'Permit Name'} value={selectedInspection.permitName} />
                  </DoubleRow>
                  <DoubleRow>
                    <StaticField label={'Permit Number'} value={selectedInspection.permitNumber} />
                    <StaticField label={'Jurisdiction'} value={selectedInspection.projectJurisdiction} />
                  </DoubleRow>
                  <DoubleRow>
                    <StaticField label={'Project Manager'} value={manager} />
                    <StaticField label={'Inspection Number'} value={selectedInspection.id} />
                  </DoubleRow>
                  <StaticField label={'Address'} value={selectedInspection.projectAddress} />
                  <EditableControlsWrapper>
                    <ActivityTitle control={control} errors={errors} setValue={setValue} />
                    <InspectionType getValues={getValues} setDisabledSubmit={setDisabledSubmit} control={control} errors={errors} setValue={setValue} />

                    <DatePicker name="toDate" control={control} errors={errors} label={labels.inspectionDate} />
                    <div>
                      <Period control={control} errors={errors} setValue={setValue} />
                    </div>
                    <Information register={register} errors={errors} />
                    <ActivityStatus control={control} errors={errors} setValue={setValue} />
                    <Contact control={control} errors={errors} setValue={setValue} />
                    <MaskedPhone control={control} errors={errors} name="cellPhone" setValue={setValue} />
                    <MaskedPhone control={control} errors={errors} name="phoneNumber" setValue={setValue} />
                  </EditableControlsWrapper>
                  <ReportCreator
                    setPdfObject={setPdfObject}
                    setReportPdf={setReportPdf}
                    activityStatus={activityStatus}
                    setInspectionReport={setInspectionReport}
                    getValues={getValues}
                    watch={watch}
                  />
                  <ReportRequiredMessage showReportError={showReportError} />
                </ControlsWrapper>
              </TabPage>
              <TabPage tab={tab} index={1}>
                <CommentsWrapper>
                  <CommentsChat noFile title={'Comments List'} buttonText="Add Comment" />
                </CommentsWrapper>
              </TabPage>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" style={{ width: '200px' }} onClick={() => closeInspection(dispatch)}>
                Cancel
              </Button>
              <Button
                disabled={disabledSubmit || disabledGlobalSearch}
                variant="contained"
                color="primary"
                style={{ width: '200px' }}
                type="submit"
              >
                Update
              </Button>
            </DialogActions>
          </Form>
        </PopupWrapper>
      </Dialog>
    </div>
  );
};

export default InspectionEditor;

const DoubleRow = styled.div`
  display: flex;
  gap: 90px;
`;

const PopupWrapper = styled.div`
  position: relative;
  height: 90vh;
  .MuiDialogTitle-root {
    padding: 0px 24px;
  }
`;
const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  .react-select__menu {
    height: 120px;
    overflow: auto;
  }
`;

const EditableControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 25px;
`;

const DialogContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabPage = styled.div<{ tab: number; index: number }>`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  height: ${({ tab, index }) => (tab === index ? '70vh' : '0')};
  /* height: ${({ tab, index }) => (tab === index ? 'inherit' : '0')}; */
`;

const CommentsWrapper = styled.div`
  position: relative;
  top: 0;
  margin: 26px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
