import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import DrawerContent from '../../Common/Containers/DrawerContent';
import ConditionsTable from './ConditionsTable';
import ConditionItems from './ConditionItems';
import useCloseDrawers from '../../../hooks/useCloseDrawers';
import useShowDetailsFromDashboard from '../../../hooks/useShowDetailsFromDashboard';

const Conditions = () => {
  useCloseDrawers();
  useShowDetailsFromDashboard();
  return (
    <ClientContainer>
      <BannerStrip label="CONDITIONS"></BannerStrip>
      <WrapperWithRightDrawer>
        <ConditionsTable />
        <Drawer>
          <DrawerContent>
            <ConditionItems />
          </DrawerContent>
        </Drawer>
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default Conditions;
