import React, { FC, useEffect, useState } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import _ from 'lodash';
import { Required, RequiredWrapper, TextLabel } from '../../pages/Dashboard/NewProject/ProjectControlsArea';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

type Props = {
  register: any;
  errors: any;
  control: any;
  dropdownItems: any[];
  name: string;
  label?: string;
  externalValue?: any;
  setValue?: Function;
  trigger?: Function;
  width?: number;
};

const Dropdown: FC<Props> = ({ register, errors, control, setValue, dropdownItems, name, label, externalValue, trigger, width }) => {
  const [selectedItem, setSelectedItem] = useState<any>();
  const { franchises, managers } = useSelector((s: RootState) => s.projectsSlice);

  const handleChange = event => {
    const selectedName = event.target.value;
    const selectedItem = dropdownItems.find(item => item.name === selectedName);
    setSelectedItem(selectedItem);
  };

  return (
    <ClientTypeWrapper width={width}>
      <FormControl fullWidth>
        <InputLabel
          style={{
            paddingLeft: '15px',
            top: '16px',
            position: 'relative',
          }}
          id="demo-simple-select-label"
        >
          <TextLabel isError={errors[name] !== undefined}>{label ? label : _.upperFirst(name)}</TextLabel>
        </InputLabel>

        <Controller
          as={Select}
          control={control}
          inputRef={register}
          name={name}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={''}
          label={label}
          variant="outlined"
          onChange={handleChange}
          error={errors[name] !== undefined}
        >
          {dropdownItems.map((item, index) => (
            <MenuItem value={item.id} key={index}>
              {item.title}
            </MenuItem>
          ))}
        </Controller>
        {errors[name] !== undefined && <ErrorMessage>{errors[name] ? errors[name].message : ''}</ErrorMessage>}
      </FormControl>
    </ClientTypeWrapper>
  );
};

export default Dropdown;

const ClientTypeWrapper = styled.div<{ width?: number }>`
  margin-top: 20px;
  width: ${({ width }) => (width ? `${width}%` : 'inherit')};
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 11px;
  margin-top: 3px;
`;
