import * as React from 'react';

import useTablePage from '../hooks/useTablePage';

import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import ModalInfo from '../molecules/ModalInfo';
import TicketsForm from './TicketsForm';
import { updateTicket } from '../api/tickets';

const TicketsEditForm = ({
  initialValues,
  handleCloseInfoModal,
  permit,
  project,
  setInitialValues,
}: Props) => {    
    
  const {
    modalSuccess,
    modalError,
    createOrUpdate,
    modalTitle,
    errors,
    values,
    setValues,
    modalErrorTitle,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage({
    updateEndpoint: updateTicket,
  });

  React.useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const handleCloseActivitiesModal = () => {
    if (!modalError && !modalSuccess) {
      setInitialValues(null);
      handleCloseModal();
    }
  };

  return (
    <>
      {values ? (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseActivitiesModal}
        >
            <TicketsForm
            permit={permit}
            projectId={project}
            initialValues={values}
            initialErrors={errors}
            isModalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      ) : null}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={() =>
            handleCloseInfoModal(handleCloseModalInfo)
          }
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  initialValues: object;
  handleCloseInfoModal: (data: () => void) => void;
  permit: number;
  project: number;
  setInitialValues: (data: any) => void;
}

export default TicketsEditForm;
