import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { ROUTES } from '../../constants/routes';
import { Loader } from '../../atoms';
import isRoutePathExact from '../../utils/isRoutePathExact';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import convertObjToRouteObj from '../../utils/convertObjToRouteObj';
import FeesTable from '../../organisms/Fees/FeesTable';
import { FeesButtonTypeLink } from '../../api/fees';
import { getVocabulariesDataList } from '../../api/vocabulary';

import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import SubMenuHeadLine from '../../organisms/SubMenuHeadLine';

const FeesPage = () => {
  const { pathname } = useLocation();
  const { push }: History = useHistory();

  const [routes, setRoutes] = React.useState<
    FeesButtonTypeLink[]
  >(null);

  const [fees, setFees] = React.useState<
    FeesButtonTypeLink
  >(null);

  React.useEffect(() => {
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 4,
      ordering: 'title',
    }).then(response => {
      setRoutes(
        convertObjToRouteObj(response.data.results) as any,
      );
    });
  }, []);

  React.useEffect(() => {
    if (routes && ROUTES.SETTINGS_FEES === pathname) {
      setFees(routes[0]);
      return push(`${pathname}/${routes[0].linkTo}`);
    }
    if (
      routes &&
      isRoutePathExact(pathname, ROUTES.SETTINGS_FEES)
    ) {
      const pathArr = pathname.split('/');
      const searchPathObj = routes.find(
        el => el.linkTo === pathArr[pathArr.length - 1],
      );
      return setFees(searchPathObj);
    }
    return setFees(null);
  }, [routes, pathname]);

  return (
    <DashboardLayout>
      {routes && fees ? (
        <>
          <ActionsPanel />
          <StyledSubMenuHeadLine
            menuLinks={routes as any}
            basePath={ROUTES.SETTINGS_FEES}
          />
          <Container>
            <FeesTable feesQuery={fees} />
          </Container>
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSubMenuHeadLine = styled(SubMenuHeadLine)`
  padding: 16px 0;
  zoom: 0.9;
`;

export default FeesPage;
