import * as React from 'react';
import styled from 'styled-components';
import { PermitType } from '../api/permits';
import { InputSelect } from '../molecules';
import PermitProcessBlock from './PermitProcessBlock/PermitProcessBlock';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '240px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};

const ActionPanelPermit = ({
  permits,
  permitSelect,
  selectedPermit,
  processes,
  currentStatus,
}: Props) => {
  const selectedId = selectedPermit && selectedPermit.id;
  const defaultPermit = permits?.find(
    el => el.id === selectedId,
  );
  return (
    <Container>
      <Part>
        <InputSelect
          placeholder="Select Permit"
          isSearchable={false}
          styles={selectStyles}
          defaultValue={defaultPermit as any}
          onChange={permitSelect as any}
          value={selectedPermit as any}
          options={permits as any}
          getOptionValue={(item: any) =>
            item.id || Math.random()
          }
          getOptionLabel={(item: any) => item.name}
          defaultStyles
        />
      </Part>
      <PermitProcessBlock
        processes={processes}
        currentStatus={currentStatus}
      />
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
`;

const Part = styled.div`
  width: 200px;
`;

interface Props {
  permits: Array<PermitType>;
  permitSelect: (data: PermitType) => void;
  selectedPermit: PermitType;
  processes: Process[];
  currentStatus: number;
}

interface Process {
  title: string;
  subProcesses: SubProcess[];
  statuses: number[];
}

interface SubProcess {
  title: string;
  id: number;
}

export default ActionPanelPermit;
