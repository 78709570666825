import * as React from 'react';
import styled from 'styled-components';

import { getFranchiseRevenue } from '../../api/dashboard';

const IncomeBlock = () => {
  const [incomeData, setIncomeData] = React.useState<
    IncomeData
  >();

  React.useEffect(() => {
    getFranchiseRevenue().then(response => {
      setIncomeData(response.data);
    });
  }, []);

  return (
    <Wrapper>
      <Header>Income</Header>
      {incomeData && (
        <Content>
          <RevenueBlock>
            <RevenueItem>
              <h4>Proposal</h4>
              <div style={{ backgroundColor: '#FF9441' }}>
                {`$${incomeData?.projectedSales}`}
              </div>
            </RevenueItem>
            <RevenueItem>
              <h4>Month</h4>
              <div style={{ backgroundColor: '#067775' }}>
                {`$${incomeData?.salesPerMonth}`}
              </div>
            </RevenueItem>
            <RevenueItem>
              <h4>This Year</h4>
              <div style={{ backgroundColor: '#832C96' }}>
                {`$${incomeData?.salesPerYear}`}
              </div>
            </RevenueItem>
          </RevenueBlock>

          <ColumnBlock>
            Today&apos;s Followup:{' '}
            <span>{incomeData?.dueTodayActivities}</span>
          </ColumnBlock>
          <ColumnBlock>
            Total Number Of Pending Activity:
            <span>{incomeData?.pendingActivities}</span>
          </ColumnBlock>
        </Content>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 6px;
  width: 460px;
  height: 340px;
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-bottom: 20px;
  flex: 1;
  @media (max-width: 840px) {
    width: 100%;
  }
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-bottom: 10px;
`;

const Content = styled.div`
  height: 280px;
`;

const RevenueBlock = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ababab;
  padding: 10px;
  padding-bottom: 40px;
  margin-bottom: 20px;
`;

const RevenueItem = styled.div`
  & h4 {
    margin: 16px 0;
    text-align: center;
  }
  & div {
    width: 100px;
    word-wrap: break-word;
    background-color: green;
    padding: 10px 0px;
    text-align: center;
    color: #fff;
    border-radius: 7px;
    font-size: 14px;
  }
`;

const ColumnBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  & span {
    color: var(--primary);
  }
`;

interface IncomeData {
  pendingActivities: number;
  dueTodayActivities: number;
  salesPerYear: number;
  salesPerMonth: number;
  projectedSales: number;
}

export default IncomeBlock;
