import * as React from 'react';
import styled from 'styled-components';
import { ModalDefaultDraggable } from '../molecules';
import { FormSetupType } from '../api/formSetup';
import ViewFormSetup from './ViewFormSetup';

const ViewFormSetupModal = ({
  isModalOpen,
  initialValues,
  onClose,
}: Props) => {
  return (
    <div>
      {isModalOpen && (
        <StyledModalDraggable
          onClose={onClose}
          title={initialValues?.name}
        >
          <ViewFormSetup initialValues={initialValues} />
        </StyledModalDraggable>
      )}
    </div>
  );
};

interface Props {
  isModalOpen: boolean;
  initialValues: FormSetupType;
  onClose: () => void;
}

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default ViewFormSetupModal;
