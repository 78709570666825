import * as React from 'react';
import {
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import styled from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton } from '../atoms';
import { setSearchToken } from '../redux/slices/filterSlice';
import { useDispatch } from 'react-redux';

const SearchPanel = ({
  placeholder = 'Search...',
  className,
  setSearchValue,
}: Props) => {
  const [value, setValue] = React.useState<string>('');
  const dispatch = useDispatch();
  const localSetValue = (token:string) => {
    setValue(token);
    dispatch(setSearchToken(token));
  };
  
  React.useEffect(()=> {
    const searchData = localStorage.getItem('search')
    if(searchData) {
      setValue(searchData)
      setSearchValue(searchData)
    }
  }, [])
  return (
    <Search
      value={value}
      className={className}
      onChange={e => localSetValue(e.target.value)}
      onKeyDown={e =>
        e.key === 'Enter' ? setSearchValue(value) : null
      }
      placeholder={placeholder}
      endAdornment={
        <InputAdornment position="end">
          {value && (
            <IconButton
              size="small"
              edge="end"
              onClick={() => {setValue(''); setSearchValue(null);}}
            >
              <CloseIcon />
            </IconButton>
          )}
          <IconButton
          
            size="medium"
            edge="end"
            onClick={() =>
              value ? setSearchValue(value) : null
            }
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const Search = styled(OutlinedInput)`
  min-height: 30px;
  background-color: var(--defaultBackground);
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 6px 14px 7px 14px;
  }
`;

interface Props {
  placeholder?: string;
  className?: string;
  setSearchValue: (data: string) => void;
}

export default SearchPanel;
