import { capitalCase } from 'change-case';

import { FRANCHISE_TYPE_DATA } from '../__DATA__';
import formatDate from './formatDate';
import { USER_TYPES_VALUES } from '../constants/forms';
import { DEFAULT_DATETIME_FORMAT } from '../constants';

export const convertTableBoolean = (value: any): string => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  return value;
};

export const convertTableFranchiseBoolean = (
  value: any,
): string => {
  if (typeof value === 'boolean') {
    return value ? 'Linked' : 'Unlinked';
  }

  return value;
};

export const convertTableUserRole = (
  value: string,
  column: string,
): string => {
  if (column === 'userRole') {
    return USER_TYPES_VALUES[value]
      ? capitalCase(USER_TYPES_VALUES[value])
      : value;
  }

  return value;
};

export const convertTableAmountToCurrency = (
  value: string,
  column: string,
) => {
  if (column === 'amount' || column === 'total') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(+value);
  }
  if (column === 'isPaid') {
    if (value) {
      return 'Yes';
    }
    if (!value) {
      return 'No';
    }
  }

  return value;
};

export const convertTableFranchiseType = (
  value: string,
  column: string,
) => {
  if (column === 'franchiseType') {
    return (
      FRANCHISE_TYPE_DATA.filter(el => {
        return el.value === value;
      })[0]?.label || value
    );
  }

  if (column === 'yearlyGoal') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(+value);
  }

  return value;
};

export const convertTableUTCDate = (
  value: string,
  column: string,
) => {
  if (column === 'publicationDate') {
    const date = new Date(value);

    if (
      date instanceof Date &&
      !Number.isNaN(date.getTime())
    ) {
      return formatDate(date, DEFAULT_DATETIME_FORMAT);
    }

    return value;
  }

  return value;
};
