import * as React from 'react';
import styled from 'styled-components';
import { getRunnerReport } from '../../api/reports';
import {
  DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const RunnerReport = React.forwardRef(
  ({ jurisdiction, setIsValidForExport }: Props, ref) => {
    const [
      isNullInspections,
      setIsNullInspections,
    ] = React.useState(false);

    const [
      franchiseName,
      setFranchiseName,
    ] = React.useState('');
    const [
      jurisdictionName,
      setJurisdictionName,
    ] = React.useState('');

    const [infoData, setInfoData] = React.useState(null);

    const getModifiedRunnerReports = () =>
      Promise.all([
        getRunnerReport({ jurisdiction: jurisdiction.id }),
      ])
        .then(([runnerResponse]) => {
          if (runnerResponse.data.length === 0) {
            setIsNullInspections(true);
            setIsValidForExport(false);
          } else {
            setIsValidForExport(true);
            setIsNullInspections(false);
            setFranchiseName(
              runnerResponse.data[0].franchiseName,
            );
            setJurisdictionName(
              runnerResponse.data[0].jurisdictionName,
            );
            const data = runnerResponse.data.map(el => {
              return {
                id: el.id,
                information: el.information,
                lastComment: el.lastComment,
              };
            });

            setInfoData({ data, isDailyInspection: false });
          }

          return {
            ...runnerResponse,
            data: {
              ...runnerResponse.data,
              results: runnerResponse.data.map(el => {
                return {
                  ...el,
                  projectNamePermitName: `${el.projectName} | ${el.permitName}`,
                  correctFollowUp: formatDateTime(
                    el.followUp,
                    DEFAULT_DATE_FORMAT,
                  ),
                };
              }),
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});

    const columns = column => {
      if (
        [
          'franchiseName',
          'jurisdictionName',
          'permitName',
          'projectName',
          'followUp',
          'lastComment',
          'information',
          'id',
        ].includes(column)
      ) {
        return null;
      }

      switch (column) {
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';
        case 'activityTitle':
          return 'Activity Title';
        case 'correctFollowUp':
          return 'Follow Up Date';
        case 'jurisdictionName':
          return 'Jurisdiction';
        case 'permitNumber':
          return 'Permit Number';
        case 'projectAddress':
          return 'Project Address';
        case 'projectManager':
          return 'Project Manager';

        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
      'projectNamePermitName',
      'projectAddress',
      'permitNumber',
      'activityTitle',
      'correctFollowUp',
      'projectManager',
      'information',
    ];

    if (isNullInspections) {
      return (
        <InfoText>
          No project was found with selected Jurisdiction.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Runner Report</Title>

        <List>
          <li>
            <Bold>Franchise Name: </Bold>
            {franchiseName}
          </li>
          <li>
            <Bold>Jurisdiction: </Bold>
            {jurisdictionName}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedRunnerReports}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={infoData}
          isRunnerCheckbox
        />
      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: black;
  font-weight: bold;
`;

const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

interface Props {
  jurisdiction: object;
  setIsValidForExport: (boolean) => void;
}

export default RunnerReport;
