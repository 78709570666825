import * as React from 'react';
import styled from 'styled-components';
import {
  // eslint-disable-next-line import/no-named-default
  default as RCPagination,
  PaginationProps,
} from 'rc-pagination';
import locale from 'rc-pagination/lib/locale/en_US';

import 'rc-pagination/assets/index.css';

const Pagination = (props: PaginationProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledPagination locale={locale} {...props} />
);

const StyledPagination = styled(RCPagination)`
  .rc-pagination-item,
  .rc-pagination-item-link {
    border: none;
    outline: none;
  }

  .rc-pagination-item-active {
    border: none;

    a {
      color: var(--primary);
      font-weight: bold;
    }
  }
`;

export default Pagination;
