import React from 'react';
import styled from 'styled-components';

const AdditionalField = ({ caption, value }) => {
  return (
    <Wrapper>
      <Caption>{caption}:</Caption>
      {value}
    </Wrapper>
  );
};

export default AdditionalField;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-wrap: nowrap;
  font-size: 1rem;
`;

const Caption = styled.div`
font-weight: bold;
`;
