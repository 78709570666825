import React from 'react';
import PopupMessage from './PopupMessage';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { persistInspectionData } from '../../Utils';
import { setShowPopup } from '../../../../../redux/slices/inspectionsSlice';
import styled from 'styled-components';

const InspectionCompleteConfirmation = () => {
  const { formData, selectedInspection } = useSelector((s: RootState) => s.inspections);
  const dispatch = useDispatch();
 
  return (
    <PopupMessage caption="Confirmation">
      <p>This will send inspection completed message to the inspection contact. Do you want to continue?</p>
      <Buttons>
        <Button variant="contained" color="primary" style={{ width: '200px' }} onClick={() => dispatch(setShowPopup('none'))}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ width: '200px' }}
          onClick={() => {
            dispatch(setShowPopup('saving'));
          }}
        >
          Ok
        </Button>
      </Buttons>
    </PopupMessage>
  );
};

export default InspectionCompleteConfirmation;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
`;
