import * as React from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

import theme from '../../theme';
import { FranchiseType } from '../../api/franchise';
import { SelectInputWithSelectAll } from '../../atoms';
import { convertDataKeysToPieValues } from '../../utils/convertWidgetValues';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const percentFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
});

const LegendItem = ({
  color,
  name,
  value,
}: LegendItemProps) => (
  <LegendItemContainer color={color}>
    <span>{name}</span>
    <span>
      <strong>{value}</strong>
    </span>
  </LegendItemContainer>
);

const FranchiseIncomeChartWidget = ({
  data,
  franchises,
  onSelectChange,
}: Props) => {
  const achievedPercentage = data
    ? percentFormat.format(data.sales / data.projectedSales)
    : 0;

  return (
    <Container>
      <Header>Branch Income Chart</Header>
      <Header>Current month</Header>
      {franchises && franchises.length && (
        <SelectContainer>
          <SelectInputWithSelectAll
            data={franchises}
            valueKey="id"
            labelKey="name"
            onMenuClose={onSelectChange}
            placeholder="Select branches from the list..."
          />
        </SelectContainer>
      )}
      {data && (
        <Content>
          <Pie>
            <ResponsivePie
              innerRadius={0.6}
              isInteractive={false}
              enableRadialLabels={false}
              colors={[theme.marker1, theme.marker2]}
              sliceLabel={v =>
                currencyFormat.format(+v.value)
              }
              data={convertDataKeysToPieValues(
                data,
                'total',
              )}
            />
            <PieTotal>
              {Number.isNaN(achievedPercentage)
                ? 0
                : achievedPercentage}
            </PieTotal>
          </Pie>
          <Legend>
            <LegendItem
              color={theme.marker1}
              name="Sales"
              value={currencyFormat.format(+data.sales)}
            />
            <LegendItem
              color={theme.marker2}
              name="Projected Sales"
              value={currencyFormat.format(
                +data.projectedSales,
              )}
            />
            <LegendItem
              name="Achieved Percentage"
              value={
                Number.isNaN(achievedPercentage)
                  ? 0
                  : achievedPercentage
              }
            />
          </Legend>
        </Content>
      )}
    </Container>
  );
};

const Container = styled.div``;

const SelectContainer = styled.div`
  margin: 16px 0;
`;

const Pie = styled.div`
  position: relative;
  width: 100%;
  height: 420px;
`;

const PieTotal = styled.div`
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  font-size: 1.125rem;
  text-align: center;
  font-weight: bold;
`;

const Content = styled.div`
  padding: 24px 0;
`;

const Legend = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 36px auto 0 auto;
`;

const LegendItemContainer = styled.div<{ color?: string }>`
  margin: 0 0 24px 0;

  &:before {
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({ color }) => color || 'gray'};
    position: relative;
    top: 2px;
    margin: 0 8px 0 0;
    opacity: ${({ color }) => (color ? 1 : 0)};
  }

  > span {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

interface Props {
  data: any;
  franchises: FranchiseType[];
  onSelectChange?: (
    selected: any[] | null,
    isCanceled: boolean,
  ) => void;
}

interface LegendItemProps {
  name: string;
  color?: string;
  value: string | number;
}

export default FranchiseIncomeChartWidget;
