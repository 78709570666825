import * as React from 'react';
import styled from 'styled-components';

import { ButtonMain } from '../atoms';
import ModalDefaultDraggable from './ModalDefaultDraggable';

const ModalActionDialog = ({
  title = 'Are you sure you want to delete?',
  buttonFirstTitle = 'Save',
  buttonFirstDisable = false,
  buttonSecondTitle = `Don't save`,
  actionFirst,
  actionSecond,
  onCancel,
  children,
  className,
  centered,
  noCancel,
}: Props) => {
  const [
    disabledAction,
    setDisabledAction,
  ] = React.useState<boolean>(false);

  const handleButtonClick = actionFn => () => {
    setDisabledAction(true);
    actionFn();
  };

  return (
    <ModalDefaultDraggable
      title={title}
      onClose={onCancel}
      className={className}
      centered={centered}
    >
      <Container>
        {children && <div>{children}</div>}
        <ButtonContainer>
          {actionFirst && (
            <ButtonMain
              color="primary"
              onClick={handleButtonClick(actionFirst)}
              disabled={
                disabledAction || buttonFirstDisable
              }
            >
              {buttonFirstTitle}
            </ButtonMain>
          )}
          {actionSecond && (
            <ButtonMain
              color="default"
              onClick={handleButtonClick(actionSecond)}
              disabled={disabledAction}
            >
              {buttonSecondTitle}
            </ButtonMain>
          )}
          {!noCancel && (
            <ButtonMain
              color="secondary"
              onClick={onCancel}
              disabled={disabledAction}
            >
              Cancel
            </ButtonMain>
          )}
        </ButtonContainer>
      </Container>
    </ModalDefaultDraggable>
  );
};

const Container = styled.div`
  text-align: center;

  > div {
    margin: 0 0 40px 0;
    &:last-child {
      margin: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  > .MuiButton-root {
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

interface Props {
  title?: string;
  children?: React.ReactNode | React.ReactNodeArray;
  buttonFirstTitle?: string;
  buttonFirstDisable?: boolean;
  buttonSecondTitle?: string;
  actionFirst: () => void;
  actionSecond: () => void;
  onCancel: () => void;
  className?: string;
  centered?: boolean;
  noCancel?: boolean;
}

export default ModalActionDialog;
