import React from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { DashboardTableRecord } from '../../../../types';
import Table2, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { setSelectedPermit } from '../../../../../../redux/slices/inspectionsSlice';
import { setShowDrawerContent } from '../../../../../../redux/slices/generalSlice';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';

const InspectionTable = () => {
  const records = useSelector((s:RootState) => s.inspections.permitInspectionsList);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClick = (id: number) => {
    const _selected = records.find(r => r.permitId === id);
    dispatch(setSelectedPermit(_selected));
    history.push(`${ROUTES.INSPECTIONS}?openDrawer=true`);
  }
  return (
    <Table2
      headers={DashboardTableHeaders('Request')}
      records={records.slice(0,5)}      
      iconTitle="Request Inspection"
      onClick={onClick}
     >
        <AssignmentIndIcon />
    </Table2>
  );
};

export default InspectionTable;