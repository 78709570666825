import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateUserInfo,
  userInfoSelector,
  clearRequestError,
  userErrorsSelector,
} from '../redux/ducks/userAuth';
import UserAccountForm from '../forms/UserAccountForm';
import DashboardLayout from '../layouts/DashboardLayout';
import useUserRole from '../hooks/useUserRole';
import { useHistory } from 'react-router';
import { ROUTES } from '../modules/clientModule/components/Routes/ClientRoutes';

const UserAccountPage = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const userErrors = useSelector(userErrorsSelector);
  const { isClient } = useUserRole();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(clearRequestError());
    if( isClient) {
      history.push(ROUTES.ACCOUNT);
    }
      
      // setAccountUrl(ROUTES.ACCOUNT);
      // accountUrl.current = ROUTES.ACCOUNT;
      const x = userInfo;
  }, []);

  const handleOnSettingsChange = formValues => {
    dispatch(updateUserInfo(formValues));
  };

  return (
    <DashboardLayout>
      <UserAccountForm
        initialValues={userInfo}
        initialErrors={userErrors as any}
        onSubmit={handleOnSettingsChange}
      />
    </DashboardLayout>
  );
};

export default UserAccountPage;
