import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../redux/root';
import { Button } from '@material-ui/core';
import { setShowCompleteInspectionsPopup } from '../../redux/slices/dashboardSlice';
import MobileRecord, { FieldData } from '../../pages/Responsive/MobileRecord';
import { Pagination } from '../../modules/clientModule/components/Common/Table/types';
import PaginationMobile from '../../pages/Responsive/PaginationMobile';
import { Loader } from '../../atoms';
import MobileFilter from './MobileFilter';

const BarRecordsPopup = () => {
  const dispatch = useDispatch();
  const { showCompleteInspectionsPopup, selectedMonthYear } = useSelector((s: RootState) => s.dashboard);
  const inspections = useSelector((s:RootState) => s.inspections.inspections);

  const dailyPendingFields: FieldData[] = [
    { label: 'Project Name', key1: 'projectName' },
    { label: 'Permit Number', key1: 'permitNumber' },
    { label: 'Address', key1: 'projectAddress' },
    { label: 'Permit Name', key1: 'permitName' },
    { label: 'Inspection Id', key1: 'id' },
    { label: 'Inspection Type', key1: 'activityTypeName' },
    { label: 'Project Manager', key1: 'managerFirstName', key2: 'managerLastName' },
    { label: 'Jurisdiction', key1: 'projectJurisdiction' },
    { label: 'Project Contact', key1: 'contactFirstName', key2: 'contactLastName' },
    { label: 'Cell phone', key1: 'cellPhone' },
    { label: 'Inspection Date', key1: 'inspectionDate' },
  ];

  const filterMeta = [
    { label: "Search", name: "search" }
  ];

  return (
    <>
      {showCompleteInspectionsPopup && (
        <Backdrop>
          <PopupWrapper>
            <Caption>Inspections for {selectedMonthYear}</Caption>
            <MobileFilter filterMeta={filterMeta} />
            <Recrods>
              {inspections.length === 0 && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              {inspections.map((item, index) => {
                return <MobileRecord item={item} fields={dailyPendingFields} shaded={index % 2 === 0} removeBorder={true} />;
              })}
            </Recrods>
            <PaginationWrapper>
              {inspections.length > 0 && (
                <Pagination>
                  <PaginationMobile showEverywhere={true} />
                </Pagination>
              )}
            </PaginationWrapper>

            <Buttons>
              <Button
                disabled={false}
                variant="contained"
                color="primary"
                style={{ width: '200px' }}
                onClick={() => dispatch(setShowCompleteInspectionsPopup(false))}
              >
                Close
              </Button>
            </Buttons>
          </PopupWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default BarRecordsPopup;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.6);
  z-index: 3;
  top: 0px;
  left: 0;
`;

const PopupWrapper = styled.div`
  width: 80%;
  padding: 10px;
  margin: 10%;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border: 3px solid grey;
  border-radius: 6px;
  box-shadow: 5px 5px 0 1px hsla(0, 0%, 0%, 0.3);
  p {
    margin: 15px 80px;
    line-height: 30px;
  }
`;

const Caption = styled.div`
  font-size: 27px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
`;

const Recrods = styled.div`
  width: 100%;
  height: 464px;
  overflow-y: auto;
  border: 1px solid grey;
`;

const PaginationWrapper = styled.div`
  height: 67px;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  text-align: center;
`;
