import React from 'react';
import styled from 'styled-components';

import {
  ButtonWithSearchResults,
  InputDatepickerRange,
} from '../../molecules';
import {
  SearchTypeList,
  // getSearchAllList,
  getSearchClientsList,
  // getSearchEmployeeList,
  getSearchInspectionsList,
  getSearchPermitsList,
  // getSearchPmList,
  getSearchTicketsList,
  getSearchActivitiesList,
} from '../../api/search';

import {DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS} from '../../constants';
import { setSearchButton } from '../../redux/slices/filterSlice';
import { useDispatch } from 'react-redux';

const buttonValues = [
  [
    {
      id: 1,
      name: 'Inspections',
      keyName: 'inspections',
      apiCallBackFn: getSearchInspectionsList,
    },
    {
      id: 2,
      name: 'Reviews',
      keyName: 'permitActivity',
      apiCallBackFn: getSearchActivitiesList,
    },
  ],
 ];

const ButtonsResultsPanelInspector = ({
  className,
  searchValuesData,
  setEndpoint,
  setActiveTabName,
  setDates,
}: ButtonsResultsPanelTypes) => {
  const [activeButton, setActiveButton] = React.useState<
    string
  >('');
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   if (
  //     searchValuesData &&
  //     Object.keys(searchValuesData).length
  //   ) {
  //     setActiveButton(buttonValues[0][0].keyName);
  //     setEndpoint(buttonValues[0][0].apiCallBackFn);
  //   }
  // }, [searchValuesData]);

  const handleClick = button => {
    setActiveTabName(button.keyName);
    setActiveButton(button.keyName);
    setEndpoint(button.apiCallBackFn);
    dispatch(setSearchButton(button.keyName));
  };

  return (
    <Container className={className}>
      <LeftButtonContainer>
        {buttonValues[0].map(button => (
          <ButtonWithSearchResults
            key={button.id}
            onClick={() => handleClick(button)}
            searchResult={searchValuesData[button.keyName]}
            className={
              button.keyName === activeButton
                ? 'active'
                : ''
            }
          >
            {button.name}
          </ButtonWithSearchResults>
        ))}

        {(activeButton === 'inspections' ||
          activeButton === 'permitActivity') && (
          <StyledInputDatepickerRange
            name="date"
            placeholderText="Select Date"
            dateFormat={DEFAULT_DATE_FORMAT}
            onRangeChange={(start, end) => {
              if (start && end) {
                setDates({ fromDate: start, toDate: end });
              }
            }}
          />
        )}
      </LeftButtonContainer>
    </Container>
  );
};

interface ButtonsResultsPanelTypes {
  className?: string;
  searchValuesData: SearchTypeList | object;
  setEndpoint: (data: any) => void;
  setActiveTabName: (data: any) => void;
  setDates: (data: any) => void;
}

const Container = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .MuiButton-containedPrimary.active {
    background-color: rgba(11, 105, 152, 0.5) !important;
  }
`;

const StyledInputDatepickerRange = styled(
  InputDatepickerRange,
)`
  margin-left: 2px;

  .MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 32px;
  }
`;

const LeftButtonContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  @media only screen and (max-width: 840px) {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: auto;
    grid-gap: 6px;
    width: 100%;
  }
`;

const RightButtonContainer = styled.div`
  display: flex;
`;

export default ButtonsResultsPanelInspector;
