import * as React from 'react';
import styled from 'styled-components';

import { getMessagesList } from '../../api/messages';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import formatDate from '../../utils/formatDate';
import { DEFAULT_DATE_FORMAT } from '../../constants';

import Table from '../Table';

const labels = {
  id: 'id',
  franchiseNames: 'franchiseNames',
  publicationDate: 'publicationDate',
  addressesList: 'addressesList',
  allEmployees: 'All',
  userRole: 'userRole',

  senderName: {
    case: 'addressesList',
    column: 'Addresses List',
  },
  text: { case: 'text', column: 'Text' },
};

const FADailyMessage = () => {
  const getModifiedMessages = params => {
    const today = formatDate(
      new Date(),
      DEFAULT_DATE_FORMAT,
    );
    return Promise.all([
      getMessagesList({ ...params, date: today }),
    ]).then(([messagesList]) => {
      return {
        ...messagesList,
        data: {
          ...messagesList.data,
          results: messagesList.data.results.map(
            message => ({
              ...message,
            }),
          ),
        },
      };
    });
  };
  const getColumns = column => {
    if (
      [
        labels.id,
        labels.franchiseNames,
        labels.publicationDate,
        labels.userRole,
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case labels.senderName.case:
        return labels.senderName.column;
      case labels.text.case:
        return labels.text.column;
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    labels.senderName.case,
    labels.text.case,
  ];

  return (
    <Wrapper>
      <Header>Messages of the Day</Header>
      <Content>
        <Table
          customSearch
          rows={getModifiedMessages}
          columns={getColumns}
          columnsOrder={columnsOrder}
          onEdit={null}
          onConfigure={null}
          onDelete={null}
          additionalActions={null}
        />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 6px;
  width: 460px;
  height: 340px;
  border: 1px solid var(--border);
  border-radius: 5px;
  flex: 1;
  @media (max-width: 840px) {
    width: 100%;
  }
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-bottom: 10px;
`;

const Content = styled.div`
  height: 280px;
  overflow: auto;
`;

export default FADailyMessage;
