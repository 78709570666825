import React from 'react';
import { InputBaseContainer, TextAreaWithLabel } from '../../../../../molecules';
import { labels } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import styled from 'styled-components';

const Information = ({ register, errors }) => {
  const disabledGlobalSearch = useSelector((s: RootState) => s.inspections.disabledGlobalSearch);
  return (
    <InfoWrapper>
      <InputBaseContainer>
        <TextAreaWithLabel
          showInRow={true}
          name="information"
          error={errors.information}
          label={labels.information}
          inputRef={register}
          maxChar={2000}
          disabled={disabledGlobalSearch}
        />
      </InputBaseContainer>
    </InfoWrapper>
  );
};

export default Information;

const InfoWrapper = styled.div`
  grid-column: 1/3;
  label{
    width: 141px;
  }
`;
