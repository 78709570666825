import React, { FC, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { updateFilters } from '../../../../utils';
import { RootState } from '../../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { FilterEntry } from '../../../../../../redux/slices/filterSlice';

type Props = {
    name: string;
}
const CellFilter:FC<Props> = ({name}) => {
    const filters:FilterEntry[] = useSelector((state: RootState) => state.filters.filters);
    const dispatch = useDispatch();
    const [value, setValue] = useState<string>('');
    const onChange = e => {
        setValue(e.currentTarget.value)
       updateFilters(e,filters,dispatch);
    }

    const clearValue = () => {
        const payload = {
            currentTarget: {
                name,
                value:''
            }
        }
        setValue('');
        updateFilters(payload,filters,dispatch);
    }
    return (
        <Wrapper>
            <input name={name} onChange={onChange} value={value} autoComplete="off"/>
            <SearchWrapper> 
                {value.length > 0 && <ClearIcon onClick={clearValue}/>}
                {value.length === 0  && <SearchIcon />}
                
            </SearchWrapper>           
        </Wrapper>
    );
};

export default CellFilter;

const Wrapper = styled.div`
  border: 1px solid grey;
  max-width: 230px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-top: 4px;
    border-radius: 6px;
    margin: 8px;
    position: relative;
    input, input:active, input:focus-visible {
        border: none;
        outline:none;
        width: 85%;
    }
`;

const SearchWrapper = styled.div`
  position: absolute;
  top: 7px;
  right: 4px;
  cursor: pointer;
`;