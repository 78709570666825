export const APPLICATION_NAME = 'EPIC Management Suite';
export const COMPANY_NAME = 'by Elite Permits';

export enum MODULE_NAME {
  SUPER_ADMIN = 'SUPER ADMIN MODULE MANAGEMENT',
  FRANCHISE_ADMIN = 'BRANCH ADMIN MODULE MANAGEMENT',
  EMPLOYEE = 'EMPLOYEE MODULE MANAGEMENT',
  INSPECTOR = 'INSPECTOR MODULE MANAGEMENT', // SANDEEP
}

export const TABLE_DEFAULT_PAGE_SIZES = [10, 25, 50, 100, 200];

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_DATETIME_FORMAT = 'hh:mm a MM/dd/yyyy';
export const DATETIME_FORMAT = 'MM/dd/yyyy, hh:mm a';
export const COMMENTS_DATETIME_FORMAT = 'MM/dd/yyyy, hh:mm:ss a';

export const INVOICES_SEARCH_DATE_FORMAT = 'yyyy-MM-dd';
export const INVOICES_SORT_DATE_FORMAT =
  'MM/dd/yyyy, hh:mm:ss z';

export const MONTH_FULL_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const { MODE } = process.env;

export const GET_ALL_QUERY_PARAMS = {
  offset: 0,
  isActive: true,
  isDeleted: false,
  limit: 100000000,
};

export const DEFAULT_PARAMS_ALL = {
  "isDeleted": false,
  "limit": 200,
  "offset": 0,
  "ordering": null,
  "searchColumn": null,
  "follow_up": null,
  "period": null,
  "activity_status": null,
  "permit_name": null,
  "project_name": null,
  "permit_number": null,
  "jurisdiction_name": null,
  "activity_type": null,
  "activity_title": null,
  "assigned_to": null,
  "phone_number": null,
  "mobile_phone_number": null,
  "inspection_type": null,
  "contact_name": null,
  "inspector_name": null,
  "inspection_number": null,
  "project_address": null,
  "review_type": null,
  "reviewer_name": null,
  "inspection_date": null,
  "invoice_number": null,
  "date_invoiced": null,
  "date_paid": null,
  "invoice": null,
  "attachments": null,
  "email": null,
  "user_name": null,
  "company_name": null,
  "contact_type": null,
  "last_comment": null,
  "fee_name": null,
  "amount": null,
  "fee_service_type": null,
  "quantity": null,
  "total": null,
  "contact_id": null,
  "admin_file_name": null,
  "client_file_name": null,
  "setting_doc": null,
  "document_name": null,
  "completed": null,
  "id": null,
  "createdAt": null,
  "createdBy": null,
  "completedAt": null,
  "completedBy": null,
  "visible_filename": null,
  "information": null,
  "isEmergency": null
}

export const DEFAULT_PARAMS_INSPECTIONS = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Inspection"
}

export const DEFAULT_PARAMS_CALLS = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Activity",
  "activityTypeName": "Calls"
}

export const DEFAULT_PARAMS_FEES = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Activity",
  "activityTypeName": "Fee",
  "search": undefined
}

export const DEFAULT_PARAMS_REVIEWS = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Activity",
  "activityTypeName": "Reviews"
}

export const DEFAULT_PARAMS_CONDITIONS = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Activity",
  "activityTypeName": "Conditions"
}

export const DEFAULT_PARAMS_PROPOSAL = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Activity",
  "activityTypeName": "Proposal"
}

export const DEFAULT_PARAMS_PERMITTING = {
  ...DEFAULT_PARAMS_ALL,
  "eventType": "Activity",
  "activityTypeName": "Permitting",
  "search": undefined
}

