import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DocumentFileItem,
  DocumentConditionItem,
  PercentComplete,
  ReviewCommentType,
  ProjectDocumentEntry,
} from '../../modules/clientModule/types';

export interface DocumentsState {
  AllPermitsWithDocs: DocumentConditionItem[];
  PermitDocuments: DocumentFileItem[];
  SelectedDocument: DocumentFileItem;
  SelectedDocumentPermit: any;
  PercentComplete: PercentComplete;
  OpenedTabIndex: number;
  ReviewComments: ReviewCommentType[];
  ReviewDocuments: ProjectDocumentEntry[];
  filesToUpload: any[];
  permitActivityId?: number;
}

const initialState: DocumentsState = {
  AllPermitsWithDocs: [],
  PermitDocuments: [],
  SelectedDocument: {} as DocumentFileItem,
  SelectedDocumentPermit: {},
  PercentComplete: { percent: 0, completedCount: 0, allCount: 0, rejectCount: 0 },
  OpenedTabIndex: 1,
  ReviewComments: [],
  ReviewDocuments: [],
  filesToUpload: [],
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments: (state, action: PayloadAction<DocumentConditionItem[]>) => {
      state.AllPermitsWithDocs = action.payload;
    },
    setPermitDocuments: (state, action: PayloadAction<DocumentFileItem[]>) => {
      state.PermitDocuments = action.payload;
    },
    setSelectedDocument: (state, action: PayloadAction<DocumentFileItem>) => {
      state.SelectedDocument = action.payload;
    },
    setSelectedDocumentPermit: (state, action: PayloadAction<any>) => {
      state.SelectedDocumentPermit = action.payload;
    },
    setPercentComplete: (state, action: PayloadAction<PercentComplete>) => {
      state.PercentComplete = action.payload;
    },
    setOpenedTabIndex: (state, action: PayloadAction<number>) => {
      state.OpenedTabIndex = action.payload;
    },
    setReviewComments: (state, action: PayloadAction<ReviewCommentType[]>) => {
      state.ReviewComments = action.payload;
    },
    setReviewDocuments: (state, action: PayloadAction<ProjectDocumentEntry[]>) => {
      state.ReviewDocuments = action.payload;
    },
    setFilesToUpload: (state, action: PayloadAction<any[]>) => {
      state.filesToUpload = action.payload;
    },
    setPermitActivityId: (state, action: PayloadAction<number>) => {
      state.permitActivityId = action.payload;
    },
    resetDocuments: state => {
      state.AllPermitsWithDocs = [];
      state.PermitDocuments = [];
      state.SelectedDocument = {} as DocumentFileItem;
      state.SelectedDocumentPermit = {};
      state.PercentComplete = { percent: 0, completedCount: 0, allCount: 0, rejectCount: 0 };
      state.OpenedTabIndex = 1;
      state.ReviewComments = [];
      state.ReviewDocuments = [];
      state.filesToUpload = [];
      state.permitActivityId = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDocuments,
  setPermitDocuments,
  setSelectedDocument,
  setSelectedDocumentPermit,
  setPercentComplete,
  setOpenedTabIndex,
  resetDocuments,
  setReviewComments,
  setReviewDocuments,
  setFilesToUpload,
  setPermitActivityId
} = documentsSlice.actions;

export default documentsSlice.reducer;
