import * as React from 'react';
import styled from 'styled-components';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { MenuIcon } from '../atoms';

import { hex2rgba } from '../utils';

const SidebarMenuItem = ({
  onClick,
  isActive,
  icon,
  title,
}: Props) => (
  <Wrapper onClick={onClick} isActive={isActive}>
    <MenuIcon title={title} icon={icon} />
  </Wrapper>
);

interface WrapperProps {
  isActive: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  cursor: pointer;
  background: ${({ isActive, theme }) =>
    isActive
      ? hex2rgba(theme.defaultBackground, 0.15)
      : 'transparent'};
  transition: background-color 0.3s;

  &:before {
    position: absolute;
    content: '';

    height: 48px;
    background: ${props =>
      props.isActive ? 'var(--primary)' : 'transparent'};
    border-radius: 4px;
    left: 0;
  }

  span {
    color: var(--defaultBackground);
    width: 100%;
    height: 40px;
    border-radius: 50%;
    background: ${props =>
      props.isActive
        ? 'var(--defaultBackground)'
        : 'transparent'};
  }

  svg {
    path {
      color: ${props =>
        props.isActive
          ? 'var(--primary)'
          : 'var(--defaultBackground)'};
      fill: ${props =>
        props.isActive
          ? 'var(--primary)'
          : 'var(--defaultBackground)'};
    }
  }

  &:hover {
    background: ${({ theme }) =>
      hex2rgba(theme.defaultBackground, 0.15)};

    span {
      color: var(--defaultBackground);
    }
  }
`;

interface Props {
  icon: React.ComponentType<SvgIconProps>;
  onClick?: (e: React.MouseEvent) => void;
  isActive: boolean;
  title: string;
}

export default SidebarMenuItem;
