import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import apiForgotPassword from '../api/forgotPassword';

import AuthLayout from '../layouts/AuthLayout';

import ResetPasswordForm from '../organisms/ResetPasswordForm';

const initialErrors: FormErrors = {
  newPassword1: '',
  newPassword2: '',
};

const initialFields: FormData = {
  token: '',
  uid: '',
  newPassword1: '',
  newPassword2: '',
};

const ResetPasswordPage = ({
  location,
}: RouteComponentProps) => {
  const [fields, setFields] = React.useState(initialFields);
  const [errors, setErrors] = React.useState(initialErrors);
  const [isUrlCorrect, setIsUrlCorrect] = React.useState(
    true,
  );

  const [
    passwordIsReset,
    setPasswordIsReset,
  ] = React.useState(false);

  const pathData = location.pathname.split('/');

  const tokenData = pathData[pathData.length - 1];
  const uidData = pathData[pathData.length - 2];

  React.useEffect(() => {
    setFields({
      ...fields,
      token: tokenData,
      uid: uidData,
    });

    if (!tokenData || !uidData) {
      setIsUrlCorrect(false);
    }
  }, []);

  const handleResetPassword = () => {
    apiForgotPassword
      .resetPassword(fields)
      .then(() => {
        setPasswordIsReset(true);
      })
      .catch(e => {
        const newPassword1Message = e.response.data
          .newPassword1
          ? e.response.data.newPassword1[0]
          : null;
        const newPassword2Message = e.response.data
          .newPassword2
          ? e.response.data.newPassword2[0]
          : null;

        setErrors({
          newPassword1: newPassword1Message,
          newPassword2: newPassword2Message,
        });

        if (e.response.data.token || e.response.data.uid) {
          setIsUrlCorrect(false);
        }
      });
  };

  return (
    <AuthLayout
      mainText="Reset your password"
      secondaryText="Enter a new password for your account"
    >
      <ResetPasswordForm
        fields={fields}
        setFields={setFields}
        errors={errors}
        setErrors={setErrors}
        passwordIsReset={passwordIsReset}
        onResetPassword={handleResetPassword}
        isUrlCorrect={isUrlCorrect}
      />
    </AuthLayout>
  );
};

interface FormErrors {
  [s: string]: string;
  newPassword1: string;
  newPassword2: string;
}

interface FormData {
  token: string;
  uid: string;
  newPassword1: string;
  newPassword2: string;
}

export default ResetPasswordPage;
