import * as React from 'react';
import useTablePage from '../hooks/useTablePage';
import { sendPermitDocuments } from '../api/projectDocuments';
import PermitDocsSendForm from '../forms/PermitDocsSendForm';
import { ModalDefaultDraggable } from '../molecules';
import ModalInfo from '../molecules/ModalInfo';

const PermitDocsSend = ({
  permit,
  isSendDocs,
  setSendDocs,
  documents,
  statuses,
}: Props) => {
  const {
    isModalOpen,
    handleCloseModal,
    createOrUpdate,
    modalError,
    modalSuccess,
    modalTitle,
    handleAdd,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage({
    createEndpoint: sendPermitDocuments,
    modalCreateTitle: 'Choose contacts',
  });

  React.useEffect(() => {
    if (isSendDocs) {
      handleAdd();
    }
  }, [isSendDocs]);

  const handleCloseContactsModal = () => {
    if (!modalError && !modalSuccess) {
      setSendDocs(false);
      handleCloseModal();
    }
  };

  const handleCloseInfo = () => {
    handleCloseModalInfo();
    setSendDocs(false);
  };

  return (
    <>
      {isModalOpen && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseContactsModal}
        >
          <PermitDocsSendForm
            permit={permit}
            onSubmit={createOrUpdate}
            documents={documents}
            statuses={statuses}
            modalError={modalError}
          />
        </ModalDefaultDraggable>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  permit: number;
  isSendDocs: boolean;
  setSendDocs: (data: boolean) => void;
  documents: Array<any>;
  statuses: Array<any>;
}

export default PermitDocsSend;
