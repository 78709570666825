import * as React from 'react';
import styled from 'styled-components';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getScheduling,
  createScheduling,
  updateScheduling,
  deleteScheduling,
  SchedulingTableType,
} from '../../api/scheduling';
import Table from '../../organisms/Table';
import ActionsPanel from '../../organisms/ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import SchedulingForm from '../../forms/SchedulingForm';
import useUserRole from '../../hooks/useUserRole';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import { convertTableBoolean } from '../../utils/convertTableValues';

const modifiedGetSchedulingRequest = params =>
  getScheduling(params).then(response => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results.map(item => {
        if (item.fromEpsuperadmin) {
          return {
            ...item,
            isDeleted: true,
          };
        }

        return item;
      }),
    },
  }));

const SchedulingPage = () => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    modalErrorTitle,
    values,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<SchedulingTableType>({
    createEndpoint: createScheduling,
    updateEndpoint: updateScheduling,
    deleteEndpoint: deleteScheduling,
    modalCreateTitle: 'Add Scheduling',
    modalUpdateTitle: 'Update Scheduling',
  });

  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'periodic',
        'franchise',
        'fromEpsuperadmin',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    if (column === 'periodicFull') {
      return 'Recurrence';
    }

    return tableColumnsFormatter(column);
  };

  const [
    selectedEvents,
    setSelectedEvents,
  ] = React.useState([]);

  const getSchedulingData = async () => {
    const data = await getScheduling(GET_ALL_QUERY_PARAMS);

    if (data && data.data.results.length) {
      return setSelectedEvents(
        data.data.results.filter(
          item => item.fromEpsuperadmin,
        ),
      );
    }

    return setSelectedEvents(null);
  };

  React.useEffect(() => {
    getSchedulingData();
  }, []);

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={handleAdd}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={
            isSuperAdmin
              ? getScheduling
              : modifiedGetSchedulingRequest
          }
          onEdit={handleEdit}
          onDelete={isSuperAdmin && handleDelete}
          manuallyUpdate={tableManuallyUpdate}
          hightLightSelectedRow={
            !isSuperAdmin ? selectedEvents : []
          }
          formatValue={convertTableBoolean}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <SchedulingForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong> /{' '}
          {selectedItem.description} / {selectedItem.date} /{' '}
          {selectedItem.periodicFull}
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

export default SchedulingPage;
