import * as React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import { InputProps } from '@material-ui/core';

const FileInput = ({ accept, ...props }: Props) => {
  if (props?.multiple) {
    return (
      <StyledInput
        inputProps={{
          type: 'file',
          accept,
          multiple: true,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
  return (
    <StyledInput
      inputProps={{ type: 'file', accept }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
const StyledInput = styled(Input)`
  width: 100%;

  .MuiInputBase-input.MuiInput-input {
    padding: 6px 0 6px;
  }
`;

type Props = InputProps & {
  accept?: string;
  multiple?: boolean;
};

export default FileInput;
