import * as React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core';

import { TextInput, LabelForm } from '../atoms';
import ErrorMessages from './ErrorMessages';

const InputTextWithLabel = ({
  isRequired,
  label,
  error,
  isDisabled,
  labelRequired,
  showInRow=false,
  ...props
}: Props) => {
  const { id, name } = props;

  return (
    <InputContainer showInRow={showInRow}>
      <LabelForm
        htmlFor={id || name}
        isRequired={labelRequired||isRequired}
      >
        {label}
      </LabelForm>
      <StyledTextInput
        id={id || name}
        fullWidth
        required={isRequired}
        error={!!error}
        disabled={isDisabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {error && <ErrorMessages error={error} />}
    </InputContainer>
  );
};

const InputContainer = styled.div<{showInRow: boolean}>`
  display: flex;
  flex-direction: ${({showInRow}) => showInRow ? 'row' : 'column'};
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
    width:  ${({showInRow}) => showInRow ? '159px' : 'inherit'};
    height:  ${({showInRow}) => showInRow ? '40px' : 'inherit'};
    align-items: center;
    display: ${({showInRow}) => showInRow ? 'flex' : 'inherit'};
  }
`;

const StyledTextInput = styled(TextInput)`
  .MuiInputBase-multiline fieldset {
    height: auto;
  }

  fieldset {
    height: 40px;
    top: 0;
  }

  input {
    border-radius: 1px;
    padding: 0 16px;
    top: 0;
    height: 40px;
  }

  .PrivateNotchedOutline-root-2 {
    top: 0;
  }
`;

type Props = Omit<TextFieldProps, 'error' | 'variant'> & {
  isRequired?: boolean;
  error?: FieldError;
  labelRequired?: boolean;
  showInRow?: boolean;
};

export default InputTextWithLabel;
