import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';

import theme from '../../theme';

import { getMonthlySalesReport } from '../../api/dashboard';

const MonthlySales = () => {
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    getMonthlySalesReport().then(({ data }) => {
      setChartData([
        {
          name: 'Proposal Sent',
          totalProposalsSent: data.totalProposalsSent,
        },
        {
          name: 'Proposal Approved',
          totalProposalsApproved:
            data.totalProposalsApproved,
        },
      ]);
    });
  }, []);

  return (
    <Wrapper>
      <Header>Monthly Sales Report</Header>
      <Content>
        {chartData && chartData.length && (
          <ResponsiveBar
            key={Math.random()}
            data={chartData}
            keys={[
              'totalProposalsSent',
              'totalProposalsApproved',
            ]}
            indexBy="name"
            margin={{
              top: 10,
              right: 10,
              bottom: 22,
              left: 40,
            }}
            axisLeft={null}
            groupMode="stacked"
            valueScale={{ type: 'symlog' }}
            enableGridY={false}
            padding={0.4}
            borderRadius={5}
            innerPadding={4}
            colors={[theme.marker1, theme.marker2]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            enableLabel
            isInteractive={false}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  margin-top: 10px;
  margin-right: 10px;
  padding: 6px;
  width: 770px;
  min-height: 300px;
  border: 1px solid var(--border);
  border-radius: 5px;
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;
const Content = styled.div`
  height: 280px;
`;

export default MonthlySales;
