import axios, {
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';

import { isDevMode } from '../utils';
//Added comment so I can do a new MR for pre-staging
export const axiosConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  baseURL: `${isDevMode() ? ':8443/api/v1' : '/api/v1'}`,
};

export const api: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: '/api/v1',
});

export const apiPublic: AxiosInstance = axios.create(
  axiosConfig,
);