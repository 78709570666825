import * as React from 'react';
import styled from 'styled-components';
import {
  useFormContext,
  Controller,
} from 'react-hook-form';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import {
  InputBaseContainer,
  InputSelectWithLabel,
  InputTextWithLabel,
  LoaderWithContainer,
} from '../molecules';
// import { getFranchiseList } from '../api/franchise';
import { getJurisdictions } from '../api/jurisdiction';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { ButtonMain } from '../atoms';
import { getUsers, getUsersCustom } from '../api/users';
import { USER_TYPES_VALUES } from '../constants/forms';

const ProjectFormStepThree = ({
  labels,
  currentValidationSchema,
  setIsSecondModalOpen,
  setIsSomeStepLoading,
  isContactFormSubmitting,
  setIsContactFormSubmitting,
  currentManager,
}: Props) => {
  const {
    control,
    errors,
    setValue,
    getValues,
  } = useFormContext();

  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  const [
    jurisdictionsData,
    setJurisdictionsData,
  ] = React.useState([]);
  // const [
  //   franchisesData,
  //   setFranchisesData,
  // ] = React.useState([]);
  const [
    projectManagersData,
    setProjectManagersData,
  ] = React.useState([]);
  const [contactData, setContactsData] = React.useState([]);

  React.useEffect(() => {
    const managerValue = getValues('manager');
    if (currentManager && !managerValue) {
      setValue('manager', currentManager);
    }
  }, [currentManager]);

  React.useEffect(() => {
    setIsLoading(true);
    setIsSomeStepLoading(true);

    Promise.all([
      getJurisdictions({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      // getFranchiseList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   ordering: 'name',
      // }),
      getUsersCustom({
        userRole: USER_TYPES_VALUES.EMPLOYEE,
        project: 'true'
      }),
      // getUsersCustom({
      //   userRole: USER_TYPES_VALUES.CLIENT,
      //   // clientType: 1,
      // }),
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.CLIENT,
      //   clientType: 2,
      // }),
    ])
      .then(
        ([
          jurisdictionsResponse,
          // franchisesResponse,
          projectManagersResponse,
          // individualClientsResponse,
          // companyClientsResponse,
        ]) => {
          setJurisdictionsData(
            jurisdictionsResponse.data.results,
          );
          // setFranchisesData(
          //   franchisesResponse.data.results,
          // );
          setProjectManagersData(
            projectManagersResponse.data &&
              projectManagersResponse.data?.filter(
                el => el?.isActive,
              ),
          );
          // setContactsData([
          //   ...individualClientsResponse.data?.filter(
          //     el => el?.isActive && (el?.clientType === 1 || el?.clientType === 2)
          //   ),
          
          // ]);
        },
      )
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setIsSomeStepLoading(false);
        setIsContactFormSubmitting(false);
      });
  }, [isContactFormSubmitting]);

  if (isLoading) {
    return <LoaderWithContainer />;
  }

  // calling client users api on menu open event
  const getClientUsers = () => {
    Promise.all([getUsersCustom({
      userRole: USER_TYPES_VALUES.CLIENT,
      project: "true"
    })]).then(([individualClientsResponse]) => {
      setContactsData([
        ...individualClientsResponse.data?.filter(
          el => el?.isActive && (el?.clientType === 1 || el?.clientType === 2)
        ),
      ]);
    })
  }
  
  return (
    <>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="name"
          control={control}
          helperText={errors.name?.message}
          error={errors.name?.message}
          label={labels.name}
          isRequired={isYupFieldRequired(
            'name',
            currentValidationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="jurisdiction"
          defaultValue=""
          control={control}
          render={props => {
            return (
              <InputSelectWithLabel
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.jurisdiction}
                helperText={errors.jurisdiction?.message}
                error={errors.jurisdiction?.message}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'jurisdiction',
                  currentValidationSchema,
                )}
                options={jurisdictionsData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          }}
        />
      </InputBaseContainer>
      {/* <InputBaseContainer>
        <Controller
          name="franchise"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              isLoading={isLoading}
              isDisabled={isLoading}
              label={labels.franchise}
              helperText={errors.franchise?.message}
              error={errors.franchise?.message}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) => item.name}
              isRequired={isYupFieldRequired(
                'franchise',
                currentValidationSchema,
              )}
              options={franchisesData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer> */}
      <InputBaseContainer>
        <Controller
          name="manager"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              isLoading={isLoading}
              isDisabled={isLoading}
              label={labels.manager}
              helperText={errors.manager?.message}
              error={errors.manager?.message}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) =>
                `${item.firstName} ${item.lastName}`
              }
              isRequired={isYupFieldRequired(
                'manager',
                currentValidationSchema,
              )}
              options={projectManagersData}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      <StyledInputContainer>
        <Controller
          name="projectContacts"
          defaultValue=""
          control={control}
          render={props => {
            return (
              <InputSelectWithLabel
                isMulti
                showCompany
                onFocus={() => getClientUsers()}
                // selectAllActive
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.contacts}
                helperText={errors.contacts?.message}
                error={errors.contacts?.message}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) =>
                  // eslint-disable-next-line no-nested-ternary
                  item.name
                    ? item.name
                    : `${item.firstName} ${item.lastName}
                    ${
                        item?.companyName ||
                        item?.parentCompany
                          ? ` - ${
                              item?.companyName ||
                              item?.parentCompany
                            }`
                          : ''
                      }
                      `
                }
                isRequired={isYupFieldRequired(
                  'contacts',
                  currentValidationSchema,
                )}
                options={contactData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            );
          }}
        />
        <ButtonContainer>
          <StyledButtonMain
            variant="outlined"
            onClick={() => setIsSecondModalOpen(true)}
          >
            Add new Contact
          </StyledButtonMain>
        </ButtonContainer>
      </StyledInputContainer>
    </>
  );
};

const StyledInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 8px;
`;

const ButtonContainer = styled.div`
  width: min-content;
  margin-left: 20px;
`;

const StyledButtonMain = styled(ButtonMain)`
  &.MuiButton-root {
    line-height: 1.15;
  }
`;

interface Props {
  currentValidationSchema: any;
  labels: FieldsType;
  setIsSecondModalOpen: (data: boolean) => void;
  isContactFormSubmitting: boolean;
  setIsSomeStepLoading: (data: boolean) => void;
  setIsContactFormSubmitting: (data: boolean) => void;
  currentManager?: object;
}

interface FieldsType {
  // franchise: string;
  jurisdiction: string;
  manager: string;
  contacts: string;
  name: string;
}

export default ProjectFormStepThree;
