import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../../constants/menu';
import MessageSharp from '@material-ui/icons/MessageSharp';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { format } from 'date-fns';
import { createTicketComment } from '../../../../../api/tickets';
import { appendComment } from '../../../../../redux/slices/ticketsSlice';
import { TicketComment } from '../../../types';

const CommentBox = () => {
  const taRef = useRef<HTMLTextAreaElement>();
  const dispatch = useDispatch();
  const [openAddComment, setOpenAddComment] = useState<boolean>(true);
  const [comment, setComment] = useState<string>('');
  const selectedTicket = useSelector((s: RootState) => s.tickets.selectedTicket);
  const userInfo = useSelector((s: RootState) => s.userAuth.info);

  useEffect(()=>{
    if(openAddComment){
        taRef.current.focus();
    }
  },[openAddComment])
  const onSave = async () => {
    const payload = {
      createdAt: `${format(new Date(), 'MM/dd/yyyy, hh:mm:ss')} +0000`,
      text: comment,
      id: selectedTicket.id,
      creator: userInfo.id,
    };
    //@ts-ignore
    const result = await createTicketComment(payload);
    //@ts-ignore
    dispatch(appendComment(result.data));
    setOpenAddComment(false);
    setComment('');
  };
  return (
    <NewCommentWrapper>
    {false && <AddComment onClick={e => setOpenAddComment(true)}>
      <MessageSharp />
      <span>Add Comment</span>
    </AddComment>}
      <NewComment showControls={openAddComment}>
        {openAddComment && (
          <>
            <Header>Add Conversation Item</Header>
            <textarea ref={taRef} value={comment} onChange={e => setComment(e.target.value)}></textarea>
            <Buttons>
              <Button variant="contained" onClick={() => onSave()}>
                Save
              </Button>
            {false && <Button variant="contained" onClick={() => setOpenAddComment(false)}>
              Close
            </Button>}
            </Buttons>
          </>
        )}
      </NewComment>
    </NewCommentWrapper>
  );
};

export default CommentBox;

export const Buttons = styled.div`
  button {
    color: ${Logo_Sky};
  }

  width: 200px;
  display: flex;
  justify-content: space-between;
`;

const AddComment = styled.div`
  display: flex;
  font-size: 17px;
  color: ${Logo_Sky};
  width: 145px;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
`;

const NewComment = styled.div<{ showControls: boolean }>`
  width: 60%;
  height: ${({ showControls }) => (showControls ? '240px' : '20px')};
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  textarea {
    width: 100%;
    height: 122px;
    @media only screen and (max-width: 840px) {
     height: 75px;
    }
  }
  @media only screen and (max-width: 840px) {
     height: 175px;
    }
`;

const NewCommentWrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: ${Logo_Sky};
  padding-bottom: 8px;
  @media only screen and (max-width: 840px) {
    font-size: 16px;
  }
`;