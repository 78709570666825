import { PieDatum } from '@nivo/pie';
import { capitalCase } from 'change-case';

export const convertDataKeysToLabels = (data: any[]) =>
  data && data.length
    ? data.map(item =>
        Object.keys(item).reduce(
          (acc, current) => ({
            ...acc,
            [current === 'id'
              ? current
              : capitalCase(current)]: item[current],
          }),
          {},
        ))
    : data;

export const convertDataKeysToPieValues = (
  data: object,
  excludeKey: string,
): PieDatum[] =>
  data
    ? Object.entries(data)
        .map(
          ([key, value]) =>
            key !== excludeKey && {
              id: capitalCase(key),
              label: capitalCase(key),
              value,
            },
        )
        .filter(Boolean)
    : (data as PieDatum[]);
