import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  all,
  put,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';

import history from '../../history';
import { ROUTES, HOMEPAGE } from '../../constants/routes';
import {
  setUserData,
  setAuthData,
  getStoredUserInfo,
} from '../../auth';
import apiUserAuth, {
  UserType,
  LogInDataType,
  AuthResponseType,
} from '../../api/userAuth';
import apiUserSettings, {
  UserDataPayload,
} from '../../api/userSettings';
import { APIFormErrors } from '../../types';

const initialState: UserAuthStateType = {
  info: getStoredUserInfo() || null,
  errors: null,
  activeRequest: false,
  responseHandled: true,
};

const reducerName = 'userAuth';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setActiveRequest: state => {
      state.activeRequest = true;
    },
    setUserInfo: (
      state,
      action: PayloadAction<UserType>,
    ) => {
      state.info = action.payload;
      state.activeRequest = false;

      setUserData(action.payload);
    },
    setNewUserInfo: (
      state,
      action: PayloadAction<UserType>,
    ) => {
      state.info = action.payload;
      state.activeRequest = false;
      state.responseHandled = false;

      setUserData(action.payload);
    },
    requestFailure: (
      state,
      action: PayloadAction<APIFormErrors>,
    ) => {
      state.activeRequest = false;
      state.errors = action.payload;
    },
    setResponseHandled: state => {
      state.responseHandled = true;
    },
    clearUserInfo: () => initialState,
    clearRequestError: state => {
      state.errors = null;
    },
  },
});

export const {
  setActiveRequest,
  setResponseHandled,
  setNewUserInfo,
  setUserInfo,
  requestFailure,
  clearUserInfo,
  clearRequestError,
} = slice.actions;

export const getUserInfo = createAction(
  `${reducerName}/getUserInfo`,
);

export const updateUserInfo = createAction<UserDataPayload>(
  `${reducerName}/updateUserInfo`,
);

export const updateUserAvatar = createAction<File | Blob>(
  `${reducerName}/updateUserAvatar`,
);

export const logIn = createAction<LogInDataType>(
  `${reducerName}/logIn`,
);

const redirectToTargetPage = (
  dest: ROUTES = HOMEPAGE,
  force: boolean = false,
) => {
  const destination = history.location.state
    ? history.location.state.from.pathname
    : dest;

  history.push(force ? dest : destination);
};

function* logInSaga({ payload }: LogInActionType) {
  try {
    yield put(setActiveRequest());
    const res: AuthResponseType = yield call(
      apiUserAuth.logIn,
      payload,
    );

    const { accessToken, refreshToken, user } = res.data;
    const defaultFranchise = user?.franchise?.find(el => el?.id === user?.defaultFranchiseId)
    const modifiedUser = { ...user, franchise: [defaultFranchise] }

    yield put(setUserInfo(modifiedUser));

    yield call(
      setAuthData,
      accessToken,
      refreshToken,
      user,
    );

    yield call(redirectToTargetPage);
  } catch (e) {
    if (e.response.status === 400) {
      yield put(requestFailure(e.response.data));
    }
    // TODO make handler for error case
  }
}

function* updateUserInfoSaga({
  payload: newData,
}: UpdateUserInfoType) {
  const state = yield select();
  const { id } = state.userAuth.info;

  try {
    yield put(setActiveRequest());

    const { data } = yield call(
      apiUserSettings.sendNewUserData,
      id,
      newData,
    );

    yield put(setNewUserInfo(data));
    yield call(setUserData, data);
  } catch (e) {
    if (e.response.status === 400) {
      yield put(requestFailure(e.response.data));
    }
    // TODO make handler for error case
  }
}

function* updateUserAvatarSaga({
  payload: newAvatar,
}: UpdateUserAvatarType) {
  const state = yield select();
  const { id } = state.userAuth.info;

  try {
    yield put(setActiveRequest());

    const formData = new FormData();

    formData.set('avatar', newAvatar, 'avatar.jpeg');

    const { data } = yield call(
      apiUserSettings.sendNewUserAvatar,
      id,
      formData,
    );

    const { avatar, ...restInfo } = data;

    // we need to do so, because user avatar always updated under the same url
    const newAvatarLink = avatar
      ? `${avatar}?${Date.now()}`
      : avatar;

    const newData = {
      ...restInfo,
      avatar: newAvatarLink,
    };

    yield put(setNewUserInfo(newData));
    yield call(setUserData, newData);
  } catch (e) {
    if (e.response.status === 400) {
      yield put(requestFailure(e.response.data));
    }
    // TODO make handler for error case
  }
}

export const userInfoSelector = (state: PartialRootState) =>
  state.userAuth.info;

export const userErrorsSelector = (
  state: PartialRootState,
): APIFormErrors => state.userAuth.errors || {};

export const loadingSelector = state =>
  state.userAuth.activeRequest;

export const successfulRequestSelector = ({
  userAuth,
}: PartialRootState) =>
  !userAuth.errors && !userAuth.responseHandled;

export function* userAuthSaga() {
  yield all([
    takeEvery(logIn.type, logInSaga),
    takeEvery(updateUserInfo.type, updateUserInfoSaga),
    takeEvery(updateUserAvatar.type, updateUserAvatarSaga),
  ]);
}

export const errorSelector = state =>
  state.userAuth.logInErrors;

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export interface UserAuthStateType {
  info?: UserType;
  activeRequest: boolean;
  responseHandled: boolean;
  errors: APIFormErrors | null;
}

type LogInActionType = ReturnType<typeof logIn>;
type UpdateUserInfoType = ReturnType<typeof updateUserInfo>;
type UpdateUserAvatarType = ReturnType<
  typeof updateUserAvatar
>;

export default slice.reducer;
