import {useState, useEffect} from 'react'
import { Device } from '@capacitor/device';


const useDevice = () => {
    const [isIpad, setIsIpad] = useState(false)
  useEffect(() => {
   setIsIpad(navigator.userAgent.includes("iPad"))
  // const logDeviceInfo = async () => {
  //   const info = await Device.getInfo();
  //   if(info.model === 'iPad') {
  //     setIsIpad(true)
  //   } else {
  //     setIsIpad(false)
  //   }
  // };
  // logDeviceInfo()
  },[])
  
  return [isIpad]
}

export default useDevice