import { AxiosResponse } from 'axios';

import { apiPublic } from './api';

function resetPasswordSendMail(
  data: UserResetPasswordMailData,
) {
  return apiPublic.request<UserResetPasswordMail>({
    method: 'POST',
    url: '/authentication/password/reset/',
    data,
  });
}

function resetPassword(data: UserResetPasswordData) {
  return apiPublic.request<UserResetPassword>({
    method: 'POST',
    url: '/authentication/password/reset/confirm/',
    data,
  });
}

interface UserResetPasswordData {
  token: string;
  uid: string;
  newPassword1: string;
  newPassword2: string;
}

type UserResetPassword = AxiosResponse<
  UserResetPasswordData
>;

interface UserResetPasswordMailData {
  email: string;
}

type UserResetPasswordMail = AxiosResponse<
  UserResetPasswordMailData
>;

export default {
  resetPasswordSendMail,
  resetPassword,
};
