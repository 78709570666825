import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SaveIcon from '@material-ui/icons/Save';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { getReviewerComments } from '../../../../api/projectDocuments';
import { getStatusesFromApi } from '../../../../modules/clientModule/utils';
import { setReviewComments } from '../../../../redux/slices/documentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createReviewerComment } from '../../../../api/activities';
import { ReviewCommentStatuses, ReviewCommentType } from '../../../../modules/clientModule/types';
import { RootState } from '../../../../redux/root';

type Props = {
    documentId: number
}

const CommentsSection:FC<Props> = ({documentId}) => {
    const [prestine, setPrestine] = useState<boolean>(true);
    const reviewComments = useSelector((s:RootState) => s.documents.ReviewComments); 
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>();  
    useEffect(()=>{
        const load = async () => {
            const result = await getReviewerComments(documentId);  
            const statuses = getStatusesFromApi(result);              
            dispatch(setReviewComments(statuses));
        }
        load();
      },[]);

    useEffect(()=>{        
        if(isLastItemEmpty())
        {
            ref.current.scrollTop = ref.current.scrollHeight;
        } else {
            ref.current.scrollTop = 0;
        }
    },[reviewComments]);

    const isLastItemEmpty = ():boolean => {
        const last = reviewComments[reviewComments.length - 1];
        if(!last.reviewerComment && !last.reference)
        {
            return true;
        }
        return false;
      }

    const onClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const data = {
            reviewId: documentId,
            reviewerCommentForm: reviewComments
        }
        let result = await createReviewerComment(data);
        setPrestine(result.status === 201);

        result = await getReviewerComments(documentId);  
        const statuses = getStatusesFromApi(result);              
        dispatch(setReviewComments(statuses));
        return false;
      }

      const onChange = e => {
        
        const value = e.target.value; 
        const nameParts = e.target.name.split(/_/);
         const name = nameParts[0];
         const index:number = Number(nameParts[1]);

         const payload = reviewComments.map((item,ndx)=>{
            if(ndx === index)
            {
                let _item;
                if(name === 'status')
                {
                    const _label = ReviewCommentStatuses.find(i => i.value === Number(value))?.label || '';
                    _item = {
                        ...item,
                        [name]: {label: _label, value}
                      }
                } else {
                  _item = {
                    ...item,
                    [name]: value
                  }
                }
                return _item;
            }
            return item;
         });
         setPrestine(false);
         dispatch(setReviewComments(payload));;
      }

      const addReview = () => {
        if(isLastItemEmpty())
        {
            ref.current.scrollTop = ref.current.scrollHeight;
            return;
        }
         const payload = [
            ...reviewComments,
            {id:0, status:{label:'Rejected', value: 0}} as ReviewCommentType
         ];
         dispatch(setReviewComments(payload));         
      }
    return (
        <ReviewsWrapper ref={ref}>
         <AddNewWrapper onClick={addReview}>
            <span>Add New Comment</span><AddToPhotosIcon />
        </AddNewWrapper>
        
        {reviewComments.map((comment,ndx) => (
            <CommentBackground key={ndx} index={ndx}>
                <CommentWrapper>
                    <span>Reviewer Comment</span>
                    <input name={`reviewerComment_${ndx}`}  value={comment.reviewerComment} onChange={onChange} />
                    <span>Reference</span>
                    <input name={`reference_${ndx}`}  value={comment.reference} onChange={onChange} />                            
                        <DropdownWrapper>                                      
                        <select name={`status_${ndx}`} onChange={onChange} value={comment?.status?.value}>
                        {ReviewCommentStatuses.map((statusTitle,ndx) => 
                                <option key={ndx} value={statusTitle.value}>{statusTitle.label}</option>
                            )
                        }                                    
                        </select>
                        <IconWrapper onClick={onClick} isPrestine={prestine}>
                            <SaveIcon />                                    
                        </IconWrapper>                    
                    </DropdownWrapper>                
                </CommentWrapper> 
            </CommentBackground>
        ))}   
        </ReviewsWrapper>
    );
};

export default CommentsSection;

const AddNewWrapper = styled.div`
 display: flex;
 span{
    margin-right: 20px;
    font-size: 20px;
    margin-top: 6px;
    margin-left: 8px;
 }
 svg {
    zoom:2;
 }
`;

const DropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: 63% 1fr;
  select {
    height: 36px;
    margin-top: 13px;
    font-size:20px;
  }
`;

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    font-size:20px;
  }
`;

const CommentBackground = styled.div<{index: number}>`
  border-bottom: 1px solid grey;
  background-color: ${({index}) => index % 2 === 0 ? 'rgb(212,212,212)' : 'rgb(229,229,229)' };
  padding:10px;
`
const IconWrapper = styled.div<{isPrestine: boolean}>`
 margin:auto;
 zoom:1.5;
 margin-top: 10px;
 color: ${({isPrestine}) => isPrestine ? 'green' : 'red'};
`;

export const ReviewsWrapper = styled.div`
 border: 1px solid gray;
 height: 73vh;
 overflow: auto;
 padding: 5px;
`;