import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { FormsReportType } from '../api/reports';

const FormReportItem = ({
  form,
  setSelectedFormsReports,
  selectedFormsReports,
}: Props) => {
  const handleCheck = () => {
    const isSelected = selectedFormsReports?.includes(
      form.id,
    );
    if (isSelected) {
      setSelectedFormsReports(
        selectedFormsReports.filter(el => el !== form?.id),
      );
    } else {
      setSelectedFormsReports([
        ...selectedFormsReports,
        form.id,
      ]);
    }
  };

  return (
    <ItemWrapper
      className={
        !selectedFormsReports?.includes(form.id)
          ? 'should-print'
          : ''
      }
    >
      <ItemHead>
        <ItemName>{form.name}</ItemName>
        <Checkbox
          color="primary"
          checked={selectedFormsReports?.includes(form.id)}
          onClick={handleCheck}
          classes={{ root: 'check' }}
        />
      </ItemHead>
      <ItemBody>
        <ItemImgWrapper>
          <ItemImg
            id={form.id}
            src={`data:image/png;base64,${form.image}`}
          />
        </ItemImgWrapper>
      </ItemBody>
    </ItemWrapper>
  );
};

interface Props {
  form: any;
  setSelectedFormsReports: (data: any) => void;
  selectedFormsReports: Array<FormsReportType>;
}

const ItemWrapper = styled.div`
  margin: 20px 0;
  width: 100%;

  @media print {
    @page {
      size: A4;
      margin-top: 20px;
      margin-bottom:0;
    }
    width: 1293px;
    position: relative;
    left: -100px;
    top: -50px;
    &.should-print {
      display: none;
    }
  }
`;

const ItemHead = styled.div`
  background-color: #f5f5f5;
  padding: 10px;

  .check {
    padding: 0 0 5px 5px;
  }

  @media print {
    margin: 0;
    padding: 0;
    height: 0;
    visibility: hidden;
  }
`;

const ItemBody = styled.div`
  display: flex;
  justify-content: center;

  @media print {
    margin: 0;
    padding: 0;
  }
`;

const ItemImgWrapper = styled.div`
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin-top: 20px;

  @media print {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: initial;
  }
`;

const ItemName = styled.span`
  font-size: 20px;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const ItemImg = styled.img`
  width:100%;
  height: auto;

  @media print {
    margin: 50px;
    padding: 10px;
    width: 88%;
    height: auto;
    object-fit: contain;
  }
`;

export default FormReportItem;
