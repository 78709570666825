import * as React from 'react';
import styled from 'styled-components';

import { getCurrentUser } from '../../api/userSettings';
import { getMessagesList } from '../../api/messages';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';

import Table from '../Table';

const labels = {
  id: 'id',
  franchiseNames: 'franchiseNames',
  publicationDate: 'publicationDate',
  addressesList: 'addressesList',
  allEmployees: 'All',
  userRole: 'userRole',

  senderName: {
    case: 'sender',
    column: 'Sender',
  },
  text: { case: 'text', column: 'Text' },
};

const DailyMessage = () => {
  const getModifiedMessages = params => {
    return Promise.all([
      getMessagesList(params),
      getCurrentUser(),
    ]).then(([messagesList, currentUserList]) => {
      const fullName = `${currentUserList.data.firstName} ${currentUserList.data.lastName}`;

      return {
        ...messagesList,
        data: {
          ...messagesList.data,
          results: messagesList.data.results
            .map(message => {
              // const receiver =
              //   message.addressesList !==
              //   labels.allEmployees
              //     ? fullName
              //     : 'For all employee';

              return {
                ...message,
              };
            })
            .filter(elem => {
              if (
                elem.addressesList === fullName ||
                elem.addressesList === labels.allEmployees
              ) {
                return elem;
              }
              return null;
            })
            .filter(elem => {
              const getDate = (
                param?: string | undefined,
              ) => {
                return param ? new Date(param) : new Date();
              };

              const date = {
                day: getDate(elem.publicationDate).getDay(),
                month: getDate(
                  elem.publicationDate,
                ).getMonth(),
                year: getDate(
                  elem.publicationDate,
                ).getFullYear(),
              };

              if (
                date.day === getDate().getDay() &&
                date.month === getDate().getMonth() &&
                date.year === getDate().getFullYear()
              ) {
                return elem;
              }
              return null;
            }),
        },
      };
    });
  };
  const getColumns = column => {
    if (
      [
        labels.id,
        labels.franchiseNames,
        labels.publicationDate,
        labels.addressesList,
        labels.userRole,
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case labels.senderName.case:
        return labels.senderName.column;
      case labels.text.case:
        return labels.text.column;
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    labels.senderName.case,
    labels.text.case,
  ];
  return (
    <Wrapper>
      <Header>Message of the day</Header>
      <Content>
        <Table
          customSearch
          rows={getModifiedMessages}
          columns={getColumns}
          columnsOrder={columnsOrder}
          onEdit={null}
          onConfigure={null}
          onDelete={null}
          additionalActions={null}
        />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 6px;
  width: 270px;
  height: 340px;
  border: 1px solid var(--border);
  border-radius: 5px;
  @media only screen and (max-width: 840px) {
    display: none;
    width: 100%;
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-bottom: 10px;
`;

const Content = styled.div`
  height: 280px;
`;

export default DailyMessage;
