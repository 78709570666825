import * as React from 'react';
import styled from 'styled-components';
import { InputProps } from '@material-ui/core';
import { FieldError } from 'react-hook-form';
import { FileInput } from '../atoms';
import ErrorMessages from './ErrorMessages';

const InputFileContainer = ({
  error,
  isRequired,
  accept,
  ...props
}: InputFileContainerProps) => {
  return (
    <InputContainer>
      <StyledFileInput
        required={isRequired}
        error={!!error}
        accept={accept}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {error && <ErrorMessages error={error} />}
    </InputContainer>
  );
};

const StyledFileInput = styled(FileInput)`
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

type InputFileContainerProps = Omit<
  InputProps,
  'error' | 'variant'
> & {
  isRequired?: boolean;
  error?: FieldError;
  accept?: string;
  multiple?: boolean;
};

export default InputFileContainer;
