import * as React from 'react';
import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';
import AddIcon from '@material-ui/icons/Add';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { checkValidStateName } from '../utils/parseGoogleApiAddress';
import { ButtonSecondary } from '../atoms';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import { FormServerError } from '../types';
import {
  InputAutocompletePlaces,
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  TextAreaWithLabel,
  InputCurrencyWithLabel,
  InputDatePickerWithLabel,
  ButtonWithLoader,
} from '../molecules';
import {
  emailRegex,
  phoneRegex,
  zipCode,
  goalRegex,
} from '../regex';
import {
  FranchiseType,
  FranchiseFormType,
} from '../api/franchise';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import useUserRole from '../hooks/useUserRole';
import { DEFAULT_DATE_FORMAT } from '../constants';
import parseUTCDate from '../utils/parseUTCDate';
import formatDate from '../utils/formatDate';

import {
  FRANCHISE_LEVEL_DATA,
  FRANCHISE_TYPE_DATA,
} from '../__DATA__';

const labels = {
  name: 'Branch Name',
  franchiseLevel: 'Branch Level',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone',
  contactPerson: 'Contact Person',
  franchiseType: 'Branch Type',
  yearlyGoal: 'Projected yearly Goal',
  note: 'Notes',
  address: 'Address',
  street: 'Street',
  city: 'City',
  state: 'State',
  zipCode: 'Zip',
  toDate: 'To date',
  fromDate: 'From date',
};

const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  franchiseLevel: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a franchise level from the list')
    .label(labels.franchiseLevel),
  firstName: yup
    .string()
    .required()
    .label(labels.firstName),
  lastName: yup.string().required().label(labels.lastName),
  email: yup
    .string()
    .matches(emailRegex, 'Enter a valid email address')
    .required()
    .label(labels.email),
  phoneNumber: yup
    .string()
    .matches(
      phoneRegex,
      'Invalid phone number. The phone number must be in format 2XX-XXX-XXXX',
    )
    .required()
    .label(labels.phoneNumber),
  contactPerson: yup
    .string()
    .required()
    .label(labels.contactPerson),
  franchiseType: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a franchise level from the list')
    .nullable()
    .label(labels.franchiseType),
  yearlyGoal: yup
    .string()
    .matches(
      goalRegex,
      'Invalid goal. The goal must include only numbers',
    )
    .required()
    .label(labels.yearlyGoal),
  note: yup.string().label(labels.note),
  address: yup.string().required().label(labels.address),
  state: yup.string().label(labels.state),
  street: yup.string().label(labels.street),
  city: yup.string().label(labels.city),
  zipCode: yup
    .string()
    .matches(
      zipCode,
      'Invalid Code number. The code number must be in format 55555 or 55555-5555',
    )
    .label(labels.zipCode),
  toDate: yup
    .date()
    .required()
    .typeError('Invalid or absent date')
    .label(labels.toDate),
  fromDate: yup
    .date()
    .required()
    .typeError('Invalid or absent date')
    .label(labels.fromDate),
});

const FranchiseForm = ({
  onSubmit,
  initialValues,
  modalError,
  pushToSettingsPage,
  addButtonText = 'Add new item',
  updateButtonText = 'Update',
  initialErrors,
}: Props) => {
  const fromDateUTC = parseUTCDate(
    initialValues?.fromDate as string,
  );
  const toDateUTC = parseUTCDate(
    initialValues?.toDate as string,
  );

  const {
    handleSubmit,
    setError,
    errors,
    register,
    control,
    setValue,
    formState,
  } = useForm<FranchiseType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues
      ? {
          ...initialValues,
          fromDate: fromDateUTC,
          toDate: toDateUTC,
          franchiseLevel: initialValues.franchiseLevel
            ? FRANCHISE_LEVEL_DATA.filter(
                item =>
                  item.value ===
                  initialValues.franchiseLevel,
              )[0]
            : '',
          franchiseType: initialValues.franchiseType
            ? FRANCHISE_TYPE_DATA.filter(
                item =>
                  item.value ===
                  initialValues.franchiseType,
              )[0]
            : '',
        }
      : ({} as FranchiseType),
  });
  const { isSuperAdmin } = useUserRole();

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<FranchiseType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: FranchiseType) => void,
  ) => (data: FranchiseFormType) => {
    submitFn({
      ...data,
      id: initialValues?.id,
      fromDate: formatDate(data.fromDate),
      toDate: formatDate(data.toDate),
      franchiseLevel: data.franchiseLevel?.value,
      franchiseType: data.franchiseType?.value,
    });
  };

  const onCurrencyChange = (value, name) => {
    setValue(name, value);
  };

  const handlePlaceSelect = (address, isSelect) => {
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        return setValue(key, value);
      });
    }
    return setValue('address', address);
  };

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('name', schema)}
          />
          <Controller
            name="franchiseLevel"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.franchiseLevel}
                error={errors.franchiseLevel}
                isDisabled={!isSuperAdmin}
                options={FRANCHISE_LEVEL_DATA}
                isRequired={isYupFieldRequired(
                  'franchiseLevel',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="firstName"
            error={errors.firstName}
            inputRef={register}
            label={labels.firstName}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired(
              'firstName',
              schema,
            )}
          />
          <InputTextWithLabel
            name="lastName"
            error={errors.lastName}
            inputRef={register}
            label={labels.lastName}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired(
              'lastName',
              schema,
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="email"
            error={errors.email}
            inputRef={register}
            label={labels.email}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('email', schema)}
          />
          <Controller
            control={control}
            defaultValue=""
            name="phoneNumber"
            render={props => (
              <ReactInputMask
                mask="999-999-9999"
                maskChar=" "
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              >
                {() => (
                  <InputTextWithLabel
                    error={errors.phoneNumber}
                    label={labels.phoneNumber}
                    isRequired={isYupFieldRequired(
                      'phoneNumber',
                      schema,
                    )}
                  />
                )}
              </ReactInputMask>
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="contactPerson"
            error={errors.contactPerson}
            inputRef={register}
            label={labels.contactPerson}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired(
              'contactPerson',
              schema,
            )}
          />
          <Controller
            name="franchiseType"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label={labels.franchiseType}
                error={errors.franchiseType}
                isDisabled={!isSuperAdmin}
                options={FRANCHISE_TYPE_DATA}
                isRequired={isYupFieldRequired(
                  'franchiseType',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="fromDate"
            defaultValue=""
            valueName="selected"
            onChange={([selected]) => selected}
            control={control}
            render={props => (
              <InputDatePickerWithLabel
                label={labels.fromDate}
                error={errors.fromDate}
                disabled={!isSuperAdmin}
                dateFormat={DEFAULT_DATE_FORMAT}
                isRequired={isYupFieldRequired(
                  'fromDate',
                  schema,
                )}
                // eslint-disable-next-line react/prop-types
                selected={props.value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
          <Controller
            name="toDate"
            defaultValue=""
            valueName="selected"
            onChange={([selected]) => selected}
            control={control}
            render={props => (
              <InputDatePickerWithLabel
                label={labels.toDate}
                error={errors.toDate}
                disabled={!isSuperAdmin}
                dateFormat={DEFAULT_DATE_FORMAT}
                isRequired={isYupFieldRequired(
                  'toDate',
                  schema,
                )}
                // eslint-disable-next-line react/prop-types
                selected={props.value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="yearlyGoal"
            defaultValue=""
            control={control}
            render={props => (
              <InputCurrencyWithLabel
                allowDecimals={false}
                error={errors.yearlyGoal}
                label={labels.yearlyGoal}
                onCurrencyChange={onCurrencyChange}
                isRequired={isYupFieldRequired(
                  'yearlyGoal',
                  schema,
                )}
                disabled={!isSuperAdmin}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <TextAreaWithLabel
            name="note"
            error={errors.note}
            label={labels.note}
            disabled={!isSuperAdmin}
            inputRef={register as any}
            isRequired={isYupFieldRequired('note', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="address"
            defaultValue=""
            control={control}
            render={props => (
              <InputAutocompletePlaces
                error={errors.address}
                label={labels.address}
                isDisabled={!isSuperAdmin}
                handleFieldChange={handlePlaceSelect}
                isRequired={isYupFieldRequired(
                  'address',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="street"
            error={errors.street}
            inputRef={register}
            label={labels.street}
            isRequired={isYupFieldRequired(
              'street',
              schema,
            )}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
          />
          <InputTextWithLabel
            name="city"
            error={errors.city}
            inputRef={register}
            label={labels.city}
            isRequired={isYupFieldRequired('city', schema)}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="state"
            defaultValue=""
            control={control}
            render={props => (
              <InputTextWithLabel
                name="state"
                error={errors.state}
                label={labels.state}
                InputProps={{
                  readOnly: !isSuperAdmin,
                }}
                isRequired={isYupFieldRequired(
                  'state',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                // eslint-disable-next-line react/prop-types
                value={checkValidStateName(props.value)}
              />
            )}
          />
          <InputTextWithLabel
            name="zipCode"
            error={errors.zipCode}
            inputRef={register}
            label={labels.zipCode}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired(
              'zipCode',
              schema,
            )}
          />
        </InputBaseContainer>
        {initialValues && isSuperAdmin && (
          <ConfigurationButtonContainer>
            <ButtonSecondary onClick={pushToSettingsPage}>
              <AddIcon /> Add configuration
            </ButtonSecondary>
          </ConfigurationButtonContainer>
        )}
        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

const ConfigurationButtonContainer = styled.div`
  text-align: start;
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: FranchiseType;
  onSubmit: (data: FranchiseType) => void;
  pushToSettingsPage: () => void;
  initialErrors?: FormServerError<FranchiseType>;
  modalError: boolean;
}

export default FranchiseForm;
