import * as React from 'react';
import {
  Tooltip as TooltipMUI,
  TooltipProps,
} from '@material-ui/core';

const Tooltip = ({
  title,
  children,
  arrow = true,
  enterDelay = 500,
  ...props
}: TooltipProps) =>
  title ? (
    <TooltipMUI
      arrow={arrow}
      title={title}
      enterDelay={enterDelay}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </TooltipMUI>
  ) : (
    children
  );

export default Tooltip;
