const isYupFieldRequired = (
  field: string,
  schema: any,
): boolean =>
  schema?.describe &&
  schema
    .describe()
    .fields[field]?.tests.findIndex(
      ({ name }) => name === 'required',
    ) >= 0;

export default isYupFieldRequired;
