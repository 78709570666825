import * as React from 'react';

import useManuallyUpdate from './useManuallyUpdate';

const defaultEndpointPlaceholder = data =>
  new Promise(resolve => resolve(data));

const useTablePage = <T extends object>({
  deleteAllEndpoint= defaultEndpointPlaceholder,
  updateEndpoint= defaultEndpointPlaceholder,
  updateTarget,
}: useTablePageSettings): useTablePageReturnType<T> => {
  const [search, setSearch] = React.useState<string>('');

  const [modalSuccess, setModalSuccess] = React.useState<
    boolean
  >(false);
  const [modalError, setModalError] = React.useState<
    boolean
  >(false);

  const [targetId, setTargetId] = React.useState(null);
  const [
    modalErrorTitle,
    setModalErrorTitle,
  ] = React.useState<string>('');

  const [isModalOpen, setIsModalOpen] = React.useState<
    boolean
  >(false);

  const [values, setValues] = React.useState(null);
  const [errors, setErrors] = React.useState(null);

  const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();

  const successRequest = () => {
    updateTable();
    setIsModalOpen(false);
    setModalSuccess(true);
    setValues(null);
    setErrors(null);
    setModalErrorTitle('');
  };

  const handleErrorRequest = error => {
    const { data, status } = error.response;

    if (status > 500) {
      setModalError(true);
      return;
    }

    if (status <= 500 && !data.nonFieldErrors) {
      setModalError(true);
      setModalErrorTitle(
        'Something went wrong. Please, contact support',
      );
      return;
    }

    if (
      status <= 500 &&
      data.nonFieldErrors &&
      typeof data.nonFieldErrors !== 'string'
    ) {
      setModalError(true);
      setModalErrorTitle(data.nonFieldErrors.join(', '));
      return;
    }

    if (
      status <= 500 &&
      data.nonFieldErrors &&
      typeof data.nonFieldErrors === 'string'
    ) {
      setModalError(true);
      setModalErrorTitle(data.nonFieldErrors);
      return;
    }

    setErrors(data);
  };

  const updateDataRequest = () => {
    if (values.length) {
      return updateEndpoint(
        { [updateTarget]: values },
        targetId,
      )
        .then(() => {
          successRequest();
        })
        .catch(error => {
          handleErrorRequest(error);
        });
    }
    return deleteAllEndpoint(targetId)
      .then(() => {
        successRequest();
      })
      .catch(error => {
        handleErrorRequest(error);
      });
  };

  const handleListChange = data => {
   const newValues = data?.reduce((acc, el) => {
      if (el.status || el.isResolved) {
        return [...acc, el.id];
      }
      return [...acc];
    }, []);
   setValues(newValues);
  };

  const handleListChangeForComment = data => {
    setValues(data);
  };

  const handleCancelUpdate = () => {
    updateTable();
    setIsModalOpen(false);
  };

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setValues(null);
    setErrors(null);
  };

  const handleCloseModalInfo = () => {
    setModalSuccess(false);
    setModalError(false);
  };

  return {
    search,
    setSearch,
    updateTable,
    handleCancelUpdate,
    setTargetId,
    modalError,
    modalSuccess,
    showErrorModal: setModalError,
    showSuccessModal: setModalSuccess,
    isModalOpen,
    openModal: setIsModalOpen,
    values,
    setValues,
    errors,
    setErrors,
    tableManuallyUpdate,
    successRequest,
    handleErrorRequest,
    updateDataRequest,
    modalErrorTitle,
    handleAdd,
    handleCloseModal,
    handleCloseModalInfo,
    handleListChange,
    handleListChangeForComment

  };
};

interface useTablePageSettings {
  deleteAllEndpoint?: (id) => Promise<any>;
  updateEndpoint?: (data: any, id: number) => Promise<any>;
  updateTarget?: string;
}

interface useTablePageReturnType<T extends object> {
  search: string;
  setSearch: (search: string) => void;
  modalError: boolean;
  modalSuccess: boolean;
  showErrorModal: (status: boolean) => void;
  showSuccessModal: (status: boolean) => void;
  isModalOpen: boolean;
  openModal: (status: boolean) => void;
  values: any;
  setValues: (values: any) => any;
  errors: any;
  setErrors: (values: any) => any;
  tableManuallyUpdate: number;
  updateTable: () => void;
  updateDataRequest: () => void;
  handleCancelUpdate: () => void;
  handleAdd: () => void;
  handleCloseModal: () => void;
  handleCloseModalInfo: () => void;
  handleListChange: (data: T) => void;
  handleListChangeForComment: (data: T) => void;
  setTargetId: (id: number) => void;
  modalErrorTitle: string;

}

export default useTablePage;
