import React from 'react';
import styled from 'styled-components';

import {
  ButtonWithSearchResults,
  InputDatepickerRange,
} from '../../molecules';
import {
  SearchTypeList,
  // getSearchAllList,
  getSearchClientsList,
  // getSearchEmployeeList,
  getSearchInspectionsList,
  getSearchPermitsList,
  // getSearchPmList,
  getSearchTicketsList,
  getSearchActivitiesList,
  getSearchInvoiceList,
} from '../../api/search';

import { DEFAULT_DATE_FORMAT } from '../../constants';

const buttonValues = [
  [
    // {
    //   id: 1,
    //   name: 'All',
    //   keyName: 'all',
    //   apiCallBackFn: null,
    // },
    {
      id: 2,
      name: 'Permit',
      keyName: 'permit',
      apiCallBackFn: getSearchPermitsList,
    },
    {
      id: 7,
      name: 'Invoice',
      keyName: 'invoice',
      apiCallBackFn: getSearchInvoiceList,
    },
    {
      id: 3,
      name: 'Tickets',
      keyName: 'tickets',
      apiCallBackFn: getSearchTicketsList,
    },
    {
      id: 4,
      name: 'Clients',
      keyName: 'clients',
      apiCallBackFn: params =>
        getSearchClientsList({ ...params, userRole: 3 }),
    },
    {
      id: 5,
      name: 'Inspections',
      keyName: 'inspections',
      apiCallBackFn: getSearchInspectionsList,
    },
    {
      id: 6,
      name: 'Activities',
      keyName: 'permitActivity',
      apiCallBackFn: getSearchActivitiesList,
    },
  ],
  [
    {
      id: 1,
      name: 'Employee',
      keyName: 'employee',
      apiCallBackFn: params =>
        getSearchClientsList({ ...params, userRole: 1 }),
    },
    {
      id: 2,
      name: 'Project manager',
      keyName: 'projectManager',
      apiCallBackFn: params =>
        getSearchPermitsList({
          ...params,
        }),
    },
  ],
];

const ButtonsResultsPanel = ({
  className,
  searchValuesData,
  setEndpoint,
  setActiveTabName,
  setDates,
}: ButtonsResultsPanelTypes) => {
  const [activeButton, setActiveButton] = React.useState<
    string
  >('');

  // React.useEffect(() => {
  //   if (
  //     searchValuesData &&
  //     Object.keys(searchValuesData).length
  //   ) {
  //     setActiveButton(buttonValues[0][0].keyName);
  //     setEndpoint(buttonValues[0][0].apiCallBackFn);
  //   }
  // }, [searchValuesData]);

  const handleClick = button => {
    setActiveTabName(button.keyName);
    setActiveButton(button.keyName);
    setEndpoint(button.apiCallBackFn);
  };

  return (
    <Container className={className}>
      <LeftButtonContainer>
        {buttonValues[0].map(button => (
          <ButtonWithSearchResults
            key={button.id}
            onClick={() => handleClick(button)}
            searchResult={searchValuesData[button.keyName]}
            className={
              button.keyName === activeButton
                ? 'active'
                : ''
            }
          >
            {button.name}
          </ButtonWithSearchResults>
        ))}
        {(activeButton === 'inspections' ||
          activeButton === 'permitActivity') && (
          <StyledInputDatepickerRange
            name="date"
            placeholderText="Select Date"
            dateFormat={DEFAULT_DATE_FORMAT}
            onRangeChange={(start, end) => {
              if (start && end) {
                setDates({ fromDate: start, toDate: end });
              }
            }}
          />
        )}
      </LeftButtonContainer>
      <RightButtonContainer>
        {buttonValues[1].map(button => (
          <ButtonWithSearchResults
            key={button.id}
            onClick={() => handleClick(button)}
            searchResult={searchValuesData[button.keyName]}
            className={
              button.keyName === activeButton
                ? 'active'
                : ''
            }
          >
            {button.name}
          </ButtonWithSearchResults>
        ))}
      </RightButtonContainer>
    </Container>
  );
};

interface ButtonsResultsPanelTypes {
  className?: string;
  searchValuesData: SearchTypeList | object;
  setEndpoint: (data: any) => void;
  setActiveTabName: (data: any) => void;
  setDates: (data: any) => void;
}

const Container = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .MuiButton-containedPrimary.active {
    background-color: rgba(11, 105, 152, 0.5) !important;
  }
`;

const StyledInputDatepickerRange = styled(
  InputDatepickerRange,
)`
  margin-left: 2px;

  .MuiOutlinedInput-root.MuiInputBase-formControl {
    height: 32px;
  }
`;

const LeftButtonContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const RightButtonContainer = styled.div`
  display: flex;
`;

export default ButtonsResultsPanel;
