import * as React from 'react';
import styled from 'styled-components';
import { generatePath, useHistory } from 'react-router';
import {
  modifiedGetAllActivities,
  modifiedGetInspections,
  modifiedGetCalls,
  modifiedGetFees,
  modifiedGetReviews,
  modifiedGetConditions,
  modifiedGetPermitting,
  modifiedGetProposal,
} from '../../utils/allActivitiesPageRequests';
import {
  allActivitiesColumns,
  allActivitiesColumnsOrder,
  inspectionColumns,
  inspectionColumnsOrder,
  callsColumns,
  callsColumnsOrder,
  feesColumns,
  feesColumnsOrder,
  reviewsColumns,
  reviewsColumnsOrder,
  proposalsColumns,
  proposalsColumnsOrder,
  ConditionColumns,
} from '../../utils/allActivitiesPageColumns';
import useTablePage from '../../hooks/useTablePage';

import Table from '../Table';
import AllActivitiesEditForm from '../../forms/AllActivitiesEditForm';
import { ModalDefaultDraggable } from '../../molecules';
import PermitInspectionsForm from '../../forms/permitInspectionForm/PermitInspectionsForm';
import ModalInfo from '../../molecules/ModalInfo';
import { updatePermitInspection } from '../../api/inspections';
import { getUsers } from '../../api/users';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import { ROUTES } from '../../constants/routes';
import store from '../../redux/store';
import { setIsUpdateTable } from '../../redux/slices/allActivitiesSlice';

const AllActivitiesTable = ({
  typeQuery,
  emergencyQuery,
  selectInspections,
  searchQuery,
}: Props) => {
  const {
    updateTable,
    tableManuallyUpdate,
    isModalOpen,
    modalTitle,
    errors,
    modalSuccess,
    modalError,
    modalErrorTitle,
    createOrUpdate,
    handleCloseModal,
    handleEdit,
    handleCloseModalInfo,
  } = useTablePage({
    updateEndpoint: updatePermitInspection,
  });

  const [initialValues, setInitialValues] = React.useState(
    null,
  );
  const [allUsers, setAllUsers] = React.useState([])
  const [currentUser, setCurrentUser] = React.useState({})
  const history = useHistory();
  
  React.useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userInfo'))
    setCurrentUser(currentUser)
    
  }, [])

  const handleEditModal = data => {    
    if (data) {
      setInitialValues(data);
    }
  };

  const handleEditInspections = data => {
    if (data) {
      setInitialValues(data);
      handleEdit(data);
    }
  };

  const handleCloseInfoModal = closeInfo => {
    setInitialValues(null);
    store.dispatch(setIsUpdateTable(true));
    updateTable();
    closeInfo();
  };

  const handleCloseInfoInspectionsModal = () => {
    setInitialValues(null);
    store.dispatch(setIsUpdateTable(true));
    updateTable();
    handleCloseModalInfo();
  };

  const handleCloseInspectionModal = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const handlePermitClick = (data, column) => {
    if (data.permitId) {
      const path = generatePath(ROUTES.PERMIT, {
        projectId: data.projectId,
        permitId: data.permitId,
      });
      window.open(path, "_blank");
    }
  }

  return (
    <Container>
      {(typeQuery && typeQuery?.title === 'All') ? (
        <Table
          initialUseSortBy
          isActivityTable
          customSearch
          // searchGlobal={searchQuery}
          searchQuery={searchQuery}
          rows={modifiedGetAllActivities}
          columns={allActivitiesColumns}
          onEdit={handleEditModal}
          onDelete={null}
          columnsOrder={allActivitiesColumnsOrder}
          colorSchema
          activityType={typeQuery?.title}
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
          additionalRequestParams={{
            // activityType: typeQuery?.id,
            isEmergency: emergencyQuery?.value,
          }}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
        />
      ) : null}
      {(typeQuery && typeQuery?.title === 'Conditions') ? (
        <Table
          initialUseSortBy
          isActivityTable
          customSearch
          searchQuery={searchQuery}
          rows={modifiedGetConditions}
          columns={ConditionColumns}
          onEdit={handleEditModal}
          onDelete={null}
          colorSchema
          columnsOrder={feesColumnsOrder}
          activityType={typeQuery?.title}
          additionalRequestParams={{
            // activityType: typeQuery?.id,
            isEmergency: emergencyQuery?.value,
            eventType: "Activity",
            activityTypeName: typeQuery?.title,
          }}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
        />
      ) : null}
      {(typeQuery && typeQuery?.title === 'Inspection') ? (
        <Table
          initialUseSortBy
          isActivityTable
          customSearch
          searchQuery={searchQuery}
          rows={modifiedGetInspections}
          columns={inspectionColumns}
          onEdit={handleEditInspections}
          onDelete={null}
          columnsOrder={inspectionColumnsOrder}
          colorSchema
          activityType="inspections"
          additionalRequestParams={{
            isEmergency: emergencyQuery?.value,
            eventType: "Inspection",
          }}
          onSelect={selectInspections}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
        />
      ) : null}
      {typeQuery?.title === 'Calls' ? (
        <Table
          initialUseSortBy
          isActivityTable
          customSearch
          searchQuery={searchQuery}
          rows={modifiedGetCalls}
          columns={callsColumns}
          onEdit={handleEditModal}
          onDelete={null}
          columnsOrder={callsColumnsOrder}
          colorSchema
          activityType={typeQuery?.title}
          additionalRequestParams={{
            // activityType: typeQuery?.id,
            isEmergency: emergencyQuery?.value,
            eventType: "Activity",
            activityTypeName: typeQuery?.title,
          }}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
        />
      ) : null}
      {typeQuery &&
      (typeQuery?.title === 'Fee' ||
        typeQuery?.title === 'Permitting') ? (
        <Table
          initialUseSortBy
          isActivityTable
          customSearch
          searchQuery={searchQuery}
          rows={typeQuery?.title === 'Fee' ? modifiedGetFees : modifiedGetPermitting }
          columns={feesColumns}
          onEdit={handleEditModal}
          onDelete={null}
          columnsOrder={feesColumnsOrder}
          colorSchema
          activityType={typeQuery?.title}
          additionalRequestParams={
            history.location?.state?.from === 'tasksheet' ? 
            {
              activity_status: "Outstanding",
              assigned_to: `${currentUser?.firstName} ${currentUser?.lastName}`,
              eventType: "Activity",
              activityTypeName: typeQuery?.title,
              isEmergency: emergencyQuery?.value,
            } :
            {
            eventType: "Activity",
            activityTypeName: typeQuery?.title,
            isEmergency: emergencyQuery?.value,
          }}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
        />
      ) : null}
      {(typeQuery && typeQuery?.title === 'Proposal') ? (
        <Table
          initialUseSortBy
          isActivityTable
          customSearch
          searchQuery={searchQuery}
          rows={modifiedGetProposal}
          columns={proposalsColumns}
          onEdit={handleEditModal}
          onDelete={null}
          columnsOrder={proposalsColumnsOrder}
          colorSchema
          activityType={typeQuery?.title}
          additionalRequestParams={{
            // activityType: typeQuery?.id,
            isEmergency: emergencyQuery?.value,
            eventType: "Activity",
            activityTypeName: typeQuery?.title,
          }}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
        />
      ) : null}
      {(typeQuery && typeQuery?.title === 'Reviews') ? (     
         <Table
          initialUseSortBy
          isActivityTable
          customSearch
          searchQuery={searchQuery}
          rows={modifiedGetReviews}
          columns={reviewsColumns}
          onEdit={handleEditModal}
          onDelete={null}
          columnsOrder={reviewsColumnsOrder}
          colorSchema
          activityType={typeQuery?.title}
          additionalRequestParams={{
            // activityType: typeQuery?.id,
            isEmergency: emergencyQuery?.value,
            eventType: "Activity",
            activityTypeName: typeQuery?.title,
          }}
          manuallyUpdate={tableManuallyUpdate}
          // activityTable
          cellCallbackFnIsOn={['permit']}
          handleCellClick={(data, column) =>
            handlePermitClick(data, column)
          }
        />       
      ) : null}
      {typeQuery?.title !== 'Inspection' && (
        <AllActivitiesEditForm
          // typeQuery={typeQuery}
          initialValues={initialValues}
          handleCloseInfoModal={handleCloseInfoModal}
          permit={initialValues?.permitId}
          setInitialValues={setInitialValues}
        />
      )}

      {isModalOpen && initialValues && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseInspectionModal}
        >
          <PermitInspectionsForm
            onSubmit={createOrUpdate}
            initialValues={initialValues}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={initialValues?.permitId}
            allActivities
            setInitialValues={setInitialValues}
          />
        </ModalDefaultDraggable>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseInfoInspectionsModal}
          errorMsg={modalErrorTitle}
        />
      )}
    </Container>
  );
};

interface Props {
  typeQuery: any;
  emergencyQuery: any;
  selectInspections: (data: any) => void;
}

const Container = styled.div`
  margin: 16px 0;
  flex-grow: 1;
  overflow-y: auto;
`;

export default AllActivitiesTable;
