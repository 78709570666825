import styled from 'styled-components';

const ArrowPointer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ active }) => (active ? '160' : '110')}px;
  ${({ index }) => (index === 0 ? 'width: 85px;' : '')}
  height: ${({ size }) =>
    size === 'small' ? '36' : '48'}px;
  background: #0b6b9b;
  position: relative;
  text-align: center;
  padding-left: 27px;
  ${({ index }) =>
    index === 0 ? 'padding-left: 5px;' : ''}
  margin-bottom: 7px;
  color: white;
  ${({ active }) => (active ? '' : 'opacity: 0.4;')}
  ${({ size }) =>
    size === 'small' ? 'font-size: 13px;' : ''}
  ${({ size }) =>
    size === 'small' ? 'line-height: 13px;' : ''}
  ${({ size }) =>
    size === 'small'
      ? 'margin-right: 10px; margin-left: 10px;'
      : ''}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: ${({ size }) =>
        size === 'large' ? '24' : '18'}px
      solid
      ${({ index }) =>
        index === 0 ? 'transparent' : '#f5f5f5'};
    border-top: ${({ size }) =>
        size === 'large' ? '24' : '18'}px
      solid transparent;
    border-bottom: ${({ size }) =>
        size === 'large' ? '24' : '18'}px
      solid transparent;
  }

  &:before {
    content: '';
    position: absolute;
    right: ${({ size }) =>
      size === 'large' ? '-24' : '-18'}px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: ${({ size }) =>
        size === 'large' ? '24' : '18'}px
      solid #0b6b9b;
    border-top: ${({ size }) =>
        size === 'large' ? '24' : '18'}px
      solid transparent;
    border-bottom: ${({ size }) =>
        size === 'large' ? '24' : '18'}px
      solid transparent;
  }
`;

interface Props {
  size: 'small' | 'large';
  active: boolean;
  index: number;
}

export default ArrowPointer;
