import React from 'react';
import styled from 'styled-components';

import { AxiosPromise } from 'axios';
import DashboardLayout from '../layouts/DashboardLayout';
import { Loader } from '../atoms';
import SearchPanel from '../molecules/SearchPanel';
import ButtonsResultsPanel from '../organisms/Search/ButtonsResultsPanel';
import SearchTable from '../organisms/Search/SearchTable';
import {
  getSearchCategoriesList,
  SearchTypeList,
} from '../api/search';

const SearchPage = () => {
  const [searchValue, setSearchValue] = React.useState<
    SearchTypeList | {}
  >({});
  const [
    actualEndpoint,
    setActualEndpoint,
  ] = React.useState<Promise<AxiosPromise> | null>(null);
  const [searchKey, setSearchKey] = React.useState('');
  const [activeTabName, setActiveTabName] = React.useState(
    'all',
  );

  const [dates, setDates] = React.useState(
    {} as { fromDate: Date; toDate: Date },
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const searchData = value => {    
    setIsLoading(true);
    setSearchKey(value);
    getSearchCategoriesList({ search: value }).then(
      response => {        
        setIsLoading(false);
        setSearchValue(response.data);
      },
    );
  };

  const setEndpoint = fn => {
    setActualEndpoint(() => fn);
  };

  return (
    <DashboardLayout>
      <Container>
        <StyledSearchPanel setSearchValue={searchData} />
        <ButtonsResultsPanel
          searchValuesData={searchValue}
          setEndpoint={setEndpoint}
          setActiveTabName={setActiveTabName}
          setDates={setDates}
        />
      </Container>
      <Container>
        { isLoading ? 
          <Loader /> 
          : 
          (<>
            {actualEndpoint && (
              <SearchTable
                searchKey={searchKey}
                activeTabName={activeTabName}
                actualEndpoint={actualEndpoint}
                dates={dates}
                setDates={setDates}
              />
            )}
          </>)}
      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: var(--defaultBackground);
`;

const StyledSearchPanel = styled(SearchPanel)`
  width: 70%;
  height: 50px;
  font-size: 18px;
`;

export default SearchPage;
