import * as React from 'react';
import styled from 'styled-components';

import { PERMIT_MENU_ACTIVE_TAB } from '../constants/permitMenu';

import PermitActionsTable from './PermitActionsTable';
import NotesPage from '../pages/Permits/NotesPage';
import TicketsPage from '../pages/Permits/TicketsPage';
import ConditionsPage from '../pages/Permits/ConditionsPage';
import FeesPage from '../pages/Permits/FeesPage';
import InspectionsPage from '../pages/Permits/InspectionsPage';
import DocumentsPage from '../pages/Permits/DocumentsPage';

const PermitContentBlock = ({
  projectId,
  permit,
  permitStatus,
  activeTab,
  activityType,
  isAddNote,
  setAddNote,
  isAddTicket,
  setAddTicket,
  setAddInspection,
  isAddInspection,
  isAddFees,
  setAddFees,
  activityCreated,
  setActivityCreated,
  isAddCondition,
  setAddCondition,
  setFeeSelected,
  isSendingSelectedFees,
  setIsSendingSelectedFees,
  selectedBuilding,
  selectedWork,
  isAddDocument,
  setAddDocument,
  isDownloadAllDocs,
  setDocsAvailable,
  setDownloadAllDocs,
  setDocsWaitingOnClient,
  setDisableSend,
  isSendDocs,
  setSendDocs,
  cameFromSearch,
  handleDocumentData,
  isLocked,
  setDisableReviewCommentBtn,
  showReviewCommentLtr,
  setShowReviewCommentLtr,
}: Props) => {
  if (activeTab === PERMIT_MENU_ACTIVE_TAB.ACTIVITIES) {
    return (
      <Container>
        <ContentTitle>Permit Activities</ContentTitle>
        <PermitActionsTable
          projectId={projectId}
          permit={permit}
          activityType={activityType}
          activityCreated={activityCreated}
          setActivityCreated={setActivityCreated}
          cameFromSearch={cameFromSearch}
          setDisableReviewCommentBtn={setDisableReviewCommentBtn}
          showReviewCommentLtr={showReviewCommentLtr}
          setShowReviewCommentLtr={setShowReviewCommentLtr}
        />
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.INSPECTIONS) {
    return (
      <Container>
        <ContentTitle>Permit Inspections</ContentTitle>
        <InspectionsPage
          projectId={projectId}
          permit={permit}
          permitStatus={permitStatus}
          isAddInspection={isAddInspection}
          setAddInspection={setAddInspection}
          cameFromSearch={cameFromSearch}
        />
      </Container>
    );
  }
  if (activeTab === PERMIT_MENU_ACTIVE_TAB.FEES) {
    return (
      <Container>
        <ContentTitle>Permit Fees</ContentTitle>
        <FeesPage
          permit={permit}
          isAddFees={isAddFees}
          setAddFees={setAddFees}
          setFeeSelected={setFeeSelected}
          isSendingSelectedFees={isSendingSelectedFees}
          setIsSendingSelectedFees={
            setIsSendingSelectedFees
          }
          cameFromSearch={cameFromSearch}
        />
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.NOTES) {
    return (
      <Container>
        <ContentTitle>Permit Notes</ContentTitle>
        <NotesPage
          permit={permit}
          isAddNote={isAddNote}
          setAddNote={setAddNote}
        />
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.TICKETS) {
    return (
      <Container>
        <ContentTitle>Permit Tickets</ContentTitle>
        <TicketsPage isAddTicket={isAddTicket} setAddTicket={setAddTicket} permit={permit} projectId={projectId} />
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.DOCUMENTS) {
    return (
      <Container>
        <ContentTitle>Permit Documents</ContentTitle>
        <DocumentsPage
          permit={permit}
          work={selectedWork?.id}
          building={selectedBuilding?.id}
          isAddDocument={isAddDocument}
          setAddDocument={setAddDocument}
          isDownloadAllDocs={isDownloadAllDocs}
          setDocsAvailable={setDocsAvailable}
          setDownloadAllDocs={setDownloadAllDocs}
          setDocsWaitingOnClient={setDocsWaitingOnClient}
          setDisableSend={setDisableSend}
          isSendDocs={isSendDocs}
          setSendDocs={setSendDocs}
          cameFromSearch={cameFromSearch}
          handleDocumentData={handleDocumentData}
          isLocked={isLocked}
        />
      </Container>
    );
  }

  if (activeTab === PERMIT_MENU_ACTIVE_TAB.CONDITIONS) {
    return (
      <Container>
        <ContentTitle>Permit Conditions</ContentTitle>
        <ConditionsPage
          permit={permit}
          setAddCondition={setAddCondition}
          isAddCondition={isAddCondition}
          cameFromSearch={cameFromSearch}
        />
      </Container>
    );
  }
  return null;
};

interface Props {
  permit: number;
  activeTab: PERMIT_MENU_ACTIVE_TAB;
  activityType: number;
  isAddNote: boolean;
  isAddTicket: boolean;
  isAddFees: boolean;
  isSendDocs: boolean;
  isAddInspection: boolean;
  setAddNote: (data: boolean) => void;
  setAddTicket: (data: boolean) => void;
  setAddFees: (data: boolean) => void;
  setAddInspection: (data: boolean) => void;
  setActivityCreated: (data: boolean) => void;
  setAddCondition: (data: boolean) => void;
  setFeeSelected: (data: boolean) => void;
  isAddDocument: boolean;
  setAddDocument: (data: boolean) => void;
  setIsSendingSelectedFees: (data: boolean) => void;
  activityCreated: boolean;
  isAddCondition: boolean;
  isSendingSelectedFees: boolean;
  selectedWork: any;
  selectedBuilding: any;
  isLocked: boolean;
  isDownloadAllDocs: boolean;
  setDocsAvailable: (data: boolean) => void;
  setDownloadAllDocs: (data: boolean) => void;
  setDocsWaitingOnClient: (data: boolean) => void;
  setSendDocs: (data: boolean) => void;
  handleDocumentData:(data:any)=>void;
}

const Container = styled.div`
  margin: 24px 0 50px 0;
  width: 100%;
`;

const ContentTitle = styled.h2`
  text-align: center;
  margin-bottom: 33px;
`;

export default PermitContentBlock;
