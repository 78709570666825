import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import styled from 'styled-components';
import DrawerContent from '../../Common/Containers/DrawerContent';
import DetailsHeaderComponent from '../../Common/DetailsHeaderComponent';
import { Logo_Sky } from '../../../../../constants/menu';
import useProjectsViewItems, { ProjectViewItem } from '../../../hooks/useProjectsViewItems';
import { useHistory } from 'react-router';
import { setShowDrawerContent } from '../../../../../redux/slices/generalSlice';
import { ROUTES } from '../../Routes/ClientRoutes';
import { loadDocumentsData } from '../Documents/DocumentsTable';

const projectDetails = () => {
  const {selectedPermit, linkedItems} = useProjectsViewItems();
  const history = useHistory();
  const dispatch = useDispatch();
  const vocabulary = useSelector((s:RootState) => s.general.vocabulary);

  const handleClick = (item: ProjectViewItem) => {
    dispatch(setShowDrawerContent(true));
    loadDocumentsData(selectedPermit,dispatch,vocabulary);
    history.push(item.route);
    
  };

  return (
    <DrawerContent>
      <DetailsRoot>
        <DetailsHeaderComponent names={ `${selectedPermit.name}/${selectedPermit.permitName}`} address={selectedPermit?.address} />
        <LinkedItems>
          {linkedItems.map((item, index) => (
            <LinkedItem key={index}>
              <ItemLabel>{item.label}</ItemLabel>

              <div style={{ display: 'flex' }}>
                <ItemQty hasData={item.qty > 0}>{item.qty}</ItemQty>
                <ItemLink hasData={item.qty > 0} onClick={e => handleClick(item)}>View</ItemLink>
              </div>
            </LinkedItem>
          ))}
        </LinkedItems>
      </DetailsRoot>
    </DrawerContent>
  );
};

export default projectDetails;

const ItemQty = styled.div<{hasData: boolean}>`
  margin-right: 19px;
  color: ${({hasData}) => hasData ? 'green' : '#316B99'};
  text-decoration: none;
  border: ${({hasData}) => hasData ? '4px solid green' : '2px solid #316B99'} ;
  border-radius: 50%;
  height:  ${({hasData}) => hasData ? '30px' : '28px'};
  width:  ${({hasData}) => hasData ? '30px' : '28px'};
  left:  ${({hasData}) => hasData ? '-2px' : '0px'};
  text-align: center;
  margin-top: 11px;
  position: relative;
  font-weight: ${({hasData}) => hasData ? 'bold' : 'inherit'};
`;

const DetailsRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 85px;
  @media only screen and (max-width: 840px) {
    margin: 10px;
    margin-top: 24px;
  }
`;

const LinkedItems = styled.div`
  margin-top: 59px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 400px;
  grid-gap: 32px;
`;

const LinkedItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ItemLabel = styled.div`
  font-size: 32px;
  color: ${Logo_Sky};
  @media only screen and (max-width: 840px) {
    font-size: 22px;
    font-weight: bold;
  }
`;

const ItemLink = styled.div<{hasData: boolean}>`
  font-size: 20px;
  color:  ${({hasData}) => hasData ? 'blue' : '#ccc'};
  text-decoration: underline;
  display: flex;
  align-items: center;
  cursor:  ${({hasData}) => hasData ? 'pointer' : 'default'};
`;
