import * as React from 'react';
import styled, { css } from 'styled-components';
import WarningIcon from '@material-ui/icons/Warning';

const ErrorMessage = ({
  children,
  className = '',
  centred = false,
}: Props) => {
  return (
    <Wrapper className={className} centred={centred}>
      <WarningIconStyled />
      <Text>{children}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  color: var(--primary);
  padding-top: 10px;
  padding-bottom: 10px;

  ${({ centred }) =>
    centred &&
    css`
      justify-content: center;
    `}
`;

const Text = styled.div`
  font-size: 2rem;
`;

const WarningIconStyled = styled(WarningIcon)`
  margin-right: 10px;
`;

interface WrapperProps {
  centred?: boolean;
}

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  centred?: boolean;
}

export default ErrorMessage;
