import * as React from 'react';

import apiUserSettings from '../api/userSettings';

import DashboardLayout from '../layouts/DashboardLayout';

import ChangePasswordForm from '../forms/ChangePasswordForm';

const initialApiErrors: ApiErrors = {
  oldPasswordMessage: '',
  newPassword1Message: '',
  newPassword2Message: '',
};

const initialValues = {
  oldPassword: '',
  newPassword1: '',
  newPassword2: '',
};

const ChangePasswordPage = () => {
  const [values, setValues] = React.useState(initialValues);

  const [apiErrors, setApiErrors] = React.useState(
    initialApiErrors,
  );

  const [isModalOpen, setIsModalOpen] = React.useState(
    false,
  );

  const handlePasswordChange = () => {
    apiUserSettings
      .sendNewPassword(values)
      .then(() => {
        setIsModalOpen(true);
      })
      .catch(e => {
        const oldPasswordMessage = e.response.data
          .oldPassword
          ? e.response.data.oldPassword[0]
          : null;
        const newPassword1Message = e.response.data
          .newPassword1
          ? e.response.data.newPassword1[0]
          : null;
        const newPassword2Message = e.response.data
          .newPassword2
          ? e.response.data.newPassword2[0]
          : null;

        setApiErrors({
          oldPasswordMessage,
          newPassword1Message,
          newPassword2Message,
        });
      });
  };

  return (
    <DashboardLayout>
      <ChangePasswordForm
        values={values}
        setValues={setValues}
        apiErrors={apiErrors}
        setApiErrors={setApiErrors}
        isModalOpen={isModalOpen}
        onPasswordChange={handlePasswordChange}
      />
    </DashboardLayout>
  );
};

interface ApiErrors {
  oldPasswordMessage: string;
  newPassword1Message: string;
  newPassword2Message: string;
}

export default ChangePasswordPage;
