import * as React from 'react';
import styled from 'styled-components';

import Table from '../Table';
import ActionsPanel from '../ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import { SubMenuLink } from '../../constants/menu';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import VocabularyForm from '../../forms/VocabularyForm';
import {
  getVocabulariesDataList,
  createVocabularyData,
  VocabularyDataType,
  deleteVocabularyData,
  updateVocabularyData,
} from '../../api/vocabulary';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableBoolean } from '../../utils/convertTableValues';

const VocabularyTable = ({
  activitiesQuery,
}: ActivitiesTitleTableTypes) => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage<VocabularyDataType>({
    createEndpoint: createVocabularyData,
    updateEndpoint: updateVocabularyData,
    deleteEndpoint: deleteVocabularyData,
    modalCreateTitle: `Add ${activitiesQuery.name} Title`,
    modalUpdateTitle: `Update ${activitiesQuery.name} Title`,
  });

  const columns = column => {
    if (
      ['isDeleted', 'vocabularyEntity'].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'vocabularyEntity':
        return 'Vocabulary Entity';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <>
      <StyledActionsPanel
        onSearch={setSearch}
        onAdd={handleAdd}
        isBreadCrumbsVisible={false}
        isActionPanelMoreVisible={false}
      />
      <Container>
        {activitiesQuery && (
          <Table
            customSearch
            searchQuery={search}
            columns={columns}
            rows={getVocabulariesDataList}
            onEdit={handleEdit}
            onDelete={handleDelete}
            formatValue={convertTableBoolean}
            manuallyUpdate={tableManuallyUpdate}
            additionalRequestParams={{
              vocabularyEntity: activitiesQuery.id,
            }}
          />
        )}
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <VocabularyForm
            initialValues={values}
            initialErrors={errors}
            modalError={modalErrorTitle.length > 0}
            activitiesQuery={activitiesQuery}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.title}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

const Container = styled.div`
  margin: 16px 0;
`;

const StyledActionsPanel = styled(ActionsPanel)`
  margin: 0;
  width: 100%;
  height: 50px;
`;

interface ActivitiesTitleTableTypes {
  activitiesQuery: SubMenuLink;
}

export default VocabularyTable;
