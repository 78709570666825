import React, { useEffect } from 'react';
import { InputBaseContainer, InputSelectWithLabel } from '../../../../../molecules';
import { Controller } from 'react-hook-form';
import { labels } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { setShowPopup } from '../../../../../redux/slices/inspectionsSlice';

const Contact = ({ control, setValue, errors }) => {
  const { permitUsers, selectedInspection } = useSelector((s: RootState) => s.inspections);
  const dispatch = useDispatch();
  useEffect(() => {
    if (permitUsers.length > 0) {
      const contact = permitUsers.find(c => 
        c.id === selectedInspection.contact ||
        //@ts-ignore
        `${c.firstName} ${c.lastName}` === selectedInspection.contact
        );
      setValue('activityContact', contact);
    }
  }, [permitUsers, selectedInspection]);

  const showContacts = () => {
    dispatch(setShowPopup('contacts'))
  };
  return (
    <ContactsWrapper>
      <ControlWrapper>
        <InputBaseContainer>
          <Controller
            name="activityContact"
            defaultValue=""
            control={control}
            render={({ onChange, ...props }) => (
              <InputSelectWithLabel
                showInRow={true}
                onChange={item => {
                  onChange(item);
                  // setValue('cellPhone', item?.mobilePhoneNumber || "");
                  // setValue('phoneNumber', item?.phoneNumber || "");
                }}
                showCompany
                placeholder=""
                menuPlacement="bottom"
                label={labels.activityContact}
                error={errors.activityContact}
                isDisabled={false}
                // isDisabled={isLoading || isReadOnly || isInspectionCompleted || cameFromSearch || isInspector}
                options={permitUsers}
                // isRequired={isYupFieldRequired(
                //   'activityContact',
                //   initialValues ? schemaEdit : schemaAdd,
                // )}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  `${item?.firstName} ${item?.lastName}${
                    item.companyName || item.parentCompany ? ` - ${item.companyName || item.parentCompany}` : ''
                  }`
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
      </ControlWrapper>
      <ButtonWrapper>
        <Button color="primary" variant="contained" onClick={showContacts}>
          Contacts
        </Button>
      </ButtonWrapper>
    </ContactsWrapper>
  );
};

export default Contact;

const ContactsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const ControlWrapper = styled.div`
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  height: 20px;
  padding-top: 9px;
`;
