import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import DrawerContent from '../../Common/Containers/DrawerContent';
import FeesTable from './FeesTable';
import FeesItems from './FeesItems';
import useCloseDrawers from '../../../hooks/useCloseDrawers';
import useShowDetailsFromDashboard from '../../../hooks/useShowDetailsFromDashboard';

const Fees = () => {
  useCloseDrawers();
  useShowDetailsFromDashboard();
  return (
    <ClientContainer>
      <BannerStrip label="FEES"></BannerStrip>
      <WrapperWithRightDrawer>
        <FeesTable />
        <Drawer>
          <DrawerContent>
            <FeesItems />
          </DrawerContent>
        </Drawer>
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default Fees;
