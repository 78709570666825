import styled from 'styled-components';

export default styled.a`
  background: none;
  padding: 0;
  border: none;
  outline: none;
  color: var(--mainText);
  text-decoration: underline;
  letter-spacing: 0.021px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
