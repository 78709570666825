import * as React from 'react';
import {
  Paper,
  Popper,
  Grow,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import { PopperPlacementType } from '@material-ui/core/Popper/Popper';

import { logOut } from '../auth';
import { ButtonMain } from '../atoms';
import UserDropMenuLink from './UserDropMenuLink';
import { ROUTES } from '../constants/routes';
import { useDispatch } from 'react-redux';
import { setUsers } from '../redux/slices/clientSlice';
import { clearStore } from '../modules/clientModule/utils';

const UserDropMenu = ({
  anchorRef,
  open,
  handleClose,
}: Props) => {
  if (!anchorRef) return null;
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(setUsers([]))
    logOut();
    clearStore(dispatch);
  }

  return (
    <PopperStyled
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-end"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Grow {...TransitionProps}>
          <PaperStyled placement={placement}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuListStyled
                autoFocusItem={open}
                onClick={handleClose}
              >
                <UserDropMenuLink
                  text="Account"
                  to={ROUTES.ACCOUNT}
                />
                <ButtonMainStyled onClick={onLogout}>
                  Logout
                </ButtonMainStyled>
              </MenuListStyled>
            </ClickAwayListener>
          </PaperStyled>
        </Grow>
      )}
    </PopperStyled>
  );
};

const ButtonMainStyled = styled(ButtonMain)`
  text-transform: uppercase;
  width: 105px;
  display: block;
  margin: 10px auto 0;
  box-shadow: none;
`;

const PaperStyled = styled(Paper)<PaperStyledProps>`
  border: 1px solid var(--border);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  ${({ placement }) => {
    if (placement.includes('bottom')) {
      return css`
        margin-top: 10px;
      `;
    }

    if (placement.includes('top')) {
      return css`
        margin-bottom: 10px;
      `;
    }

    return null;
  }}
`;

const PopperStyled = styled(Popper)`
  z-index: var(--popperZIndex);
`;

const MenuListStyled = styled(MenuList)`
  width: 168px;
  padding: 8px 0 16px;
  outline: none !important;
`;

interface Props {
  anchorRef?: {
    current: HTMLElement;
  };
  open: boolean;
  handleClose: () => any;
}

interface PaperStyledProps {
  placement: PopperPlacementType;
}

export default UserDropMenu;
