import React from 'react';
import TableHeaders, { DataCell, Icons, RowWrapper, SingleRow } from '../../Components/TableWithFilters/TableHeaders';
import EditIcon from '@material-ui/icons/Edit';
import EditOffIcon from '@material-ui/icons/LabelOff';
import LinkString from '../../Components/TableWithFilters/LinkString';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import InspectorDropdown from './InspectorDropdown';

const InspectionsTable = ({ rows, isFetching, onCellClick, openEditor }) => {
  const showAllPendingInspections = useSelector((s: RootState) => s.inspections.showAllPendingInspections);
  const headersAll = [
    { label: 'Project Name|Permit Name', name: 'projectNamePermitName' },
    { label: 'Permit Number', name: 'permitNumber' },
    { label: 'Project Address', name: 'projectAddress' },
    { label: 'Inspection ID', name: 'id' },
    { label: 'Inspection Type', name: 'inspectionType' },
    { label: 'Project Manager', name: 'projectManager' },
    { label: 'Jurisdiction', name: 'jurisdiction' },
    { label: 'Inspector', name: 'inspectorName' },
    { label: 'Contact', name: 'contact' },
    { label: 'Cell Phone', name: 'cellPhone' },
    { label: 'Contact Phone', name: 'contactPhone' },
    { label: 'Actions', name: '' },
  ];

  const edit = row => {
    if (showAllPendingInspections) {
      return;
    }
    openEditor(row);
  };
  return (
    <PendingInspectionsWrapper>
      {isFetching && <Backdrop />}
      <TableHeaders headers={headersAll} rows={rows || []} isLoading={isFetching} pristine={isFetching}>
        {rows &&
          rows.map((row, index) => {
            return (
              <>
                <RowWrapper key={index} index={index}>
                  <SingleRow2 count={headersAll.length} isEmergency={row.isEmergency}>
                    <DataCell>
                      <LinkString label={row.projectNamePermitName} row={row} onCellClick={onCellClick} />
                    </DataCell>
                    <DataCell>{row.permitNumber}</DataCell>
                    <DataCell>{row.projectAddress}</DataCell>
                    <DataCell>{row.id}</DataCell>
                    <DataCell>{row.inspectionType}</DataCell>
                    <DataCell>{row.projectManager}</DataCell>
                    <DataCell>{row.jurisdiction}</DataCell>
                    <DataCell>
                        {!showAllPendingInspections && <InspectorDropdown row={row} />}
                        {showAllPendingInspections && <span>{row.inspectorName}</span>}
                    </DataCell>
                    <DataCell>{row.contact}</DataCell>
                    <DataCell>{row.cellPhone}</DataCell>
                    <DataCell>{row.contactPhone}</DataCell>
                    <IconsEdit disabled={showAllPendingInspections}>
                      {!showAllPendingInspections && <EditIcon onClick={() => edit(row)} />}
                      {showAllPendingInspections && <EditOffIcon />}
                    </IconsEdit>
                    <InspectionDate>
                      <Label>Inspection Date:</Label>
                      <div>{row.inspectionDate}</div>
                    </InspectionDate>
                    <Information>
                      <Label>Information:</Label>
                      <div>{row.information}</div>
                    </Information>
                    <LastComment>
                      <Label>Comment:</Label>
                      <CommentWrapper>
                        {row.lastComment && <div dangerouslySetInnerHTML={{ __html: row.lastComment.text }} />}
                      </CommentWrapper>
                    </LastComment>
                  </SingleRow2>
                </RowWrapper>
              </>
            );
          })}
      </TableHeaders>
    </PendingInspectionsWrapper>
  );
};

export default InspectionsTable;

const PendingInspectionsWrapper = styled.div`
  position: relative;
`;

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.3);
  width: 200%;
  height: 100%;
  z-index: 3;
`;

const Label = styled.div`
  font-weight: bold;
`;

const SingleRow2 = styled(SingleRow)`
  overflow: hidden;
`;

const IconsEdit = styled(Icons)<{ disabled }>`
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  color: ${({ disabled }) => (disabled ? 'grey' : 'inherit')}; ;
`;

const CommentWrapper = styled.div`
  max-width: 370px;
  max-height: 130px;
  overflow: auto;
`;

const LastComment = styled.div`
  display: flex;
  padding: 0 10px;
  border-bottom: 2px solid grey;
  grid-column: 1 / 13;
  background-color: #fff;
  margin-top: -15px;
`;

const Information = styled.div`
  display: flex;
  padding: 0px;
  padding-left: 11px;
  grid-column: 1 / 13;
  background-color: #fff;
  div {
    margin-right: 3px;
  }
`;

const InspectionDate = styled.div`
  display: flex;
  padding: 0px;
  padding-left: 11px;
  grid-column: 1 / 13;
  background-color: #fff;
  div {
    margin-right: 3px;
  }
`;
