import React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import useUserRole from '../../hooks/useUserRole';
import PDF from './PDFCreation/Pdf';


const DesktopAddReport = ({ 
  initialValues, 
  isInspectionCompleted,
  getValues,
  cameFromSearch, 
  isReadOnly, 
  setErrorReport, 
  setInspectionReport, 
  selectedStatusValue,
  watch }) => {
  const {
    isInspector
  } = useUserRole();
  return (
    <Wrapper>
      {isInspector && <PDF  
      watch={watch}
      getValues={getValues}
      initialValues={initialValues}
      setInspectionReport={setInspectionReport}
      selectedStatusValue={selectedStatusValue}
      />} 
      { !isInspector && <Button 
        style={{
          margin: 0,
          borderRadius: '6px',
        }}
        disabled={isInspectionCompleted || cameFromSearch || isReadOnly}
        variant="contained"
        component="label"
        color="primary"
      >
        Add Inspection Report
        <input
          hidden
          name="inspectionReport"
          type="file"
          onChange={e => {
            setErrorReport('');
            setInspectionReport(e.target.files[0]);
          }}
          disabled={isInspectionCompleted || isReadOnly}
          accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </Button>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap:5px;
  /* @media only screen and (max-width: 841px) {
    display: none;
  } */

`;

export default DesktopAddReport;
