import React, { useEffect, useState } from 'react';
import SignatureEditor from './SignatureEditor';
import SignatureImage from './SignatureImage';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { getSignature } from '../../api/userAuth';

const SignatureWidget = () => {
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const userInfo = useSelector((s:RootState) => s.userAuth.info);
  useEffect(() => {
     const loadSignature = async () => {
       const sig = await getSignature(userInfo.id);
       if(sig){
        setShowEditor(false);
       } else {
        setShowEditor(true);
       }
     }

     loadSignature();
  },[])
  return <Wrapper>{showEditor ? <SignatureEditor setShowEditor={setShowEditor} /> : <SignatureImage setShowEditor={setShowEditor} />}</Wrapper>;
};

export default SignatureWidget; 

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 15px;
  height: 230px;
`;