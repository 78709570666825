import * as React from 'react';
import Table from './Table';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import { getDocumentList } from '../api/document';

const DocumentsInFormSetup = ({ initialDocs }: Props) => {
  const columns = column => {
    if (
      [
        'building',
        'buildingName',
        'documentFile',
        'forms',
        'fromEpsuperadmin',
        'isDefault',
        'isDeleted',
        'jurisdiction',
        'signature',
        'template',
        'visibleFilename',
        'work',
        'workName',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'requirement':
        return 'Document Name';
      case 'jurisdictionNames':
        return 'Jurisdiction';
      default:
        return tableColumnsFormatter(column);
    }
  };
  const columnsOrder = [
    'id',
    'name',
    'description',
    'jurisdiction',
    'isDefault',
    'file',
  ];

  const modifiedGetDocs = params =>
    getDocumentList({
      ...params,
      id: initialDocs.join(','),
    }).then(documentsResponse => {
      return {
        ...documentsResponse,
        data: {
          ...documentsResponse.data,
          results: documentsResponse.data.results.filter(
            doc => initialDocs.includes(doc.id),
          ),
        },
      };
    });

  return (
    initialDocs.length !== 0 && (
      <Table
        customSearch
        columns={columns}
        columnsOrder={columnsOrder}
        rows={modifiedGetDocs}
        onEdit={null}
        onDelete={null}
        additionalActions={null}
        onConfigure={null}
      />
    )
  );
};

interface Props {
  initialDocs: Array<number>;
}

export default DocumentsInFormSetup;
