import * as React from 'react';
import styled from 'styled-components';
import { FormsReportType } from '../api/reports';
import FormReportItem from '../molecules/FormReportItem';

const FormsReportList = React.forwardRef(
  (
    {
      forms,
      setSelectedFormsReports,
      selectedFormsReports,
    }: Props,
    ref,
  ) => {
    if (!forms?.length) {
      return (
        <InfoText>
          No project was found with selected Jurisdiction.
        </InfoText>
      );
    }
    return (
      <ListWrapper ref={ref}>
        {forms?.map(form => (
          <FormReportItem
            key={`${form?.name}${form?.id}`}
            form={form}
            setSelectedFormsReports={
              setSelectedFormsReports
            }
            selectedFormsReports={selectedFormsReports}
          />
        ))}
      </ListWrapper>
    );
  },
);

interface Props {
  forms?: Array<FormsReportType>;
  setSelectedFormsReports: (data: any) => void;
  selectedFormsReports: Array<FormsReportType>;
}

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media print {
    position: absolute;
    top:0;
    display: block;
    height: auto;
  }
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default FormsReportList;
