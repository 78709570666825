import React, { useState } from 'react';
import ProjectEditForm from '../../forms/ProjectEditForm';
import { LoaderWithContainer, ModalDefaultDraggable } from '../../molecules';

const ProjectDetials = ({ initialValues, setIsEditProjectModal, isEditProjectModal}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCloseEditProject = () => {
        setIsEditProjectModal(false);
      }
  return (
    <>
      {isEditProjectModal && (
        <ModalDefaultDraggable
          title={'Project Details'}
          // addContactModal={addContactModal}
          onClose={handleCloseEditProject}
        >
          {isLoading && (
              <LoaderWithContainer />
            )}
          {true && (
            <ProjectEditForm
              HandleAddContactModal={(a: any) => {}}
              editMode={false}
              initialValues={initialValues}
              changeMode={() => {}}
              jurisdiction={'jurisdictionsData'}
              manager={[]}
              contacts={''}
              handleCloseEditProject={()=>setIsEditProjectModal(false)}
              updateTable={()=>{}}
              hideButtons={true}
            />
          )}
        </ModalDefaultDraggable>
      )}
    </>
  );
};

export default ProjectDetials;
