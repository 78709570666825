import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FeeItemType, PermitFeeItem } from '../../modules/clientModule/types';

export interface FeesState {
  inspectionFeesList: PermitFeeItem[];
  selectedFee: PermitFeeItem;
}

const initialState: FeesState = {
  inspectionFeesList: [],
  selectedFee: {} as PermitFeeItem,
};

export const feesSlice = createSlice({
  name: 'fees',
  initialState,
  reducers: {
    setPermitFeesList: (state, action: PayloadAction<PermitFeeItem[]>) => {
      state.inspectionFeesList = action.payload;
    },
    setSelectedFee: (state, action: PayloadAction<PermitFeeItem>) => {
      state.selectedFee = action.payload;
    },
    resetFees: (state) => {
      state.inspectionFeesList = [];
      state.selectedFee = {} as PermitFeeItem;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setPermitFeesList, setSelectedFee, resetFees } = feesSlice.actions;

export default feesSlice.reducer;
