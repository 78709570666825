import React, { FC, useEffect, useState } from 'react';
import Table20 from '../../../Components/Table20/Table20';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { calculatePeriod, getDailyInspectionRecords } from './Utils';
import { formatDateTime } from '../../../utils/formatDate';
import { setPageCount, setPageIndex, setRowsCount } from '../../../redux/slices/paginationSlice';
import { AdditionalFieldType } from '../../../Components/Table20/Types';
import { setGlobalSearch } from '../../../redux/slices/filterSlice';
import { setInspectionsTypeData, setInspectorName, setModalOpen, setPermitInspectionsList, setSelectedInspection } from '../../../redux/slices/inspectionsSlice';
import { InspectionItemType } from '../../../modules/clientModule/types';
import InspectionEditor from './InspectionEditor/InspectionEditor';

type Props = {
  fromDate:any,
  setInspectionCount:Function,
  openSMS:Function,
  setIsValidForExport:Function,
}
const DailyInspectionRecords:FC<Props> = ({
  fromDate,
  setInspectionCount,
  openSMS,
  setIsValidForExport,
}) => {
  const userId = useSelector((s: RootState) => s.userAuth.info.id);
  const search = useSelector((s: RootState) => s.filters.globalSearch);
  const { pageSize, pageIndex, pageCount, rowsCount } = useSelector((state: RootState) => state.pagination);
  const selectedInspection = useSelector((s:RootState) => s.inspections.selectedInspection)
  const dispatch = useDispatch();
  const _rows = useSelector((s: RootState) => s.inspections.permitInspectionsList);

  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const headers = [
    'Permit Number',
    'Inspection ID',
    'Inspection Type',
    'Project Manager',
    'Jurisdiction',
    'AM/PM',
    'Contact',
    'Cell Phone',
    'Contact Phone',
    'Actions',
  ];

  const _fromDate = fromDate || formatDateTime(new Date(), 'MM/dd/yyyy');

  const params = {
    is_deleted: false,
    limit: pageSize,
    offset: pageIndex * pageSize,
    inspector: userId,
    date_from: _fromDate,
    is_completed: false,
    search,
  };

  useEffect(() => {
    dispatch(setGlobalSearch(undefined));
    dispatch(setPageIndex(0));
  }, []);

  useEffect(() => {
      loadData();
  }, [fromDate, pageSize, pageIndex, search]);

  useEffect(() => {
    setData(_rows);
    const counter = _rows.length;
    setIsValidForExport(counter > 0);
    dispatch(setRowsCount(counter));
    //@ts-ignore
    const insFirstName = _rows[0]?.inspectorFirstName;
    //@ts-ignore
    const insLastName = _rows[0]?.inspectorLastName;
    if (insFirstName) {
     dispatch(setInspectorName(insFirstName + ' ' + insLastName));
    } else {
     dispatch(setInspectorName(''));
    }

    setInspectionCount(counter);
    const _af = [];
    const fieldRows = _rows.map((r: any) => {
      const comment = getComment(r);
      const fields = [];

      fields.push({ caption: 'Name', value: `${r.projectName}|${r.permitName}` });
      fields.push({ caption: 'Address', value: r.projectAddress });
      fields.push({ caption: 'Information', value: r.information });
      fields.push({ caption: 'Comment', value: comment });
      _af.push(fields);
      const managerName = r.managerFirstName ? `${r.managerFirstName} ${r.managerLastName}` : '';
      const contactName = r.contactFirstName ? `${r.contactFirstName} ${r.contactLastName}` : '';
      return [
        r.permitNumber,
        r.id,
        r.activityTypeName,
        managerName,
        r.projectJurisdiction,
        calculatePeriod(r),
        contactName,
        r.cellPhone,
        r.phoneNumber,
      ];
    });
    setRows(fieldRows);
    setAdditionalFields(_af);
  }, [_rows]);

  const getComment = input => {
    if (!input.lastComment) {
      return '';
    }
    const { createdAt, text, username } = input.lastComment;
    const date = formatDateTime(new Date(createdAt), 'MM/dd/yyyy');
    const message = text.indexOf('<img') > -1 ? '' : text;
    return `${date}|${username}|${message}`;
  };
  const loadData = async () => {
    const _params = {
      ...params,
      search: params.search === '' ? undefined : params.search
    }
    const results: any = await getDailyInspectionRecords(_params);
    dispatch(setInspectionsTypeData(results.result));
    dispatch(setPermitInspectionsList(results.results));
  };

  const openEditor = (inspection: InspectionItemType) => {
    dispatch(setSelectedInspection(inspection));
    dispatch(setModalOpen(true));
  };
  return (
    <>
     <Table20
      headers={headers}
      rows={rows}
      data={data}
      additionalFields={additionalFields}
      // Action1={handleEdit}
      Action1={openEditor}
      Action2={openSMS}
    />
    {selectedInspection.id && <InspectionEditor loadData={loadData}/>}
    
    </>
   
  );
};

export default DailyInspectionRecords;
