import styled, { css } from 'styled-components';
import {
  TableCell,
  TableCellProps,
} from '@material-ui/core';

export default styled(TableCell)<TableCellProps>`
  border: none;

  ${({ variant }) =>
    variant === 'head' &&
    css`
      color: var(--mainText);
      padding: 8px 10px;
    `}

  ${({ variant }) =>
    variant === 'body' &&
    css`
      color: var(--secondaryText);
      padding: 15px 10px;
    `}
  
  &.MuiTableCell-stickyHeader {
    background-color: var(--secondary);
  }
`;
