import * as React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { TextareaAutosizeProps } from '@material-ui/core';

import { Textarea, LabelForm } from '../atoms';

const TextAreaWithLabel = ({
  isRequired,
  label,
  error,
  inputRef,
  maxChar,
  showInRow=false,
  ...props
}: Props) => {
  const { id, name } = props;
  return (
    <InputContainer showInRow={showInRow}>
      <LabelForm
        htmlFor={id || name}
        isRequired={isRequired}
      >
        {label}
      </LabelForm>
      <StyledTextarea
        id={id || name}
        error={!!error}
        inputRef={inputRef}
        required={isRequired}
        maxLength={maxChar}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {error && (
        <span
          style={{
            color: '#f44336',
            fontSize: '0.75rem',
            margin: '3px 14px 0 14px',
          }}
        >
          {error?.message ? error.message : error}
        </span>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div<{showInRow: boolean}>`
  display: flex;
  flex-direction: ${({showInRow}) => showInRow ? 'row' : 'column'};
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
    width:  ${({showInRow}) => showInRow ? '159px' : 'inherit'};
    height:  ${({showInRow}) => showInRow ? '40px' : 'inherit'};
    align-items: center;
    display: ${({showInRow}) => showInRow ? 'flex' : 'inherit'};
  }
`;

const StyledTextarea = styled(Textarea)`
  width: 100% !important;
  border-radius: 1px;
  ${({ error }) => error && 'border-color: red;'}
  top: 0;
  min-height: 40px;
`;

type Props = Omit<
  TextareaAutosizeProps,
  'error' | 'variant'
> & {
  isRequired?: boolean;
  error?: FieldError;
  label?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  maxChar?: number;
  showInRow?: boolean
};

export default TextAreaWithLabel;
