import { FranchiseType } from '../api/franchise';

export const FRANCHISE_INFO = 'franchiseInfo';

export const getStoredFranchiseInfo = (): FranchiseType | void => {
  try {
    const info = localStorage.getItem(FRANCHISE_INFO);
    if (!info) {
      return null;
    }

    return JSON.parse(info);
  } catch (e) {
    return null;
  }
};

export const setFranchiseData = (
  franchiseInfo: FranchiseType,
) => {
  localStorage.setItem(
    FRANCHISE_INFO,
    JSON.stringify(franchiseInfo),
  );
};
