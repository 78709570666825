import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import NewProjectButton from '../../pages/Dashboard/NewProjectButton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Logo_Gold, Logo_Sky } from '../../../../../constants/menu';
import { RootState } from '../../../../../redux/root';
import { resetFilters } from '../../../../../redux/slices/filterSlice';

type Props = {
  label: string;
};
const BannerStrip: FC<Props> = ({ label,children }) => {
  const dispatch = useDispatch();
  const filters = useSelector((s:RootState) => s.filters.filters);

  useEffect(()=>{
    if(filters.length > 0){
      dispatch(resetFilters());
    }
  },[])

  return (
    <BannerContainer>
      <Banner>
        <span>{label}</span>
      </Banner>
      {children}
      <NewProjectButton />
    </BannerContainer>
  );
};

export default BannerStrip;

const BannerContainer = styled.div`
  position: relative;
`;

const Banner = styled.div`
font-weight: bold;
color: ${Logo_Sky};
font-style: italic;
  border: 1px solid gray;
  border-radius: 4px;
  box-shadow: inset 10px 10px 30px ${Logo_Gold};
  height: 44px;
  span {
    margin-left: 34px;
    font-size: 30px;
    @media only screen and (max-width: 540px) {
      margin-left: 34px;
    font-size: 20px;
    position: relative;
    top: 6px;
    }
  }
`;
