import tableColumnsFormatter from './tableColumnsFormatter';

export const allActivitiesColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'contact',
      'isCompleted',
      'reviewType',
      'amount',
      'permitId',
      'reviewTypeName',
      'permitName',
      'jurisdictionName',
      'titleName',
      'statusName',
      'typeName',
      'assignedToEmail',
      'type',
      'fees',
      'assignedToFirstName',
      'assignedToLastName',
      'contactId',
      'inspectionDate',
      'followUpDate',
      'attachments',
      'projectId',
      'inspectionType',
      'period',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    default:
      return tableColumnsFormatter(column);
  }
};

export const allActivitiesColumnsOrder = [
  'projectName',
  'permit',
  'jurisdiction',
  'permitNumber',
  'activityType',
  'title',
  'status',
  'information',
  'followUp',
  'assignedTo',
  'lastComment',
  'cellPhone',
  'phoneNumber',
  'period',
  'inspectionType'
];

export const inspectionColumns = column => {
  if (
    [
      'id',
      'isCompleted',
      'isDeleted',
      'isEmergency',
      'documentFile',
      'contact',
      'reviewType',
      'amount',
      'contact',
      'lastComment',
      'trades',
      'title',
      'info',
      'information',
      'report',
      'permitId',
      'reviewTypeName',
      'activityType',
      'permitName',
      // 'permitNumber',
      'projectJurisdiction',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'inspectorFirstName',
      'inspectorLastName',
      'activityTypeName',
      'activityTitleName',
      'statusName',
      'jurisdictionName',
      'typeName',
      'titleName',
      'assignedToEmail',
      'type',
      'contactId',
      'projectId',
      'period'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'status':
      return 'Activity Status';
    case 'permit':
      return 'Permit Name';
    case 'period':
      return 'AM/PM';
    case 'visibleFilename':
      return '';
    default:
      return tableColumnsFormatter(column);
  }
};

export const inspectorColumns = column => {
  if (
    [
      'isCompleted',
      'isDeleted',
      'isEmergency',
      'documentFile',
      'reviewType',
      'amount',
      'lastComment',
      'trades',
      'title',
      'info',
      'information',
      'report',
      'permitId',
      'reviewTypeName',
      'activityType',
      'status',
      'followUp',
      'inspector',
      'permit',
      'assignedTo',
      'phoneNumber',
      'permitName',
      'projectName',
      'projectJurisdiction',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'inspectorFirstName',
      'inspectorLastName',
      'activityTypeName',
      'activityTitleName',
      'statusName',
      'managerFirstName',
      'managerLastName',
      'projectId',
      'contactId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy',
      'inspectionDate',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'phoneNumber':
      return 'Contact Details';
    case 'firstName + lastName':
      return 'Contact';
    case 'id':
      return 'Inspection ID';
    case 'inspectionDate':
      return 'Inspection Date';
    case 'inspectionType':
      return 'Inspection Type';
    case 'jurisdiction':
      return 'Jurisdiction';
    case 'permitNumber':
      return 'Permit Number';
    case 'projectAddress':
      return 'Project Address';
    case 'projectManager':
      return 'Project Manager';
    case 'period':
      return 'AM/PM';
    case 'projectNamePermitName':
      return 'Project Name | Permit Name';
    case 'cellPhone':
      return 'Cell Phone';
    case 'contact':
      return 'Contact';
    case 'contactPhone':
      return 'Contact Phone';
    case 'distance_from_source':
      return '';
    case 'isReportDeleted':
      return '';
    case 'visibleFilename':
      return '';

    default:
      return tableColumnsFormatter(column);
  }
};

export const ipadInspectorColumns = column => {
  if (
    [
      'isCompleted',
      'isDeleted',
      'isEmergency',
      'documentFile',
      'reviewType',
      'amount',
      'lastComment',
      'trades',
      'title',
      'info',
      'information',
      'report',
      'permitId',
      'reviewTypeName',
      'activityType',
      'status',
      'followUp',
      'inspector',
      'permit',
      'assignedTo',
      'phoneNumber',
      'jurisdiction',
      'projectManager',
      'contact',
      'contactPhone',
      'firstName + lastName',
      'projectId'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'phoneNumber':
      return 'Contact Details';
    case 'id':
      return 'Inspection ID';
    case 'inspectionDate':
      return 'Inspection Date';
    case 'inspectionType':
      return 'Inspection Type';
    case 'permitNumber':
      return 'Permit Number';
    case 'projectAddress':
      return 'Project Address';
    case 'period':
      return 'AM/PM';
    case 'projectNamePermitName':
      return 'Project Name | Permit Name';
    case 'cellPhone':
      return 'Cell Phone';
    case 'distance_from_source':
      return '';
    case 'isReportDeleted':
      return '';
    case 'visibleFilename':
      return '';

    default:
      return tableColumnsFormatter(column);
  }
};

export const inspectionColumnsOrder = [
  'projectName',
  'permit',
  'projectAddress',
  'jurisdiction',
  'inspectionNumber',
  'permitNumber',
  'status',
  'inspectionType',
  'inspectionDate',
  'period',
  'inspector',
  'followUp',
  'assignedTo',
];

export const inspectorColumnsOrder = [
  'projectNamePermitName',
  'permitNumber',
  'projectAddress',
  'id',
  'inspectionType',
  'projectManager',
  'jurisdiction',
  'inspectionDate',
  'period',
  'firstName + lastName',
  'phoneNumber',
];

export const ipadInspectorColumnsOrder = [
  'projectNamePermitName',
  'permitNumber',
  'projectAddress',
  'id',
  'inspectionType',
  'inspectionDate',
  'period',
];

export const callsColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'isCompleted',
      'reviewType',
      'amount',
      'activityType',
      'information',
      'permitId',
      'reviewTypeName',
      'fees',
      'feeNames',
      'attachments',
      'conditionName',
      'selectedDocuments',
      'permitName',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'activityTitleName',
      'activityTypeName',
      'projectJurisdiction',
      'statusName',
      'jurisdictionName',
      'typeName',
      'titleName',
      'assignedToEmail',
      'phoneNumber',
      'cellPhone',
      'type',
      'contactId',
      'projectId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    case 'contact':
      return 'Contact Name';
    default:
      return tableColumnsFormatter(column);
  }
};

export const callsColumnsOrder = [
  'projectName',
  'permit',
  'jurisdiction',
  'permitNumber',
  'title',
  'status',
  'contact',
  'followUp',
  'assignedTo',
  'lastComment',
];

export const ConditionColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'isCompleted',
      'reviewType',
      'amount',
      'activityType',
      'information',
      'contact',
      'permitId',
      'reviewTypeName',
      'fees',
      'feeNames',
      'attachments',
      'selectedDocuments',
      'permitName',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'activityTitleName',
      'activityTypeName',
      'projectJurisdiction',
      'statusName',
      'jurisdictionName',
      'typeName',
      'titleName',
      'assignedToEmail',
      'phoneNumber',
      'cellPhone',
      'type',
      'contactId',
      'attachments',
      'projectId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy',
      'reviewTypeComment'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    default:
      return tableColumnsFormatter(column);
  }
};

export const feesColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'isCompleted',
      'reviewType',
      'amount',
      'activityType',
      'information',
      'contact',
      'permitId',
      'reviewTypeName',
      'fees',
      'feeNames',
      'attachments',
      'conditionName',
      'selectedDocuments',
      'permitName',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'activityTitleName',
      'activityTypeName',
      'projectJurisdiction',
      'statusName',
      'jurisdictionName',
      'typeName',
      'titleName',
      'assignedToEmail',
      'phoneNumber',
      'cellPhone',
      'type',
      'contactId',
      'attachments',
      'projectId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    default:
      return tableColumnsFormatter(column);
  }
};

export const feesColumnsOrder = [
  'projectName',
  'permit',
  'jurisdiction',
  'permitNumber',
  'title',
  'status',
  'followUp',
  'assignedTo',
  'lastComment',
];

export const proposalsColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'isCompleted',
      'reviewType',
      'amount',
      'activityType',
      'information',
      'permitId',
      'reviewTypeName',
      'fees',
      'feeNames',
      'attachments',
      'conditionName',
      'selectedDocuments',
      'permitName',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'activityTitleName',
      'activityTypeName',
      'projectJurisdiction',
      'statusName',
      'jurisdictionName',
      'typeName',
      'titleName',
      'assignedToEmail',
      'phoneNumber',
      'cellPhone',
      'type',
      'contactId',
      'projectId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    case 'contact':
      return 'Activity Contact';
    default:
      return tableColumnsFormatter(column);
  }
};

export const proposalsColumnsOrder = [
  'projectName',
  'permit',
  'jurisdiction',
  'permitNumber',
  'title',
  'status',
  'contact',
  'followUp',
  'assignedTo',
  'lastComment',
];

export const reviewsColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'contact',
      'isCompleted',
      'amount',
      'activityType',
      'information',
      'permitId',
      'reviewTypeName',
      'fees',
      'feeNames',
      'attachments',
      'permitName',
      'contactFirstName',
      'contactLastName',
      'companyName',
      'assignedToFirstName',
      'assignedToLastName',
      'activityTitleName',
      'activityTypeName',
      'projectJurisdiction',
      'statusName',
      'jurisdictionName',
      'typeName',
      'titleName',
      'assignedToEmail',
      'phoneNumber',
      'cellPhone',
      'type',
      'contactId',
      'projectId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy',
      'conditionName',
      'selectedDocuments',
      'reviewTypeComment'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    default:
      return tableColumnsFormatter(column);
  }
};

export const reviewsColumnsOrder = [
  'projectName',
  'permit',
  'jurisdiction',
  'permitNumber',
  'reviewType',
  'title',
  'status',
  'reviewer',
  'followUp',
  'assignedTo',
  'lastComment',
];
