import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputTextWithLabel,
  InputBaseContainer,
  CheckboxWithLabel,
  ButtonWithLoader,
} from '../molecules';
import { ActivitiesStatusType } from '../api/activities';

import { FormServerError } from '../types';

import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import useUserRole from '../hooks/useUserRole';

const labels = {
  name: 'Title',
  active: 'Active',
};

const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  isActive: yup.boolean().label(labels.active),
});

const ActivitiesStatusForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new item',
  updateButtonText = 'Update item',
  initialErrors,
  activitiesQuery,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    formState,
  } = useForm<ActivitiesStatusType>({
    resolver: yupResolver(schema),
    defaultValues:
      initialValues || ({} as ActivitiesStatusType),
  });

  const { isSuperAdmin } = useUserRole();

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<ActivitiesStatusType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: ActivitiesStatusType) => void,
  ) => (data: ActivitiesStatusType) => {
    submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      activity: activitiesQuery.id,
    });
  };

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('name', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="isActive"
            control={control}
            valueName="checked"
            defaultValue={
              initialValues
                ? initialValues.isActiveStatus.toLowerCase() ===
                  'true'
                : true
            }
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.isActive}
                label={labels.active}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: ActivitiesStatusType;
  onSubmit: (data: ActivitiesStatusType) => void;
  initialErrors?: FormServerError<ActivitiesStatusType>;
  activitiesQuery?: ActivitiesStatusType;
  modalError: boolean;
}

export default ActivitiesStatusForm;
