import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { formatToFormData } from '../utils';

import {
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  CheckboxWithLabel,
  InputFileContainer,
  ButtonWithLoader,
} from '../molecules';
import {
  DocumentType,
  DocumentFormType,
} from '../api/document';
import { getJurisdictions } from '../api/jurisdiction';
import { getVocabulariesDataList } from '../api/vocabulary';
import { FormServerError } from '../types';

import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import openInNewTab from '../utils/openInNewTab';
import useUserRole from '../hooks/useUserRole';
import FormsTable from '../organisms/FormsTable';

const labels = {
  requirement: 'Document Name',
  jurisdiction: 'Jurisdictions',
  franchise: 'Franchise',
  signature: 'Signature',
  template: 'Template',
  documentFile: 'documentFile',
  work: 'Work',
  building: 'Building',
  isDefault: 'Set as default in new Branch',
};

const schema = yup.object().shape({
  requirement: yup
    .string()
    .required()
    .label(labels.requirement),
  jurisdiction: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a Jurisdictions from the list')
    .nullable()
    .label(labels.jurisdiction),
  work: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a work from the list')
    .label(labels.work),
  building: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a building from the list')
    .label(labels.work),
  signature: yup
    .boolean()
    .required()
    .label(labels.signature),
  template: yup.boolean().required().label(labels.template),
  documentFile: yup.mixed().when('template', {
    is: true,
    then: yup.mixed().required('A file is required'),
  }),
  isDefault: yup.boolean().label(labels.isDefault),
});

const DocumentForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new item',
  updateButtonText = 'Update',
  initialErrors,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    watch,
    setValue,
    formState,
  } = useForm<DocumentType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues
      ? {
          requirement: initialValues?.requirement,
          template: initialValues?.template,
          signature: initialValues?.signature,
        }
      : ({} as any),
  });

  const { isSuperAdmin } = useUserRole();

  const [isLoading, setIsLoading] = React.useState(false);

  const [
    jurisdictionsData,
    setJurisdictionsData,
  ] = React.useState([]);

  const [buildingsData, setBuildingsData] = React.useState(
    [],
  );

  const [worksData, setWorksData] = React.useState([]);

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  const isTemplateChecked = watch('template');

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getJurisdictions({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 1,
        ordering: 'title',
      }), // buildings types
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 2,
        ordering: 'title',
      }), // Works types
    ])
      .then(
        ([
          jurisdictionsResponse,
          buildingsTypesResponse,
          worksTypesResponse,
        ]) => {
          const jurisdiction =
            jurisdictionsResponse.data.results;
          const buildings =
            buildingsTypesResponse.data.results;
          const work = worksTypesResponse.data.results;

          if (initialValues?.jurisdiction) {
            setValue(
              'jurisdiction',
              jurisdiction.filter(item =>
                initialValues.jurisdiction.includes(
                  item.id,
                )),
            );
          }
          if (
            initialValues?.work &&
            initialValues?.building
          ) {
            setValue(
              'work',
              work.find(
                item => item.id === initialValues.work,
              ) as any,
            );
            setValue(
              'building',
              buildings.find(
                item => item.id === initialValues.building,
              ) as any,
            );
          }

          setJurisdictionsData(jurisdiction);
          setWorksData(work);
          setBuildingsData(buildings);
        },
      )
      // eslint-disable-next-line
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<DocumentType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: DocumentType) => void,
  ) => (data: DocumentFormType) => {
    const jurisdictions = `[${data.jurisdiction
      ?.filter(item => item.id)
      .map(item => item.id)
      .join(',')}]`;
    submitFn(
      formatToFormData(
        Object.entries({
          ...data,
          id: initialValues?.id,
          isDeleted: initialValues?.isDeleted,
          jurisdiction: jurisdictions,
          work: [Number(data.work?.id)],
          building: [Number(data.building?.id)],
        }).reduce((acc, [key, value]) => {
          if (
            (initialValues &&
              key === 'template' &&
              typeof acc.documentFile !== 'object') ||
            (key === 'documentFile' &&
              typeof value !== 'object')
          ) {
            if (key === 'template' && value) {
              delete acc.template;
            }

            delete acc.documentFile;

            return acc;
          }

          return {
            ...acc,
            [key]: value,
          };
        }, data),
      ),
    );
  };

  const handleChange = e => {
    setValue('documentFile', e.target.files[0]);
  };

  const handleClick = () => {
    if (initialValues?.documentFile) {
      openInNewTab(initialValues?.documentFile as string);
    }
  };

  const getErrorForJur = () => {
    return {
      ...errors.jurisdiction,
      message: errors.jurisdiction.types.server0,
    };
  };

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="requirement"
            error={errors.requirement}
            inputRef={register}
            label={labels.requirement}
            isRequired={isYupFieldRequired(
              'requirement',
              schema,
            )}
          />
        </InputBaseContainer>

        <InputBaseContainer>
          <Controller
            name="jurisdiction"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                selectAllActive
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.jurisdiction}
                error={
                  errors.jurisdiction && getErrorForJur()
                }
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                options={jurisdictionsData}
                isRequired={isYupFieldRequired(
                  'jurisdiction',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="building"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.building}
                error={errors.building}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.title}
                options={buildingsData}
                isRequired={isYupFieldRequired(
                  'building',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="work"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.work}
                error={errors.work}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.title}
                options={worksData}
                isRequired={isYupFieldRequired(
                  'work',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="signature"
            control={control}
            valueName="checked"
            defaultValue={initialValues?.signature || false}
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.signature}
                label={labels.signature}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
          <Controller
            name="template"
            control={control}
            valueName="checked"
            defaultValue={initialValues?.template || false}
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.template}
                label={labels.template}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        {initialValues?.visibleFilename &&
          isTemplateChecked && (
            <InputBaseContainer>
              <DocumentLink
                onClick={handleClick}
                type="button"
              >
                View document
              </DocumentLink>
            </InputBaseContainer>
          )}
        {isTemplateChecked && (
          <InputBaseContainer>
            <Controller
              name="documentFile"
              control={control}
              defaultValue={
                initialValues?.documentFile || null
              }
              render={() => (
                <InputFileContainer
                  onChange={handleChange}
                  error={errors.documentFile}
                  accept="application/pdf"
                  isRequired={isYupFieldRequired(
                    'documentFile',
                    schema,
                  )}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {isSuperAdmin && (
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
      {initialValues ? (
        <FormTableWrapper>
          <FormsTable forms={initialValues?.forms} />
        </FormTableWrapper>
      ) : null}
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

const DocumentLink = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  color: var(--primary);
  text-decoration: underline;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
`;

const FormTableWrapper = styled.div`
  margin-top: 20px;
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: DocumentType;
  onSubmit: (data: DocumentType) => void;
  initialErrors?: FormServerError<DocumentType>;
  modalError: boolean;
}

export default DocumentForm;
