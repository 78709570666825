import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import InspectionTable from '../Dashboard/DashboardTables/InspectionTable';
import InspectionsControls from './Controls/InspectionsControls';
import { ScreenType } from './PermitInspectionsDetails';
import { downloadInspectionReport } from '../../../../../api/inspectionTypes';
import { updatePermitInspection } from '../../../../../api/inspections';
import { downloadFile, findInspection, validateInspectionDate } from '../../../utils';
import { format } from 'date-fns';
import { setInspectionApiResults, setPermitInspectionsList, setSelectedInspection, setSelectedPermit } from '../../../../../redux/slices/inspectionsSlice';
import { InspectionItemType, PermitInspectionsType } from '../../../types';
import BottomDrawer from '../../Common/Containers/BottomDrawer';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';

const EditInspectionPopup: FC = () => {
  const { selectedInspection, selectedPermit, permitInspectionsList } = useSelector((s: RootState) => s.inspections);
  const schedules = useSelector((s: RootState) => s.general.schedules);
  const [inspectionDate, _setInspectionDate] = useState<string>('');
  const [invalidMessage, setInvalidMessage] = useState<string>(null);
  const [notes, _setNotes] = useState<string>('');
  const [showClose, setShowClose] = useState<boolean>(false);
  const dispatch = useDispatch();

  const setNotes = (data: string) => {
    _setNotes(data);
    setShowClose(false);
    dispatch(setInspectionApiResults({apiMessage: undefined, isApiError: false}))
  }

  const setInspectionDate = (data: string) => {
    _setInspectionDate(data);
    setShowClose(false);
    dispatch(setInspectionApiResults({apiMessage: undefined, isApiError: false}))
  }
  useEffect(() => {
    setInspectionDate(selectedInspection.inspectionDate);
    setNotes(selectedInspection.information);
  }, [selectedInspection]);

  const onUpdate = async () => {
    if(showClose) {
      setShowClose(false);
      dispatch(setBottomDrawerOpened(false));
    }
    const reductedInspection = { ...selectedInspection };
    delete reductedInspection.report;
    const _inspection = {
      ...reductedInspection,
      inspectionDate: format(new Date(inspectionDate), 'MM/dd/yyyy'),
      inspection_date: format(new Date(inspectionDate), 'MM/dd/yyyy'),
      created_by: selectedInspection.contact,
      information: notes,
    };
    const validation = validateInspectionDate(new Date(inspectionDate), schedules);
    setInvalidMessage(validation);
    if (validation === null) {
      try {
        const result = await updatePermitInspection(_inspection);
        dispatch(setInspectionApiResults({apiMessage: 'Success', isApiError: false}));
        setShowClose(true);

        //updating the selected permit, so the UI gets refreshed
        const _permit = {
          ...selectedPermit,
          inspections: selectedPermit.inspections.map((selectedInspection: InspectionItemType) => {
            if (selectedInspection.id === _inspection.id) {
              return _inspection;
            }
            return selectedInspection;
          }),
        };
        dispatch(setSelectedPermit(_permit));

        //updating the main permit-inspection list with the latest inspection
        const _updateList = permitInspectionsList.map(item => {
          if (item.permitId === selectedPermit.permitId) {
            return _permit;
          }
          return item;
        });
        dispatch(setPermitInspectionsList(_updateList));
      } catch (e) {
        setShowClose(true);
        dispatch(setInspectionApiResults({apiMessage: JSON.stringify(e.response.data), isApiError: true}));
      }
    }
  };
  return (
    <BottomDrawer submitLabel= {showClose ? "Close" : "Update"} onSubmit={onUpdate}>
      <ControlsArea>
        <ControlsLabel>Update Inspection</ControlsLabel>
        <InspectionsControls
          invalidMessage={invalidMessage}
          inspection={selectedInspection}
          setInspectionDate={setInspectionDate}
          notes={notes}
          setNotes={setNotes}
        />
      </ControlsArea>
    </BottomDrawer>
  );
};

export default EditInspectionPopup;

const ControlsArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 30px 1fr;
`;

const ControlsLabel = styled.div`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
`;
