import * as React from 'react';
import styled from 'styled-components';
import {
  Button,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import { SubMenuLink } from '../constants/menu';
import { SidebarSubMenuItem } from './index';

const DropdownButton = ({ links, basePath }: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen(prev => !prev);
  };

  const handleClose = event => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <Wrapper>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        Setup
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom'
                  ? 'center top'
                  : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <ItemsWrapper>
                    {links.map(element => (
                      <ButtonWrapper>
                        <SidebarSubMenuItem
                          key={element.id}
                          to={element.linkTo}
                          title={element.name}
                          basePath={basePath}
                        />
                      </ButtonWrapper>
                    ))}
                  </ItemsWrapper>
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Wrapper>
  );
};

interface Props {
  links: Array<SubMenuLink>;
  basePath: string;
}

const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  button {
    font-size: 16px;
    font-weight: normal;
    text-transform: none;
  }
`;

const ButtonWrapper = styled.li`
  width: 80px;
  margin: 10px 0;
  & > div {
    margin-left: 0;
  }
`;

const ItemsWrapper = styled.div`
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

const StyledMenuList = styled(MenuList)`
  padding: 0;
`;

export default DropdownButton;
