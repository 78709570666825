import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ButtonMain } from '../atoms';

const NotFoundContent = ({ buttonText, route }: Props) => {
  const history = useHistory();

  return (
    <Container>
      <Description>Page not found</Description>
      <ButtonMain onClick={() => history.push(route)}>
        {buttonText}
      </ButtonMain>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Description = styled.p`
  color: var(--secondaryText);
`;

interface Props {
  buttonText: string;
  route: string;
}

export default NotFoundContent;
