
/* eslint-disable function-paren-newline */
import * as React from 'react';
import styled, {css} from 'styled-components';
import useTablePage from '../hooks/useTablePage';
import { ModalDefaultDraggable, InputDatepickerRange, InputBaseContainer, ButtonWithLoader,} from '../molecules';
import { DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS } from '../constants';
import InspectorDashboardBlocks from "./InspectorDashboardBlocks";
import { Controller, useForm } from "react-hook-form";
import { FormsReportType, ReportTableType } from "../api/reports";
import { DatePicker} from "../atoms";
import { getActivitiesList, getActivitiesStatusList } from "../api/activities";
import { getCurrentUser } from "../api/userSettings";
import formatDate from "../utils/formatDate";
import useManuallyUpdate from "../hooks/useManuallyUpdate";
import { useReactToPrint } from "react-to-print";
import DailyInspectorReport from "./Reports/InspectionReports/DailyInspectorReport";
import FormsReport from "./Reports/FormsReport";
import useDevice from '../hooks/useDevice';

const DashboardInspectionsPage = ({
  onSubmit,
}: Props) =>
{
 const [dates, setDates] = React.useState(
    {} as { fromDate: Date; toDate: Date });
 const {
    errors,
    control,
    handleSubmit,
    } = useForm<ReportTableType>({
    mode: 'onChange',
  });
 const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();
 const [
    isValidForExport,
    setIsValidForExport,
  ] = React.useState(false);
  const getDate = () => {
    const newDate = new Date();
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const d = newDate.getDate();
        return `${month.toString().padStart(2, '0')}/${d.toString().padStart(2, '0')}/${year}`;
  }
  const [
    reportsSettings,
    setReportsSettings,
  ] = React.useState(null);

  const dailyTableRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => dailyTableRef.current,
   });
  const exportToPdf = () => {
      handlePrint();
    };

  var inspectionActivityID = [];
  const activityIds = data => {
     inspectionActivityID.push(data);
  };

  var activityStatus = [];
  const activityIds2 = data => {
     activityStatus.push(data);
  };

 const [
    inspectionStatuses,
    setInspectionStatus
 ] = React.useState([]);

 const [key, setKey] = React.useState(1);
 const onChangeKey = () => {
    setKey(key + 1);
  };
 const [currentUser, setCurrentUser] = React.useState(
    null,
  );

 const {
    isModalOpen,
    modalError,
    modalSuccess,
    modalTitle,
    handleAdd,
    handleCloseModal,
    modalErrorTitle,
    handleCloseModalInfo
   } = useTablePage({
    modalCreateTitle: 'Print Inspection Form',
  });
  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };
  const [forms, setForms] = React.useState<
  Array<FormsReportType>
>([]);
  const printableRef = React.useRef();
  const handlePrintInspectionForm = useReactToPrint({
    content: () => printableRef.current,
  });

  const formExportToPdf = () => {
      handlePrintInspectionForm();
  };
  const [
    selectedFormsReports,
    setSelectedFormsReports,
  ] = React.useState<Array<FormsReportType>>([]);

  const [isIpad] = useDevice()

  React.useEffect(() => {
    Promise.all([
      getCurrentUser(),
    ]).then(
      ([
        currentUserResponse,
      ]) => {
          setCurrentUser(currentUserResponse.data.id);
         },
    );
  }, []);

  React.useEffect(() => {
      Promise.all([
        getActivitiesList({
          ...GET_ALL_QUERY_PARAMS,
          activityType: 8,
        }),
        getActivitiesStatusList({
          ...GET_ALL_QUERY_PARAMS,
        }),
      ]).then(
        ([
          activitiesList,
          activitiesStatusList,
        ]) => {

          const activitiesTitles = activitiesList.data.results?.filter(
            el => el?.isActiveStatus === 'True',
            );

            activitiesTitles.forEach(item => {
              for (let key in item) {
                if (`${key}` === 'id')
                {
                  activityIds(`${item[key]}`);
                }
              }
            });

            const activitiesStatusLists = activitiesStatusList.data.results?.filter(
              el => el?.isActiveStatus === 'True' && el?.name === 'Pending'
              );

              for (let i = 0; i < inspectionActivityID.length; i++) {
                for (let j = 0; j < activitiesStatusLists.length; j++) {
                  if (inspectionActivityID[i] === activitiesStatusLists[j].activity.toString()) {
                    activityIds2(activitiesStatusLists[j].id);
                    break;
                  }
                }
              }
              setInspectionStatus(activityStatus);

            });
          }, []);

 const middleware = (submitFn: (data) => void) => data => {
    setReportsSettings(
                {
                    fromDate:
                        formatDate(
                            dates?.fromDate,
                            'yyyy-MM-dd',
                        ),
                    toDate:
                        formatDate(
                            dates?.toDate,
                            'yyyy-MM-dd',
                        ),
                    inspector: currentUser,
                    inspectionStatus: inspectionStatuses,
                });

    exportToPdf();
   };

 const formsMiddleware = (submitFn: (data) => void) => data => {
    setReportsSettings(
                {
                    formsDate:
                        formatDate(
                            dates?.fromDate,
                            'yyyy-MM-dd',
                        ),
                    inspector: currentUser,
                });
    handleAdd();
    // formExportToPdf()
  };

  return (
    <>
      <Container>
      <InspectorDashboardBlocks />
        <LabelWrapper>
          <Header>Daily Inspections</Header>
        </LabelWrapper>
        {/* EA-34 make responsive for ipad
        mritunjoy */}
        <InnerContainer style={isIpad ? {display: 'flex', flexDirection: 'column', alignItems: 'center'} : {}}>
          <FormContainer
            autoComplete="off"
            aria-autocomplete="none"
          >
            <InputBaseContainer>
               <DateWrapper>
              <Controller
                name="formsDate"
                defaultValue=""
                control={control}
                render={({ value, onChange }) => (
                  <DatePicker
                    onChange={(start:Date)=> {
                        setDates({fromDate: start, toDate: start});
                        setReportsSettings(
                        {
                            fromDate:
                                formatDate(start),
                            toDate:
                                formatDate(start),
                            inspectionStatus: inspectionStatuses,
                               });
                       updateTable();
                          }
                    }
                    selected={dates.fromDate ? dates.fromDate : new Date(getDate())}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    placeholderText="Select Date"
                    required
                   />
                )}
              />
            </DateWrapper>
            </InputBaseContainer>

            {/*<ButtonContainer>
              <ButtonMain type="button">See My Route</ButtonMain> Commenting this as button no longer required due to approach change
            </ButtonContainer>*/}
            <><ButtonContainer>
              <ButtonWithLoader
                variant="outlined"
                disabled={!isValidForExport}
                onClick={handleSubmit(middleware(onSubmit))}
              >
                Print Report
              </ButtonWithLoader>
            </ButtonContainer>
              <ButtonWithLoader
                variant="outlined"
                disabled={!isValidForExport}
                onClick={handleSubmit(formsMiddleware(onSubmit))}

               >
                Inspection Form
              </ButtonWithLoader></>
             {isModalOpen && (
              <ModalDefaultDraggable
                onClose={handleCloseForm}
                title={modalTitle}
                extraWide
                showPrintButton
                printRef={printableRef}
              >
              {reportsSettings &&
                  <FormsReport
                      key={key}
                      setSelectedFormsReports={
                          setSelectedFormsReports
                      }
                      selectedFormsReports={selectedFormsReports}
                      setIsValidForExport={setIsValidForExport}
                      ref={printableRef}
                      forms={forms}
                      setForms={setForms}
                      {...reportsSettings}
                  />
              }
              </ModalDefaultDraggable>
            )}
          </FormContainer>
        </InnerContainer>
        {tableManuallyUpdate && updateTable &&
        <DailyInspectorReport
          modalErrorTitle={modalErrorTitle}
          handleCloseModalInfo={handleCloseModalInfo}
          modalError={modalError}
          key={key}
          ref={dailyTableRef}
          setIsValidForExport={setIsValidForExport}
          manuallyUpdate={tableManuallyUpdate}
          updateTable={updateTable}
          {...reportsSettings}
        />
        }

      </Container>
      {isIpad && <><br /> <br /></>}
    </>
  );
};

const Container = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  padding: 6px;
  height: 50px;
  justify-content: center;
 `;

const InnerContainer = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: -24px;
  padding: 0 24px;
  @media only screen and (max-width: 840px) {
    height: 125px;
  }
`;

const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  width: 300px;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  @media only screen and (max-width: 840px) {
    width:100%;
  }
`;

const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 840px) {
    justify-content: space-between;
    flex-direction:column;
    height: 100px;
    position: relative;
    left: -56px;
    margin: auto;
  }
  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  > .MuiButton-root {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const DateWrapper = styled.div`
  input {
    cursor: pointer;
    width: 148px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 16px;
    font-size: 14px;
    &::placeholder {
      color: #808080;
      font-size: 16px;
      position: relative;
    }
  }
`;

export interface Props {
  onSubmit: (data: ReportTableType) => void;
  onExportToPDF: (data: ReportTableType) => void;
}

export default DashboardInspectionsPage;
