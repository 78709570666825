import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import InspectionsPage from '../../pages/Permits/InspectionsPage';
import { setPermitId, setShowPermitTable } from '../../redux/slices/globalSearch';

const InspectionPermitsTable = () => {
  const { permitId, showPermitTable } = useSelector((s: RootState) => s.globalSearch);

  return (
    <div>
      {showPermitTable && ( 
          <InspectionsPage
            permit={permitId}
            isAddInspection={false}
            setAddInspection={()=>{}}
            isReadOnly={true}
          />
      )}
    </div>
  );
};

export default InspectionPermitsTable;
