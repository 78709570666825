import * as yup from 'yup';

export const labels = {
  name: 'Name',
  description: 'Description',
  jurisdictions: 'Jurisdictions',
  type: 'Path',
  isDefault: 'Default',
  documents: 'Documents',
  reportScope: 'User Module',
};

export const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  file: yup.mixed().required(),
  jurisdictions: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a jurisdiction from the list')
    .nullable()
    .label(labels.jurisdictions),
  type: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a jurisdiction from the list')
    .nullable()
    .label(labels.type),
  description: yup
    .string()
    .label(labels.description)
    .required()
    .typeError('Description required'),
  isDefault: yup.boolean().label(labels.isDefault),
  documents: yup
    .array()
    .when('type', {
      is: type => type.value === '0',
      then: yup
        .array()
        .required()
        .typeError('Select a document from the list'),
    })
    .label(labels.documents)
    .nullable(),
  reportScope: yup
    .array()
    .when('type', {
      is: type => type.value === '1',
      then: yup
        .array()
        .required()
        .typeError('Select a franchise from the list'),
    })
    .label(labels.reportScope)
    .nullable(),
});
