import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Pagination } from '../../atoms';
import { MetaContainer } from '../../organisms/Table';
import { TABLE_DEFAULT_PAGE_SIZES } from '../../constants';
import { RootState } from '../../redux/root';
import { setPageIndex, setPageSize } from '../../redux/slices/paginationSlice';

const PaginationMobile:FC<{showEverywhere?: boolean}> = ({showEverywhere}) => {
    const {
        pageSize,
        pageCount,
        pageIndex,
    } = useSelector((state: RootState) => state.pagination);
    const dispatch = useDispatch();
    const gotoPage = (arg:number) => {
        dispatch(setPageIndex(arg));
    }

    return (
      <MetaContainer>
        <MetaContainerMobile showEverywhere={showEverywhere}>
          <div>
            {pageCount > 1 && (
              <Pagination
                pageSize={pageSize}
                current={pageIndex + 1}
                total={pageCount * pageSize}
                onChange={pageNumber => gotoPage(pageNumber - 1)}
              />
            )}
          </div>
          <select
            value={pageSize}
            onChange={e => dispatch(setPageSize(Number(e.target.value)))}
          >
            {TABLE_DEFAULT_PAGE_SIZES.map(ps => (
              <option key={ps} value={ps}>
                {ps}
              </option>
              ))}
          </select>
        </MetaContainerMobile>
      </MetaContainer>
    );
};

const MetaContainerMobile = styled.div<{showEverywhere?: boolean}>`
    display: ${({showEverywhere}) => showEverywhere ? 'block' : 'none'};
    width: ${({showEverywhere}) => showEverywhere ? '100%' : 'inherit'};
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 840px) {
        
        width: 100%;
    }
`;
export default PaginationMobile;
