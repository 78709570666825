import * as React from 'react';
import styled from 'styled-components';

import { BreadCrumbs } from '../molecules';
import GenerateReportForm, {
  Props as FormProps,
} from '../forms/GenerateReportForm';
import GenerateEmployeeReportForm from '../forms/GenerateEmployeeReportForm';

import useUserRole from '../hooks/useUserRole';

const ActionsPanelReports = ({
  onSubmit,
  isLoading,
  onExportToPDF,
  onChangeKey,
  isValidForExport,
  inspectionFormClick,
  setInspectionFormClick,
  setReportsSettings,
  forms,
  setForms
}: any) => {
  const { isEmployee } = useUserRole();

  return (
    <Container>
      <Part>{isEmployee ? ' ' : <BreadCrumbs />}</Part>
      <Part>
        {isEmployee ? (
          <GenerateEmployeeReportForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            onExportToPDF={onExportToPDF}
            onChangeKey={onChangeKey}
            isValidForExport={isValidForExport}
            setInspectionFormClick={setInspectionFormClick}
            inspectionFormClick={inspectionFormClick}
            setReportsSettings={setReportsSettings}
            forms={forms}
            setForms={setForms}
          />
        ) : (
          <GenerateReportForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            onExportToPDF={onExportToPDF}
          />
        )}
      </Part>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
`;

const Part = styled.div`
  display: flex;

  > * {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiTextField-root,
  .MuiInputBase-root {
    border-radius: 5px;
  }

  .MuiButton-root,
  .MuiInputBase-root {
    height: 30px;
  }
`;

type Props = Pick<
  FormProps,
  'onSubmit' | 'isLoading' | 'onExportToPDF'
>;

export default ActionsPanelReports;
