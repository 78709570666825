import { FormSetupFieldType } from '../types';

const findFormSetupField = (
  activeFields: Array<FormSetupFieldType>,
  parents: Array<string>,
  value: string,
) => {
  let currentIndex = null;
  const currentField = activeFields?.find((el, index) => {
    const isEqualParents = el?.parents?.every(
      (parent, idx) => parent === parents[idx],
    );
    if (el?.value === value && isEqualParents) {
      currentIndex = index;
    }
    return el?.value === value && isEqualParents;
  });
  return { currentIndex, currentField };
};

export default findFormSetupField;
