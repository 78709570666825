import React, { FC, useState } from 'react';
import { DatePicker } from '../../../../../atoms';
import styled from 'styled-components';
import { DEFAULT_DATE_FORMAT } from '../../../../../constants';

type Props = {
  value: any;
  onChange: (value)=>void;
}
const DatePickerControl:FC<Props> = ({value, onChange}) => {
  // const onChange = (value) => {
  //   setInspectionFollowUp(value);
  //   if (isActivityTable) {
  //     columnFilterUi('followUp', formatDate(value, DEFAULT_DATE_FORMAT));
  //   } else {
  //     setFilter('followUp', formatDate(value, DEFAULT_DATE_FORMAT));
  //   }
  // }
  return (
    <DatePickerWrapper>
      <DatePicker                
        dateFormat={DEFAULT_DATE_FORMAT}   //@ts-ignore
        selected={value}   
        onChange={onChange}
      />
    </DatePickerWrapper>
  );
};

export default DatePickerControl;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker__navigation--previous {
    left: 10px;
    border: 0.45rem solid transparent;
    border-right-color: #ccc;
  }

  .react-datepicker__navigation--next {
    border: 0.45rem solid transparent;
    border-left-color: #ccc;
  }
`;

const RemoveDateButton = styled.div`
  font-weight: bold;
  padding: 2px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
`;