import * as React from 'react';
import styled from 'styled-components';

import userSettings from '../api/userSettings';
import ButtonWithLoader from './ButtonWithLoader';

let pollOAuth = null;
let win = null;

const QuickbooksRenewButton = () => {
  const [message, setMessage] = React.useState<string>(
    null,
  );
  const [hasError, setHasError] = React.useState<boolean>(
    false,
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  const onMessage = event => {
    const eventMessage = event.data?.detail;

    if (eventMessage) {
      setMessage(eventMessage);

      if (eventMessage !== 'Success') {
        setHasError(true);
      }

      setIsLoading(false);

      clearInterval(pollOAuth);
      win.close();
      win = null;
      pollOAuth = null;
    }
  };

  React.useEffect(() => {
    let timeout = null;

    if (message) {
      timeout = setTimeout(() => {
        setMessage(null);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [message]);

  React.useEffect(() => {
    window.addEventListener('message', onMessage);

    return () =>
      window.removeEventListener('message', onMessage);
  }, []);

  const handleClick = () => {
    setIsLoading(true);
    setMessage(null);
    setHasError(false);

    userSettings
      .quickbooksOAuth()
      .then(response => {
        if (response.data.url) {
          const left = (window.screen.width - 800) / 2;
          const top = (window.screen.height - 600) / 2;

          const parameters = `location=1,width=800,height=600,left=${left},top=${top}`;

          win = window.open(
            response.data.url,
            'connectPopup',
            parameters,
          );

          if (!win) {
            setIsLoading(false);
            // eslint-disable-next-line no-alert
            alert(
              'Your browser blocked popup window! Please, unblock it!',
            );
          }

          pollOAuth = window.setInterval(() => {
            win.postMessage('get_status', '*');

            if (win.closed) {
              setHasError(true);
              setMessage(
                'Error: Renewal process is not completed!',
              );

              window.clearInterval(pollOAuth);
              pollOAuth = null;

              setIsLoading(false);
            }
          }, 100);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      <ButtonWithLoader
        loading={isLoading}
        onClick={handleClick}
      >
        Renew Quickbooks integration
      </ButtonWithLoader>
      {message && (
        <Message hasError={hasError}>{message}</Message>
      )}
    </>
  );
};

const Message = styled.div<{ hasError: boolean }>`
  color: ${props =>
    props.hasError
      ? 'var(--mainRequired)'
      : 'var(--primary)'};
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
`;

export default QuickbooksRenewButton;
