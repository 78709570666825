import React from 'react';
import { Pagination, TableWrapper, commonFilterMeta } from '../../Common/Table/types';
import Table from '../../Common/Table/Table';
import PaginationMobile from '../../../../../pages/Responsive/PaginationMobile';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { headers } from '../Inspections/InspectionsTable';
import useOnCommonTableActivity from '../../../hooks/useOnCommonTableActivity';
import { FeeItemType, PermitFeeItem } from '../../../types';
import { setSelectedFee } from '../../../../../redux/slices/feesSlice';

const FeesTable = () => {
    useGetNewProjectData();
  const dispatch = useDispatch();
  const feesList = useSelector((s: RootState) => s.fees.inspectionFeesList);
  const { visibleItems } = useOnCommonTableActivity(feesList);

  const onPermitFeeSelected = (item: PermitFeeItem) => {
    dispatch(setSelectedFee(item));
  }
  return (
    <TableWrapper>
      <Table
        collection={visibleItems}
        headers={headers}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 138px 138px 90px"
        setSelectedItemInSlice={onPermitFeeSelected}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default FeesTable;
