import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { FranchiseType } from '../../api/franchise';

import { APIFormErrors } from '../../types';

import {
  getStoredFranchiseInfo,
  setFranchiseData,
} from '../../utils/franchiseHelpers';

const initialState: FranchiseSettingsStateType = {
  franchiseInfo: getStoredFranchiseInfo() || null,
  responseHandled: true,
  activeRequest: false,
  errors: null,
};

const reducerName = 'franchiseSettings';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setActiveRequest: state => {
      state.activeRequest = true;
    },
    setFranchiseInfo: (
      state,
      action: PayloadAction<FranchiseType>,
    ) => {
      state.franchiseInfo = action.payload;
      state.activeRequest = false;

      setFranchiseData(action.payload);
    },
    requestFailure: (
      state,
      action: PayloadAction<APIFormErrors>,
    ) => {
      state.activeRequest = false;
      state.errors = action.payload;
    },
    setResponseHandled: state => {
      state.responseHandled = true;
    },
    clearFranchiseInfo: () => initialState,
    clearRequestError: state => {
      state.errors = null;
    },
  },
});

export const {
  setActiveRequest,
  setResponseHandled,
  setFranchiseInfo,
  requestFailure,
  clearRequestError,
} = slice.actions;

export const franchiseInfoSelector = state =>
  state.franchiseSettings.franchiseInfo;

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export interface FranchiseSettingsStateType {
  franchiseInfo?: FranchiseType;
  responseHandled: boolean;
  activeRequest: boolean;
  errors: APIFormErrors | null;
}

export default slice.reducer;
