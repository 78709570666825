import * as React from 'react';
import styled from 'styled-components';
import ArrowPointer from '../atoms/ArrowPointer';

const ArrowPointerBlock = ({
  process,
  currentStatus,
  index,
}: Props) => {
  const isTitleActive = process.statuses.includes(
    currentStatus,
  );

  const subProcess = process.subProcesses.find(
    el => el.id === currentStatus,
  )?.title;

  return (
    <ArrowBlockWrapper
      isWide={process.subProcesses.length >= 3}
    >
      <ArrowPointer
        size="large"
        active={isTitleActive}
        index={index}
      >
        <p style={{ margin: 0 }}>{process.title}</p>
        {subProcess && (
          <p style={{ margin: 0, fontSize: 13 }}>
            {`(${subProcess})`}
          </p>
        )}
      </ArrowPointer>
    </ArrowBlockWrapper>
  );
};

const ArrowBlockWrapper = styled.div<WrapperProps>`
  // width: ${({ isWide }) => (isWide ? '30' : '23')}%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const ArrowsSmallBlock = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
// `;

interface Props {
  process: Process;
  currentStatus: number;
  index: number;
}

interface Process {
  title: string;
  subProcesses: SubProcess[];
  statuses: number[];
}

interface SubProcess {
  title: string;
  id: number;
}

interface WrapperProps {
  isWide: boolean;
}

export default ArrowPointerBlock;
