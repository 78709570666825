import * as React from 'react';
import styled, { css } from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { format } from 'date-fns';
 
import { ButtonMain, DatePicker } from '../atoms';
 
import { FormServerError } from '../types';
 
import {
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';
 
import {
  ReportType,
  ReportTableType,
  getCompanies,
  getManagers,
  getReportManagers,
} from '../api/reports';
import { getJurisdictions } from '../api/jurisdiction';
import { getUsers } from '../api/users';
 
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
 
import { SALES_EMPLOYEE_REPORTS_DATA } from '../__DATA__';
 
import {
  InputSelect,
  InputText,
  ButtonWithLoader,
  InputBaseContainer,
  InputDatepickerRange,
  InputTextWithLabel,
} from '../molecules';
import formatDate from '../utils/formatDate';
import { InputLabel, TextField } from '@material-ui/core';
import { getPermitNames } from '../api/permits';
 
const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '180px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};
 
const labels = {
  dailyInspectionReport: 'dailyInspectionReport',
  runnerReport: 'runnerReport',
  formsReport: 'formsReport',
  inspectionByPermitReport: 'inspectionByPermitReport',
  businessToBeInvoicedReport: 'businessToBeInvoicedReport',
  companyOutstandingFeesReport:'companyOutstandingFeesReport',
  notInvoicedPermittingFeesReport: 'notInvoicedPermittingFeesReport',
  paidFeesReport: 'paidFeesReport',
  inspectorTimeTrackingReport: 'inspectorTimeTrackingReport',
  planReviewerTimeTrackingReport: 'planReviewerTimeTrackingReport',
  permitReviewCommand:'permitReviewCommand'
};
 
const schema = yup.object().shape({
  report: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a report from the list')
    .label('Report'),
  range: yup
    .array()
    .of(yup.date())
    .when('report', {
      is: labels.dailyInspectionReport,
      then: yup.array().of(yup.date()).required(),
    })
    .typeError('Select date')
    .label('Range'),
  inspector: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Inspector from the list')
    .label('Inspector'),
    permitReviewComment: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a permits from the list')
    .label('Permits'),
  jurisdiction: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Jurisdiction from the list')
    .label('Jurisdiction'),
    companyName: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Company from the list')
    .label('Company Name'),
    projectManager: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Project Manager from the list')
    .label('Project Manager'),
});
 
const GenerateEmployeeReportForm = ({
  onSubmit,
  isLoading,
  onExportToPDF,
  initialErrors,
  onChangeKey,
  isValidForExport,
  inspectionFormClick,
  setInspectionFormClick,
  setReportsSettings,
  forms,
  setForms
}: Props) => {
  const {
    errors,
    control,
    setError,
    trigger,
    getValues,
    handleSubmit,
    watch,
  } = useForm<ReportTableType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
 
  const [isFetching, setIsFetching] = React.useState(false);
  const [permit, setPermit] = React.useState([]);
 
  const report = watch('report');
  // EA-88
  // mritunjoy
  const selectedCompanyName = watch('companyName');
  // EA-94
  // mritunjoy
  const selectedprojectManager = watch('projectManager');
  const formsDateValue = watch('formsDate');
  const selectedInspector = watch('inspector')
  const selectPermit =watch('permitReviewComment')
 
  const handleReportSettings = () => {
    if(formsDateValue && selectedInspector) {
    setInspectionFormClick(true)
    setReportsSettings({
      formsDate: formatDate(
        formsDateValue,
        DEFAULT_DATE_FORMAT,
      ),
      inspector: selectedInspector?.id,
      selectedReport: 'formsReport',
      report:  {
        "value": "formsReport",
        "label": "Forms Report"
    },
    })
  }
  }
 
  const [
    inspectorsData,
    setInspectorsData,
  ] = React.useState([]);
  const [
    jurisdictionsData,
    setJurisdictionsData,
  ] = React.useState([]);
 
  const [
    companyData,
    setCompanyData,
  ] = React.useState([]);
  const [
    projectManagers,
    setProjectManagers,
  ] = React.useState([]);
 
  const [
    selectedReport,
    setSelectedReport,
  ] = React.useState(null);
 
  React.useEffect(() => {
    setIsFetching(true);
 
    getUsers({
      ...GET_ALL_QUERY_PARAMS,
      userRole: USER_TYPES_VALUES.INSPECTOR,
    })
      .then(response => {
        setInspectorsData(response.data.results);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);
 
  React.useEffect(() => {
    if (selectedReport === labels.runnerReport) {
      setIsFetching(true);
      setSelectedReport(labels.runnerReport);
 
      getJurisdictions({
        ...GET_ALL_QUERY_PARAMS,
      })
        .then(response =>
          setJurisdictionsData(response.data.results))
        .catch(() => {})
        .finally(() => setIsFetching(false));
    }
    if (selectedReport === labels.permitReviewCommand) {
      setIsFetching(true);
      Promise.all([getPermitNames(true)])
        .then(([permitsResponse]) => {
          setPermit(permitsResponse?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setIsFetching(false));
    }
    // EA-88
    // mritunjoy
    if (selectedReport === labels.businessToBeInvoicedReport || selectedReport === labels.companyOutstandingFeesReport) {
      setIsFetching(true);
      // setSelectedReport(labels.businessToBeInvoicedReport);
 
      getCompanies({
        ...GET_ALL_QUERY_PARAMS,
        user_role: 3,
        client_type: 2
      })
        .then(response => {       
          const clean = (a:string) => a.toLowerCase().trim();
          const companiesSet = [];
          response.data.results.forEach(company => {
            const _company = companiesSet.find(c => c.companyName === company.companyName);
              if(!_company){
                companiesSet.push(company);
              }
          })
          const companies = companiesSet.sort((a,b) => {
            if(clean(a.companyName) > clean(b.companyName)) return 1;
            if(clean(a.companyName) < clean(b.companyName)) return -1;
            return 0;
          });

          setCompanyData(companies)
        })
        .catch(() => {})
        .finally(() => setIsFetching(false));
    }
    // EA-94
    // mritunjoy
    if (selectedReport === labels.notInvoicedPermittingFeesReport) {
      setIsFetching(true);
      setSelectedReport(labels.notInvoicedPermittingFeesReport);
 
      getReportManagers()
        .then(response =>
          setProjectManagers(response.data))
        .catch(() => {})
        .finally(() => setIsFetching(false));
    }
    // EA-85
    // mritunjoy
    if (selectedReport === labels.paidFeesReport) {
      setIsFetching(true);
      setSelectedReport(labels.paidFeesReport);
 
      getReportManagers()
        .then(response =>
          setProjectManagers(response.data))
        .catch(() => {})
        .finally(() => setIsFetching(false));
    }
  }, [selectedReport]);
 
 
  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<ReportTableType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);
 
  React.useEffect(() => {
    if (report) {
      switch (report.value) {
        case labels.dailyInspectionReport:
          setSelectedReport(labels.dailyInspectionReport);
          break;
        case labels.runnerReport:
          setSelectedReport(labels.runnerReport);
          break;
        case labels.formsReport:
          setSelectedReport(labels.formsReport);
          break;
          // EA-86
          // mritunjoy
        case labels.inspectionByPermitReport:
          setSelectedReport(labels.inspectionByPermitReport);
          break;
        // EA-88
        // mritunjoy
        case labels.businessToBeInvoicedReport:
          setSelectedReport(labels.businessToBeInvoicedReport);
          break;
        case labels.companyOutstandingFeesReport:
          setSelectedReport(labels.companyOutstandingFeesReport);
          break;
        // EA-88
        // mritunjoy
        case labels.notInvoicedPermittingFeesReport:
          setSelectedReport(labels.notInvoicedPermittingFeesReport);
          break;
        // EA-85
        // mritunjoy
        case labels.paidFeesReport:
          setSelectedReport(labels.paidFeesReport);
          break;
        // EA-103
        // mritunjoy
        case labels.inspectorTimeTrackingReport:
          setSelectedReport(labels.inspectorTimeTrackingReport);
          break;
        // EA-105
        // mritunjoy
        case labels.planReviewerTimeTrackingReport:
          setSelectedReport(labels.planReviewerTimeTrackingReport);
          break;
          // EA-105
        // mritunjoy
        case labels.permitReviewCommand:
          setSelectedReport(labels.permitReviewCommand);
          break;
        default:
          break;
      }
    }
  }, [report]);  
 
  const [permitNum, setPermitNum] = React.useState('')
 
  const middleware = (
    submitFn: (data: ReportType) => void,
  ) => (data: ReportTableType) => {
 
    submitFn({
      ...data,
      fromDate:
      selectedReport !== labels.runnerReport &&
      selectedReport !== labels.permitReviewCommand&&
       selectedReport !== labels.inspectionByPermitReport &&
        selectedReport !== labels.businessToBeInvoicedReport &&
        selectedReport !== labels.dailyInspectionReport &&
         selectedReport !== labels.companyOutstandingFeesReport ? format(data.range[0], DEFAULT_DATE_FORMAT) :
        selectedReport === labels.dailyInspectionReport ?
        format(data.formsDate, DEFAULT_DATE_FORMAT)
          : null,
      toDate:
      selectedReport !== labels.runnerReport &&
      selectedReport !== labels.permitReviewCommand&&
       selectedReport !== labels.inspectionByPermitReport &&
        selectedReport !== labels.businessToBeInvoicedReport &&
        selectedReport !== labels.dailyInspectionReport &&
         selectedReport !== labels.companyOutstandingFeesReport ? format(data.range[1], DEFAULT_DATE_FORMAT) :
         selectedReport === labels.dailyInspectionReport ?
        format(data.formsDate, DEFAULT_DATE_FORMAT)
          : null,
      inspector: data.inspector,
      jurisdiction: data.jurisdiction,
      selectedReport,
      permitNum,
      company_name: selectedCompanyName?.companyName,
      projectManager: selectedprojectManager,
      permitdata:selectPermit
 
    });
    onChangeKey();
  };
  const formsMiddleware = (
    submitFn: (data: any) => void,
  ) => (data: ReportTableType) => {
   
    if (formsDateValue && data.inspector?.id) {
     
      submitFn({
        formsDate: formatDate(
          formsDateValue,
          DEFAULT_DATE_FORMAT,
        ),
        inspector: data.inspector?.id,
        selectedReport: 'formsReport',
        report:  {
          "value": "formsReport",
          "label": "Forms Report"
      },
      });
    }
  };
 
  const handleExportToPDF = async () => {
    try {
      const isValid = await trigger();
 
      if (isValid) {
        middleware(onExportToPDF)(getValues());
      }
    } catch (e) {
      // TODO
    }
  };
 
  return (
    <Container>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(
          (selectedReport === labels.dailyInspectionReport && inspectionFormClick)
            ? formsMiddleware(onSubmit)
            : middleware(onSubmit),
        )}
      >
        <InputBaseContainer>
          <Controller
            name="report"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelect
                placeholder="Select report"
                isSearchable={false}
                styles={selectStyles}
                error={errors.report}
                options={SALES_EMPLOYEE_REPORTS_DATA}
                isRequired={isYupFieldRequired(
                  'report',
                  schema,
                )}
                isDisabled={isFetching}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {selectedReport === labels.formsReport || selectedReport === labels.dailyInspectionReport && (
          <InputBaseContainer>
            <DateWrapper>
              <Controller
                name="formsDate"
                defaultValue=""
                control={control}
                render={({ value, onChange }) => (
                  <DatePicker
                    disabled={isFetching}
                    onChange={onChange}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    selected={value}
                    placeholderText="Select Date"
                    required
                  />
                )}
              />
            </DateWrapper>
          </InputBaseContainer>
        )}
        {selectedReport !== labels.runnerReport &&
        selectedReport !== labels.dailyInspectionReport &&
        selectedReport !== labels.permitReviewCommand&&
        selectedReport !== labels.formsReport && selectedReport !== labels.inspectionByPermitReport && selectedReport !== labels.businessToBeInvoicedReport && selectedReport !== labels.companyOutstandingFeesReport &&(            <InputBaseContainer>
              <Controller
                name="range"
                defaultValue=""
                control={control}
                render={({
                  value,
                  name,
                  onChange,
                  onBlur,
                }) => (
                  <InputDatePicker>
                    <InputDatepickerRange
                      name={name}
                      placeholderText="Select Date"
                      selected={null}
                      error={
                        errors.range ||
                        errors.fromDate ||
                        errors.toDate
                      }
                      dateFormat={DEFAULT_DATE_FORMAT}
                      isRequired={isYupFieldRequired(
                        'range',
                        schema,
                      )}
                      onRangeChange={(start, end) => {
                        if (start && end) {
                          onChange([start, end]);
                        }
                      }}
                      onBlur={onBlur}
                      initialDates={value}
                    />
                  </InputDatePicker>
                )}
              />
            </InputBaseContainer>
          )}
        {selectedReport === labels.runnerReport && (
          <InputBaseContainer>
            <Controller
              name="jurisdiction"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelect
                  isSearchable={false}
                  styles={selectStyles}
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  error={errors.jurisdiction}
                  getOptionValue={(item: any) =>
                    item.id || Math.random()
                  }
                  getOptionLabel={(item: any) => item.name}
                  placeholder={
                    isFetching ? '' : 'Jurisdiction'
                  }
                  isRequired={isYupFieldRequired(
                    'jurisdiction',
                    schema,
                  )}
                  options={jurisdictionsData}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {selectedReport !== labels.runnerReport &&
        selectedReport !== labels.inspectionByPermitReport &&
        selectedReport !== labels.businessToBeInvoicedReport &&
        selectedReport !== labels.notInvoicedPermittingFeesReport &&
        selectedReport !== labels.companyOutstandingFeesReport &&
        selectedReport !== labels.paidFeesReport &&
        selectedReport !== labels.permitReviewCommand &&
        (<InputBaseContainer>
            <Controller
              name="inspector"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelect
                  isSearchable={false}
                  styles={selectStyles}
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  error={errors.inspector}
                  getOptionValue={(item: any) =>
                    item.id || Math.random()
                  }
                  getOptionLabel={(item: any) =>
                    `${item?.firstName} ${item?.lastName}`
                  }
                  placeholder={
                    isFetching ? '' : 'Select Inspector'
                  }
                  isRequired={isYupFieldRequired(
                    'inspector',
                    schema,
                  )}
                  options={inspectorsData}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {/* EA-88
        mritunjoy */}
          {(selectedReport === labels.businessToBeInvoicedReport ||selectedReport === labels.companyOutstandingFeesReport) &&(
          <InputBaseContainer>
            <Controller
              name="companyName"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelect
                  isSearchable={true}
                  styles={selectStyles}
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  error={errors.companyName}
                  getOptionValue={(item: any) =>
                    item.id || Math.random()
                  }
                  getOptionLabel={(item: any) =>
                    item?.companyName
                  }
                  placeholder={
                    isFetching ? '' : 'Company Name'
                  }
                  isRequired={isYupFieldRequired(
                    'companyName',
                    schema,
                  )}
                  options={companyData}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {/* EA-94
        mritunjoy */}
          {((selectedReport === labels.notInvoicedPermittingFeesReport) || (selectedReport === labels.paidFeesReport)) && (
          <InputBaseContainer>
            <Controller
              name="projectManager"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelect
                  isSearchable={true}
                  styles={selectStyles}
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  error={errors.projectManager}
                  getOptionValue={(item: any) =>
                    item.id || Math.random()
                  }
                  getOptionLabel={(item: any) =>
                    item?.managerName
                  }
                  placeholder={
                    isFetching ? '' : 'Project Manager'
                  }
                  isRequired={isYupFieldRequired(
                    'projectManager',
                    schema,
                  )}
                  options={projectManagers}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {/* ############################### */}
        {/* EA-81
        mritunjoy */}
        {selectedReport === labels.inspectionByPermitReport && (
         <>
         <p style={{marginRight: '5px'}}>Permit Number</p>
          <TextField required style={{backgroundColor: '#fff'}} id="inspectionByPermitReport" onChange={(e) => setPermitNum(e.target.value)} name={labels.inspectionByPermitReport} variant="outlined" />
         </>
        )}
         {selectedReport === labels.permitReviewCommand && (
         <InputBaseContainer>
         <Controller
           name="permitReviewComment"
           defaultValue=""
           control={control}
           render={props => (
             <InputSelect
               isSearchable
               styles={selectStyles}
               isLoading={isFetching}
               isDisabled={isFetching}
               error={errors.permitReviewComment}
               getOptionValue={(item: any) =>
                 item.id || Math.random()
               }
               getOptionLabel={(item: any) =>
                 item?.permitName
               }
               placeholder={
                 isFetching ? '' : 'Select Permit'
               }
               isRequired={isYupFieldRequired(
                 'permitReviewComment',
                 schema,
               )}
               options={permit}
               // eslint-disable-next-line react/jsx-props-no-spreading
               {...props}
             />
           )}
         />
       </InputBaseContainer>
        )}
        <ButtonContainer>
          <ButtonMain type="submit" disabled={isLoading}
          // onClick={() => setInspectionFormClick(false)}
           >
            Submit
          </ButtonMain>
          <ButtonWithLoader
            variant="outlined"
            loading={isLoading}
            disabled={!isValidForExport}
            onClick={
              selectedReport === labels.formsReport
                ? onExportToPDF
                : handleExportToPDF
            }
          >
            Export to PDF
          </ButtonWithLoader>
          {selectedReport === labels.dailyInspectionReport && <ButtonWithLoader
          type='submit'
            variant="outlined"
            loading={isLoading}
            style={{marginLeft: '1rem'}}
            // onClick={() => setInspectionFormClick(true)
              onClick={() => handleReportSettings()
                       
            }
          >
            Inspection Form
          </ButtonWithLoader>}
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};
 
const Container = styled.div`
  position: relative;
`;
 
const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
 
  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;
 
    &:last-child {
      margin: 0;
    }
  }
`;
 
const ButtonContainer = styled.div`
  > .MuiButton-root {
    margin: 0 24px 0 0;
 
    &:last-child {
      margin: 0;
    }
  }
`;
 
const InputDatePicker = styled.div`
  pointer-events: ${({ disabled }) =>
    disabled ? 'none' : 'all'};
  > div {
    > div {
      > div {
        ${({ disabled }) =>
          disabled &&
          css`
            background-color: var(--disabledButton);
            color: var(--defaultBackground);
          `}
      }
    }
  }
`;
 
const DateWrapper = styled.div`
  input {
    cursor: pointer;
    width: 148px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 16px;
    font-size: 14px;
    &::placeholder {
      color: #808080;
      font-size: 16px;
      position: relative;
    }
  }
`;
 
export interface Props {
  isLoading?: boolean;
  initialValues?: ReportTableType;
  onSubmit: (data: ReportTableType) => void;
  onExportToPDF: (data: ReportTableType) => void;
  initialErrors?: FormServerError<ReportTableType>;
  onChangeKey?: () => void;
  isValidForExport: () => void;
}
 
export default GenerateEmployeeReportForm;