/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {green, red} from 'material-ui-colors'
import { useSelector } from 'react-redux';
import usePrevious from 'react-use-previous-hook';
import {
  Column,
  useColumnOrder,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { AxiosResponse } from 'axios';
import { snakeCase } from 'change-case';

import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import { TABLE_DEFAULT_PAGE_SIZES } from '../constants';
import {
  CheckboxRounded,
  IconButton,
  // Pagination,
  Tooltip,
  Checkbox,
} from '../atoms';
import {
  LoaderWithContainer,
  SearchBar,
} from '../molecules';
import { PaginatedList } from '../types';
import useManuallyUpdate from '../hooks/useManuallyUpdate';
import { tableLoadModeSelector } from '../redux/ducks/userSettings';

const TABLE_DEFAULT_PAGE_SIZES = [100];

const RoundedCheckbox = React.forwardRef((props, ref) => (
  <CheckboxRounded inputRef={ref as any} {...props} />
));

const MainCheckbox = React.forwardRef((props, ref) => (
  <Checkbox inputRef={ref as any} {...props} />
));

const MetaMessage = ({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) => (
  <MetaMessageContainer>
    <td>{children}</td>
  </MetaMessageContainer>
);

const SELECTION_ID = 'selection';
const ACTIONS_ID = 'actions';
const MAP_COLUMNS_PLACEHOLDER = '-';

const Table = <T extends object, P extends object>({
  columns = [] as any,
  rows = [] as any,
  columnsOrder,
  onEdit,
  handleInputChange,
  disableSortBy,
  onSelect,
  onDelete,
  onConfigure,
  formatValue,
  searchQuery,
  customSearch,
  cellClassName,
  manuallyUpdate,
  handleCellClick,
  cellCallbackFnIsOn,
  additionalRequestParams,
  onSelectRowHeader,
  isRoundedCheckbox,
  bindingCheckboxesTo,
  endPositionOfSelectionColumn,
  initialSortBy,
  handleListChanged,
  isCommentCheckbox,
  }: Props<T, P>) => {
  const isAsync = typeof rows === 'function';
  const isNeedToMapColumns = typeof columns === 'function';

  const isFirstRun = React.useRef(true);

  const tableLoadDataMode = useSelector(
    tableLoadModeSelector,
  );

  // Local table updater
  const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();

  //  Main table updater
  const [updater, setUpdater] = React.useState<number>(
    manuallyUpdate,
  );

  const [error, setError] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(
    isAsync,
  );

  const [data, setData] = React.useState(
    isAsync ? ([] as any) : rows,
  );

  const [checkboxState, setCheckboxState] = React.useState(
    null,
  );

  const [columnsList, setColumnsList] = React.useState<
    Column<T>[]
  >(
    isNeedToMapColumns
      ? [
          {
            Header: MAP_COLUMNS_PLACEHOLDER,
            accessor: MAP_COLUMNS_PLACEHOLDER,
          },
        ]
      : (columns as any),
  );

  const [
    pageManualSize,
    // setPageManualSize,
  ] = React.useState<number>(
    TABLE_DEFAULT_PAGE_SIZES[0] || 5,
  );

  const [manualTotal, setManualTotal] = React.useState<
    number
  >(0);

  const [
    pageManualCount,
    setPageManualCount,
  ] = React.useState<number>(0);

  const hasActions = onConfigure || onEdit || onDelete;

  const handleCheckboxChange = (row, e, checkBoxState) => {
    const unMatchesRow = checkBoxState?.filter(
      el => el.id !== Number(row.id),
    );
    const newData = {
      ...row,
      [bindingCheckboxesTo.field]: e.target.checked,
    };
    setCheckboxState([...unMatchesRow, newData]);
  };

  const actionsList = [
    onConfigure && {
      title: 'Configure',
      handler: onConfigure,
      Icon: SettingsIcon,
    },
    onEdit && {
      title: 'Edit',
      handler: onEdit,
      Icon: EditIcon,
    },
    onDelete && {
      title: 'Delete',
      handler: onDelete,
      Icon: DeleteIcon,
    },
  ].filter(Boolean);

  const filterTypes = React.useMemo(
    () => ({
      text: (pureRows, id, filterValue) =>
        pureRows.filter(row => {
          const rowValue = row.values[id];

          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(
                  String(filterValue).toLowerCase(),
                )
            : true;
        }),
    }),
    [],
  );

  const isCustomClassName = cellColumn => {
    let cellClass = null;
    cellClassName.forEach(cl => {
      cellClass =
        cl.column === cellColumn ? cl.className : null;
    });
    return cellClass;
  };

  const selectionColumn = {
    id: SELECTION_ID,
    Header: ({
      getToggleAllPageRowsSelectedProps,
    }: any) => (
      <>
        {onSelectRowHeader ? (
          <span>{onSelectRowHeader}</span>
        ) : (
          <RoundedCheckbox
            {...getToggleAllPageRowsSelectedProps()}
          />
        )}
      </>
    ),
    Cell: ({ row, checkBoxState }: any) => {
      const matchesRow = checkBoxState?.find(
        el => el.id === Number(row.original.id),
      );
     return (
        <td>
          {isRoundedCheckbox && matchesRow ? (
            <RoundedCheckbox
              {...row.getToggleRowSelectedProps()}
            />
          ) : (
            matchesRow?.isResolved && isCommentCheckbox ? (
              <MainCheckbox
                {...row.getToggleRowSelectedProps()}
                checked={
                  matchesRow[bindingCheckboxesTo.field]
                 }
                style={{backgroundColor: green[500]}}

                onChange={e =>
                  handleCheckboxChange(
                    row.original,
                    e,
                    checkBoxState,
                  )
                }
              />
            ) : !matchesRow?.isResolved && isCommentCheckbox ? (
              <MainCheckbox
                {...row.getToggleRowSelectedProps()}
                checked={
                  matchesRow[bindingCheckboxesTo.field]
                 }
                style={{backgroundColor: red[500]}}

                onChange={e =>
                  handleCheckboxChange(
                    row.original,
                    e,
                    checkBoxState,
                  )
                }
              />
            ): (
                matchesRow && (
              <MainCheckbox
                {...row.getToggleRowSelectedProps()}
                checked={
                  matchesRow[bindingCheckboxesTo.field]
                 }

                onChange={e =>
                  handleCheckboxChange(
                    row.original,
                    e,
                    checkBoxState,
                  )
                }
              />
            ))
          )}
        </td>
      );
    },
    width: 60,
  };

  const actionsColumn = {
    id: ACTIONS_ID,
    Header: 'Actions',
    Cell: ({ row: { original } }) => (
      <td>
        <ActionsContainer>
          {actionsList.map(({ title, handler, Icon }) => (
            <IconButton
              className="table-actions"
              key={String(Math.random())}
              onClick={() => handler(original)}
            >
              <Tooltip title={title} placement="left">
                <Icon />
              </Tooltip>
            </IconButton>
          ))}
        </ActionsContainer>
      </td>
    ),
    width: 140,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    // pageCount,
    // setPageSize,
    setColumnOrder,
    setGlobalFilter,
    selectedFlatRows,
    state: {
      globalFilter,
      pageIndex,
      // pageSize,
      sortBy,
      selectedRowIds,
    },
  }: any = useTable(
    {
      columns: columnsList as any,
      data: data as object[],
      // @ts-ignore
      filterTypes,
      checkBoxState: checkboxState,
      pageCount: pageManualCount,
      manualGlobalFilter: isAsync,
      manualPagination: isAsync,
      manualSortBy: isAsync,
      disableSortBy: disableSortBy,
      initialState: {
        pageSize: pageManualSize,
        sortBy: initialSortBy || [],
      } as any,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    hooks => {
      hooks.visibleColumns.push(colmns =>
        [
          onSelect &&
            !endPositionOfSelectionColumn &&
            selectionColumn,
          ...colmns,
          hasActions && actionsColumn,
          endPositionOfSelectionColumn && selectionColumn,
        ].filter(Boolean));
    },
  );


  const selectedFlatRowsCount = selectedFlatRows.length;
  const previousSelectedFlatRowsCount = usePrevious<number>(
    selectedFlatRowsCount,
  );
  const fillColumns = (row) => {
   if (
      row &&
      columnsList[0].accessor === MAP_COLUMNS_PLACEHOLDER
    ) {
      const filteredColumns = Object.keys(row)
        .map(column => {
          return {
            Header: columns(column as keyof T),
            accessor: column,
            Cell: ({ value, cell }) => {
              if (column === 'value') {
                
                const [value, setValue] = React.useState(
                  cell.row.original?.value,
                );
                return (
                  <td>
                    <input
                      style={{ width: 250 }}
                      onChange={e => {
                        setValue(e.target.value);
                        handleInputChange(
                          e,
                          cell,
                          e.target.value,
                          cell.row.values.name,
                        );
                      }}
                      value={value}
                    />
                    {value===""&&<h6 style={{color:"red"}}>Please Enter Any Value</h6>}
                  </td>
                );
              }
            return (

                <td
                  className={
                    cellClassName &&
                    Array.isArray(cellClassName)
                      ? isCustomClassName(column)
                      : null
                  }
                >
                  {' '}
                  {cellCallbackFnIsOn &&
                  cellCallbackFnIsOn.includes(column) ? (
                    <button
                      onClick={() =>
                        handleCellClick(cell.row?.original)
                      }
                      type="button"
                    >
                      {formatValue
                        ? formatValue(value, column)
                        : value}
                    </button>
                  ) : (
                    <>
                      {formatValue
                        ? formatValue(value, column)
                        : value}
                    </>
                  )}
                </td>
              );

            },

          };
        })
        .filter(column => Boolean(column.Header));

      setColumnsList(filteredColumns as any);

      if (columnsOrder) {
        setColumnOrder(
          typeof columnsOrder === 'function'
            ? filteredColumns
                .map(column => column.accessor)
                .sort(columnsOrder)
            : columnsOrder,
        );
      }
    }
  };

  // Combine updaters
  React.useEffect(() => {
    setUpdater(prevUpdater =>
      prevUpdater === manuallyUpdate
        ? tableManuallyUpdate
        : manuallyUpdate);
  }, [manuallyUpdate, tableManuallyUpdate]);

  const previousGlobalFilter = usePrevious(globalFilter);

  React.useEffect(() => {
    if (
      isAsync &&
      (isFirstRun.current ||
        !isLoading ||
        previousGlobalFilter !== globalFilter)
    ) {
      isFirstRun.current = false;

      setError(false);
      setIsLoading(true);

      const isDeleted = false;

      const additional = additionalRequestParams || {};

      const pageMax = Math.ceil(
        manualTotal / pageManualSize,
      );

      if (manualTotal && pageIndex + 1 > pageMax) {
        gotoPage(pageMax - 1);
        return;
      }

      const params = {
        isDeleted,
        search: globalFilter,
        limit: pageManualSize,
        offset: pageIndex * pageManualSize,
        ordering:
          sortBy.length > 0
            ? (sortBy[0].desc ? '-' : '') +
              snakeCase(sortBy[0].id)
            : null,
        ...additional,
      };

      (rows(params) as Promise<any>)
        .then(({ data: response }) => {
          if (response && response.results) {
            fillColumns(response.results[0]);
            setCheckboxState(
              bindingCheckboxesTo ? response.results : null,
            );
            setData(response.results);
            setManualTotal(response.count);
            setPageManualCount(
              Math.ceil(response.count / pageManualSize),
            );
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true))
        .finally(() => setIsLoading(false));
    }
  }, [
    pageIndex,
    pageManualSize,
    globalFilter,
    sortBy,
    tableLoadDataMode,
    updater,
  ]);

  const prevAdditionalRequestParams = usePrevious(
    additionalRequestParams,
  );

  React.useEffect(() => {
    if (
      !R.equals(
        prevAdditionalRequestParams,
        additionalRequestParams,
      )
    ) {
      updateTable();
    }
  }, [additionalRequestParams]);

  React.useEffect(() => {
    if (searchQuery !== globalFilter) {
      setGlobalFilter(searchQuery);
      updateTable();
    }
  }, [searchQuery]);

  React.useEffect(() => {
    fillColumns(rows[0]);
  }, [rows]);

  React.useEffect(() => {
    if (checkboxState) {
      handleListChanged(checkboxState);
    }
  }, [checkboxState]);

  React.useEffect(() => {
    if (
      onSelect &&
      typeof onSelect === 'function' &&
      (selectedFlatRowsCount ||
        (!selectedFlatRowsCount &&
          previousSelectedFlatRowsCount))
    ) {
      onSelect(
        selectedFlatRows?.map(
          selectedRow => selectedRow.original,
        ),
        selectedRowIds,
      );
    }
  }, [selectedRowIds, selectedFlatRows]);

  return (
    <Container>
      {!isLoading && !error ? (
        <>
          {!customSearch && (
            <SearchContainer>
              <SearchBar
                text={globalFilter}
                onChange={setGlobalFilter}
              />
            </SearchContainer>
          )}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (column: any) => {
                      if (column.Header === '-') {
                        return null;
                      }

                      const isSpecialColumn = [
                        SELECTION_ID,
                        ACTIONS_ID,
                      ].includes(column.id);

                      const computedProps = {
                        style: null,
                        ...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        ),
                      };

                      if (isSpecialColumn) {
                        computedProps.style = {
                          width: column.width,
                          textAlign:
                            column.id === ACTIONS_ID
                              ? 'center'
                              : 'left',
                        };
                      }

                      const sortDirection = column.isSortedDesc ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      );

                      return (
                        <th {...computedProps}>
                          {column.render('Header')}
                          {!isSpecialColumn &&
                          !disableSortBy &&
                            (column.isSorted ? (
                              sortDirection
                            ) : (
                              <UnfoldMoreIcon />
                            ))}
                        </th>
                      );
                    },
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length ? (
                page.map(row => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()}>
                   {row.cells.map(cell =>
                      cell.render('Cell', {
                        ...cell.getCellProps(),
                      }))}
                    </tr>
                  );
                })
              ) : (
                <MetaMessage>
                  No matching records found
                </MetaMessage>
              )}
            </tbody>
          </table>
          {/* <MetaContainer>
            {(pageCount > 1 || pageManualCount > 1) && (
              <Pagination
                pageSize={
                  isAsync ? pageManualSize : pageSize
                }
                current={pageIndex + 1}
                total={
                  isAsync
                    ? pageManualCount * pageManualSize
                    : pageCount * pageSize
                }
                onChange={pageNumber =>
                  gotoPage(pageNumber - 1)
                }
              />
            )}
            <select
              value={isAsync ? pageManualSize : pageSize}
              onChange={e => {
                if (isAsync) {
                  setPageManualSize(Number(e.target.value));
                } else {
                  setPageSize(Number(e.target.value));
                }
              }}
            >
              {TABLE_DEFAULT_PAGE_SIZES.map(ps => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>
          </MetaContainer> */}
        </>
      ) : (
        <LoaderWithContainer />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 100px;
  overflow-x: auto;

  table {
    color: var(--mainText);
    font-size: 0.875rem;
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 6px;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);

    thead {
      color: var(--defaultTableCellTitle);
      background-color: var(--defaultTableCell);
    }

    th {
      user-select: none;

      > svg {
        position: relative;
        top: 3px;
        left: 3px;
        width: 1rem;
        height: auto;
        font-size: 1rem;
      }
    }

    tr {
      &:nth-child(even) {
        background-color: var(--defaultTableCell);
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      text-align: left;
      padding: 18px 16px;
      border-bottom: 1px solid var(--border);

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left: 1px solid var(--border);
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid var(--border);
      }

      button {
        background: transparent;
        outline: none;
        border: none;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

const ActionsContainer = styled.div`
  text-align: center;

  .MuiButtonBase-root {
    margin: 0 6px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 0 30px 0;
  justify-content: flex-end;
`;

// const MetaContainer = styled.div`
//   width: 100%;
//   display: flex;
//   margin: 30px 0 0 0;

//   > select {
//     outline: none;
//     padding: 2px 4px;
//     margin-left: auto;
//     border-radius: 6px;
//     border: 2px solid var(--border);
//   }
// `;

const MetaMessageContainer = styled.tr`
  border: none;
  width: 100%;
  height: 54px;
  position: relative;

  > td {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    text-align: center;
    color: var(--secondaryText);
  }
`;

type RowData<T> = {
  [key in keyof T]: any;
} & {
  [key: string]: any;
};

interface Props<T extends object, P extends object> {
  columns: (column: keyof T) => string | Column<T>[];
  rows: (
    params: object,
  ) =>
    | Promise<AxiosResponse<PaginatedList<RowData<P>>>>
    | RowData<T>[];
  columnsOrder?: any;
  onEdit?: (data: T) => void;
  onSelect?: (
    data: T[],
    selectedIndexes: { [index: number]: boolean },
  ) => void;
  onDelete?: (data: T) => void;
  onConfigure?: (data: T) => void;
  searchQuery?: string;
  customSearch?: boolean;
  manuallyUpdate?: number;
  formatValue?: (value: any, column?: string) => any;
  handleCellClick?: (rowValue, column?: string) => any;
  cellClassName?: {
    column: string;
    className: string;
  }[];
  additionalRequestParams?: object;
  cellCallbackFnIsOn?: string[];
  onSelectRowHeader?: string;
  isRoundedCheckbox?: boolean;
  endPositionOfSelectionColumn?: boolean;
  bindingCheckboxesTo?: {
    field: string;
    value?: string;
  };
  handleListChanged: (data: object[]) => void;
  initialSortBy?: { id: string; desc: boolean }[];
  isCommentCheckbox?: boolean;
 }

const ReactMemo: <T>(C: T) => T = React.memo;

export default ReactMemo(Table);
