import * as yup from 'yup';
import { zipCode } from '../../../../../../regex';

export type Contact = {
  firstName: string;
  lastName: string;
  id: number;
};
const labels = {
  address: 'Address',
  street: 'Street',
  city: 'City',
  state: 'State',
  zipCode: 'Zip',
  jurisdiction: 'Jurisdiction',
  franchise: 'Franchise',
  manager: 'Project Manager',
  contacts: 'Contacts',
  name: 'Project Name',
  phone: 'Phone',
  projectName: 'Project/Permit name',
  description: 'Description',
  work: 'Work',
  building: 'Building',
};

const schema = yup.object().shape(
  {
    address: yup.string().required().label(labels.address),
    street: yup.string().required().label(labels.street),
    state: yup.string().required().label(labels.state),
    city: yup.string().required().label(labels.city),
    zipCode: yup
      .string()
      .matches(
        zipCode,
        'Invalid Code number. The code number must be in format 55555 or 55555-5555',
      )
      .required()
      .label(labels.zipCode),
    // phone: yup.string().required().label(labels.phone),
    projectName: yup
      .string()
      .required()
      .label(labels.projectName),
    description: yup
      .string()
      .required()
      .label(labels.description),
    work: yup.string().required().label(labels.work),
    // manager: yup
    //   .string()
    //   .required()
    //   .label('Project Manager'),
    building: yup
      .string()
      .required()
      .label(labels.building),
    serviceType: yup
      .array()
      .of(yup.object())
      .required()
      .label('Service Type')
      .typeError('Select a Service-Type from the list')
      .label('Service Type')
      .min(1, 'Select a Service-Type from the list'),
    applicantType: yup
      .array()
      .of(yup.object())
      .required()
      .label('Applicant Type'),
    franchise: yup
      .array()
      .of(yup.object())
      .required()
      .label('Franchise'),
  },
);

export default schema;
