import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FormsReportType } from '../../api/reports';
import { FranchiseType } from '../../api/franchise';

export interface InspectionsReportState {
  showFormPopup: boolean;
  records: FormsReportType[];
  reportFormLoader: boolean;
  franchiseIds: string;
}

const initialState: InspectionsReportState = {
  showFormPopup: false,
  reportFormLoader: false,
  records: [],
  franchiseIds: '',
};

export const inspectionsReportSlice = createSlice({
  name: 'inspectionsReport',
  initialState,
  reducers: {
    setShowFormPopup: (state, action: PayloadAction<boolean>) => {
      state.showFormPopup = action.payload;
    },
    setRecords: (state, action: PayloadAction<any[]>) => {
      state.records = action.payload;
    },
    setReportsFormLoader: (state, action: PayloadAction<boolean>) => {
      state.reportFormLoader = action.payload;
    },
    setFranchisesForReport: (state, action: PayloadAction<string>) => {
      state.franchiseIds = action.payload;
    },
    resetInspectionReports: state => {
      state.showFormPopup = false;
      state.records = [];
      state.reportFormLoader = false;
      state.franchiseIds = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShowFormPopup, setRecords, setReportsFormLoader, resetInspectionReports,setFranchisesForReport } = inspectionsReportSlice.actions;

export default inspectionsReportSlice.reducer;
