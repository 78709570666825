import * as React from 'react';
import styled from 'styled-components';

import { fontFamily } from '../theme';

const LabelForm = ({
  children,
  htmlFor,
  isRequired
}: LabelFormTypes) => (
  <LabelFormContainer htmlFor={htmlFor}>
    {children} {isRequired && <span>*</span>}
  </LabelFormContainer>
);

const LabelFormContainer = styled.label`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 21px;

  span {
    color: var(--mainRequired);
  }
`;

interface LabelFormTypes {
  children: React.ReactNode;
  htmlFor: string;
  isRequired?: boolean;
}

export default LabelForm;
