import * as React from 'react';
import styled from 'styled-components';

import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import { TABLE_LOAD_DATA_MODE } from '../constants/settings';
import Tooltip from './Tooltip';

const icons = {
  [TABLE_LOAD_DATA_MODE.ALL]: <AllInclusiveIcon />,
  [TABLE_LOAD_DATA_MODE.INACTIVE]: <DeleteForeverIcon />,
  [TABLE_LOAD_DATA_MODE.ACTIVE]: <PlaylistAddCheckIcon />,
};

const tooltips = {
  [TABLE_LOAD_DATA_MODE.ALL]: 'You see all the entries',
  [TABLE_LOAD_DATA_MODE.INACTIVE]:
    'You see only deleted entries',
  [TABLE_LOAD_DATA_MODE.ACTIVE]:
    'You see only operational entries',
};

const TableLoadMode = ({ mode }: Props) => {
  const icon = icons[mode];
  const tooltip = tooltips[mode];

  return (
    <Container>
      <Tooltip enterDelay={0} title={tooltip}>
        {icon}
      </Tooltip>
    </Container>
  );
};

const Container = styled.span`
  color: rgba(0, 0, 0, 0.54);

  > svg {
    vertical-align: text-bottom;
  }
`;

interface Props {
  mode: TABLE_LOAD_DATA_MODE;
}

export default TableLoadMode;
