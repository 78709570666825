import React from 'react';
import styled from 'styled-components';

const NoRecordsFound = () => {
    return (
        <Wrapper>
            No Records Found.
        </Wrapper>
    );
};

export default NoRecordsFound;

const Wrapper = styled.div`
  font-size: 22px;
  margin:35px;
`;