import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import isYupFieldRequired from '../utils/isYupFieldRequired';

import {
  InputSelectWithLabel,
  InputBaseContainer,
  ButtonWithLoader,
  ModalDefaultDraggable,
  CheckboxWithLabel,
} from '../molecules';
import { FormServerError } from '../types';
import { getCurrentUser } from '../api/userSettings';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root';

const labels = {
  contacts: 'Select the contact to Invoice',
  sendTo: 'Select the contacts to send a copy of Invoice',
  checkbox: 'Do you want to send a copy of this invoice to other contacts?'
};

const schema = yup.object().shape({
  contacts: yup
    .object().nullable()
    // .of(yup.object())
    // .required()
    // .typeError('Select contacts from the list')
    .label(labels.contacts),
  sendTo: yup
  .array()
  .of(yup.object())
.nullable()
    // .required()
    // .typeError('Select a contact from the list')
    .label(labels.sendTo),
});

const FeesSendForm = ({
  permit,
  fees,
  onSubmit,
  contacts,
  modalError,
  initialErrors,
  setOpenFeesSendModal
}: Props) => {
  const {
    handleSubmit,
    errors,
    control,
    formState,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

const isChecked = watch('checkbox');
const selectedContact = watch('contacts');
const selectedSendTo = watch('sendTo');
const selectedFranchise = useSelector((s:RootState) => s.userAuth.info.selectedFranchise)
const handleCloseModalInfo = () => {  
  setOpenFeesSendModal(false)
}

  const [isSubmitting, setIsSubmitting] = React.useState(
    false,
  );

  React.useEffect(() => {
    const accountingContact = contacts.find(c => c.isAccounting);
    if(accountingContact){
      setValue('contacts',accountingContact);
    }
  },[contacts])
  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
    if(initialErrors) {
    }
  }, [errors, initialErrors]);

  const middleware = submitFn => data => {    
    Promise.all([getCurrentUser()]).then(([currentUser]) => {      
      let submitData = {}
      if(contacts?.length === 1) {
        const newContacts = contacts[0]?.id
        submitData = {
          permit,
          fees,
          users: [newContacts],
          sendTo: newContacts,
          userId: currentUser?.data?.id,
          franchise: selectedFranchise,
        }
      } else {
        const newContacts = data?.sendTo?.map(contact => contact?.id)
        submitData = {
          permit,
          fees,
          users:  isChecked ? [...newContacts, data?.contacts?.id] : [data?.contacts?.id],
          sendTo: data?.contacts?.id,
          userId: currentUser?.data?.id,
          franchise: selectedFranchise,
        }
      }
      submitFn(submitData);
    }
    )
    
  };


  const getItemName = (name: object) => {
    return name?.name
      ? name?.name
      : `${name?.firstName} ${name?.lastName}${
          name?.companyName || name?.parentCompany
            ? ` - ${
                name?.companyName || name?.parentCompany
              }`
            : ''
        }`;
  };

  if(initialErrors) {
    return <div style={{height: "100vh", width: "100vw", textAlign: "center"}}><ModalDefaultDraggable
    title="Error"
    onClose={handleCloseModalInfo}
  >
    <>
      <h3>{initialErrors?.msg}</h3>
      <ButtonWithLoader
        onClick={handleCloseModalInfo}
      >
        Close
      </ButtonWithLoader>
    </>
  </ModalDefaultDraggable>
  </div>
  }

  return (
    <Container disabled={isSubmitting && !modalError}>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="contacts"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                showCompany
                placeholder=""
                label={labels.contacts}
                error={errors.contacts}
                options={contacts}
                getOptionValue={(item: any) =>
                  item?.value ? item?.value : item.id
                }
                getOptionLabel={
                  (item: any) => getItemName(item)
                  // eslint-disable-next-line no-nested-ternary
                }
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="checkbox"
            control={control}
            valueName="checked"
            defaultValue={false}
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.checkbox}
                label={labels.checkbox}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer hidden={!isChecked}>
          <Controller
            name="sendTo"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
              isMulti
                // selectAllActive={contacts.length !== 1}
                placeholder=""
                label={labels.sendTo}
                error={errors.sendTo}
                options={contacts?.filter(contact => contact?.id !== selectedContact?.id)}
                getOptionValue={(item: any) => item.id}
                getOptionLabel={(item: any) =>
                  getItemName(item)
                }
                isRequired={isYupFieldRequired(
                  'sendTo',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>

        <ButtonWithLoader
          disabled={isSubmitting && !modalError || !selectedContact || (isChecked && !selectedSendTo?.length)}
          loading={isSubmitting && !modalError}
          type="submit"
        >
          Send
        </ButtonWithLoader>
      </FormContainer> 
    </Container>
  );
};

export default FeesSendForm;

interface Props {
  permit: number;
  fees: Array<number>;
  onSubmit: (data: any) => void;
  modalError: boolean;
  initialErrors?: FormServerError<any>;
}

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;
