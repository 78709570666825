import React from 'react';
import { RootState } from '../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import SingleItemWithDownload from '../../Common/SingleItemWithDownload';
import BottomDrawer from '../../Common/Containers/BottomDrawer';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';
import ConditionsControls from './ConditionsControls';
import { useForm } from 'react-hook-form';
import schema from './schema';
import { setConditionComments, setConditionName, setSelectedCondition, setSelectedConditionItem } from '../../../../../redux/slices/conditionsSlice';
import DetailsHeaderComponent from '../../Common/DetailsHeaderComponent';
import { InspectionItemType } from '../../../types';
import { getPermitActivityComments } from '../../../../../api/activities';

const ConditionItems = () => {
  const dispatch = useDispatch();
  const conditions = useSelector((s: RootState) => s.conditions.PermitConditions);
  const SelectedCondition = useSelector((s: RootState) => s.conditions.SelectedCondition);
  const items = conditions.map(item => ({
    name: `${item.permitName} - ${item.activityTitleName}`,
    status: item.status,
    id: item.id,
    attachments: item.attachments,
    title: item.title,
  }));

  const onEdit = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(setSelectedConditionItem(item));
    dispatch(setBottomDrawerOpened(true));

    const params = {
      offset: 0,
      is_active: true,
      is_deleted: false,
      limit: 100000000,
      system_message: false,
    };
    const loadComments = async () => {
      const result = await getPermitActivityComments(params, item.id);
      dispatch(setConditionComments(result.data.results));
      dispatch(setConditionName(item.name));
    };
    loadComments();
  };

  return (
    <Wrapper>
      {/*@ts-ignore */}
      <DetailsHeaderComponent names={SelectedCondition.namePermitName} address={`${SelectedCondition.address}`} />
      <PaperWrapper>
        {items.map((item, index) => (
          <SingleItemWithDownload onEdit={onEdit} key={index} item={item} />
        ))}

        <ConditionsControls />
      </PaperWrapper>
    </Wrapper>
  );
};

export default ConditionItems;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 37px 1fr;
  margin: 50px;
  overflow: auto;
  max-height: 90%;
  @media only screen and (max-width: 840px) {
    margin: 10px;
    margin-top: 50px;
  }
`;

const PaperWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
    margin-top: 11px;
    margin-left: -18px;
  }
`;

const ConditionItem = styled.div`
  width: 100%;
  min-height: 80px;
  max-height: 220px;
  border: 1px solid grey;
  background-color: #ccc;
  border-radius: 8px;
  position: relative;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Attachments = styled.div`
  border: 1px solid grey;
  border-radius: 8px;
  margin: 30px;
  height: 110px;
  overflow: auto;
`;

const Flex = styled.div`
  display: flex;
  margin: 20px;
  margin-left: 30px;
`;

const AttachmentsLabel = styled.div`
  font-size: 12px;
  position: absolute;
  left: 43px;
  top: 50px;
`;
