import geographyStates from '../constants/geographyStates';

export const parseGoogleApiAddress = (
  address,
  fullAddressArray,
) => {
  const fullAddressObj: fullAddressObjType = {};

  fullAddressArray.map(element => {
    if (element.types.includes('street_number')) {
      fullAddressObj.street = element.long_name;
    }
    if (element.types.includes('route')) {
      fullAddressObj.street = `${fullAddressObj.street} ${element.long_name}`;
    }
    if (element.types.includes('locality')) {
      fullAddressObj.city = element.long_name;
    }
    if (
      element.types.includes('administrative_area_level_1')
    ) {
      fullAddressObj.state = element.short_name;
    }
    if (element.types.includes('postal_code')) {
      fullAddressObj.zipCode = element.long_name;
    }
    return false;
  });
  return {
    ...fullAddressObj,
    address,
  };
};

const findMatchesInArray = (
  value: string,
  arrayOfString: string[],
) => {
  return arrayOfString.some(
    element =>
      element.toLowerCase() === value.toLowerCase(),
  );
};

export const checkValidStateName = (stateValue: string) => {
  if (
    findMatchesInArray(
      stateValue,
      Object.keys(geographyStates),
    )
  ) {
    const objectKey = Object.keys(geographyStates).find(
      key => {
        return (
          key.toLowerCase() === stateValue.toLowerCase()
        );
      },
    );
    return geographyStates[objectKey];
  }
  return stateValue;
};

interface fullAddressObjType {
  street?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
