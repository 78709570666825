import * as React from 'react';
import styled from 'styled-components';
import {
  SuccessCheckmark,
  CustomizedDialogs,
  ErrorCheckmark,
} from '../atoms';

interface Props {
  title: string;
  isError?: boolean;
  onClose: () => void;
}

const modalTimerValue = 4000;

const InfoModal = ({
  title,
  onClose,
  isError = false,
}: Props) => {
  React.useEffect(() => {
    let timer;

    if (!isError && !timer) {
      timer = setTimeout(() => {
        onClose();
      }, modalTimerValue);
    }

    return () => !isError && clearTimeout(timer);
  }, [isError]);

  return (
    <StyledCustomizedDialogs onClose={onClose}>
      <Wrapper>
        <Title>{title}</Title>
        {isError ? (
          <ErrorCheckmark />
        ) : (
          <SuccessCheckmark />
        )}
      </Wrapper>
    </StyledCustomizedDialogs>
  );
};

const Wrapper = styled.div`
  background-color: var(--secondary);
  width: 250px;
  height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
`;

const StyledCustomizedDialogs = styled(CustomizedDialogs)`
  position: fixed;
  z-index: var(--modalZIndex);
`;

const Title = styled.span`
  text-align: center;
  width: 190px;
  color: var(--mainText);
`;

export default InfoModal;
