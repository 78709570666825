import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { InspectionsType } from '../../../api/inspections';
import { AddressCell, CommentCell, MobileTableWrapper, RecordWrapper } from '../ResponsiveStyles';
import MobileRecord, { FieldData } from '../MobileRecord';

import DailyInspectionsPopup from './DailyInspectionsPopup';
import DailyInspectionReportPopup from './DailyInspectionReportPopup';
import DailyInspectionsSearchBox from '../../Reports/DailyInspectionReport/DailyInspectionSearchBox';
import InspectionEditor from '../../Reports/DailyInspectionReport/InspectionEditor/InspectionEditor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { setModalOpen, setSelectedInspection } from '../../../redux/slices/inspectionsSlice';

export const dailyPendingFields: FieldData[] = [
    { label: "Project Name", key1: "projectName" },
    { label: "Permit Number", key1: "permitNumber" },
    { label: "Address", key1: "projectAddress" },
    { label: "Permit Name", key1: "permitName" },
    { label: "Inspection Type", key1: "activityTypeName" },
    { label: "Contact", key1: "contactFirstName", key2: "contactLastName" },
    { label: "Cell phone", key1: "cellPhone" },
    { label: "Information", key1: "information" },
];

type MyProps = {
    data: any,
    tableData: InspectionsType[]
}

const DailyInspections:FC<MyProps> = ({ data, tableData }) => {
    const [open, setOpen] = React.useState(false);
    const [reportOpened, setReportOpened] = useState<boolean>(false);
    const [selectedItem, setSelectedItem]  = useState<InspectionsType>({} as InspectionsType);
    const {selectedInspection,permitInspectionsList} = useSelector((s:RootState) => s.inspections);
    const dispatch = useDispatch();

    const showDetails = (item: any) => {
      dispatch(setSelectedInspection(item));
      dispatch(setModalOpen(true));
    };

    const onSendSMS = (event, item: InspectionsType) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedItem(item);
       setOpen(true);
    };
    return (
      <>
      {selectedInspection.id && <InspectionEditor />}
       <MobileSearchBox>
        <DailyInspectionsSearchBox />
       </MobileSearchBox>    
        <MobileTableWrapper>
          {permitInspectionsList.map((item, index) => {
               let comment = item.lastComment ? item.lastComment.text : '';
                const div = document.createElement('DIV');
                div.innerHTML = comment;
                comment = div.innerText;
                return (
                  <Wrapper key={index} shaded={index % 2 === 0} onClick={() => showDetails(item)}>
                    <SMSButton onTouchStart={e => onSendSMS(e, item)} onClick={e => onSendSMS(e, item)}>Send SMS</SMSButton>
                    <MobileRecord
                      item={item}
                      fields={dailyPendingFields}
                      shaded={index % 2 === 0}
                      removeBorder={true}
                    />
                    <CommentCell><strong>Comment:</strong> {comment}</CommentCell>
                  </Wrapper>

                );
            })}
        </MobileTableWrapper>
        <DailyInspectionsPopup open={open} setOpen={setOpen} messageData={selectedItem} />
        <DailyInspectionReportPopup setReportOpened={setReportOpened} reportOpened={reportOpened} item={selectedItem}/>
      </>
    );
};

export default DailyInspections;

const Wrapper = styled.div<{shaded:boolean}>`
 border-bottom: 1px solid gray;
 background-color: ${({shaded}) => shaded ? "white" : "#A4C7ED"};
 color:#000;
 border-bottom:12px solid grey;
`;

const MobileSearchBox = styled.div`
  display: none;
  @media only screen and (max-width: 840px) {
    display: block;
  }
`;

const SMSButton = styled.div`
  background-color: #ccc;
  width: 100px;
  height: 34px;
  position: relative;
  top: 10px;
  left: 10px;
  text-align: center;
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 10px;
  border: 1px solid black;
`;
