import React, { useEffect } from 'react';
import { setShowDrawerContent } from '../../../redux/slices/generalSlice';
import { useDispatch } from 'react-redux';

const useCloseDrawers = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        return () => {
            dispatch(setShowDrawerContent(false))
        }
    },[])
    return {}
};

export default useCloseDrawers;