import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CommentCell, RecordWrapper } from './ResponsiveStyles';
import PaginationMobile from './PaginationMobile';
import { RootState } from '../../redux/root';
import { getPermitInspections, updatePermitInspection } from '../../api/inspections';
import { setRowsCount } from '../../redux/slices/paginationSlice';
import { setInspections, setPermitInspections } from '../../redux/slices/inspectionsSlice';
import ModalDefaultDraggable from '../../molecules/ModalDefaultDraggable';
import { getCustomActivities } from '../../api/activities';
import MobileRecord from './MobileRecord';
import useTablePage from '../../hooks/useTablePage';
import PermitInspectionsForm from '../../forms/permitInspectionForm/PermitInspectionsForm';
import useManuallyUpdate from '../../hooks/useManuallyUpdate';
import MobileFilter from './MobileFilter';
import { dailyPendingFields } from './DailyInspections/DailyInspections';
import useFilterActions from './useFilterActions';

const PendingInspectionMobile = ({openEditor}) => {
    const { pageSize, pageIndex } = useSelector((state: RootState) => state.pagination);
    const [openTotalInspections, setOpenTotalInspections] = useState<boolean>(false);
    const [, setPermitModal] = React.useState(false);
    const [permit, setPermit] = React.useState(null);
    const [, updateTable] = useManuallyUpdate();
    const currentUser = useSelector((state: RootState) => state.me.currentUserId);
    const permitInspection = useSelector((state: RootState) => state.inspections.permitInspections);
    const rows = useSelector((state: RootState) => state.inspections.inspections);    
    const filteredRows = useFilterActions(rows);
    const dispatch = useDispatch();
    const errors = null;
    const {
        isModalOpen,
        modalError,
        modalSuccess,
        modalTitle,
        values,
        createOrUpdate,
        handleEdit,
        handleCloseModal,
        setValues,
       } = useTablePage({
         updateEndpoint: updatePermitInspection,
         modalUpdateTitle: 'Update Inspection',
      });

    useEffect(() => {
      if (currentUser !== -1) {
        getPermitInspections({
        is_deleted: false,
        limit: pageSize,
        offset: pageIndex,
        ordering: '-is_emergency,follow_up',
        inspector: currentUser,
        isCompleted: false,
       }).then(data => {
         dispatch(setRowsCount(data.data.count));
         dispatch(setInspections(data.data.results));
       });
      }
    }, [pageSize, pageIndex, currentUser]);

    const onClick = item => {
          setPermit(item.permit);
          // handleEdit(item);
          openEditor(item);
    };

    const handleCloseForm = () => {
        if (!modalError && !modalSuccess) {
           setPermitModal(false);
           handleCloseModal();
        }
      };
    const totalInspectionClick = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenTotalInspections(true);
        getCustomActivities({
            is_deleted: false,
            limit: pageSize,
            offset: pageIndex,
            permit_id: item.permit,
            // sort_by: params?.ordering,
            // ordering:null,
            event_type: "Inspection",
          }).then(result => {
            dispatch(setPermitInspections(result.data.results));
          });
    };
    const data = [
        { label: "Activity Title", key1: "activityTitleName" },
        { label: "Activity Status", key1: "statusName" },
        { label: "Inspection Number", key1: "id" },
        { label: "Inspection Type", key1: "activityTypeName" },
        { label: "Inspection Date", key1: "inspectionDate" },
        { label: "Inspector", key1: "inspectorFirstName", key2: "inspectorLastName" },
        { label: "Activity Contact", key1: "contactFirstName", key2: "contactLastName" },
        { label: "Date Created", key1: "createdAt" },
      ];
      const filterMeta = [
        { label: "Project Name", name: "projectName" },
        { label: "Permit Number", name: "permitNumber" },
        { label: "Project Address", name: "projectAddress" },
        { label: "Inspection Type", name: "activityTypeName" },
      ];
    return (
      <>
        <MobileFilter filterMeta={filterMeta} />
        <MobileTableWrapper>

          {filteredRows.map((item, index) => {
                let comment = item.lastComment ? item.lastComment.text : '';
                const div = document.createElement('DIV');
                div.innerHTML = comment;

                comment = div.innerText;
                    return (
                      <RecordWrapper $primary={index % 2 === 0} key={index} onClick={() => onClick(item)}>
                        <div>
                          <TotalInspectionsButton onClick={e => totalInspectionClick(e,item)}>
                            {item.projectName}
                          </TotalInspectionsButton>
                        </div>
                        <MobileRecord
                          item={item}
                          fields={dailyPendingFields}
                          shaded={index % 2 === 0}
                          removeBorder
                        />

                        <CommentCell><strong>Comment:</strong> {comment}</CommentCell>
                      </RecordWrapper>
                            );
            })}
          {openTotalInspections &&
          <ModalDefaultDraggable title={`Total Inspections: ${permitInspection.length}`} onClose={() => setOpenTotalInspections(false)}>
            <TotalInspectionWrapper>
              {
                  permitInspection.map(
                    (item, index) => <MobileRecord fields={data} item={item} key={item.id} shaded={index % 2 === 0} />,
                    )}
            </TotalInspectionWrapper>
          </ModalDefaultDraggable>
          }
          <PaginationMobile /> 
        </MobileTableWrapper>
        {isModalOpen && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseForm}
        >
          <PermitInspectionsForm
          // <InspectorPermitInspectionsForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            setInitialValues={setValues}
            updateTable={updateTable}
          />
        </ModalDefaultDraggable>
      )}
      </>
    );
};

export const MobileTableWrapper = styled.div`
   display: none;
   @media only screen and (max-width: 840px)  {
       display: flex;
       flex-direction: column;
       max-height: 62vh;
       overflow-y:auto;
    }
    @media only screen and (max-width: 390px)  {
       max-height: 54vh;
    }
`;

const TotalInspectionsButton = styled.div`
background-color: #ccc;
width: fit-content;
padding: 11px;
border: 1px solid gray;
border-radius: 8px;
`;

const TotalInspectionWrapper = styled.div`
  height: 760px;
  overflow-y: auto;
  @media only screen and (max-width: 390px) {
    height: 590px;
  }
`;

export default PendingInspectionMobile;
