import * as React from 'react';
import styled from 'styled-components';

import {
  getNotInvoicedPermittingFeesReport,
  ReportTableType,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const NotInvoicedPermittingFeesReport = React.forwardRef(
  (
    {
        fromDate,toDate, projectManager,
      setIsValidForExport,
    }: ReportTableType,
    ref,
  ) => {
    const [
      isNullReport,
      setIsNullReport,
    ] = React.useState(false);
    const [additionalRowData, setAdditionalRowData] = React.useState([])    

    const data = {
      from_date: fromDate, to_date: toDate, manager_id: projectManager.id
    };
    let currentPermit = ""
    const getModifiedNotInvoicedPermittingFeesReport = () =>
      Promise.all([getNotInvoicedPermittingFeesReport(data)])
        .then(([reports]) => {                     
          if (reports.data?.length === 0) {
            setIsNullReport(true);
            setIsValidForExport(false);
          } else {
            setIsNullReport(false);
            setIsValidForExport(true);
            currentPermit = reports.data[0].permitDetails[0]
            
        }
        const permitDetailsArr = []
       
        let grandTotal = 0 
          reports.data.map((proj, projIdx) => {
            proj.permitDetails.map((prmt, prmtIdx) => {
                grandTotal += prmt.permitAmount
                prmt.feeDetails.map((fee, feeIdx) => {
                   
                    permitDetailsArr.push({projectNamePermitName: `${proj.projectName} | ${prmt.permitName}`,
                    permitNumber: prmt.permitNumber,
                            amount: fee.amount,
                            jurisdiction: proj.jurisdiction,
                            projectStatus: prmt.permitStatus,
                            company: prmt.companyName.map((com, i) => i === prmt.companyName.length - 1 ? com += "" : com += ", ")
                })
                })
            })
          })
          setAdditionalRowData([{title: "Count", value: permitDetailsArr.length}, {title: "Total", value: grandTotal.toFixed(2)}])          
          return {
              ...reports,
              data: {
                  ...reports.data,
                  results: permitDetailsArr
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});
        

    const columns = column => {
      if (
        [
          'franchiseName',
          'projectAddress',
          'totalFees',
          'permitDetails',
          'inspectorName',
          'lastComment',
          'projectName',
          'permitName',
        ].includes(column)
      ) {
        return null;
      }

      switch (column) {
        case 'permitNumber':
          return 'Permit#';
        case 'companyName':
          return 'Company';
        case 'jurisdiction':
          return 'Jurisdiction';
        case 'projectStatus':
            return 'Permit Status';
        case 'amount':
            return 'Amount';
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';

        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
        'permitNumber',
      'projectNamePermitName',
      'company',
      'jurisdiction',
      'projectStatus',
      'amount'
    ];

    if (isNullReport) {
      return (
        <InfoText>
          No project was found with selected Project Manager and Date Range.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Not Invoiced Permitting Fees Report</Title>

        <List>
          <li>
            <Bold>Project Manager: </Bold> {projectManager.managerName}
          </li>
          <li>
            <Bold>Project Created From: </Bold> {fromDate} <Bold>To: </Bold> {toDate}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedNotInvoicedPermittingFeesReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={null}
        //   additionalFeesTotalRow={totalFeesData}
          additionalBottomRow={additionalRowData}
        />
      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default NotInvoicedPermittingFeesReport;
