import * as React from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';

import { Tooltip } from '../atoms';
import DatePickerRange, {
  Props as DatePickerRangeProps,
} from './DatePickerRange';

const InputDatepickerRange = ({
  isRequired,
  error,
  className,
  ...props
}: Props) => {
  const title =
    error?.message ||
    (error?.types ? Object.values(error.types)[0] : null);

  return (
    <Tooltip title={title} placement="top">
      <div className={className}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <DatePickerRange hasError={!!error} {...props} />
      </div>
    </Tooltip>
  );
};

type Props = DatePickerRangeProps & {
  isRequired?: boolean;
  error?: FieldError | FieldErrors;
  className?: string;
};

export default InputDatepickerRange;
