import * as React from 'react';
import Radio, { RadioProps } from '@material-ui/core/Radio';

import CircleChecked from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const RadioButton = ({
  color = 'primary',
  size = 'small',
  ...props
}: RadioProps) => (
  <Radio
    color={color}
    size={size}
    icon={<CircleUnchecked />}
    checkedIcon={<CircleChecked />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default RadioButton;
