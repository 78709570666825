import * as React from 'react';
import useTablePage from '../hooks/useTablePage';
import { sendFeesInvoices } from '../api/fees';
import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import FeesSendForm from '../forms/FeesSendForm';
import ModalInfo from '../molecules/ModalInfo';
import { UserType } from '../api/users';
import { setIsViewContactsModal } from '../redux/slices/projectSlice';
import { useDispatch } from 'react-redux';

const FeesSendModal = ({
  permit,
  contacts,
  fees,
  isOpenFeesSendModal,
  setOpenFeesSendModal,
  updateTable,
}: Props) => {
  const {
    modalError,
    modalSuccess,
    modalTitle,
    createOrUpdate,
    handleAdd,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
    errors,
  } = useTablePage({
    createEndpoint: sendFeesInvoices,
    modalCreateTitle: `Send`,
  });

  const dispatch = useDispatch();
  const [accountingFound, setAccoutingFound] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (isOpenFeesSendModal) {
      handleAdd();
    }
  }, [isOpenFeesSendModal]);

  React.useEffect(() => {
    const found = contacts.find(c => c.isAccounting === true);
    setAccoutingFound(found !== undefined);
  }, [contacts]);

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      setOpenFeesSendModal(false);
      handleCloseModal();
    }
  };

  const handleAlert = () => {
    setOpenFeesSendModal(false);
    handleCloseModal();
    dispatch(setIsViewContactsModal(true))
  }
  const closeSuccessModal = () => {
    updateTable();
    setOpenFeesSendModal(false);
    handleCloseModalInfo();
  };
  return (
    <>
      {!accountingFound && 
       <ModalInfo
       errorMsg='Please make sure to select at least one contact on the permit as accounting contact.'
       timeToClose={5000}
       onClose={handleAlert}
       isError={true}
     />
      }
      {accountingFound && <>      
      {contacts.length !== 0 ? (
        <>
          <ModalDefaultDraggable
            onClose={handleCloseForm}
            title={modalTitle}
          >
            <FeesSendForm
              permit={permit}
              contacts={contacts}
              fees={fees}
              onSubmit={createOrUpdate}
              modalError={modalError}
              initialErrors={errors}
              setOpenFeesSendModal={setOpenFeesSendModal}
            />
          </ModalDefaultDraggable>
          {(modalSuccess || modalError) && (
            <ModalInfo
              timeToClose={5000}
              isError={modalError}
              onClose={closeSuccessModal}
              errorMsg={modalErrorTitle}
            />
          )}
        </>
      ) : (
        <ModalInfo
          timeToClose={5000}
          isError
          onClose={handleCloseForm}
          errorMsg="There are no suitable contacts for this permission."
        />
      )}
      </>}
    </>
  );
};

interface Props {
  permit: number;
  contacts: Array<UserType>;
  fees: Array<number>;
  isOpenFeesSendModal: boolean;
  setOpenFeesSendModal: (data: boolean) => void;
  updateTable: () => void;
}

export default FeesSendModal;
