export const SALES_REPORTS_DATA = [
  {
    value: '1',
    label: 'Admin Sales Report',
  },
  {
    value: '2',
    label: 'Paid Fees',
  },
  {
    value: '3',
    label: 'A/R Aging Summary',
  },
  {
    value: '4',
    label: 'A/R Aging Details',
  },

];
export const SALES_REPORTS_DATA_FA = [
  {
    value: '1',
    label: 'Admin Sales Report',
  },
  {
    value: '2',
    label: 'Paid Fees',
  },
  {
    value: '3',
    label: 'A/R Aging Summary',
  },
  {
    value: '4',
    label: 'A/R Aging Details',
  },
];
export const SALES_EMPLOYEE_REPORTS_DATA = [
  {
    value: 'dailyInspectionReport',
    label: 'Daily Inspection Report',
  },
  {
    value: 'runnerReport',
    label: 'Runner Report',
  },
  // {
  //   value: 'formsReport',
  //   label: 'Forms Report',
  // },
  {
    value: 'inspectionByPermitReport',
    label: 'Inspections By Permit Report',
  },
  {
    value: 'businessToBeInvoicedReport',
    label: 'Business to be Invoiced Report',
  },
  {
    value: 'companyOutstandingFeesReport',
    label: 'Company Outstanding Fees Report',
  },
  {
    value: 'notInvoicedPermittingFeesReport',
    label: 'Not Invoiced Permitting Fees Report',
  },
  {
    value: 'paidFeesReport',
    label: 'Paid Fees Report',
  },
  {
    value: 'inspectorTimeTrackingReport',
    label: 'Inspector Time Tracking Report',
  },
  {
    value: 'planReviewerTimeTrackingReport',
    label: 'Plan Reviewer Time Tracking Report',
  },
  {
    value: 'permitReviewCommand',
    label: 'Review Comments',
  },
];

export const REPORTS_NAMES_DATA = [
  {
    value: 'Super Admin Sales Report',
    label: 'Super Admin Sales Report',
  },
];

export const FRANCHISE_LEVEL_DATA = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
];

export const FRANCHISE_TYPE_DATA = [
  {
    value: 'C',
    label: 'Corporate',
  },
  {
    value: 'F',
    label: 'Branch',
  },
];

export const FORM_SETUP_FONT_SIZE = [
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 22,
    label: '22',
  },
  {
    value: 24,
    label: '24',
  },
  {
    value: 26,
    label: '26',
  },
  {
    value: 28,
    label: '28',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 32,
    label: '32',
  },
  {
    value: 34,
    label: '34',
  },
  {
    value: 36,
    label: '36',
  },
  {
    value: 38,
    label: '38',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 42,
    label: '42',
  },
  {
    value: 44,
    label: '44',
  },
  {
    value: 46,
    label: '46',
  },
  {
    value: 48,
    label: '48',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 52,
    label: '52',
  },
  {
    value: 54,
    label: '54',
  },
  {
    value: 56,
    label: '56',
  },
  {
    value: 58,
    label: '58',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 62,
    label: '62',
  },
  {
    value: 64,
    label: '64',
  },
  {
    value: 66,
    label: '66',
  },
  {
    value: 68,
    label: '68',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 72,
    label: '72',
  },
];
