import * as R from 'ramda';

const mapKeys = R.curry(
  (fn: (key: string) => string, obj: R.Dictionary<any>) => {
    const transform: Transform = R.pipe(
      R.keys,
      R.map(fn),
      (mappedKeys: string[]) =>
        R.zipObj(mappedKeys, R.values(obj)),
    );

    return transform(obj);
  },
);

type Transform = <T>(obj: T) => any;

export default mapKeys;
