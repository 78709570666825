import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import DrawerContent from '../../Common/Containers/DrawerContent';
import DocumentsTable, { loadDocumentsData } from './DocumentsTable';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import DocumentsItems from './DocumentsItems/DocumentsItems';
import useCloseDrawers from '../../../hooks/useCloseDrawers';
import useShowDetailsFromDashboard from '../../../hooks/useShowDetailsFromDashboard';
import { setSelectedDocumentPermit } from '../../../../../redux/slices/documentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

const Documents = () => {
  const dispatch = useDispatch();
    useGetNewProjectData();
    useCloseDrawers();
    useShowDetailsFromDashboard();
    const vocabulary = useSelector((s:RootState) => s.general.vocabulary);

    const onPermitItemSelected = item => {
      dispatch(setSelectedDocumentPermit(item))
      loadDocumentsData(item,dispatch,vocabulary);
    };
    return (       
        <ClientContainer>
        <BannerStrip label="DOCUMENTS"></BannerStrip>
        <WrapperWithRightDrawer>
          <DocumentsTable onPermitItemSelected={onPermitItemSelected}/>         
          <Drawer>
            <DrawerContent>
              <DocumentsItems onPermitItemSelected={onPermitItemSelected} />              
            </DrawerContent>
          </Drawer>
        </WrapperWithRightDrawer>
      </ClientContainer>
    );
};

export default Documents;