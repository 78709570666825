import * as React from 'react';
import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import AvatarModal from './AvatarModal';
import { Avatar, UnderlinedLink } from '../../atoms';
import { SuccessModal } from '../../molecules';
import {
  successfulRequestSelector,
  userInfoSelector,
} from '../../redux/ducks/userAuth';

const UserAvatar = () => {
  const { avatar } = useSelector(userInfoSelector);
  const userAvatarWasUpdated = useSelector(
    successfulRequestSelector,
  );

  const [
    isAvatarModalOpen,
    setIsAvatarModalOpen,
  ] = useState(false);

  const [
    isSuccessAvatarUploadModal,
    setIsSuccessAvatarUploadModal,
  ] = useState(false);

  useEffect(() => {
    if (userAvatarWasUpdated) {
      setIsAvatarModalOpen(false);
    }
  }, [userAvatarWasUpdated]);

  const showAvatarModal = useCallback(() => {
    setIsAvatarModalOpen(true);
  }, []);

  const hideAvatarModal = useCallback(() => {
    setIsAvatarModalOpen(false);
  }, []);

  return (
    <>
      <AvatarBlock onClick={showAvatarModal}>
        <AvatarStyled alt="avatar" src={avatar} />
        <ChangeAvatar>Change Photo</ChangeAvatar>
      </AvatarBlock>

      <ModalWrapper>
        {isAvatarModalOpen && (
          <AvatarModal
            onClose={hideAvatarModal}
            setIsSuccessAvatarUploadModal={
              setIsSuccessAvatarUploadModal
            }
            setIsAvatarModalOpen={setIsAvatarModalOpen}
          />
        )}

        {isSuccessAvatarUploadModal && (
          <SuccessModal
            title="Changes have been saved"
            onClose={() =>
              setIsSuccessAvatarUploadModal(false)
            }
          />
        )}
      </ModalWrapper>
    </>
  );
};

const ModalWrapper = styled.div`
  position: relative;
  z-index: var(--modalZIndex);
`;

const AvatarBlock = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const AvatarStyled = styled(Avatar)`
  margin-right: 24px;
`;

const ChangeAvatar = styled(UnderlinedLink).attrs({
  as: 'div',
})``;

export default UserAvatar;
