import { TABLE_LOAD_DATA_MODE } from './constants/settings';

const TABLE_LOAD_DATA_MODE_KEY = 'tableLoadDataMode';

export const setTableLoadDataMode = (
  mode: TABLE_LOAD_DATA_MODE,
): void => {
  localStorage.setItem(TABLE_LOAD_DATA_MODE_KEY, mode);
};

export const getTableLoadDataMode = (): TABLE_LOAD_DATA_MODE | null =>
  localStorage.getItem(
    TABLE_LOAD_DATA_MODE_KEY,
  ) as TABLE_LOAD_DATA_MODE;
