import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../../../redux/root';

const PopupMessage = ({ caption, children }) => {
  const showPopup = useSelector((s: RootState) => s.inspections.showPopup);

  return (
    <>
      {showPopup && (
        <Backdrop>
          <PopupWrapper>
            <Caption>{caption}</Caption>
            {children}
          </PopupWrapper>
        </Backdrop>
      )}
    </>
  );
};

export default PopupMessage;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.6);
  z-index: 3;
`;

const PopupWrapper = styled.div`
  width: 80%;
  padding: 10px;
  margin: 10%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border: 3px solid grey;
  border-radius: 6px;
  box-shadow: 5px 5px 0 1px hsla(0, 0%, 0%, 0.3);
  p {
    margin: 15px 80px;
    line-height: 30px;
  }
`;

const Caption = styled.div`
  font-size: 27px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;
