import * as React from 'react';
import styled from 'styled-components';
import { getReviewCommentsReport } from '../../api/reports';
import Table from '../../organisms/Table';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';
 
const PermitReport = React.forwardRef(
  ({ permitdata, setIsValidForExport }: Props, ref) => {
    const [
      isNullInspections,
      setIsNullInspections,
    ] = React.useState(false);
 
    const getModifiedRunnerReports = () =>
      Promise.all([
        getReviewCommentsReport({ permit_id: permitdata?.id }),
      ])
        .then(([runnerResponse]) => {
          if (runnerResponse.data.length === 0) {
            setIsNullInspections(true);
            setIsValidForExport(false);
          } else {
            setIsValidForExport(true);
            setIsNullInspections(false);
          }
 
          return {
            ...runnerResponse,
            data: {
              ...runnerResponse.data,
              results: runnerResponse.data.map((item) => {
                if (item?.reviewTypeComments !== null) {
                    return item.reviewTypeComments.map((comment, index) => ({
                        reviewType: item.reviewType,
                        reviewStatus: item.reviewStatus,
                        completedDate: item.completedDate,
                        assignedTo: item.assignedTo,
                        Comment: comment.comment
                    }));
                } else {
                    return {
                      reviewType: item.reviewType,
                      reviewStatus: item.reviewStatus,
                      completedDate: item.completedDate,
                      assignedTo: item.assignedTo,
                      Comment:null,
                    };
                }
            }).flat(),
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});
 
 
        const columns = column => {
          if (
            [
              'permitName',
              'projectName',
              'information',
              'id',
            ].includes(column)
          ) {
            return null;
          }
   
          switch (column) {
               default:
              return tableColumnsFormatter(column);
          }
        };
   
    if (isNullInspections) {
      return (
        <InfoText>
          No project was found with selected.
        </InfoText>
      );
    }
    const columnsArray = [
      {
        Header: 'Comment',
        accessor: 'comments',
        Cell: ({ value }) => (
          <>
            {value.map((comment, index) => (
              <tr key={`comment-${index}`}>
                <td colSpan={2}>{comment.comment}</td> {/* colSpan set to 2 for review_type_comments */}
              </tr>
            ))}
          </>
        ),
      },
      // ... (other columns)
    ];
    return (
      <Wrapper ref={ref}>
        <Title>Review Comments Report</Title>
        <List>
          <li>
            <Bold>Permit Name: {permitdata?.permitName} </Bold>
          </li>
        </List>
 
 
        <Table
          customSearch
          columns={columns}
          rows={getModifiedRunnerReports}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          isRunnerCheckbox
        />
      </Wrapper>
    );
  },
);
 
const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;
 
const Bold = styled.span`
  color: black;
  font-weight: bold;
`;
 
const Title = styled.h1`
  margin: 0;
`;
 
const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;
 
interface Props {
  permitdata: object;
  setIsValidForExport: (boolean) => void;
}
 
export default PermitReport;