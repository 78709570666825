import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getReviewerComments, updateClientReviewComments } from '../../../../../../../api/projectDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../redux/root';
import Save from '@material-ui/icons/Save';
import { Logo_Sky } from '../../../../../../../constants/menu';
import { setPermitActivityId, setReviewComments } from '../../../../../../../redux/slices/documentsSlice';
import { getCustomActivities } from '../../../../../../../api/activities';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import ButtonMain from '../../../../Common/Controls/ButtonMain';
import ModalDefaultDraggable from '../../../../../../../molecules/ModalDefaultDraggable';
import ButtonWithLoader from '../../../../../../../molecules/ButtonWithLoader';
import { sendReviewComments } from '../../../../../../../api/document';

const ReviewComments = () => {
  const { SelectedDocumentPermit, ReviewComments, permitActivityId } = useSelector((s: RootState) => s.documents);
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorids, setErrorIds] = useState([]);
  useEffect(() => {
    const loadComments = async () => {
      if (SelectedDocumentPermit.permitId) {
        const params = {
          is_deleted: false,
          permit_id: SelectedDocumentPermit.permitId,
          event_type: 'Activity',
          activity_type_name: 'Reviews',
        };
        const results = await getCustomActivities(params);
        const permitActivityId = _.get(results, 'data.results[0].id');
        const reviewCommentsFromApi = _.get(results, 'data.results[0].reviewTypeComment', []);
        const _reviewComments = reviewCommentsFromApi.map(item => ({ ...item, pristine: true }));
        dispatch(setReviewComments(_reviewComments));
        dispatch(setPermitActivityId(permitActivityId));
      }
    };
    loadComments();
  }, [SelectedDocumentPermit]);

  const onChange = (e, id) => {
    const value = e.target.value;
    const _reviewComments = ReviewComments.map(item => {
      if (item.id === id) {
        return {
          ...item,
          pristine: false,
          clientComment: value,
        };
      }
      return item;
    });
    dispatch(setReviewComments(_reviewComments));
  };

  const saveComment = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const result: any = await updateClientReviewComments(item);
    const _reviewComments = ReviewComments.map(item => {
      if (item.id === result.data.id) {
        return {
          ...item,
          pristine: true,
        };
      }
      return item;
    });
    dispatch(setReviewComments(_reviewComments));
    setErrorIds([]);
  };

  const sendReviewCommentsHandler = () => {
    //1. all are pristine
    //2. all have value in text
    let noErrorsFound = true;
    ReviewComments.forEach(item => {
      const isEmpty = value => {
        return value === undefined || value === null || value === '';
      };
      const setErrorId = id => {
        const _errorIds = [...errorids];
        _errorIds.push(id);
        setErrorIds(_errorIds);
      };
      if (!item.pristine) {
        setErrorId(item.id);
        noErrorsFound = false;
      }
      if (item.pristine && isEmpty(item.clientComment)) {
        setErrorId(item.id);
        noErrorsFound = false;
      }
    });
    setShowError(false);
    if (noErrorsFound) {
      setShowConfirmation(true);
    } else {
      setShowError(true);
    }
  };

  const persistReviewComments = () => {     ;
      //ReviewComments, permitActivityId

      //const commentsArr = ReviewComments.map( comment => { id: comment.id);
      const load = async () => {
        const result = await sendReviewComments(permitActivityId,ReviewComments);
        if(result.status === 200)
        {
          setShowConfirmation(false);
          const _ReviewComments = ReviewComments.map( item => ({
            ...item,
            isClientCommentVisible: true
          }))
          //
          dispatch(setReviewComments(_ReviewComments));
        }
      }
      load();
  }

  return (
    <>
      <ButtonWrapper>
        <ButtonMain label="Send Review Comments" onClick={sendReviewCommentsHandler} />
        {showError && <span>Please reply (or save) for all reviewer comments</span>}
      </ButtonWrapper>

      {ReviewComments.length === 0 && <NA>N/A</NA>}
      {ReviewComments.length > 0 &&
        ReviewComments.map((item, index) => (
          <ReviewsWrapper key={item.id} index={index}>
            <Label>
              Review Comment {index + 1}: {item.reviewerComment}
            </Label>
            <Label>Reference: {item.reference}</Label>
            {item.isClientCommentVisible && 
            <div>
              <Label>Client Comment:  {item.clientComment}</Label> 
              
              </div>
            }
            {!item.isClientCommentVisible && (
              <InputWrapper disabled={item.pristine} markError={errorids.indexOf(item.id) > -1}>
                <InputBox disabled={false} onChange={e => onChange(e, item.id)} value={item.clientComment} />
                <div onClick={e => saveComment(e, item)}>
                  <Save />
                </div>
              </InputWrapper>
            )}
          </ReviewsWrapper>
        ))}
      {showConfirmation && (
        <ModalDefaultDraggable title="Confirmation" onClose={() => setShowConfirmation(false)}>
          <>
            <p>This will send review comment replies to the project manager. Are you sure?</p>
            <br />
            <Buttons>
              <ButtonWithLoader loading={false} onClick={persistReviewComments}>
                Yes
              </ButtonWithLoader>
              <ButtonWithLoader loading={false} onClick={() => setShowConfirmation(false)}>
                No
              </ButtonWithLoader>
            </Buttons>
          </>
        </ModalDefaultDraggable>
      )}
    </>
  );
};

export default ReviewComments;

const ReviewsWrapper = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${({ index }) => (index % 2 === 0 ? 'rgb(230, 230, 230)' : 'rgb(240, 240, 240)')};
  border-bottom: 1px solid grey;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Label = styled.div`
  color: ${Logo_Sky};
  line-height: 30px;
  font-size: 17px;
  font-weight: bold;
`;

const InputWrapper = styled.div<{ disabled: boolean; markError: boolean }>`
  display: flex;
  margin-top: 12px;
  border: ${({ markError }) => (markError ? '4px solid red' : 'none')};
  svg {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    color: ${({ disabled }) => (disabled ? 'rgb(190,190,190)' : '#316B99')};
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 8px;
  span {
    color: red;
  }
`;

const InputBox = styled.input`
  width: 80%;
`;

const NA = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: ${Logo_Sky};
`;
