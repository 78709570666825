import * as R from 'ramda';

const methods = ['_in', '_qte', '_qt', '_lte', '_contains'];

// should be used only after objectKeysToSnakeCase method
export default params =>
  Object.keys(params).reduce((acc, key) => {
    const newKey = R.reduce(
      (currentKey, method) => {
        const suffixMethod = new RegExp(`${method}$`);

        if (suffixMethod.test(currentKey)) {
          return R.reduced(
            currentKey.replace(suffixMethod, `_${method}`),
          );
        }

        return currentKey;
      },
      key,
      methods,
    );

    acc[newKey] = params[key];
    return acc;
  }, {});
