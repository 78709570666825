import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import apiForgotPassword from '../api/forgotPassword';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {
  ButtonMain,
  ButtonInverted,
  TextInput,
  SuccessCheckmark,
} from '../atoms';

import {
  addErrorsForEmptyFields,
  isValidationSucceed,
} from '../utils/validation';
import { useSelector } from 'react-redux';
import { errorsSelector } from '../redux/ducks/requestApiError';


const initialValue: FormData = {
  email: '',
};
const initialErrorMessage: FormErrors = {
  email: null,
};
const ForgotPasswordForm = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(initialValue);
  const [error, setError] = React.useState(
    initialErrorMessage,
  );
  const [
    instructionIsSent,
    setInstructionIsSent,
  ] = React.useState(false);

  const apiErrors = useSelector(
    errorsSelector,
  );

  const handleReminderSend = () => {
    apiForgotPassword
      .resetPasswordSendMail(value)
      .then(() => {
        setInstructionIsSent(true);
      })
      .catch(e => {
        const errorResponse =
          e.response.data.email[0] ||
          'Enter a valid email address';
        setError({ email: errorResponse });
      });
  };

  const handleChangeValue = e => {
    setValue({ email: e.target.value });
    setError({email: ""})
  };

  const validateForm = () => {
    const newError = addErrorsForEmptyFields(
      value,
      error,
      'This field can not be empty',
    );

    setError(newError);

    return isValidationSucceed(newError);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      handleReminderSend();
    }
  };

  const ErrorMessage = () => {
    return (
      <p style={{display: 'flex', alignItems: 'center', padding: 0, margin: 0}}>
      <WarningRoundedIcon /> {apiErrors.detail}
      </p>
    )
  }

  return (
    <>
      {!instructionIsSent && (
        <>
          <Description>
            We will send you an email with instructions on
            how to reset your password
          </Description>

          <Form onSubmit={handleSubmit}>
            <StyledTextInput
              label="Your Email"
              value={value.email}
              onChange={handleChangeValue}
              error={!!apiErrors.detail || error.email}
              helperText={(apiErrors.detail && <ErrorMessage />) || error.email}
            />
            <ButtonsWrapper>
              <StyledButtonMain type="submit">
                Send
              </StyledButtonMain>
              <StyledButtonInverted
                onClick={() => history.push(ROUTES.LOG_IN)}
              >
                Cancel
              </StyledButtonInverted>
            </ButtonsWrapper>
          </Form>
        </>
      )}

      {instructionIsSent && (
        <SuccessSendWrapper>
          <StyledSuccessCheckmark />
          <p>
            The email with instructions on how to reset your
            password has been sent to
            <span> {value.email}</span>
          </p>
        </SuccessSendWrapper>
      )}
    </>
  );
};

const Form = styled.form`
  width: 100%;
  margin-bottom: 73px;
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 46px;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;

  & button {
    height: 36px;
  }
`;

const StyledButtonMain = styled(ButtonMain)`
  margin-right: 16px;
  width: 112px;
`;

const StyledButtonInverted = styled(ButtonInverted)`
  width: 112px;
`;

const SuccessSendWrapper = styled.div.attrs({
  className: 'font-body2',
})`
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--mainText);
  line-height: 17px;
  margin-bottom: 344px;

  & span {
    font-weight: bold;
  }
`;

const StyledSuccessCheckmark = styled(SuccessCheckmark)`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const Description = styled.p.attrs({
  className: 'font-body2',
})`
  width: 100%;
  color: var(--secondaryText);
  margin-top: 0;
  text-align: center;
`;

interface Props {
  onReminderSend: () => void;
  value: FormData;
  setValue: (arg: FormData) => void;
  error: FormErrors;
  setError: (arg: FormErrors) => void;
  instructionIsSent: boolean;
}

interface FormErrors {
  [s: string]: string;
  email: string;
}

interface FormData {
  email: string;
}

export default ForgotPasswordForm;