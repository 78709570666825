import styled, { css } from 'styled-components';

const TextWithDropIcon = styled.div<Props>`
  position: relative;
  padding-right: ${({ width }) => `${width * 2}px`};
  --arrow-color: var(--primary);
  cursor: pointer;

  &:after {
    transition: transform 0.3s;
    transform: rotate(
      ${({ open }) => (open ? '180deg' : 0)}
    );
    content: '';
    position: absolute;
    top: 50%;
    margin-top: ${({ height }) => `-${height / 2}px`};
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ height, width }) =>
      `${height}px ${width / 2}px 0 ${width / 2}px`};
    border-color: var(--arrow-color) transparent transparent
      transparent;

    ${({ open }) =>
      open &&
      css`
        transform: rotate(180deg);
      `} );
  }
`;

TextWithDropIcon.defaultProps = {
  width: 10,
  height: 6,
};

interface Props {
  open: boolean;
  width?: number;
  height?: number;
}

export default TextWithDropIcon;
