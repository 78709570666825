import * as React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';
import { ReactDatePickerProps } from 'react-datepicker';

import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';

import { DatePicker } from '../atoms';
import { DEFAULT_DATE_FORMAT } from '../constants';

const DatePickerRange = ({
  initialDates = [null, null],
  onRangeChange,
  hasError,
  isRequired,
  placeholderText = 'Select Date',
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    initialDates[0],
  );
  const [endDate, setEndDate] = React.useState(
    initialDates[1],
  );

  const selectDateMessage = <span>{placeholderText}</span>;

  const range = isOpen ? (
    selectDateMessage
  ) : (
    <>
      <span>
        {startDate &&
          format(startDate, DEFAULT_DATE_FORMAT)}
      </span>
      <span>→</span>
      <span>
        {endDate && format(endDate, DEFAULT_DATE_FORMAT)}
      </span>
    </>
  );

  const handleOnChange = dates => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (onRangeChange) {
      onRangeChange(start, end);
    }
  };

  React.useEffect(() => {
    if (endDate && isOpen) {
      setIsOpen(false);
    }
  }, [endDate]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Container
        onClick={() => setIsOpen(true)}
        hasError={hasError}
      >
        <DataContainer>
          <EventNoteOutlinedIcon />
          {startDate && endDate ? range : selectDateMessage}
        </DataContainer>
        <DatePicker
          open={isOpen}
          selectsRange
          endDate={endDate}
          selected={startDate}
          startDate={startDate}
          required={isRequired}
          showPopperArrow={false}
          onChange={handleOnChange}
          placeholderText={placeholderText}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Container>
    </ClickAwayListener>
  );
};

const Container = styled.div.attrs({
  className:
    'MuiFormControl-root MuiTextField-root WithStyles(ForwardRef(TextField))-root-1',
})<{ hasError: boolean }>`
  position: relative;
  min-width: 40px;
  border: 1px solid
    ${({ hasError }) =>
      hasError
        ? 'var(--mainRequired)'
        : 'rgba(0, 0, 0, 0.23)'};

  &:hover {
    border: 1px solid
      ${({ hasError }) =>
        hasError
          ? 'var(--mainRequired)'
          : 'rgba(0, 0, 0, 0.87)'};
  }

  .react-datepicker-wrapper {
    display: none;
  }

  .react-datepicker-popper {
    top: 32px !important;
    left: -51px !important;
    transform: none !important;
  }
`;

const DataContainer = styled.div.attrs({
  className:
    'MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl',
})`
  display: flex;
  padding: 0 16px;
  cursor: pointer;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  background-color: var(--defaultBackground);

  > svg {
    top: 1px;
    position: relative;
    font-size: 1.125rem;
    margin: 0 8px 0 0;
  }

  > span {
    text-transform: uppercase;

    &:nth-of-type(2) {
      padding: 0 8px;
    }
  }
`;

export type Props = Omit<
  ReactDatePickerProps,
  'onChange'
> & {
  isRequired?: boolean;
  initialDates?: Date[];
  hasError?: boolean;
  onChange?: (
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any> | undefined,
  ) => void;
  onRangeChange?: (start: Date, end: Date) => void;
};

export default DatePickerRange;
