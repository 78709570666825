import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  SwapVert,
  ArrowDownward,
  ArrowUpward,
} from '@material-ui/icons';
import { TableHeader } from '../types';
import { RootState } from '../../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTableSortingDirection,
  setTableSortingKey,
} from '../../../../../../redux/slices/generalSlice';
import CellFilter from './CellFilter';
import { Logo_Sky } from '../../../../../../constants/menu';

const TableHeaderCell: FC<TableHeader> = ({
  label,
  sortingKey,
  hideFilter,
  itemName
}) => {
  const [selected, setSelected] = useState(0);
  const [active, setActive] = useState(false);
  const { tableSortingKey } = useSelector(
    (state: RootState) => state.general,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setActive(sortingKey === tableSortingKey);
  }, [tableSortingKey]);

  useEffect(() => {
    dispatch(setTableSortingDirection(getSortingValue()));
  }, [selected]);

  const getSortingValue = () => {
    if (selected % 3 === 0) return 'NONE';
    return selected % 3 === 1 ? 'ASC' : 'DESC';
  };

  return (
    <div>      
      <HeaderCell>
        <LogoSky>{label}</LogoSky>
        <div>
          {sortingKey !== undefined && (
            <LogoSky>
               <SortingIcon
              onClick={() => {
                setSelected(selected + 1);
                dispatch(setTableSortingKey(sortingKey));
              }}
            >
              {active ? (
                <>
                  {selected % 3 === 0 && <SwapVert />}
                  {selected % 3 === 1 && <ArrowUpward />}
                  {selected % 3 === 2 && <ArrowDownward />}
                </>
              ) : (
                <SwapVert />
              )}
            </SortingIcon>
            </LogoSky>
           
          )}
        </div>
      </HeaderCell>
      {!hideFilter && <CellFilter name={itemName}/>}
      {hideFilter && <FilterSpacer />}
    </div>
  );
};

export default TableHeaderCell;

const LogoSky = styled.div`
  color: ${Logo_Sky};
`;

const HeaderCell = styled.div`
  display: flex;
  text-align: center;
  padding-left: 10px;
  svg{
    margin-left:20px;
  }
`;

const SortingIcon = styled.div`
  cursor: pointer;
`;

const FilterSpacer = styled.div`  
  width:3px;
  height: 53px;
`;