import * as React from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';

import { getAdminPaidFeesReports, getAdminSalesReports, getAdminAgingSummary } from '../../api/reports';

import Table from '../../organisms/Table';
import ActionsPanelReports from '../../organisms/ActionsPanelReports';
import DashboardLayout from '../../layouts/DashboardLayout';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import ReportsTable from '../../organisms/ReportsTable';
import useUserRole from '../../hooks/useUserRole';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import AgingReportDetails from './AgingReportDetails/AgingReportDetails';

const SalesPage = () => {
  const [
    reportsSettings,
    setReportsSettings,
  ] = React.useState(null);
  const FAfranchise = useSelector((info: RootState) => info.userAuth.info.franchise[0].name)  
  const SUBranches = reportsSettings?.branches?.map(el => el?.name)?.join(", ");
  const reportFrequency = reportsSettings?.frequency?.name;
  const reportType = reportsSettings?.reportType?.name;
  const startDate = reportsSettings?.fromDate;
  const endDate = reportsSettings?.toDate;
  const isReimbursable = reportsSettings?.reimbursables;
  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );
  const [paidFeesAmt, setPaidFeesAmt] = React.useState<number>(
    null,
  );
  const [reimbursablesFeesAmt, setReimbursablesFeesAmt] = React.useState<number>(
    null,
  );
  const printableRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ];
  const currentDate = new Date()

  const handleExportToPDF = () => {
    handlePrint();
  };

  const getModifiedSalesReport = data => {
    setIsLoading(true);

    return (
      getAdminSalesReports({
        ...data,
        franchises: data.franchises,
        fromDate: data.fromDate,
        toDate: data.toDate,
      })
        .then(response => {
          return {
            ...response,
            data: {
              ...response.data,
              results: response.data.results.map(el => {
                return {
                  ...el,
                  comments: ``,
                };
              }),
            },
          };
        })

        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

const [feeServiceTypeAmt, setFeeServiceTypeAmt] = React.useState(null)
  const getModifiedPaidFeesReport = (data) => {
    const branchIds = data?.branches?.map(branch => branch?.id)
    setIsLoading(true);
    return (
      getAdminPaidFeesReports({
        // ...data,
        from_date: data?.fromDate,
        to_date: data?.toDate,
        report: data?.frequency?.name,
        scope: data?.reportType?.name,
        is_reimburse: data?.reimbursables ? true : false,
        franchise_id: branchIds,
      })
      .then(response => {
        if(data?.reimbursables) {
          setPaidFeesAmt(response?.data?.amountWithoutReimbursables)
          setReimbursablesFeesAmt(response?.data?.total)
        } else {
          setPaidFeesAmt(response?.data?.total)
        }
          
          const obj = {}
          const feeServiceTypes = response?.data?.fees?.map(el => {
            if(Object.keys(obj).includes(el?.feeServiceType)) {
              obj[el?.feeServiceType] = obj[el?.feeServiceType] + el?.amount
            } else {
              obj[el?.feeServiceType] = el?.amount
            }
          })
          setFeeServiceTypeAmt(obj)
          
          return {
            ...response,
            data: {
              ...response.data,
              results: response?.data?.fees?.map(el => {
                // const feePaidDate = formatDateTime(new Date(el?.datePaid), DEFAULT_DATE_FORMAT)
                return {
                  ...el,
                  projectPermit: el?.projectName + ' | ' + el?.permitName,
                  amount: `$${el?.amount}`,
                  total: `$${el?.amount + el?.reimbursables}`
                  
                };
              }),
            },
          };
        })

        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  const getModifiedPaidFeesReportMonthly = (data) => {    
    setIsLoading(true);
    let branchIds = [];
    if(data.branches){
      branchIds = data.branches.map(branch => branch.id)
    } 

    return (
      getAdminPaidFeesReports({
        // ...data,
        from_date: data?.fromDate,
        to_date: data?.toDate,
        report: data?.frequency?.name,
        scope: data?.reportType?.name,
        is_reimburse: data?.reimbursables ? true : false,
        franchise_id: branchIds,
      })
        .then(response => {
          if(data?.reimbursables) {
            setPaidFeesAmt(response?.data?.amountWithoutReimbursables)
            setReimbursablesFeesAmt(response?.data?.total)
          } else {
            setPaidFeesAmt(response?.data?.total)
          }
          const obj = {}          
          const feeServiceTypes = response?.data?.fees?.map(el => {
            
            if(Object.keys(obj).includes(el?.feeServiceType)) {
              obj[el?.feeServiceType] = obj[el?.feeServiceType] + el?.amount
            } else {
              obj[el?.feeServiceType] = el?.amount
            }
          })
          setFeeServiceTypeAmt(obj)
          return {
            ...response,
            data: {
              ...response.data,
              results: response?.data?.fees?.map(el => {
                return {
                  ...el,
                  projectPermit: el?.projectName + ' | ' + el?.permitName,
                  amount: `$${el?.amount}`,
                  total: `$${el?.reimbursables + el?.amount}`,
                  reimbursables: `$${el?.reimbursables}`
                };
              }),
            },
          };
        })

        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  const getModifiedAgingServices = (data) => {
   const branchIds = data?.branches?.map(branch => branch?.id)
    setIsLoading(true);
    return (
      getAdminAgingSummary(branchIds)
      .then(response => {                
        const modifiedData = [...Object.values(response?.data[0]), 
          {"1_30": <b>${response?.data[1]?.grandTotal["1_30"] || 0}</b>,
           "31_60": <b>${response?.data[1]?.grandTotal["31_60"] || 0}</b>,
            "61_90": <b>${response?.data[1]?.grandTotal["61_90"] || 0}</b>,
            "91AndOver": <b>${response?.data[1]?.grandTotal["91AndOver"] || 0}</b>,
             current: <b>${response?.data[1]?.grandTotal["current"] || 0}</b>,
             grandTotal: <b>${response?.data[1]?.grandTotal["grandTotal"] || 0}</b>,
              companyName: <b>Total</b>}]
        
          return {
            ...response,
            data: {
              ...response.data,
              results: modifiedData?.map(el => {                
                return {
                  ...el                  
                };
              }),
            },
          };
        })
 
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
        })
    );
  }

  const dailyPaidDetailColumnsOrder = ['projectPermit', 'permitNumber', 'feeServiceType', 'companyName','name', 'invoiceNumber', 'datePaid', 'amount'];
  const monthlyPaidDetailColumnsOrder = ['projectPermit', 'permitNumber', 'feeServiceType', 'companyName','contactName', 'invoiceNumber', 'datePaid', 'amount'];
  const monthlyPaidDetailRemColumnsOrder = ['projectPermit', 'permitNumber', 'feeServiceType', 'companyName', 'name', 'invoiceNumber', 'datePaid', 'reimbursables', 'amount', 'total'];
  const dailyPaidSummaryColumnsOrder = ['name', 'type', 'amount']
  const dailyPaidSummaryRemColumnsOrder = ['name', 'type', 'reimbursables', 'amount', 'total']
  const monthlyPaidSummaryRemColumnsOrder = ['name', 'type', 'reimbursables', 'amount', 'total']
  const agingSummaryColumnsOrder = ['companyName', 'current', '1_30', '31_60', '61_90', '91AndOver', 'grandTotal']

  const getColumns = column => {
    if (['franchiseId', 'id'].includes(column)) {
      return null;
    }
    
    if(reportsSettings?.report == 1) {
      switch (column) {
        case 'name':
          return 'Franchise Name';
        case 'comments':
          return 'Comments \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  
        default:
          return tableColumnsFormatter(column);
      }
    }
    if(reportsSettings?.report == 3) {
      switch (column) {
        case 'grandTotal':
          return 'Total';
        case 'companyName':
          return 'Contact Name';
        case 'current':
          return 'CURRENT';
        case '1_30':
          return '1 - 30';
        case '31_60':
          return '31 - 60';
        case '61_90':
          return '61 - 90';
        case '91AndOver':
        return '91 AND OVER';
        
        default:
          return tableColumnsFormatter(column);
      }
    }
    if(reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 1 && reportsSettings?.reportType?.id === 1 && isReimbursable) {      
      if (['date', 'invoiceCustomerStripeId', 'projectName', 'permitName', 'type', 'feeServiceType', 'projectPermit', 'datePaid'].includes(column)) {
        return null;
      }
      switch (column) {
        case 'amount':
          return 'Amount without Reimbursables'
          case 'reimbursables':
            return 'Reimbursables'
        default:
          return tableColumnsFormatter(column);
      }
    }
    if(reportsSettings?.report == 2 && reportsSettings?.reportType?.id === 1 && reportsSettings?.frequency?.id === 2 && isReimbursable) {      
      if (['date', 'invoiceCustomerStripeId', 'projectPermit', 'datePaid'].includes(column)) {
        return null;
      }
      switch (column) {
        case 'total':
          return 'Total';
        case 'amount':
          return 'Amount without Reimbursables'
        default:
          return tableColumnsFormatter(column);
      }
    }
    if(reportsSettings?.report == 2 && reportsSettings?.reportType?.id === 1) {      
      if (['date', 'invoiceCustomerStripeId', 'projectPermit', 'datePaid', 'total', 'reimbursables'].includes(column)) {
        return null;
      }
      switch (column) {
        default:
          return tableColumnsFormatter(column);
      }
    }
    
    if(reportsSettings?.report == 2 && reportsSettings?.reportType?.id === 2 && isReimbursable) {      
      if (['date', 'invoiceCustomerStripeId', 'projectName', 'permitName', 'type'].includes(column)) {
        return null;
      }
      switch (column) {  
        case 'projectPermit':
          return 'Project Name | Permit Name';
        case 'name':
          return 'Contact Name';
        case 'reimbursables':
          return 'Reimbursable Amount';
        case 'amount':
          return 'Amount without Reimbursables';
        default:
          return tableColumnsFormatter(column);
      }
    }
    if(reportsSettings?.report == 2 && reportsSettings?.reportType?.id === 2) {      
      if (['date', 'invoiceCustomerStripeId', 'projectName', 'permitName', 'total', 'reimbursables'].includes(column)) {
        return null;
      }
      switch (column) {  
        case 'projectPermit':
          return 'Project Name | Permit Name'
        default:
          return tableColumnsFormatter(column);
      }
    }
  };
const [additional, setAdditional] = React.useState(null)
  React.useEffect(() => {
    if(reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 2) {
      getAdminPaidFeesReports({
        from_date: reportsSettings?.fromDate,
        to_date: reportsSettings?.toDate,
        report: reportsSettings?.frequency?.name,
        scope: reportsSettings?.reportType?.name,
        is_reimburse: reportsSettings?.reimbursables ? true : false,
      }).then(response => {
        const unique = response?.data?.fees?.map(item => item.datePaid)
  .filter((value, index, self) => self.indexOf(value) === index).map((el, idx) => {
    return {id: idx, datePaid: el}
})
        setAdditional({data: unique, isDailyInspection: false})
        
      })
    }

  }, [isLoading === true])

const {isSuperAdmin} = useUserRole();
  return (
    <DashboardLayout>
      <ActionsPanelReports
        onExportToPDF={handleExportToPDF}
        onSubmit={setReportsSettings}
        isLoading={isLoading}
        handlePrint={handlePrint}
      />
      <Container>
        {(reportsSettings && reportsSettings?.report == 1) ? (
          <Wrapper ref={printableRef}>
            <Table
              customSearch
              columns={getColumns}
              rows={getModifiedSalesReport}
              additionalRequestParams={reportsSettings}
              additionalActions={null}
            />
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 3) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>A/R Aging Summary</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>As of {months[currentDate.getMonth()]} {currentDate.getDate()}, {currentDate.getFullYear()}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              rows={getModifiedAgingServices}
              columnsOrder={agingSummaryColumnsOrder}
              additionalRequestParams={reportsSettings}
              additionalActions={null}
            />
          </Wrapper>
        ): (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 1 && reportsSettings?.reportType?.id === 1&& !isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees {reportFrequency} {reportType} 
                {reportsSettings.reimbursables ? 
                  ' with Reimbursables' : ''}
              </h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate}</h3>
              <h3>Reimbursables: {!isReimbursable && "No"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={dailyPaidSummaryColumnsOrder}
              rows={getModifiedPaidFeesReport}
              additionalRequestParams={reportsSettings}
              additionalActions={null}
            />
            <b>Paid Fees for {startDate}: ${paidFeesAmt}</b>
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 1 && reportsSettings?.reportType?.id === 1 && isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees {reportFrequency} {reportType} 
                {isReimbursable ? 
                  ' with Reimbursables' : ''}
              </h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate}</h3>
              <h3>Reimbursables: {isReimbursable && "Yes"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={dailyPaidSummaryRemColumnsOrder}
              rows={getModifiedPaidFeesReport}
              additionalRequestParams={reportsSettings}
              additionalActions={null}
            />
            <b>Paid Fees for {startDate} (Reimbursables): ${reimbursablesFeesAmt}</b><br />
            <b>Paid Fees for {startDate} (Without Reimbursables): ${paidFeesAmt}</b>
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 1 && reportsSettings?.reportType?.id === 2 && !isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees Daily Detail</h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate}</h3>
              <h3>Reimbursables: {!isReimbursable && "No"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={dailyPaidDetailColumnsOrder}
              rows={getModifiedPaidFeesReport}
              additionalRequestParams={reportsSettings}
              additionalActions={null}
            />
            <b>Paid Fees for {startDate}: ${paidFeesAmt}</b>
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 1 && reportsSettings?.reportType?.id === 2 && isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees Daily Detail ({isReimbursable ? "Reimbursables" : ""})</h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate}</h3>
              <h3>Reimbursables: {isReimbursable && "Yes"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={dailyPaidDetailColumnsOrder}
              rows={getModifiedPaidFeesReport}
              additionalRequestParams={reportsSettings}
              additionalActions={null}
            />
            {/* <b>Paid Fees for {startDate} (Reimbursables): ${reimbursablesFeesAmt}</b><br />
            <b>Paid Fees for {startDate} (Without Reimbursables): ${paidFeesAmt}</b><br />
            {feeServiceTypeAmt && Object.keys(feeServiceTypeAmt)?.map(el => <b>{el} Total: ${feeServiceTypeAmt[el]} <br /></b>)}
            <b>Grand Total: ${reimbursablesFeesAmt + paidFeesAmt}</b> */}
            {feeServiceTypeAmt && <ReportsTable from={startDate} reimbursables={reimbursablesFeesAmt} notReimbursables={paidFeesAmt} types={feeServiceTypeAmt} total={paidFeesAmt + reimbursablesFeesAmt} />}
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 2 && reportsSettings?.reportType?.id === 1 && !isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees Monthly Summary</h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate} - {endDate}</h3>
              <h3>Reimbursables: {!isReimbursable && "No"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={dailyPaidSummaryColumnsOrder}
              rows={getModifiedPaidFeesReportMonthly}
              additionalRequestParams={reportsSettings}
              additionalTopRow={additional}
              additionalActions={null}
            />
            <b>Paid Fees from {startDate} to {endDate}: ${paidFeesAmt}</b>
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 2 && reportsSettings?.reportType?.id === 2 && !isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees Monthly Detail</h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate} - {endDate}</h3>
              <h3>Reimbursables: {!isReimbursable && "No"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={monthlyPaidDetailColumnsOrder}
              rows={getModifiedPaidFeesReportMonthly}
              additionalRequestParams={reportsSettings}
              additionalTopRow={additional}
              additionalActions={null}
            />
            {/* <b>Paid Fees from {startDate} to {endDate}: ${paidFeesAmt}</b>
            <br />
            {feeServiceTypeAmt && Object.keys(feeServiceTypeAmt)?.map(el => <b>{el} Total: ${feeServiceTypeAmt[el]} <br /></b>)}
            <b>Grand Total: ${paidFeesAmt}</b> */}
            {feeServiceTypeAmt && <ReportsTable from={startDate} to={endDate} notReimbursables={paidFeesAmt} types={feeServiceTypeAmt} total={paidFeesAmt} />}
          </Wrapper>
        ) : (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 2 && reportsSettings?.reportType?.id === 1 && isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees Monthly Summary with Reimbursables</h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate} - {endDate}</h3>
              <h3>Reimbursables: {isReimbursable && "Yes"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={monthlyPaidSummaryRemColumnsOrder}
              rows={getModifiedPaidFeesReportMonthly}
              additionalRequestParams={reportsSettings}
              additionalTopRow={additional}
              additionalActions={null}
            />
            <b>Paid Fees from {startDate} to {endDate} (Reimbursables): ${reimbursablesFeesAmt}</b><br />
            <b>Paid Fees from {startDate} to {endDate} (Without Reimbursables): ${paidFeesAmt}</b>
          </Wrapper>
        ): (reportsSettings && reportsSettings?.report == 2 && reportsSettings?.frequency?.id === 2 && reportsSettings?.reportType?.id === 2 && isReimbursable) ? (
          <Wrapper ref={printableRef}>
            <div>
              <h3>Paid Fees Monthly Detail with Reimbursables</h3>
              <h3>Frequency: {reportFrequency}</h3>
              <h3>Report type: {reportType}</h3>
              <h3>Branch: {isSuperAdmin ? SUBranches : FAfranchise}</h3>
              <h3>Date: {startDate} - {endDate}</h3>
              <h3>Reimbursables: {isReimbursable && "Yes"}</h3>
            </div>
            <Table
              customSearch
              columns={getColumns}
              columnsOrder={monthlyPaidDetailRemColumnsOrder}
              rows={getModifiedPaidFeesReportMonthly}
              additionalRequestParams={reportsSettings}
              additionalTopRow={additional}
              additionalActions={null}
            />
            {feeServiceTypeAmt && <ReportsTable from={startDate} to={endDate} reimbursables={reimbursablesFeesAmt} notReimbursables={paidFeesAmt} types={feeServiceTypeAmt} total={paidFeesAmt + reimbursablesFeesAmt} />}
          </Wrapper>
        ) : (reportsSettings && !reportsSettings.report ) ? (
          <Wrapper ref={printableRef}>
            <AgingReportDetails />
          </Wrapper>
        ):(
          <InfoText>
            Please, select Report from top menu and click on
            «Submit» button.
          </InfoText>
        )}
      </Container>
    </DashboardLayout>
  );
};

const Wrapper = styled.div`
  width: 100%;

  @media print {
    margin: 0;
    padding: 10px;
    display: block;
    width: 1263px;

    & table {
      font-size: 12px;
    }
  }
`;

const Container = styled.div`
  margin: 24px 0 0 0;
`;

const InfoText = styled.div`
  color: var(--secondaryText);
  text-align: center;
`;

export default SalesPage;
