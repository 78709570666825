import * as React from 'react';
import styled from 'styled-components';
import Select, {
  components as Components,
  Props,
  OptionsType,
} from 'react-select';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { hex2rgba } from '../utils';
import theme from '../theme';

const allOption = {
  name: 'Select all',
  value: '*',
};

const ValueContainer = prps => {
  const currentValues = prps.getValue();
  let toBeRendered = prps.children;
  if (
    currentValues.some(val => val.value === allOption.value)
  ) {
    toBeRendered = [
      [prps.children[0][0]],
      prps.children[1],
    ];
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Components.ValueContainer {...prps}>
      {toBeRendered}
    </Components.ValueContainer>
  );
};

const MultiValue = prps => {
  const isShowCompanyName = prps?.selectProps?.showCompany;
  let labelToBeDisplayed = `${
    prps.data.name ||
    prps.data.title ||
    (isShowCompanyName &&
      `${prps.data.firstName} ${prps.data.lastName}${
        prps.data.companyName
          ? ` - ${prps.data.companyName}`
          : ''
      }`)
  }`;

  if (prps.data.value === allOption.value) {
    labelToBeDisplayed = 'All is selected';
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Components.MultiValue {...prps}>
      <span>{labelToBeDisplayed}</span>
    </Components.MultiValue>
  );
};

const customAddPermitStyle = {
  control: styles => styles,
  option: (styles, { data }) => {
    if (data.button) {
      return {
        ...styles,
        color: 'white',
        backgroundColor: '#0b6b9b',
        cursor: 'pointer',
      };
    }
    return styles;
  },
  input: styles => styles,
  placeholder: styles => styles,
  singleValue: styles => styles,
};

const customTableStyles = {
  control: styles => styles,
  option: styles => {
    return {
      ...styles,
      padding: '4px 12px',
      fontSize: '12px',
    };
  },
  menuList: styles => {
    return {
      ...styles,
      padding: 0,
    };
  },
  menu: styles => {
    return {
      ...styles,
      marginTop: '-11px',
    };
  },
  input: styles => styles,
  placeholder: styles => styles,
  singleValue: styles => styles,
};

const SelectInput = (props: SelectTypes) => {
  const {
    components,
    error,
    selectAllActive,
    options,
    isMulti,
    defaultStyles,
    tableStyles,
  } = props;
  const DropdownIndicator = prps => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Components.DropdownIndicator {...prps}>
      {prps.selectProps.menuIsOpen ? (
        <ArrowDropUpIcon style={{height: "22px"}} />
      ) : (
        <ArrowDropDownIcon style={{height: "22px"}} />
      )}
    </Components.DropdownIndicator>
  );

  if (selectAllActive && isMulti) {
    return (
      <WrapperForSelectWithOpenMenu error={error}>
        <Select
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            MultiValue,
            ValueContainer,
            ...components,
          }}
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          options={
            [allOption, ...options] as OptionsType<any>
          }
          onChange={(selected, event) => {
            if (selected !== null && selected.length > 0) {
              if (
                selected[selected.length - 1].value ===
                allOption.value
              ) {
                return props.onChange([
                  allOption,
                  ...options,
                ]);
              }
              if (
                selected.includes(allOption) &&
                (event.action === 'remove-value' ||
                  (event.action === 'deselect-option' &&
                    event.option === allOption))
              ) {
                return props.onChange([]);
              }
              let result = [];
              if (selected.length === options.length) {
                if (selected.includes(allOption)) {
                  result = selected.filter(
                    option =>
                      option.value !== allOption.value,
                  );
                } else if (
                  event.action === 'select-option'
                ) {
                  result = [allOption, ...options];
                }
                return props.onChange(result);
              }
            }

            return props.onChange(selected);
          }}
        />
      </WrapperForSelectWithOpenMenu>
    );
  }

  if (isMulti && !selectAllActive) {
    return (
      <WrapperForSelectWithOpenMenu error={error}>
        <Select
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            ...components,
          }}
          classNamePrefix="react-select"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </WrapperForSelectWithOpenMenu>
    );
  }

  if (defaultStyles || tableStyles) {
    return (
      <Container hasError={error}>
        <Select
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            ...components,
          }}
          classNamePrefix="react-select"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          styles={
            defaultStyles
              ? customAddPermitStyle
              : customTableStyles
          }
        />
      </Container>
    );
  }
  return (
    <Container hasError={error}>
      <Select
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
          ...components,
        }}
        classNamePrefix="react-select"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Container>
  );
};

const Container = styled.div<{ hasError: boolean }>`
  width: 100%;

  .react-select {
    &__container {
      outline: none !important;
    }

    &__control {
      border-radius: 1px;
      box-shadow: none;
      border-color: ${({ hasError }) =>
        hasError
          ? hex2rgba(theme.mainRequired)
          : 'rgba(0, 0, 0, 0.23)'};
      &:hover,
      &:focus {
        border-color: ${({ hasError }) =>
          hasError
            ? hex2rgba(theme.mainRequired, 0.87)
            : 'rgba(0, 0, 0, 0.87)'};
      }
    }

    &__option {
      text-align: left;
      &--is-focused {
        text-align: left;
      }
    }
  }
`;

const WrapperForSelectWithOpenMenu = styled.div<Wrapper>`
  width: 100%;

  div > .react-select__control {
    border-radius: 1px;
    ${({ error }) => error && 'border-color: red;'}
  }

  .react-select__option {
    &--is-selected {
      &::after {
        content: '✓';
        position: absolute;
        right: 2%;
      }
    }
  }
`;

interface Wrapper {
  error?: boolean;
}

export type SelectTypes = Props & {
  selectAllActive?: boolean;
  showCompany?: boolean;
  tableStyles?: boolean;
};

export default SelectInput;
