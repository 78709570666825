import React, { useEffect, useRef, useState } from 'react';
import { RootState } from '../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { getComments, getTickets } from '../../../../../api/tickets';
import ReplyIcon from '@material-ui/icons/Reply';
import { setComments, setSelectedTicket, setTicketList } from '../../../../../redux/slices/ticketsSlice';
import { Header, Wrapper, HeaderCell } from '../Fees/FeesItems';
import DetailsHeaderComponent from '../../Common/DetailsHeaderComponent';
import styled from 'styled-components';
import BottomDrawer from '../../Common/Containers/BottomDrawer';
import { Logo_Sky } from '../../../../../constants/menu';
import { TicketItemType } from '../../../types';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';
import TicketComments from './TicketComments';
import AddTicketBox from './AddTicketBox';
import TicketMobileRow from './TicketMobileRow';

const params = {
  is_deleted: false,
  limit: 25,
  offset: 0,
};
const WAITING_ON_CLIENT = 'Waiting on Client';
const TicketsItems = () => {
  const dispatch = useDispatch();
  const [scrollOn, setScrollOn] = useState<boolean>(false);
  const ticketList = useSelector((s: RootState) => s.tickets.ticketList);
  const selectedFranchise =  useSelector((s: RootState) => s.userAuth.info.selectedFranchise);
  const scrollRef = useRef<HTMLDivElement>();
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  const selectedPermitTicket = useSelector((s: RootState) => s.tickets.selectedPermitTicket);
  useEffect(() => {
    const permit_Id = selectedPermitTicket.permitId;
    const loadTickets = async () => {
      const payload = await getTickets({ ...params, permit: permit_Id, franchise_id:selectedFranchise });

      const data = payload.data.results.map(item => ({
        ...item,
        status: vocabulary.find(v => v.id === Number(item.status)).title,
      }));

      const _sortedData = data.reduce((itemsArr, item) => {
        if (item.status === WAITING_ON_CLIENT) {
          itemsArr.unshift(item);
        } else {
          itemsArr.push(item);
        }
        return itemsArr;
      }, []);
      //@ts-ignore
      dispatch(setTicketList(_sortedData));
    };
    if (permit_Id) {
      loadTickets();
    }
  }, [selectedPermitTicket]);
  useEffect(() => {
    setScrollOn(scrollRef.current.scrollHeight > scrollRef.current.clientHeight);
  }, [ticketList]);
  const onTicketClick = (item: TicketItemType) => {
    const getData = async () => {
      dispatch(setSelectedTicket(item));
      const payload = await getComments({ ...params, system_message: false }, item.id);
      const comments = payload.data.results.map(c => ({
        ...c,
        text: c.text.replace(/\<p\>/g, '').replace(/\<\/p\>/g, ''),
      }));
      //@ts-ignore
      dispatch(setComments(comments));
    };
    getData();
    dispatch(setBottomDrawerOpened(true));
  };
  return (
    <Wrapper>
      <Header>Tickets List</Header>
      <DetailsHeaderComponent names={selectedPermitTicket.namePermitName} address={selectedPermitTicket.address} />
      <AddTicketBox />
      <SingleRow isOdd={true} scrollOn={scrollOn}>
        <HeaderCell>Ticket Number</HeaderCell>
        <HeaderCell>Ticket Name</HeaderCell>
        <HeaderCell>Details</HeaderCell>
        <HeaderCell>Status</HeaderCell>
        <HeaderCell>Respond</HeaderCell>
      </SingleRow>
      <ItemsContainer ref={scrollRef}>
        {ticketList.map((ticket, index) => (
          <>
            <TicketMobileRow ticket={ticket} isOdd={index % 2 > 0} key={index} onTicketClick={onTicketClick} />
            <SingleRow isOdd={index % 2 > 0} key={index}>
              <Cell status={ticket.status}>{ticket.id}</Cell>
              <Cell status={ticket.status}>{ticket.subject}</Cell>
              <Cell status={ticket.status}>{ticket.description}</Cell>
              <Cell status={ticket.status}>{ticket.status}</Cell>
              <Cell>
                <IconWrapper onClick={() => onTicketClick(ticket)}>
                  <ReplyIcon />
                </IconWrapper>
              </Cell>
            </SingleRow>
          </>
        ))}
      </ItemsContainer>
      <BottomDrawer submitLabel="Close" onSubmit={() => dispatch(setBottomDrawerOpened(false))}>
        <TicketComments />
      </BottomDrawer>
    </Wrapper>
  );
};

export default TicketsItems;

export const IconWrapper = styled.div`
  position: relative;
  left: 36px;
  cursor: pointer;
`;

export const SingleRow = styled.div<{ isOdd: boolean; scrollOn?: boolean }>`
  display: grid;
  grid-template-columns: ${({ scrollOn }) => (scrollOn ? '1fr 1fr 1fr 1fr 137px' : '1fr 1fr 1fr 1fr 120px')};
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  color: ${Logo_Sky};
  background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};
  @media only screen and (max-width: 840px) {
    grid-template-columns: repeat(3, 1fr);
    display: none;
  }
  @media only screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
    display: none;
  }
  :hover {
    background-color: #efefef;
  }
`;

export const Cell = styled.div<{ status?: number | string }>`
  font-size: 18px;
  display: flex;
  align-items: center;
  border-right: 1px solid grey;
  padding-left: 15px;
  height: 45px;
  font-weight: ${({ status }) => (status === WAITING_ON_CLIENT ? 'bold' : 'inherit')};
  font-style: ${({ status }) => (status === WAITING_ON_CLIENT ? 'italic' : 'inherit')};
`;

const ItemsContainer = styled.div`
  height: 19vh;
  overflow: auto;
  @media only screen and (max-width: 840px) {
    /* margin-left: -19px; */
  }
`;
