import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import MobileRecord from '../MobileRecord';
import PaginationMobile from '../PaginationMobile';
import { updatePermitActivity } from '../../../api/activities';
import { RootState } from '../../../redux/root';
import { getSearchActivitiesList, getSearchInspectionsList } from '../../../api/search';
import { setModalOpen, setSearchInspections, setSelectedInspection } from '../../../redux/slices/inspectionsSlice';
import { setRowsCount } from '../../../redux/slices/paginationSlice';
import Loader from '../../../atoms/Loader';
import { ModalDefaultDraggable } from '../../../molecules';
import PermitInspectionsForm from '../../../forms/permitInspectionForm/PermitInspectionsForm';
import useTablePage from '../../../hooks/useTablePage';
import DocumentsPage from '../../Permits/DocumentsPage';
import { setPermitId, setPermitInspectionsId, setShowPermitTable } from '../../../redux/slices/globalSearch';
import InspectionEditor from '../../Reports/DailyInspectionReport/InspectionEditor/InspectionEditor';
import PopupInspections from './PopupInspections';
import { InspectionItemType } from '../../../modules/clientModule/types';


const SearchInspections = () => {
    const rows = useSelector((state: RootState) => state.inspections.searchInspections);
    const { pageSize, pageIndex } = useSelector((state:RootState) => state.pagination);
    const dispatch = useDispatch();
    const search = useSelector((state: RootState) => state.filters.searchToken);
    const [record, setRecord] = useState({});
    const [isAddDocument, setAddDocument] = React.useState(false);
    const [isDownloadAllDocs, setDownloadAllDocs] = React.useState(false);
    const [isDocsAvailable, setDocsAvailable] = React.useState(false);
    const [isDocsWaitingOnClient, setDocsWaitingOnClient] = React.useState(false);
    const [isSendDocs, setSendDocs] = React.useState(false);
    const searchButton = useSelector((state: RootState) => state.filters.searchButton);
    const loadData = () => {
        const param = {
            is_deleted: false,
            search,
            limit: pageSize,
            offset: pageIndex,
            activity_type: 7,
        };
        const apiCall =
         searchButton === 'inspections' ? getSearchInspectionsList
         : getSearchActivitiesList;
        apiCall(param).then(response => {
            dispatch(setSearchInspections(response.data.results));
            dispatch(setRowsCount(response.data.count));
        });
    };
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
      loadData();
  }, [pageSize, pageIndex, search]);

  useEffect(() => {
    dispatch(setSearchInspections([]));
    loadData();
}, [pageIndex, searchButton]);

const openPermits = (e,item) => {
  e.preventDefault();
  e.stopPropagation();
  dispatch(setPermitInspectionsId(item.permit));
  dispatch(setShowPermitTable(true));
 }
 
    const dataInspection = [
        { label: "Project Name", key1: "projectPermitName", linkAction: openPermits },
        { label: "Inspection Type", key1: "inspectionType" },
        { label: "Inspection Date", key1: "inspectionDate" },
        { label: "Permit Name", key1: "permitName" },
        { label: "Activity Statue", key1: "statusName" },
        { label: "Inspector", key1: "inspectorEmail" },
    ];

    const dataReviews = [
      { label: "Project Permit", key1: "projectPermitName" },
        { label: "Jurisdiction", key1: "jurisdictionName" },
        { label: "Review Type", key1: "reviewType" },
        { label: "Permit Number", key1: "permitNumber" },
        { label: "Contact Name", key1: "contactFirstName", key2: "contactLastName" },
        { label: "Assign To", key1: "assignedTo" },
    ];
    const data = searchButton === 'inspections' ? dataInspection : dataReviews;
    const {
      isModalOpen,
      modalError,
      errors,
      createOrUpdate,
      handleCloseModal,
      modalSuccess,
      modalTitle,
      handleAdd,
     } = useTablePage({
       updateEndpoint: updatePermitActivity,
       modalUpdateTitle: 'Update',
     });

     const openEditor = (inspection: InspectionItemType) => {
      dispatch(setSelectedInspection(inspection));
      dispatch(setModalOpen(true));
    };

     const onclick = (e, item) => {
      e.preventDefault();
      e.stopPropagation();
      setRecord(item);
      openEditor(item);
      // handleAdd();
    };
    const handleCloseForm = () => {
      if (!modalError && !modalSuccess) {
          handleCloseModal();
      }
    };

    return (
      <>
        <SearchInspectorWrapper>
          <LoaderWrapper>
            {rows.length === 0 && <Loader />}
          </LoaderWrapper>
          <PopupInspections />
          <InspectionEditor/>
          <ReviewPageContainer>
            {rows.map((record, index) => {
            return (
              <div onClick={(e) => onclick(e,record)}>
                <MobileRecord item={record} fields={data} shaded={index % 2 === 0} />
              </div>
            );
          })}
          </ReviewPageContainer>
          <PaginationWrapper>
            <PaginationMobile />
          </PaginationWrapper>
        </SearchInspectorWrapper>
        {isModalOpen && searchButton !== 'permitActivity' && (
          <ModalDefaultDraggable
            title={modalTitle}
            onClose={handleCloseModal}
          >
            <PermitInspectionsForm
              onSubmit={createOrUpdate}
              initialValues={record}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={record.permit}
              isSimulateDeleted={true}
            />
          </ModalDefaultDraggable>
      )}
        {isModalOpen && searchButton === 'permitActivity' && (
        <ModalDefaultDraggable
          title="Permit Documents"
          onClose={handleCloseForm}
          extraWide
        >
          <DocumentsPage
            permit={record.permit}
            work={null}
            building={null}
            isAddDocument={false}
            setAddDocument={setAddDocument}
            isDownloadAllDocs={false}
            setDocsAvailable={setDocsAvailable}
            setDownloadAllDocs={setDownloadAllDocs}
            setDocsWaitingOnClient={setDocsWaitingOnClient}
            isSendDocs={false}
            setSendDocs={setSendDocs}
            isInspector={true}
            isViewMode={true}
          />
        </ModalDefaultDraggable>
      )}
      </>
    );
};

export default SearchInspections;

const SearchInspectorWrapper = styled.div`
  @media only screen and (min-width: 840px) {
    display: none;
  }
`;

const ReviewPageContainer = styled.div`
    overflow-y: auto;
    @media only screen and (max-width: 840px) {
        height: 60vh;
    }
    @media only screen and (max-width: 390px) {
        height: 52vh;
    }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const PaginationWrapper = styled.div`
 width: 90vw;
`;
