import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { makeStyles } from '@material-ui/core';
import { Collapse } from 'react-collapse';
import UsersForm from './UsersForm';
import useTablePage from '../hooks/useTablePage';
import { updateProject } from '../api/projects';
import ModalInfo from '../molecules/ModalInfo';
import Table from '../organisms/Table';
import { DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS } from '../constants';

import {
  InputTextWithLabel,
  InputBaseContainer,
  TextAreaWithLabel,
  InputSelectWithLabel,
  InputCurrencyWithLabel,
  ButtonWithLoader,
  LoaderWithContainer,
  ModalDefaultDraggable,
  InputDatePickerWithLabel,
  CheckboxWithLabel,
} from '../molecules';
import { getContactTypeList } from '../api/contactType';

import { VocabularyDataType } from '../api/vocabulary';
import { PermitType, getPermitActivitiesInspectionStatus } from '../api/permits';
import { ContactTypesType } from '../api/contactType';
import { UserType, getPermitUsers, getUsersCustom, createUser, getUserByID } from '../api/users';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import { ButtonMain } from '../atoms';
import { getProjectByID } from '../api/projects';
import { USER_TYPES_VALUES } from '../constants/forms';
import ActionsPanel from '../organisms/ActionsPanel';
import formatDate from '../utils/formatDate';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/root';
import { setIsViewContactsModal, setPermitContacts } from '../redux/slices/projectSlice';
import { setPermitUsers } from '../redux/slices/inspectionsSlice';
import { getAccountingInfo } from '../utils';
const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const labels = {
  projectName: 'Project Name',
  name: 'Permit Name',
  number: 'Permit Number',
  total: 'Contract Total',
  square: 'Square Feet',
  inspections: 'Count of Inspections',
  storageBox: 'Storage Box Number',
  jurisdiction: 'Jurisdiction',
  documents: 'Permit Supporting Documents',
  address: 'Address',
  description: 'Description',
  info: 'Permit Info',
  status: 'Permit Status',
  permitContacts: 'Contacts',
  serviceType: 'Type of Service',
  manager: 'Project Manager',
  work: 'Work',
  building: 'Building',
  expirationDate: 'Expiration Date',
  permitIssued: "Permit Issued",
};

const validationSchema = yup.object().shape({
  projectName: yup.string().label(labels.projectName),
  name: yup.string().required().label(labels.name),
  company:yup.object(),
  number: yup.string().label(labels.number),
  total: yup.string().label(labels.total).nullable(),
  square: yup
    .number()
    .typeError('This field must include only digits')
    .label(labels.square),
  inspections: yup
    .number()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError('Number Inspections must be a number')
    .nullable()
    .label(labels.inspections),
    // Making storage box number not required field
    storageBox: yup
    .number()
         .typeError("This field must include only digits")
         .nullable()
         .transform((_, val) => (val !== "" && val ? Number(val) : null))
    .label(labels.storageBox),
  jurisdiction: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .typeError('Select a Jurisdictions from the list')
    .nullable()
    .label(labels.jurisdiction),
  documents: yup.string().label(labels.documents),
  address: yup.string().required().label(labels.address),
  description: yup
    .string()
    .required()
    .label(labels.description),
  info: yup.string().label(labels.info),
  status: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .required()
    .typeError('Select a permit status')
    .label(labels.work),
  permitContacts: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a Contact Names from the list')
    .label(labels.permitContacts),
  serviceType: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a service type'),
  manager: yup.string().label(labels.manager),
  permitIssued: yup.boolean(),
  work: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .required()
    .typeError('Select a work from the list')
    .nullable()
    .label(labels.work),
  building: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .required()
    .typeError('Select a building from the list')
    .label(labels.building),
});

const PermitForm = ({
  initialValues,
  // selectedPermit,
  permitStatus,
  allContacts,
  contactTypes,
  serviceTypes,
  workTypes,
  buildingTypes,
  setFormSaved,
  setIsContactsModalOpen,
  onSubmit,
  // projectId,
  cameFromSearch
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    formState,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    // shouldUnregister: false,
  });
  const {permitId,projectId} = useParams();
  const dispatch = useDispatch();
  const selectedPermit = useSelector((s:RootState) => s.projectsSlice.selectedPermit);
  const permitContactsRedux = useSelector((s:RootState) => s.projectsSlice.permitContacts);
  const {
    createOrUpdate,
    modalSuccess,
    modalError,
    isApiSuccess,
    successResponse,
    handleCloseModalInfo,
    modalErrorTitle,
    handleAdd,
    isModalOpen,
    modalTitle,
    handleCloseModal,
    values,
    innerModalErrors,
    setSearch,
    search,
    // tableManuallyUpdate,
  } = useTablePage({
    createEndpoint: createUser,
    modalCreateTitle: 'Add Contact',
    updateEndpoint: updateProject,
  });
  // const [users, setUsers] = React.useState(null)
  const [selectedContact, setSelectedContact] = React.useState(null)
  const [initialContact,setInitialContact] = React.useState(getValues("permitContacts"))
  const [isAddContactBtnLoading, setIsAddContactBtnLoading] = React.useState(false)

  const [isShowMore, setShowMore] = React.useState(false);
  const handleContactSelect = (newContacts,ids,data) => {    
    setSelectedContact(newContacts)
    const contacts=getValues("permitContacts")||[]
    let tempContact;
    if(data){
      if(contacts.findIndex(contact => contact?.id === data?.id) === -1){
        tempContact=[...contacts]
        tempContact.push(data)
        setValue("permitContacts",tempContact)
            }else{
              if(newContacts.findIndex(contact => contact?.id === data?.id) === -1){
                // tempContact=contacts.filter(contact => contact?.id! === data?.id)
                tempContact=[...newContacts]
                setValue("permitContacts",tempContact)
              }
            }
    }
  }
  const handleAddContact = () => {
    setIsAddContactBtnLoading(true)
    const _data=getValues("permitContacts")||[]

    const uniqData = _data.filter((v,i,a)=> v !== undefined && a.findIndex(v2 => (v2?.id === v?.id)) === i)
    const contactsData = uniqData.map(contact => contact?.id)

    updateProject({id: projectId, contacts: contactsData}).then(res => {
        setIsAddContactBtnLoading(false)
        setAddUserModal(false)
        console.log('uniqData',uniqData)
        setValue("permitContacts",uniqData,{shouldDirty:true})
        setInitialContact(getValues("permitContacts"))
    }
    )
  }
 const [initialSelectedRows,setInitialSelectedRows] =React.useState({})
 const userColumns = column => {
  if (
    [
      'id',
     'firstName',
     'lastName',
     'phoneNumber',
     'address',
     'city',
     'state',
     'clientType',
     'phoneNumber',
     'parentCompany',
     'street',
     'mobilePhoneNumber',
     'userRole',
     'zipCode',
     'isEpsuperadmin',
     'isInvitationSent',
     'licenses',
     'prNumber',
     'isActive',
     'isDeleted',
     'trade',
     'tradesNames',
     'franchise',
     'franchiseNames',
     'avatar',
     'createdAt',
     'defaultFranchiseId',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'companyName':
      return 'Company Name';
    case 'email':
      return 'Email';
    case 'name':
      return 'Name';
    default:
      return tableColumnsFormatter(column);
  }
};

const userColumnOrder = [
  'name',
  'email',
  'companyName',
];
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isContactFormSubmitting,
    setIsContactFormSubmitting,
  ] = React.useState(false);

  const [addUserModal, setAddUserModal] = React.useState(false)
  const [allUserData, setAllUserData] = React.useState(null)
  const [addNewUserModalOpen, setAddNewUserModalOpen] = React.useState(false)
  const [isPermitCompleted, setIsPermitCompleted] = React.useState(true)

  React.useEffect(()=>{
    if(isApiSuccess){
      setAddNewUserModalOpen(false)
    }
      },[isApiSuccess])
      React.useEffect(()=>{
        setInitialContact(getValues("permitContacts"))
        Promise.all([getPermitActivitiesInspectionStatus(permitId)]).then(([res]) => setIsPermitCompleted(res?.data?.data))
        },[])
  const modifiedGetUsers = (params) =>
  Promise.all([
    getUsersCustom({
      // limit: 25,
      ...GET_ALL_QUERY_PARAMS,
      limit: 25,
      userRole: USER_TYPES_VALUES.CLIENT,
      search:search || null,
      ...params
    }),
    getContactTypeList({
      ...GET_ALL_QUERY_PARAMS,
    }),
  ]).then(
    ([
      userRes,
     contactTypesRes
    ]) => {
      const contactsId= userRes?.data?.map(item=>item.id)||[]
      const permitContacts=getValues("permitContacts")||[]
      const contactsData = permitContacts?.map(contact => contact?.id)||[]
      const tempInitial = contactsId.reduce((acc,val,i)=>{
        if(contactsData.length && contactsData.includes(val)){
          //  acc = {...acc,i:true}
          acc[i]=true
        }
        return acc
      },{})
      setInitialSelectedRows(tempInitial)
      return {
        ...userRes,
        data: {
          results: userRes.data.map(
            item => {
              return {
                ...item,
                name: `${item?.firstName} ${item?.lastName}`,
                email: item?.email,
                companyName: item?.companyName || item?.parentCompany?.companyName || "",
              };
            },
          ),
        },
      };
    },
  );
  React.useEffect(() => {
    if (projectId) {
      Promise.all([getProjectByID(projectId)])
        .then(([projectResponse]) => {
          setValue(
            'projectName',
            projectResponse?.data?.name,
          );
        })
        .catch(err => console.log(err))
        .finally(() => {});
    }
  }, [projectId, errors]);

  React.useEffect(() => {
    if(Object.keys(selectedPermit).length === 0){
      return;
    }
    if (!initialValues?.storageBox) {
      setValue('storageBox', '0');
    }
    if (initialValues?.building) {
      // @ts-ignore
      setValue('building', initialValues.building);
    }
    if (initialValues?.work) {
      // @ts-ignore
      setValue('work', initialValues.work);
    }
    if (initialValues?.status) {
      setValue('status', initialValues.status);
    }
    if (initialValues?.serviceType) {
      setValue('serviceType', initialValues.serviceType);
    }
    if (initialValues?.expirationDate) {      
      const date = Date.parse(
        initialValues.expirationDate,
      );
      setValue('expirationDate', new Date(date));
    }
    
    Promise.all([getPermitUsers({user_role: 3,
      permit_id: selectedPermit?.id || permitId}), selectedPermit?.contractor && getUserByID(selectedPermit?.contractor)]).then(([permitUsers, contractor]) => {
        setValue('permitContacts', [...permitUsers?.data, contractor?.data])
        dispatch(setPermitUsers(permitUsers?.data))
      })
  }, [initialValues, selectedPermit]);

React.useEffect(() => {
  if(Object.keys(errors).length > 0) {
    Promise.all([getPermitUsers({user_role: 3,
      permit_id: selectedPermit?.id}), selectedPermit?.contractor && getUserByID(selectedPermit?.contractor)]).then(([permitUsers, contractor]) => {
        setValue('permitContacts', [...permitUsers?.data, contractor?.data])
        dispatch(setPermitUsers(permitUsers?.data))
      })
  }
}, [errors])

  React.useEffect(() => {
    if (
      formState.isSubmitted &&
      formState.isSubmitSuccessful
    ) {
      setFormSaved(true);
    }
  }, [formState.isSubmitSuccessful, formState.isSubmitted]);

  const classes = useStyles();

  const onCurrencyChange = (value, name) => {
    setValue(name, value, { shouldDirty: true });
  };

  const formatContacts = contacts => {
    if (contacts) {
      const res = contacts
        ?.map(contact => ({
          user: +contact.id,
          contact_type: contact.contactType
            ?contactTypes.find(item=>item?.name == contact?.contactType)?.id
            : contactTypes[0]?.id,
          isAccounting: contact.isAccounting ? contact.isAccounting : false,
        }))
        .filter(el => !!el.user);
        return res;
    }
    return [];
  };
  const middleware = (submitFn: (data) => void) => data => { 
    const contacts = getAccountingInfo(data.permitContacts,permitContactsRedux);
    return submitFn({
      id: selectedPermit?.id || permitId,
      name: data.name,
      number: data.number,
      amount: data?.total ? data?.total || 0 : 0,
      squareFeet: data.square,
      inspectionsCount: data.inspections
        ? data.inspections
        : 0,
      storageBox: data.storageBox,
      address:data.address || data.street+','+ data.city+','+ data.state+','+ data.zipCode,
      description: data.description,
      info: data.info || '',
      status: +data.status.value || data.status.id,
      contacts: formatContacts(contacts),
      serviceType: data.serviceType
        .map(el => el.id)
        .filter(el => el !== undefined),
      work: data.work.value
        ? +data.work.value
        : data.work.id,
      building: data.building?.value || data.building?.id,
      expirationDate: data.expirationDate
      ? `${formatDate(
          data.expirationDate,
        )}`
      : null,
      permitIssued: data?.permitIssued
    });
  };

  const toggleCollapse = () => {
    setShowMore(!isShowMore);
    setInitialContact(getValues("permitContacts"))
  };

  if (formState.isSubmitting) {
    return <LoaderWithContainer />;
  }
  return (
    <Container>
      <h2>Permit Details</h2>
      {initialValues && (
        <FormContainer
          autoComplete="off"
          aria-autocomplete="none"
        >
          <InputBaseContainer>
            <Controller
              as={InputTextWithLabel}
              InputProps={{
                readOnly: true,
              }}
              name="projectName"
              defaultValue=""
              control={control}
              error={errors.projectName}
              label={labels.projectName}
              isRequired={isYupFieldRequired(
                'projectName',
                validationSchema,
              )}
            />
            <Controller
              as={InputTextWithLabel}
              InputProps={{
                readOnly: cameFromSearch,
              }}
              name="name"
              defaultValue=""
              control={control}
              error={errors.name}
              label={labels.name}
              isRequired={isYupFieldRequired(
                'name',
                validationSchema,
              )}
            />
            <InputTextWithLabel
              name="number"
              error={errors.number}
              inputRef={register}
              label={labels.number}
              // EA-221
              onChange={(event)=>{
                setValue("number",event.target.value.slice(0,200))}}
              isRequired={isYupFieldRequired(
                'number',
                validationSchema,
              )}
              disabled={cameFromSearch}
            />
            <Controller
              as={InputTextWithLabel}
              name="address"
              defaultValue=""
              InputProps={{ readOnly: true }}
              control={control}
              error={errors.address}
              label={labels.address}
              isRequired={isYupFieldRequired(
                'address',
                validationSchema,
              )}
            />
          </InputBaseContainer>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ButtonMain
              style={{ marginTop: 10, width: 140 }}
              variant="outlined"
              onClick={toggleCollapse}
            >
              {isShowMore ? 'Hide' : 'Show More'}
            </ButtonMain>
          </div>

          <Collapse
            isOpened={isShowMore}
            initialStyle={{
              height: '0px',
              overflow: 'hidden',
              transition: '0.4s',
            }}
          >
            <div style={{ height: 'auto' }}>
              <InputBaseContainer>
                <Controller
                  as={TextAreaWithLabel}
                  name="description"
                  defaultValue=""
                  control={control}
                  error={errors.description}
                  label={labels.description}
                  isRequired={isYupFieldRequired(
                    'description',
                    validationSchema,
                  )}
                  disabled={cameFromSearch}
                />
                <Controller
                  name="permitContacts"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <ContactsWrapper>
                      <InputSelectWithLabel
                      // onFocus={() => getAllUsers()}
                        isMulti
                        placeholder=""
                        showCompany
                        menuPlacement="top"
                        label={labels.permitContacts}
                        error={errors.permitContacts}
                        options={allUserData ? allUserData: []}
                        getOptionValue={(item: any) =>
                          item.id
                        }
                        getOptionLabel={(item: any) => {
                          // eslint-disable-next-line no-nested-ternary
                          
                          return item.name
                            ? item.name
                            : `${item.firstName} ${
                                item.lastName
                              }${
                                item.companyName ||
                                item.parentCompany
                                  ? ` - ${
                                      item.companyName ||
                                      item.parentCompany
                                    }`
                                  : ''
                              }`
                            }
                        }
                        isRequired={isYupFieldRequired(
                          'permitContacts',
                          validationSchema,
                        )}
                       isDisabled={cameFromSearch}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                      />
                    </ContactsWrapper>
                  )}
                />
                <ButtonsWrapper>
                  <ButtonContainer>
                    <StyledButtonMain
                      variant="outlined"
                      onClick={() =>
                        setAddUserModal(true)
                      }
                      disabled={cameFromSearch}
                    >
                      Add Contact
                    </StyledButtonMain>
                  </ButtonContainer>
                  <ButtonContainer>
                    <StyledButtonMain
                      variant="outlined"
                      onClick={() =>
                        dispatch(setIsViewContactsModal(true))
                      }
                    >
                      View Contacts
                    </StyledButtonMain>
                  </ButtonContainer>
                </ButtonsWrapper>
              </InputBaseContainer>
              <InputBaseContainer>
                <Controller
                  as={InputTextWithLabel}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="jurisdiction"
                  defaultValue=""
                  control={control}
                  error={errors.jurisdiction}
                  label={labels.jurisdiction}
                  isRequired={isYupFieldRequired(
                    'jurisdiction',
                    validationSchema,
                  )}
                />
                <Controller
                  as={InputTextWithLabel}
                  InputProps={{
                    readOnly: true,
                  }}
                  name="manager"
                  defaultValue=""
                  control={control}
                  error={errors.manager}
                  label={labels.manager}
                  isRequired={isYupFieldRequired(
                    'manager',
                    validationSchema,
                  )}
                />
              </InputBaseContainer>
              <InputBaseContainer>
                <Controller
                  name="status"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <InputSelectWithLabel
                      placeholder=""
                      menuPlacement="top"
                      label={labels.status}
                      error={errors.status}
                      isOptionDisabled={option => isPermitCompleted && (option.title === 'Completed' ||
                            option.title === 'Withdrawn')
                    }
                      options={permitStatus as any}
                      getOptionValue={(item: any) =>
                        item.value ? item.value : item.id
                      }
                      getOptionLabel={(item: any) =>
                        item.label ? item.label : item.title
                      }
                      isRequired={isYupFieldRequired(
                        'status',
                        validationSchema,
                      )}
                      isDisabled={cameFromSearch}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                />
                <Controller
                  name="serviceType"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <InputSelectWithLabel
                      isMulti
                      selectAllActive
                      placeholder=""
                      menuPlacement="top"
                      label={labels.serviceType}
                      error={errors.serviceType}
                      options={serviceTypes as any}
                      getOptionValue={(item: any) =>
                        item.id
                      }
                      getOptionLabel={(item: any) =>
                        item.name ? item.name : item.title
                      }
                      isRequired={isYupFieldRequired(
                        'serviceType',
                        validationSchema,
                      )}
                      isDisabled={cameFromSearch}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                />
              </InputBaseContainer>
              <InputBaseContainer>
                <Controller
                  name="work"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <InputSelectWithLabel
                      placeholder=""
                      menuPlacement="top"
                      label={labels.work}
                      error={errors.work}
                      getOptionValue={(item: any) =>
                        item.value ? item.value : item.id
                      }
                      getOptionLabel={(item: any) =>
                        item.label ? item.label : item.title
                      }
                      options={workTypes as any}
                      isRequired={isYupFieldRequired(
                        'work',
                        validationSchema,
                      )}
                      isDisabled={cameFromSearch}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                />
                <Controller
                  name="building"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <InputSelectWithLabel
                      placeholder=""
                      menuPlacement="top"
                      label={labels.building}
                      error={errors.building}
                      options={buildingTypes as any}
                      getOptionValue={(item: any) =>
                        item.value ? item.value : item.id
                      }
                      getOptionLabel={(item: any) =>
                        item.label ? item.label : item.title
                      }
                      isRequired={isYupFieldRequired(
                        'building',
                        validationSchema,
                      )}
                      isDisabled={cameFromSearch}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                />
                <Controller
                  name="total"
                  defaultValue=""
                  control={control}
                  render={props => (
                    <InputCurrencyWithLabel
                      allowDecimals={false}
                      error={errors.total}
                      label={labels.total}
                      onCurrencyChange={onCurrencyChange}
                      isRequired={isYupFieldRequired(
                        'total',
                        validationSchema,
                      )}
                      disabled={cameFromSearch}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                    />
                  )}
                />
              </InputBaseContainer>
              <InputBaseContainer>
                <InputTextWithLabel
                  InputProps={{
                    readOnly: cameFromSearch,
                  }}
                  name="inspections"
                  error={errors.inspections}
                  inputRef={register}
                  label={labels.inspections}
                  isRequired={isYupFieldRequired(
                    'inspections',
                    validationSchema,
                  )}
                />
                <InputTextWithLabel
                  name="square"
                  error={errors.square}
                  inputRef={register}
                  label={labels.square}
                  isRequired={isYupFieldRequired(
                    'square',
                    validationSchema,
                  )}
                  disabled={cameFromSearch}
                />
                <InputTextWithLabel
                  name="storageBox"
                  error={errors.storageBox}
                  inputRef={register}
                  label={labels.storageBox}
                  isRequired={isYupFieldRequired(
                    'storageBox',
                    validationSchema,
                  )}
                  disabled={cameFromSearch}
                />
                <Controller
                  as={TextAreaWithLabel}
                  name="info"
                  defaultValue=""
                  control={control}
                  error={errors.info}
                  label={labels.info}
                  isRequired={isYupFieldRequired(
                    'info',
                    validationSchema,
                  )}
                  disabled={cameFromSearch}
                />
              </InputBaseContainer>
              <div style={{width: "48%", display: "flex"}}>
                  <Controller
                    name="expirationDate"
                    defaultValue=""
                    valueName="selected"
                    onChange={([selected]) => selected}
                    control={control}
                    menuPlacement="top"
                    render={props => (
                      <InputDatePickerWithLabel
                        label={labels.expirationDate}
                        error={errors.expirationDate}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        // disabled={isReadOnly || initialValues?.isCompleted}
                        isRequired={isYupFieldRequired(
                          'expirationDate',
                          validationSchema,
                        )}
                        // eslint-disable-next-line react/prop-types
                        selected={props.value}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                      />
                    )}
                  />
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',
                 width: "400px", marginLeft: "10px", marginTop: "25px"}}>
                  <Controller
                    name="permitIssued"
                    control={control}
                    valueName="checked"
                    defaultValue={
                      selectedPermit?.permitIssued ?? false
                    }
                    render={({ onChange, onBlur, value }) => (
                      <CheckboxWithLabel
                        error={errors.permitIssued}
                        label={labels.permitIssued}
                        checked={value}
                        onBlur={onBlur}
                        onChange={(e, flag) => onChange(flag)}
                        disabled={cameFromSearch}
                        />
                    )}
                  />
              </div>
              </div>
              <ButtonWithLoader
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(middleware(onSubmit))}
                className={classes.button}
                disabled={
                  !formState.isDirty ||
                  !!Object.keys(errors).length || cameFromSearch
                }
                loading={
                  formState.isSubmitting &&
                  !Object.keys(errors).length
                }
              >
                Save
              </ButtonWithLoader>
            </div>
          </Collapse>
        </FormContainer>
      )}
       {addUserModal && (
        <StyledModalDraggable
          onClose={() => {
            if(addNewUserModalOpen){
              setAddNewUserModalOpen(false)
            }else{
              setAddUserModal(false)
              setValue("permitContacts",initialContact)
            }
            }}
          title="Add Users"
        >
          <div
          style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
           {!addNewUserModalOpen && <>
          <ActionsPanel
          isBreadCrumbsVisible={false}
          onSearch={setSearch}
          isActionPanelMoreVisible={false}
          className='action-container'
        />
      </>}
          {!addNewUserModalOpen && <>
            <ButtonWithLoader style={{float:"right"}}
                  variant="contained"
                  color="primary"

                  onClick={() => setAddNewUserModalOpen(true)}

                  loading={
                    false
                  }
                >
                  New
                </ButtonWithLoader>
                <br />
          </>
          }
          </div>
          {addNewUserModalOpen && <UsersForm
            setIsContactFormSubmitting={
              setIsContactFormSubmitting
            }
            initialValues={values}
            searchForm
            modalError={modalErrorTitle.length > 0}
            initialErrors={innerModalErrors}
            onSubmit={createOrUpdate}

          />}
           {(isApiSuccess || modalError) && (
        <ModalInfo
          title="Success"
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}

        {/* <InfiniteScroll
      dataLength={newData.length}
      next={fetchMoreData}
      height={800}
      hasMore={true}
      loader={<h4>Loading...</h4>}
    > */}
          {!addNewUserModalOpen && <><Table
            // initialUseSortBy
            customSearch
            initialSelectedRows = {initialSelectedRows}
            searchQuery={search}
            rows={modifiedGetUsers}
            columns={userColumns}
            isActivityTable
            addContactView={true}
            columnsOrder={userColumnOrder}
            // colorSchema
            // activityType="inspections"
            // additionalRequestParams={{
            //   eventType: "Inspection",
            // }}
            onSelect={(el,ids,data) => handleContactSelect(el,ids,data)}
            onConfigure={null}
          onEdit={null}
          onDelete={null}
          additionalActions={null}
            // manuallyUpdate={tableManuallyUpdate}
        />
        <br />
        <ButtonWithLoader
                variant="outlined"
                // color="primary"
                onClick={() => setAddUserModal(false)}
                loading={
                  false
                }
              >
                Cancel
              </ButtonWithLoader>{" "}
        <ButtonWithLoader
                variant="contained"
                color="primary"
                // type="submit"
                // style={{marginLeft: ''}}
                onClick={() => handleAddContact()}
                
                loading={
                  false
                }
              >
                Ok
              </ButtonWithLoader> </>}
        {/* </InfiniteScroll> */}
        </StyledModalDraggable>
      )}
    </Container>
  );
};

interface Props {
  initialValues: InitialValues;
  selectedPermit: PermitType;
  permitStatus: VocabularyDataType;
  allContacts: UserType;
  serviceTypes: VocabularyDataType;
  workTypes: VocabularyDataType;
  buildingTypes: VocabularyDataType;
  setFormSaved: (data: boolean) => void;
  isLoading: boolean;
  contactTypes: Array<ContactTypesType>;
  setIsContactsModalOpen: (data: boolean) => void;
  onSubmit: (data: VocabularyDataType) => void;
  projectId?: number;
}

interface InitialValues {
  name: string;
  number?: string;
  total?: string;
  square?: string;
  inspections: number;
  storageBox?: string;
  jurisdiction: string;
  documents?: any;
  address: string;
  description: string;
  info?: string;
  status?: any;
  permitContacts: { user: number; contactType: number }[];
  serviceType?: Array<VocabularyDataType>;
  manager: string;
  work?: Array<VocabularyDataType>;
  building?: Array<VocabularyDataType>;
}

const Container = styled.fieldset`
  display: block;
  position: relative;
  margin: 0;
  padding: 15px 20px 30px 20px;
  width: 100%;
  border: none;
  text-align: center;
  // min-height: 10vh;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  width: min-content;
  margin-left: 10px;
  margin-top: 4px;
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  overflow-y: auto; 
  height:100%;
  text-align: center;
`;

const StyledButtonMain = styled(ButtonMain)`
  &.MuiButton-root {
    line-height: 1.15;
  }
`;

const ContactsWrapper = styled.div`
  max-width: 500px;
`;

export default PermitForm;
