import * as React from 'react';
import styled from 'styled-components';

import { Loader } from '../atoms';

const LoaderWithContainer = ({
  className,
}: LoaderProps) => {
  return (
    <LoaderContainer className={className}>
      <Loader />
    </LoaderContainer>
  );
};

interface LoaderProps {
  className?: string;
}

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--defaultBackground);
  //z-index: var(--popperZIndex);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoaderWithContainer;
