import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { userInfoSelector } from '../redux/ducks/userAuth';
import api from '../api/userSettings';
import { logOut } from '../auth';

import { SuccessModal } from '../molecules';
import DashboardLayout from '../layouts/DashboardLayout';
import ChangeEmailForm from '../forms/ChangeEmailForm';
import VerifyPasswordForm from '../forms/VerifyPasswordForm';

const ChangeEmailPage = () => {
  const userInfo = useSelector(userInfoSelector);

  const newEmail = React.useRef(null);
  const [
    verifyPassForm,
    setVerifyPassForm,
  ] = React.useState<boolean>(false);
  const [
    emailChangeSuccess,
    setEmailChangeSuccess,
  ] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState(null);

  const [currentEmail] = React.useState<string>(() => {
    const { email } = userInfo;
    return email;
  });

  const handleModalClose = () => logOut();

  const handleEmailChange = formData => {
    return api
      .setNewUserEmail(formData)
      .then(() => {
        newEmail.current = formData;
        setVerifyPassForm(true);
      })
      .catch(error => {
        const { data, status } = error.response;
        if (status >= 500) {
          return;
        }
        setErrors(data);
      });
  };

  const handleCodeCheck = verifyCode => {
    return api
      .changeUserEmail({
        ...verifyCode,
        ...newEmail.current,
      })
      .then(() => {
        setEmailChangeSuccess(true);
      })
      .catch(error => {
        const { data, status } = error.response;
        if (status >= 500) {
          return;
        }
        setErrors(data);
      });
  };

  return (
    <DashboardLayout>
      <PageTitle>Change Email</PageTitle>
      {verifyPassForm ? (
        <VerifyPasswordForm
          onSubmit={handleCodeCheck}
          initialErrors={errors}
        />
      ) : (
        <ChangeEmailForm
          onSubmit={handleEmailChange}
          initialErrors={errors}
          currentEmail={currentEmail}
        />
      )}
      {emailChangeSuccess && (
        <SuccessModal
          title="Changes have been saved, you need to login"
          onClose={handleModalClose}
        />
      )}
    </DashboardLayout>
  );
};

const PageTitle = styled.span`
  color: var(--secondaryText);
`;

export default ChangeEmailPage;
