import React, { FC, useEffect, useRef, useState } from 'react';
import { TableColumnsData } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import styled from 'styled-components';
import TableHeaderCell from './Header/TableHeaderCell';
import TableCell from './TableCell';
import DetailsIcon from './DetailsIcon';
import _ from 'lodash';
import { generateComparator } from '../../../utils';
import NoRecordsFound from './NoRecordsFound';
import MobileFilter from '../../../../../pages/Responsive/MobileFilter';
import { setShowDrawerContent } from '../../../../../redux/slices/generalSlice';
import TableRow from './TableRow';

const Table: FC<TableColumnsData> = ({
  collection,
  headers,
  gridTemplateCols,
  setSelectedItemInSlice,
  filterMeta
}) => {
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>();
  const [sortedCollection, setSortedCollection] = useState(
    collection,
  );
  const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);
  const {
    tableSortingDirection,
    tableSortingKey,
  } = useSelector((state: RootState) => state.general);

  useEffect(() => {
    if(scrollRef.current){
      setIsScrollbarVisible(scrollRef.current.scrollHeight > scrollRef.current.clientHeight);     
    }
  },[sortedCollection])
  useEffect(() => {
    if (tableSortingDirection === 'NONE') {
      setSortedCollection(collection);
    }
  }, [tableSortingKey, collection, tableSortingDirection]);

  useEffect(() => {
    let _sortedItem = [...collection];
    _sortedItem.sort(
      generateComparator(
        tableSortingDirection,
        tableSortingKey,
      ),
    );
    if (tableSortingDirection !== 'NONE') {
      setSortedCollection(_sortedItem);
    }
  }, [tableSortingDirection, collection]);

  // See if better to pass function from outside
  const handleDetailClick = (item: any) => {
    if (item.status === 'Sent') {
      return;
    }
    if (setSelectedItemInSlice) {
      setSelectedItemInSlice(item);
    }
    dispatch(setShowDrawerContent(true));
  };

  return (
    <TableRoot>
      <TableHeaderRow gridTemplateCols={gridTemplateCols} isScrollVisible={isScrollbarVisible}>                                 
        {headers.map(({ label, sortingKey, hideFilter,itemName }, index) => (
          <TableHeaderCell
            key={index}
            label={label}
            itemName={itemName}
            sortingKey={sortingKey}
            hideFilter={hideFilter}
          />
        ))}
      </TableHeaderRow>
      <TableContentContainer ref={scrollRef} id='mainTable'>
        {sortedCollection.length === 0 && (
          <NoRecordsFound></NoRecordsFound>
        )}
        <MobileFilter filterMeta={filterMeta} />
        {sortedCollection.map((collectionItem, index) => (
          <TableRow key={index} index={index} gridTemplateCols={gridTemplateCols} status={collectionItem.status}>
            {headers.map(
              ({ label, sortingKey, theKey }, index) => {
                if (index === headers.length - 1) {
                  return (
                    <TableCell header={label} key={index}>
                      <div
                        onClick={e =>
                          handleDetailClick(collectionItem)
                        }
                      >
                        <DetailsIcon
                          allowVisibility={
                            collectionItem.status !== 'Sent'
                          }
                        />
                      </div>
                    </TableCell>
                  );
                }
                const _theKey = sortingKey || theKey;
                return (
                  <TableCell
                    key={index}
                    header={label}
                    value={_.get(collectionItem, _theKey)}
                  />
                );
              },
            )}
          </TableRow>
        ))}
      </TableContentContainer>
    </TableRoot>
  );
};

export default Table;

const TableRoot = styled.div`
  width: 99%;
  display: flex;
  height: 92%;
  flex-direction: column;
  border: 1px solid #aaa;
  margin: 10px;
  border-radius: 5px;
  @media only screen and (max-width: 840px) {
    margin-left: 5px;
  }
  @media only screen and (max-width: 540px) {
    margin-left: 0;
  }
`;

const TableContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: 80vh;
`;

const TableHeaderRow = styled.div<{
  gridTemplateCols: string;
  isScrollVisible: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ gridTemplateCols }) =>
    gridTemplateCols};
  border-bottom: 1px solid #ccc;
  font-size: 18px;
  font-weight: bold;
  padding-right: ${({isScrollVisible})=> isScrollVisible ? '16px': '0px'};
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

