import * as React from 'react';
import styled from 'styled-components';
import LoaderWithContainer from '../molecules/LoaderWithContainer';

const InspectionsInfoBlock = ({ info }: Props) => {
  if (!info?.length) {
    return (
      <InfoWrapper>
        <LoaderWithContainer />
      </InfoWrapper>
    );
  }
  return (
    <InfoWrapper>
      {info?.map(infoLine => {
        return (
          <Line>
            <Label>{`${infoLine?.label}: `}</Label>
            <span>{infoLine?.value}</span>
          </Line>
        );
      })}
    </InfoWrapper>
  );
};

interface Props {
  info: Array<Info>;
}

interface Info {
  label: string;
  value: string;
}

const InfoWrapper = styled.div`
  text-align: left;
  min-height: 165px;
  position: relative;
`;

const Line = styled.p`
  font-size: 1.2em;
`;

const Label = styled.span`
  font-weight: bold;
`;

export default InspectionsInfoBlock;
