import React, { FC } from 'react';
import styled from 'styled-components';

const AgingReportTableHeader:FC<{lineCounter:any}> = ({lineCounter}) => {
    lineCounter.current = lineCounter.current + 1;
  return (
    <TableHeader>
      <div className="">Date</div>
      <div className="">Invoice Number</div>
      <div className="">Contact Name</div>
      <div className="">Project Name-Permit Name</div>
      <div className="">Amount</div>
    </TableHeader>
  );
};

export default AgingReportTableHeader;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 10% 15% 20% 1fr 15%;
  border-top: 2px solid grey;
  border-bottom: 2px solid grey;
  font-size: 19px;
  margin-top: 50px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  grid-column: 1 / 6;
`;
