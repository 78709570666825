import * as React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core';

import { TextInput, Tooltip } from '../atoms';

const InputText = ({
  isRequired,
  label,
  error,
  ...props
}: Props) => {
  const { id, name } = props;
  const title =
    error?.message ||
    (error?.types ? Object.values(error.types)[0] : null);

  return (
    <Tooltip title={title} placement="top">
      <div>
        <StyledTextInput
          id={id || name}
          required={isRequired}
          error={!!error}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
    </Tooltip>
  );
};

const StyledTextInput = styled(TextInput)`
  input {
    border-radius: 1px;
    padding: 0 16px;
    top: 0;
    height: 40px;
  }

  .PrivateNotchedOutline-root-2 {
    top: 0;
  }
`;

type Props = Omit<TextFieldProps, 'error' | 'variant'> & {
  isRequired?: boolean;
  error?: FieldError;
};

export default InputText;
