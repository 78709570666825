import React, { FC } from 'react';
import styled from 'styled-components';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';

type Props = { item: any; onEdit: Function };
const SingleItemWithDownload: FC<Props> = ({ item, onEdit }) => {
  const hasAttachment = item.attachment !== undefined;
  return (
    <FileItem status={item.status}>
      <FileName title={item.name}>{item.name}</FileName>
      <IconDownload hasAttachment={hasAttachment} onClick={() => console.log(item.pk, item.status)}>
        <IconWrapper hasAttachment={hasAttachment}>{/* <CloudDownloadIcon /> */}</IconWrapper>
        <EditIconWrapper>
          <EditIcon onClick={e => onEdit(e, item)} />
        </EditIconWrapper>
      </IconDownload>
    </FileItem>
  );
};

export default SingleItemWithDownload;

const EditIconWrapper = styled.div`
  cursor: pointer;
`;

const IconWrapper = styled.div<{ hasAttachment: boolean }>`
  color: ${({ hasAttachment }) => (hasAttachment ? '#000' : '#aaa')};
`;

const IconDownload = styled.div<{ hasAttachment: boolean }>`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: ${({ hasAttachment }) => (hasAttachment ? 'pointer' : 'default')};
  display: flex;
  justify-content: space-between;
  width: 67px;
  @media only screen and (max-width: 840px) {
    top: 8px;
  }
`;

const FileItem = styled.div<{ status: number | string }>`
  position: relative;
  border: 1px solid black;
  border-radius: 8px;
  padding: 30px;
  margin: 20px;
  background-color: ${({ status }) => {
    if (typeof status === 'string') {
      switch (status) {
        case 'Completed':
          return 'rgb(210, 237, 185)';
        case 'Pending':
          return '#dfdfdf';
        case 'Rejected':
          return 'rgb(250, 181, 168)';
        case 'Submitted':
        case 'Under Review':
          return 'rgba(11,107,155,0.25)';
      }
    }
    switch (status) {
      case 31:
      case 42:
      case 43:
      case 167:
      case 187:
      case 182:
      case 177:
        return 'rgb(210, 237, 185)';
      case 46:
      case 165:
      case 189:
      case 184:
      case 179:
        return 'rgb(250, 181, 168)';
      case 45:
      case 162:
      case 176:
      case 174:
      case 175:
        return '#dfdfdf';
      case 47:
      case 166:
      case 191:
      case 186:
      case 181:
      case 164:
      case 190:
      case 185:
      case 180:
        return '#0ddbef55';
      default:
        return '#fff';
    }
  }};
  @media only screen and (max-width: 840px) {
    max-width: 82%;
  }
`;

const FileName = styled.div`
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 530px;
  @media only screen and (max-width: 840px) {
    font-size: 20px;
    max-width: 400px;
  }
`;
