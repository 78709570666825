import { api } from './api';

import {
  PaginatedList,
  PaginatedRequest,
  SelectOption,
} from '../types';
import { FranchiseType } from './franchise';

export const getDailyInspectionReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/daily-inspection-report',
    data,
  });
};

// EA-88
// mritunjoy
export const getCompanies = (
  params: PaginatedRequest,
) =>
  api.request({
    method: 'GET',
    url: '/account/users/',
    params,
  });
// EA-94
// mritunjoy
export const getManagers = (
  params: PaginatedRequest,
) =>
  api.request({
    method: 'GET',
    url: '/projects/managers/',
    params,
  });

export const getReportManagers = () =>
  api.request({
    method: 'GET',
    url: '/projects/project/managers/',
  });

// EA-85
// mritunjoy
export const getPaidFeesReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/paid-fees-report',
    data,
  });
};
export const getNotInvoicedPermittingFeesReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/manager-invoice-report',
    data,
  });
};

// EA-103
// mritunjoy
export const InspectorTimeTrackingReports = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/inspector-time-report',
    data,
  });
};

export const getCompanyToBeInvoiceReport = (
  data
) => {
  // data = {
  //   "company_name": "COMP121"
  // }
  return api.request({
    method: 'POST',
    url: '/reports/invoice-report',
    data,
  })
}
export const getPlanReviewerTimeTrackingReport = (
  data
) => {
  return api.request({
    method: 'POST',
    url: '/reports/plan-reviewer-time-tracking-report',
    data,
  })
}
export const getCompanyOutstandingFeesReport = (
  data
) => {
  return api.request({
    method: 'POST',
    url: '/reports/fees-report',
    data,
  })
}

export const getRunnerReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/runner-report',
    data,
  });
};

export const getFormsReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/form-report',
    data,
  });
};

export const getSingleFormReport = data => {
  const frmData = new FormData();
  frmData.append('inspector',data.inspector);
  frmData.append('date',data.date);
  frmData.append('jurisdiction',data.jurisdiction);
  frmData.append('inspectionId',data.inspectionId);
  frmData.append('statusName',data.statusName);
  return api.request<ReportsList>({
    method: 'POST',
    url: `/reports/signed-report`,
    data
  });
};

export const getInspectionPermitReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/permit-report',
    data,
  });
};

export const getAdminSalesReports = (
  params: PaginatedRequest,
) =>
  api.request<ReportsList>({
    method: 'GET',
    url: '/reports/special/admin-sales-report/',
    params,
  });

// Paid fees report FA and SA
export const getAdminPaidFeesReports = (
  data: PaginatedRequest,
) => {
  const modifiedData = { ...data }
  delete modifiedData?.franchise_id;

  return api.request<ReportsList>({
    method: 'POST',
    url: data?.franchise_id ? `/reports/paid-fees-daily-summary?franchise_id=${data?.franchise_id}` : '/reports/paid-fees-daily-summary',
    data: modifiedData,
  });
}


export const getReportByID = (id: number) =>
  api.request<ReportType>({
    method: 'GET',
    url: `/reports/${id}/`,
  });

export const createReport = (data: ReportType) =>
  api.request<ReportType>({
    method: 'POST',
    url: '/reports/',
    data,
  });
// Paid Admin Aging Summary
export const getAdminAgingSummary = (data
) => api.request<ReportsList>({
  method: 'GET',
  url: data ? `/reports/ar-aging-summary?franchise_id=${data}` : '/reports/ar-aging-summary'
});

export const updateReport = (data: ReportType) =>
  api.request<ReportType>({
    method: 'PATCH',
    url: `/reports/${data.id}/`,
    data,
  });

export const getReviewCommentsReport = data => {
  return api.request<ReportsList>({
    method: 'POST',
    url: '/reports/review-comments-report',
    data,
  });
};

export const deleteReport = (id: number) =>
  api.request<ReportType>({
    method: 'DELETE',
    url: `/reports/${id}/`,
  });

type ReportsList = PaginatedList<ReportType>;

export interface ReportType {
  report: string | SelectOption;
  fromDate: string | Date;
  formsDate?: string | Date;
  toDate: string | Date;
  franchises: [] | FranchiseType;
  isDeleted: boolean;
  inspector?: object | SelectOption;
  jurisdiction?: object | SelectOption;
  initialValue?: object;
  selectedReport?: null | string;
  franchiseId?: Array<any> | null;
}

export interface ReportTableType extends ReportType {
  report: SelectOption;
  fromDate: Date;
  toDate: Date;
  formsDate?: string | Date;
  range: Date[];
  franchises: FranchiseType;
  inspector?: object | SelectOption;
  jurisdiction?: object | SelectOption;
  isInspector?: boolean;
  setSelectedId?: (number) => void;
  manuallyUpdate?: number;
  renderActions?: boolean;
  inspectionStatus?: object | SelectOption;
}

export interface FormsReportType {
  id: number;
  name: string;
  image: string;
  franchise: string | Array<string>;
  jurisdiction: string | Array<string>;
}
