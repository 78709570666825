import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { SuccessModal, PasswordInput } from '../molecules';
import { ROUTES } from '../constants/routes';
import { ButtonMain } from '../atoms';

import {
  addErrorsForEmptyFields,
  isValidationSucceed,
} from '../utils/validation';

const ResetPasswordForm = ({
  fields,
  setFields,
  errors,
  setErrors,
  passwordIsReset,
  onResetPassword,
  isUrlCorrect,
}: Props) => {
  const history = useHistory();

  const handleChangeValues = (name: string) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFields(R.assoc(name, e.target.value, fields));
    setErrors(R.dissoc(name, errors));
  };

  const validateForm = () => {
    const newErrors = addErrorsForEmptyFields(
      fields,
      errors,
      'This field can not be empty',
    );

    setErrors(newErrors);

    return isValidationSucceed(newErrors);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      onResetPassword();
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <StyledPasswordInput
          id="newPassword1"
          label="New Password"
          value={fields.newPassword1}
          onChange={handleChangeValues('newPassword1')}
          isValid={!errors.newPassword1}
          helperText={errors.newPassword1}
        />
        <StyledPasswordInput
          id="newPassword2"
          label="Confirm Password"
          value={fields.newPassword2}
          onChange={handleChangeValues('newPassword2')}
          isValid={!errors.newPassword2}
          helperText={errors.newPassword2}
        />

        <StyledButtonMain type="submit">
          Reset Password
        </StyledButtonMain>

        {!isUrlCorrect && (
          <ErrorUrl>
            Password can not be changed via this link.
            <br />
            Please contact Elite Permits support
          </ErrorUrl>
        )}
      </Form>

      {passwordIsReset && (
        <SuccessModal
          title="Your password has been reset"
          onClose={() => history.push(ROUTES.LOG_IN)}
        />
      )}
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 252px;
  margin-top: 50px;
  margin-bottom: 73px;
`;

const StyledPasswordInput = styled(PasswordInput)`
  width: 100%;

  &:first-child {
    margin-bottom: 25px;
  }
`;

const StyledButtonMain = styled(ButtonMain)`
  margin: 76px auto 0;
  height: 36px;
  width: 236px;
`;

const ErrorUrl = styled.p`
  text-align: center;
  color: #ff3d3d;
`;

interface FormErrors {
  [s: string]: string;
  newPassword1: string;
  newPassword2: string;
}

interface FormData {
  token: string;
  uid: string;
  newPassword1: string;
  newPassword2: string;
}

interface Props {
  fields: FormData;
  setFields: (arg: FormData) => void;
  errors: FormErrors;
  setErrors: (arg: FormErrors) => void;
  passwordIsReset: boolean;
  onResetPassword: () => void;
  isUrlCorrect: boolean;
}

export default ResetPasswordForm;
