import * as React from 'react';
import useTablePage from '../hooks/useTablePage';
import Table from './Table';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import { FormSetupType } from '../api/formSetup';
import { getJurisdictions } from '../api/jurisdiction';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import ViewFormSetupModal from './ViewFormSetupModal';

const FormsTable = ({ forms }: Props) => {
  const {
    modalError,
    modalSuccess,
    isModalOpen,
    values,
    handleEdit,
    handleCloseModal,
  } = useTablePage({});

  const getForms = () =>
    Promise.all([
      getJurisdictions({ ...GET_ALL_QUERY_PARAMS }),
    ]).then(([jurisdictionsResponse]) => {
      return {
        data: {
          results: forms.map(form => ({
            ...form,
            jurisdictions: jurisdictionsResponse.data.results
              ?.filter(jur =>
                form.jurisdictions.includes(jur.id),
              )
              .map(el => el.name)
              .join(', '),
          })),
        },
      };
    });

  const columns = column => {
    if (
      ['documents', 'file', 'formMarkup'].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'name':
        return 'Form Name';

      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = ['id', 'name', 'jurisdiction'];

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  if (!forms?.length) {
    return null;
  }

  return (
    <>
      <Table
        useViewModeInsteadEdit
        onEdit={handleEdit}
        columns={columns}
        rows={getForms}
        columnsOrder={columnsOrder}
        customSearch
      />
      <ViewFormSetupModal
        isModalOpen={isModalOpen}
        onClose={handleCloseForm}
        initialValues={values}
      />
    </>
  );
};

interface Props {
  forms: Array<FormSetupType>;
}

export default FormsTable;
