import * as React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  InputBaseContainer,
  ButtonWithLoader,
  TextAreaWithLabel,
} from '../molecules';
import isYupFieldRequired from '../utils/isYupFieldRequired';

const labels = {
  text: 'Note',
};

const schema = yup.object().shape({
  text: yup.string().required().label(labels.text),
});

const NotesForm = ({
  onSubmit,
  modalError,
  addButtonText = 'Create',
  permit,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {} as any,
  });

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors]);

  const middleware = (submitFn: (data) => void) => data => {
    return submitFn({
      ...data,
      permit,
    });
  };

  return (
    <Container disabled={isSubmitting && !modalError}>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <TextAreaWithLabel
            name="text"
            error={errors.text}
            label={labels.text}
            inputRef={register as any}
            isRequired={isYupFieldRequired('text', schema)}
          />
        </InputBaseContainer>

        <ButtonWithLoader
          disabled={isSubmitting && !modalError}
          loading={isSubmitting && !modalError}
          type="submit"
        >
          {addButtonText}
        </ButtonWithLoader>
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  onSubmit: (data) => void;
  modalError: boolean;
  permit: number;
}

export default NotesForm;
