import * as React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomizedDialogs, ButtonMain } from '../../atoms';
import { Cropper } from '../../molecules';
import {
  requestFailure,
  setActiveRequest,
  setUserInfo,
  userInfoSelector,
} from '../../redux/ducks/userAuth';
import apiUserSettings from '../../api/userSettings';

import ArrowLeftSVG from '../../resources/icons/arrowLeft.svg';
import ArrowRightSVG from '../../resources/icons/arrowRight.svg';
import PersonSVG from '../../resources/icons/person.svg';

const getNewAngle = (
  oldAngle: number,
  direction: Direction,
) => {
  const step = 90 * direction;

  if (Math.abs(oldAngle) === 270) return 0;
  return oldAngle + step;
};

const allowedFileSize = 2e6;

const AvatarModal = ({
  onClose,
  setIsSuccessAvatarUploadModal,
  setIsAvatarModalOpen,
}: Props) => {
  const dispatch = useDispatch();
  const { id } = useSelector(userInfoSelector);

  const cropperRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer
  >('');
  const [rotationAngle, setRotationAngle] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(null);

  const [isImageTooBig, setIsImageTooBig] = useState(false);

  const [isUploadError, setIsUploadError] = useState(false);

  useEffect(() => {
    if (cropperRef.current) {
      cropperRef.current.setCanvasData({ top: 0 });
      cropperRef.current.cropper.resize();
    }
  }, [wrapperHeight]);

  const updateWrapperDimensions = newAngle => {
    const { width } = cropperRef.current.getCanvasData();

    const isVerticalMode = !!((newAngle / 90) % 2);

    setWrapperHeight(isVerticalMode ? width : null);
  };

  const rotate = (direction: Direction) => {
    const newAngle = getNewAngle(rotationAngle, direction);
    setRotationAngle(newAngle);
    updateWrapperDimensions(newAngle);
  };

  const rotateLeft = () => {
    rotate(-1);
  };

  const rotateRight = () => {
    rotate(1);
  };

  const handleAvatarChange = e => {
    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].size <= allowedFileSize
    ) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSelectedImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      setIsImageTooBig(false);
    }

    if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files[0].size > allowedFileSize
    ) {
      setIsImageTooBig(true);
    }
  };

  const uploadAvatar = async (newAvatar: Blob) => {
    try {
      dispatch(setActiveRequest());

      const formData = new FormData();

      formData.set('avatar', newAvatar, 'avatar.jpeg');

      const newUserInfo = await apiUserSettings.sendNewUserAvatar(
        id,
        formData,
      );

      const { avatar, ...restInfo } = newUserInfo;

      // we need to do so, because user avatar always updated under the same url
      const newAvatarLink = avatar
        ? `${avatar}?${Date.now()}`
        : avatar;

      dispatch(
        setUserInfo({
          ...restInfo,
          avatar: newAvatarLink,
        }),
      );

      setIsAvatarModalOpen(false);
      setIsSuccessAvatarUploadModal(true);
    } catch (e) {
      setIsUploadError(true);

      if (e.status === 400) {
        dispatch(requestFailure(e.response.data));
      }
    }
  };

  const handleSave = async () => {
    cropperRef.current
      .getCroppedCanvas()
      .toBlob(uploadAvatar, 'image/jpeg', 0.85);
  };

  const handleClose = () => {
    setSelectedImage('');
    onClose();
  };

  return (
    <CustomizedDialogsStyled onClose={handleClose}>
      <ContentWrapper>
        {!selectedImage && (
          <>
            <FileInputLabel>
              <BlockTitle>
                Drag a profile photo here
              </BlockTitle>

              <FileInput
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                multiple={false}
              />

              <AvatarPlaceholder />

              <OrText>or</OrText>

              <ButtonMainStyled as="div">
                Select a photo from your computer
              </ButtonMainStyled>
            </FileInputLabel>

            {isImageTooBig && (
              <ErrorUploadText>
                The size of the avatar should be less than
                2Mb, this file is too big
              </ErrorUploadText>
            )}
          </>
        )}
        {selectedImage && (
          <>
            <BlockTitle>
              To crop this image, drag the region below
            </BlockTitle>

            <CropperStyled
              ref={cropperRef}
              src={selectedImage}
              viewMode={1}
              aspectRatio={1}
              guides={false}
              rotateTo={rotationAngle}
              autoCropArea={1}
              zoomable={false}
              toggleDragModeOnDblclick={false}
              background={false}
              height={wrapperHeight}
            />

            <RotationButtons>
              <RotationButton onClick={rotateLeft}>
                <ArrowIcon src={ArrowLeftSVG} />
                <span>left</span>
              </RotationButton>
              <RotationButton onClick={rotateRight}>
                <ArrowIcon src={ArrowRightSVG} />
                <span>right</span>
              </RotationButton>
            </RotationButtons>

            <ButtonMainStyled
              onClick={handleSave}
              disabled={!cropperRef}
            >
              Set as profile photo
            </ButtonMainStyled>
            {isUploadError && (
              <ErrorUploadText>
                Something went wrong. The size of the avatar
                should be less than 2Mb, this file is too
                big.
              </ErrorUploadText>
            )}
          </>
        )}
      </ContentWrapper>
    </CustomizedDialogsStyled>
  );
};

const CustomizedDialogsStyled = styled(CustomizedDialogs)`
  .modal-window__modal-content {
    max-width: 936px;
    padding: 72px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BlockTitle = styled.div`
  text-align: center;
  margin-bottom: 26px;
`;

const RotationButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-between;
  margin: 0 auto 24px;
`;

const RotationButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--secondaryText);
  letter-spacing: 0.15px;

  span::first-letter {
    text-transform: uppercase;
  }
`;

const CropperStyled = styled(Cropper)`
  width: 100%;
  height: ${({ height }) =>
    height ? `${height}px` : '145px'};
  margin-bottom: 24px;

  .cropper-container {
    ${({ height }) =>
      height &&
      css`
        height: ${`${height}px`} !important;
      `}
  }

  .point-n,
  .point-w,
  .point-e,
  .point-s {
    display: none;
  }
`;

const ButtonMainStyled = styled(ButtonMain)`
  text-align: center;
  font-weight: 700;
  width: 100%;
  max-width: 315px;
  position: relative;
  cursor: pointer;
  z-index: var(--initialZIndex);
  padding-top: 11px;
  padding-bottom: 11px;
  margin: 0 auto;
`;

const ArrowIcon = styled(ReactSVG)`
  color: var(--primary);
  margin-bottom: 5px;

  svg {
    width: 25px;
    height: 16px;
  }
`;

const FileInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

const FileInput = styled.input.attrs({
  type: 'file',
  accept: 'image/*',
  multiple: false,
})`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const AvatarPlaceholder = styled(ReactSVG).attrs({
  src: PersonSVG,
})`
  background: var(--secondary);
  display: flex;
  padding: 36px 45px;
  color: var(--border);
  margin-bottom: 29px;

  svg {
    width: 55px;
    height: 70px;
  }
`;

const OrText = styled.span`
  text-align: center;
  display: block;
  margin-bottom: 29px;
`;

const ErrorUploadText = styled.span`
  position: absolute;
  bottom: -25px;
  color: var(--primary);
`;

interface Props {
  onClose: () => void;
  setIsSuccessAvatarUploadModal: (arg: boolean) => void;
  setIsAvatarModalOpen: (arg: boolean) => void;
}

type Direction = 1 | -1;

export default AvatarModal;
