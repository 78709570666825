import React, { useEffect, useState } from 'react';
import { DocumentConditionItem, PermitFeeItem } from '../types';
import { updatePagination } from '../utils';
import useFilterActions from '../../../pages/Responsive/useFilterActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { setRowsCount } from '../../../redux/slices/paginationSlice';

const useOnCommonTableActivity = (items: DocumentConditionItem[]| PermitFeeItem[]) => {
    const { pageSize, pageIndex } = useSelector((state: RootState) => state.pagination);
    const filteredRows = useFilterActions(items);
    const dispatch = useDispatch();
    const [visibleItems, setVisibleItems] = useState(filteredRows);
    useEffect(() => {
        const _items = updatePagination(pageIndex, pageSize, filteredRows);
        dispatch(setRowsCount(filteredRows.length));
        setVisibleItems(_items);
      }, [pageIndex, pageSize, filteredRows, items]);
    return { visibleItems }
};

export default useOnCommonTableActivity;