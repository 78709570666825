import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../../../../redux/root';
import { setShowDrawerContent } from '../../../../../../redux/slices/generalSlice';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';

const Drawer: FC = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const show = useSelector((s:RootState) => s.general.showDrawerContent)
  useEffect(() => {
    if(history.location.pathname !== ROUTES.DASHBOARD ){
      dispatch(setShowDrawerContent(false));
    }
  }, []);
  return <DrawerArea show={show}>{children}</DrawerArea>;
};

export default Drawer;

const DrawerArea = styled.div<{ show: boolean }>`
  height: 100%;
  width: 100%;
  border: 3px solid lightgrey;
  border-radius: 8px;
  left: ${({ show }) => (show ? '0' : '106')}vw;
  position: absolute;
  background-color: #fff;
  transition-property: left;
  transition-duration: 1s;
  display: flex;
`;
