import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGetContactTypesQuery } from '../../redux/rtkQuery/apiSlice';
import { updatePermitUser } from '../../api/users';
import { useParams } from 'react-router';

const ContactTypeSelect = ({ contactType, userId }) => {
  const {data} = useGetContactTypesQuery({});
  const [options,setOptions] = useState([]);
  const [value, setValue] = useState<number>(-1);
  //@ts-ignore
  const { permitId } = useParams();

  useEffect(() => {
    if(data){
      setOptions(data.results);
      const _value = data.results.find(r => r.name === contactType);
      if(_value){
        setValue(_value.id)
      }      
    }
  },[data])
 
  const onChange = async (e) => {
    const newContactType = e.target.value;
    const _data = {
        id:userId,
        newContactType,
        permitId: permitId
    }
    setValue(newContactType)
    await updatePermitUser(_data);
        // const { id, newContactType, permitId } = data
  }
  return (
    <Wrapper>
        <select value={value} onChange={onChange}>
            {options.map((item,index)=> <option key={index} value={item.id}>{item.name}</option>)}
        </select>
    </Wrapper>
  );
};

export default ContactTypeSelect;

const Wrapper = styled.div`
  width: 100%;
   select{
    width: 100%;
    padding: 8px;
   }
`;