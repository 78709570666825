import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserType } from '../../api/users';

export interface GlobalSearchState {
  permitId: number;
  permitInspectionsId: number;
  showPermitTable: boolean;
}

const initialState: GlobalSearchState = {
    permitId: -1,
    permitInspectionsId: -1,
    showPermitTable: false,
};

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setPermitId: (state, action: PayloadAction<number>) => {
      state.permitId = action.payload;
    },
    setPermitInspectionsId: (state, action: PayloadAction<number>) => {
      state.permitInspectionsId = action.payload;
    },
    setShowPermitTable: (state, action: PayloadAction<boolean>) => {
      state.showPermitTable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPermitId, setPermitInspectionsId, setShowPermitTable } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
