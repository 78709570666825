import * as React from 'react';
import styled from 'styled-components';

import Table from '../Table';
import ActionsPanel from '../ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ActivitiesTitleForm from '../../forms/ActivitiesTitleForm';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableBoolean } from '../../utils/convertTableValues';

import {
  getActivitiesList,
  createActivities,
  deleteActivities,
  updateActivities,
  ActivitiesType,
  ActivitiesTypeLink,
} from '../../api/activities';
import useUserRole from '../../hooks/useUserRole';

const modifiedGetActivitiesTitlesRequest = params =>
  getActivitiesList(params).then(response => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results.map(item => {
        if (item.isFranchiseAll) {
          return {
            ...item,
            franchiseNames: 'All',
          };
        }

        return item;
      }),
    },
  }));

const ActivitiesTitleTable = ({
  activitiesQuery,
  setSelectedActivity,
  hightLightSelectedRow,
}: ActivitiesTitleTableTypes) => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
    modalErrorTitle,
  } = useTablePage<ActivitiesTypeLink>({
    createEndpoint: createActivities,
    updateEndpoint: updateActivities,
    deleteEndpoint: deleteActivities,
    modalCreateTitle: 'Add Activity Title',
    modalUpdateTitle: 'Update Activity Title',
  });

  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'isDeleted',
        'franchise',
        'activityType',
        'isFranchiseAll',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    if (column === 'isActiveStatus') {
      return 'Is Active';
    }
    switch (column) {
      case 'franchiseNames':
        return 'Branches';
      default:
        return tableColumnsFormatter(column);
    }

  };

  const setSelectedActivityInfo = data => {
    if (data && data.length) {
      return setSelectedActivity([data[0]]);
    }
    return setSelectedActivity(null);
  };

  const columnsOrder = ['id', 'name', 'franchiseNames'];

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <>
      <h2>Activity Title</h2>
      <StyledActionsPanel
        onSearch={setSearch}
        onAdd={isSuperAdmin && handleAdd}
        isBreadCrumbsVisible={false}
        isActionPanelMoreVisible={false}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={modifiedGetActivitiesTitlesRequest}
          onEdit={handleEdit}
          onDelete={isSuperAdmin && handleDelete}
          manuallyUpdate={tableManuallyUpdate}
          formatValue={convertTableBoolean}
          columnsOrder={columnsOrder}
          additionalRequestParams={{
            activityType: activitiesQuery.id,
          }}
          handleCellClick={selectedRow =>
            setSelectedActivity([selectedRow])
          }
          cellCallbackFnIsOn={['name']}
          onLoadGetData={setSelectedActivityInfo}
          hightLightSelectedRow={hightLightSelectedRow}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <ActivitiesTitleForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            activitiesQuery={activitiesQuery}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

const Container = styled.div`
  margin: 16px 0;
`;

const StyledActionsPanel = styled(ActionsPanel)`
  margin: 0;
  width: 100%;
  height: 50px;
`;

interface ActivitiesTitleTableTypes {
  activitiesQuery: ActivitiesType;
  hightLightSelectedRow: ActivitiesTypeLink;
  setSelectedActivity: (data: ActivitiesTypeLink) => void;
}

export default ActivitiesTitleTable;
