import React, { useEffect, useState } from 'react';
import { Controls, Information, OtherFields } from '../Inspections/Controls/InspectionsControls';
import StaticField from '../../Common/Controls/StaticField';
import OtherField from '../../Common/Controls/OtherField';
import UploadFiles from '../../Common/UploadFiles';
import styled from 'styled-components';
import { Button, Drawer } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers';
import { RootState } from '../../../../../redux/root';
import schema from './schema';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';
import { updateConditionNotesAttachemnts } from '../../../../../api/conditions';
import DetailsHeaderComponent from '../../Common/DetailsHeaderComponent';
import CommentsTable from '../../Common/CommentsTable';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getPermitActivityComments } from '../../../../../api/activities';
import { Logo_Sky } from '../../../../../constants/menu';

const ConditionsControls = () => {
  type FormValue = {
    notes: string;
    documentFile: any[];
  };
  const comments = useSelector((s: RootState) => s.conditions.Comments);
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  const bottomDrawerOpened = useSelector((s: RootState) => s.general.bottomDrawerOpened);
  const { handleSubmit, control, register, errors, trigger, setValue, getValues, clearErrors, reset } = useForm<FormValue>({
    resolver: yupResolver(schema),
    defaultValues: {} as FormValue,
  });
  const dispatch = useDispatch();
  const condition = useSelector((s: RootState) => s.conditions.SelectedCondition);
  const conditionName = useSelector((s: RootState) => s.conditions.ConditionName);
  const selectedConditionItem = useSelector((s: RootState) => s.conditions.selectedConditionItem);

  const onClose = () => {
    dispatch(setBottomDrawerOpened(false));
  };

  const onSubmit = async form => {
    const _data: any = { ...condition };
    const data = {
      ..._data,
      status: selectedConditionItem.status,
      permit: _data.permitId,
      title: selectedConditionItem.title,
      information: form.notes,
      description: form.notes,
      attachments: form.documentFile,
    };
    try {
      const result = await updateConditionNotesAttachemnts(data);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setValue('notes', condition.information);
    }, 10);
  }, [condition]);
  return (
    <Drawer anchor={'bottom'} open={bottomDrawerOpened} transitionDuration={750} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Area>
            <CloseIcon onClick={onClose}>
              <HighlightOffIcon />
            </CloseIcon>
          </Area>
          <Area>
            <div>
              {/*@ts-ignore */}
              <DetailsHeaderComponent names={conditionName} address={`${condition.address}`} />
            </div>

            <Controls>
              <StaticField label="Condition Name:" value={conditionName} />
              <StaticField label="Status:" value={`${condition.status}`} />
              {selectedConditionItem.attachments &&
                selectedConditionItem.attachments.map((attach, index) => {
                  return (
                    <FileItem>
                      <IconWrapper>
                        <a href={attach.file} target="_blank">
                          <CloudDownloadIcon />
                        </a>
                      </IconWrapper>

                      <StaticField key={index} label={`Attachment (${index + 1}):`} value={attach.visibleFilename} />
                    </FileItem>
                  );
                })}

              <StaticField label="Comments:" value="" />

              {comments.length > 0 && <CommentsTable comments={comments} />}
              <OtherFields>
                <OtherField label="Notes" value="">
                  <NotesFrame foundError={errors.notes !== undefined}>
                    <Information name="notes" rows={5} ref={register} />
                  </NotesFrame>
                  {errors.notes !== undefined && <ErrorMsg>{errors.notes.message}</ErrorMsg>}
                  <UploadFiles setValue={setValue} register={register} />
                </OtherField>
              </OtherFields>
            </Controls>
          </Area>
          <Area>
            <ButtonsArea>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </ButtonsArea>
          </Area>
        </Wrapper>
      </form>
    </Drawer>
  );
};

export default ConditionsControls;

const IconWrapper = styled.div`
  color: ${Logo_Sky};
  cursor: pointer;
  margin-top: 6px;
`;

const FileItem = styled.div`
  display: grid;
  grid-template-columns: 26px 1fr;
  width: 43%;
  margin-left: 84px;
  div {
    margin-left: 5px;
  }
`;

const NotesFrame = styled.div<{ foundError: boolean }>`
  border: ${({ foundError }) => (foundError ? '3px solid red' : '1px solid grey')};
`;

const ErrorMsg = styled.div`
  color: red;
`;

const Wrapper = styled.div`
  height: 89vh;
  margin: 17px;
  margin-inline: 100px;
  display: grid;
  grid-template-rows: 40px 1fr 1fr 75px;
  border: 1px solid grey;
  border-radius: 8px;
  box-shadow: 10px 10px 10px grey;
  @media only screen and (max-width: 840px) {
    margin-inline: 0;
  }
`;

const Area = styled.div`
  width: 100%;
  position: relative;
`;

const CloseIcon = styled.div`
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  zoom: 1.5;
  cursor: pointer;
`;

const ButtonsArea = styled.div`
  margin: auto;
  display: flex;
  margin-top: 26px;
  button {
    margin: auto;
    width: 250px;
  }
  @media only screen and (max-width: 840px) {
    margin-top: 10px;
  }
`;
