import React from 'react';
import { useSelector } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { RootState } from '../../../../../../redux/root';
import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Logo_Sky } from '../../../../../../constants/menu';
import StaticField from '../../../Common/Controls/StaticField';
import { deleteClientDocument, sendClientDocument } from '../../../../../../api/document';
import { dispatch } from '../../../../../../redux/store';
import { setPermitDocuments, setSelectedDocument } from '../../../../../../redux/slices/documentsSlice';
import { IconWrapper } from './FilesSection';

const ActionFiles = () => {
  const {SelectedDocument,PermitDocuments} = useSelector((s: RootState) => s.documents);
  const deleteAttachment = async id => {
    const result = await deleteClientDocument(id);
    if (result.status === 200) {
      const _clientFiles = SelectedDocument.clientFiles.reduce((files, file) => {
        if (file.id !== id) {
          files.push(file);
        }
        return files;
      }, []);
      const _selectedDocument = {
        ...SelectedDocument,
        clientFiles: _clientFiles,
      };
      dispatch(setSelectedDocument(_selectedDocument));

      const _permitDocuments = PermitDocuments.map((doc) => {
        if(doc.pk === _selectedDocument.pk){
          return _selectedDocument;
        }
        return doc;
      });
      dispatch(setPermitDocuments(_permitDocuments));
    }
  };
  const allowDelete: boolean = SelectedDocument.status === 45;
  return (
    <Wrapper>
      {SelectedDocument.clientFiles?.length > 0 && <StaticField label="Client Files:" value={''} />}
      {SelectedDocument.clientFiles?.map((item, index) => (
        <FileItemWrapper>
          {allowDelete && (
            <DeleteForeverIcon
              onClick={e => {
                deleteAttachment(item.id);
              }}
            />
          )}
          {!allowDelete && (
            <IconWrapper status={43}>
              <CheckCircleIcon />
            </IconWrapper>
          )}
          <a href={item.file} target="_blank">
            <FileItem>{item.fileName}</FileItem>
          </a>
        </FileItemWrapper>
      ))}
    </Wrapper>
  );
};

export default ActionFiles;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 90px;
`;

const FileItem = styled.div`
  font-size: 15px;
  color: ${Logo_Sky};
`;

const FileItemWrapper = styled.div`
  display: flex;
  svg {
    margin-right: 10px;
    cursor: pointer;
  }
`;
