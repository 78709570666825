import React from 'react';
import ClientContainer from '../ClientContainer';
import UserAccountForm from '../../../../forms/UserAccountForm';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo, userErrorsSelector, userInfoSelector } from '../../../../redux/ducks/userAuth';

const UserAccount = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userInfoSelector);
    const userErrors = useSelector(userErrorsSelector);
    const handleOnSettingsChange = formValues => {
        dispatch(updateUserInfo(formValues));
      };
  return (
    <ClientContainer>
      <UserAccountForm initialValues={userInfo} initialErrors={userErrors as any} onSubmit={handleOnSettingsChange} />
    </ClientContainer>
  );
};

export default UserAccount;
