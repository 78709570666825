import { isBefore } from 'date-fns';
import React, { FC } from 'react';
import styled from 'styled-components';

export type FieldData = {
  label: string,
  key1: string,
  key2?: string,
  bgColor?: string,
  verifyDate?: string,
  linkField?: string;
  linkAction?: Function;
}

type MyProps = {
    item: any,
    shaded: boolean,
    fields: FieldData[],
    removeBorder?: boolean
}
const MobileRecord:FC<MyProps> = ({ item, shaded, fields, removeBorder }) => {
    return (
      <RecordWrapper
        shaded={shaded}
        isEmergency={item.isEmergency}
        removeBorder={removeBorder}
      >
        {fields.map((rec, index) => {
          let bgColor = "";
          if (rec.verifyDate) {
             const date = item[rec.verifyDate];
             if (isBefore(new Date(date), new Date())) {
              bgColor = "rgb(250,181,168)";
             }
          }
          return (
            <Field
              key={index}
              label={rec.label}
              value={`${item[rec.key1] ? item[rec.key1] : ''} ${rec.key2 ? item[rec.key2] : ''}`.replace(/undefined/,'').trim()}
              bgColor={bgColor}
              item={item}
            />
          );
        })}
      </RecordWrapper>
    );
};

const Field:FC<{item:any, label:string, value:string, bgColor?:string, linkAction?: Function}> = 
({ label, value, bgColor, linkAction, item }) => {
    return (
      <FieldWrapper bgColor={bgColor}>
        <FieldLabel>{`${label}:`}</FieldLabel>
        {linkAction ? (
        <div onClick={(e) => linkAction(e)} onTouchStart={(e) => linkAction(e,item)}>
          <FieldValueLink>{value}</FieldValueLink>
        </div>
      ) : (
        <FieldValue>{value}</FieldValue>
      )}
      </FieldWrapper>
    );
};

const RecordWrapper = styled.div<{shaded:boolean,
  isEmergency:boolean,
  removeBorder?:boolean}>`
  display: grid;
  padding: 7px;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: ${props => props.removeBorder ? null : "4px solid gray;"};
  background-color: ${props => {
    if (props.isEmergency) {
      return "rgb(250,181,168)";
    }

    return props.shaded ? "white" : "#A4C7ED";
  }
}
`;

const FieldWrapper = styled.div<{bgColor:string}>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.bgColor ? `${props.bgColor}` : ""};
`;

const FieldLabel = styled.div`
@media only screen and (max-width: 390px) {
    font-size: 8px;
}
  font-size: 12px;
  font-weight: bold;
`;

const FieldValue = styled.div`
@media only screen and (max-width: 390px) {
   font-size: 12px;
}
  font-size: 16px;
  padding-bottom: 5px;
`;

const FieldValueLink = styled.div`
  @media only screen and (max-width: 390px) {
    font-size: 12px;
  }
  font-size: 16px;
  padding-bottom: 5px;
  text-decoration: underline;
  cursor: pointer;
`;
export default MobileRecord;
