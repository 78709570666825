import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { convertErrorResponseToValid } from '../../utils/parseErrorResponse';

const initialState: RequestApiErrorState = {
  errorData: null,
  errorStatus: null,
};

const reducerName = 'requestApiError';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setRequestError: (
      state,
      action: PayloadAction<PayloadErrorsObj>,
    ) => {
      const { status, data } = action.payload;

      if (
        status === 401 ||
        (status === 400 && !data.detail)
      ) {
        return;
      }

      state.errorStatus = status;
      state.errorData = convertErrorResponseToValid(data);
    },
    clearRequestError: state => {
      state.errorData = null;
      state.errorStatus = null;
    },
  },
});

export const {
  clearRequestError,
  setRequestError,
} = slice.actions;

export const isOpenErrorModalSelector = ({
  requestApiError,
}) => !!requestApiError.errorData;

export const errorsSelector = (
  state,
) => {
  return state.requestApiError.errorData || {}
};

export const requestApiErrorObj = (
  state: PartialRootState,
) => {
  const { errorData, errorStatus } = state.requestApiError;
  return {
    data: errorData,
    status: errorStatus,
  };
};

interface PayloadErrorsObj {
  data: ErrorsObj;
  status: null | number;
}
interface RequestApiErrorState {
  errorData: ErrorsObj | null;
  errorStatus: number | null;
}

interface ErrorsObj {
  [key: string]: string;
  detail?: string;
}

type PartialRootState = {
  [reducerName]: RequestApiErrorState;
};

export default slice.reducer;
