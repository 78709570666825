import * as React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import styled from 'styled-components';
import { FieldError, FieldErrors } from 'react-hook-form';
import { Props as SelectProps } from 'react-select';
import { LabelForm, TextInput } from '../atoms';
import { parseGoogleApiAddress } from '../utils/parseGoogleApiAddress';

const InputAutocompletePlaces = ({
  isRequired,
  label,
  error,
  theme,
  isDisabled,
  handleFieldChange,
  blueFont,
  ...props
}: Props) => {
  const { name, id, value } = props;
  const [addressValue, setAddressValue] = React.useState(
    '',
  );

  React.useEffect(() => {
    if (value) {
      setAddressValue(value as any);
    }
  }, [value]);

  const handleChange = address => {
    setAddressValue(address);
    handleFieldChange(address, false);
  };

  const handleSelect = address => {
    setAddressValue(address);
    geocodeByAddress(address)
      .then(results =>
        handleFieldChange(
          parseGoogleApiAddress(
            address,
            results[0].address_components,
          ),
          true,
        ))
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <InputContainer>
      <LabelForm htmlFor={name} isRequired={isRequired}>
        <LabelWrapper blueFont={blueFont}>{label}</LabelWrapper>
      </LabelForm>
      <PlacesAutocomplete
        value={addressValue}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={onError}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
        }) => (
          <AutocompleteContainer>
            <StyledTextInput
              id={id || name}
              fullWidth
              required={isRequired}
              error={!!error}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getInputProps({ disabled: isDisabled })}
            />
            {!!suggestions.length && (
              <OptionsMenuContainer>
                <OptionsMenuListContainer>
                  {suggestions.map(suggestion => {
                    return (
                      <SelectOption
                        isActive={suggestion.active}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getSuggestionItemProps(
                          suggestion,
                        )}
                        key={suggestion.description}
                      >
                        <span>
                          {suggestion.description}
                        </span>
                      </SelectOption>
                    );
                  })}
                </OptionsMenuListContainer>
              </OptionsMenuContainer>
            )}
          </AutocompleteContainer>
        )}
      </PlacesAutocomplete>
      {error && (
        <span
          style={{
            color: '#f44336',
            fontSize: '0.75rem',
            margin: '3px 14px 0 14px',
          }}
        >
          {error.message ? error.message : error}
        </span>
      )}
    </InputContainer>
  );
};

type Props = SelectProps & {
  isRequired?: boolean;
  error?: FieldError | FieldErrors;
};

const LabelWrapper = styled.div<{blueFont: boolean}>`
  color: ${({blueFont}) => blueFont ? '#316B99' : 'inherit'}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
  }
`;

const StyledTextInput = styled(TextInput)`
  fieldset {
    height: 40px;
    top: 0;
  }

  input {
    border-radius: 1px;
    padding: 0 16px;
    top: 0;
    height: 40px;
  }

  .PrivateNotchedOutline-root-2 {
    top: 0;
  }
`;

const AutocompleteContainer = styled.div`
  width: 100%;
  position: relative;
`;

const OptionsMenuContainer = styled.div`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: var(--modalZIndex);
  box-sizing: border-box;
`;

const OptionsMenuListContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
`;

const SelectOption = styled(({ isActive, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...rest} />
))`
  text-align: left;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;

  ${props =>
    props.isActive &&
    `
  background-color: #DEEBFF;
  `}
`;

export default InputAutocompletePlaces;
