import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

import Table from '../Table';

import ActivitiesEditForm from '../../forms/ActivitiesEditForm';

import { ROUTES } from '../../constants/routes';
import { DATETIME_FORMAT, DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS } from '../../constants';

import { getActivitiesList, getCustomActivities, getTasksheetPermitActivitiesForUser } from '../../api/activities';
import { getStripeInvoicesList } from '../../api/invoices';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableBoolean } from '../../utils/convertTableValues';
import formatDate, { formatDateTime } from '../../utils/formatDate';
import useTablePage from '../../hooks/useTablePage';
import { getTickets } from '../../api/tickets';
import TicketsEditForm from '../../forms/TicketsEditForm';
import DocumentsEditForm from '../../forms/DocumentsEditForm';
import ConditionsEditForm from '../../forms/ConditionsEditForm';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { setUseTaskSheet } from '../../redux/slices/allActivitiesSlice';

const labels = {
  invoice: 'invoice',
  documents: 'documents',
  tickets: 'tickets',
  waitingStatus: 45,
  underReviewStatus: 47,
  completedStatus: 42,
};

const TaskSheetBlock = () => {
  const { push }: History = useHistory();

  const [invoicesColumn, setInvoicesColumn] = useState(0);
  const [ticketsColumn, setTicketsColumn] = useState(0);
  const [documentsColumn, setDocumentsColumn] = useState(0);
  const [conditionsColumn, setConditionsColumn] = useState(0);
  const pageSize = useSelector((s:RootState) => s.pagination.pageSize);
  const {id:currentUserId,selectedFranchise} = useSelector((s:RootState) => s.userAuth.info);

  const [editFormData, setEditFormData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTicketsTable, setShowTicketsTable] = useState(false);
  const [showDocumentsTable, setShowDocumentsTable] = useState(false);
  const [showConditionsTable, setShowConditionsTable] = useState(false);

  const [isTicketsModalOpen, setIsTicketsModalOpen] = useState(false);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [isConditionsModalOpen, setIsConditionsModalOpen] = useState(false);
  setIsDocumentsModalOpen;
  const { updateTable, tableManuallyUpdate } = useTablePage({});
  const dispatch = useDispatch();
  React.useEffect(() => {
    const today = formatDate(new Date(), DEFAULT_DATE_FORMAT);
    const loadData = async () => {
      //Invoices
      const invoicesResponse = await getStripeInvoicesList({
        createdBefore: today,
        franchise_id: selectedFranchise,
      });
      setInvoicesColumn(invoicesResponse.data.count);

      //Tickets
      const ticketResponse = await getTickets({ 
        assignedTo: currentUserId,
        franchise_id: selectedFranchise,
      });
      setTicketsColumn(ticketResponse?.data?.count);

      //Documents
      const docResponse = await getCustomActivities({
        is_deleted: false,
        limit: pageSize,
        event_type: 'Activity',
        activity_type_name: 'Document',
        task_sheet: true,
        franchise_id: selectedFranchise,
      });
      setDocumentsColumn(docResponse?.data?.highestCount)

      //Conditions
      const conditionResponse = await getCustomActivities({
        is_deleted: false,
        limit: pageSize,
        event_type: 'Activity',
        activity_type_name: 'Conditions',
        franchise_id: selectedFranchise,
        task_sheet: true,
      });
      setConditionsColumn(conditionResponse?.data?.highestCount)
    }
    loadData();
  }, []);

  const getModifiedDocuments = params =>
    getCustomActivities({
      ...params,
      event_type: 'Activity',
      activity_type_name: 'Document',
      task_sheet: true,
      franchise_id: selectedFranchise
    }).then(response => {
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(item => {
            return {
              ...item,
              clientFile: item?.clientFiles?.map(file => file?.fileName),
              id: item?.pk,
              projectPermit: `${item?.projectName} - ${item?.permitName}`,
              ticketNumber: item?.id,
              status: item?.statusName,
            };
          }),
        },
      };
    });

  const getModifiedConditions = params =>
    getCustomActivities({
      ...params,
      event_type: 'Activity',
      activity_type_name: 'Conditions',
      task_sheet: true,
      franchise_id: selectedFranchise,
    }).then(response => {
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(item => {
            return {
              ...item,
              activityType: item?.type,
              projectPermit: `${item?.projectName} - ${item?.permitName}`,
              attachments: item?.attachments?.map(file => file?.visibleFilename),
              // ticketNumber: item?.id,
              // status: item?.statusName,
            };
          }),
        },
      };
    });

  const getModifiedTickets = params =>
    getTickets({ ...params, assignedTo: currentUserId,franchise_id:selectedFranchise }).then(response => {
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map((item:any) => {
            return {
              ...item,
              projectPermit: `${item?.projectName} - ${item?.permitName}`,
              ticketNumber: item?.id,
              status: item?.statusName,
            };
          }),
        },
      };
    });
  const getModifiedActivities = params =>
    Promise.all([
      getTasksheetPermitActivitiesForUser({
        ...params,
        assignedTo: currentUserId,
      }),
      getActivitiesList({
        ...GET_ALL_QUERY_PARAMS,
      }),
    ])
      .then(([activitiesResponse, activityTitlesResponse]) => {
        return {
          ...activitiesResponse,
          data: {
            ...activitiesResponse?.data,
            results: activitiesResponse?.data?.data.map(el => {
              const activitiesTitles = activityTitlesResponse.data.results?.filter(item => item?.isActiveStatus === 'True');
              const title = activitiesTitles.find(item => item?.id === el.title);

              return {
                ...el,
                activityTitle: title?.name,
                projectPermit: `${el?.projectName} - ${el?.permitName}`,
                followUp: formatDateTime(el.followUp, DATETIME_FORMAT),
              };
            }),
          },
        };
      })
      .catch(err => console.error(err))
      .finally(() => {});

  const ticketsColumns = column => {
    if (
      [
        'permit',
        'amount',
        'id',
        'inspector',
        'isEmergency',
        'isCompleted',
        'reviewType',
        'information',
        'lastComment',
        'reviewTypeName',
        'fees',
        'feeNames',
        'title',
        'contact',
        'assignedTo',
        'attachments',
        'activityType',
        'permitId',
        'contactFirstName',
        'contactLastName',
        'assignedToFirstName',
        'assignedToLastName',
        'activityTitleName',
        'activityTypeName',
        'projectName',
        'projectJurisdiction',
        'statusName',
        'contactId',
        'permitName',
        'permitNumber',
        'companyName',
        'projectId',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'conditionName',
        'selectedDocuments',
        'reviewTypeComment',
        'creatorUsername',
        'subject',
        'description',
        'lastUpdated',
        'isDeleted',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'projectPermit':
        return 'Project - Permit';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const ticketsColumnsOrder = ['projectPermit', 'ticketNumber', 'status'];

  const documentsColumns = column => {
    if (
      [
        'permit',
        'amount',
        'id',
        'inspector',
        'isEmergency',
        'isCompleted',
        'reviewType',
        'information',
        'lastComment',
        'reviewTypeName',
        'fees',
        'feeNames',
        'title',
        'contact',
        'assignedTo',
        'attachments',
        'activityType',
        'permitId',
        'contactFirstName',
        'contactLastName',
        'assignedToFirstName',
        'assignedToLastName',
        'activityTitleName',
        'activityTypeName',
        'projectName',
        'projectJurisdiction',
        'statusName',
        'contactId',
        'permitName',
        'permitNumber',
        'companyName',
        'projectId',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'conditionName',
        'selectedDocuments',
        'reviewTypeComment',
        'creatorUsername',
        'subject',
        'description',
        'lastUpdated',
        'isDeleted',
        'ticketNumber',
        'pk',
        'type',
        'clientFiles',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'projectPermit':
        return 'Project - Permit';
      case 'name':
        return 'Document Name';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const documentsColumnsOrder = ['projectPermit', 'name', 'clientFile', 'status'];

  const conditionsColumns = column => {
    if (
      [
        'permit',
        'amount',
        'id',
        'inspector',
        'isEmergency',
        'isCompleted',
        'reviewType',
        'information',
        'lastComment',
        'reviewTypeName',
        'fees',
        'feeNames',
        'title',
        'contact',
        'assignedTo',
        'activityType',
        'permitId',
        'contactFirstName',
        'contactLastName',
        'assignedToFirstName',
        'assignedToLastName',
        'activityTypeName',
        'projectName',
        'projectJurisdiction',
        'contactId',
        'permitName',
        'permitNumber',
        'companyName',
        'projectId',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'selectedDocuments',
        'reviewTypeComment',
        'creatorUsername',
        'subject',
        'description',
        'lastUpdated',
        'isDeleted',
        'ticketNumber',
        'pk',
        'type',
        'clientFiles',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'projectPermit':
        return 'Project - Permit';
      case 'activityTitleName':
        return 'Activity Title';
      case 'statusName':
        return 'Activity Status';
      case 'conditionName':
        return 'Condition Name';
      case 'attachments':
        return 'Attachment';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const conditionsColumnsOrder = ['projectPermit', 'activityTitleName', 'conditionName', 'statusName', 'followUp', 'attachments'];

  const activitiesColumns = column => {
    if (
      [
        'permit',
        'amount',
        'id',
        'inspector',
        'isEmergency',
        'isCompleted',
        'reviewType',
        'information',
        'lastComment',
        'reviewTypeName',
        'fees',
        'feeNames',
        'status',
        'title',
        'contact',
        'assignedTo',
        'attachments',
        'activityType',
        'permitId',
        'contactFirstName',
        'contactLastName',
        'assignedToFirstName',
        'assignedToLastName',
        'activityTitleName',
        'activityTypeName',
        'projectName',
        'projectJurisdiction',
        'statusName',
        'contactId',
        'permitName',
        'permitNumber',
        'companyName',
        'projectId',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'conditionName',
        'selectedDocuments',
        'reviewTypeComment',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'projectPermit':
        return 'Project - Permit';
      case 'activityTitle':
        return 'Activity Title';
      case 'followUp':
        return 'Follow Up';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const activitiesColumnsOrder = ['projectPermit', 'activityTitle', 'followUp'];

  const onChangeRoute = route => {
    if (route === labels.invoice) {
      dispatch(setUseTaskSheet(true));
      push(`${ROUTES.ALL_ACTIVITIES}/fee`, { from: 'tasksheet' });
    } else {
      push(`${ROUTES.ALL_ACTIVITIES}/${route}`);
    }
  };

  const handleCellClick = data => {
    setEditFormData(data);
    setIsModalOpen(true);
  };

  const handleTicketsCellClick = data => {
    setEditFormData(data);
    setIsTicketsModalOpen(true);
  };

  const handleDocumentsCellClick = data => {
    setEditFormData(data);
    setIsDocumentsModalOpen(true);
  };
  const handleConditionsCellClick = data => {
    setEditFormData(data);
    setIsConditionsModalOpen(true);
  };

  const handleCloseInfoModal = closeInfo => {
    setEditFormData(null);
    updateTable();
    closeInfo();
  };

  return (
    <Wrapper>
      <Header>Task Sheet</Header>
      <Content>
        <HeaderBlock>
          <StyledParagraph onClick={() => onChangeRoute(labels.invoice)}>
            Invoices(Click here): <b>{invoicesColumn}</b>
          </StyledParagraph>
          <StyledParagraph
            onClick={() => {
              setShowTicketsTable(true);
              setShowDocumentsTable(false);
            }}
          >
            Tickets: <b>{ticketsColumn}</b>
          </StyledParagraph>
          <StyledParagraph
            onClick={() => {
              setShowTicketsTable(false);
              setShowDocumentsTable(true);
            }}
          >
            Documents: <b>{documentsColumn}</b>
          </StyledParagraph>
          <StyledParagraph
            onClick={() => {
              setShowTicketsTable(false);
              setShowDocumentsTable(false);
              setShowConditionsTable(true);
            }}
          >
            Conditions: <b>{conditionsColumn}</b>
          </StyledParagraph>
        </HeaderBlock>
        <StyledTitle>
          {showTicketsTable ? 'Tickets' : showDocumentsTable ? 'Documents' : showConditionsTable ? 'Conditions' : 'Activities'}
        </StyledTitle>
        {showTicketsTable && (
          <Table
            customSearch
            manuallyUpdate={tableManuallyUpdate}
            columnsOrder={ticketsColumnsOrder}
            columns={ticketsColumns}
            rows={getModifiedTickets}
            onEdit={null}
            onDelete={null}
            onConfigure={null}
            additionalActions={null}
            formatValue={convertTableBoolean}
            cellCallbackFnIsOn={['projectPermit']}
            handleCellClick={handleTicketsCellClick}
          />
        )}

        {showDocumentsTable && (
          <Table
            customSearch
            manuallyUpdate={tableManuallyUpdate}
            columnsOrder={documentsColumnsOrder}
            columns={documentsColumns}
            rows={getModifiedDocuments}
            onEdit={null}
            onDelete={null}
            onConfigure={null}
            additionalActions={null}
            formatValue={convertTableBoolean}
            cellCallbackFnIsOn={['projectPermit']}
            handleCellClick={handleDocumentsCellClick}
          />
        )}

        {showConditionsTable && (
          <Table
            customSearch
            manuallyUpdate={tableManuallyUpdate}
            columnsOrder={conditionsColumnsOrder}
            columns={conditionsColumns}
            rows={getModifiedConditions}
            onEdit={null}
            onDelete={null}
            onConfigure={null}
            additionalActions={null}
            formatValue={convertTableBoolean}
            cellCallbackFnIsOn={['projectPermit']}
            handleCellClick={handleConditionsCellClick}
          />
        )}
        {currentUserId && !showTicketsTable && !showDocumentsTable && !showConditionsTable && (
          <Table
            customSearch
            manuallyUpdate={tableManuallyUpdate}
            columnsOrder={activitiesColumnsOrder}
            columns={activitiesColumns}
            rows={getModifiedActivities}
            onEdit={null}
            onDelete={null}
            onConfigure={null}
            additionalActions={null}
            formatValue={convertTableBoolean}
            cellCallbackFnIsOn={['projectPermit']}
            handleCellClick={handleCellClick}
          />
        )}
        {isModalOpen && editFormData && (
          <ActivitiesEditForm
            initialValues={editFormData}
            handleCloseInfoModal={handleCloseInfoModal}
            permit={editFormData.permit}
            setInitialValues={setEditFormData}
          />
        )}
        {isTicketsModalOpen && editFormData && (
          <TicketsEditForm
            initialValues={editFormData}
            handleCloseInfoModal={handleCloseInfoModal}
            permit={editFormData.permit}
            project={editFormData.projectId}
            setInitialValues={setEditFormData}
          />
        )}
        {isDocumentsModalOpen && editFormData && (
          <DocumentsEditForm
            initialValues={editFormData}
            handleCloseInfoModal={handleCloseInfoModal}
            permit={editFormData.permitId}
            project={editFormData.projectId}
            setInitialValues={setEditFormData}
          />
        )}
        {isConditionsModalOpen && editFormData && (
          <ConditionsEditForm
            initialValues={editFormData}
            handleCloseInfoModal={handleCloseInfoModal}
            permit={editFormData.permitId}
            project={editFormData.projectId}
            setInitialValues={setEditFormData}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  margin-right: 10px;
  padding: 6px;
  width: 760px;
  min-height: 300px;
  border: 1px solid var(--border);
  border-radius: 5px;
  overflow: auto;
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;
const Content = styled.div`
  overflow: auto;
  height: 290px;
`;
const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledParagraph = styled.p`
  font-size: 14px;
  cursor: pointer;
  b {
    color: var(--primary);
  }
`;
const StyledTitle = styled.h4`
  text-align: center;
  margin: 6px 0;
`;

export default TaskSheetBlock;
