import React from 'react';
import styled from 'styled-components';
import { Logo_Gold, Logo_Sky } from '../../../../../constants/menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

const ProgressBar = () => {
    const {percent, completedCount, allCount, rejectCount} = useSelector((s:RootState) => s.documents.PercentComplete);
  return (
    <ProgressPercent>
      <SubHeader>Document {completedCount} out of {allCount - rejectCount} completed</SubHeader> 
      <MovingSection>
          <MovingBar percent={`${percent}%`} />
      <Percent>{`${percent}%`}</Percent>
      </MovingSection>
    
    </ProgressPercent>
  );
};

export default ProgressBar;

const Percent = styled.div`
  color: ${Logo_Sky};
`;

const MovingSection = styled.div`
  position: relative;
  top:-20px;
  height: 20px;
`;

const SubHeader = styled.div`
  position: relative;
  top: -23px;
  color: ${Logo_Sky};
`;

const ProgressPercent = styled.div`
  height: 30px;
  width: 90%;
  margin: auto;
  margin-top:33px;
  background-color: ${Logo_Gold};
  border-radius: 6px;
  padding: 4px;
  padding-top: 2px;
  padding-left: 2px;
  position: relative;
  @media only screen and (max-width: 840px) {
    margin-top:58px;
  }
`;

const MovingBar = styled.div<{ percent: string }>`
  height: 100%;
  background-color: ${Logo_Sky};
  width: ${({ percent }) => percent};
  border-radius: 6px;
  margin: 1px;
`;
