import * as React from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';
import { Props as SelectProps } from 'react-select';

import { SelectInput, Tooltip } from '../atoms';

const InputSelect = ({
  isRequired,
  label,
  error,
  theme,
  ...props
}: Props) => {
  const title =
    error?.message ||
    (error?.types ? Object.values(error.types)[0] : null);

  return (
    <Tooltip title={title} placement="top">
      <div>
        <SelectInput
          required={isRequired}
          error={!!error}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
    </Tooltip>
  );
};

type Props = SelectProps & {
  isRequired?: boolean;
  error?: FieldError | FieldErrors;
  tableStyles?: boolean;
};

export default InputSelect;
