import * as React from 'react';

import useTablePage from '../hooks/useTablePage';

import { updatePermitActivity } from '../api/activities';

import PermitActivitiesForm from './PermitActivitiesForm';

import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import ModalInfo from '../molecules/ModalInfo';

const ConditionsEditForm = ({
  initialValues,
  handleCloseInfoModal,
  permit,
  setInitialValues,
}: Props) => {    
  const {
    modalSuccess,
    modalError,
    createOrUpdate,
    modalTitle,
    errors,
    values,
    setValues,
    modalErrorTitle,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage({
    updateEndpoint: updatePermitActivity,
  });

  React.useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const handleCloseActivitiesModal = () => {
    if (!modalError && !modalSuccess) {
      setInitialValues(null);
      handleCloseModal();
    }
  };

  return (
    <>
      {values ? (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseActivitiesModal}
        >
          <PermitActivitiesForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            conditionsMode
          />
        </ModalDefaultDraggable>
      ) : null}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={() =>
            handleCloseInfoModal(handleCloseModalInfo)
          }
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  initialValues: object;
  handleCloseInfoModal: (data: () => void) => void;
  permit: number;
  setInitialValues: (data: any) => void;
}

export default ConditionsEditForm;
