import React, { useEffect } from 'react';
import PaymentIcon from '@material-ui/icons/Payment';
import Table2, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { RootState } from '../../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFee } from '../../../../../../redux/slices/feesSlice';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';

const FeesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const permitsWithFees = useSelector((s: RootState) => s.fees.inspectionFeesList);
  const onClick = (id: number) => {
    const _selected = permitsWithFees.find(r => r.permitId === id);
    dispatch(setSelectedFee(_selected));
    history.push(`${ROUTES.FEES}?openDrawer=true`);
  }
  return (
    <Table2 headers={DashboardTableHeaders('Fee')} records={permitsWithFees.slice(0, 5)} iconTitle="Pay fees" onClick={onClick}>
      <PaymentIcon />
    </Table2>
  );
};

export default FeesTable;
