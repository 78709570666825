import React, { FC, useState } from 'react';

import geographyStates from '../../../../constants/geographyStates';
import { Controller } from 'react-hook-form';
import Dropdown from './Controls/Dropdown';

type Props = {
  register: any;
  errors: any;
  setValue: Function;
  control: any;
};
const StatesDropdown: FC<Props> = ({
  register,
  errors,
  control,
}) => {
  const [stateName, setStateName] = useState<string>('');
  const states: any = geographyStates;

  const statesItems = Object.entries(states).map(
          (entry: any, index: number) => (
            {id: entry[1], title: entry[1] }
                     ),
        )
  return (
    <Dropdown
          register={register}
          errors={errors}
          control={control}
          dropdownItems={statesItems} //item.id    item.title
          name="state"
          width={100}
        />
  );
};

export default StatesDropdown;
