export const labels = {
  activityTitle: 'Activity Title',
  inspectionType: 'Inspection Type',
  information: 'Information',
  trades: 'Trades',
  activityContact: 'Activity Contact',
  inspectionDate: 'Inspection Date',
  activityStatus: 'Activity Status',
  period: 'Time',
  inspector: 'Inspector',
  followUp: 'Follow-up date',
  assignedTo: 'Assigned to',
  emergency: 'Emergency',
  isCompleted: 'Completed',
  activityContactCheckbox: 'Activity Contact',
  jurisdictionContacts: 'Jurisdiction',
  projectContacts: 'Project Contacts',
  additionalContacts: 'More Contacts',
  cellPhone: 'Cell Phone',
  phoneNumber: 'Phone Number',
  dateCreated: 'Date Created',
  createdBy: 'Created By',
  dateCompleted: 'Date Completed',
  completedBy: 'Completed By',
};

export const periodData = [
  { name: 'AM', id: 0 },
  { name: 'PM', id: 1 },
  { name: 'Any', id: 2 },
];
