import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InspectionItemType } from '../../../../types';
import StaticField from '../../../Common/Controls/StaticField';
import OtherField from '../../../Common/Controls/OtherField';
import DatePickerControl from '../../../Common/Controls/DatePickerControl';
import { Logo_Sky } from '../../../../../../constants/menu';
import { useDispatch, useSelector } from 'react-redux';
import { setInspectionApiResults } from '../../../../../../redux/slices/inspectionsSlice';
import { RootState } from '../../../../../../redux/root';

type Props = {
  inspection: InspectionItemType;
  setInspectionDate: Function;
  invalidMessage: string;
  notes: string;
  setNotes: Function;
};
const InspectionsControls: FC<Props> = ({ inspection, setInspectionDate, invalidMessage, notes, setNotes }) => {
  const contactName = `${inspection.contactFirstName} ${inspection.contactLastName}`.replace(/null null/, '');
  const [date, setDate] = useState<string>(inspection.inspectionDate);
  const dispatch = useDispatch();
  const { isApiError, apiMessage } = useSelector((s:RootState) => s.inspections);
  useEffect(() => {
    return () => {
      dispatch(setInspectionApiResults({apiMessage: undefined, isApiError: false}));
    };
  }, []);
  const setDateValue = e => {
    setDate(e);
    setInspectionDate(e);
  };
  return (
    <Controls>
      <StaticField label="Project Name:" value={inspection.projectName} />
      <StaticField label="Permit Name:" value={inspection.permitName} />
      <StaticField label="Permit Address:" value={inspection.projectAddress} />
      <StaticField label="Jurisdiction:" value={inspection.projectJurisdiction} />
      <StaticField label="Project Manager:" value={`${inspection.managerFirstName} ${inspection.managerLastName}`} />
      <StaticField label="Inspection Number:" value={`${inspection.id}`} />

      <OtherFields>
        <OtherField label="Inspection Type" value={inspection.activityTypeName} />
        <OtherField label="Inspection Status" value={inspection.statusName} />
        <OtherField invalidMessage={invalidMessage} label="Inspection Date" value={inspection.inspectionDate}>
          <DatePickerControl value={new Date(date)} onChange={setDateValue} />
        </OtherField>
        <OtherField label="Contact Name" value={contactName} />
        <OtherField label="Phone Number" value={inspection.phoneNumber} />
        <Disclaimer>
          *if you wish to provide details for a field contact and their phone number, kindly include them in the notes below. Also, please
          note that time frame requests will not be honored.
        </Disclaimer>
        <OtherField label="Notes" value="">
          <Information rows={8} value={notes} onChange={e => setNotes(e.target.value)} />
        </OtherField>
        {apiMessage && <SuccessMessage isApiError={isApiError}>{apiMessage}</SuccessMessage>}
      </OtherFields>
    </Controls>
  );
};

export default InspectionsControls;

export const SuccessMessage = styled.div<{ isApiError: boolean }>`
  color: ${({ isApiError }) => (isApiError ? 'red' : 'green')};
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;

export const Controls = styled.div`
  height: 100%;
  width: 100%;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  overflow: auto;
`;

export const OtherFields = styled.div`
  margin: 50px;
  margin-inline: 86px;
`;

export const Information = styled.textarea`
  width: 100%;
  overflow: auto;
  outline: none;
  border: none;
`;

const Disclaimer = styled.div`
  color: ${Logo_Sky};
  font-size: 12px;
  font-style: italic;
`;
