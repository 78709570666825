import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  IconButton,
  Tooltip,
  TableLoadMode,
} from '../atoms';
import TableSettingsForm from '../forms/TableSettingsForm';
import ModalDefaultDraggable from './ModalDefaultDraggable';
import {
  changeTableLoadMode,
  tableLoadModeSelector,
} from '../redux/ducks/userSettings';

const ActionPanelMore = () => {
  const dispatch = useDispatch();
  const tableLoadDataMode = useSelector(
    tableLoadModeSelector,
  );

  const [isOpen, setIsOpen] = React.useState<boolean>(
    false,
  );

  const handleSubmit = data => {
    dispatch(changeTableLoadMode(data.tableLoadMode));
    setIsOpen(false);
  };

  return (
    <Container>
      <TableLoadMode mode={tableLoadDataMode} />

      <IconButton onClick={() => setIsOpen(true)}>
        <Tooltip title="Open table settings">
          <MoreVertIcon />
        </Tooltip>
      </IconButton>

      {isOpen && (
        <ModalDefaultDraggable
          title="Table settings"
          onClose={() => setIsOpen(false)}
        >
          <TableSettingsForm
            initialValues={{
              tableLoadMode: tableLoadDataMode,
            }}
            onSubmit={handleSubmit}
          />
        </ModalDefaultDraggable>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ActionPanelMore;
