import * as React from 'react';
import { useRef, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import history from '../history';
import { setUserInfo, userInfoSelector } from '../redux/ducks/userAuth';
import DefaultUserLogo from '../resources/icons/person.svg';

import { UserDropMenu } from '../molecules';
import { TextWithDropIcon, Tooltip } from '../atoms';
import getModuleName from '../utils/getModuleName';
import { MenuItem, Select } from '@material-ui/core';
import { getCurrentUser } from '../api/userSettings';
import { RootState } from '../redux/root';
import { ROUTES } from '../constants/routes';

const UserMenu = () => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const userInfo = useSelector(userInfoSelector);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const [selectedFranchise, setSelectedFranchise] = React.useState(-1)
  
  React.useEffect(() => {
    const userInfoStr = localStorage.getItem('userInfo'); 
    const userInfo = JSON.parse(userInfoStr);
    const _selectedFranchise = userInfo.selectedFranchise || userInfo.defaultFranchiseId;
    setSelectedFranchise(_selectedFranchise)
    getCurrentUser(_selectedFranchise).then(currentUser => {  
      const user = currentUser.data; 
      dispatch(setUserInfo({...user,selectedFranchise: _selectedFranchise } ));
    })
  }, [])

  const handleChangeBranch = (e) => {
    const _userInfo = {...userInfo,selectedFranchise: e.target.value };
    localStorage.setItem('userInfo',JSON.stringify(_userInfo));
    dispatch(setUserInfo( _userInfo));
    if(/\/permit\/[0-9]+\/[0-9]+$/.test( window.location.pathname)) {
      history.push(ROUTES.DASHBOARD);
    }
   window.location.reload()
  }

  return (
    <>
      {(getModuleName() === 'EMPLOYEE MODULE MANAGEMENT') ? 
        <FranchiseName>Branch: <span>
          <Select value={selectedFranchise}
          label="Age" onChange={handleChangeBranch}>
            {userInfo.franchise?.map((branch,index) => <MenuItem key={index} value={branch?.id}>{branch?.name}</MenuItem>)}
          </Select>
          </span></FranchiseName>
      : ''}
      <TextWithDropIcon
        onClick={handleOpen}
        ref={menuRef}
        open={isOpen}
      >
        <StyledTooltip title={userInfo.email}>
          <img
            src={userInfo.avatar || DefaultUserLogo}
            alt="person_svg"
          />
        </StyledTooltip>
      </TextWithDropIcon>

      <UserDropMenu
        handleClose={handleClose}
        open={isOpen}
        anchorRef={menuRef}
      />
    </>
  );
};

const StyledTooltip = styled(Tooltip).attrs({
  placement: 'top-start',
  PopperProps: {
    popperOptions: {
      modifiers: {
        offset: {
          enabled: true,
          offset: '0px, -5px',
        },
      },
    },
  },
})`
  z-index: var(--popperZIndex);
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;
const FranchiseName = styled.div`
  span{
    text-transform: uppercase;
    margin-right:10px;
  }
`
export default UserMenu;
