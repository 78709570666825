import { api } from './api';
import { FranchiseType } from './franchise';
import { PaginatedList, PaginatedRequest } from '../types';

export const getContactTypeList = (
  params: PaginatedRequest,
) =>
  api.request<ContactTypeListType>({
    method: 'GET',
    url: '/contact-types/',
    params,
  });

export const createContactType = (data: ContactTypesType) =>
  api.request<ContactTypesType>({
    method: 'POST',
    url: '/contact-types/',
    data,
  });

export const updateContactType = (data: ContactTypesType) =>
  api.request<ContactTypesType>({
    method: 'PATCH',
    url: `/contact-types/${data.id}/`,
    data,
  });

export const deleteContactType = (id: number) =>
  api.request<ContactTypesType>({
    method: 'DELETE',
    url: `/contact-types/${id}/`,
  });

type ContactTypeListType = PaginatedList<ContactTypesType>;

export interface ContactTypesType {
  id?: number;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  isActiveStatus: string;
  franchise: number[] | string | FranchiseType[];
  isDefault?: boolean;
}
