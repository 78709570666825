import React, { FC, useEffect } from 'react';
import { ReviewsWrapper } from './CommentsSection';
import { getPermitDocuments } from '../../../../api/projectDocuments';
import { setReviewDocuments } from '../../../../redux/slices/documentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import styled from 'styled-components';
import { RootState } from '../../../../redux/root';
import { getVocabulariesDataList } from '../../../../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../../../../constants';
import { setVocabulary } from '../../../../redux/slices/generalSlice';
import { debug } from 'console';

const FilesSection: FC<{ permitId: number }> = ({ permitId }) => {
  const dispatch = useDispatch();
  const reviewDocuments = useSelector((s: RootState) => s.documents.ReviewDocuments);
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  useEffect(() => {
    const load = async () => {
      const result = await getPermitDocuments({}, `${permitId}`);
      const documents = result.data.reduce((docs, doc) => {
        if (doc.clientFiles?.length > 0) {
          docs.push(doc);
        }
        return docs;
      }, []);
      dispatch(setReviewDocuments(documents));
    };
    load();
    const loadVocabulary = async () => {
      const payload = await getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'title',
      });
      const vocabularyItems = payload.data.results;
      dispatch(setVocabulary(vocabularyItems));
    };
    loadVocabulary();
  }, []);
  const getStatusName = (status: number): string => {
    const _status = vocabulary.find(v => v.id == status);
    return _status.title;
  };

  return (
    <ReviewsWrapper>
      {reviewDocuments.map((doc, ndx) => (
        <FileNameSection key={ndx} index={ndx}>
          <EntryName>
            <span>Document Name:</span>
            <NameArea>{doc.name}</NameArea>
          </EntryName>
          <EntryName>
            {doc.clientFiles.map((item, ndx1) => {
                const hasLink = item.file?.length > 0
              return (
                <FileLink hasLink={hasLink} key={ndx1}>
                 {
                    hasLink && <a href={item.file} target="_blank">
                        <InlineAnchor><CloudDownloadIcon /><Name>{item.fileName}</Name></InlineAnchor> 
                    </a>
                 }   
                 { !hasLink && <><CloudDownloadIcon /> <Name>{item.fileName}</Name></>}
                </FileLink>
              );
            })}
          </EntryName>
          <EntryName>
            <span>status:</span>
            <Name>{getStatusName(doc.status)}</Name>
          </EntryName>
        </FileNameSection>
      ))}
    </ReviewsWrapper>
  );
};

export default FilesSection;

const InlineAnchor = styled.div`
 display: flex;
`;

const Name = styled.div`
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:55vw;
`;

const EntryName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  span {
    font-size: 13px;
  }
`;

const FileNameSection = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ index }) => (index % 2 === 0 ? 'rgb(212,212,212)' : 'rgb(239,239,239)')};
`;

const FileLink = styled.div<{ hasLink: boolean }>`
  display: flex;
  height: 40px;
  width: 50%;
  font-weight: ${({ hasLink }) => (hasLink ? 'bold' : 'inherit')};
  svg {
    margin-right: 9px;
    color: ${({ hasLink }) => (hasLink ? 'black' : '#aaaaaa')};
  }
  a {
    white-space: nowrap;
  }
`;

const NameArea = styled.div`
  font-size: 25px;
`;
