import * as React from 'react';
import styled from 'styled-components';

import { getPermitNames, getPermits } from '../api/permits';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import {
  ActionPanelMore,
  BreadCrumbs,
  InputSelect,
  SearchBar,
} from '../molecules';
import { ButtonMain } from '../atoms';
import { SelectOption } from '../types';
import {
  USER_TYPES_VALUES,
  CLIENT_TYPES_VALUES,
  CLIENT_TYPES,
} from '../constants/forms';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '240px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};

const ActionsPanelClients = ({
  onSearch,
  onAdd,
  onSelectedAdditionalParamsChange,
  onPermitChange,
}: Props) => {
  const USER_TYPE_ALL = {
    label: 'Client: All',
    value: undefined,
  };

  const [permits, setPermits] = React.useState(null);
  const [
    permitsLoading,
    setPermitsLoading,
  ] = React.useState(false);

  React.useEffect(() => {
    if (onPermitChange) {
      setPermitsLoading(true);
      Promise.all([getPermitNames()])
        .then(([permitsResponse]) => {
          const permitData = permitsResponse.data.map((permit) => {
            return {id: permit.id, name: permit.permitName}
          })
          setPermits([
            { name: 'All contacts', value: 'Clear' },
            ...permitData,
          ]);
        })
        .catch(err => console.log(err))
        .finally(() => setPermitsLoading(false));
    }
  }, []);

  const selectOptions = [
    USER_TYPE_ALL,
    ...CLIENT_TYPES.map(item => {
      if (item.value === 1) {
        return {
          label: 'Client: Individual',
          value: {
            userRole: 3,
            clientType: item.value,
          },
        };
      }

      return {
        label: 'Client: Company',
        value: {
          userRole: 3,
          clientType: item.value,
        },
      };
    }),
  ];

  return (
    <Container>
      <Part>
        <BreadCrumbs />
        {onPermitChange && (
          <InputSelect
            placeholder="Select Permit"
            options={permits}
            isSearchable
            isLoading={permitsLoading}
            isDisabled={!permits}
            styles={selectStyles}
            onChange={selected => onPermitChange(selected)}
            getOptionValue={(item: any) =>
              item.id || Math.random()
            }
            getOptionLabel={(item: any) => item.name}
          />
        )}
      </Part>
      <Part>
        {onSelectedAdditionalParamsChange && (
          <InputSelect
            placeholder=""
            options={selectOptions}
            isSearchable={false}
            styles={selectStyles}
            defaultValue={USER_TYPE_ALL}
            onChange={(selected: SelectOption) =>
              onSelectedAdditionalParamsChange(
                selected.value,
              )
            }
          />
        )}
        {onSearch && <SearchBar onChange={onSearch} />}
        {onAdd && (
          <ButtonMain onClick={onAdd}>Add</ButtonMain>
        )}
        <ActionPanelMore />
      </Part>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
`;

const Part = styled.div`
  display: flex;

  > * {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiTextField-root,
  .MuiInputBase-root {
    border-radius: 5px;
  }

  .MuiButton-root,
  .MuiInputBase-root {
    height: 30px;
  }
`;

export interface SelectedAdditionalParams {
  userRole?: USER_TYPES_VALUES;
  clientType?: CLIENT_TYPES_VALUES;
}

interface Props {
  onAdd?: () => void;
  onSearch?: (value: string) => void;
  onSelectedAdditionalParamsChange?: (
    data: SelectedAdditionalParams,
  ) => void;
  onPermitChange?: (data: object) => void;
}

export default ActionsPanelClients;
