import * as React from 'react';
import styled, {
  createGlobalStyle,
} from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { ClickAwayListener } from '@material-ui/core';

import { ButtonModalClose } from '../atoms';
import { ButtonWithLoader } from './index';
import { useReactToPrint } from 'react-to-print';
import FormsReport from '../pages/Reports/FormsReport';

const ModalFullScreen = ({
  children,
  title,
  onClose,
  className = '',
  printRef,
}: Props) => {
  const containerRef = React.useRef(null);
  return (
    <ModalContainer ref={containerRef} className={className}>
      <GlobalStyles />
          <ModalContent
          >
            <MobileClose onTouchStart={onClose}>
              <CloseWrapper>
                <CloseIcon />
              </CloseWrapper>
            </MobileClose>
            <StyledButtonModalClose onClick={onClose} />
            <StyledTitle>{title}</StyledTitle>          
            <ModalContentInner >
              <div>{children}</div>
            </ModalContentInner>
          </ModalContent>
    </ModalContainer>
  );
};

const GlobalStyles = createGlobalStyle`
  body {
    max-height: 100vh;
    overflow: hidden;
    padding-right: 17px;
    
    @media print {
      table[role="table"] {
        display: none;
      }
    }
  }
`;

const MobileClose = styled.button`
 width: 32px;
 height: 32px;
 border-radius:50%;
 z-index: 300;
 position: absolute;
 right:32px;
 top:32px;
 background-color:#fff;
 @media only screen and (min-width: 840px) {
   display:none;
 }
`;

const CloseWrapper = styled.div`  ;
zoom: 1.2;
position: relative;
left: -5px;
top: -2px;
`;
const ModalContainer = styled.div`
  position: fixed;
  z-index: var(--modalZIndex);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px 20px;
  overflow-x: hidden;
  overflow:auto;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  ${({ className }) =>
    className === 'over' && 'overflow: visible;'}
`;

const ModalContent = styled.div`
  background-color: var(--defaultBackground);
  width: 100%;
  min-width: 280px;
  max-width: 100%;
  height: 100%;
  padding: 35px;
  border-radius: 5px;
  position: absolute;
  overflow:auto;
`;

const ModalContentInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  width: 90%;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0 auto 30px auto;
`;

const StyledButtonModalClose = styled(ButtonModalClose)`
  top: 32px;
  right: 32px;

  @media print {
    display: none;
  }
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  title?: string;
  onClose: () => void;
  className?: string;
}

export default ModalFullScreen;
