import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useCallback } from 'react';
import isRoutePathExact from '../utils/isRoutePathExact';

import SidebarMenuItem from './SidebarMenuItem';

const SidebarMenuLink = ({ to, icon, title }: Props) => {
  const { pathname } = useLocation();
  const { push }: History = useHistory();

  const splitArr = to.split('/');
  const firstLevelPath = React.useRef(splitArr[splitArr.length - 1]);
  const onClick = useCallback(() => {
    push(to);
  }, [to]);

  return (
    <SidebarMenuItem
      icon={icon}
      title={title}
      onClick={onClick}
      isActive={isRoutePathExact(
        pathname,
        firstLevelPath.current,
        true,
      )}
    />
  );
};

interface Props {
  icon: React.ComponentType<SvgIconProps>;
  to: string;
  title: string;
}

export default SidebarMenuLink;
