import * as React from 'react';
import { debounce } from 'throttle-debounce';
import {
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import styled from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton } from '../atoms';

const SearchBar = ({
  text = '',
  placeholder = 'Search...',
  onChange,
  debounceDelay = 500,
}: Props) => {
  const [value, setValue] = React.useState(text);
  const handleOnChange = React.useCallback(
    debounce(debounceDelay, onChange),
    [],
  );

  React.useEffect(() => {
    if (text !== value) {
      setValue(text);
    }
  }, [text]);

  React.useEffect(() => {
    handleOnChange(value);
  }, [value]);

  return (
    <Search
      value={value}
      onChange={e => setValue(e.target.value)}
      placeholder={placeholder}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            edge="end"
            onClick={() =>
              value ? setValue('') : onChange(value)
            }
          >
            {value ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const Search = styled(OutlinedInput)`
  min-height: 30px;
  background-color: var(--defaultBackground);

  .MuiOutlinedInput-input {
    padding: 6px 14px 7px 14px;
  }
`;

interface Props {
  text?: string;
  placeholder?: string;
  debounceDelay?: number;
  onChange: (value: string) => void;
}

export default SearchBar;
