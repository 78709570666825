import * as React from 'react';
import {
  Checkbox as Box,
  CheckboxProps,
} from '@material-ui/core';

const Checkbox = ({
  color = 'primary',
  size = 'small',
  ...props
}: CheckboxProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box color={color} size={size} {...props} />
);

export default Checkbox;
