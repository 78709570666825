import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  logIn,
  clearRequestError,
} from '../redux/ducks/userAuth';
import { LogInDataType } from '../api/userAuth';
import { COMPANY_NAME, APPLICATION_NAME } from '../constants';
import LoginForm from '../organisms/LogInForm';
import AuthLayout from '../layouts/AuthLayout';
import { apiSlice } from '../redux/rtkQuery/apiSlice';

const initialFields: LogInDataType = {
  email: '',
  password: '',
};

const LogInPage = () => {
  const dispatch = useDispatch();

  const [fields, setFields] = React.useState(initialFields);

  React.useEffect(() => {
    dispatch(clearRequestError());
    dispatch(apiSlice.util.resetApiState());
  }, []);

  const handleLogIn = () => {
    dispatch(logIn(fields));
  };

  return (
    <AuthLayout
      mainText={APPLICATION_NAME}
      companyText={COMPANY_NAME}
      secondaryText="Welcome back! Please, login to your account."
    >
      <LoginForm
        fields={fields}
        setFields={setFields}
        onLogIn={handleLogIn}
      />
    </AuthLayout>
  );
};

export default LogInPage;
