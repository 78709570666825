import { snakeCase } from 'change-case';

export default obj => {
  if (typeof obj === 'object') {
    const fd = new FormData();
    Object.entries(obj).map(([key, value]) => {
      if (typeof value !== 'boolean' && !value) {
        return false;
      }
      return fd.append(snakeCase(key), value as any);
    });
    return fd;
  }
  return obj;
};
