import * as React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Chat from './Chat';
import {
  ButtonWithLoader,
  LoaderWithContainer,
} from '../../molecules';
import useUserRole from '../../hooks/useUserRole';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Switch } from '@material-ui/core';

const CommentsChat = ({
  commentsRequest,
  entityData,
  postRequest,
  permit,
  title,
  buttonText,
  isButtonHidden,
  isReadOnly,
  setCommentCreated,
  commentCreated,
  ticketViewOnly=false
}: // noFile,
Props) => {
  const { isEmployee, isInspector } = useUserRole();
  const [commentValue, setCommentValue] = React.useState(
    '',
  );
  const [isLoading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [isSystemMsg, setIsSystemMsg] = React.useState(false);

  const editorRef = React.useRef(null);

  const sortComments = (comments) => {
    const tempComments = comments;
    //sorting with full datetime
    const sortedcomments = tempComments?.map(comment => {
      return { ...comment, createdAt: new Date(comment.createdAt) }
    })
    .sort((a, b) => b.createdAt - a.createdAt);    
    //Transforming with specified  date format
    const transformedComments = sortedcomments?.map(comment => {
      return { ...comment, createdAt: (new Date(comment.createdAt).toLocaleDateString() +", "+ new Date(comment.createdAt).toLocaleTimeString([], {
        timeStyle: 'short'
      })) }      
    })
    setMessages(transformedComments);    
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSystemMsg(event.target.checked);    
  };
  
  const loadComments = () => {
    setLoading(true);
    Promise.all([commentsRequest(isSystemMsg)])
      .then(([commentsResponse]) => {
        sortComments(commentsResponse.data.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }

  React.useEffect(() => {
    loadComments();
  },[isSystemMsg])

  React.useEffect(() => {
    loadComments();
  }, []);

  React.useEffect(() => {
    if (commentCreated) {
      loadComments();
    }
  }, [commentCreated]);

  React.useEffect(() => {
    if (
      /\S/.test(
        editorRef?.current?.getEditor()?.getText() &&
          isError,
      )
    ) {
      setError('');
    }
  }, [commentValue]);

  const sendComment = () => {
    if (
      /\S/.test(editorRef?.current?.getEditor()?.getText())
    ) {
      Promise.all([
        postRequest({
          text: commentValue,
          permit,
          id: entityData?.id || entityData?.pk,
        })
          .then(response => {
            setCommentCreated(response.data);
          })
          .catch(() => {})
          .finally(() => {
            setCommentValue('');
          }),
      ]);
    } else {
      setError('Text is required for Comment');
    }
  };

  return (
    <>
      <Title>
        {title}
        <ToggleComment>          
          <Switch color='primary' checked={isSystemMsg} onChange={handleSwitchChange} /> 
          Show Audit Comments
        </ToggleComment>
      </Title>
      <CommentsWrapper>
        {isLoading && <LoaderWithContainer />}
        {!isLoading && <Chat messages={messages} />}        
        {(isEmployee || isInspector) && (
          <EditorWrapper>
            <ReactQuill
              theme="snow"
              modules={{
                toolbar: [
                  [{ size: [] }, { align: [] }],
                  ['bold', 'italic', 'underline'],
                  [{ color: [] }, { background: [] }],
                  ['image'],
                ],
              }}
              ref={editorRef}
              readOnly={isReadOnly}
              value={commentValue}
              onChange={comment => setCommentValue(comment)}
            />
          </EditorWrapper>
        )}
        {!!isError && <Error>{isError}</Error>}
        {!isButtonHidden && (
        <ButtonWrapper>
          <ButtonWithLoader
            disabled={!!isError || isReadOnly || ticketViewOnly}
            loading={false}
            type="button"
            onClick={sendComment}
          >
            {buttonText}
          </ButtonWithLoader>
        </ButtonWrapper>
        )}
      </CommentsWrapper>
    </>
  );
};

interface Props {
  commentsRequest: (isSystemMsg?: boolean) => Promise<any>;
  entityData: any;
  postRequest: (data: any) => Promise<any>;
  // eslint-disable-next-line react/no-unused-prop-types
  noFile?: boolean;
  permit?: number;
  title: string;
  buttonText: string;
  isButtonHidden?: boolean;
  isReadOnly?: boolean;
  commentCreated?: boolean;
  setCommentCreated?: any;
  ticketViewOnly: boolean;
}

const CommentsWrapper = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  @media only screen and (max-width: 840px) {
      width: 75vw !important
  }
  .editor-wrapper {
    padding-top: 20px;
    max-width: 696px;
  }
  .comments-editor,
  .comments-toolbar {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  .comments-editor {
    height: 200px;
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 16px;
  display: flex;
  width: 280px;
  justify-content: center;
`;

const Title = styled.h2`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
`;

const EditorWrapper = styled.div`
  .quill {
    margin-top: 20px;
  }
  .ql-container {
    height: 200px;
    max-width: 696px;
  }
`;

const ToggleComment = styled.div`
  float:right;
  font-size: 16px;
`;

export default CommentsChat;
