import * as React from 'react';
import styled from 'styled-components';

import {
  getDailyInspectionReport,
  ReportTableType,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const DailyInspectionReport = React.forwardRef(
  (
    {
      fromDate,
      toDate,
      inspector,
      setIsValidForExport,
    }: ReportTableType,
    ref,
  ) => {
    const [
      isNullInspections,
      setIsNullInspections,
    ] = React.useState(false);
    const [
      inspectorName,
      setInspectorName,
    ] = React.useState('');
    const [lastComment, setLastComment] = React.useState(
      null,
    );
    const [inspectionCount, setInspectionCount] = React.useState(null)

    const data = {
      fromDate,
      toDate,
      inspector: inspector.id,
    };

    const getModifiedDailyInspectionReport = () =>
      Promise.all([getDailyInspectionReport(data)])
        .then(([reports]) => {         
          if (reports.data?.data === 0) {
            setIsNullInspections(true);
            setIsValidForExport(false);
          } else {
            setIsNullInspections(false);
            setIsValidForExport(true);
            setInspectorName(reports.data?.data[0].inspectorName);
            setInspectionCount(reports.data?.count)
            const commentData = reports.data?.data.map(el => {
              return {
                id: el.id,
                lastComment: el.lastComment,
              };
            });
            
            const informationData = reports.data?.data.map(
              el => ({
                id: el?.id,
                information: el?.information,
              }),
            );

            const recordData = reports.data?.data.map(
              el => ({
                id: el.id,
                name:  `${el?.projectName} | ${el?.permitName}`,
                address: el?.projectAddress
              }),
            );
            
            setLastComment({
              commentData,
              informationData,
              recordData,
              isDailyInspection: true,
            });
          }
          return {
            ...reports,
            data: {
              ...reports.data?.data,
              results: reports.data?.data.map(el => {
                delete(el['projectAddress']);
                delete(el['inspectionDate']);
                return {
                  ...el,
                  period: el.period === 0 ? 'AM' : 'PM',
                  // projectNamePermitName: `${el.projectName} | ${el.permitName}`,
                };
              }),
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});

    const columns = column => {
      if (
        [
          'franchiseName',
          'inspectorName',
          'lastComment',
          'projectName',
          'permitName',
          'information'
        ].includes(column)
      ) {
        return null;
      }

      switch (column) {
        case 'contactDetails':
          return 'Contact Details';
        case 'contactName':
          return 'Contact';
        case 'id':
          return 'Inspection ID';
        case 'inspectionDate':
          return 'Inspection Date';
        case 'inspectionTypeName':
          return 'Inspection Type';
        case 'jurisdiction':
          return 'Jurisdiction';
        case 'permitNumber':
          return 'Permit Number';
        case 'projectAddress':
          return 'Project Address';
        case 'projectManager':
          return 'Project Manager';
        case 'period':
          return 'AM/PM';
        case 'projectNamePermitName':
          return 'Project Name | Permit Name';

        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
      'projectNamePermitName',
      'permitNumber',
      'projectAddress',
      'id',
      'inspectionTypeName',
      'projectManager',
      'jurisdiction',
      'inspectionDate',
      'period',
      'contactName',
      'contactDetails',
    ];

    if (isNullInspections) {
      return (
        <InfoText>
          No project was found with selected date or
          Inspector.
        </InfoText>
      );
    }

    return (
      <Wrapper ref={ref}>
        <Title>Daily Inspection Report</Title>

        <List>
        <li>
            <Bold>Inspector: </Bold> {inspectorName}
          </li>
          <li>
            <Bold>Inspection Date: </Bold>
            {formatDateTime(fromDate, "MM/dd/yyy")}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
          <li>
            <Bold>Total Inspections: </Bold>{inspectionCount}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedDailyInspectionReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalRow={lastComment}
          smallPrintHeader={true}
        />
      </Wrapper>
    );
  },
);


const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

export default DailyInspectionReport;
