import * as React from 'react';
import styled from 'styled-components';

import {
    getInspectionPermitReport,
} from '../../api/reports';

import { DATETIME_FORMAT } from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Wrapper from './print-mixin';

const InspectionReportPermit = React.forwardRef(
  (
    { permitNum, setIsValidForExport }: Props,
    ref, 
  ) => {
    const [
      isNullInspections,
      setIsNullInspections,
    ] = React.useState(false);
    const [totalInspections, setTotalInspections] = React.useState(0)
    const [
      projectName,
      setProjectName,
    ] = React.useState('');
    const [
      permitName,
      setPermitName,
    ] = React.useState('');

    const data = {
      permit_number: permitNum
    };

    const getModifiedInspectionPermitReport = () =>
      Promise.all([getInspectionPermitReport(data)])
        .then(([reports]) => {
          if (reports.data.permitData?.length === 0) {
            setIsNullInspections(true);
            setIsValidForExport(false);
          } else {
            setIsNullInspections(false);
            setIsValidForExport(true);
            setProjectName(reports.data.permitData.projectName);
            setPermitName(reports.data.permitData.name);
            setTotalInspections(reports.data.inspectionData.length)            
          }
          const tableData = reports.data.inspectionData.map((el) => {
            return {
              inspectionName: el.inspectionType.name ? el.inspectionType.name : "",
              inspectionNumber: el.id,
              inspectionStatus: el.status.name ? el.status.name : "",
              inspectionDate: el.inspectionDate ? el.inspectionDate : "",
            }
          })
          
          return {
            ...reports,
            data: {
              ...reports.data,
              results: tableData
            },
          };
        })
        .catch(err => console.log(err))
        .finally(() => {});

    const columns = column => {
      if (
        [
          'franchiseName',
          'inspectorName',
          'lastComment',
          'projectName',
          'permitName',
        ].includes(column)
      ) {
        return null;
      }

      switch (column) {
        case 'inspectionName':
          return 'Inspection Name';
        case 'inspectionNumber':
          return 'Inspection Number';
        case 'inspectionStatus':
          return 'Inspection Status';
        case 'inspectionDate':
          return 'Inspection Date';
        
        default:
          return tableColumnsFormatter(column);
      }
    };

    const columnsOrder = [
      'inspectionName',
      'inspectionNumber',
      'inspectionStatus',
      'inspectionDate'
    ];

    if (isNullInspections) {
      return (
        <InfoText>
          No inspections were found with the searched permit number.
        </InfoText>
      );
    }

    const additionalBottomData = [{
      title: "Total Inspections",
      value: totalInspections
    }]

    return (
      <Wrapper ref={ref}>
        <Title>Inspection Report By Permit</Title>

        <List>
          <li>
            <Bold>Permit Number: </Bold>
            {permitNum}
          </li>
          <li>
            <Bold>Project Name: </Bold> {projectName}
          </li>
          <li>
            <Bold>Permit Name: </Bold> {permitName}
          </li>
          <li>
            <Bold>Report Date: </Bold>
            {formatDateTime(new Date(), DATETIME_FORMAT)}
          </li>
        </List>

        <Table
          customSearch
          columnsOrder={columnsOrder}
          columns={columns}
          rows={getModifiedInspectionPermitReport}
          onEdit={null}
          onDelete={null}
          onConfigure={null}
          additionalActions={null}
          additionalBottomRow={additionalBottomData}
        />
      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

interface Props {
    permitNumber: string;
    setIsValidForExport: (boolean) => void;
  }

export default InspectionReportPermit;
