import * as React from 'react';
import styled from 'styled-components';
import {
  DEFAULT_DATE_FORMAT,
} from '../../constants';

import Table from '../../organisms/Table';

import { formatDateTime } from '../../utils/formatDate';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { getReviewerComments } from '../../api/projectDocuments';
import Wrapper from './print-mixin';

const ReviewCommentLetter = React.forwardRef(
  ({ reviews }: Props, ref) => {
    const [reviewComments, setReviewComments] = React.useState(null)

    React.useEffect(() => {
      const promises = []
      reviews?.map(review => promises.push(getReviewerComments(review.id)))
      Promise.all(promises).then(response => {
        const arr = response.map(res => res.data)                
        setReviewComments(arr)
      })
    }, [reviews])

    const PrintableTable = ({ data }) => {
      return (
        <TableWrapper>
          <tr>
            <StyledTH>#</StyledTH>
            <StyledTH>Comments</StyledTH>
            <StyledTH>Code</StyledTH>
          </tr>
          {data?.map(({ id, reviewerComment, reference }, idx) => (
            <tr>
              <StyledTD style={{width: '10%'}}>{idx + 1}</StyledTD>
              <StyledTD style={{width: '65%'}}>{reviewerComment}</StyledTD>
              <StyledTD style={{width: '25%'}}>{reference}</StyledTD>
            </tr>
          ))}
        </TableWrapper>
      );
    };
    
    const marginTop="50px"
const marginRight="5px"
const marginBottom="10px"
const marginLeft="5px"
const getPageMargins = () => {
  return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
};

    return (
      <Wrapper ref={ref}>
        <HeadWrapper>
        <h1>ELITE PERMITS of {reviews && reviews[0]?.franchiseName}</h1>
            <h3>Exceptional Service For All Your Permitting Needs</h3>
            <p>Permitting | Private Provider Review and Inspections | Building Code Consultants</p>
            <hr />
            </HeadWrapper>
        <Title>Review Comments</Title>

        <List>
          <li>
            <Bold>Project Name: </Bold>
            {reviews && reviews[0]?.projectName}
          </li>
          <li>
            <Bold>Permit Name: </Bold>
            {reviews && reviews[0]?.permitName}
          </li>
          <li>
            <Bold>Project Location: </Bold>
            {reviews && reviews[0]?.projectAddress}
          </li>
          <li>
            <Bold>Date: </Bold>
            {formatDateTime(new Date(), DEFAULT_DATE_FORMAT)}
          </li>
        </List>
        <p>Please make all applicable changes to the permit set and provide (1) letter responding to the  comments to expedite the review process. For electronic permitting we will need one (1) electronically sealed set of all applicable construction documents. </p>
         {reviewComments && reviewComments?.map((item, idx) => {
          if(item?.length) {
            return <>
            <List>
          <li>
            <Bold>{reviews[idx]?.reviewType} Review</Bold>
          </li>
          <li>
          <Bold>Reviewed By: {reviews[idx]?.inspectorFirstName} {reviews[idx]?.inspectorLastName}</Bold>
          </li>
          <li>
          <Bold>Office Phone: {reviews[idx]?.franchisePhoneNumber}</Bold>
          </li>
          <li>
          <Bold>Email: {reviews[idx]?.inspectorEmail}</Bold>
          </li>
          </List>
            <PrintableTable data={item} />
          </>
          }
          return null;
         })}
      </Wrapper>
    );
  },
);

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bold = styled.span`
  color: black;
  font-weight: bold;
`;

const Title = styled.h1`
  margin: 0;
  text-align: center;
`;

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
`;

const TableWrapper = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;
const StyledTD = styled.td`
  border: 1px solid #030303;
  text-align: left;
  padding: 8px;
  width: 33.33%;
`;
const StyledTH = styled.th`
  border: 1px solid #030303;
  text-align: left;
  padding: 8px;
`;

interface Props {
  jurisdiction: object;
  setIsValidForExport: (boolean) => void;
}

export default ReviewCommentLetter;
