import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageIndex } from '../../redux/slices/paginationSlice';
import styled from 'styled-components';
import SearchInput from './SearchInput';
import { Pagination } from '../../modules/clientModule/components/Common/Table/types';
import PaginationMobile from '../../pages/Responsive/PaginationMobile';

const TableHeaders = ({ headers, rows, isLoading, pristine, children }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);
  useEffect(() => {
    const checkScrollbar = () => {
      if (ref.current) {
        const hasVerticalScrollbar = ref.current.scrollHeight > ref.current.clientHeight;
        setIsScrollVisible(hasVerticalScrollbar);
      }
    };

    checkScrollbar();
    window.addEventListener('resize', checkScrollbar);
    return () => {
      dispatch(setPageIndex(0));
      window.removeEventListener('resize', checkScrollbar);
    };
  }, []);

  return (
    <>
      <TableWrapper>
        <>
          <HeadersRow count={headers.length}>
            {headers.map((header, index) => {
              return <HeaderCell key={index}>{header.label}</HeaderCell>;
            })}
          </HeadersRow>
          <SearchRow count={headers.length} isScrollVisible={isScrollVisible}>
            {headers.map((search, index) => {
              if (search.name) {
                return <SearchInput key={index} name={search.name} />;
              }
              return null;
            })}
          </SearchRow>
        </>
        {!pristine && rows.length === 0 && <NoRecords>No matching records found</NoRecords>}
        <RowScroller ref={ref}>{children}</RowScroller>
      </TableWrapper>
      {rows.length > 0 && (
        <Pagination>
          <PaginationMobile showEverywhere={true} />
        </Pagination>
      )}
    </>
  );
};

export default TableHeaders;

const cellWidth = '130px';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: fit-content;
  height: 90%;
`;

const HeadersRow = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: ${({ count }) => `repeat(${count},1fr)`};
  grid-gap: 15px;
  background-color: #fcf9fc;
`;

const SearchRow = styled.div<{ count: number; isScrollVisible: boolean }>`
  display: grid;
  grid-template-columns: ${({ count }) => `repeat(${count},1fr)`};
  grid-gap: 15px;
  padding-right: ${({ isScrollVisible }) => (isScrollVisible ? '21px' : '0')};
`;

export const SingleRow = styled.div<{ count: number; isEmergency: boolean }>`
  display: grid;
  grid-template-columns: ${({ count }) => `repeat(${count},1fr)`};
  grid-gap: 15px;
  padding: 3px;
  border-bottom: 1px solid var(--border);
  background-color: ${({ isEmergency }) => (isEmergency ? 'rgb(250, 181, 168)' : '#fcf9fc')};
`;

const HeaderCell = styled.div`
  width: ${cellWidth};
  align-content: center;
  font-size: 0.875rem;
  font-weight: bold;
  color: #949494;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;
export const DataCell = styled.div`
  overflow: hidden;
  padding-top: 16px;
  width: ${cellWidth};
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

export const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-content: space-around;
  align-items: center;
`;

const NoRecords = styled.div`
  color: var(--secondaryText);
  width: 100%;
  text-align: center;
  padding: 17px;
`;

const RowScroller = styled.div`
  height: 78vh;
  overflow-y: auto;
`;

export const RowWrapper = styled.div<{ index }>`
  //background-color: ${({ index }) => (index % 2 === 0 ? '#A4C7ED' : '#ffffff')};
  color: #000;
  padding: 3px;
`;
