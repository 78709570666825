import React, { FC } from 'react';
import styled from 'styled-components';
import { Logo_Blue, Logo_Sky } from '../../../../../constants/menu';

const DahsboardPanel: FC<{ caption: string }> = ({ caption, children }) => {
  return (
    <PanelWrapper>
      <PanelHeader>{caption}</PanelHeader>
      <Card>{children}</Card>
    </PanelWrapper>
  );
};

export default DahsboardPanel;

const PanelWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PanelHeader = styled.div`
  font-size: 26px;
  padding-left: 20px;
  color: ${Logo_Sky};
`;

export const Card = styled.div`
  border: 2px solid #acacac;
  border-radius: 6px;
  width: 100%;
  height: 30vh;
  box-shadow: 7px 7px 13px darkgrey;
`;
