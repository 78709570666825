import * as React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Visibility } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';

import theme from '../theme';

const PasswordInput = ({
  id,
  value,
  onChange,
  isValid = true,
  helperText,
  className = '',
  label = 'Password',
}: Props) => {
  const [showPassword, setShowPassword] = React.useState(
    false,
  );

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container
      variant="outlined"
      error={!isValid}
      className={className}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <PasswordField
        id={id}
        onChange={onChange}
        value={value}
        type={showPassword ? 'text' : 'password'}
        isValid={isValid}
        showPassword={showPassword}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleShowPasswordClick}
              edge="end"
            >
              <Visibility />
            </IconButton>
          </InputAdornment>
        }
      />
      <StyledFormHelperText error={!isValid}>
        {!isValid && helperText}
      </StyledFormHelperText>
    </Container>
  );
};

interface Props {
  id: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  isValid?: boolean;
  helperText?: string | string[];
  className?: string;
  label?: string;
}

interface InputProps {
  isValid: boolean;
  showPassword: boolean;
}

const Input = withStyles({
  root: {
    borderRadius: 1,
    backgroundColor: theme.defaultBackground,
  },
})(OutlinedInput);

const Container = styled(FormControl)`
  .MuiInputLabel-animated {
    transition: none;
    animation: none;
  }

  .MuiFormLabel-root {
    &.Mui-focused,
    &.MuiFormLabel-filled {
      display: none;
    }
  }
`;

const PasswordField = styled(
  ({ isValid, showPassword, ...rest }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Input {...rest} />
  ),
)<InputProps>`
  .MuiInputAdornment-positionEnd {
    margin-left: -48px;
  }

  svg {
    fill: ${props =>
      props.showPassword
        ? props.theme.primary
        : props.theme.border};
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  position: absolute;
  margin: 0;
  bottom: -18px;
  left: 14px;
`;

export default PasswordInput;
