import styled from 'styled-components';
import { Button } from '@material-ui/core';

const ButtonSecondary = styled(Button)`
  background: transparent;
  border: 1px solid var(--disabledButton);
  border-radius: 1px;
  padding: 10px 30px;
  text-transform: capitalize;
  font-size: 16px;

  &:hover {
    box-shadow: none;
  }
`;

export default ButtonSecondary;
