import React from 'react';
import styled from 'styled-components';

const ReportRequiredMessage = ({showReportError}) => {
    return (
        <>
        { showReportError && <Wrapper>
            Please upload a report in order to continue
        </Wrapper>}
        </>
        
    );
};

export default ReportRequiredMessage;

const Wrapper = styled.div`
  color: red;
  width: 100%;
  text-align: center;
  font-size: 12px;
`;