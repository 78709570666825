import React, { useEffect, useState } from 'react';
import ClientContainer from '../../ClientContainer';
import GridArea from './GridArea';
import NewProject from './NewProject/NewProject';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import ProjectCreatedModal from './NewProject/ProjectCreatedModal';
import { useLocation } from 'react-router';
import { setShowDrawerContent } from '../../../../../redux/slices/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

const Dashboard = () => {  
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const info = useSelector((s:RootState) => s.userAuth.info);
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const NEW_PROJECT = 'newProject';
  const newProject = params.get(NEW_PROJECT);
  useEffect(() => {
    if (newProject) {
      const updatedUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({ path: updatedUrl }, '', updatedUrl);
      dispatch(setShowDrawerContent(true));
    }
  }, [newProject]);
  return (
    <div>
      <ClientContainer topCaption={`Welcome to Elite Permits, ${info.firstName}! Explore your personal dashboard`}>
        <BannerStrip label="DASHBOARD" />          

        <WrapperWithRightDrawer>
          <GridArea />
          <Drawer>
            <NewProject setShowModal={setShowModal} />
          </Drawer>
        </WrapperWithRightDrawer>
      </ClientContainer>
      <ProjectCreatedModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Dashboard;
