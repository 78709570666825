import * as React from 'react';
import {
  getFormsReport,
  FormsReportType,
} from '../../api/reports';
import { LoaderWithContainer } from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import FormsReportList from '../../organisms/FormsReportList';
import { clearRequestError, requestApiErrorObj, setRequestError } from '../../redux/ducks/requestApiError';
import { dispatch } from '../../redux/store';

const FormsReport = React.forwardRef(
  (
    {
      formsDate,
      inspector,
      setSelectedFormsReports,
      selectedFormsReports,
      setIsValidForExport,
      forms,
      setForms
    }: Props,
    ref,
  ) => {
    const [isLoading, setIsLoading] = React.useState(false);
    
    
    const [errMsg, setErrMsg] = React.useState(false)

    React.useEffect(() => {
      if (formsDate && inspector) {
        setIsLoading(true);
        getFormsReport({
          date: formsDate,
          inspector,
        })
          .then(res => {
            if (!res.data.length) {
              setIsValidForExport(false);
            } else {
              setIsValidForExport(true);
            }
            // const newArr = []
            // const newArr2 = []
            // res?.data?.forEach(form => {
            //   if(!newArr.includes(form.jurisdiction)) {
            //     newArr2.push(form)
            //     newArr.push(form.jurisdiction)
            //   }
            // })
            
            // setForms(newArr2);
            setForms(res.data);
            setSelectedFormsReports(
              res.data?.map(el => el?.id),
            );
          })
          .catch(err => {
            if(err && err.response && err.response.status === 500) {
              dispatch(clearRequestError());
              setErrMsg(true)              
            }
          })
          .finally(() => setIsLoading(false));
      }
    }, [formsDate, inspector]);
    if (isLoading) {
      return <LoaderWithContainer />;
    }
    if(errMsg) {
      return (
        <div style={{height: '100vh'}}>
          <ModalInfo isError={true} errorMsg="Inspection Form is not configured for this jurisdiction. Please have your administrator configure this in admin settings." />
        </div>
      )
    }
    
    return (
      <FormsReportList
        ref={ref}
        forms={forms}
        setSelectedFormsReports={setSelectedFormsReports}
        selectedFormsReports={selectedFormsReports}
        />
    );
  },
);

interface Props {
  formsDate: string;
  inspector: number;
  setSelectedFormsReports: (data: any) => void;
  selectedFormsReports: Array<FormsReportType>;
  setIsValidForExport: () => void;
}

export default FormsReport;
