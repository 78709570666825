import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Logo_Sky } from '../../../../constants/menu';

const CommentsTable = ({comments}) => {
    return (
        <MassageTable>
        <Rows>
          {comments.map((item, index) => {
            return (
              <Row key={index}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </Row>
            );
          })}
        </Rows>
      </MassageTable>
    );
};

export default CommentsTable;

const MassageTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 105px;
`;

const Rows = styled.div`
  max-height: 100px;
  overflow: auto;
  border: 1px solid grey;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 100px 150px 1fr;
  color: ${Logo_Sky};
  font-size:14px;
`;

const HeaderCell = styled.div`
  font-weight: bold;
`;

const Cell = styled.div`
  font-size: inherit;
`;

const LineEntry = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 4fr 1fr;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Value = styled.div`
  font-weight: inherit;
`;