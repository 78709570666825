import React from 'react';
import SwitchCheckbox from '../../../../../modules/clientModule/components/Common/Controls/SwitchCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { setShowAllPendingInspections } from '../../../../../redux/slices/inspectionsSlice';
import { changeTableLoadMode } from '../../../../../redux/ducks/userSettings';
import { TABLE_LOAD_DATA_MODE } from '../../../../../constants/settings';

const ShowAllInspectionSwitch = ({disabled}) => {
    const showAllPendingInspections = useSelector((s:RootState) => s.inspections.showAllPendingInspections);
    const dispatch = useDispatch();

    const onChange = e => {
       dispatch(changeTableLoadMode(TABLE_LOAD_DATA_MODE.ALL));
        dispatch(setShowAllPendingInspections(!showAllPendingInspections));
    }
    return (
        <div>
            <SwitchCheckbox
            isRadio={false}
            label="Show All Pending Inspections"
            disabled={disabled}
            checked={showAllPendingInspections}
            name="moshe"
            value="moshe"
            title=""
            onChange={(e)=> onChange(e)}
          />
          </div>
    );
};

export default ShowAllInspectionSwitch;