/* eslint-disable no-nested-ternary */
import * as React from 'react';
import styled from 'styled-components';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import openInNewTab from '../../utils/openInNewTab';
import useTablePage from '../../hooks/useTablePage';
import {
  getDocumentList,
  createDocument,
  updateDocument,
  deleteDocument,
  DocumentType,
} from '../../api/document';
import DocumentForm from '../../forms/DocumentForm';
import { convertTableBoolean } from '../../utils/convertTableValues';
import { getJurisdictions } from '../../api/jurisdiction';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import Table from '../../organisms/Table';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useUserRole from '../../hooks/useUserRole';
import { Loader } from '../../atoms';

const modifiedGetDocumentsRequest = params =>
  getDocumentList(params).then(response => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results.map(item => {
        if (item.fromEpsuperadmin) {
          return {
            ...item,
            canOnlyEdit: true,
          };
        }

        return item;
      }),
    },
  }));

const DocumentsPage = () => {
  const {
    setSearch,
    handleAdd,
    search,
    tableManuallyUpdate,
    isModalOpen,
    values,
    handleEdit,
    handleDelete,
    errors,
    createOrUpdate,
    selectedItem,
    deleteById,
    handleCloseModal,
    handleCloseModalInfo,
    setSelectedItem,
    modalError,
    modalSuccess,
    modalTitle,
    modalErrorTitle,
  } = useTablePage<DocumentType>({
    updateEndpoint: updateDocument,
    deleteEndpoint: deleteDocument,
    createEndpoint: createDocument,
    modalCreateTitle: 'Add Document',
    modalUpdateTitle: 'Update Document',
  });

  const [
    jurisdictionsData,
    setJurisdictionsData,
  ] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getJurisdictions({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
    ])
      .then(([jurisdictionsResponse]) => {
        setJurisdictionsData(
          jurisdictionsResponse.data.results,
        );
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'isDeleted',
        'documentFile',
        'building',
        'work',
        'jurisdiction',
        'franchise',
        'franchiseNames',
        'fromEpsuperadmin',
        'canOnlyEdit',
        'forms',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'requirement':
        return 'Document Name';
      case 'visibleFilename':
        return 'File name';
      case 'jurisdictionNames':
        return 'Jurisdiction';
      case 'buildingName':
        return 'Building';
      case 'workName':
        return 'Work';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const cellClassNames = [
    {
      column: 'visibleFilename',
      className: 'link-cell',
    },
  ];

  const handleCellClick = rowValue => {
    if (rowValue.documentFile) {
      openInNewTab(rowValue.documentFile);
    }
  };

  const cellCallbackFnIsOn = ['visibleFilename'];

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={
          isSuperAdmin
            ? handleAdd
            : isFranchiseAdmin && jurisdictionsData.length
            ? handleAdd
            : null
        }
      />

      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={
            isSuperAdmin
              ? getDocumentList
              : modifiedGetDocumentsRequest
          }
          onEdit={
            isSuperAdmin
              ? handleEdit
              : isFranchiseAdmin && jurisdictionsData.length
              ? handleEdit
              : null
          }
          onDelete={
            isSuperAdmin
              ? handleDelete
              : isFranchiseAdmin && jurisdictionsData.length
              ? handleDelete
              : null
          }
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
          handleCellClick={handleCellClick}
          cellCallbackFnIsOn={cellCallbackFnIsOn}
          cellClassName={cellClassNames}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <DocumentForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.requirement}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DocumentsPage;
