import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  ButtonWithLoader,
} from '../molecules';
import { ReportType } from '../api/reportSetup';
import { FormServerError, SelectOption } from '../types';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import {
  getFranchiseList,
  FranchiseType,
} from '../api/franchise';
import { REPORTS_NAMES_DATA } from '../__DATA__';

import { GET_ALL_QUERY_PARAMS } from '../constants';
import { USER_TYPES_REPORT_SETUP } from '../constants/forms';

const labels = {
  name: 'Report',
  description: 'Description',
  franchise: 'Branch',
  userModule: 'User Module',
};

const schema = yup.object().shape({
  name: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select report from the list')
    .label(labels.name),
  description: yup.string().label(labels.description),
  franchise: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a franchise from the list')
    .label(labels.franchise),
  userModule: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a user module from the list')
    .label(labels.userModule),
});

const ReportSetupForm = ({
  onSubmit,
  modalError,
  initialValues,
  addButtonText = 'Add new item',
  updateButtonText = 'Update',
  initialErrors,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    formState,
  } = useForm<ReportType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as any),
  });

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [franchiseData, setFranchiseData] = React.useState(
    [],
  );

  const [userRoleData, setUserRoleData] = React.useState(
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getFranchiseList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
    ])
      .then(([franchisesResponse]) => {
        setFranchiseData(franchisesResponse.data.results);

        setUserRoleData(USER_TYPES_REPORT_SETUP);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (
      initialValues?.id &&
      franchiseData.length &&
      userRoleData.length
    ) {
      setValue(
        'franchise',
        franchiseData.filter(item =>
          initialValues.franchise.includes(item.id)),
      );
      setValue(
        'userModule',
        userRoleData.filter(
          item => initialValues.userModule === item.value,
        )[0],
      );
      setValue(
        'name',
        REPORTS_NAMES_DATA.filter(
          item => initialValues.name === item.value,
        )[0],
      );
    }
  }, [franchiseData, userRoleData]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<ReportType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: ReportType) => void,
  ) => (data: ReportType) =>
    submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      franchise: (data.franchise as FranchiseType[])
        .filter(item => item.id)
        .map(item => item.id),
      userModule: (data?.userModule as SelectOption)?.value,
      name: (data?.name as SelectOption)?.value,
    });

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder="Select report"
                isSearchable={false}
                error={errors.name}
                label={labels.name}
                options={REPORTS_NAMES_DATA}
                isRequired={isYupFieldRequired(
                  'name',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="description"
            error={errors.description}
            inputRef={register}
            label={labels.description}
            isRequired={isYupFieldRequired(
              'description',
              schema,
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="franchise"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                selectAllActive
                isMulti
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.franchise}
                error={errors.franchise}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'franchise',
                  schema,
                )}
                options={franchiseData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="userModule"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.userModule}
                error={errors.userModule}
                isRequired={isYupFieldRequired(
                  'userModule',
                  schema,
                )}
                options={userRoleData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: ReportType;
  onSubmit: (data: ReportType) => void;
  initialErrors?: FormServerError<ReportType>;
  modalError: boolean;
}

export default ReportSetupForm;
