import { createGlobalStyle, css } from 'styled-components';

import { fontFamily } from './theme';

const GlobalStyles = createGlobalStyle`
  :root {
    ${({ theme }) => css`
      --linkText: ${theme.linkText};
      --mainText: ${theme.mainText};
      --secondaryText: ${theme.secondaryText};
      --primary: ${theme.primary};
      --secondary: ${theme.secondary};
      --border: ${theme.border};
      --defaultBackground: ${theme.defaultBackground};
      --defaultTableCell: ${theme.defaultTableCell};
      --defaultShadow: ${theme.defaultShadow};
      --disabledButton: ${theme.disabledButton};
      --defaultTableCellTitle: ${theme.defaultTableCellTitle};
      --mainRequired: ${theme.mainRequired};
      --marker1: ${theme.marker1};
      --marker2: ${theme.marker2};
      --marker3: ${theme.marker3};
      --marker4: ${theme.marker4};
      --marker5: ${theme.marker5};
      --marker6: ${theme.marker6};

      --initialZIndex: 1;
      --popperZIndex: 5;
      --modalZIndex: 10;
    `}
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    color: var(--mainText);
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: ${fontFamily};
  }
  
  a {
    display: inline;
    color: var(--primary);
    text-decoration-color: var(--primary);
    transition: all 0.15s ease-in-out;

    &:hover, &:focus, &:active  {
      color: var(--linkText);
      text-decoration-color: transparent;
    }
  }

  input {
    &::-ms-reveal, ::-ms-clear {
      display: none;
    }
  }
  
  input, select, textarea {
    &[readonly] {
      color: var(--secondaryText);
      background-color: var(--border);
    }
  }

  button {
    cursor: pointer;
  }
`;

export default GlobalStyles;
