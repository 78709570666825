import { combineReducers } from '@reduxjs/toolkit';
import { fork } from 'redux-saga/effects';

import userAuth, { userAuthSaga } from './ducks/userAuth';
import userSettings from './ducks/userSettings';
import franchiseSettings from './ducks/franchiseSettings';
import requestApiError from './ducks/requestApiError';
import { paginationSlice } from './slices/paginationSlice';
import { meSlice } from './slices/meSlice';
import { inspectionsSlice } from './slices/inspectionsSlice';
import { filterSlice } from './slices/filterSlice';
import { clientSlice } from './slices/clientSlice';
import { generalSlice } from './slices/generalSlice';
import { projectSlice } from './slices/projectSlice';
import { documentsSlice } from './slices/documentsSlice';
import { allActivitiesSlice } from './slices/allActivitiesSlice';
import { conditionsSlice } from './slices/conditionsSlice';
import { feesSlice } from './slices/feesSlice';
import { ticketsSlice } from './slices/ticketsSlice';
import { employeeActivitiesSlice } from './slices/employeeActivitySlice';
import { globalSearchSlice } from './slices/globalSearch';
import { dashboardSlice } from './slices/dashboardSlice';
import { inspectionsReportSlice } from './slices/InspectionsReportSlice';
import { apiSlice } from './rtkQuery/apiSlice';

const rootReducer = combineReducers({
  requestApiError,
  userAuth,
  userSettings,
  franchiseSettings,
  pagination: paginationSlice.reducer,
  me: meSlice.reducer,
  inspections: inspectionsSlice.reducer,
  inspectionReports: inspectionsReportSlice.reducer,
  filters: filterSlice.reducer,
  client: clientSlice.reducer,
  general: generalSlice.reducer,
  projectsSlice: projectSlice.reducer,
  allActivities: allActivitiesSlice.reducer,
  documents: documentsSlice.reducer,
  conditions: conditionsSlice.reducer,
  fees: feesSlice.reducer,
  tickets: ticketsSlice.reducer,
  employeeActivities: employeeActivitiesSlice.reducer,
  globalSearch: globalSearchSlice.reducer,
  dashboard: dashboardSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield fork(userAuthSaga);
}

export default rootReducer;
