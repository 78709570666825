import * as React from 'react';
import styled from 'styled-components';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Paper, SelectInput } from '../atoms';
import { FranchiseType } from '../api/franchise';

const CurrentFranchiseSelector = ({
  franchises,
  selected,
  onSelect,
}: Props) => {
  const isFranchisesEmpty =
    !franchises || franchises.length === 0;

  const [
    selectedFranchise,
    setSelectedFranchise,
  ] = React.useState<FranchiseType>(null);

  React.useEffect(() => {
    if (!isFranchisesEmpty) {
      setSelectedFranchise(
        franchises.find(item => item.id === selected),
      );
    }
  }, [selected]);

  React.useEffect(() => {
    if (selectedFranchise) {
      onSelect(selectedFranchise.id);
    }
  }, [selectedFranchise]);

  const select = (
    <SelectInput
      getOptionValue={(item: any) =>
        item.id || Math.random()
      }
      getOptionLabel={(item: any) => item.name}
      options={franchises as any}
      value={selectedFranchise as any}
      onChange={setSelectedFranchise as any}
    />
  );

  return (
    <Wrapper>
      <span>Select current franchise</span>
      <PaperBox>
        {isFranchisesEmpty
          ? 'You have no franchises to choose from. Please, contact support.'
          : select}
        {!selected && (
          <ErrorMessage>
            <WarningRoundedIcon />
            The current franchise is not selected!
          </ErrorMessage>
        )}
      </PaperBox>
    </Wrapper>
  );
};

interface Props {
  franchises: FranchiseType[];
  selected: number;
  onSelect: (id: number) => void;
}

const Wrapper = styled.div`
  & > span {
    color: var(--secondaryText);
  }
`;

const PaperBox = styled(Paper).attrs({
  as: 'form',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--secondary);
  padding: 24px 24px 28px;
  margin-top: 7px;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--mainRequired);
  margin-top: 16px;
  font-weight: bold;
`;

export default CurrentFranchiseSelector;
