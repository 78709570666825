import * as React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';

import { hex2rgba } from '../utils';

const MINIMIZED_MESSAGE_LENGTH = 134; // 134 symbols = 3 lines of text

const Message = React.memo(
  ({ message, className }: Props) => {
    const dateTimeString = React.useMemo(
      () => format(message.time, 'MMMM d, H:mm'),
      [message],
    );

    const [collapsed, setCollapsed] = useState(true);

    const minimizedMessage = useMemo(() => {
      if (
        message.message.length < MINIMIZED_MESSAGE_LENGTH
      ) {
        return '';
      }

      return `${message.message.slice(
        0,
        MINIMIZED_MESSAGE_LENGTH,
      )}...`;
    }, [message]);

    const handleClick = useCallback(() => {
      setCollapsed(flag => !flag);
    }, []);

    return (
      <Wrapper className={className}>
        <DateTime>{dateTimeString}</DateTime>
        <Content>
          {collapsed && minimizedMessage}
          {(!collapsed || !minimizedMessage) &&
            message.message}
        </Content>
        {minimizedMessage && (
          <CollapseButton onClick={handleClick}>
            {collapsed ? 'Read' : 'Minimize'}
          </CollapseButton>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  background: ${({ theme }) =>
    hex2rgba(theme.defaultBackground, 0.1)};
  border-radius: 10px;
  padding: 16px 24px;
  color: var(--defaultBackground);
`;

const DateTime = styled.div`
  margin-bottom: 10px;
`;

const Content = styled.div`
  line-height: 1.22;
  margin-bottom: 5px;
`;

const CollapseButton = styled.button`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  color: var(--defaultBackground);

  &:hover {
    text-decoration: none;
  }
`;

interface Props {
  message: MessageInterface;
  className?: string;
}

export interface MessageInterface {
  time: number;
  message: string;
}

export default Message;
