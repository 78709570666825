// import { pascalCase } from 'change-case';

import { api } from './api';

import { PaginatedList, PaginatedRequest } from '../types';

export const getInvoicesList = (
  params?: PaginatedRequest,
) =>
  api.request<InvoicesList>({
    method: 'GET',
    url: '/stripe/invoices/',
    params: {
      ...params,
    },
  });

export const getInvoiceByID = (id: number) =>
  api.request<Invoice>({
    method: 'GET',
    url: `/stripe/invoices/${id}/`,
  });

export const getAllInvoicesList = () =>
  api.request<InvoicesList>({
    method: 'POST',
    url: `/quickbooks/invoices-all/`,
  });

export const getStripeInvoicesList = params => {
  return api.request<InvoicesList>({
    method: 'GET',
    url: `/stripe/invoices/`,
    params,
  });
};

export const getInvoicePDF = (data) => {
  const { id, franchise_id } = data
  return new Promise((resolve, reject) => {
    return api
      .request<any>({
        method: 'GET',
        url: `/stripe/invoices/${id}/get-pdf/?franchise_id=${franchise_id}`,
        responseType: 'arraybuffer',
      })
      .then(response => {
        const getParams = new URLSearchParams(
          response.config.params,
        ).toString();

        if (
          response &&
          !Object.keys(response.data).length
        ) {
          fetch(
            getParams ? `${response.config.baseURL}${response.config.url}?${getParams}` : `${response.config.baseURL}${response.config.url}`,
            response.config,
          )
            .then(fetchResponse =>
              fetchResponse
                .blob()
                .then(data => resolve({ data }))
                .catch(reject))
            .catch(reject);
        }
      })
      .catch(reject);
  });
}

export const getPDFInvoiceByID = (id: number) =>
  new Promise((resolve, reject) => {
    return api
      .request<any>({
        method: 'GET',
        url: `/stripe/invoices/${id}/get-pdf/`,
        responseType: 'arraybuffer',
      })
      .then(response => {
        const getParams = new URLSearchParams(
          response.config.params,
        ).toString();
        if (
          response &&
          !Object.keys(response.data).length
        ) {
          fetch(
            `${response.config.baseURL}${response.config.url}?${getParams}`,
            response.config,
          )
            .then(fetchResponse =>
              fetchResponse
                .blob()
                .then(data => resolve({ data }))
                .catch(reject))
            .catch(reject);
        }
      })
      .catch(reject);
  });

type InvoicesList = PaginatedList<InvoiceTableType>;

export interface InvoiceTableType {
  createdAt: string | number | Date;
  id: number;
  dueDate: string;
  balance: string;
  deposit: number;
  customer: string;
  totalAmt: string;
  docNumber: number;
  createTime: string;
  invoiceStatus: string;
  lastUpdateTime: string;
}

export interface Invoice {
  id: number;
  deposit: number;
  balance: string;
  totalAmt: string;
  docNumber: number;
  dueDate: string;
  invoiceStatus: string;
  customer: string;
  createTime: string;
  lastUpdateTime: string;
  salesTermRef: string;
  billAddr?: BillAddr;
  customField: CustomField[];
  customerMemo: string;
  line: Line[];
}

interface BillAddr {
  line4: string;
  line3: string;
  line2: string;
  line1: string;
  long: string;
  lat: string;
  id: number;
}

interface CustomField {
  definitionId: number;
  stringValue: string;
  type: string;
  name: string;
}

interface Line {
  description?: string;
  detailType: string;
  lineNum: number;
  amount: string;
  id?: number;
  salesItemLineDetail?: SalesItemLineDetail;
  subTotalLineDetail?: SubTotalLineDetail;
}

interface SalesItemLineDetail {
  taxCodeRef: TaxCodeRef;
  qty: number;
  unitPrice: string;
  itemRef: ItemRef;
}

interface TaxCodeRef {
  name: string;
  value: string;
}

interface ItemRef {
  name: string;
  value: string;
}

interface SubTotalLineDetail { }
