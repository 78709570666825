import * as React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Chat from './Chat';
import '../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Switch } from '@material-ui/core';
import { ButtonWithLoader, LoaderWithContainer } from '../../../../../../molecules';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { setComments, setIsSystemMessages } from '../../../../../../redux/slices/inspectionsSlice';
import { useState } from 'react';
import { createNewComment } from '../../../Utils';

const CommentsChat = ({
  title,
  buttonText,
  isButtonHidden,
  isReadOnly,
}: // noFile,
Props) => {
  const dispatch = useDispatch();
  const [commentValue, setCommentValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState('');
  const [messages, setMessages] = useState([]);
  const { selectedInspection, isSystemMessages, comments } = useSelector((s: RootState) => s.inspections);

  React.useEffect(() => {
    sortComments(comments);
  }, [comments, isSystemMessages]);

  const editorRef = React.useRef(null);

  const sortComments = comments => {
    const tempComments = comments;
    //sorting with full datetime
    const sortedcomments = tempComments
      ?.map(comment => {
        return { ...comment, createdAt: new Date(comment.createdAt) };
      })
      .sort((a, b) => b.createdAt - a.createdAt);
    //Transforming with specified  date format
    const transformedComments = sortedcomments?.map(comment => {
      return {
        ...comment,
        createdAt:
          new Date(comment.createdAt).toLocaleDateString() +
          ', ' +
          new Date(comment.createdAt).toLocaleTimeString([], {
            timeStyle: 'short',
          }),
      };
    });
    setMessages(transformedComments);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsSystemMessages(event.target.checked));
  };

  React.useEffect(() => {
    if (/\S/.test(editorRef?.current?.getEditor()?.getText() && isError)) {
      setError('');
    }
  }, [commentValue]);

  const sendComment = async () => {
    if (/\S/.test(editorRef?.current?.getEditor()?.getText())) {
      const result = await createNewComment(selectedInspection.id,commentValue);
      setCommentValue('');
      dispatch(setComments([...comments,result.data]));
    } else {
      setError('Text is required for Comment');
    }
  };

 const assertDisableComment = () => {
   if(commentValue === '<p><br></p>' || commentValue === '') {
    return true;
   }
   return false
 }
  return (
    <>
      <Title>
        {title}
        <ToggleComment>
          <Switch color="primary" checked={isSystemMessages} onChange={handleSwitchChange} />
          Show Audit Comments
        </ToggleComment>
      </Title>
      <CommentsWrapper>
        {isLoading && <LoaderWithContainer />}
        {!isLoading && <Chat messages={messages} />}
        <EditorWrapper>
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [[{ size: [] }, { align: [] }], ['bold', 'italic', 'underline'], [{ color: [] }, { background: [] }], ['image']],
            }}
            ref={editorRef}
            readOnly={isReadOnly}
            value={commentValue}
            onChange={comment => setCommentValue(comment)}
          />
        </EditorWrapper>

        {!!isError && <Error>{isError}</Error>}
        {!isButtonHidden && (
          <ButtonWrapper>
            <ButtonWithLoader disabled={assertDisableComment()} loading={false} type="button" onClick={sendComment}>
              {buttonText}
            </ButtonWithLoader>
          </ButtonWrapper>
        )}
      </CommentsWrapper>
    </>
  );
};

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  noFile?: boolean;
  permit?: number;
  title: string;
  buttonText: string;
  isButtonHidden?: boolean;
  isReadOnly?: boolean;
}

const CommentsWrapper = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  @media only screen and (max-width: 840px) {
    width: 75vw !important;
  }
  .editor-wrapper {
    padding-top: 20px;
    max-width: 696px;
  }
  .comments-editor,
  .comments-toolbar {
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  .comments-editor {
    height: 200px;
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 16px;
  display: flex;
  width: 280px;
  justify-content: center;
`;

const Title = styled.h2`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
`;

const EditorWrapper = styled.div`
  .quill {
    margin-top: 20px;
  }
  .ql-container {
    height: 200px;
    /* max-width: 696px; */
  }
`;

const ToggleComment = styled.div`
  float: right;
  font-size: 16px;
`;

export default CommentsChat;
