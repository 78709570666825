import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { getPermitByID } from '../../api/permits';
import {
  createPermitFeesComment,
  getPermitsFees,
  getPermitsFeesStatistics,
  manualUpdatePermitFees,
  updatePermitFees,
} from '../../api/fees';
import { getProjectByID } from '../../api/projects';
import PermitFeesForm from '../../forms/PermitFeesForm';
import openInNewTab from '../../utils/openInNewTab';

import useTablePage from '../../hooks/useTablePage';
import { ModalDefaultDraggable } from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import Table from '../../organisms/Table';
import { convertTableBoolean } from '../../utils/convertTableValues';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import FeesSendModal from '../../organisms/FeesSendModal';
import { getPermitUsers, getUsers } from '../../api/users';
import {
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../../constants';
import { USER_TYPES_VALUES } from '../../constants/forms';
import formatDate from '../../utils/formatDate';
import { getCustomActivities } from '../../api/activities';
import { getCurrentUser } from '../../api/userSettings';
import { RootState } from '../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { setPermitContacts } from '../../redux/slices/projectSlice';

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const FeesPage = ({
  permit,
  isAddFees,
  setAddFees,
  setFeeSelected,
  isSendingSelectedFees,
  setIsSendingSelectedFees,
  cameFromSearch,
}: Props) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const permitContacts = useSelector((s:RootState)=>s.projectsSlice.permitContacts);

  const [
    selectedTableFees,
    setSelectedTableFees,
  ] = React.useState([]);

  const [
    selectedFeesIds,
    setSelectedFeesIds,
  ] = React.useState([]);

  const [
    projectJurisdiction,
    setProjectJurisdiction,
  ] = React.useState(null);
  const [totalInvoices, setTotalInvoices] = React.useState(
    0,
  );
  const [
    totalOutstanding,
    setTotalOutstanding,
  ] = React.useState(0);

  const [
    isOpenFeesSendModal,
    setOpenFeesSendModal,
  ] = React.useState(false);

  const [
    isPaidManuallySubmit,
    setIsPaidManuallySubmit
  ] = React.useState(false);

  const {
    tableManuallyUpdate,
    updateTable,
    search,
    handleEdit,
    isModalOpen,
    createOrUpdate,
    modalSuccess,
    modalError,
    modalTitle,
    modalErrorTitle,
    errors,
    values,
    handleCloseModalInfo,
    handleCloseModal,
    handleAdd,
    showSuccessModal,
  } = useTablePage({
    updateEndpoint: updatePermitFees,
    createEndpoint: createPermitFeesComment,
    modalCreateTitle: 'Add Fees',
  });

  React.useEffect(() => {
    if (permit) {
      Promise.all([
        getPermitUsers({
          user_role: 3,
          permit_id: permit
        }),
      ])
        .then(
          ([
            permitUsers,
          ]) => {
              const contacts = permitUsers?.data
            dispatch(setPermitContacts(contacts));
          },
        )
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    }
  }, [permit]);

  React.useEffect(() => {
    if (
      isSendingSelectedFees &&
      Array.isArray(selectedTableFees) &&
      selectedTableFees?.length
    ) {
      setOpenFeesSendModal(true);
    }
  }, [isSendingSelectedFees]);

  React.useEffect(() => {
    if (!isOpenFeesSendModal) {
      setIsSendingSelectedFees(false);
    }
  }, [isOpenFeesSendModal]);

  React.useEffect(() => {
    if (Array.isArray(selectedTableFees)) {
      if (selectedTableFees?.length) {
        const sentOrPaidFees = selectedTableFees.filter(
          fee =>
            fee.status === 'Sent' || fee.status === 'Paid' || fee.status === 'Void' || fee.status === 'Paid by Another Method' || fee.status === 'Invoiced in QB',
        );
        if (sentOrPaidFees.length) {
          setSelectedFeesIds([]);
          setFeeSelected(false);
        }
        if (!sentOrPaidFees.length) {
          setFeeSelected(true);
          setSelectedFeesIds(
            selectedTableFees?.map(fee => fee?.id),
          );
        }
      }
      if (!selectedTableFees?.length) {
        setSelectedFeesIds([]);
        setFeeSelected(false);
      }
    }
  }, [selectedTableFees]);

  React.useEffect(() => {
    if (projectId) {
      Promise.all([getProjectByID(projectId)]).then(
        ([projectResponse]) => {
          setProjectJurisdiction(
            projectResponse.data?.jurisdiction,
          );
        },
      );
    }
  }, [projectId]);

  React.useEffect(() => {
    if (isAddFees) {
      handleAdd();
    }
  }, [isAddFees]);

  React.useEffect(() => {
    updateTable();
  }, [modalSuccess, modalError]);

  const closeInfoModal = () => {
    updateTable();
    handleCloseModalInfo();
  };

  const columns = column => {
    if (
      [
        'permit',
        'creator',
        'feeSettings',
        'feeCheckbox',
        'invoiceId',
        'isPaidManual',
        'type',
        'isVoid',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy'
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'id':
        return 'ID';
      case 'name':
        return 'Fee Name';
      case 'amount':
        return 'Amount';
      case 'quantity':
        return 'Quantity';
      case 'total':
        return 'Total';
      case 'status':
        return 'Status';
      case 'dateInvoiced':
        return 'Date Invoiced';
      case 'datePaid':
        return 'Date Paid';
      case 'lastComment':
        return 'Comments';
      case 'invoiceNumber':
        return 'Invoice Number';
      case 'invoicePdf':
        return 'Invoice';
      case 'createdAt':
        return 'Date Created';
      // case 'completedAt':
      //   return 'Date Completed'
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'id',
    'invoiceNumber',
    'name',
    'amount',
    'quantity',
    'total',
    'status',
    'dateInvoiced',
    'datePaid',
    'invoicePdf',
    'lastComment',
    'createdAt',
    'createdBy',
    // 'completedAt',
    // 'completedBy'
  ];

  const modifiedGetPermitFees = params =>
    Promise.all([
      // getPermitsFees({
      //   ...params,
      //   permit,
      // }),
      getCustomActivities({
        ...params,
        // permit,
        permit_id:permit,
        event_type:"Activity",
        activity_type_name:"Fee",
        sort_by: params?.ordering,
        ordering:null,
      }),
      getPermitsFeesStatistics({
        ...params,
        permit,
      }),
    ])
      .then(([feesResponse, feesStatistics]) => {        
        setTotalOutstanding(
          feesStatistics.data.outstanding
            ? feesStatistics.data.outstanding
            : 0,
        );
        setTotalInvoices(
          feesStatistics.data.totalInvoiced
            ? feesStatistics.data.totalInvoiced
            : 0,
        );
        return {
          ...feesResponse,
          data: {
            ...feesResponse.data,
            results: feesResponse.data.results.map(fee => {
              return {
                ...fee,
                dateInvoiced: fee.dateInvoiced
                  ? formatDate(
                      fee.dateInvoiced,
                      DEFAULT_DATE_FORMAT,
                    )
                  : fee.dateInvoiced,
                datePaid: fee.datePaid
                  ? formatDate(
                      fee.datePaid,
                      DEFAULT_DATE_FORMAT,
                    )
                  : fee.datePaid,
                amount: currencyFormat.format(+fee.amount),
                total: currencyFormat.format(+fee.total),
                lastComment: fee?.lastComment?.replace(
                  /(<([^>]+)>)/gi,
                  ' ',
                ),
                invoicePdf: fee?.invoicePdf
                  ? `${window.location.origin}${fee?.invoicePdf}`
                  : '',
              };
            }),
          },
        };
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => {});

  const handleCloseFeesModal = () => {
    if (!modalError && !modalSuccess && !isPaidManuallySubmit) {
      setAddFees(false);
      handleCloseModal();
      updateTable();
    }
  };

  const handleTableFeeSelect = fees => {
    setSelectedTableFees(fees);
  };

  const cellCallbackFnIsOn = ['invoicePdf'];

  const handleCellClick = rowValue => {
    openInNewTab(rowValue?.invoicePdf);
  };

  return (
    <>
      <h3>
        Total invoiced:{' '}
        <StyledNumber>
          {currencyFormat.format(totalInvoices)}
        </StyledNumber>
      </h3>
      <h3>
        Outstanding:{' '}
        <StyledNumber>
          {currencyFormat.format(totalOutstanding)}
        </StyledNumber>
      </h3>
      <Table
        customSearch
        initialUseSortBy
        permitFeesTable
        searchQuery={search}
        columns={columns}
        columnsOrder={columnsOrder}
        rows={modifiedGetPermitFees}
        onEdit={handleEdit}
        onDelete={null}
        onConfigure={null}
        formatValue={convertTableBoolean}
        manuallyUpdate={tableManuallyUpdate}
        onSelect={handleTableFeeSelect}
        handleCellClick={handleCellClick}
        cellCallbackFnIsOn={cellCallbackFnIsOn}
        feesTable
        useViewModeInsteadEdit={cameFromSearch}
      />

      {isOpenFeesSendModal && (
        <FeesSendModal
          permit={permit}
          fees={selectedFeesIds}
          contacts={permitContacts}
          isOpenFeesSendModal={isOpenFeesSendModal}
          setOpenFeesSendModal={setOpenFeesSendModal}
          updateTable={updateTable}
        />
      )}
      {isModalOpen && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseFeesModal}
        >
          <PermitFeesForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            jurisdiction={projectJurisdiction}
            handleCloseModal={handleCloseFeesModal}
            showSuccessModal={showSuccessModal}
            cameFromSearch={cameFromSearch}
            // isPaidManuallySubmit={isPaidManuallySubmit}
            setIsPaidManuallySubmit={setIsPaidManuallySubmit}
            updatePermitFeesTable={updateTable}
          />
        </ModalDefaultDraggable>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={closeInfoModal}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  permit: number;
  isAddFees: boolean;
  isSendingSelectedFees: boolean;
  setAddFees: (data: boolean) => void;
  setIsSendingSelectedFees: (data: boolean) => void;
  setFeeSelected: (data: boolean) => void;
  cameFromSearch?:boolean;
  isPaidManuallySubmit: boolean;
  setIsPaidManuallySubmit: (data: boolean) => void;
}

const StyledNumber = styled.span`
  color: var(--primary);
`;

export default FeesPage;
