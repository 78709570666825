import React, { useEffect, useState } from 'react';
import { useGetPendingInspectionsQuery } from '../../redux/rtkQuery/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { setRowsCount } from '../../redux/slices/paginationSlice';
import { format } from 'date-fns';
import { InspectionItemType } from '../../modules/clientModule/types';

const useInspectionFilters = (dates: { fromDate: Date; toDate: Date }, toggleControl: boolean) => {
  const [params, setParams] = useState({});
  const currentUser = useSelector((s: RootState) => s.userAuth.info.id);
  const filters = useSelector((s: RootState) => s.filters.filters);
  const { pageIndex, pageSize } = useSelector((s: RootState) => s.pagination);
  const [rows, setRows] = useState<InspectionItemType[]>([]);
  const dispatch = useDispatch();
  const { data: pendingInspections, isFetching, isLoading } = useGetPendingInspectionsQuery(params, {
    skip: Object.keys(params).length === 0,
  });
  useEffect(() => {
    if (!pendingInspections) {
      setParamsValue(currentUser);
      return;
    }
    if (toggleControl) {
      setParamsValue(currentUser);
    } else {
      setParamsValue();
    }
  }, [toggleControl, dates, pageIndex, pageSize]);

  useEffect(() => {
    if (!pendingInspections) {
      return;
    }

    if (filters.length > 0) {
      const filteredRows = [];
      pendingInspections.records.forEach(insp => {
        let foundMatch = 0;
        filters.forEach(filter => {
          
         for(var i = 0; i< Object.keys(insp).length; i++){
             if(`${insp[filter.filterName]}`.toLowerCase().indexOf(filter.filterValue) > -1){
              foundMatch++;
              break;
             }
          }

        })
          if(foundMatch === filters.length){
            filteredRows.push(insp);
          }
      });
      setRows(filteredRows);
    } else {
      //No filters
      dispatch(setRowsCount(pendingInspections.count));
      setRows(pendingInspections.records);
    }
  }, [pendingInspections, filters]);

  const setParamsValue = (inspectorId?: number) => {
    let _params = {
      limit: pageSize,
      offset: pageIndex * pageSize,
      is_deleted: false,
      ordering: '-is_emergency,follow_up',
      inspector: inspectorId,
      is_complete: false,
      date_from: undefined,
      date_to: undefined,
    };

    if (Object.keys(dates).length > 0) {
      _params.date_from = format(dates.fromDate, 'yyyy-MM-dd');
      _params.date_to = format(dates.toDate, 'yyyy-MM-dd');
    }
    setParams(_params);
  };
  return { setParamsValue, rows, isFetching };
};

export default useInspectionFilters;
