import axios from 'axios';
import { PaginatedRequest } from '../types';
import { api } from './api';
import {
  onResponseErrorInterceptor,
  onResponseInterceptor,
} from './interceptors';
import { formatToFormData } from '../utils';
import { DocumentType } from './document';
import { getAccessToken } from '../auth';

export const getFormSetupList = (
  params?: PaginatedRequest,
) =>
  api.request({
    method: 'GET',
    url: '/form_setup/',
    params,
  });

export const getFormSetupFields = (
  params?: PaginatedRequest,
) =>
  api.request({
    method: 'GET',
    url: '/form_setup/get_markup_fields/',
    params,
  });

export const getFilledForm = ({ documentId, formId }) =>
  api.request({
    method: 'GET',
    url: `/project_documents/${documentId}/form/${formId}/`,
  });

export const createFormSetup = data => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData({
    type: data.type,
    description: data.description,
    name: data.name,
    file: data.file,
    isDefault: data.isDefault,
    formMarkup: data.formMarkup,
    fontSize: data.fontSize,
  });

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data?.jurisdictions?.length; i++) {
    body.append('jurisdictions', data?.jurisdictions[i]);
  }

  if (data?.documents?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.documents?.length; i++) {
      body.append('documents', data?.documents[i]);
    }
  }

  if (data?.reportScope?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.reportScope?.length; i++) {
      body.append('report_scope', data?.reportScope[i]);
    }
  }

  return axios.request<DocumentType>({
    method: 'POST',
    url: '/api/v1/form_setup/',
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const editFormSetup = data => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData({
    type: data.type,
    description: data.description,
    name: data.name,
    file: data.file,
    isDefault: data.isDefault,
    formMarkup: data.formMarkup,
    isDeleted: data.isDeleted,
    fontSize: data.fontSize,
  });

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data?.jurisdictions?.length; i++) {
    body.append('jurisdictions', data?.jurisdictions[i]);
  }

  if (data?.documents?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.documents?.length; i++) {
      body.append('documents', data?.documents[i]);
    }
  }

  if (data?.reportScope?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.reportScope?.length; i++) {
      body.append('report_scope', data?.reportScope[i]);
    }
  }

  return axios.request<DocumentType>({
    method: 'PATCH',
    url: `/api/v1/form_setup/${data.id}/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const deleteFormSetup = (id: number) =>
  api.request({
    method: 'DELETE',
    url: `/form_setup/${id}/`,
  });

export interface FormSetupType {
  id?: string;
  name: string;
  description: string;
  jurisdictions: Array<number>;
  type: number;
  reportScope?: Array<number>;
  documents?: Array<number>;
  visibleFilename?: string;
  formMarkup: any;
  isDefault: boolean;
  file?: any;
  fontSize?: number;
}
