import * as React from 'react';
import { History } from 'history';
import styled from 'styled-components';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory, useLocation } from 'react-router-dom';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  createMessage,
  getMessagesList,
  MessagesButtonTypeLink,
  MessagesTableType,
  reUseMessage,
} from '../../api/messages';
import SubMenuHeadLine from '../../organisms/SubMenuHeadLine';
import Table from '../../organisms/Table';
import ActionsPanel from '../../organisms/ActionsPanel';
import useTablePage from '../../hooks/useTablePage';
import { convertTableUTCDate } from '../../utils/convertTableValues';
import { ModalDefaultDraggable } from '../../molecules';
import { ROUTES } from '../../constants/routes';
import ModalInfo from '../../molecules/ModalInfo';
import MessagesForm from '../../forms/MessagesForm';
import convertObjToRouteObj from '../../utils/convertObjToRouteObj';
import {
  SUPER_ADMIN_ROLE,
  USER_TYPES,
  USER_TYPES_VALUES,
} from '../../constants/forms';
import isRoutePathExact from '../../utils/isRoutePathExact';

const messagesRoutes = USER_TYPES.reduce((acc, current) => {
  if (
    current.value === USER_TYPES_VALUES.FRANCHISE_ADMIN ||
    current.value === SUPER_ADMIN_ROLE
  ) {
    return acc;
  }

  return [
    ...acc,
    { id: current.value, title: current.label },
  ];
}, []);

const MessagesPage = () => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    modalTitle,
    values,
    modalErrorTitle,
    errors,
    setErrors,
    tableManuallyUpdate,
    createOrUpdate,
    handleAdd,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<MessagesTableType>({
    createEndpoint: createMessage,
    updateEndpoint: reUseMessage,
    modalCreateTitle: 'Add Message',
    modalUpdateTitle: 'Update Message',
  });
  const { pathname } = useLocation();
  const { push }: History = useHistory();

  const [routes, setRoutes] = React.useState<
    MessagesButtonTypeLink[]
  >(null);

  const [userRole, setUserRole] = React.useState<
    MessagesButtonTypeLink
  >(null);

  React.useEffect(() => {
    setRoutes(convertObjToRouteObj(messagesRoutes) as any);
  }, []);

  React.useEffect(() => {
    if (routes && pathname === ROUTES.SETTINGS_MESSAGES) {
      setUserRole(routes[0]);
      push(`${pathname}/${routes[0].linkTo}`);
      return;
    }

    if (
      routes &&
      isRoutePathExact(pathname, ROUTES.SETTINGS_MESSAGES)
    ) {
      const pathArr = pathname.split('/');
      const searchPathObj = routes.find(
        el => el.linkTo === pathArr[pathArr.length - 1],
      );

      setUserRole(searchPathObj);

      return;
    }

    setUserRole(null);
  }, [routes, pathname]);

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'isActive',
        'franchise',
        'franchiseNames',
        'userRole',
      ].includes(column)
    ) {
      return null;
    }

    if (column === 'publicationDate') {
      return 'Date';
    }

    return tableColumnsFormatter(column);
  };

  let isReuseFirstRequest = true;

  const additionalActions = () => [
    {
      title: 'Reuse',
      handler: fields => {
        if (isReuseFirstRequest) {
          createOrUpdate(fields).finally(() => {
            isReuseFirstRequest = true;
            setErrors(null);
          });

          isReuseFirstRequest = false;
        }
      },
      Icon: RefreshIcon,
    },
  ];

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        isActionPanelMoreVisible={false}
        onSearch={setSearch}
        onAdd={handleAdd}
      />

      <StyledSubMenuHeadLine
        menuLinks={routes as any}
        basePath={ROUTES.SETTINGS_MESSAGES}
      />

      {userRole && (
        <Container>
          <Table
            customSearch
            searchQuery={search}
            columns={columns}
            rows={getMessagesList}
            formatValue={convertTableUTCDate}
            manuallyUpdate={tableManuallyUpdate}
            additionalRequestParams={{
              userRole: userRole.id,
            }}
            additionalActions={additionalActions}
          />
        </Container>
      )}

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <MessagesForm
            initialValues={values}
            initialErrors={errors}
            modalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
            selectedUserRole={userRole.id}
          />
        </ModalDefaultDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div``;

const StyledSubMenuHeadLine = styled(SubMenuHeadLine)`
  padding: 16px 0;
  zoom: 0.9;
`;

export default MessagesPage;
