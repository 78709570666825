import * as React from 'react';
import styled from 'styled-components';
import {
  CircularProgress,
  CircularProgressProps,
} from '@material-ui/core';

const Loader = ({
  thickness = 4,
  size = 44,
  variant = 'indeterminate',
  ...props
}: Partial<CircularProgressProps>) => (
  <StyledCircularProgress
    thickness={thickness}
    size={size}
    variant={variant}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const StyledCircularProgress = styled(CircularProgress)`
  color: var(--primary);
`;

export default Loader;
