import { api } from './api';
import { PaginatedList, PaginatedRequest } from '../types';
import { FranchiseType } from './franchise';
import { UserType } from './users';

export const getMessagesList = (params: PaginatedRequest) =>
  api.request<MessagesList>({
    method: 'GET',
    url: '/messages/',
    params,
  });

export const createMessage = (data: MessagesType) =>
  api.request<MessagesType>({
    method: 'POST',
    url: '/messages/',
    data,
  });

export const reUseMessage = ({ id }: { id: number }) =>
  api.request<MessagesType>({
    method: 'POST',
    url: `/messages/${id}/resend/`,
  });

type MessagesList = PaginatedList<MessagesType>;

export interface MessagesType {
  text: string;
  franchise: number[] | string | FranchiseType[];
  userRole: number;
  addresses: number[] | UserType[];
  publicationDate: string;
  addressesList: string;
}

export interface MessagesTableType {
  id: number;
  text: string;
  userRole: number;
  publicationDate: string;
  franchiseNames: number[];
  addressesList: string;
  isActive: boolean;
  isDeleted: boolean;
}

export interface MessagesButtonType {
  id: number;
  title: string;
}

export type MessagesButtonTypeLink = MessagesButtonType & {
  linkTo: string;
};
