import * as React from 'react';
import styled from 'styled-components';
import {
  InputAdornment,
  TextField,
} from '@material-ui/core';

const NumberInput = ({
  inputValue,
  onChange,
  onBlur,
  disabled = false,
  label,
  step = 0.01,
  min = null,
  max = null,
  type = 'number',
  error = false,
  helperText = '',
  className = '',
  endAdornmentContent = null,
  formId,
}: NumberInputProps) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    onChange(value);
  };
  const handleBlur = () => {
    if (onBlur) onBlur(inputValue);
  };

  return (
    <Input
      value={inputValue}
      type={type}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      label={label}
      disabled={disabled}
      helperText={helperText}
      error={error}
      className={className}
      inputProps={{
        step,
        min,
        max,
        form: formId,
      }}
      InputProps={{
        endAdornment: endAdornmentContent && (
          <AdornmentStyled>
            {endAdornmentContent}
          </AdornmentStyled>
        ),
      }}
    />
  );
};

export interface NumberInputProps {
  inputValue: number | string;
  onChange: (string) => void;
  onBlur?: (string) => void;
  label?: string;
  step?: number | string;
  min?: number | string;
  max?: number | string;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  className?: string;
  type?: string;
  endAdornmentContent?: string;
  formId?: string;
}

const AdornmentStyled = styled(InputAdornment).attrs({
  position: 'end',
})`
  .MuiTypography-colorTextSecondary {
    color: var(--mainText);
  }

  .MuiTypography-root {
    color: var(--secondaryText);
  }
`;

const Input = styled(TextField).attrs({
  InputLabelProps: {
    shrink: true,
  },
})`
  position: relative;
  width: 100%;
  .MuiFilledInput-underline:after {
    border-bottom-color: ${props =>
      props.error
        ? props.theme.primary
        : props.theme.primary};
  }

  .MuiInputLabel-root.Mui-focused {
    color: var(--secondaryText);
  }

  .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary);
  }

  .MuiFormHelperText-contained {
    position: absolute;
    margin: 0;
    bottom: -18px;
    left: 14px;
  }

  .MuiOutlinedInput-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

export default NumberInput;
