import { api } from './api';
import { PaginatedList } from '../types';

export const getNotes = props => {
  const { params, permit } = props;
  return api.request<NotesList>({
    method: 'GET',
    url: `/notes/permits/${permit}/notes/`,
    params,
  });
};

export const getTicketByID = (id: number) =>
  api.request<NoteType>({
    method: 'GET',
    url: `/notes/${id}/`,
  });

export const createNote = data => {
  return api.request<NoteType>({
    method: 'POST',
    url: `/notes/permits/${data.permit}/notes/`,
    data,
  });
};

type NotesList = PaginatedList<NoteType>;

export interface NoteType {
  id: number;
  text: string;
  author: object;
  date: string;
}
