/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputAutocompletePlaces,
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  ButtonWithLoader,
  CheckboxWithLabel,
} from '../molecules';
import { FormServerError } from '../types';
import {
  JurisdictionType,
  JurisdictionTableType,
} from '../api/jurisdiction';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import { checkValidStateName } from '../utils/parseGoogleApiAddress';
import {
  FranchiseType,
  getFranchiseList,
} from '../api/franchise';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import { emailRegex, phoneRegex, zipCode } from '../regex';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import useUserRole from '../hooks/useUserRole';

const labels = {
  name: 'Jurisdiction name',
  phoneNumber: 'Phone',
  building: 'Building Official Name',
  email: 'Email',
  address: 'Address',
  franchise: 'Branch',
  street: 'Street',
  city: 'City',
  state: 'State',
  zipCode: 'Zip',
  isDefault: 'Set as default in new Branch',
};

const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  phoneNumber: yup
    .string()
    .matches(
      phoneRegex,
      'Invalid phone number. The phone number must be in format 2XX-XXX-XXXX',
    )
    .required()
    .label(labels.phoneNumber),
  building: yup.string().required().label(labels.building),
  email: yup
    .string()
    .matches(emailRegex, 'Enter a valid email address')
    .required()
    .label(labels.email),
  address: yup.string().required().label(labels.address),
  franchise: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .typeError('Select a franchise from the list')
    .nullable()
    .label(labels.franchise),
  state: yup.string().label(labels.state),
  street: yup.string().label(labels.street),
  city: yup.string().label(labels.city),
  zipCode: yup
    .string()
    .matches(
      zipCode,
      'Invalid Code number. The code number must be in format 55555 or 55555-5555',
    )
    .label(labels.zipCode),
  isDefault: yup.boolean().label(labels.isDefault),
});

const JurisdictionForm = ({
  onSubmit,
  initialValues,
  modalError,
  addButtonText = 'Add new item',
  updateButtonText = 'Update',
  initialErrors,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    formState,
  } = useForm<JurisdictionTableType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as any),
  });
  const { isSuperAdmin } = useUserRole();

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [
    franchisesData,
    setFranchisesData,
  ] = React.useState([]);

  const handlePlaceSelect = (address, isSelect) => {
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        return setValue(key, value);
      });
    }
    return setValue('address', address);
  };

  React.useEffect(() => {
    setIsLoading(true);

    getFranchiseList({
      ...GET_ALL_QUERY_PARAMS,
      ordering: 'name',
    })
      .then(response =>
        setFranchisesData(response.data.results))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (initialValues?.id) {
      if (franchisesData.length) {
        setValue(
          'franchise',
          franchisesData.filter(item =>
            initialValues.franchise.includes(item.id)),
        );
      }
    }
  }, [franchisesData]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<JurisdictionTableType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: JurisdictionType) => void,
  ) => (data: JurisdictionTableType) => {
    return submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      franchise: data.franchise
        ? (data.franchise as FranchiseType[])
            ?.filter(item => item.id)
            .map(item => item.id)
        : [],
    });
  };

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('name', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            control={control}
            defaultValue=""
            name="phoneNumber"
            render={props => (
              <ReactInputMask
                mask="999-999-9999"
                maskChar=" "
                {...props}
              >
                {() => (
                  <InputTextWithLabel
                    error={errors.phoneNumber}
                    label={labels.phoneNumber}
                    isRequired={isYupFieldRequired(
                      'phoneNumber',
                      schema,
                    )}
                  />
                )}
              </ReactInputMask>
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="email"
            error={errors.email}
            inputRef={register}
            label={labels.email}
            isRequired={isYupFieldRequired('email', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="building"
            error={errors.building}
            inputRef={register}
            label={labels.building}
            isRequired={isYupFieldRequired(
              'building',
              schema,
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="address"
            defaultValue=""
            control={control}
            render={props => (
              <InputAutocompletePlaces
                error={errors.address}
                label={labels.address}
                handleFieldChange={handlePlaceSelect}
                isRequired={isYupFieldRequired(
                  'address',
                  schema,
                )}
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="street"
            error={errors.street}
            inputRef={register}
            label={labels.street}
            isRequired={isYupFieldRequired(
              'street',
              schema,
            )}
          />
          <InputTextWithLabel
            name="city"
            error={errors.city}
            inputRef={register}
            label={labels.city}
            isRequired={isYupFieldRequired('city', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="state"
            defaultValue=""
            control={control}
            render={props => (
              <InputTextWithLabel
                name="state"
                error={errors.state}
                label={labels.state}
                isRequired={isYupFieldRequired(
                  'state',
                  schema,
                )}
                {...props}
                // eslint-disable-next-line react/prop-types
                value={checkValidStateName(props.value)}
              />
            )}
          />
          <InputTextWithLabel
            name="zipCode"
            error={errors.zipCode}
            inputRef={register}
            label={labels.zipCode}
            isRequired={isYupFieldRequired(
              'zipCode',
              schema,
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="franchise"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                selectAllActive
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                label={labels.franchise}
                error={errors.franchise}
                isDisabled={isLoading || !isSuperAdmin}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'franchise',
                  schema,
                )}
                options={franchisesData}
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {isSuperAdmin && (
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
        )}
        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: JurisdictionTableType;
  onSubmit: (data: JurisdictionType) => void;
  initialErrors?: FormServerError<JurisdictionTableType>;
  modalError: boolean;
}

export type JurisdictionFields = Omit<
  JurisdictionTableType,
  'id' | 'isDeleted' | 'franchiseId'
> &
  Pick<JurisdictionType, 'franchise'>;

export default JurisdictionForm;
