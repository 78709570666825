import { api } from './api';
import { PaginatedList, PaginatedRequest } from '../types';
import { PermitTicketItem, TicketItem } from '../modules/clientModule/types';

export const getTickets = (params?: PaginatedRequest) =>
  api.request<TicketsList>({
    method: 'GET',
    url: '/tickets/',
    params,
  });

  export const getAllTickets = (clientId: number) =>
  api.request<TicketItem[]>({
    method: 'GET',
    url: '/client_projects/get-all-tickets',
    params: {
      clientId: clientId,
      offset:0,
      limit:100000000,
      is_active:true,
      is_deleted:false
    }
  });

export const getTicketByID = (id: number) =>
  api.request<TicketType>({
    method: 'GET',
    url: `/tickets/${id}/`,
  });

export const createTicket = (data: TicketType) =>
  api.request<TicketType>({
    method: 'POST',
    url: '/tickets/',
    data,
  });

export const updateTicket = data => {
  return api.request<TicketType>({
    method: 'PATCH',
    url: `/tickets/${data.id}/`,
    data: { ...data, status: data.status },
  });
};

export const updateTicketAll = (data: TicketType) => {
  return api.request<TicketType>({
    method: 'PATCH',
    url: `/tickets/${data.id}/`,
    data: { status: data.status },
  });
};

export const deleteTicket = (id: number) =>
  api.request<TicketType>({
    method: 'DELETE',
    url: `/tickets/${id}/`,
  });

export const getComments = (
  params: PaginatedRequest,
  id: number,
) =>
  api.request<CommentsList>({
    method: 'GET',
    url: `/tickets/${id}/comments/`,
    params,
  });

export const getCommentByID = (
  ticketId: number,
  id: number,
) =>
  api.request<CommentType>({
    method: 'GET',
    url: `/tickets/${ticketId}/comments/${id}/`,
  });

export const updateComment = (data: CommentType) =>
  api.request<CommentType>({
    method: 'PATCH',
    url: `/tickets/${data.ticket}/comments/${data.id}/`,
    data,
  });

export const createTicketComment = (data: CommentType) =>
  api.request<CommentType>({
    method: 'POST',
    url: `/tickets/${data.id}/comments/`,
    data: {
      text: data.text,
    },
  });

export const deleteComment = (
  ticketId: number,
  id: number,
) =>
  api.request<TicketType>({
    method: 'DELETE',
    url: `/tickets/${ticketId}/comments/${id}/`,
  });

type TicketsList = PaginatedList<TicketType>;

export interface TicketType {
  id: number;
  ticketNumber: number;
  status: object;
  description: string;
  isDeleted: boolean;
  lastUpdated: string;
}

type CommentsList = PaginatedList<CommentType>;

export interface CommentType {
  id: number;
  text: string;
  createdAt: string;
  ticket: number;
  creator: number;
}
