import * as React from 'react';
import { useSelector } from 'react-redux';

import { userInfoSelector } from '../redux/ducks/userAuth';

import { ROUTES } from '../constants/routes';

import DashboardLayout from '../layouts/DashboardLayout';
import PageNotFound from '../layouts/PageNotFound';

import NotFoundContent from '../organisms/NotFoundContent';

const NotFoundPage = () => {
  const userInfo = useSelector(userInfoSelector);

  return (
    <>
      {userInfo ? (
        <DashboardLayout isCentered>
          <NotFoundContent
            buttonText="Go to Dashboard page"
            route={ROUTES.DASHBOARD}
          />
        </DashboardLayout>
      ) : (
        <PageNotFound>
          <NotFoundContent
            buttonText="Go to Login page"
            route={ROUTES.LOG_IN}
          />
        </PageNotFound>
      )}
    </>
  );
};

export default NotFoundPage;
