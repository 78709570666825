
/* eslint-disable function-paren-newline */
import * as React from 'react';
import styled, {css} from 'styled-components';
import useTablePage from '../hooks/useTablePage';
import {
    ModalDefaultDraggable,
} from '../molecules';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { useForm } from "react-hook-form";
import { ReportTableType } from "../api/reports";
import { getCurrentUser } from "../api/userSettings";
import formatDate from "../utils/formatDate";
import useManuallyUpdate from "../hooks/useManuallyUpdate";
import {inspectorColumns, inspectorColumnsOrder} from "../utils/allActivitiesPageColumns";
import Table from "../organisms/Table";
import {getPermitInspections, updatePermitInspection} from "../api/inspections";
import {getProjects} from "../api/projects";
import {getPermits} from "../api/permits";
import {getInspectionTypes} from "../api/inspectionTypes";
import {getUsers} from "../api/users";
import {USER_TYPES_VALUES} from "../constants/forms";
import {getVocabulariesDataList} from "../api/vocabulary";
import ModalInfo from "../molecules/ModalInfo";
import InspectionsPage from "./Permits/InspectionsPage";

const CompletedInspectionsPage = ({
  month,
  year,
 }: Props) => {

 const {
    errors,
    } = useForm<ReportTableType>({
    mode: 'onChange',
  });
 const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();

  const getFromDate = () => {
        return `${month.toString().padStart(2, '0')}/01/${year}`;
  }
  const getToDate = () => {
      const lastDay =  new Date(year, month, 0).getDate();
      return `${month.toString().padStart(2, '0')}/${lastDay.toString().padStart(2, '0')}/${year}`;
  }

 const [currentUser, setCurrentUser] = React.useState(
    null,
  );

 const [isPermitModalOpen, setPermitModal] = React.useState(
    false,
  );

 const [
    permit,
    setPermit,
  ] = React.useState(null);
const [projectId, setProjectId] = React.useState(null)
 const {
    modalError,
    modalSuccess,
    search,
    modalTitle,
    modalErrorTitle,
    handleCloseModalInfo,
    handleCloseModal,
   } = useTablePage({
     updateEndpoint: updatePermitInspection,
     modalUpdateTitle: 'Update Inspection',
  });
  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
       setPermitModal(false);
       handleCloseModal();
    }
  };

  const handleCellClick = rowValue => {
      setPermit(rowValue.permit);
      setPermitModal(true);
  };

  const [lastComment, setLastComment] = React.useState(
      null,
    );

  const [addInspection, setAddInspection] = React.useState(
      null,
    );

  const closeInfoModal = () => {
    setPermitModal(false);
    updateTable();
    handleCloseModalInfo();
  };

  React.useEffect(() => {
    Promise.all([
      getCurrentUser(),
    ]).then(
      ([
        currentUserResponse,
      ]) => {
          setCurrentUser(currentUserResponse.data.id);
         },
    );
  }, []);

 const getInspections = params =>
  Promise.all([
    //  getProjects({ ...GET_ALL_QUERY_PARAMS }),
      getPermitInspections({
      ...params,
      inspector: currentUser,
      isCompleted: true,
      ordering: '-is_emergency,follow_up',
      graph: true,
     }),
    // getPermits({ ...GET_ALL_QUERY_PARAMS }),
    // getInspectionTypes({ ...GET_ALL_QUERY_PARAMS }),
    // getUsers({
    //   ...GET_ALL_QUERY_PARAMS,
    //   userRole: USER_TYPES_VALUES.INSPECTOR,
    // }),
    // getUsers({
    //   ...GET_ALL_QUERY_PARAMS,
    //   userRole: USER_TYPES_VALUES.EMPLOYEE,
    // }),
    // getUsers({
    //   ...GET_ALL_QUERY_PARAMS,
    // }),
    // getVocabulariesDataList({
    //   ...GET_ALL_QUERY_PARAMS,
    //   vocabularyEntity: 3,
    //   ordering: 'title',
    // }),

  ]).then(
    ([
      // projectsResponse,
      inspectionsResponse,
      // permitsResponse,
      // inspectionTypesResponse,
      // inspectorsResponse,
      // employeesResponse,
      // allUsersResponse,
      // typesResponse,
    ]) => {
      
     const commentData = inspectionsResponse.data.results.map(el => ({
            id: el.id,
           lastComment: el.lastComment,
     }));
     setLastComment({ commentData, isDailyInspection: true });

       return {
        ...inspectionsResponse,
        data: {
          ...inspectionsResponse.data,
          results: inspectionsResponse.data.results.map(
            item => {
              // const permit = permitsResponse.data.results?.find(
              //   el => el?.id === item?.permit,
              // );
              setPermit(item?.permit);
              setProjectId(item?.projectId)
            //   const project = projectsResponse.data.results?.find(
            //     el => el?.id === permit?.project,
            //   );
            //   const inspectionType = inspectionTypesResponse.data.results?.find(
            //     el => el?.id === item?.inspectionType,
            //   )?.name;
            //  const assignee = employeesResponse.data.results?.find(
            //     el => el?.id === permit?.manager,
            //  );
            //  const contact = allUsersResponse.data.results?.find(
            //     el => el?.id === item?.contact,
            //   );

              return {
                ...item,
                projectNamePermitName: `${item?.projectName} | ${item?.permitName}`,
                permitNumber: item?.permitNumber ? item?.permitNumber : '',
                projectAddress: item?.projectAddress ? item?.projectAddress : '',
                projectManager:item?.managerFirstName ? `${item?.managerFirstName} ${item?.managerLastName}` : '',
                jurisdiction: item?.projectJurisdiction ? item?.projectJurisdiction : '',
                inspectionType: item?.activityTypeName ? item?.activityTypeName : '',
                id: item?.id,
                inspectionDate: item.inspectionDate
                  ? item.inspectionDate
                  : '',
                period:
                  // eslint-disable-next-line no-nested-ternary
                  item?.period === 0
                    ? 'AM'
                    : item?.period === 1
                    ? 'PM'
                    : '',
                contact:
                   item?.contactFirstName 
                   ? `${item?.contactFirstName} ${item?.contactLastName}`
                   : '',
                contactPhone:
                 item?.phoneNumber
                  ? `${item.phoneNumber}`
                  : '',

              };
            },
          ),
        },
      };
    },
  );

  return (
      <Container>
        <InnerContainer>
          <FormContainer
            autoComplete="off"
            aria-autocomplete="none"
          >

          </FormContainer>
        </InnerContainer>
          {currentUser && <Table
            customSearch
            searchQuery={search}
            columnsOrder={inspectorColumnsOrder}
            activityType="inspectorInspections"
            isActivityTable={true}
            columns={inspectorColumns}
            rows={getInspections}
            onEdit={null}
            onSelect={null}
            onDelete={null}
            manuallyUpdate={tableManuallyUpdate}
            additionalRow={lastComment}
            additionalActions={null}
            additionalRequestParams={
             {
                  dateFrom: formatDate(
                    new Date(getFromDate()),
                    'yyyy-MM-dd',
                  ),
                  dateTo: formatDate(
                    new Date(getToDate()),
                    'yyyy-MM-dd',
                  ),
                }
             }
            cellCallbackFnIsOn={['projectNamePermitName']}
            handleCellClick={handleCellClick}
            inspectorCompletedInspectionPage={true}
      />
            }
      {isPermitModalOpen &&(
       <StyledModalDraggable
          title={'Permit Inspections'}
          onClose={handleCloseForm}
          extraWide
        >
          <InspectionsPage
            projectId={projectId}
            permit={permit}
            isAddInspection={false}
            setAddInspection={setAddInspection}
            isReadOnly={true}
          />
        </StyledModalDraggable>
        )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={closeInfoModal}
          errorMsg={modalErrorTitle}
        />
      )}

      </Container>

  );
};

const Container = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
`;

const InnerContainer = styled.div`
  width: calc(100% + 4px);
  background: var(--secondary);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -2px;
  padding: 0 24px;
`;

const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

interface Props {
  month: number;
  year: number;
}

export default CompletedInspectionsPage;
