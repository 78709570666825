import * as React from 'react';
import { useHistory } from 'react-router-dom';

import MenuLinkWithIcon from '../atoms/MenuLinkWithIcon';

const UserDropMenuLink = ({
  to,
  text,
  icon,
}: PropInterface) => {
  const history = useHistory();

  const handleLinkClick = () => history.push(to);

  return (
    <MenuLinkWithIcon
      text={text}
      onClick={handleLinkClick}
      icon={icon}
    />
  );
};

interface PropInterface {
  to: string;
  icon?: JSX.Element;
  text: string;
}

export default UserDropMenuLink;
