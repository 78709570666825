import React, { FC, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Pagination } from '../../modules/clientModule/components/Common/Table/types';
import PaginationMobile from '../../pages/Responsive/PaginationMobile';
import SmsIcon from '@material-ui/icons/Sms';
import EditIcon from '@material-ui/icons/Edit';
import AdditionalField from './AdditionalField';
import { AdditionalFieldType } from './Types';

type Props = {
  headers: string[];
  rows: string[][];
  data: any[];
  additionalFields?: AdditionalFieldType[];
  Action1?: Function;
  Action2?: Function;
};
const Table20: FC<Props> = ({ headers, rows, data, additionalFields, Action1, Action2 }) => {
  return (
    <TableWrapper>
      {rows.length === 0 && <NoRecords>No matching records found</NoRecords>}
      {rows.length > 0 && (
        <HeadersRow count={headers.length}>
          {headers.map((header, index) => {
            return <HeaderCell key={index}>{header}</HeaderCell>;
          })}
        </HeadersRow>
      )}
      {rows.map((row, index) => {
        const fields = additionalFields[index];
        return (
          <RowWrapper index={index} key={index}>
            {fields && (
              <Fragment>
                <AdditionalFields>
                  <AdditionalField caption={fields[0].caption} value={fields[0].value} />
                  <AdditionalField caption={fields[1].caption} value={fields[1].value} />
                </AdditionalFields>
                <AdditionalField caption={fields[2].caption} value={fields[2].value} />
                <AdditionalField caption={fields[3].caption} value={fields[3].value} />
              </Fragment>
            )}
            <SingleRow count={headers.length}>
              {row.map((value, ndx) => (
                <DataCell key={ndx}>{value}</DataCell>
              ))}
              <Icons>
                <EditIcon onClick={() => Action1(data[index])} />
                <SmsIcon onClick={() => Action2(data[index])}/>
              </Icons>
            </SingleRow>
          </RowWrapper>
        );
      })}

      {rows.length > 0 && (
        <Pagination>
          <PaginationMobile showEverywhere={true} />
        </Pagination>
      )}
    </TableWrapper>
  );
};

export default Table20;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadersRow = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: ${({ count }) => `192px repeat(${count - 1},1fr)`};
  grid-gap: 15px;
  background-color: #fcf9fc;
`;

const SingleRow = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: ${({ count }) => `192px repeat(${count - 1},1fr)`};
  grid-gap: 15px;
  padding: 3px;
`;

const HeaderCell = styled.div`
  height: 35px;
  align-content: center;
  font-size: 0.875rem;
  font-weight: bold;
  color: #949494;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;
const DataCell = styled.div`
  overflow: hidden;
  padding-top: 16px;
  font-size: 0.875rem;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, Roboto, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-content: space-around;
  align-items: center;
`;

const NoRecords = styled.div`
  color: var(--secondaryText);
  width: 100%;
  text-align: center;
  padding: 17px;
`;

const AdditionalFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-top: 3px;
`;

const RowWrapper = styled.div<{ index }>`
  background-color: ${({ index }) => (index % 2 === 0 ? '#A4C7ED' : '#ffffff')};
  color: #000;
  padding: 3px;
  border-bottom: 12px solid grey;
  @media print {
    background-color:#fff;
  }
`;
//#A4C7ED
//#7DC5ED


//the one we hate
//#8cd2f5