import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { InputTextWithLabel } from '../../../../../molecules';
import { labels } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

const MaskedPhone = ({control,errors,name,setValue}) => {
  const selectedInspection = useSelector((s:RootState) => s.inspections.selectedInspection);
  useEffect(() => {
    setValue(name,selectedInspection[name]);
  },[selectedInspection])
    return (
        <Controller
                control={control}
                defaultValue=""
                name={name}
                render={props => (
                  <ReactInputMask
                    mask="999-999-9999"
                    maskChar=" "
                    disabled={true}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  >
                    {() => (
                      <InputTextWithLabel
                        showInRow={true}
                        style={
                          true ? { backgroundColor: '#F2F2F2', opacity: 0.7 } : {}
                        }
                        error={errors[name]}
                        label={labels[name]}
                        labelRequired={true}
                      />
                    )}
                  </ReactInputMask>
                )}
              />
    );
};

export default MaskedPhone;