import * as React from 'react';
import styled from 'styled-components';
import { ButtonMain, CustomizedDialogs } from '../atoms';

interface Props {
  title: string;
  description?: string;
  buttonLabel: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmModal = ({
  title,
  description = '',
  buttonLabel,
  onClose,
  onSubmit,
}: Props) => (
  <StyledCustomizedDialogs onClose={onClose}>
    <Wrapper>
      <Title>{title}</Title>
      {description ? (
        <Description>{description}</Description>
      ) : (
        ''
      )}
      <ButtonMain onClick={onSubmit}>
        {buttonLabel}
      </ButtonMain>
    </Wrapper>
  </StyledCustomizedDialogs>
);

const StyledCustomizedDialogs = styled(CustomizedDialogs)`
  .MuiIconButton-root {
    right: -12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--secondary);
  padding: 20px;
  width: 320px;
  max-width: 100%;
  min-height: 192px;
  border-radius: 10px;

  button {
    width: 80%;
    font-size: 12px;
  }
`;

const Title = styled.span`
  text-align: center;
  width: 190px;
  max-width: 100%;
  color: var(--mainText);
`;

const Description = styled.span`
  color: var(--secondaryText);
  margin: 20px 0;
`;

export default ConfirmModal;
