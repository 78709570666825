import React from 'react';
import styled from 'styled-components';

const LinkString = ({label,row, onCellClick}) => {
    return (
        <Wrapper onClick={() => onCellClick(row)}>
            {label}
        </Wrapper>
    );
};

export default LinkString;

const Wrapper = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;