import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';

import theme from '../../theme';

import { getEmployeeSales } from '../../api/dashboard';

const MonthlyIncomeChart = () => {
  const [chartData, setChartData] = React.useState(null);

  React.useEffect(() => {
    getEmployeeSales().then(({ data }) => {
      setChartData([
        {
          name: 'Monthly Goal',
          monthlyGoal: data?.monthlyGoal?.toFixed(1),
        },
        {
          name: 'Sales',
          sales: data?.sales,
        },
      ]);
    });
  }, []);

  return (
    <Wrapper>
      <Header>Monthly Income Chart</Header>
      <Content>
        {chartData && chartData.length && (
          <ResponsiveBar
            key={Math.random()}
            data={chartData}
            keys={['monthlyGoal', 'sales']}
            indexBy="name"
            margin={{
              top: 0,
              right: 0,
              bottom: 20,
              left: 74,
            }}
            valueScale={{ type: 'symlog' }}
            groupMode="stacked"
            padding={0.4}
            borderRadius={5}
            innerPadding={4}
            layout="horizontal"
            colors={[theme.marker1, theme.marker2]}
            axisBottom={null}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            enableLabel
            isInteractive={false}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 10px;
  padding: 6px;
  flex: 1;
  width: 770px;
  min-height: 300px;
  border: 1px solid var(--border);
  border-radius: 5px;
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;
const Content = styled.div`
  height: 280px;
`;

export default MonthlyIncomeChart;
