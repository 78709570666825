import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { TABLE_LOAD_DATA_MODE } from '../constants/settings';
import { ButtonMain, RadioButton } from '../atoms';

const TableSettingsForm = ({
  onSubmit,
  initialValues,
}: Props) => {
  const { control, handleSubmit } = useForm({
    defaultValues: initialValues,
  });

  return (
    <Container>
      <FormControl
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StyledLabel>Load all entries?</StyledLabel>
        <Controller
          // @ts-ignore
          name="tableLoadMode"
          control={control}
          as={
            <RadioGroup aria-label="Load all entries?">
              <FormControlLabel
                value={TABLE_LOAD_DATA_MODE.ALL}
                control={<RadioButton />}
                label="Load all"
              />
              <FormControlLabel
                value={TABLE_LOAD_DATA_MODE.ACTIVE}
                control={<RadioButton />}
                label="Operational"
              />
              <FormControlLabel
                value={TABLE_LOAD_DATA_MODE.INACTIVE}
                control={<RadioButton />}
                label="Deleted"
              />
            </RadioGroup>
          }
        />
        <ButtonContainer>
          <ButtonMain type="submit">
            Save settings
          </ButtonMain>
        </ButtonContainer>
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    margin-bottom: 20px;
  }

  .MuiButton-root {
    height: auto !important;
  }
`;

const StyledLabel = styled(FormControl)`
  color: var(--secondaryText);
  font-size: 0.975rem;
  font-weight: bold;
  margin: 0 0 10px 0;

  &:hover,
  &:focus,
  &:active {
    color: var(--secondaryText);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onSubmit: (data: any) => any;
  initialValues: object;
}

export default TableSettingsForm;
