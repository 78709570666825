/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  ButtonWithLoader,
} from '../molecules';
import { FormServerError } from '../types';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import {
  COMMENTS_DATETIME_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import { getVocabulariesDataList } from '../api/vocabulary';
import {
  createTicketComment,
  getComments,
  TicketType,
} from '../api/tickets';
import { getPermitUsers, getUsers } from '../api/users';
import { formatDateTime } from '../utils/formatDate';
import CommentsChat from '../organisms/CommentsChat';
import InspectionsInfoBlock from '../organisms/InspectionsInfoBlock';
import { getProjectByID } from '../api/projects';
import { getPermitByID } from '../api/permits';

const labels = {
  id: 'Ticket Number',
  subject: 'Subject',
  description: 'Description',
  status: 'Status',
  lastUpdated: 'Last Updated',
  dateCreated: "Date Created",
  createdBy: "Created By",
  dateCompleted: "Date Completed",
  completedBy: "Completed By",
  contact: "Contact"
};

const schema = yup.object().shape({
  id: yup.string().label(labels.id),
  subject: yup
    .string()
    .required()
    .label(labels.subject),
  description: yup
    .string()
    .required()
    .label(labels.description),
  contact: yup
    .object()
    .required()
    .typeError('Contact is required!')
    .label(labels.contact),
  status: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    // .required()
    // .typeError('Select a status from the list')
    .nullable()
    .label(labels.status),
  lastUpdated: yup
    .date()
    // .required()
    // .typeError('Invalid or absent date')
    .label(labels.lastUpdated),
});

const TicketsForm = ({
  permit,
  projectId,
  onSubmit,
  initialValues,
  isModalError,
  addButtonText = 'Add new item',
  updateButtonText = 'Update',
  initialErrors,
  ticketViewOnly = false,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as any),
  });
  const [commentCreated, setCommentCreated] = React.useState(null);
  const modifiedGetCommentsRequest = (isSystemMsg) => {
    return Promise.all([
      getComments({...GET_ALL_QUERY_PARAMS,system_message:isSystemMsg}, initialValues?.id),
    ]).then(([response]) => {      
      return {
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(comment => {
            return {
              ...comment,
              creator: comment?.createdBy,
              createdAt: formatDateTime(
                comment?.createdAt,
                COMMENTS_DATETIME_FORMAT,
              ),
            };
          }),
        },
      };
    });
  };

  const isDeleted = initialValues?.isDeleted;

  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  const [readOnlyInfo, setReadOnlyInfo] = React.useState(null)
  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  const [statusData, setStatusData] = React.useState([]);
  const [contactData, setContactData] = React.useState([]);

  React.useEffect(() => {
    if(initialValues) {
    Promise.all([getPermitByID(permit), getProjectByID(projectId)]).then(([permitResult, projectRes]) => {
    setReadOnlyInfo([
      {
        label: 'Project Name',
        value: projectRes?.data?.name,
      },
      {
        label: 'Permit Name',
        value: permitResult?.data?.name,
      },
      {
        label: 'Permit Number',
        value: permitResult?.data?.number,
      },
      {
        label: 'Permit Address',
        value: projectRes?.data?.address,
      },
      {
        label: 'Jurisdiction',
        value: projectRes?.data?.jurisdictionName,
      },
      {
        label: 'Project Manager',
        value: projectRes?.data?.managerName,
      },
    ]);
    })
  }

  }, [initialValues])

  React.useEffect(() => {
    setIsLoading(true);
    Promise.all([getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 9,
      ordering: 'title',
    }), getPermitUsers({
      user_role: 3,
      permit_id: +permit,
    })]).then(([response,permitContacts]) => {
      setContactData(permitContacts?.data)
      if(initialValues) {
        const initialContact = permitContacts?.data?.find(contact => contact?.id == initialValues?.contact)
      
      setValue('contact', initialContact)
      }
      if(permitContacts?.data?.length === 1) {
        setValue('contact', permitContacts?.data[0])
      }
        const allStatusData = response.data.results;        
        setStatusData(allStatusData);
        if (initialValues?.status) {
          setValue(
            'status',
            allStatusData.find(
              item => (item.id === initialValues?.status || item?.title === initialValues?.status),
            ),
          );
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);
  

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (submitFn: (data) => void) => data => {        
    return submitFn({
      subject: data?.subject,
      description: data?.description,
      contact: data?.contact?.id,
      permit
    });
  };  
  
  const middlewareEdit = (
    submitFn: (data: any) => void,
  ) => (data: any) => {        
    submitFn({
      id: initialValues?.id,
      status: data.status?.id,
      statusTitle: data.status?.title,
      contact: data?.contact?.id,
    });
  };

  return (
    <Container
      disabled={
        isDeleted || (isSubmitting && !isModalError)
      }
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(initialValues ? middlewareEdit(onSubmit) : middleware(onSubmit))}
      >
        {initialValues && (
        <InspectionsInfoBlock info={readOnlyInfo} />
      )}
        {initialValues && <InputBaseContainer>
          <InputTextWithLabel
            name="id"
            error={errors.id}
            inputRef={register}
            label={labels.id}
            InputProps={{
              readOnly: true,
            }}
            isRequired={isYupFieldRequired('id', schema)}
          />
        </InputBaseContainer>}
        <InputBaseContainer>
          <InputTextWithLabel
            name="subject"
            error={errors.description}
            inputRef={register}
            label={labels.subject}
            InputProps={{
              readOnly: initialValues ? true : false,
            }}
            isRequired={isYupFieldRequired(
              'subject',
              schema,
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="description"
            error={errors.description}
            inputRef={register}
            label={labels.description}
            InputProps={{
              readOnly: initialValues ? true : false,
            }}
            isRequired={isYupFieldRequired(
              'description',
              schema,
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="contact"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                label={labels.contact}
                error={errors.contact}
                isDisabled={
                  isLoading || initialValues?.statusTitle === "Completed" || ticketViewOnly
                }
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item?.firstName + " " + item?.lastName}
                isRequired={isYupFieldRequired(
                  'contact',
                  schema,
                )}
                options={contactData}
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {initialValues && <InputBaseContainer>
          <Controller
            name="status"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                label={labels.status}
                error={errors.status}
                isDisabled={
                  isLoading || initialValues?.statusTitle === "Completed" || ticketViewOnly
                }
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.title}
                isRequired={isYupFieldRequired(
                  'status',
                  schema,
                )}
                options={statusData}
                {...props}
              />
            )}
          />
        </InputBaseContainer>}
        {initialValues && <InputBaseContainer>
          <InputTextWithLabel
            name="lastUpdated"
            error={errors.lastUpdated}
            inputRef={register}
            label={labels.lastUpdated}
            InputProps={{
              readOnly: true,
            }}
            isRequired={isYupFieldRequired(
              'lastUpdated',
              schema,
            )}
          />
        </InputBaseContainer>}
        {initialValues && <>
          <InputBaseContainer>
            <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.9}}
            name="createdAt"
            error={errors.dateCreated}
            inputRef={register}
            label={labels.dateCreated}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        <InputBaseContainer>
          <MobileRemove>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.9}}
            name="creatorUsername"
            error={errors.createdBy}
            inputRef={register}
            label={labels.createdBy}
            isRequired={false}
            disabled={true}
          />
          </MobileRemove>
        </InputBaseContainer>
        {initialValues?.statusTitle === 'Completed' && <>
          <InputBaseContainer>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.9}}
            name="completedAt"
            error={errors.dateCompleted}
            inputRef={register}
            label={labels.dateCompleted}
            isRequired={false}
            disabled={true}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            style={{backgroundColor: '#F2F2F2', opacity: 0.8}}
            name="completedBy"
            error={errors.completedBy}
            inputRef={register}
            label={labels.completedBy}
            isRequired={false}
            disabled={true}
          />
        </InputBaseContainer>
        </>}
        </>}

        {!isDeleted && (
          <ButtonWithLoader
            disabled={
              (isSubmitting && !isModalError) ||
              initialValues?.statusTitle === "Completed"  || ticketViewOnly
            }
            loading={isSubmitting && !isModalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
      {initialValues && <CommentsChat
        isReadOnly={initialValues?.statusTitle === "Completed"}
        commentsRequest={modifiedGetCommentsRequest}
        entityData={initialValues}
        postRequest={createTicketComment}
        noFile
        title={'Comments List'}
        buttonText='Add Comment'
        commentCreated={commentCreated}
          setCommentCreated={setCommentCreated}
        ticketViewOnly={true}
      />}
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;
const MobileRemove = styled.div`
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: TicketType;
  onSubmit: (data: TicketType) => void;
  initialErrors?: FormServerError<TicketType>;
  isModalError: boolean;
  permit: number;
  projectId: number;
  ticketViewOnly: boolean;
}

export default TicketsForm;
