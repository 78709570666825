import React, { FC } from 'react';
import { TicketComment } from '../../../types';
import styled from 'styled-components';
import { format } from 'date-fns';

type Props = {
  ticket: TicketComment;
  isOdd: boolean;
  key: number;
};

const TicketMobileCommentRow: FC<Props> = ({ ticket, isOdd, key }) => {
  return (
    <RowWrapper isOdd={isOdd} key={key}>
      <Cell>
        <Label>Conversation</Label>
        <Values>{ticket.text}</Values>
      </Cell>
      <Cell>
        <Label>Sender</Label>
        <Values>{ticket.creatorUsername}</Values>
      </Cell>
      <Cell>
        <Label>Received</Label>
        <Values>{format(new Date(ticket.createdAt), 'MM/dd/yy')}</Values>
      </Cell>
    </RowWrapper>
  );
};

export default TicketMobileCommentRow;

const RowWrapper = styled.div<{ isOdd: boolean }>`
  display: none;
  @media only screen and (max-width: 840px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid grey;
    background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  padding-left: 10px;
`;

const Values = styled.div`
  font-size: 14px;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 11px;
`;

