import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../redux/root';
import InspectionsInfoBlock from '../../../organisms/InspectionsInfoBlock';
import { getCustomActivities } from '../../../api/activities';
import { ModalDefaultDraggable } from '../../../molecules';
import InspectionsPage from '../../Permits/InspectionsPage';
import { setShowPermitTable } from '../../../redux/slices/globalSearch';
import CloseIcon from '@material-ui/icons/Close';

const PopupInspections = () => {
  const {showPermitTable,permitInspectionsId} = useSelector((s: RootState) => s.globalSearch);
  const [readOnlyInfo, setReadOnlyInfo] = useState<any[]>([]);
  const dispatch = useDispatch();

  return (
    <>
      {showPermitTable && (
      <ModalDefaultDraggable
             title={'Permit Inspections'}
             onClose={()=>{}}
             extraWide
           >
            <Close onClick={e=>dispatch(setShowPermitTable(false))}>
              <CircleWrapper>
                 <Circle>
                    <CloseIcon />
                 </Circle>
              </CircleWrapper>
             
              
            </Close>
             <InspectionsPage
               projectId={1}
               permit={permitInspectionsId}
               isAddInspection={false}
               setAddInspection={()=>{}}
               isReadOnly={true}
             />
           </ModalDefaultDraggable>
           
      )}
    </>
  );
};

export default PopupInspections;

const Close = styled.div`
 display: flex;
 width: 92%;
 position:absolute;
 justify-content: flex-end;
 top:30px;
 
`;
const CircleWrapper = styled.div`
   background-color: white;
   width:41px;
   height:41px;
   @media only screen and (min-width: 840px) {
   position: relative;
    left: 18px;
  }
  @media only screen and (min-width: 1040px) {
   position: relative;
    left: 4%;
  }
`;
const Circle = styled.div`
  border: 1px solid black;
  border-radius: 50%;
  height:32px;
  width:32px;
  svg{
    margin-left: 3px;
    margin-top: 3px;
  }
`;


const PopupWrapper = styled.div`
  width: 94%;
  padding: 10px;
  margin: 8px;
  height: 71%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border: 3px solid grey;
  border-radius: 6px;
  box-shadow: 5px 5px 0 1px hsla(0, 0%, 0%, 0.3);
  p {
    margin: 15px 80px;
    line-height: 30px;
  }
`;

const Caption = styled.div`
  font-size: 27px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const Body = styled.div`
  flex-grow: 1;
`;
