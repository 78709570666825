import React, { useState } from 'react';
import PDF from '../../../../../forms/permitInspectionForm/PDFCreation/Pdf';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import SwitchCheckbox from '../../../../../modules/clientModule/components/Common/Controls/SwitchCheckbox';

const ReportCreator = ({ getValues, watch, setInspectionReport, activityStatus, setReportPdf, setPdfObject }) => {
  const { selectedInspection, pdfReport } = useSelector((s: RootState) => s.inspections);
  return (
    <ReportWrapper>
      <PDF
        watch={watch}
        getValues={getValues}
        initialValues={selectedInspection}
        setInspectionReport={setInspectionReport}
        selectedStatusValue={activityStatus}
        setPdfObject={setPdfObject}
        setReportPdf={setReportPdf}
      />
      <FileName>{pdfReport}</FileName>
    </ReportWrapper>
  );
};

export default ReportCreator;

const SwitchWrapper = styled.div`
 div{
  z-index: 0;
 }
`;

const ReportWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
`;

const FileName = styled.div`
  color: blue;
  display: flex;
  align-items: center;
`;
