import { useEffect } from 'react';
import { setShowDrawerContent } from '../../../redux/slices/generalSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

const useShowDetailsFromDashboard = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const OPEN_DRAWER = 'openDrawer';
  const openDrawer = params.get(OPEN_DRAWER);
  useEffect(() => {
    if (openDrawer) {
      const updatedUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({ path: updatedUrl }, '', updatedUrl);
      dispatch(setShowDrawerContent(true));
    }
  }, [openDrawer]);
};

export default useShowDetailsFromDashboard;
