import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  ButtonWithLoader,
  InputBaseContainer,
  InputDatePickerWithLabel,
  // InputCurrencyWithLabel,
  InputSelectWithLabel,
  InputTextWithLabel,
  TextAreaWithLabel,
} from '../molecules';
import ContactsTable from '../organisms/ContactsTable';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import useTablePage from '../hooks/useTablePage';
import { createPermit } from '../api/permits';
import { ButtonMain } from '../atoms';
import { fontFamily } from '../theme';
import ModalActionDialog from '../molecules/ModalActionDialog';
import PermitAddContact from '../organisms/PermitAddContact';
import ModalInfo from '../molecules/ModalInfo';
import useUserRole from '../hooks/useUserRole';
import { getCurrentUser } from '../api/userSettings';
import { getUsersCustom } from '../api/users';
import { DEFAULT_DATE_FORMAT } from '../constants';
import { USER_TYPES_VALUES } from '../constants/forms';
import { isInteger } from "lodash-es";
import formatDate from '../utils/formatDate';

const defaultValues = {
  name: '',
  selectContactsField: '',
  permitManager: '',
  contacts: '',
  description: '',
  contractor: '',
  building: '',
  inspectionsNumber: '',
  totalContract: '',
  serviceType: '',
  permitType: '',
  work: '',
};

const labels = {
  name: 'Name',
  number: 'Permit Number',
  total: 'Contract Total',
  square: 'Square Feet',
  inspections: 'Count of Inspections',
  storageBox: 'Storage Box Number',
  jurisdiction: 'Jurisdiction',
  documents: 'Permit Supporting Documents',
  address: 'Address',
  description: 'Description',
  info: 'Permit Info',
  status: 'Permit Status',
  permitContacts: 'Contacts',
  serviceType: 'Type of Service',
  manager: 'Project Manager',
  work: 'Work',
  building: 'Building',
  contractor: 'Contractor',
  expirationDate: 'Expiration Date'
};

const validationSchema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  description: yup
    .string()
    .required()
    .label(labels.description),
  contractor: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .label(labels.contractor),
  building: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a building from the list')
    .label(labels.building),
    inspectionsNumber: yup
    .number()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    /* .positive() */
    .typeError('Count of inspections must be a number')
    .nullable()
    .label(labels.inspections),
  totalContract: yup
    .string()
    .label(labels.total)
    .nullable(),
  serviceType: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a Service Types from the list')
    .nullable()
    .label(labels.serviceType),
  permitManager: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Project Manager from the list')
    .nullable()
    .label(labels.manager),
  work: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Scope of Work from the list')
    .nullable()
    .label(labels.work),
  contacts: yup
    .array()
    .of(yup.object())
    .required()
    .typeError('Select permit contacts'),
  selectContactsField: yup
    .array()
    .of(yup.object())
    .typeError('Select contacts'),
});

const AddPermitForm = ({
  allContacts,
  serviceTypes,
  buildingTypes,
  workTypes,
  contactTypes,
  managersData,
  setIsContactFormSubmitting,
  selectedProjectId,
  close,
  setCreatedPermit,
}: Props) => {
  const {
    createOrUpdate,
    modalSuccess,
    modalErrorTitle,
    modalError,
  } = useTablePage({
    createEndpoint: createPermit,
  });
  const [
    isAddContactModal,
    setAddContactModal,
  ] = React.useState(false);

  const { isEmployee } = useUserRole();
  const [
    currentManager,
    setCurrentManager,
  ] = React.useState(null);

  const [allManagers, setAllManagers] = React.useState(null)

  React.useEffect(() => {
    Promise.all([
      getCurrentUser(),
      // commenting the getuser method and adding the custom getuser method to load the user in more faster way
      // getUsers({
      //   ...GET_ALL_QUERY_PARAMS,
      //   userRole: USER_TYPES_VALUES.EMPLOYEE,
      // }),
      getUsersCustom({
        userRole: USER_TYPES_VALUES.EMPLOYEE,
      }),
    ]).then(
      ([currentUserResponse, projectManagersResponse]) => {
        if (isEmployee) {
          const activeManagers = projectManagersResponse.data;
          setAllManagers(activeManagers)
          const currentUserAsEmployee = activeManagers.find(
            el => el.id === currentUserResponse.data.id,
          );
          setCurrentManager(currentUserAsEmployee);
        }
      },
    );
  }, [isEmployee]);

  React.useEffect(() => {
    if (modalSuccess && !modalError) {
      setCreatedPermit(true);
      close();
    }
  }, [modalSuccess]);

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    formState,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
const [allUserData, setAllUserData] = React.useState(null)
  React.useEffect(() => {
      getUsersCustom({
        userRole:USER_TYPES_VALUES.CLIENT,
        project: true
      }).then(userRes => {
        const users = userRes?.data?.filter(el =>
          el?.isActive &&
          (el?.clientType === 2 ||
            el?.clientType === 1))
        setAllUserData(users)
      }
      )

    if (!contactTypes || !contactTypes.length) {
      setError('contacts', {
        type: 'manual',
        message:
          'There are no Contact Types in the system. Please contact Elite Permits support.',
      });
    }
  }, [isAddContactModal]);

  React.useEffect(() => {
    const managerValue = getValues('permitManager');
    if (currentManager && !managerValue) {
      setValue('permitManager', currentManager);
    }
  }, [currentManager]);

  const [
    selectedContacts,
    setSelectedContacts,
  ] = React.useState([]);

  const [savedContacts, setSavedContacts] = React.useState(
    null,
  );

  const [
    isContactModalOpen,
    setContactModalOpen,
  ] = React.useState(false);

  React.useEffect(() => {
    if (
      (savedContacts || selectedContacts) &&
      contactTypes &&
      contactTypes?.length
    ) {
      clearErrors('contacts');
    }
    setValue('contacts', savedContacts as any);
  }, [selectedContacts, savedContacts]);

  // const onCurrencyChange = (value, name) => {
  //   setValue(name, value, { shouldDirty: true });
  // };

  const formatContacts = contacts => {
    if (contacts) {
      return contacts
        ?.map(contact => ({
          user: +contact.id,
          contact_type: contact.contactType
            ? +contact.contactType
            : contactTypes[0]?.id,
        }))
        .filter(el => !!el.user);
    }
    return [];
  };

  const handleSelectContacts = selected => {
    setSelectedContacts(selected);
    setValue('selectContactsField', selected);
    if (!selected && selectedContacts) {
      setSelectedContacts(null);
    }
  };

  const handleSaveContacts = () => {
    if (savedContacts && selectedContacts) {
      const contactsAll = [
        ...savedContacts,
        ...selectedContacts,
      ];
      const uniqueContacts = contactsAll.reduce(
        (x, y) =>
          x.findIndex(e => e.id === y.id) < 0
            ? [...x, y]
            : x,
        [],
      );
      setSavedContacts(uniqueContacts);
      setSelectedContacts(null);
    }
    if (!savedContacts) {
      setSavedContacts(selectedContacts);
    }
    setContactModalOpen(false);
  };

  const handleCloseContactForm = () => {
    setSelectedContacts(null);
    setContactModalOpen(false);
  };

  /* Inspection field only allow 0-999 */
  const setInspectionNumber = event => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
      else {
        const tempValue = event.target.value + event.key;
        if (Number(tempValue) >= 1000) {
          event.preventDefault();
        }
      }
  };

  const middleware = (submitFn: (data) => void) => data => {
    return submitFn({
      project: Number(selectedProjectId),
      name: data.name,
      description: data.description,
      serviceType: data.serviceType
        .map(el => el.id)
        .filter(el => el !== undefined),
      work: data.work.value
        ? +data.work.value
        : data.work.id,
      contractor: data.contractor?.id
        ? data.contractor.id
        : null,
      contacts: formatContacts(data.contacts),
      building: data.building?.value || data.building?.id,
      manager: data.permitManager?.id,
      inspectionsCount: data.inspectionsNumber
        ? data.inspectionsNumber
        : 0,
      amount: data.totalContract ? data.totalContract : 0,
      expirationDate: data.expirationDate
        ? `${formatDate(
            data.expirationDate,
          )}`
        : null,
    });
  };

  return (
    <>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="name"
          control={control}
          helperText={errors.name?.message}
          // @ts-ignore
          error={errors.name?.message}
          label={labels.name}
          isRequired={isYupFieldRequired(
            'name',
            validationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          as={TextAreaWithLabel}
          name="description"
          control={control}
          error={errors.description}
          label={labels.description}
          isRequired={isYupFieldRequired(
            'description',
            validationSchema,
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          name="serviceType"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              isMulti
              selectAllActive
              placeholder=""
              label={labels.serviceType}
              helperText={errors.serviceType?.message}
              error={errors.serviceType}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) =>
                item.name || item.title
              }
              isRequired={isYupFieldRequired(
                'serviceType',
                validationSchema,
              )}
              options={serviceTypes}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      
      <InputBaseContainer>
        <Controller
          name="work"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label={labels.work}
              helperText={errors.work?.message}
              error={errors.work}
              options={workTypes}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) => item.title}
              isRequired={isYupFieldRequired(
                'work',
                validationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
        <Controller
          name="building"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label={labels.building}
              helperText={errors.building?.message}
              error={errors.building}
              options={buildingTypes}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) => item.title}
              isRequired={isYupFieldRequired(
                'building',
                validationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      <StyledInputContainer>
        <HiddenInput>
          <Controller
            name="contacts"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                menuPlacement="bottom"
                label=""
                helperText={errors.contacts?.message}
                error={errors.contacts}
                // @ts-ignore
                options={selectedContacts}
                getOptionValue={(item: any) =>
                  item?.id || Math.random()
                }
                getOptionLabel={(item: any) => item?.title}
                isRequired={isYupFieldRequired(
                  'contacts',
                  validationSchema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </HiddenInput>
        <ContactsWrapper>
          <ContactsLabel>
            Contacts <span style={{ color: 'red' }}>*</span>
          </ContactsLabel>
          <ContactsBlock>
            <ContactsTable
              contacts={savedContacts}
              setContacts={setSavedContacts}
              contactTypes={contactTypes}
              error={errors.contacts?.message}
            />
            <ButtonContainer>
              <StyledButtonMain
                variant="outlined"
                onClick={() => setContactModalOpen(true)}
              >
                Select Contacts
              </StyledButtonMain>
            </ButtonContainer>
            <ButtonContainer>
          <StyledButtonMain
            variant="outlined"
            onClick={() => setAddContactModal(true)}
          >
            Add new Contact
          </StyledButtonMain>
        </ButtonContainer>
            
          </ContactsBlock>
          {errors.contacts?.message && (
            <ErrorMessage>
              {errors.contacts?.message}
            </ErrorMessage>
          )}
        </ContactsWrapper>
      </StyledInputContainer>
      <InputBaseContainer>
        <Controller
          name="permitManager"
          defaultValue=""
          control={control}
          render={props => (
            <InputSelectWithLabel
              placeholder=""
              menuPlacement="bottom"
              label={labels.manager}
              helperText={errors.permitManager?.message}
              error={errors.permitManager}
              options={allManagers}
              getOptionValue={(item: any) =>
                item.id || Math.random()
              }
              getOptionLabel={(item: any) =>
                `${item.firstName} ${item.lastName}`
              }
              isRequired={isYupFieldRequired(
                'permitManager',
                validationSchema,
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        />
      </InputBaseContainer>
      <InputBaseContainer>
        <Controller
          as={InputTextWithLabel}
          name="inspectionsNumber"
          control={control}
          helperText={errors.inspectionsNumber?.message}
          error={errors.inspectionsNumber}
          label={labels.inspections}
          onKeyPress={event => setInspectionNumber(event)}
          isRequired={isYupFieldRequired(
            'inspectionsNumber',
            validationSchema,
          )}
        />
        {/* <Controller
            name="totalContract"
            defaultValue=""
            control={control}
            render={props => (
                <InputCurrencyWithLabel
                    allowDecimals={false}
                    helperText={errors.totalContract?.message}
                    error={errors.totalContract}
                    label={labels.total}
                    onCurrencyChange={onCurrencyChange}
                    isRequired={isYupFieldRequired(
                        'totalContract',
                        validationSchema,
                    )}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            )}
        /> */}
      </InputBaseContainer>
      <InputBaseContainer>
              <Controller
                    name="expirationDate"
                    defaultValue=""
                    valueName="selected"
                    onChange={([selected]) => selected}
                    control={control}
                    menuPlacement="top"
                    render={props => (
                      <InputDatePickerWithLabel
                        label={labels.expirationDate}
                        error={errors.expirationDate}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        isRequired={isYupFieldRequired(
                          'expirationDate',
                          validationSchema,
                        )}
                        selected={props.value}
                        {...props}
                      />
                    )}
                  />
             </InputBaseContainer>
      <SaveButtonWrapper>
        <ButtonWithLoader
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit(middleware(createOrUpdate))}
          disabled={
            !formState.isDirty ||
            !!Object.keys(errors).length
          }
          loading={
            formState.isSubmitting &&
            !Object.keys(errors).length
          }
        >
          Save
        </ButtonWithLoader>
      </SaveButtonWrapper>

      {isContactModalOpen && (
        <ModalActionDialog
          title="Select Contacts"
          className="over"
          centered
          onCancel={handleCloseContactForm}
          actionFirst={handleSaveContacts}
          actionSecond={null}
          buttonFirstDisable={!selectedContacts}
          buttonFirstTitle="Select"
        >
          <InputBaseContainer>
            <Controller
              name="selectContactsField"
              defaultValue=""
              control={control}
              render={props => {
                return (
                  <InputSelectWithLabel
                    isMulti
                    // selectAllActive
                    centered
                    showCompany
                    placeholder=""
                    label="Contacts"
                    getOptionValue={(item: any) => item?.id}
                    getOptionLabel={(item: any) =>
                      // eslint-disable-next-line no-nested-ternary
                      item.name
                        ? item.name
                        : `${item.firstName} ${
                            item.lastName
                          }${
                            item?.companyName ||
                            item?.parentCompany
                              ? ` - ${
                                  item?.companyName ||
                                  item?.parentCompany
                                }`
                              : ''
                          }`
                    }
                    isRequired
                    options={allUserData ? allUserData : []}
                    {...props}
                    onChange={handleSelectContacts}
                  />
                );
              }}
            />
          </InputBaseContainer>
        </ModalActionDialog>
      )}

      {isAddContactModal && (
        <ModalActionDialog
          title="Add Contact"
          onCancel={() => setAddContactModal(false)}
          actionFirst={null}
          actionSecond={null}
          // buttonFirstDisable={!selectedContacts}
          className="over"
          noCancel
        >
          <PermitAddContact
            setIsContactFormSubmitting={
              setIsContactFormSubmitting
            }
            setAddContactModal={setAddContactModal}
          />
        </ModalActionDialog>
      )}

      {modalError && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={close}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  allContacts: any;
  permitStatus: any;
  serviceTypes: any;
  buildingTypes: any;
  workTypes: any;
  contactTypes: any;
  managersData: any;
  setIsContactFormSubmitting?: (data) => void;
  setCreatedPermit: (data: boolean) => void;
  close: () => void;
  selectedProjectId: string;
}

const ButtonContainer = styled.div`
  width: min-content;
  margin-left: 10px;
  margin-top: 4px;
`;

const SaveButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const StyledButtonMain = styled(ButtonMain)`
  &.MuiButton-root {
    line-height: 1.15;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 8px 0;
`;

const ContactsBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

const ContactsWrapper = styled.div`
  width: 100%;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  font-size: 0.75em;
  margin: 3px 14px 0 14px;
`;

const HiddenInput = styled.div`
  width: 0;
  height: 0;
  opacity: 0;
  div {
    box-sizing: border-box;
    border: none;
  }
`;

const ContactsLabel = styled.p`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 21px;
  margin-top: 0;
`;

export default AddPermitForm;
