import React, { FC, useState } from 'react';
import styled from 'styled-components';
import CommentsSection from './CommentsSection';
import FilesSection from './FilesSection';

type Props = {
    documentId:number
    permitId:number
}
const MobileDocumentsDetails:FC<Props> = ({documentId, permitId}) => {       
  const [activeTab, setActiveTab] = useState<number>(0);
  
  return (
    <>
    <TabStrip>
      <TabButton _id={0} activeTab={activeTab} onClick={e=>setActiveTab(0)}>Files</TabButton>
      <TabButton _id={1} activeTab={activeTab} onClick={e=>setActiveTab(1)}>Comments</TabButton>
    </TabStrip>   
     { activeTab === 0 && <FilesSection  permitId={permitId}/> }
     { activeTab === 1 && <CommentsSection documentId={documentId}/> }
    </>

  );
};

export default MobileDocumentsDetails;

const TabStrip = styled.div`
  display: flex;
`;
//
const TabButton = styled.div<{_id:number, activeTab:number}>`
  font-size: 20px;
  background-color: ${({_id,activeTab})=> {
    if(_id === activeTab)
    {
      return '#D2B96D';
    }
    return '#fff';
  }}; 
    color: ${({_id,activeTab})=> {
    if(_id !== activeTab)
    {
      return '#000';
    }
    return '#FFF';
  }}; 
  width: 120px;
  text-align: center;
  border: 1px solid grey;
  padding: 7px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  margin-right:8px;
`;



