import * as React from 'react';
import styled, {
  createGlobalStyle,
} from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ClickAwayListener } from '@material-ui/core';

const ModalWindow = ({
  children,
  onClose,
  className = '',
}: Props) => (
  <ModalContainer className={className}>
    <GlobalStyles />
    <ClickAwayListener onClickAway={onClose}>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {children}
      </ModalContent>
    </ClickAwayListener>
  </ModalContainer>
);

const GlobalStyles = createGlobalStyle`
  body {
    max-height: 100vh;
    overflow: hidden;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px 20px;
  overflow: auto;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContent = styled.div.attrs({
  className: 'modal-window__modal-content',
})`
  position: relative;
  margin: auto;
  background-color: var(--defaultBackground);
  width: 100%;
  max-width: 630px;
  display: flex;
  justify-content: center;
  padding: 72px 100px;
  border-radius: 10px;
`;

const CloseButton = styled(IconButton)`
  color: var(--defaultBackground);
  position: absolute;
  top: -45px;
  padding: 12px;
  right: -12px;
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  onClose: () => void;
  className?: string;
}

export default ModalWindow;
