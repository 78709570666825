import * as React from 'react';
import {
  TextareaAutosize,
  TextareaAutosizeProps,
} from '@material-ui/core';
import styled from 'styled-components';

const TextArea = ({
  error,
  inputRef,
  ...props
}: ExtendedTextareaAutosizeProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Textarea error={error} inputRef={inputRef} {...props} />
);

const Textarea = styled(({ error, inputRef, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextareaAutosize ref={inputRef} {...props} />
))`
  border: 1px solid var(--disabledButton);
  border-radius: 1px;
  font-size: 15px;
  padding: 10px 16px;
  font-weight: 300;

  &:focus {
    border: 1px solid var(--disabledButton);
    outline: 1px solid var(--primary);
  }
`;

interface ExtendedTextareaAutosizeProps
  extends TextareaAutosizeProps {
  error: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export default TextArea;
