import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface EmployeeActivityState {
  All: number;
  Calls: number;
  Conditions: number;
  Fee: number;
  Inspection: number;
  Permitting: number;
  Proposal: number;
  Reviews: number;
}

const initialState: EmployeeActivityState = {
  All: 0,
  Calls: 0,
  Conditions: 0,
  Fee: 0,
  Inspection: 0,
  Permitting: 0,
  Proposal: 0,
  Reviews: 0,
};

export const employeeActivitiesSlice = createSlice({
  name: 'employeeActivities',
  initialState,
  reducers: {
    setAllActivitiesCount: (state, action: PayloadAction<number>) => {
      state.All = action.payload;
    },
    setConditionCount: (state, action: PayloadAction<number>) => {
      state.Conditions = action.payload;
    },
    setFeeCount: (state, action: PayloadAction<number>) => {
      state.Fee = action.payload;
    },
    setCallsCount: (state, action: PayloadAction<number>) => {
      state.Calls = action.payload;
    },
    setInspectionCount: (state, action: PayloadAction<number>) => {
      state.Inspection = action.payload;
    },
    setPermittingCount: (state, action: PayloadAction<number>) => {
      state.Permitting = action.payload;
    },
    setProposalCount: (state, action: PayloadAction<number>) => {
      state.Proposal = action.payload;
    },
    setReviewsCount: (state, action: PayloadAction<number>) => {
      state.Reviews = action.payload;
    },
    resetAll: (state, action: PayloadAction) => {
      (state.All = 0),
        (state.Calls = 0),
        (state.Conditions = 0),
        (state.Fee = 0),
        (state.Inspection = 0),
        (state.Permitting = 0),
        (state.Proposal = 0),
        (state.Reviews = 0);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllActivitiesCount,
  setConditionCount,
  setInspectionCount,
  setPermittingCount,
  setFeeCount,
  setCallsCount,
  setProposalCount,
  setReviewsCount,
  resetAll,
} = employeeActivitiesSlice.actions;

export default employeeActivitiesSlice.reducer;
