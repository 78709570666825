import React, { FC } from 'react';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../../constants/menu';

export type Props = {
  label: string;
  value: string;
  link?: { url: string; name: string };
};
const StaticField: FC<Props> = ({ label, value, link }) => {
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      {!link && <div>{value}</div>}
      {link && (
        <a href={link.url} target="blank">
          {link.name}
        </a>
      )}
    </FieldWrapper>
  );
};

export default StaticField;

const FieldWrapper = styled.div`
  display: flex;
  margin: 6px;
  margin-left: 88px;
  font-size: 17px;
  color: ${Logo_Sky};
  a {
    color: ${Logo_Sky};
    &:visited, &:focus {
      color: ${Logo_Sky};
      text-decoration: underline;
    }
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-right: 8px;
  color: ${Logo_Sky};
`;
