import * as React from 'react';
import useTablePage from '../hooks/useTablePage';
import { updatePermitActivity } from '../api/activities';
import PermitActivitiesForm from './PermitActivitiesForm';
import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import ModalInfo from '../molecules/ModalInfo';
import PermitInspectionsForm from './permitInspectionForm/PermitInspectionsForm';
import { updatePermitInspection } from '../api/inspections';

const AllActivitiesEditForm = ({
  // typeQuery,
  initialValues,
  handleCloseInfoModal,
  permit,
  setInitialValues,
}: Props) => {
  const {
    modalSuccess,
    modalError,
    createOrUpdate,
    modalTitle,
    errors,
    values,
    setValues,
    modalErrorTitle,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage({
    updateEndpoint:
      initialValues?.type === 'inspection'
        ? updatePermitInspection
        : updatePermitActivity,
  });

  React.useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const handleCloseActivitiesModal = () => {
    if (!modalError && !modalSuccess) {
      setInitialValues(null);
      handleCloseModal();
    }
  };

  return (
    <>
      {values ? (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseActivitiesModal}
        >
          {values.type !== 'inspection' && (
            <PermitActivitiesForm
              onSubmit={createOrUpdate}
              initialValues={values}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={permit}
            />
          )}
          {values.type === 'inspection' && (
            <PermitInspectionsForm
              onSubmit={createOrUpdate}
              initialValues={values}
              modalError={modalError}
              updateButtonText="Update"
              initialErrors={errors}
              permit={permit}
              allActivities
            />
          )}
        </ModalDefaultDraggable>
      ) : null}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={() =>
            handleCloseInfoModal(handleCloseModalInfo)
          }
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  // typeQuery: object;
  initialValues: object;
  handleCloseInfoModal: (data: () => void) => void;
  permit: number;
  setInitialValues: (data: any) => void;
}

export default AllActivitiesEditForm;
