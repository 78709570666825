import * as React from 'react';
import styled from 'styled-components';
import {
  CustomizedDialogs,
  SuccessCheckmark,
} from '../atoms';

interface Props {
  title: string;
  onClose?: () => void;
}

const modalTimerValue = 4000;

const SuccessModal = ({ title, onClose }: Props) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, modalTimerValue);
    return () => clearTimeout(timer);
  }, []);

  return (
    <CustomizedDialogs onClose={onClose}>
      <Wrapper>
        <Title>{title}</Title>
        <SuccessCheckmark />
      </Wrapper>
    </CustomizedDialogs>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 24px;
  justify-content: space-between;
  background-color: var(--secondary);
  width: 248px;
  min-height: 192px;
  border-radius: 10px;

  & button {
    width: 80%;
  }
`;

const Title = styled.span`
  text-align: center;
  width: 220px;
  color: var(--mainText);
  margin-bottom: 10px;
  font-weight: bold;
`;

export default SuccessModal;
