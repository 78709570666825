import * as React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import {
  LoaderWithContainer,
  ButtonWithLoader,
} from '../molecules';
import {
  getInvoiceByID,
  getInvoicePDF,
  Invoice,
  InvoiceTableType,
} from '../api/invoices';
// import { ButtonMain } from '../atoms';

// const currencyFormat = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   minimumFractionDigits: 2,
// });

const InvoicePreviewForm = ({ values, franchise }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [invoice, setInvoice] = React.useState<Invoice>(
    null,
  );
  const [pdf, setPdf] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    // getInvoiceByID(values.id)
    //   .then(response => setInvoice(response.data))
    //   .catch(() => {});
    // getInvoicePDF(values.id).then(response =>
    //   console.log('PDF____', response),
    // );
    Promise.all([
      getInvoiceByID(values.id),
      getInvoicePDF({id: values.id, franchise_id: franchise}),
    ])
      .then(([invoiceResponse, pdfResponse]) => {
        setInvoice(invoiceResponse.data);
        const file = new Blob([pdfResponse.data], {
          type: 'application/pdf',
        });
        setPdf(file);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  if (!invoice) {
    return <LoaderWithContainer />;
  }

  const viewInvoice = () => {
    if (pdf) {
      const blobURL = URL.createObjectURL(pdf);
      window.open(blobURL);
    }
  };

  return (
    <>
      {values?.docNumber && (
        <InvoiceNumber>
          Number #{values?.docNumber}
        </InvoiceNumber>
      )}
      <StyledGrid container>
        {invoice?.billAddr && (
          <StyledGrid item xs={6}>
            <p>
              <strong>Invoiced To:</strong>
            </p>
            {Object.entries(invoice?.billAddr).map(
              ([key, value]) =>
                !['id', 'lat', 'long'].includes(key) &&
                value !== 'INVALID' && (
                  <p key={key}>{value}</p>
                ),
            )}
          </StyledGrid>
        )}
        <StyledGrid item xs={6}>
          <p>
            <strong>Pay To:</strong>
          </p>
          <p>ELITE PERMITS FL LLC</p>
          <p>2670 HORSESHOE DR N SUITE 205</p>
          <p>NAPLES, FL 34104 US</p>
          <p>239-280-0570</p>
          <p>www.eliteswfl.com</p>
        </StyledGrid>
      </StyledGrid>
      {/* <StyledGrid container>
        <StyledGrid item xs={6}>
          <p>
            <strong>Order Date:</strong>
          </p>
          <p>{invoice.createTime?.split('T')[0]}</p>
        </StyledGrid>
        <StyledGrid item xs={6}>
          <p>
            <strong>Due date:</strong>
          </p>
          <p>{invoice.dueDate?.split('T')[0]}</p>
        </StyledGrid>
      </StyledGrid> */}
      {/* <StyledGrid container>
        <Table>
          <thead>
          <tr>
            <td>Service</td>
            <td>Description</td>
            <td>Rate</td>
            <td>QTY</td>
            <td>Amount</td>
          </tr>
          </thead>
          <tbody>
          {invoice.line?.map(row => {
            const itemDetails = row.salesItemLineDetail;

            if (itemDetails) {
              return (
                <tr key={row.id}>
                  <td>{itemDetails.itemRef.name}</td>
                  <td>{row.description}</td>
                  <td>
                    {currencyFormat.format(
                      +itemDetails.unitPrice,
                    )}
                  </td>
                  <td>{itemDetails.qty}</td>
                  <td>
                    {currencyFormat.format(+row.amount)}
                  </td>
                </tr>
              );
            }

            if (row.detailType === 'SubTotalLineDetail') {
              return (
                <React.Fragment key={row.id}>
                  <tr className="table-bg-light">
                    <td colSpan={4} align="right">
                      <strong>Sub Total:</strong>
                    </td>
                    <td>
                      {currencyFormat.format(+row.amount)}
                    </td>
                  </tr>
                  <tr className="table-bg-light">
                    <td colSpan={4} align="right">
                      <strong>Tax:</strong>
                    </td>
                    <td>
                      {currencyFormat.format(
                        parseFloat(invoice.totalAmt) -
                        parseFloat(row.amount),
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            }

            return null;
          })}

          <tr className="table-bg-light">
            <td colSpan={4} align="right">
              <strong>Total:</strong>
            </td>
            <td>
              <strong>
                {currencyFormat.format(+invoice.totalAmt)}
              </strong>
            </td>
          </tr>
          </tbody>
        </Table>
        <ButtonContainer>
          <ButtonMain onClick={() => window.print()}>
            Print
          </ButtonMain>
        </ButtonContainer>
      </StyledGrid> */}
      <ButtonContainer>
        <ButtonWithLoader
          type="button"
          loading={isLoading}
          disabled={!pdf}
          onClick={viewInvoice}
        >
          View Invoice
        </ButtonWithLoader>
      </ButtonContainer>
    </>
  );
};

const StyledGrid = styled(Grid)`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0 0 6px 0;
  }
`;

const InvoiceNumber = styled.div`
  color: var(--secondaryText);
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
`;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   border: 1px solid #dee2e6;
//
//   & td,
//   & th {
//     padding: 0.75rem;
//     vertical-align: top;
//     border-top: 1px solid #dee2e6;
//   }
//
//   thead td {
//     font-weight: bold;
//   }
//
//   thead,
//   .table-bg-light {
//     background-color: #f8f8f8;
//   }
// `;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 40px 0 0 0;
  text-align: center;

  @media print {
    display: none;
  }
`;

interface Props {
  values: InvoiceTableType;
}

export default InvoicePreviewForm;
