
import * as React from 'react';
import styled from 'styled-components';

import DailyMessage from '../organisms/Dashboard/DailyMessage';
import InspectionTasksBlock from "../molecules/InspectionTasksBlock";
import CompletedInspectionsGraph from "../organisms/CompletedInspectionsGraph";
import useDevice from '../hooks/useDevice';
import MobileInspectionGraph from '../organisms/MobileInspectionGraph/MobileInspectionGraph';

const InspectorDashboardBlocks = () => {
  // EA-34 make responsive for ipad
  //       mritunjoy
  const [isIpad] = useDevice()
  return (
    <Wrapper>
      <InspectionTasksBlock />
      <CompletedInspectionsGraph />
      <MobileInspectionGraph />
      <DailyMessage />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
`;
const StatsBlock = styled.div`
  width: 100%;
  display: flex;
`;

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export default InspectorDashboardBlocks;
