import * as R from 'ramda';

export default <T, U>(data: T, inputFields: U): T => {
  try {
    if (
      typeof data === 'object' &&
      Object.keys(data).length
    ) {
      const validKeysArray = Object.keys(inputFields);
      const newData: T = Object.keys(data).reduce<T>(
        (acc, key) => {
          const value = validKeysArray.find(validKey => {
            const re = new RegExp(validKey, 'i');
            return key.match(re);
          });
          if (value) {
            return {
              ...acc,
              [value]: data[key],
            };
          }
          return {
            ...acc,
            [key]: data[key],
          };
        },
        {} as T,
      );
      return newData;
    }
    return data;
  } catch {
    return data;
  }
};

export const getOtherFieldErrors = (
  errors: { [key: string]: string },
  baseInputFields: string[],
) => {
  const otherErrorsObj = Object.keys(errors).reduce(
    (acc, key) => {
      if (baseInputFields.includes(key)) {
        return { ...acc };
      }
      return {
        ...acc,
        [key]: errors[key],
      };
    },
    {},
  );
  return otherErrorsObj;
};

export const convertErrorResponseToValid = (
  data: {
    [key: string]: Object | any;
  },
  keyPrefix?: string,
) => {
  const defaultError = {
    detail:
      'Error. Something went wrong. Please, contact support.',
  };

  if (!data || !R.is(Object, data)) return defaultError;

  try {
    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (R.is(Object, value)) {
          return {
            ...acc,
            ...convertErrorResponseToValid(value, key),
          };
        }

        const numberKey = Number.isNaN(Number(key))
          ? key
          : Number(key) + 1;
        const uniqKey = keyPrefix
          ? `${keyPrefix} ${numberKey}`
          : numberKey;

        return {
          ...acc,
          [uniqKey]: value,
        };
      },
      {},
    );
  } catch {
    return defaultError;
  }
};
