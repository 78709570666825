import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { AxiosPromise } from 'axios';
import DashboardLayout from '../layouts/DashboardLayout';
import SearchPanel from '../molecules/SearchPanel';

import {
  getSearchCategoriesList,
  SearchTypeList,
} from '../api/search';
import ButtonsResultsPanelInspector from "../organisms/Search/ButtonResultsPanelInspector";
import SearchTableInspector from "../organisms/Search/SearchTableInspector";
import { setSearchToken } from '../redux/slices/filterSlice';

const SearchPageInspector = () => {
  const [searchValue, setSearchValue] = React.useState<
    SearchTypeList | {}
  >({});
  const dispatch = useDispatch();
  const [
    actualEndpoint,
    setActualEndpoint,
  ] = React.useState<Promise<AxiosPromise> | null>(null);
  const [searchKey, setSearchKey] = React.useState('');
  const [activeTabName, setActiveTabName] = React.useState(
    'all',
  );

  const [dates, setDates] = React.useState(
    {} as { fromDate: Date; toDate: Date },
  );

  const searchData = value => {
    dispatch(setSearchToken(value));
    setSearchKey(value);
    getSearchCategoriesList({ search: value, activity: 7 }).then(
      response => {
        setSearchValue(response.data);
      },
    );
  };

  const setEndpoint = fn => {
    setActualEndpoint(() => fn);
  };

  return (
    <DashboardLayout>
      <Container>
        <StyledSearchPanel setSearchValue={searchData} />
        <ButtonWrapper>
          <ButtonsResultsPanelInspector
            searchValuesData={searchValue}
            setEndpoint={setEndpoint}
            setActiveTabName={setActiveTabName}
            setDates={setDates}
          />
        </ButtonWrapper>
      </Container>
      <Container>
        {actualEndpoint && (
          <SearchTableInspector
            searchKey={searchKey}
            activeTabName={activeTabName}
            actualEndpoint={actualEndpoint}
            dates={dates}
            setDates={setDates}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background: var(--defaultBackground);
 
`;

const ButtonWrapper = styled.div`
  
  @media only screen and (max-width: 840px) { 
    width:100%!important;   
    button {      
      width: 100%!important;
    }
}
`;

const StyledSearchPanel = styled(SearchPanel)`
  @media only screen and (max-width: 840px) {
    width: 100%;    
  }
  width: 70%;
  height: 50px;
  font-size: 18px;
`;

export default SearchPageInspector;
