import React from 'react';
import styled from 'styled-components';
import { ButtonProps } from '@material-ui/core';

import ButtonMain from '../atoms/ButtonMain';

const ButtonWithSearchResults = ({
  searchResult,
  ...props
}: Props) => (
  <Container>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <StyledButtonMain disabled={!searchResult} {...props}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
      {!!searchResult && (
        <SearchResultContainer>
          <span>{searchResult}</span>
        </SearchResultContainer>
      )}
    </StyledButtonMain>
  </Container>
);

const Container = styled.div`
  position: relative;
  margin: 0 2px;
`;

const StyledButtonMain = styled(ButtonMain)`
  font-size: 12px;
`;

const SearchResultContainer = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;

  span {
    color: var(--secondary);
    font-weight: bold;
  }
`;

type Props = ButtonProps & {
  loading?: boolean;
  searchResult: number | string;
};

export default ButtonWithSearchResults;
