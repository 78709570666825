import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COMPANY_NAME } from '../constants';
import { ROUTES } from '../constants/routes';

import logo from '../resources/logo.png';

const Logo = ({ linkTo = ROUTES.DASHBOARD }: Props) => (
  <Container to={linkTo}>
    <img src={logo} alt={COMPANY_NAME} />
  </Container>
);

const Container = styled(Link)`
  display: block;
  border: none;
  outline: none;
  cursor: pointer;

  > img {
    max-width: 100%;
  }
`;

interface Props {
  linkTo?: ROUTES;
}

export default Logo;
