
/* eslint-disable function-paren-newline */
import * as React from 'react';
import styled, {css} from 'styled-components';
import useTablePage from '../hooks/useTablePage';
import {ModalDefaultDraggable,
} from '../molecules';
import {DATETIME_FORMAT, DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS} from '../constants';
import { LibraryBooks } from '@material-ui/icons';
import { useForm } from "react-hook-form";
import {  ReportTableType } from "../api/reports";
import {getActivitiesList, getActivitiesStatusList, getCustomActivities, getPermitActivities, updatePermitActivity} from "../api/activities";
import { getCurrentUser } from "../api/userSettings";
import { formatDateTime } from "../utils/formatDate";
import useManuallyUpdate from "../hooks/useManuallyUpdate";
import DashboardLayout from "../layouts/DashboardLayout";
import Table from "../organisms/Table";
import {getProjects} from "../api/projects";
import {getPermits} from "../api/permits";
import {getUsers} from "../api/users";
import {USER_TYPES_VALUES} from "../constants/forms";
import {getVocabulariesDataList} from "../api/vocabulary";
import ModalInfo from "../molecules/ModalInfo";
import tableColumnsFormatter from "../utils/tableColumnsFormatter";
import PermitActivitiesForm from "../forms/PermitActivitiesForm";
import DocumentsPage from "./Permits/DocumentsPage";
import { modifiedGetReviews } from '../utils/allActivitiesPageRequests';
import ReviewsPageMobile from './Responsive/PendingReviewsMobile';
import { useDispatch } from 'react-redux';
import { setCurrentUserId } from '../redux/slices/meSlice';
import DocumentsDetailedPage from './Permits/DocumentsDetailedPage';
import ModalFullScreen from '../molecules/ModalFullScreen';

const ReviewsPage = () =>
{

 const {
    errors,
    } = useForm<ReportTableType>({
    mode: 'onChange',
  });
 const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();
 const dispatch = useDispatch();
 const [isAddDocument, setAddDocument] = React.useState(
    false,
  );
  const [
    isDownloadAllDocs,
    setDownloadAllDocs,
  ] = React.useState(false);
  const [
    isDocsAvailable,
    setDocsAvailable,
  ] = React.useState(false);
  const [
    isDocsWaitingOnClient,
    setDocsWaitingOnClient,
  ] = React.useState(false);
  const [isSendDocs, setSendDocs] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(
    null,
  );
  const [
    permit,
    setPermit,
  ] = React.useState(null);
  
  const {
    isModalOpen,
    modalError,
    modalSuccess,
    modalTitle,
    modalErrorTitle,
    values,
    createOrUpdate,
    handleEdit,
    handleCloseModalInfo,
   } = useTablePage({
     updateEndpoint: updatePermitActivity,
     modalUpdateTitle: 'Update Review',
   });

  const [initialValues, setInitialValues] = React.useState(
    null,
  );
  const [customReviewsData, setCustomReviewsData] = React.useState(null)
  const [
    documents,
    showDocuments,
  ] = React.useState(null);
  const handleEditModal = data => {

    if (data) {
      setPermit(data?.permitId);
      setInitialValues(data);
      handleEdit(data);
    }
  };

  const closeInfoModal = () => {
    setInitialValues(null);
    showDocuments(false);
    updateTable();
    handleCloseModalInfo();
  };

  React.useEffect(() => {

      Promise.all([
        getCurrentUser(),
    ]).then(
      ([
        currentUserResponse,
      ]) => {
        getCustomActivities({
          is_deleted: false,
          inspector_id: currentUserResponse?.data?.id,
          event_type: "Activity",
          activity_type_name: "Reviews"
        }).then(reviews => {
          const modifiedData = reviews?.data?.results?.filter(el => !el?.reviewTypeComment?.length)?.map(el => el?.id)
          setCustomReviewsData(modifiedData)
          
        })
        setCurrentUser(currentUserResponse.data.id);
        dispatch(setCurrentUserId(currentUserResponse.data.id))
      },
      );
  }, []);

 const reviewColumns = column => {
  if (
    [
      'id',
      'inspector',
      'isEmergency',
      'documentFile',
      'contact',
      'isCompleted',
      'amount',
      'activityType',
      'information',
      'permitId',
      'reviewTypeName',
      'fees',
      'feeNames',
      'attachments',
      // 'lastComment',
      'permitName',
      'contactFirstName',
      'contactLastName',
      'assignedToFirstName',
      'assignedToLastName',
      'companyName',
      'activityTitleName',
      'activityTypeName',
      'projectJurisdiction',
      'statusName',
      'contactId',
      'type',
      'reviewer',
      'projectId',
      'createdAt',
      'createdBy',
      'completedAt',
      'completedBy',
      'conditionName',
      'selectedDocuments',
      'reviewTypeComment',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'title':
      return 'Activity Title';
    case 'status':
      return 'Activity Status';
    case 'projectName':
      return 'Project Name';
    case 'permit':
      return 'Permit Name';
    case 'assignedTo':
      return 'Assigned To';
    default:
      return tableColumnsFormatter(column);
  }
};

 const reviewColumnsOrder = [
  'projectName',
  'permit',
  'jurisdiction',
  'permitNumber',
  'reviewType',
  'title',
  'status',
  'followUp',
 ];

 const getReviews = params =>
  Promise.all([
    getPermitActivities({
      ...params,
      ordering: '-is_emergency,follow_up',
    }),
    // getProjects({ ...GET_ALL_QUERY_PARAMS }),
    // getUsers({
    //   ...GET_ALL_QUERY_PARAMS,
    //   userRole: USER_TYPES_VALUES.EMPLOYEE,
    // }),
    // getUsers({
    //   ...GET_ALL_QUERY_PARAMS,
    //   userRole: USER_TYPES_VALUES.INSPECTOR,
    // }),
    // getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
    // getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
    // getPermits({ ...GET_ALL_QUERY_PARAMS }),
    // getUsers({
    //   ...GET_ALL_QUERY_PARAMS,
    // }),
    // getVocabulariesDataList({
    //   ...GET_ALL_QUERY_PARAMS,
    //   vocabularyEntity: 3,
    //   ordering: 'title',
    // }),
  ]).then(
    ([
      activitiesResponse,
      // projectsResponse,
      // employeesResponse,
      // inspectorsResponse,
      // titlesResponse,
      // statusesResponse,
      // permitsResponse,
      // allUsersResponse,
      // typesResponse,
    ]) => {

       return {
        ...activitiesResponse,
        data: {
          ...activitiesResponse.data,
          results: activitiesResponse.data.results.map(
            item => {
              // const assigned = employeesResponse.data.results?.find(
              //   user => user?.id === item?.assignedTo,
              // );
              // const permit = permitsResponse.data.results?.find(
              //   el => el?.id === item?.permit,
              // );

              setPermit(item?.permit);
              // const project = projectsResponse.data.results?.find(
              //   el => el?.id === permit?.project,
              // );
              // const inspector = inspectorsResponse.data.results?.find(
              //   el => el?.id === item?.inspector,
              // );
              // const contact = allUsersResponse.data.results?.find(
              //   el => el?.id === item?.contact,
              // );


              return {
                ...item,
                projectName: item?.projectName,
                permit: item?.permitName,
                permitId: item?.permit,
                jurisdiction: item?.projectJurisdiction,
                permitNumber: item?.permitNumber,
                reviewType: item?.reviewTypeName || item?.reviewType,
                title: item?.activityTitleName,
                status: item?.statusName,

                followUp: formatDateTime(
                  item.followUp,
                  DATETIME_FORMAT,
                ),
                assignedTo: `${item.assignedToFirstName} ${item.assignedToLastName}`,

                contact: `${item?.contactFirstName} ${item?.contactLastName}`,
                activityType: item?.activityTypeName,
                lastComment: item?.lastComment
                // lastComment: <div dangerouslySetInnerHTML={{__html: item?.lastComment}}
              // />
              };
            },
          ),
        },
      };
    },
  );

  const additionalActions = rowValue => {
    return [
      {
        title: 'View Documents',
        handler: () => showDocuments(rowValue),
        Icon: LibraryBooks,
      },
    ];
  };

  return (
    <DashboardLayout>
      <ReviewsPageMobile rows={modifiedGetReviews}/>
      <Container>

        { currentUser && <Table
        customSearch
        rows={modifiedGetReviews}
        columns={reviewColumns}
        onEdit={handleEditModal}
        onDelete={null}
        columnsOrder={reviewColumnsOrder}
        colorSchema
        isActivityTable
        activityType='InspectorReviews'
          additionalRequestParams={{
            inspectorId: currentUser,
            eventType: "Activity",
            activityTypeName: 'Reviews',
          }}
        manuallyUpdate={tableManuallyUpdate}
        additionalActions={additionalActions}
        reviewerCommentData={customReviewsData}
         /> }
      {isModalOpen && initialValues && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={closeInfoModal}
        >
          <PermitActivitiesForm
              onSubmit={createOrUpdate}
              initialValues={initialValues}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={permit}
              // isReadOnly={true}
          />
         </ModalDefaultDraggable>
      )}
      {/* Adding new Documents modal */}
      {/* {documents &&(
        <ModalDefaultDraggable
          title={'Permit Documents'}
          onClose={closeInfoModal}
          extraWide
        >
      <DocumentsPage
          permit={documents.permitId}
          work={null}
          building={null}
          isAddDocument={false}
          setAddDocument={setAddDocument}
          isDownloadAllDocs={false}
          setDocsAvailable={setDocsAvailable}
          setDownloadAllDocs={setDownloadAllDocs}
          setDocsWaitingOnClient={setDocsWaitingOnClient}
          isSendDocs={false}
          setSendDocs={setSendDocs}
          isInspector={true}
          initialValues={documents}
        />
        </ModalDefaultDraggable>
      )} */}
      {documents &&(
        <ModalFullScreen
          title={'Review Comments'}
          onClose={closeInfoModal}
        >
        <DocumentsDetailedPage
              permit={documents.permitId}
              work={null}
              building={null}
              isAddDocument={false}
              setAddDocument={setAddDocument}
              isDownloadAllDocs={false}
              setDocsAvailable={setDocsAvailable}
              setDownloadAllDocs={setDownloadAllDocs}
              setDocsWaitingOnClient={setDocsWaitingOnClient}
              isSendDocs={false}
              setSendDocs={setSendDocs}
              isInspector={true}
              initialValues={documents} 
              onSubmit={function (data: any): void {
                throw new Error('Function not implemented.');
              } }        />
        </ModalFullScreen>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={closeInfoModal}
          errorMsg={modalErrorTitle}
        />
      )}

      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const InnerContainer = styled.div`
  width: calc(100% + 4px);
  background: var(--secondary);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -2px;
  padding: 0 24px;
`;

const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

export default ReviewsPage;
