import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputTextWithLabel,
  InputBaseContainer,
  CheckboxWithLabel,
  ButtonWithLoader,
  InputSelectWithLabel,
  TextAreaWithLabel,
} from '../molecules';
import { ActivitiesStatusType, createReviewerComment, updateReviewerComment } from '../api/activities';

import { FormServerError } from '../types';

import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import useTablePage from '../hooks/useTablePage';
import { getReviewerComments } from '../api/projectDocuments';
import { ExpandMore, Done } from '@material-ui/icons';
import SmallSelectWithLabel from '../molecules/SmallSelectWithLabel';
// import DoneIcon from '@mui/icons-material/icons';

const labels = {
    reviewerComment: 'Reviewer Comment',
    reference: 'Reference',
    status: 'Status',
};

const schema = yup.object().shape({
    reviewerComment: yup.string().required().label(labels.reviewerComment),
    reference: yup.string().required().label(labels.reference),
    status: yup.string().required().label(labels.status),
});

const ReviewerCommentForm = ({
  reviewId,
  setFormSubmitted,
  formSubmitted,
  isViewMode = false,
}: Props) => {

  const {
    register,
    setValue,
    handleSubmit,
    errors,
    setError,
    control,
    formState,
  } = useForm({
    // resolver: yupResolver(schema),
    // defaultValues: {reviewerCommentsList}
    //   initialValues || ({} as ActivitiesStatusType),
  });

  const {append, fields} = useFieldArray({
    control,
    name: 'reviewerCommentForm'
  })

  const [reviewerCommentsList,setReviewerCommentsList] = React.useState([{
    reviewerComment: null,
    reference: null,
    status: undefined
}]);

   React.useEffect(() => {
      getReviewerComments(reviewId).then(reviewerCommentRes => {      
        setReviewerCommentsList(reviewerCommentRes?.data)      
        reviewerCommentRes?.data?.forEach(value => append({...value, status: {label: value?.status, value: value?.status === 'Rejected' ? 0 : 1}}))  
      })
      
  }, [append])

  const {
    createOrUpdate,
   } = useTablePage({
     createEndpoint: createReviewerComment,
     updateEndpoint: updateReviewerComment
   });

   


  const statusesData = [{label:'Rejected',value:'0'},{label:'Approved',value:'1'}, {label:'Not Applicable',value:'2'}]

  // const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);
  const [showSaved, setShowSaved] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);


  const middleware = (
    submitFn: (data: ActivitiesStatusType) => void,
  ) => (data: ActivitiesStatusType) => {
    const sanitizedData = data?.reviewerCommentForm?.filter(el => (el?.reference !== "" && el?.reviewerComment !== ""))
    
    setShowSaved(true)
    submitFn({
      reviewerCommentForm: sanitizedData,
      reviewId
    }).then(res => {
      setFormSubmitted(!formSubmitted)
      setIsSubmitting(false)
      
      setTimeout(() => setShowSaved(false), 2000)
    })
  };

  return (
    <Container
    >
      <FormContainer
      name="reviewerCommentForm"
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(createOrUpdate))}
      >
        {fields?.map((item, idx) => <Wrapper><DivContainer>
          <input
          hidden={true}
          name={`reviewerCommentForm[${idx}].id`}
          type='number'
          ref={register}
          placeholder='Id'
          defaultValue={item?.id}
        />
        <IndexWrapper>{idx + 1}.</IndexWrapper>
          <InputBaseContainer>
          <TextAreaWithLabel
          key={item.id}
            name={`reviewerCommentForm[${idx}].reviewerComment`}
            error={errors.reviewerComment}
            inputRef={register}
            // ref={register}
            label={labels.reviewerComment}
            defaultValue={item?.reviewerComment}
            isRequired={isYupFieldRequired('reviewerComment', schema)}
            maxChar={5000}
            disabled={isViewMode}
          />
        </InputBaseContainer>
       
        <InputBaseContainer>
          <InputTextWithLabel
          key={item.id}
            name={`reviewerCommentForm[${idx}].reference`}
            error={errors.reference}
            inputRef={register}
            // ref={register}
            label={labels.reference}
            defaultValue={item?.reference}
            isRequired={isYupFieldRequired('reference', schema)}
            inputProps={{ maxLength: 40 }}
            disabled={isViewMode}
          />
        </InputBaseContainer>
      <div style={{width: "35%", maxHeight:"40px"}}>
        <InputBaseContainer>
                <Controller
                key={item.id}
                ref={register}
                name={`reviewerCommentForm[${idx}].status`}
                  defaultValue={item?.status}
                control={control}
                render={props => (
                    <SmallSelectWithLabel
                    isDisabled={isViewMode}
                    placeholder=""
                    menuPlacement="bottom"
                    // isLoading={isLoading}
                    // isDisabled={isLoading}
                    label={labels.status}
                    error={errors.status}
                    getOptionValue={(item: any) => item.value}
                    getOptionLabel={(item: any) => item.label}
                    options={statusesData}
                    isRequired={isYupFieldRequired(
                        'status',
                        schema,
                    )}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    />
                )}
                />
            </InputBaseContainer>  
            </div>
          
        <ButtonWithLoader
            style={{marginTop: '37px'}}
            variant='text'
            disabled={isSubmitting || isViewMode}
            loading={false}
            type="submit"
          ><Icon>
          {showSaved ? <p style={{marginTop: "10px", marginLeft: "-10px", color: "#6CBB3C"}}>Saved!</p> : <Done />}
        </Icon>
          </ButtonWithLoader>
          </DivContainer>
          {item?.isClientCommentVisible &&
            <p style={{margin: 0, display: 'flex'}}><b style={{marginRight: '5px'}}>Client comment:</b>{item?.clientComment}</p>}
        </Wrapper>)}
      </FormContainer>
      <a onClick={() => append({id: 0, reviewerComment: "", reference: "", status: {label:'Rejected',value:'0'}})} style={{ margin: 0, cursor: 'pointer', float:'left', pointerEvents: isViewMode ? "none" :"auto"}} disabled={isViewMode}>
        + New</a>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const Wrapper = styled.div`
  padding: 0 0 8px 16px;
  border-radius: 5px;
  &:nth-child(odd) {
    background-color: #E8F0FE;
  }
`;

const IndexWrapper = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 34px;
  margin-right: 5px;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const DivContainer = styled.form`
width: 100%;
display:flex;
justify-content: space-evenly;
.MuiButton-root {
  margin-top: 40px;
  margin-left: 10px;
}  
`;

interface Props {
  reviewId: number;
  setFormSubmitted: () => void;
  formSubmitted: boolean;
  isViewMode?: boolean;
}

export default ReviewerCommentForm;
