import * as React from 'react';
import styled from 'styled-components';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { TradeType } from '../../api/trades';
import Table from '../../organisms/Table';
// import ActionsPanel from '../../organisms/ActionsPanel';
import useTablePage from '../../hooks/useTablePage';
import { convertTableBoolean } from '../../utils/convertTableValues';
import { ModalDefaultDraggable } from '../../molecules';
import { getNotes, createNote } from '../../api/notes';
import ModalInfo from '../../molecules/ModalInfo';
import NotesForm from '../../forms/NotesForm';
import {
  DATETIME_FORMAT,
} from '../../constants';
import { formatDateTime } from '../../utils/formatDate';

const NotesPage = ({
  permit,
  isAddNote,
  setAddNote,
}: Props) => {
  const {
    search,
    modalError,
    modalSuccess,
    isModalOpen,
    modalTitle,
    modalErrorTitle,
    tableManuallyUpdate,
    createOrUpdate,
    handleAdd,
    // handleEdit,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<TradeType>({
    createEndpoint: createNote,
    modalCreateTitle: 'Add Note',
  });

  React.useEffect(() => {
    if (isAddNote) {
      handleAdd();
    }
  }, [isAddNote]);

  React.useEffect(() => {
    if (modalSuccess && !modalError) {
      setAddNote(false);
    }
  }, [modalError, modalSuccess]);

  const columnsOrder = ['text', 'creator', 'createdAt'];

  const columns = column => {
    if (['id', 'permit', 'isDeleted', 'creatorLastName', 'creatorFirstName'].includes(column)) {
      return null;
    }

    switch (column) {
      case 'text':
        return 'Note';
      case 'creator':
        return 'Added By';
      case 'createdAt':
        return 'Created';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      setAddNote(false);
      handleCloseModal();
    }
  };

  const modifiedGetNotesRequest = params =>
    Promise.all([
      getNotes({ params, permit }),
    ])
      .then(([notesResponse]) => {        
        return {
          ...notesResponse,
          data: {
            ...notesResponse.data,
            results: notesResponse.data.results.map(el => {
              if (!el?.creator) {
                return {
                  ...el,
                  creator: 'Elite Permits',
                  createdAt: formatDateTime(
                    el.createdAt,
                    DATETIME_FORMAT,
                  ),
                };
              }
              return {
                ...el,
                createdAt: formatDateTime(
                  el.createdAt,
                  DATETIME_FORMAT,
                ),
                creator: `${el?.creatorFirstName} ${el?.creatorLastName}`,
              };
            }),
          },
        };
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {});

  return (
    <>
      {/* <ActionsPanel
          onSearch={setSearch}
          onAdd={handleAdd}
      /> */}
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columnsOrder={columnsOrder}
          columns={columns}
          rows={modifiedGetNotesRequest}
          // onEdit={handleEdit}
          onDelete={null}
          onEdit={null}
          onConfigure={null}
          initialSortBy={[{ id: 'createdAt', desc: true }]}
          additionalActions={null}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <NotesForm
            modalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
            permit={permit}
          />
        </ModalDefaultDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  permit: number;
  isAddNote: boolean;
  setAddNote: (data: boolean) => void;
}

const Container = styled.div`
  margin: 24px 0 0 0;
  width: 100%;
`;

export default NotesPage;
