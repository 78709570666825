import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { FormServerError } from '../types';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import {
  InputTextWithLabel,
  InputBaseContainer,
  CheckboxWithLabel,
  ButtonWithLoader,
} from '../molecules';
import { TradeType } from '../api/trades';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import useUserRole from '../hooks/useUserRole';
import { getFranchiseList } from '../api/franchise';

const labels = {
  name: 'Trade',
  isActive: 'Active',
  isDefault: 'Set as default in new Branch',
};

const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  isActive: yup.boolean().required().label(labels.isActive),
  isDefault: yup.boolean().label(labels.isDefault),
});

const TradesForm = ({
  onSubmit,
  modalError,
  initialValues,
  addButtonText = 'Add',
  updateButtonText = 'Update',
  initialErrors,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    formState,
  } = useForm<TradeType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as TradeType),
  });
  const { isSuperAdmin } = useUserRole();

  const isDeleted = initialValues?.isDeleted;

  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (isSuperAdmin) {
      setIsLoading(true);

      Promise.all([
        getFranchiseList({
          ...GET_ALL_QUERY_PARAMS,
          ordering: 'name',
        }),
      ])
        .then(([franchisesResponse]) => {
          const franchises = franchisesResponse.data.results.map(
            item => item.id,
          );

          setValue(
            'franchise',
            franchises.filter(Boolean).length
              ? franchises
              : undefined,
          );
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, []);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<TradeType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: TradeType) => void,
  ) => (data: TradeType) => {
    submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      franchise: data.franchise
        ? (data.franchise as string)
            .split(',')
            .map(parseFloat)
        : undefined,
    });
  };

  return (
    <Container
      disabled={
        isLoading ||
        isDeleted ||
        (isSubmitting && !modalError)
      }
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            InputProps={{
              readOnly: !isSuperAdmin,
            }}
            isRequired={isYupFieldRequired('name', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="isActive"
            control={control}
            valueName="checked"
            defaultValue={
              initialValues
                ? initialValues.isActiveStatus.toLowerCase() ===
                  'true'
                : true
            }
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.isActive}
                label={labels.isActive}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        {isSuperAdmin && (
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
        )}

        <input
          type="hidden"
          name="franchise"
          ref={register}
        />

        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: TradeType;
  onSubmit: (data: TradeType) => void;
  initialErrors?: FormServerError<TradeType>;
  modalError: boolean;
}

export default TradesForm;
