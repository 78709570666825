import styled from 'styled-components';
import { Paper } from '@material-ui/core';

interface Props {
  variant?: string;
}

export default styled(Paper).attrs((props: Props) => ({
  variant: props.variant || 'outlined',
}))`
  border-radius: 10px;
  border: 0;
`;
