import * as React from 'react';
import AuthLayout from '../layouts/AuthLayout';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';

const ForgotPasswordPage = () => {

  return (
    <AuthLayout mainText="Forgot Password">
      <ForgotPasswordForm
      />
    </AuthLayout>
  );
};

interface FormErrors {
  [s: string]: string;
  email: string;
}

interface FormData {
  email: string;
}

export default ForgotPasswordPage;
