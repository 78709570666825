/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { ReportTableType, getDailyInspectionReport, getFormsReport } from '../../../api/reports';
import { ButtonWithLoader, InputBaseContainer, InputSelect } from '../../../molecules';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { Controller, useForm } from 'react-hook-form';
import { labels, selectStyles } from './InspectorReportSchema';
import { ButtonMain, DatePicker } from '../../../atoms';
import { DATETIME_FORMAT, DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS } from '../../../constants';
import { getUsers } from '../../../api/users';
import { USER_TYPES_VALUES } from '../../../constants/forms';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { format } from 'date-fns';
import { setPermitInspectionsList } from '../../../redux/slices/inspectionsSlice';
import MobileRecord from '../../Responsive/MobileRecord';
import { formatDateTime } from '../../../utils/formatDate';
import PrintRecrod from './PrintRecrod';
import InspectionFormPopup from './InspectionFormPopup';
import { setRecords, setReportsFormLoader, setShowFormPopup } from '../../../redux/slices/InspectionsReportSlice';
//Sample at /forms/GenerateEmployeeReportForm.tsx
const InspectorReports = () => {
  const { errors, control, setError, trigger, handleSubmit, watch } = useForm<ReportTableType>({});
  const [isFetching, setIsFetching] = useState(false);
  const [inspectorsData, setInspectorsData] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const printRef = React.useRef<HTMLDivElement>();
  const permitInspectionsList = useSelector((s: RootState) => s.inspections.permitInspectionsList);
  const dispatch = useDispatch();
  const selectedReport = watch('report');
  const formsDate = watch('formsDate');
  const inspector: any = watch('inspector');

  useEffect(() => {
    const bool1 = inspector && inspector.id;
    const bool2 = selectedReport && selectedReport.value;
    const bool3 = formsDate && typeof formsDate === 'object';
    setDisabledSubmit(!(bool1 && bool2 && bool3));
  }, [selectedReport, formsDate, inspector]);

  const handlePrintDailyTable = useReactToPrint({
    content: () => printRef.current,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  React.useEffect(() => {
    setIsFetching(true);
    getUsers({
      ...GET_ALL_QUERY_PARAMS,
      userRole: USER_TYPES_VALUES.INSPECTOR,
    })
      .then(response => {
        setInspectorsData(response.data.results);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));

    return () => {
      dispatch(setPermitInspectionsList([]));
    };
  }, []);

  const dailyReportItem = [
    {
      value: 'dailyInspectionReport',
      label: 'Daily Inspection Report',
    },
  ];

  const onSubmit = data => {
    setSubmitClicked(true);
    const fromDate = format(data.formsDate, 'MM/dd/yyyy');
    const params = {
      fromDate,
      toDate: fromDate,
      inspector: data.inspector.id,
    };
    const loadData = async () => {
      const result = await getDailyInspectionReport(params);
      dispatch(setPermitInspectionsList(result.data.data));
    };
    loadData();
  };
  const inspectionForm = async () => {
    try {
      dispatch(setShowFormPopup(true));
       const payload = {
        inspector: inspector.id,
        date: formatDateTime(formsDate, 'MM/dd/yyyy')
       }
       const results = await getFormsReport(payload);
       
       //@ts-ignore
       const recordsNewIds = results.data.map((item,index) => ({
        ...item,
        id: index
       }));
       dispatch(setRecords(recordsNewIds));
       dispatch(setReportsFormLoader(false));
    } catch (e) {
      // TODO
    }
  };

  const data = [
    { label: 'Project Name', key1: 'projectName' },
    { label: 'Permit Name', key1: 'permitName' },
    { label: 'Address', key1: 'projectAddress' },
    { label: 'Information', key1: 'information' },
    { label: 'Permit Number', key1: 'permit' },
    { label: 'Inspection ID', key1: 'id' },
    { label: 'Inspection Type', key1: 'activityTypeName' },
    { label: 'Project Manager', key1: 'managerFirstName', key2: 'managerLastName' },
    { label: 'Jurisdiction', key1: 'jurisdiction' },
    { label: 'AM/PM', key1: 'period' },
    { label: 'Contact', key1: 'contactFirstName', key2: 'contactLastName' },
    { label: 'Contact Details', key1: 'phoneNumber' },
  ];
  return (
    <DashboardLayout NoSubMenu={true}>
      <ControlBar>
        <FormContainer autoComplete="off" aria-autocomplete="none" onSubmit={handleSubmit(onSubmit)}>
          <InputBaseContainer>
            <Controller
              name="report"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelect
                  placeholder="Select report"
                  isSearchable={false}
                  styles={selectStyles}
                  error={errors.report}
                  options={dailyReportItem}
                  isRequired={true}
                  // isDisabled={isFetching}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <DateWrapper>
              <Controller
                name="formsDate"
                defaultValue=""
                control={control}
                render={({ value, onChange }) => (
                  <DatePicker
                    disabled={isFetching}
                    onChange={onChange}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    selected={value}
                    placeholderText="Select Date"
                    required
                  />
                )}
              />
            </DateWrapper>
          </InputBaseContainer>
          <InputBaseContainer>
            <Controller
              name="inspector"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelect
                  isSearchable={false}
                  styles={selectStyles}
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  error={errors.inspector}
                  getOptionValue={(item: any) => item.id || Math.random()}
                  getOptionLabel={(item: any) => `${item?.firstName} ${item?.lastName}`}
                  placeholder={isFetching ? '' : 'Select Inspector'}
                  options={inspectorsData}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
          <ButtonContainer>
            <ButtonMain
              type="submit"
              disabled={disabledSubmit}
              // onClick={() => setInspectionFormClick(false)}
            >
              Submit
            </ButtonMain>
            <ButtonWithLoader
              variant="outlined"
              loading={isLoading}
              disabled={permitInspectionsList.length === 0}
              onClick={handlePrintDailyTable}
            >
              Export to PDF
            </ButtonWithLoader>
            <ButtonWithLoader
              disabled={permitInspectionsList.length === 0}
              type="submit"
              variant="outlined"
              loading={isLoading}
              style={{ marginLeft: '1rem' }}
              onClick={inspectionForm}
            >
              Inspection Form
            </ButtonWithLoader>
          </ButtonContainer>
          
        </FormContainer>
      </ControlBar>
      <InspectionFormPopup />
      <ReportBody>
        {((permitInspectionsList.length > 0 && inspector) || submitClicked) && (
          <PrintArea ref={printRef}>
            <TableWrapper>
              <Title>Daily Inspection Report</Title>

              <List>
                <li>
                  <Bold>Inspector: </Bold> {`${inspector.firstName} ${inspector.lastName}`}
                </li>
                <li>
                  <Bold>Inspection Date: </Bold>
                  {formatDateTime(formsDate, 'MM/dd/yyy')}
                </li>
                <li>
                  <Bold>Report Date: </Bold>
                  {formatDateTime(new Date(), DATETIME_FORMAT)}
                </li>
                <li>
                  <Bold>Total Inspections: </Bold>
                  {permitInspectionsList.length}
                </li>
              </List>
            </TableWrapper>
            <PrintRecrod rows={permitInspectionsList} />
            {permitInspectionsList.map((item, index) => (
              <PrintHide>
                <MobileRecord fields={data} item={item} key={item.id} shaded={index % 2 === 0} />
              </PrintHide>
            ))}
          </PrintArea>
        )}

        {!submitClicked && <NoRecords>Please, select Report from top menu and click on «Submit» button.</NoRecords>}
      </ReportBody>
    </DashboardLayout>
  );
};

const PrintArea = styled.div`
  margin: 10px;
`;

const NoRecords = styled.div`
  margin: 0 auto;
  color: var(--secondaryText);
  text-align: center;
  margin-top: 24px;
`;

const PrintHide = styled.div`
  @media print {
    display: none;
  }
`;

const ControlBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 103%;
  background-color: var(--secondary);
  height: 50px;
  margin-left: -24px;
  justify-content: flex-end;
  padding-right: 25px;
  @media only screen and (max-width: 1025px) {
    height: 130px;
  }
`;

const ReportBody = styled.div`
  flex-grow: 1;
`;

const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const DateWrapper = styled.div`
  input {
    cursor: pointer;
    width: 148px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 16px;
    font-size: 14px;
    &::placeholder {
      color: #808080;
      font-size: 16px;
      position: relative;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`;

const TableWrapper = styled.div``;

const Bold = styled.span`
  color: var(--mainText);
  font-weight: bold;
`;
const Title = styled.h1`
  margin: 0;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 8px 0;
    font-size: 18px;
    color: var(--primary);
  }
`;
export default InspectorReports;
