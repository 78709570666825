import * as React from 'react';
import styled from 'styled-components';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import Table from '../../organisms/Table';
import useTablePage from '../../hooks/useTablePage';
import { convertTableBoolean } from '../../utils/convertTableValues';
import {
  createPermitActivity,
  getActivitiesList,
  getActivitiesStatusList,
  getPermitActivities,
  getCustomActivities,
  updatePermitActivity,
} from '../../api/activities';
import openInNewTab from '../../utils/openInNewTab';
import ModalDefaultDraggable from '../../molecules/ModalDefaultDraggable';
import ModalInfo from '../../molecules/ModalInfo';
import { getVocabulariesDataList } from '../../api/vocabulary';
import {
  DATETIME_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../../constants';
import { formatDateTime } from '../../utils/formatDate';
import PermitActivitiesForm from '../../forms/PermitActivitiesForm';

const ConditionsPage = ({
  permit,
  setAddCondition,
  isAddCondition,
  cameFromSearch,
}: Props) => {
  const {
    search,
    handleEdit,
    handleAdd,
    tableManuallyUpdate,
    modalSuccess,
    modalError,
    handleCloseModal,
    handleCloseModalInfo,
    isModalOpen,
    modalTitle,
    createOrUpdate,
    modalErrorTitle,
    values,
    errors,
  } = useTablePage({
    modalUpdateTitle: 'Update Condition',
    modalCreateTitle: 'Add Condition',
    createEndpoint: createPermitActivity,
    updateEndpoint: updatePermitActivity,
  });
  const [
    conditionsVocabularyId,
    setConditionsVocabularyId,
  ] = React.useState(null);

  React.useEffect(() => {
    getVocabulariesDataList({
      ...GET_ALL_QUERY_PARAMS,
      vocabularyEntity: 3,
    }).then(response =>
      setConditionsVocabularyId(
        response.data.results?.find(
          el => el?.title === 'Conditions',
        )?.id,
      ));
  }, []);

  React.useEffect(() => {
    if (isAddCondition) {
      handleAdd();
    }
  }, [isAddCondition]);

  React.useEffect(() => {
    if (modalSuccess && !modalError) {
      setAddCondition(false);
    }
  }, [modalError, modalSuccess]);

  const columnsOrder = [
    'activityType',
    'title',
    'conditionName',
    'status',
    'contact',
    'followUp',
    'assignedTo',
    'createdAt',
    'createdBy',
    'completedAt',
    'completedBy',
    'attachments',
  ];

  const columns = column => {
    if (
      [
        'id',
        'permit',
        'documentFile',
        'information',
        'inspector',
        'isEmergency',
        'amount',
        'reviewType',
        'isCompleted',
        'lastComment',
        'reviewTypeName',
        'fees',
        'feeNames',
        'permitName',
        'permitNumber',
        'permitId',
        'contactFirstName',
        'contactLastName',
        'assignedToFirstName',
        'assignedToLastName',
        'companyName',
        'activityTitleName',
        'activityTypeName',
        'projectName',
        'projectJurisdiction',
        'statusName',
        'type',
        'projectId',
        'contact',
        'contactId',
        'createdAt',
        'createdBy',
        'completedAt',
        'completedBy',
        'attachmentsId',
        'attachmentsFile',
        'selectedDocuments',
        // 'attachments',
        'reviewTypeComment',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'title':
        return 'Activity Title';
      case 'status':
        return 'Activity Status';
      case 'description':
        return 'Condition description';
      case 'attachments':
        return 'Attachment';
      case 'createdAt':
        return 'Date Created';
      case 'completedAt':
        return 'Date Completed'
      default:
        return tableColumnsFormatter(column);
    }
  };

  const modifiedGetConditionsRequest = params => {
    return Promise.all([
      // getPermitActivities({
      //   ...params,
      //   activityType: conditionsVocabularyId,
      // }),
      getCustomActivities({
        ...params,
        permit_id:permit,
        // activityType: conditionsVocabularyId,
        sort_by: params?.ordering,
        ordering:null,
        event_type:"Activity",
        activity_type_name:"Conditions",
      }),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 3,
        ordering: 'title',
      }),
      getActivitiesList({ ...GET_ALL_QUERY_PARAMS }),
      getActivitiesStatusList({ ...GET_ALL_QUERY_PARAMS }),
    ]).then(
      ([
        conditionsResponse,
        typesResponse,
        titlesResponse,
        statusesResponse,
      ]) => {
        return {
          ...conditionsResponse,
          data: {
            ...conditionsResponse.data,
            results: conditionsResponse.data.results.map(
              el => {
                const contactName = `${el?.contactFirstName} ${el?.contactLastName}`;
                const assigneeName = `${el?.assignedToFirstName} ${el?.assignedToLastName}`;
                return {
                  ...el,
                  activityType: typesResponse?.data?.results?.find(
                    type => type?.id === el?.activityType,
                  )?.title,
                  title: titlesResponse?.data?.results?.find(
                    title => title?.id === el?.title,
                  )?.name,
                  status: statusesResponse?.data?.results?.find(
                    status => status?.id === el?.status,
                  )?.name,
                  contact: contactName,
                  followUp: formatDateTime(
                    el?.followUp,
                    DATETIME_FORMAT,
                  ),
                  assignedTo: assigneeName,
                  attachments:
                    el.attachments &&
                    el.attachments?.map(
                      file => file?.visibleFilename,
                    ),
                  attachmentsId: el.attachments &&
                    el.attachments?.map(
                      file => file?.id
                    ),  
                  attachmentsFile: el.attachments &&
                    el.attachments?.map(
                      file => file?.file
                    ),                 
                  documentFile:
                    el.attachments &&
                    el.attachments?.map(doc => {
                      return doc?.file;
                    }),
                };
              },
            ),
          },
        };
      },
    );
  };

  const handleCellClick = rowValue => {
    if (
      rowValue.documentFile &&
      Array.isArray(rowValue.documentFile)
    ) {
      const link = rowValue.documentFile[0];
      openInNewTab(link);
    } else {
      openInNewTab(rowValue.documentFile);
    }
  };

  const cellCallbackFnIsOn = ['attachments'];

  const useSortBySelectedColumns = [
    'title',
    'status',
    'attachments',
    'description',
  ];

  const initialSortBy = [
    {
      id: 'status',
      desc: true,
    },
  ];

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      setAddCondition(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <Container>
        {conditionsVocabularyId ? (
          <Table
            customSearch
            initialUseSortBy
            permitConditionTable
            searchQuery={search}
            columnsOrder={columnsOrder}
            columns={columns}
            colorSchema
            rows={modifiedGetConditionsRequest}
            onEdit={handleEdit}
            onDelete={null}
            onConfigure={null}
            formatValue={convertTableBoolean}
            manuallyUpdate={tableManuallyUpdate}
            handleCellClick={handleCellClick}
            cellCallbackFnIsOn={cellCallbackFnIsOn}
            isMultipleDocuments
            // initialUseSortBySelectedColumns={
            //   useSortBySelectedColumns
            // }
            // initialSortBy={initialSortBy}
            isPermitActivitiesPage={true}
            useViewModeInsteadEdit={cameFromSearch}
            italic
          />
        ) : null}
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <PermitActivitiesForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            conditionsMode
            cameFromSearch={cameFromSearch}
          />
        </ModalDefaultDraggable>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  permit: number;
  setAddCondition: (data: boolean) => void;
  isAddCondition: boolean;
  cameFromSearch?:boolean;
}

const Container = styled.div`
  margin: 24px 0 0 0;
  width: 100%;
`;

export default ConditionsPage;
