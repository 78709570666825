import * as React from 'react';
import {
  Redirect,
  Route,
  useLocation,
  RouteComponentProps,
} from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { isTokensInStore } from '../auth';

const PrivateRoute = ({
  component: Component,
  ...rest
}: Props) => {
  const location = useLocation();
  const isAuthenticated = isTokensInStore();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={props =>
        isAuthenticated ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOG_IN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;

interface Props {
  path: string;
  exact?: boolean;
  strict?: boolean;
  sensitive?: boolean;
  component?: React.FC<RouteComponentProps<any>>;
}
