import * as React from 'react';
import {
  TextField,
  TextFieldProps,
  withStyles,
} from '@material-ui/core';

import theme from '../theme';

const TextInput = ({
  variant = 'outlined',
  ...props
}: TextFieldProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Input variant={variant} {...props} />
);

const Input = withStyles({
  root: {
    borderRadius: 1,
    backgroundColor: theme.defaultBackground,
    '& legend > span': {
      display: 'none',
    },
    '& .MuiInputBase-root': {
      borderRadius: 1,
    },
    '& .MuiInputLabel-animated': {
      transition: 'none',
      animation: 'none',
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      display: 'none',
    },
  },
})(TextField);

export default TextInput;
