import * as React from 'react';
import styled from 'styled-components';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getTrades,
  createTrade,
  updateTrade,
  deleteTrade,
  TradeType,
} from '../../api/trades';
import Table from '../../organisms/Table';
import ActionsPanel from '../../organisms/ActionsPanel';
import useTablePage from '../../hooks/useTablePage';
import { convertTableBoolean } from '../../utils/convertTableValues';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import TradesForm from '../../forms/TradesForm';
import useUserRole from '../../hooks/useUserRole';

const TradesPage = () => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    modalErrorTitle,
    errors,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<TradeType>({
    createEndpoint: createTrade,
    updateEndpoint: updateTrade,
    deleteEndpoint: deleteTrade,
    modalCreateTitle: 'Add Trade',
    modalUpdateTitle: 'Update Trade',
  });
  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'franchise',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    if (column === 'name') {
      return 'Trade';
    }

    if (column === 'isActiveStatus') {
      return 'Is Active';
    }

    return tableColumnsFormatter(column);
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={isSuperAdmin && handleAdd}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={getTrades}
          onEdit={handleEdit}
          onDelete={isSuperAdmin && handleDelete}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <TradesForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

export default TradesPage;
