import * as React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonMain, DatePicker } from '../atoms';
import { FormServerError } from '../types';
import {
  DEFAULT_DATE_FORMAT,
  GET_ALL_QUERY_PARAMS,
} from '../constants';
import {
  ReportType,
  ReportTableType,
} from '../api/reports';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import {
  InputSelect,
  ButtonWithLoader,
  InputBaseContainer,
  InputDatepickerRange,
  CheckboxWithLabel,
} from '../molecules';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import { FranchiseType, getFranchiseList } from '../api/franchise';
import { SALES_REPORTS_DATA, SALES_REPORTS_DATA_FA } from '../__DATA__';
import useUserRole from '../hooks/useUserRole';
import { breakLines } from '../modules/clientModule/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setFranchisesForReport } from '../redux/slices/InspectionsReportSlice';
import { RootState } from '../redux/root';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '180px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};

const schema = yup.object().shape({
  report: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a report from the list')
    .label('Report'),
    // adding schema validaton for branches super admin dropdown
    branches: yup.array().when('isSuperadmin', {
      is: true,
      then: yup.array().min(1, 'At least one branch is required').typeError('Select at least one Branch from the list'),
      otherwise: yup.array(),
    }),
  range: yup
    .array()
    .of(yup.date())
    // .required()
    .typeError('Select date')
    .label('Range'),
  franchises: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    // .required()
    .typeError('Select Franchises from the list')
    .nullable()
    .label('Franchises'),
});
const labels = {
  paidFees: "Paid Fees",
  adminSalesReport: "Admin Sales Report",
  daily: "Daily",
  reimbursables: 'Reimbursables'
}

const GenerateReportForm = ({
  onSubmit,
  isLoading,
  onExportToPDF,
  initialValues,
  initialErrors,
}: Props) => {
  const {
    errors,
    control,
    setError,
    trigger,
    getValues,
    handleSubmit,
    watch
  } = useForm<ReportTableType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const selectedReport = watch('report')
  const selectedFrequency = watch('frequency')
  const selectedBranches: FranchiseType[] = watch('branches');
  const franchise = useSelector((s:RootState) => s.userAuth.info.franchise)
  const dispatch = useDispatch();
 
  const {isSuperAdmin, isFranchiseAdmin} = useUserRole()

  const frequencyData = [
    {
      id: 1,
      name: 'Daily'
    },
    {
      id: 2,
      name: 'Monthly'
    }
  ]
  
  const reportTypeData = [
    {
      id: 1,
      name: 'Summary'
    },
    {
      id: 2,
      name: 'Detail'
    }
  ]

  const [isFetching, setIsFetching] = React.useState(false);
  const [
    franchisesData,
    setFranchisesData,
  ] = React.useState([]);

  React.useEffect(() => {
    setIsFetching(true);

    getFranchiseList({
      ...GET_ALL_QUERY_PARAMS,
      ordering: 'name',
    })
      .then(response =>
        setFranchisesData(response.data.results))
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<ReportTableType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  const middleware = (
    submitFn: (data: ReportType) => void,
  ) => (data: ReportTableType) => {
    
    const franchises = data.franchises
      ?.filter(el => el?.value !== '*')
      ?.map(el => +el?.id)
      ?.join(',');

    if (franchises === '') {
      setError('franchises', {
        type: 'required',
        message: 'Franchises is a required field',
      });
    }
    if(data?.report?.label ==="A/R Aging Summary")
      return submitFn({...data,
        report: data.report.value,  
      })

    if(data?.report?.label ==="A/R Aging Details"){
      const brnchId = selectedBranches ? selectedBranches.reduce((str,item)=>{
        if(str.length > 0){
          str = `${str},${item.id}`;
        } else {
          str = `${item.id}`;
        }
        return str;
      },'') : [franchise[0].id].toString();
      dispatch(setFranchisesForReport(brnchId));
        
      return submitFn({...data,
        report: data.value,  
      })
      
    } 
       
      
      
    return submitFn({
      ...data,
      isDeleted: initialValues?.isDeleted || data.isDeleted,
      fromDate: data?.range ? format(data.range[0], DEFAULT_DATE_FORMAT) : format(data?.fromDate, DEFAULT_DATE_FORMAT),
      toDate: data?.range ? format(data.range[1], DEFAULT_DATE_FORMAT) : null,
      report: data.report.value,
      franchises,
    });
  };

  const handleExportToPDF = async () => {
    try {
      const isValid = await trigger();
      if (isValid) {
        middleware(onExportToPDF)(getValues());
      }
    } catch (e) {
      // TODO
    }
  };

  return (
    <Container>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="report"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelect
                placeholder="Select report"
                isSearchable={false}
                styles={selectStyles}
                error={errors.report}
                options={isFranchiseAdmin ? SALES_REPORTS_DATA_FA : SALES_REPORTS_DATA}
                isRequired={isYupFieldRequired(
                  'report',
                  schema,
                )}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {/* Franchise Dropdown for super admin  */}
        {selectedReport && selectedReport?.label !== labels.adminSalesReport && isSuperAdmin && <InputBaseContainer>
          <Controller
            name="branches"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelect
                isMulti
                isSearchable={true}
                styles={selectStyles}
                isLoading={isFetching}
                isDisabled={isFetching}
                error={errors.branches}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                placeholder={
                  isFetching
                    ? 'Branches'
                    : 'Select Branches'
                }
                isRequired={isYupFieldRequired(
                  'branches',
                  schema,
                )}
                options={franchisesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>}

        {/* Frequency dropdown for paid fees report  */}
        {selectedReport && selectedReport?.label === labels.paidFees && <InputBaseContainer>
          <Controller
            name="frequency"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelect
                isSearchable={false}
                styles={selectStyles}
                isLoading={isFetching}
                isDisabled={isFetching}
                error={errors.frequency}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                placeholder={
                  isFetching
                    ? 'frequency'
                    : 'Select frequency'
                }
                isRequired={isYupFieldRequired(
                  'frequency',
                  schema,
                )}
                options={frequencyData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>}
        {/* date picker for daily */}
        {selectedFrequency && selectedFrequency?.name === labels.daily && <InputBaseContainer>
            <DateWrapper>
              <Controller
                name="fromDate"
                defaultValue=""
                control={control}
                render={({ value, onChange }) => (
                  <DatePicker
                    disabled={isFetching}
                    onChange={onChange}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    selected={value}
                    placeholderText="Select Date"
                    required
                  />
                )}
              />
            </DateWrapper>
          </InputBaseContainer>}

          {/* fee report type dropdown */}
          {selectedFrequency && <InputBaseContainer>
          <Controller
            name="reportType"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelect
                isSearchable={false}
                styles={selectStyles}
                isLoading={isFetching}
                isDisabled={isFetching}
                error={errors.reportType}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                placeholder={
                  isFetching
                    ? 'Report Type'
                    : 'Report Type'
                }
                isRequired={isYupFieldRequired(
                  'reportType',
                  schema,
                )}
                options={reportTypeData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>}
        {selectedFrequency && selectedFrequency?.name !== labels.daily && <InputBaseContainer>
          <Controller
            name="range"
            defaultValue=""
            control={control}
            render={({ value, name, onChange, onBlur }) => (
              <InputDatepickerRange
                name={name}
                placeholderText="Select Date"
                error={
                  errors.range ||
                  errors.fromDate ||
                  errors.toDate
                }
                dateFormat={DEFAULT_DATE_FORMAT}
                // isRequired={isYupFieldRequired(
                //   'range',
                //   schema,
                // )}
                onRangeChange={(start, end) => {
                  if (start && end) {
                    onChange([start, end]);
                  }
                }}
                onBlur={onBlur}
                initialDates={value}
              />
            )}
          />
        </InputBaseContainer>}
        {selectedFrequency && <InputBaseContainer>
            <Controller
              name="reimbursables"
              control={control}
              valueName="checked"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.reimbursables}
                  label={labels.reimbursables}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
        </InputBaseContainer>}
        {selectedFrequency && selectedFrequency?.name !== labels.daily && selectedReport?.label !== labels.paidFees && <InputBaseContainer>
          <Controller
            name="franchises"
            defaultValue=""
            control={control}
            render={props => (
              <StyledInputSelect
                isMulti
                selectAllActive
                isSearchable={false}
                styles={selectStyles}
                isLoading={isFetching}
                isDisabled={isFetching}
                error={errors.franchises}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                placeholder={
                  isFetching
                    ? 'Franchises'
                    : 'Select Franchises'
                }
                // isRequired={isYupFieldRequired(
                //   'franchises',
                //   schema,
                // )}
                options={franchisesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>}
        <ButtonContainer>
          <ButtonMain type="submit">Submit</ButtonMain>
          <ModifiedButtonWithLoader
            variant="outlined"
            loading={isLoading}
            onClick={handleExportToPDF}
          >
            Export to PDF
          </ModifiedButtonWithLoader>
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  > .MuiButton-root {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;
const DateWrapper = styled.div`
  input {
    cursor: pointer;
    width: 148px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 16px;
    font-size: 14px;
    &::placeholder {
      color: #808080;
      font-size: 16px;
      position: relative;
    }
  }
`;
const ModifiedButtonWithLoader = styled(ButtonWithLoader)`
  white-space: nowrap;
`;

const StyledInputSelect = styled(InputSelect)`
  width: 400px;
`;

export interface Props {
  isLoading?: boolean;
  initialValues?: ReportTableType;
  onSubmit: (data: ReportTableType) => void;
  onExportToPDF: (data: ReportTableType) => void;
  initialErrors?: FormServerError<ReportTableType>;
}

export default GenerateReportForm;
