import * as React from 'react';
import styled from 'styled-components';

import { ErrorCheckmark, SuccessCheckmark } from '../atoms';
import ModalDefaultDraggable from './ModalDefaultDraggable';

const ModalInfo = ({
  title = 'Success',
  timeToClose = 5000,
  onClose,
  isError = false,
  errorMsg = 'Error: Unable to perform action',
}: Props) => {
  React.useEffect(() => {
    let timer;

    if (timeToClose && !timer) {
      timer = setTimeout(() => {
        onClose();
      }, timeToClose);
    }

    return () => timeToClose && clearTimeout(timer);
  }, [timeToClose]);

  return (
    <ModalDefaultDraggable
      title={isError ? errorMsg : title}
      onClose={onClose}
    >
      <Container>
        {isError ? (
          <ErrorCheckmark />
        ) : (
          <SuccessCheckmark />
        )}
      </Container>
    </ModalDefaultDraggable>
  );
};

const Container = styled.div`
  text-align: center;
`;

interface Props {
  title?: string;
  timeToClose?: number;
  isError?: boolean;
  onClose: () => void;
  errorMsg?: string;
}

export default ModalInfo;
