import React from 'react';
import styled from 'styled-components';

const PayPopup = () => {
  return <Wrapper>Payment Message will be here</Wrapper>;
};

export default PayPopup;

const Wrapper = styled.div`
  font-size: 45px;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;
