import { useDispatch, useSelector } from "react-redux";
import { setInspections } from "../../redux/slices/inspectionsSlice";
import { endOfMonth, format } from 'date-fns';
import { setShowCompleteInspectionsPopup } from "../../redux/slices/dashboardSlice";
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import { getPermitInspections } from '../../api/inspections';
import { setRowsCount } from '../../redux/slices/paginationSlice';
import { RootState } from "../../redux/root";

const useInspectionGraphUtil = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((s:RootState) => s.userAuth.info);
  const filters = useSelector((s:RootState) => s.filters.filters);
  const { pageSize, pageIndex } = useSelector((s: RootState) => s.pagination);

  const loadRecords = async (yearMonth:{year:number,month:number}) => {
    const {year, month} = yearMonth;
    dispatch(setInspections([]));
    const thisMonth = new Date(year, month - 1, 1);
    const date_from = new Date(`${month}/01/${year}`);
    const date_to = endOfMonth(thisMonth);
    dispatch(setShowCompleteInspectionsPopup(true));
    const params = {
      ...GET_ALL_QUERY_PARAMS,
      ordering: '-is_emergency,follow_up',
      date_from: format(date_from, 'yyyy-MM-dd'),
      date_to: format(date_to, 'yyyy-MM-dd'),
      inspector: userInfo.id,
      is_completed: true,
      graph: true,
      limit: pageSize,
      offset: pageIndex,
      is_active: null,
    };
    if(filters.length > 0 ){
      const name = filters[0].filterName;
      const value = filters[0].filterValue;
      if(value){
        params[name] = value;
      }
      
    }

    const result = await getPermitInspections(params);
    //@ts-ignore
    dispatch(setInspections(result.data.results));
    dispatch(setRowsCount(result.data.count));
  };

  return {
    loadRecords
  }
}


  export default useInspectionGraphUtil;