import * as React from 'react';
import useTablePage from '../hooks/useTablePage';
import { createUser } from '../api/users';
import UsersForm from '../forms/UsersForm';

const PermitAddContact = ({
  setIsContactFormSubmitting,
  setAddContactModal,
}: Props) => {
  const {
    createOrUpdate,
    modalSuccess,
    modalErrorTitle,
    values,
    errors,
    isApiSuccess
  } = useTablePage({
    createEndpoint: createUser,
    modalCreateTitle: 'Add Contact',
  });

  React.useEffect(() => {
    if (isApiSuccess) {
      setAddContactModal(false);      
    }
  }, [isApiSuccess]);

  return (
    <UsersForm
      setIsContactFormSubmitting={
        setIsContactFormSubmitting
      }
      initialValues={values}
      modalError={modalErrorTitle.length > 0}
      initialErrors={errors}
      onSubmit={createOrUpdate}
    />
  );
};

interface Props {
  setIsContactFormSubmitting?: (data) => void;
  setAddContactModal?: (data: boolean) => void;
}

export default PermitAddContact;
