import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { getTableLoadDataMode } from '../../settings';
import { TABLE_LOAD_DATA_MODE } from '../../constants/settings';

const initialState: UserSettingsStateType = {
  tableLoadDataMode:
    getTableLoadDataMode() || TABLE_LOAD_DATA_MODE.ACTIVE,
};

const reducerName = 'userSettings';

const slice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    changeTableLoadMode: (
      state,
      action: PayloadAction<TABLE_LOAD_DATA_MODE>,
    ) => {
      state.tableLoadDataMode = action.payload;
    },
  },
});

type PartialRootState = {
  [reducerName]: ReturnType<typeof slice.reducer>;
};

export const tableLoadModeSelector = (
  state: PartialRootState,
) => state.userSettings.tableLoadDataMode;

export interface UserSettingsStateType {
  tableLoadDataMode: TABLE_LOAD_DATA_MODE;
}

export const { changeTableLoadMode } = slice.actions;

export default slice.reducer;
