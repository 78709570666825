import * as React from 'react';
import styled from 'styled-components';

const StatisticBlock = ({ label, value }: Props) => (
  <Container>
    <strong>{label}</strong>
    {value}
  </Container>
);

const Container = styled.div`
  display: flex;
  padding: 10px;
  min-width: 160px;
  min-height: 72px;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1rem;
  border-radius: 2px;
  flex-direction: row;
  justify-content: center;
  background-color: var(--secondary);

  > strong {
    width: 100%;
    display: block;
  }
`;

interface Props {
  label: string;
  value: string | number;
}

export default StatisticBlock;
