import * as React from 'react';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { useDispatch, useSelector } from 'react-redux';
import { capitalCase } from 'change-case';
import styled from 'styled-components';

import { CustomizedDialogs } from '../atoms';
import {
  clearRequestError,
  requestApiErrorObj,
} from '../redux/ducks/requestApiError';

const ModalErrorRequest = () => {
  const dispatch = useDispatch();

  const requestApiError = useSelector(requestApiErrorObj);

  const handleClose = () => {
    dispatch(clearRequestError());
  };

  return (
    <StyledCustomizedDialogs onClose={handleClose}>
      <Wrapper>
        <WarningRoundedIcon
          style={{
            fontSize: 84,
            color: `var(--primary)`,
          }}
        />
        <Title>
          <span>{requestApiError.status}</span>
          {requestApiError.data &&
            Object.entries(
              requestApiError.data,
            ).map(([key, value]) => (
              <div key={key}>
                {Number.isNaN(Number(key)) &&
                key !== 'detail'
                  ? `${capitalCase(key)}: ${value}`
                  : value}
              </div>
            ))}
        </Title>
      </Wrapper>
    </StyledCustomizedDialogs>
  );
};

const Wrapper = styled.div`
  background-color: var(--secondary);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  padding: 20px 10px;
`;

const StyledCustomizedDialogs = styled(CustomizedDialogs)`
  position: fixed;
  z-index: var(--modalZIndex);
`;

const Title = styled.span`
  display: block;
  text-align: center;
  color: var(--mainText);

  > span {
    display: block;
    font-weight: bold;
    font-size: 1.125rem;
    margin: 10px 0;
  }
`;

export default ModalErrorRequest;
