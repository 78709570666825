import * as React from 'react';
import styled from 'styled-components';

import { TextField } from '@material-ui/core';

interface Props {
  label: string;
  error?: boolean;
  helperText?: string;
  className?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: string;
  onChange?: (e: React.MouseEvent) => void;
  value: string;
  isValid?: boolean;
  readonly?: boolean;
}

const TextInputSecondary = ({
  label,
  error = false,
  className = '',
  size = 'normal',
  variant = 'filled',
  onChange,
  value,
  helperText,
  isValid = true,
  readonly = false,
}: Props) => (
  <Input
    label={label}
    error={error}
    variant={variant}
    className={className}
    size={size}
    value={value}
    helperText={helperText}
    onChange={onChange}
    InputProps={{ readOnly: readonly }}
    isValid={isValid}
    readonly={readonly}
  />
);

interface InputProps {
  isValid: boolean;
  readonly: boolean;
}

// eslint-disable-next-line react/jsx-props-no-spreading
const Input = styled(({ isValid, readonly, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...rest} />
))<InputProps>`
  position: relative;

  .MuiFilledInput-root {
    background-color: var(--defaultBackground);
  }

  &.Mui-focused {
    color: var(--secondaryText);
  }

  .MuiFilledInput-underline:before {
    border-bottom-color: var(--border);
  }

  & .MuiFilledInput-underline:after {
    border-bottom-color: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.readonly
        ? props.theme.border
        : props.isValid
        ? props.theme.primary
        : props.theme.primary};
  }

  .MuiInputLabel-root.Mui-focused {
    color: var(--secondaryText);
  }

  .MuiFormHelperText-root {
    position: absolute;
    margin: 0;
    bottom: -18px;
    left: 14px;
  }

  .MuiFormHelperText-root.Mui-error,
  .MuiFormLabel-root.Mui-error {
    color: var(--primary);
  }
`;

export default TextInputSecondary;
