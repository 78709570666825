/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';

import DashboardLayout from '../../layouts/DashboardLayout';

import ActionsPanelReports from '../../organisms/ActionsPanelReports';

import DailyInspectionReport from './DailyInspectionReport';
import RunnerReport from './RunnerReport';
import FormsReport from './FormsReport';
import { FormsReportType } from '../../api/reports';
import InspectionReportPermit from './InspectionReportPermit';
import BusinessToBeInvoicedReport from './BusinessToBeInvoicedReport';
import CompanyOutstandingFeesReport from './CompanyOutstandingFeesReport';
import NotInvoicedPermittingFeesReport from './NotInvoicedPermittingFeesReport';
import PaidFeesReport from './PaidFeesReport';
import InspectorTimeTrackingReport from './InspectorTimeTrackingReport';
import PlanReviewerTimeTrackingReport from './PlanReviewerTimeTrackingReport';
import { ModalDefaultDraggable } from '../../molecules';
import PermitReport from './PermitReport';

const labels = {
  dailyInspectionReport: 'dailyInspectionReport',
  runnerReport: 'runnerReport',
  formsReport: 'formsReport',
  inspectionByPermitReport: 'inspectionByPermitReport',
  businessToBeInvoicedReport: 'businessToBeInvoicedReport',
  companyOutstandingFeesReport:'companyOutstandingFeesReport',
  notInvoicedPermittingFeesReport: 'notInvoicedPermittingFeesReport',
  paidFeesReport: 'paidFeesReport',
  inspectorTimeTrackingReport: 'inspectorTimeTrackingReport',
  planReviewerTimeTrackingReport: 'planReviewerTimeTrackingReport',
  permitReviewCommand:'permitReviewCommand',
};

const EmployeeReports = () => {
  const printableRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const dailyTableRef = React.useRef();
  const handlePrintDailyTable = useReactToPrint({
    content: () => dailyTableRef.current,
  });

  const runnerTableRef = React.useRef();
  const handlePrintRunnerTable = useReactToPrint({
    content: () => runnerTableRef.current,
  });

  const permitReportTableRef = React.useRef()
  const handlePrintPermitTable = useReactToPrint({
    content: () => permitReportTableRef.current,
  });

  const businessInvoiceReportTableRef = React.useRef()
  const handlePrintBusinessInvoiceTable = useReactToPrint({
    content: () => businessInvoiceReportTableRef.current,
  });
  const companyOutstandingFeesReportTableRef = React.useRef()
  const handlecompanyOutstandingFeesReportTable = useReactToPrint({
    content: () => companyOutstandingFeesReportTableRef.current,
  });

  const notInvoicedPermittingFeesReportTableRef = React.useRef()
  const handleNotInvoicedPermittingFeesTable = useReactToPrint({
    content: () => notInvoicedPermittingFeesReportTableRef.current,
  });

  // EA-85
  // mritunjoy
  const paidFeesTableRef = React.useRef();
  const handlePaidFeesTable = useReactToPrint({
    content: () => paidFeesTableRef.current,
  });

  // EA-163
  // mritunjoy
  const timeTrackingTableRef = React.useRef();
  const handlePrintTimeTrackingTable = useReactToPrint({
    content: () => timeTrackingTableRef.current,
  });

  // EA-105
  // mritunjoy
  const planReviewerTimeTrackingReportTableRef = React.useRef();
  const handlePlanReviewerTimeTrackingReportTable = useReactToPrint({
    content: () => planReviewerTimeTrackingReportTableRef.current,
  });
  const permitReviewTableRef = React.useRef();
  const handlePermitReportTableRef = useReactToPrint({
    content: () => permitReviewTableRef.current,
  });
  const [
    isValidForExport,
    setIsValidForExport,
  ] = React.useState(false);

  const [
    reportsSettings,
    setReportsSettings,
  ] = React.useState(null);

  const [forms, setForms] = React.useState<
      Array<FormsReportType>
    >([]);
  const [key, setKey] = React.useState(1);

  const [
    selectedFormsReports,
    setSelectedFormsReports,
  ] = React.useState<Array<FormsReportType>>([]);

  const exportToPdf = response => {    
    if (selectedFormsReports?.length) {
      handlePrint();
    }

    if (
      response?.selectedReport ===
      labels.dailyInspectionReport
    ) {
      handlePrintDailyTable();
    } else if(
      response?.selectedReport ===
      labels.runnerReport
    ) {
      handlePrintRunnerTable();
    } else if(response?.selectedReport ===
      labels.businessToBeInvoicedReport) {
        handlePrintBusinessInvoiceTable()
    } else if(response?.selectedReport ===
      labels.notInvoicedPermittingFeesReport) {
        handleNotInvoicedPermittingFeesTable()
    } else if(response?.selectedReport ===
      labels.companyOutstandingFeesReport) {
        handlecompanyOutstandingFeesReportTable()
    } else if(response?.selectedReport ===
      labels.paidFeesReport) {
        handlePaidFeesTable()
    } else if(response?.selectedReport ===
      labels.inspectorTimeTrackingReport){
        handlePrintTimeTrackingTable()
      } else if(response?.selectedReport ===
      labels.planReviewerTimeTrackingReport) {
        handlePlanReviewerTimeTrackingReportTable()
    }else if(response?.selectedReport ===
      labels.permitReviewCommand) {
        handlePermitReportTableRef()
    } else {
      handlePrintPermitTable()
    }
  }
  const onChangeKey = () => {
    setKey(key + 1);
  };

  const [inspectionFormClick, setInspectionFormClick] = React.useState(false)

  
  return (
    <DashboardLayout>
      <ActionsPanelReports
        onExportToPDF={exportToPdf}
        onSubmit={setReportsSettings}
        onChangeKey={onChangeKey}
        isValidForExport={isValidForExport}
        setInspectionFormClick={setInspectionFormClick}
        inspectionFormClick={inspectionFormClick}
        setReportsSettings={setReportsSettings}
        forms={forms}
        setForms={setForms}
      />
      <Container>
        {reportsSettings ? (
          <>
            {reportsSettings.selectedReport ===
              labels.dailyInspectionReport && (
              <DailyInspectionReport
                key={key}
                ref={dailyTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {/* EA-103
            mritunjoy */}
            {reportsSettings.selectedReport ===
              labels.inspectorTimeTrackingReport && (
              <InspectorTimeTrackingReport
                key={key}
                ref={timeTrackingTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {reportsSettings.selectedReport ===
              labels.runnerReport && (
              <RunnerReport
                key={key}
                ref={runnerTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {reportsSettings.selectedReport ===
              labels.formsReport && inspectionFormClick &&(
        
          <ModalDefaultDraggable
                onClose={() => setInspectionFormClick(false)}
                extraWide
                showPrintButton
              >
              <FormsReport
                key={key}
                setSelectedFormsReports={
                  setSelectedFormsReports
                }
                selectedFormsReports={selectedFormsReports}
                setIsValidForExport={setIsValidForExport}
                ref={printableRef}
                forms={forms}
                setForms={setForms}
                {...reportsSettings}
              />
              </ModalDefaultDraggable>
            )}
            {/* EA-86
            mritunjoy */}
            {reportsSettings.selectedReport ===
              labels.inspectionByPermitReport && (
              <InspectionReportPermit
                key={key}
                ref={permitReportTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {/* EA-88
            mritunjoy */}
            {reportsSettings.selectedReport ===
              labels.businessToBeInvoicedReport && (
              <BusinessToBeInvoicedReport
                key={key}
                ref={businessInvoiceReportTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {reportsSettings.selectedReport ===
              labels.companyOutstandingFeesReport && (
              <CompanyOutstandingFeesReport
                key={key}
                ref={companyOutstandingFeesReportTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {/* EA-94
            mritunjoy */}
            {reportsSettings.selectedReport ===
              labels.notInvoicedPermittingFeesReport && (
              <NotInvoicedPermittingFeesReport
                key={key}
                ref={notInvoicedPermittingFeesReportTableRef}
                setIsValidForExport={setIsValidForExport}
                {...reportsSettings}
              />
            )}
            {/* EA-85
            mritunjoy */}
            {reportsSettings.selectedReport ===
              labels.paidFeesReport && (
              <PaidFeesReport
                key={key}
                setIsValidForExport={setIsValidForExport}
                ref={paidFeesTableRef}
                {...reportsSettings}
              />
            )}
            {/* EA-105
            mritunjoy */}
            {reportsSettings.selectedReport ===
              labels.planReviewerTimeTrackingReport && (
              <PlanReviewerTimeTrackingReport
                key={key}
                setIsValidForExport={setIsValidForExport}
                ref={planReviewerTimeTrackingReportTableRef}
                {...reportsSettings}
              />
            )}
            {reportsSettings.selectedReport ===
              labels.permitReviewCommand && (
              <PermitReport
                key={key}
                setIsValidForExport={setIsValidForExport}
                ref={permitReviewTableRef}
                {...reportsSettings}
              />
            )}
          </>
        ) : (
          <InfoText>
            Please, select Report from top menu and click on
            «Submit» button.
          </InfoText>
        )}
      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
  display: flex;
`;

const InfoText = styled.div`
  margin: 0 auto;
  color: var(--secondaryText);
  text-align: center;
`;

export default EmployeeReports;
