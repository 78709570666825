import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PaginationState {
    pageCount: number,
    pageSize: number,
    pageIndex: number,
    rowsCount: number,
  }

  const initialState: PaginationState = {
    pageCount: 1,
    pageSize: 100,
    pageIndex: 0,
    rowsCount: 0,
  };

  export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPageCount: (state, action:PayloadAction<number>) => {
            state.pageCount = action.payload;
          },
          setPageSize: (state, action:PayloadAction<number>) => {
            state.pageSize = action.payload;
            state.pageIndex = 0;
          },
          setPageIndex: (state, action:PayloadAction<number>) => {
            state.pageIndex = action.payload;
          },
          setRowsCount: (state, action:PayloadAction<number>) => {
            state.rowsCount = action.payload;
            let pageCount = Math.floor(action.payload / state.pageSize) + 1;
            //this is edge case where the page size is exactly equals to the rowCounts
            //for example pageSize=10 rowCount=30 , in this case we don't want extra page which
            //will be empty
            if( (pageCount - 1) * state.pageSize === action.payload){
              pageCount--;
            }
            state.pageCount = pageCount;
          },
    },
  });

  // Action creators are generated for each case reducer function
export const { setPageCount, setPageSize, setPageIndex, setRowsCount } = paginationSlice.actions;

export default paginationSlice.reducer;
