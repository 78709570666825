
import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar';

import theme from '../theme';
import { getCurrentUser } from "../api/userSettings";
import { getInspectionStats } from '../api/dashboard';
import { MONTH_FULL_LIST } from '../constants';
import ModalDefaultDraggable from "../molecules/ModalDefaultDraggable";
import useTablePage from "../hooks/useTablePage";
import {updatePermitActivity} from "../api/activities";
import CompletedInspectionsPage from "../pages/CompletedInspectionsPage";


const CompletedInspectionsGraph = () => {

  const {
    handleCloseModalInfo,
   } = useTablePage({
    updateEndpoint: updatePermitActivity,
    modalUpdateTitle: 'Update',
  });

  const [currentUser, setCurrentUser] = React.useState(
    null,
  );
  const [selectedMonth, setSelectedMonth] = React.useState(
    null,
  );
  const [selectedYear, setSelectedYear] = React.useState(
    null,
  );
  const [chartData, setChartData] = React.useState([]);
  const [
    inspections,
    showInspections,
  ] = React.useState(null);
  const closeInfoModal = () => {
    showInspections(false);
    handleCloseModalInfo();
  };

  React.useEffect(() => {
    Promise.all([
      getCurrentUser(),
    ]).then(
      ([
        currentUserResponse,
      ]) => {
        setCurrentUser(currentUserResponse.data.id);
      });
  }, []);

  const sortByYearAndMonth = (a, b) => {
    if (a.year === b.year) {
        return b.month - a.month;
    }
    return b.year - a.year;
}

  React.useEffect(() => {
    if (currentUser) {
       getInspectionStats(currentUser).then(({data}) => {
        const sortedData = data.sort(sortByYearAndMonth)        
        setChartData(sortedData);
      });
    }
  }, [currentUser]);

  return (
    <Container>
      <Header>Completed Inspections</Header>

      {chartData?.length && (
        <Bar>
          <ResponsiveBar
            data={chartData}
            keys={['inspectionCount']}
            indexBy="month"
            isInteractive={false}
            colors={[theme.marker1]}
            padding={0.8}
            valueScale={{ type: 'linear' }}
            borderRadius={5}
            innerPadding={10}
            groupMode="grouped"
            margin={{
              top: 50,
              right:130,
              bottom: 50,
              left: 60,
            }}
            enableGridY={false}
            axisBottom={{
              format: (v: any) =>
                `${MONTH_FULL_LIST[v - 1]?.slice(
                  0,
                  3,
                )}'${chartData
                  .find(item => item.month === v)
                  ?.year.toString()
                  .substr(-2)}`,
            }}
            onClick={(data) => {
              showInspections(true);
              setSelectedMonth(data.data.month);
              setSelectedYear(data.data.year);
            }}
          />
        </Bar>
      )}
      {inspections &&(
        <ModalDefaultDraggable
          title={'Completed Inspections'}
          onClose={closeInfoModal}
          extraWide
        >
          <CompletedInspectionsPage
            month={selectedMonth}
            year={selectedYear}
          />
        </ModalDefaultDraggable>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin: 0 10px;
  padding: 6px;
  width: 760px;
  min-height: 300px;
  border: 1px solid var(--border);
  border-radius: 5px;
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const Bar = styled.div`
  height: 288px;
`;

const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;

export default CompletedInspectionsGraph;
