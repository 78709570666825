import React, { useEffect } from 'react';
import { InputBaseContainer, InputSelectWithLabel } from '../../../../../molecules';
import { Controller } from 'react-hook-form';
import { labels, periodData } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

const ActivityTitle = ({ control, errors, setValue }) => {
  const { selectedInspection, disabledGlobalSearch } = useSelector((s: RootState) => s.inspections);
  useEffect(() => {
    const periodId = selectedInspection.period ?? 2;
    const period = periodData.find(el => el?.id === periodId);
    setValue('period', period);
  }, [selectedInspection]);
  return (
    <InputBaseContainer>
      <Controller
        name="period"
        defaultValue=""
        control={control}
        render={props => (
          <InputSelectWithLabel
            showInRow={true}
            placeholder=""
            menuPlacement="bottom"
            isDisabled={disabledGlobalSearch}
            label={labels.period}
            error={errors.period}
            getOptionValue={(item: any) => item.id || Math.random()}
            getOptionLabel={(item: any) => item.name}
            options={periodData}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
    </InputBaseContainer>
  );
};

export default ActivityTitle;
