import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { ReactSVG } from 'react-svg';
import { capitalCase } from 'change-case';
import ArrowRight from '../resources/icons/crumbs_arrow_right.svg';

const BreadCrumbs = () => {
  const [links, setLinks] = React.useState([]);

  const { pathname } = useLocation();
  const { push }: History = useHistory();

  React.useEffect(() => {
    if (pathname) {
      const splittedPath = pathname.split('/');
      setLinks(splittedPath.filter(path => !!path));
    }
  }, [pathname]);

  const handleClick = link => {
    const index = links.findIndex(
      element => element === link,
    );
    if (index !== -1) {
      const pathArrayToPush = links.slice(0, index + 1);
      const path = `/${pathArrayToPush.join('/')}`;

      push(path);
    }
  };

  const transformLinkText = (text: string) =>
    capitalCase(text);

  return (
    <Container>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ReactSVG src={ArrowRight} />}
      >
        {links &&
          links.map(link => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              key={link}
              component="button"
              onClick={() => handleClick(link)}
            >
              {transformLinkText(link)}
            </Link>
          ))}
      </Breadcrumbs>
    </Container>
  );
};

const Container = styled.div`
  .MuiBreadcrumbs-li button {
    color: var(--defaultTableCellTitle);
    font-size: 13px;
    text-transform: capitalize;
  }

  .MuiBreadcrumbs-separator {
    margin-top: 4px;
  }
`;

export default BreadCrumbs;
