import * as React from 'react';
import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import {
  ErrorMessages,
  CheckboxWithLabel,
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  InputAutocompletePlaces,
  ButtonWithLoader,
} from '../molecules';
import { FormServerError, SelectOption } from '../types';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import {
  USER_TYPES,
  CLIENT_TYPES,
  USER_TYPES_VALUES,
  CLIENT_TYPES_VALUES,
  SUPER_ADMIN_ROLE,
} from '../constants/forms';
import { getTrades, TradeType } from '../api/trades';
import {
  getUsers,
  UserTableType,
  UserType,
} from '../api/users';
import { getCurrentUser } from '../api/userSettings';
import { getFranchiseList } from '../api/franchise';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { checkValidStateName } from '../utils/parseGoogleApiAddress';
import useUserRole from '../hooks/useUserRole';
import {
  labels,
  schema,
  SaFaSchema,
} from './validation/usersFormValidation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/root';

const NO_COMPANY = {
  companyName: 'No Company',
  id: '~PLACEHOLDER~',
};

const UsersForm = ({
  searchForm,
  onSubmit,
  disabledRole = false,
  isReadOnly,
  clientTypeChange,
  initialValues,
  modalError,
  addButtonText = 'Add User',
  updateButtonText = 'Update User',
  initialErrors,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsContactFormSubmitting = data => {},
  cameFromSearch = false
}: Props) => {
  const {
    isSuperAdmin,
    isEmployee,
    isFranchiseAdmin,
  } = useUserRole();

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    watch,
    formState,
    getValues,
  } = useForm<UserTableType>({
    resolver: yupResolver(
      isSuperAdmin || isFranchiseAdmin
        ? SaFaSchema
        : schema,
    ),
    defaultValues: initialValues
      ? {
          ...initialValues,
          userRole: initialValues?.isEpsuperadmin
            ? USER_TYPES.find(
                item => item.value === SUPER_ADMIN_ROLE,
              )
            : USER_TYPES.find(
                item =>
                  item.value ===
                  (initialValues.userRole as number),
              ),
          clientType: CLIENT_TYPES.filter(
            item =>
              item.value ===
              (initialValues.clientType as number),
          )[0],
          parentCompany: NO_COMPANY as any,
          franchise: undefined,
          trade: undefined,
        }
      : ({} as UserTableType),
  });

  React.useEffect(() => {
    const roleValue = getValues('userRole');
    const franchiseValue = getValues('franchise');
    if (
      roleValue &&
      // @ts-ignore
      roleValue?.value !== USER_TYPES_VALUES.EMPLOYEE
    ) {
      if (
        franchiseValue &&
        !Array.isArray(franchiseValue) &&
        Object.keys(franchiseValue).length
      ) {
        // @ts-ignore
        setValue('franchise', [
          {
            // @ts-ignore
            id: franchiseValue.value || franchiseValue.id,
            // @ts-ignore
            name: franchiseValue.name,
          } as any,
        ]);
      }
    }
  }, [formState.isDirty, formState]);

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsContactFormSubmitting(true);
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);
React.useEffect(()=>{
  clientTypeChange && clientTypeChange(getValues("clientType"))
},[getValues("clientType")])
  const userRole = watch('userRole') as SelectOption;
  const clientType = watch('clientType') as SelectOption;   
  const selectedFranchise = watch('franchise')
  const franchiseValue = useSelector((s:RootState) => s.userAuth.info.selectedFranchise);
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    franchisesData,
    setFranchisesData,
  ] = React.useState([]);
  const [tradesData, setTradesData] = React.useState([]);
  const [
    parentCompanyData,
    setParentCompanyData,
  ] = React.useState([NO_COMPANY as any]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getFranchiseList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      getTrades({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        clientType: CLIENT_TYPES_VALUES.COMPANY,
      }),
    ])
      .then(
        ([
          franchisesResponse,
          tradesResponse,
          usersResponse,
        ]) => {
          setFranchisesData(
            franchisesResponse.data.results,
          );
          setTradesData(tradesResponse.data.results);
          setParentCompanyData([
            ...parentCompanyData,
            ...usersResponse.data.results,
          ]);
        },
      )
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (isEmployee) {
      setValue(
        'userRole',
        USER_TYPES?.find(
          el => {
              return el?.value === USER_TYPES_VALUES.CLIENT
          },
        ),
      );
      getCurrentUser()
        .then(res =>
          setValue('franchise', res.data.franchise))
        .catch(err => console.log(err));
    }
  }, [isEmployee]);

  useEffect(() => {
    if(initialValues && initialValues.defaultFranchiseId ){
      if(franchisesData && franchisesData.length > 0) {
        const defaultFran = franchisesData.find(f => f.id === initialValues.defaultFranchiseId);
        if(defaultFran){
          setValue('defaultFranchise', defaultFran);
        }
      }
    }
  },[initialValues,franchisesData])
  React.useEffect(() => {
    if (initialValues?.id) {
      if (franchisesData.length) {
        const selectedFranchises = franchisesData.filter(
          item => initialValues.franchise.includes(item.id),
        );

        if (selectedFranchises.length) {
          if (selectedFranchises.length === 1) {
            setValue('franchise', {
              // @ts-ignore
              id: selectedFranchises[0].id,
              name: selectedFranchises[0].name,
            });
          } else {
            setValue('franchise', selectedFranchises);
          }
        }
      }

      if (tradesData.length) {
        const selectedTrades = tradesData.filter(item =>
          initialValues.trade.includes(item.id),
        );

        if (selectedTrades.length) {
          setValue('trade', selectedTrades);
        }
      }

      if (parentCompanyData.length) {
        const selectedCompany = parentCompanyData.find(
          item => item.id === initialValues.parentCompany || item.companyName === initialValues.parentCompany,
        );

        if (selectedCompany) {
          setValue('parentCompany', selectedCompany);
        }
      }
    }
  }, [franchisesData, tradesData, parentCompanyData]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<UserType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }),
      );
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const handlePlaceSelect = (address, isSelect) => {
    if (isSelect) {
      return Object.entries(address).map(([key, value]) => {
        return setValue(key, value);
      });
    }

    return setValue('address', address);
  };

  const middleware = (
    submitFn: (data: UserType) => void,
  ) => (data: UserTableType) => {
    const selectedFranchises = franchisesData.filter(item =>
      initialValues?.franchise?.includes(item.id),
    );
    const userRoleValue = (data.userRole as SelectOption)
      .value;
    const clientTypeValue =
      (data.clientType as SelectOption)?.value ||
      CLIENT_TYPES_VALUES.NOT_CLIENT;

    const companyName =
      (data.clientType as SelectOption)?.value ===
      String(CLIENT_TYPES_VALUES.COMPANY)
        ? data?.companyName
        : '';

    submitFn({
      ...data,
      defaultFranchiseId:data.defaultFranchise.id,
      firstName: data.firstName,
      lastName: data.lastName,
      id: initialValues?.id,
      userRole:
        userRoleValue === SUPER_ADMIN_ROLE
          ? USER_TYPES_VALUES.FRANCHISE_ADMIN
          : userRoleValue,
      clientType:
        userRoleValue === String(USER_TYPES_VALUES.CLIENT)
          ? clientTypeValue
          : CLIENT_TYPES_VALUES.NOT_CLIENT,
      parentCompany:
        (data.parentCompany as any)?.id === NO_COMPANY.id
          ? null
          : (data.parentCompany as UserType)?.id,
      franchise: 
        userRoleValue === SUPER_ADMIN_ROLE
          ? []
          : [franchiseValue],
      trade: (data.trade as TradeType[])?.map(
        item => item.id,
      ),
      isEpsuperadmin: userRoleValue === SUPER_ADMIN_ROLE,
      companyName,
    });
  };

  const isFranchiseMultiSelect = () => {
    // removing super admin check -- EA-657 - mritunjoy
    if (isFranchiseAdmin) {
      if (userRole?.value === USER_TYPES_VALUES.EMPLOYEE) {
        return false;
      }
    }
    return true;
  };

  return (
    <Container
      disabled={isDeleted || (isSubmitting && !modalError)}
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="userRole"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                label={labels.userRole}
                error={errors.userRole}
                isRequired={isYupFieldRequired(
                  'userRole',
                  schema,
                )}
                isDisabled={((disabledRole && initialValues) || cameFromSearch) || isReadOnly}
                isSearchable={false}
                isOptionDisabled={option =>
                  (!isSuperAdmin &&
                    (option.label === 'Super admin' ||
                      option.label ===
                        'Branch admin')) ||
                  (isEmployee &&
                    (option.label === 'Super admin' ||
                      option.label === 'Branch admin' ||
                      option.label === 'Inspector' ||
                      option.label === 'Employee'))
                }
                options={USER_TYPES}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            userRole?.value !== USER_TYPES_VALUES.CLIENT
          }
        >
          <Controller
            name="clientType"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isRequired
                isDisabled={isReadOnly}
                placeholder=""
                isSearchable={false}
                label={labels.clientType}
                error={errors.clientType}
                options={CLIENT_TYPES}
                isDisabled={cameFromSearch}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            !(
              userRole?.value ===
                USER_TYPES_VALUES.CLIENT &&
              clientType?.value ===
                CLIENT_TYPES_VALUES.COMPANY
            )
          }
        >
          <InputTextWithLabel
            name="companyName"
            isDisabled={isReadOnly}
            inputRef={register}
            error={errors.companyName}
            label={labels.companyName}
            isRequired={
              isYupFieldRequired('companyName', schema) ||
              (userRole?.value ===
                USER_TYPES_VALUES.CLIENT &&
                clientType?.value ===
                  CLIENT_TYPES_VALUES.COMPANY)
            }
            disabled={cameFromSearch}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            userRole?.value !== USER_TYPES_VALUES.CLIENT ||
            !clientType ||
            clientType?.value ===
              CLIENT_TYPES_VALUES.COMPANY
          }
        >
          <Controller
            name="parentCompany"
            defaultValue={NO_COMPANY}
            control={control}
            render={props => (
              <InputSelectWithLabel
                placeholder=""
                isDisabled={isReadOnly}
                label={labels.parentCompany}
                error={errors.parentCompany}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) =>
                  item.companyName
                }
                isRequired={isYupFieldRequired(
                  'parentCompany',
                  schema,
                )}
                options={parentCompanyData}
                isDisabled={cameFromSearch}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="firstName"
            disabled={isReadOnly}
            inputRef={register}
            error={errors.firstName}
            label={labels.firstName}
            isRequired={
              isEmployee
                ? isYupFieldRequired('firstName', schema)
                : isYupFieldRequired(
                    'firstName',
                    SaFaSchema,
                  )
            }
            disabled={cameFromSearch}
          />
          <InputTextWithLabel
            name="lastName"
            disabled={isReadOnly}
            inputRef={register}
            error={errors.lastName}
            label={labels.lastName}
            isRequired={
              isEmployee
                ? isYupFieldRequired('lastName', schema)
                : isYupFieldRequired('lastName', SaFaSchema)
            }
            disabled={cameFromSearch}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="email"
            disabled={isReadOnly}
            error={errors.email}
            inputRef={register}
            label={labels.email}
            isRequired={isYupFieldRequired('email', schema)}
            disabled={cameFromSearch}
          />
          <Controller
            control={control}
            defaultValue=""
            name="phoneNumber"
            render={props => (
              <ReactInputMask
                mask="999-999-9999"
                disabled={isReadOnly}
                maskChar=" "
                disabled={cameFromSearch}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              >
                {() => (
                  <InputTextWithLabel style={isReadOnly && {backgroundColor:"#f2f2f2",opacity:"0.7"}}
                    error={errors.phoneNumber}
                    label={labels.phoneNumber}
                    isRequired={isYupFieldRequired(
                      'phoneNumber',
                      schema,
                    ) || (userRole?.label !== 'Client')}
                    style={cameFromSearch ? {backgroundColor: '#F2F2F2', opacity: 0.7} : {}}
                  />
                )}
              </ReactInputMask>
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="address"
            defaultValue=""
            control={control}
            render={props => (
              <InputAutocompletePlaces
              isDisabled={isReadOnly}
                error={errors.address}
                label={labels.address}
                handleFieldChange={handlePlaceSelect}
                isRequired={isYupFieldRequired(
                  'address',
                  schema,
                )}
                isDisabled={cameFromSearch}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
           disabled={isReadOnly}
            name="street"
            error={errors.street}
            inputRef={register}
            label={labels.street}
            isRequired={isYupFieldRequired(
              'street',
              schema,
            )}
            disabled={cameFromSearch}
          />
          <InputTextWithLabel
            name="city"
            disabled={isReadOnly}
            error={errors.city}
            inputRef={register}
            label={labels.city}
            isRequired={isYupFieldRequired('city', schema)}
            disabled={cameFromSearch}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="state"
            defaultValue=""
            control={control}
            render={props => (
              <InputTextWithLabel
                name="state"
                disabled={isReadOnly}
                error={errors.state}
                label={labels.state}
                isRequired={isYupFieldRequired(
                  'state',
                  schema,
                )}
                disabled={cameFromSearch}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                // eslint-disable-next-line react/prop-types
                value={checkValidStateName(props.value)}
              />
            )}
          />
          <InputTextWithLabel
            name="zipCode"
            disabled={isReadOnly}
            error={errors.zipCode}
            inputRef={register}
            label={labels.zipCode}
            isRequired={isYupFieldRequired(
              'zipCode',
              schema,
            )}
            disabled={cameFromSearch}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            userRole?.value === SUPER_ADMIN_ROLE ||
            isEmployee
          }
        >
          <Controller
            name="franchise"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti={isFranchiseMultiSelect()}
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                isDisabled={isLoading || cameFromSearch || isReadOnly}
                label={labels.franchise}
                error={errors.franchise}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={
                  isYupFieldRequired(
                    'franchise',
                    isSuperAdmin || isFranchiseAdmin
                      ? SaFaSchema
                      : schema,
                  ) ||
                  userRole?.value !==
                    String(SUPER_ADMIN_ROLE)
                }
                options={franchisesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        {/* Adding single select for default franchise - EA-657- mritunjoy */}
        <InputBaseContainer
          hidden={
            userRole?.value === SUPER_ADMIN_ROLE ||
            isEmployee
          }
        >
          <Controller
            name="defaultFranchise"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti={false}
                placeholder=""
                menuPlacement="top"
                isLoading={isLoading}
                isDisabled={isLoading || cameFromSearch || isReadOnly}
                label={labels.defaultFranchise}
                error={errors.defaultFranchise}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={
                  isYupFieldRequired(
                    'defaultFranchise',
                    isSuperAdmin || isFranchiseAdmin
                      ? SaFaSchema
                      : schema,
                  ) ||
                  userRole?.value !==
                    String(SUPER_ADMIN_ROLE)
                }
                options={selectedFranchise ? selectedFranchise : []}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            userRole?.value !== USER_TYPES_VALUES.INSPECTOR
          }
        >
          <Controller
            name="trade"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                isRequired
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading || cameFromSearch || isReadOnly}
                menuPlacement="top"
                label={labels.trade}
                error={errors.trade}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                options={tradesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            userRole?.value !== USER_TYPES_VALUES.INSPECTOR
          }
        >
          <InputTextWithLabel
            name="licenses"
            disabled={isReadOnly}
            inputRef={register}
            error={errors.licenses}
            label={labels.licenses}
            isRequired={isYupFieldRequired(
              'licenses',
              schema,
            )}
            disabled={cameFromSearch}
          />
        </InputBaseContainer>
        <InputBaseContainer
          hidden={
            userRole?.value !==
              USER_TYPES_VALUES.INSPECTOR &&
            userRole?.value !== USER_TYPES_VALUES.EMPLOYEE
          }
        >
          <InputTextWithLabel
            name="prNumber"
            disabled={isReadOnly}
            inputRef={register}
            error={errors.prNumber}
            label={labels.prNumber}
            isRequired={isYupFieldRequired(
              'prNumber',
              schema,
            )}
            disabled={cameFromSearch}
          />
        </InputBaseContainer>
        {/* Bug fix as can't add company client after cellphone number field added for individual user */}
        {/* EA-77 */}
        {/* mritunjoy */}
        {userRole?.label === 'Client' &&
        clientType.label === 'Individual' ? (
          <InputBaseContainer
            hidden={
              userRole?.value !==
                USER_TYPES_VALUES.CLIENT ||
              !clientType ||
              clientType?.value ===
                CLIENT_TYPES_VALUES.COMPANY
            }
          >
            <Controller
              control={control}
              defaultValue=""
              name="mobilePhoneNumber"
              render={props => (
                <ReactInputMask
                  mask="999-999-9999"
                  disabled={isReadOnly}
                  maskChar=" "
                  disabled={cameFromSearch}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                >
                  {() => (
                    <InputTextWithLabel style={isReadOnly && {backgroundColor:"#f2f2f2",opacity:"0.7"}}
                      error={errors.mobilePhoneNumber}
                      label={labels.mobilePhoneNumber}
                      isRequired={
                        isYupFieldRequired(
                          'mobilePhoneNumber',
                          schema,
                        ) || (userRole?.label !== 'Client')
                      }
                      style={cameFromSearch ? {backgroundColor: '#F2F2F2', opacity: 0.7} : {}}
                    />
                  )}
                </ReactInputMask>
              )}
            />
          </InputBaseContainer>
        ) : null}
        <InputBaseContainer>
          <Controller
            name="isActive"
            control={control}
            valueName="checked"
            defaultValue={
              initialValues ? initialValues.isActive : true
            }
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
              disabled={isReadOnly}
                error={errors.isActive}
                label={labels.isActive}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
                disabled={cameFromSearch}
              />
            )}
          />
        </InputBaseContainer>
        {errors?.parentCompany && (
          <ErrorMessages error={errors.parentCompany} />
        )}
        {userRole?.value === SUPER_ADMIN_ROLE &&
          errors?.franchise && (
            <ErrorMessages error={errors.franchise} />
          )}
        {(!isDeleted && !isReadOnly)&& (
          <ButtonWithLoader
            disabled={((isSubmitting && !modalError) || cameFromSearch) || isReadOnly}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: UserTableType;
  onSubmit: (data: UserType) => void;
  initialErrors?: FormServerError<UserType>;
  modalError: boolean;
  setIsContactFormSubmitting?: (data) => void;
  cameFromSearch?:boolean;
  searchForm?: boolean;
}

export default UsersForm;
