
import * as React from 'react';
import styled from 'styled-components';
import {getPendingInspectionsCount, getPendingReviewsCount} from "../api/inspections";

const InspectionTasksBlock = () => {
    const [
        pendingInspectionsColumn,
        setPendingInspectionsColumn,
    ] = React.useState(0);
    const [
        pendingReviewsColumn,
        setPendingReviewsColumn,
    ] = React.useState(0);
    const [
        totalCount,
        setTotalCountColumn,
    ] = React.useState(0);

    React.useEffect(() => {

        Promise.all([
          getPendingInspectionsCount(),
          getPendingReviewsCount(),

        ]).then(([
                  pendingInspectionsCount,
                  pendingReviewsCount,

                 ]) => {
           setPendingInspectionsColumn(pendingInspectionsCount.data);
           setPendingReviewsColumn(pendingReviewsCount.data);
           setTotalCountColumn(pendingInspectionsCount.data + pendingReviewsCount.data);
        });
    }, []);
    return (
      <Wrapper>
        <Header>My Tasks</Header>
        <Content>
          <StyledParagraph>
            Pending Inspections:
            <b> {pendingInspectionsColumn}</b>
          </StyledParagraph>
          <StyledParagraph>
            Pending Reviews:
            <b> {pendingReviewsColumn}</b>
          </StyledParagraph>
          <StyledParagraph>
            Total:
            <b> {totalCount}</b>
          </StyledParagraph>
        </Content>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 270px;
  padding: 6px;
  height: 340px;
  border: 1px solid var(--border);
  border-radius: 5px;
  @media only screen and (max-width: 840px) {
    width: 100%;
    height: 178px;
  }
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;

const Content = styled.div`
  height: 280px;
`;

const StyledParagraph = styled.p`
  font-size: 17px;
  text-align: center;
  b {
    color: var(--primary);
  }
`;

export default InspectionTasksBlock;
