import { api } from './api';

import {
  PaginatedList,
  PaginatedRequest,
  SelectOption,
} from '../types';
import { PERIODIC_VALUES } from '../constants/forms';
import { FranchiseType } from './franchise';

export const getScheduling = (params: PaginatedRequest) =>
  api.request<SchedulingList>({
    method: 'GET',
    url: '/schedules/',
    params,
  });

export const getSchedulingByID = (id: number) =>
  api.request<SchedulingType>({
    method: 'GET',
    url: `/schedules/${id}/`,
  });

export const createScheduling = (data: SchedulingType) =>
  api.request<SchedulingType>({
    method: 'POST',
    url: '/schedules/',
    data,
  });

export const updateScheduling = (data: SchedulingType) =>
  api.request<SchedulingType>({
    method: 'PATCH',
    url: `/schedules/${data.id}/`,
    data,
  });

export const deleteScheduling = (id: number) =>
  api.request<SchedulingType>({
    method: 'DELETE',
    url: `/schedules/${id}/`,
  });

type SchedulingList = PaginatedList<SchedulingType>;

export interface SchedulingType {
  id: number;
  name: string;
  description: string;
  date: string | Date;
  fromEpsuperadmin: boolean;
  periodic: PERIODIC_VALUES | SelectOption | string;
  periodicFull: string;
  isDeleted: boolean;
  franchise: number[] | string | FranchiseType[];
}

export interface SchedulingTableType
  extends SchedulingType {
  date: Date;
  periodic: SelectOption;
  hightLightSelectedRows: SchedulingType[];
  setSelectedEvents: (data: SchedulingType) => void;
  isDefault?: boolean;
}
