import axios from 'axios';
import { PaginatedRequest } from '../types';
import { api } from './api';
import {
  onResponseErrorInterceptor,
  onResponseInterceptor,
} from './interceptors';
import { getAccessToken } from '../auth';
import { DocumentType } from './document';
import { formatToFormData } from '../utils';

export const getConditions = (params: PaginatedRequest) =>
  api.request({
    method: 'GET',
    url: '/conditions/',
    params,
  });

export const getConditionComments = (
  params: PaginatedRequest,
  id,
) =>
  api.request({
    method: 'GET',
    url: `/conditions/${id}/comments/`,
    params,
  });

  export const getAllConditions = () =>
    api.request({
      method: 'GET',
      url: `/client_projects/get-all-conditions`
    });


 export const updateConditionNotesAttachemnts = (data) => {
    axios.interceptors.response.use(
      onResponseInterceptor,
      onResponseErrorInterceptor,
    );
  
    

    const body = formatToFormData({...data});
    let base = window.location.origin;
    if(window.location.origin === "http://localhost:1234"){
      base = 'https://epapp.elitepermits.com/api/v1';
    }
    return axios.request<DocumentType>({
      method: 'POST',
      url: `${base}/client_projects/conditions/multiple/`,
      data: body,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });



    // return api.request({
    //   method: 'POST',
    //   url: `/client_projects/conditions/multiple/`,
    //   data:{...data,description:"description"},
    // });


    // return axios.request<DocumentType>({
    //   method: 'PUT',
    //   url: `/api/v1/client_projects/conditions/multiple/`,
    //   data: body,
    //   headers: {
    //     Authorization: `Bearer ${getAccessToken()}`,
    //   },
    // });
  };
    
export const createCondition = (data: FormData) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  return axios.request<DocumentType[]>({
    method: 'POST',
    url: '/api/v1/conditions/',
    data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const createConditionComment = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const { id } = data;
  const body = formatToFormData({
    text: data.text,
    file: data.file,
  });
  return axios.request<DocumentType>({
    method: 'POST',
    url: `/api/v1/conditions/${id}/comments/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const updateCondition = (data: any) => {
  const fd = formatToFormData(data);
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  return axios.request<DocumentType[]>({
    method: 'PATCH',
    url: `/api/v1/conditions/${fd?.get('id')}/`,
    data: fd,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const addConditionAttachments = (
  data: FormData,
  id,
) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  return axios.request({
    method: 'POST',
    url: `/api/v1/conditions/${id}/attachments/`,
    data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
