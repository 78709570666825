import React, { FC } from 'react';
import styled from 'styled-components';
import { CommentType, DailyInspectionType } from '../../../api/inspectionTypes';

const PrintRecrod: FC<{ rows: DailyInspectionType[] }> = ({ rows }) => {
  const getPeriod = (period?: number) => {
    switch (period) {
      case 0:
        return 'AM';
      case 1:
        return 'PM';
      default:
        return '';
    }
  };

  const getComment = (comment?: CommentType) => {
    if (!comment) {
      return '';
    }

    if (comment.text.indexOf('data:image') > -1) {
      return `${comment.username}: [Image]`;
    }
    return `${comment.username}: ${comment.text}`;
  };
  return (
    <Wrapper>
      <HeaderAndRecord>
        <HeaderRow>
          <HeaderCell>Permit Number</HeaderCell>
          <HeaderCell>Inspection ID</HeaderCell>
          <HeaderCell>Inspection Type</HeaderCell>
          <HeaderCell>Project Manager</HeaderCell>
          <HeaderCell>Jurisdiction</HeaderCell>
          <HeaderCell>AM/PM</HeaderCell>
          <HeaderCell>Contact</HeaderCell>
          <HeaderCell>Contact Details</HeaderCell>
        </HeaderRow>
        {rows.map((item, index) => (
          <ContentRow key={index}>
            <ContentCell>{item.permitNumber}</ContentCell>
            <ContentCell>{item.id}</ContentCell>
            <ContentCell>{item.inspectionTypeName}</ContentCell>
            <ContentCell>{item.projectManager}</ContentCell>
            <ContentCell>{item.jurisdiction}</ContentCell>
            <ContentCell>{getPeriod(item.period)}</ContentCell>
            <ContentCell>{item.contactName}</ContentCell>
            <ContentCell>{item.contactDetails}</ContentCell>
            <BottomTable>
              <TableItem>
                <UpperLine>
                  <Label>Name:</Label>
                  <ContentCell>{`${item.projectName}|${item.permitName}`}</ContentCell>
                </UpperLine>
              </TableItem>
              <TableItem>
                <UpperLine>
                  <LeftLine>
                    <Label>Address:</Label>
                    <ContentCell>{item.projectAddress}</ContentCell>
                  </LeftLine>
                </UpperLine>
              </TableItem>
              <TableItem>
                <Label>Information:</Label>
                <ContentCell>{item.information}</ContentCell>
              </TableItem>
              <TableItem></TableItem>
              <TableItem>
                <Label>Comment:</Label>
                <ContentCell>{getComment(item.lastComment)}</ContentCell>
              </TableItem>
            </BottomTable>
          </ContentRow>
        ))}
      </HeaderAndRecord>
    </Wrapper>
  );
};

export default PrintRecrod;

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  @media print {
    display: flex;
  }
`;
const BottomTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1 / 9;
  border-bottom: 6px solid var(--border);
  grid-gap: 4px;
`;

const UpperLine = styled.div`
  border-top: 1px solid var(--border);
  display: flex;
  width: 100%;
`;

const Label = styled.div`
  font-weight: bold;
  padding-right: 5px;
`;

const LeftLine = styled.div`
  border-left: 1px solid var(--border);
  display: flex;
  width: 100%;
  padding-left: 4px;
`;
const TableItem = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--border);
`;

const HeaderAndRecord = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 25px;
  background-color: rgb(252, 249, 252);
  height: 70px;
  border: 1px solid var(--border);
  border-radius: 6px;
`;

const HeaderCell = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: var(--secondaryText);
`;

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 25px;
`;

const ContentCell = styled.div`
  font-size: 16px;
`;
