import * as React from 'react';
import styled from 'styled-components';
import { TextFieldProps } from '@material-ui/core';
import { ReactDatePickerProps } from 'react-datepicker';
import { FieldError, FieldErrors } from 'react-hook-form';

import { LabelForm, DatePicker, TextInput } from '../atoms';
import ErrorMessages from './ErrorMessages';

const Input = React.forwardRef((props: InputProps, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledTextInput fullWidth inputRef={ref} {...props} />
));

const InputDatePickerWithLabel = ({
  isRequired,
  label,
  error,
  showInRow=false,
  ...props
}: Props) => {
  const { name } = props;

  return (
    <InputContainer showInRow={showInRow}>
      <LabelForm htmlFor={name} isRequired={isRequired}>
        {label}
      </LabelForm>
      <StyledDatePicker
        customInput={<Input error={!!error} />}
        showPopperArrow={false}
        required={isRequired}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {error && <ErrorMessages error={error} />}
    </InputContainer>
  );
};

const InputContainer = styled.div<{showInRow: boolean}>`
  display: flex;
  flex-direction: ${({showInRow}) => showInRow ? 'row' : 'column'};
  align-items: start;
  width: 100%;

  label {
    margin-bottom: 8px;
    width:  ${({showInRow}) => showInRow ? '159px' : 'inherit'};
    height:  ${({showInRow}) => showInRow ? '40px' : 'inherit'};
    align-items: center;
    display: ${({showInRow}) => showInRow ? 'flex' : 'inherit'};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledTextInput = styled(TextInput)`
  fieldset {
    height: 40px;
    top: 0;
  }

  input {
    border-radius: 1px;
    padding: 0 16px;
    top: 0;
    height: 40px;
  }

  .PrivateNotchedOutline-root-2 {
    top: 0;
  }
`;

type Props = ReactDatePickerProps & {
  isRequired?: boolean;
  label: string;
  error?: FieldError | FieldErrors;
  showInRow?: boolean;
};

type InputProps = {
  id?: string;
  error?: boolean;
  value?: string;
  
} & Partial<Pick<TextFieldProps, 'onClick'>>;

export default InputDatePickerWithLabel;
