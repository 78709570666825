import * as React from 'react';
import { debounce } from 'throttle-debounce';
import {
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import styled from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton } from '../atoms';
import useDidUpdateEffect from '../hooks/useDidUpdate';
import { useDispatch } from 'react-redux';
import { clearFilter } from '../redux/slices/filterSlice';

const FeesSearchBar = ({
  columnName = '',
  text = '',
  onChange,
  onKeyChanged,
  debounceDelay = 300,
  tableSearch,
}: Props) => {
  const [value, setValue] = React.useState(text);
  const dispatch = useDispatch();
  const handleOnChange = React.useCallback(
    debounce(debounceDelay, onChange),
    [],
  );

  React.useEffect(() => {
    if (text !== value) {
      setValue(text);
    }
  }, [text]);

  useDidUpdateEffect(() => {
    handleOnChange(columnName, value);
  }, [value]);

  const removeFilter = (value1, columnName1) => {
    if (value1) {
      setValue('');
    } else {
      onChange(columnName1, value1);
    }
    dispatch(clearFilter(columnName1));
  };
  return (
    <Search
      value={value}
      name={columnName}
      onChange={e => { setValue(e.target.value); onKeyChanged(e);  }}
      tableSearch={tableSearch}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            edge="end"
            onClick={() => removeFilter(value, columnName)}
          >
            {value ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const Search = styled(OutlinedInput)`
  min-height: 30px;
  background-color: var(--defaultBackground);

  .MuiOutlinedInput-input {
    padding: 6px 14px 7px 14px;
    ${({ tableSearch }) =>
      tableSearch ? 'padding-right: 0;' : ''}
  }
  ${({ tableSearch }) =>
    tableSearch &&
    '&.MuiOutlinedInput-adornedEnd {padding-right: 0!important; .MuiInputAdornment-positionEnd {margin-left: 0;}}'}
`;

interface Props {
  columnName: string;
  text?: string;
  tableSearch?: boolean;
  debounceDelay?: number;
  onChange: (name: string, value: string) => void;
  onKeyChanged?: any;
}

export default FeesSearchBar;
