import * as React from 'react';
import styled from 'styled-components';

import { getPermitStatuses } from '../api/permits';

const labels = {
  paymentPending: 'Payment Pending',
  completed: 'Completed',
  reviewApproved: 'Review Approved',
  permitSubmitted: 'Permit Submitted',
  permitIssued: 'Permit Issued',
  inspectionStarted: 'Inspection Started',
  hold: 'Hold',
};

const ActivePermitsBlock = () => {
  const [
    waitingPaymentColumn,
    setWaitingPaymentColumn,
  ] = React.useState(0);
  const [
    totalActivePermits,
    setTotalActivePermitsColumn,
  ] = React.useState(0);
  const [
    inProgressCount,
    setInProgressCountColumn,
  ] = React.useState(0);

  React.useEffect(() => {
    getPermitStatuses().then(response => {
      response.data?.forEach(item => {
        if (item.title === labels.paymentPending) {
          setWaitingPaymentColumn(item.count);
        } else if (item.title === labels.completed) {
          setTotalActivePermitsColumn(item.count);
        } else if (
          item.title === labels.reviewApproved ||
          item.title === labels.permitSubmitted ||
          item.title === labels.permitIssued ||
          item.title === labels.inspectionStarted ||
          item.title === labels.paymentPending ||
          item.title === labels.hold
        ) {
          setInProgressCountColumn(
            prev => prev + item.count,
          );
        }
      });
    });
  }, []);
  return (
    <Wrapper>
      <Header>My active permits</Header>
      <Content>
        <StyledParagraph>
          Waiting for payment:
          <b> {waitingPaymentColumn}</b>
        </StyledParagraph>
        <StyledParagraph>
          Total active permits:
          <b> {totalActivePermits}</b>
        </StyledParagraph>
        <StyledParagraph>
          In progress:
          <b> {inProgressCount}</b>
        </StyledParagraph>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 270px;
  padding: 6px;
  height: 340px;
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-right:10px;
  @media (max-width: 820px) { 
    width: 100%
  }
`;
const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;
const Content = styled.div`
  height: 280px;
`;

const StyledParagraph = styled.p`
  font-size: 17px;
  text-align: center;
  b {
    color: var(--primary);
  }
`;

export default ActivePermitsBlock;
