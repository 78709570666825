const parseUTCDate = (dateUTC: string): Date | null => {
  if (!dateUTC || typeof dateUTC !== 'string') {
    return null;
  }

  let date = null;

  try {
    const dateSplitted = dateUTC.split('/');

    dateSplitted.splice(
      0,
      0,
      dateSplitted.splice(dateSplitted.length - 1, 1)[0],
    );
    /*date = new Date(
      `${dateSplitted.join('-')}T00:00:00.000Z`,
    );*/
    date = new Date(`${dateSplitted}`);

  } catch (e) {
    try {
      date = new Date(`${dateUTC}Z`);
    } catch (err) {
      date = new Date(dateUTC);
    }
  }

  return date;
};

export default parseUTCDate;
