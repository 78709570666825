import React, { useState } from 'react';
import styled from 'styled-components';
import { AxiosPromise } from 'axios';
import openInNewTab from '../../utils/openInNewTab';

import useManuallyUpdate from '../../hooks/useManuallyUpdate';
import PermitInspectionsForm from '../../forms/permitInspectionForm/PermitInspectionsForm';
import PermitFeesForm from '../../forms/PermitFeesForm';

import PermitActivitiesForm from '../../forms/PermitActivitiesForm';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT} from '../../constants';
import Table from '../Table';
import setColumns from './columns';
import UsersForm from '../../forms/UsersForm';
import useTablePage from '../../hooks/useTablePage';
import {VisibilityOutlined} from '@material-ui/icons';
import { convertTableBoolean } from '../../utils/convertTableValues';
import formatDate, {
  formatDateTime,
} from '../../utils/formatDate';
// import PermitPage from '../../pages/Permits/PermitPage';
// import PermitSearchPage from '../../pages/PermitSearchPage';
import { generatePath, useHistory } from 'react-router';
import { ROUTES } from '../../constants/routes';
import useUserRole from '../../hooks/useUserRole';
import { ModalDefaultDraggable } from '../../molecules';
import { updatePMResults } from '../../utils';
import TicketsForm from '../../forms/TicketsForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import ProjectDetailsPopup from './ProjectDetailsPopup';

// import PermitPage from '../../pages/Permits/PermitPage';
// import PermitSearchPage from '../../pages/PermitSearchPage';

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
const SearchTable = ({
  actualEndpoint,
  searchKey,
  activeTabName,
  dates,
  setDates,
}: SearchTableProps) => {
  const {
    modalError,
    modalSuccess,
    modalTitle,
    isModalOpen,
    errors,
    createOrUpdate,
    handleEdit,
    handleCloseModal,
  } = useTablePage({
    modalUpdateTitle: 'Update User',
  });

  const [
    tableManuallyUpdate,
    updateTable,
  ] = useManuallyUpdate();
  const {franchise,selectedFranchise} = useSelector((s:RootState) => s.userAuth.info);
  const [showPermitPage, setShowPermitPage] = React.useState(false)
  const [isEditProjectModal,setIsEditProjectModal] = useState(false);
  const [initialValues, setInitialValues] = React.useState(
    null,
  );
  const handleEditInspections = data => {
    if (data) {
      setInitialValues(data);
      handleEdit(data);
    }
  };
  const handleCloseInspectionModal = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  React.useEffect(() => {
    if(searchKey && activeTabName === 'permit') {
      localStorage.setItem('search', searchKey)
    }
  }, [activeTabName])

  React.useEffect(() => {
    updateTable();
    setDates({});
  }, [actualEndpoint]);


  const {
    isSuperAdmin,
    isFranchiseAdmin,
    isEmployee,
    isInspector,
  } = useUserRole();

  const additionalActions = (row) => {
    if(["employee","inspections","permitActivity","invoice","clients"].includes(activeTabName))
      return [
        {
         title: activeTabName,
          handler: () => handleEditInspections(row),
          Icon: VisibilityOutlined,
        },
      ];
    return null
  };

  const modifiedGetTableData = params => {
    if (activeTabName === 'clients') {
      return actualEndpoint(params).then(
        clientsResponse => ({
          ...clientsResponse,
          data: {
            ...clientsResponse.data,
            results: clientsResponse.data.results?.map(
              el => ({
                ...el,
                name: `${el?.firstName} ${el?.lastName}${
                  el?.companyName
                    ? ` (${el?.companyName})`
                    : ''
                }`,
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'permitActivity') {
      return actualEndpoint(params).then(
        inspectionResponse => {          
          return ({
            ...inspectionResponse,
            data: {
              ...inspectionResponse.data,
              results: inspectionResponse.data.results?.map(
                el => ({
                  ...el,
                jurisdiction: el?.projectJurisdiction,
                permitNumber: el?.permitNumber,
                reviewType: el?.reviewType,
                activityType: el?.activityTypeName,
                activityTitle: el?.activityTitleName,
                assignedTo: `${el?.assignedToFirstName} ${el?.assignedToLastName}`,
                contact: el?.contactFirstName ? `${el?.contactFirstName} ${el?.contactLastName}` : "",
                }),
              ),
            },
          })
        },
      );
    }
    if (activeTabName === 'employee') {
      return actualEndpoint(params).then(
        employeeResponse => ({
          ...employeeResponse,
          data: {
            ...employeeResponse.data,
            results: employeeResponse.data.results?.map(
              el => ({
                ...el,
                userRole:
                  el?.userRole === 1 ? 'Employee' : '',
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'invoice') {
      return actualEndpoint(params).then(
        invoiceResponse => ({
          ...invoiceResponse,
          data: {
            ...invoiceResponse.data,
            results: invoiceResponse.data.results?.map(
              el => ({
                ...el,
                invoicePdf: el?.invoicePdf
                ? `${window.location.origin}${el?.invoicePdf}`
                : '',
                total: currencyFormat.format(+el.total),
                dateInvoiced: el.dateInvoiced
                ? formatDate(
                    el.dateInvoiced,
                    DEFAULT_DATE_FORMAT,
                  )
                : el.dateInvoiced,
              datePaid: el.datePaid
                ? formatDate(
                    el.datePaid,
                    DEFAULT_DATE_FORMAT,
                  )
                : el.datePaid,
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'inspections') {
      return actualEndpoint(params).then(
        inspectionResponse => ({
          ...inspectionResponse,
          data: {
            ...inspectionResponse.data,
            results: inspectionResponse.data.results?.map(
              el => ({
                ...el,
                phoneNumber:el.contactPhoneNumber,
                cellPhone:el.contactMobilePhoneNumber,
                contactId:el.contact?el.contact : "",
                activityContact: el.contact?`${el.contactFirstName} ${el.contactLastName}`:""
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'tickets') {
      return actualEndpoint(params).then(
        employeeResponse => ({
          ...employeeResponse,
          data: {
            ...employeeResponse.data,
            results: employeeResponse.data.results?.map(
              el => ({
                ...el,
                status: el.statusTitle,
                createdAt: formatDateTime(
                  el.createdAt,
                  DEFAULT_DATETIME_FORMAT,
                ),
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'projectManager') {
      return actualEndpoint(params).then(pmResponse => {
        return {
        ...pmResponse,
        data: {
          ...pmResponse.data,
          results: updatePMResults(pmResponse.data.results,params),
        },
      }
      });
    }
    if (activeTabName === 'permit') {
      return actualEndpoint(params).then(
        permitResponse => {          
          return {
            ...permitResponse,
            data: {
              ...permitResponse.data,
              results: permitResponse.data.results?.map(
                el => ({
                  ...el,
                }),
              ),
            },
          }
        }
      );
    }
    return actualEndpoint(params);
  };

  const history = useHistory();
  const [selectctedTicket,setSelectedTicket] = useState<any>({});
  const [isTicketsModalOpen,setIsTicketsModalOpen] = useState<boolean>(false);
  const editTicket = (cell) => {
    setIsTicketsModalOpen(true);
    setSelectedTicket(cell);
  }

  const handlePermitEdit = (cell) => {    
    const path = generatePath(ROUTES.PERMIT, {
      projectId: cell?.projectId,
      permitId: cell?.id,
    });
    history.push(path, { from: 'globalSearchPage'});
  }
  const cellCallbackFnIsOn = ['invoicePdf'];

  const handleCellClick = rowValue => {
    openInNewTab(rowValue?.invoicePdf);
  };

  const handleProjectClick = data => {
    setIsEditProjectModal(true);
    const _fran = franchise.find(f => f.id === selectedFranchise);
    const frnachiseName = _fran ? _fran.name : '';
    setInitialValues({
      ...data,
      contacts: data.permitContacts,
      franchiseName: frnachiseName,
      zipCode: data.zip || "00000",
    });
  }

  const handleCloseForm = () => {
    handleCloseModal();
  };
  return (
    <>
      {!showPermitPage && <Container>
        <ProjectDetailsPopup 
          initialValues={initialValues} 
          setIsEditProjectModal={setIsEditProjectModal} 
          isEditProjectModal={isEditProjectModal}/>

        {activeTabName !== 'permit' && activeTabName !== 'tickets' &&
          <Table
          customSearch
          initialUseSortBy={false}
          columns={setColumns(activeTabName, 'columns')}
          columnsOrder={setColumns(activeTabName, 'order')}
          manuallyUpdate={tableManuallyUpdate}
          rows={modifiedGetTableData}
          {...(isEmployee && activeTabName === 'projectManager' && {onEdit: (cell) => handlePermitEdit(cell)})}
          additionalRequestParams={
            dates?.fromDate && dates?.toDate
              ? {
                  search: searchKey,
                  dateFrom: formatDate(
                    dates?.fromDate,
                    'yyyy-MM-dd',
                  ),
                  dateTo: formatDate(
                    dates?.toDate,
                    'yyyy-MM-dd',
                  ),
                }
              : { search: searchKey }
          }
          handleCellClick={handleProjectClick}
          cellCallbackFnIsOn={["projectName"]}
          // {...(isEmployee && {onEdit: (cell) => handleEditClient(cell)})}
          // {...(isEmployee && {useViewModeInsteadEdit: true})}
          useViewModeInsteadEdit={isEmployee}
          // onConfigure={null}
          // onEdit={null}
          onDelete={null}
          {...(["employee","inspections","permitActivity","invoice","clients"].includes(activeTabName) ? { 
            additionalActions: additionalActions,
           }
          : null)}
          formatValue={convertTableBoolean}
          // requestParamsIsCanceled
        />}


         {activeTabName === 'tickets' &&
          <Table
          customSearch
          initialUseSortBy={false}
          columns={setColumns(activeTabName, 'columns')}
          columnsOrder={setColumns(activeTabName, 'order')}
          manuallyUpdate={tableManuallyUpdate}
          rows={modifiedGetTableData}
          additionalRequestParams={
            dates?.fromDate && dates?.toDate
              ? {
                  search: searchKey,
                  dateFrom: formatDate(
                    dates?.fromDate,
                    'yyyy-MM-dd',
                  ),
                  dateTo: formatDate(
                    dates?.toDate,
                    'yyyy-MM-dd',
                  ),
                }
              : { search: searchKey }
          }
          handleCellClick={handleCellClick}
          cellCallbackFnIsOn={cellCallbackFnIsOn}
          {...(isEmployee && {onEdit: (cell) => editTicket(cell)})}
          // {...(isEmployee && {useViewModeInsteadEdit: true})}
          useViewModeInsteadEdit={isEmployee}
          // onConfigure={null}
          // onEdit={null}
          onDelete={null}
          {...(["employee","inspections","permitActivity","invoice","clients"].includes(activeTabName) ? { 
            additionalActions: additionalActions,
           }
          : null)}
          formatValue={convertTableBoolean}
          // requestParamsIsCanceled
        />}


         {isModalOpen && initialValues && activeTabName === "employee" && (
      <ModalDefaultDraggable
        title={modalTitle}
        onClose={handleCloseModal}
      >
        <UsersForm
          initialValues={initialValues}
          // modalError={modalErrorTitle.length > 0}
          isReadOnly
          initialErrors={errors}
          onSubmit={createOrUpdate}
        />
      </ModalDefaultDraggable>
      )}
         {isModalOpen && initialValues && activeTabName==="inspections" && (
      <ModalDefaultDraggable
        title="Permit Inspections"
        onClose={handleCloseModal}
      >
            <PermitInspectionsForm
              onSubmit={createOrUpdate}
              initialValues={initialValues}
              modalError={modalError}
              updateButtonText="Update"
              initialErrors={errors}
              permit={initialValues?.permit}
              setInitialValues={setInitialValues}
              isReadOnly
            />
      </ModalDefaultDraggable>
      )}
               {isModalOpen && initialValues && activeTabName==="invoice" && (
      <ModalDefaultDraggable
        title="Invoice"
        onClose={handleCloseModal}
      >
           <PermitFeesForm
            onSubmit={createOrUpdate}
            initialValues={initialValues}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={initialValues?.permitId}
            // jurisdiction={projectJurisdiction}
            handleCloseModal={handleCloseModal}
            isReadOnly
          />
      </ModalDefaultDraggable>
      )}

      {isModalOpen && initialValues && activeTabName==="clients" && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <UsersForm
            searchForm
            isReadOnly
            initialValues={initialValues}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

         {isModalOpen && initialValues && activeTabName==="permitActivity" && (
      <ModalDefaultDraggable
        title="Permit Activity"
        onClose={handleCloseModal}
      >
            <PermitActivitiesForm
              onSubmit={createOrUpdate}
              initialValues={initialValues}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={initialValues.permit}
              setInitialValues={setInitialValues}
              isReadOnly
            />
      </ModalDefaultDraggable>
      )}
        {activeTabName === 'permit' && 
          <Table
          customSearch
          columns={setColumns(activeTabName, 'columns')}
          rows={modifiedGetTableData}
          {...(isEmployee && {onEdit: (cell) => handlePermitEdit(cell)})}
          onDelete={null}
          columnsOrder={setColumns(activeTabName, 'order')}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
          additionalRequestParams={dates?.fromDate && dates?.toDate
            ? {
                search: searchKey,
                dateFrom: formatDate(
                  dates?.fromDate,
                  'yyyy-MM-dd',
                ),
                dateTo: formatDate(
                  dates?.toDate,
                  'yyyy-MM-dd',
                ),
              }
            : { search: searchKey }}
            {...(isEmployee && {useViewModeInsteadEdit: true})}
            useViewModeInsteadEdit={isEmployee}
            handleCellClick={handleProjectClick}
            cellCallbackFnIsOn={["projectName"]}
        />
        }
      </Container>}

       {isTicketsModalOpen  && (
        <ModalDefaultDraggable
          onClose={() => setIsTicketsModalOpen(false)}
          title={modalTitle}
        >
          <TicketsForm
            permit={selectctedTicket.permitId}
            projectId={selectctedTicket.projectId}
            initialValues={selectctedTicket}
            initialErrors={errors}
            isModalError={false}
            onSubmit={()=>{}}
            ticketViewOnly={true}
          />
        </ModalDefaultDraggable>
      )}
    </>
  );
};

const Container = styled.div`
  margin: 0;
  width: 100%;
`;

interface SearchTableProps {
  actualEndpoint: Promise<AxiosPromise>;
  searchKey: string;
  activeTabName: string;
  dates: { fromDate: Date; toDate: Date };
  setDates: (data: any) => void;
}

export default SearchTable;
