import styled from "styled-components";

export default {};
export const RecordWrapper = styled.div<{ $primary: boolean }>`
    @media only screen and (max-width: 840px) and (min-width: 600px) {
        grid-template-columns: 2fr 1fr;
        background-color: ${props => props.$primary ? "white" : "rgb(252,249,252)"};
        padding: 25px;
        border-bottom: 1px solid gray;
    }
    @media only screen and (max-width: 390px) {
        grid-template-columns: 2fr 1fr;
        background-color: ${props => props.$primary ? "white" : "rgb(252,249,252)"};
        padding: 25px;
        font-size:11px;
        border-bottom: 1px solid gray;
    }
`;

export const MobileTableWrapper = styled.div`
   display: none;
   @media only screen and (max-width: 840px)  {
       display: flex;
       flex-direction: column;
       max-height: 57vh;
       overflow-y:auto;
    }
    @media only screen and (max-width: 390px)  {
       max-height: 38vh;
    }
`;

export const AddressCell = styled.div`
  grid-column: 1/3;
`;

export const CommentCell = styled.div`
  grid-column: 1/3;
  max-width: 448px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
