import React, { useEffect } from 'react';
import { InputBaseContainer, InputSelectWithLabel } from '../../../../../molecules';
import { Controller } from 'react-hook-form';
import { labels } from '../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import DropdownList from './DropdownList';

const InspectionType = ({ control, errors, setValue,getValues, setDisabledSubmit }) => {
  const { inspectionsTypeData, selectedInspection } = useSelector((s: RootState) => s.inspections);
  useEffect(() => {
    const currentValue = getValues('inspectionType');
    //this is the case where we changed the InspectionType before we persist it
    if(currentValue.id && currentValue.id !== selectedInspection.inspectionType)
    {
      return;
    }
    if (selectedInspection.inspectionType && inspectionsTypeData?.length > 0) {
      const value = inspectionsTypeData.find(
        item =>
          item.id === selectedInspection.inspectionType ||
          //@ts-ignore
          item.name === selectedInspection.inspectionType,
      );
      setValue('inspectionType', value);
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }
  }, [inspectionsTypeData, selectedInspection]);

  return (
    <InputBaseContainer>
      <Controller
        name="inspectionType"
        defaultValue=""
        control={control}
        render={props => (
          <DropdownList
            data={inspectionsTypeData}
            label={labels.inspectionType}
            isLoading={!inspectionsTypeData || inspectionsTypeData?.length === 0}
            {...props}
          />
        )}
      />
    </InputBaseContainer>
  );
};

export default InspectionType;
