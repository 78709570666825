import React from 'react';
import styled from 'styled-components';
import { AxiosPromise } from 'axios';

import {DEFAULT_DATETIME_FORMAT} from '../../constants';
import Table from '../Table';
import setColumns from './columns';
import { convertTableBoolean } from '../../utils/convertTableValues';
import formatDate, {
  formatDateTime,
} from '../../utils/formatDate';
import useTablePage from "../../hooks/useTablePage";
import {InspectionTableType} from "../../api/inspectionTypes";
import {ModalDefaultDraggable} from "../../molecules";
import PermitInspectionsForm from "../../forms/permitInspectionForm/PermitInspectionsForm";
import SearchInspections from '../../pages/Responsive/SearchInspections/SearchInspections';
import { setDisabledGlobalSearch, setModalOpen, setSearchInspections, setSelectedInspection } from '../../redux/slices/inspectionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import PermitActivitiesForm from '../../forms/PermitActivitiesForm';
import { LibraryBooks } from '@material-ui/icons';
import DocumentsDetailedPage from '../../pages/Permits/DocumentsDetailedPage';
import ModalFullScreen from '../../molecules/ModalFullScreen';
import { InspectionItemType } from '../../modules/clientModule/types';
import InspectionEditor from '../../pages/Reports/DailyInspectionReport/InspectionEditor/InspectionEditor';
import { RootState } from '../../redux/root';
import { setPermitId, setPermitInspectionsId, setShowPermitTable } from '../../redux/slices/globalSearch';
import InspectionPermitsTable from './InspectionPermitsTable';
import PopupInspections from '../../pages/Responsive/SearchInspections/PopupInspections';


const SearchTableInspector = ({
  actualEndpoint,
  searchKey,
  activeTabName,
  dates,
  setDates,
}: SearchTableProps) => {

  const {
    modalError,
    modalSuccess,
    isModalOpen,
    modalTitle,
    values,
    errors,
    tableManuallyUpdate,
    updateTable,
    createOrUpdate,
    handleEdit,
    handleCloseModal,
    handleCloseModalInfo,
   } = useTablePage<InspectionTableType>({
      modalUpdateTitle: '',
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    updateTable();
    setDates({});
  }, [actualEndpoint]);
  const selectedInspection = useSelector((s:RootState) => s.inspections.selectedInspection);
  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
        handleCloseModal();
    }
  };
  const [isAddDocument, setAddDocument] = React.useState(
    false,
  );
  const [
    documents,
    showDocuments,
  ] = React.useState(null);
  const [
    isDownloadAllDocs,
    setDownloadAllDocs,
  ] = React.useState(false);
  const [
    isDocsAvailable,
    setDocsAvailable,
  ] = React.useState(false);
  const [
    isDocsWaitingOnClient,
    setDocsWaitingOnClient,
  ] = React.useState(false);
  const [isSendDocs, setSendDocs] = React.useState(false);
  const [
    permit,
    setPermit,
  ] = React.useState(null);
  const [customReviewsData, setCustomReviewsData] = React.useState(null);
  const [offset, setOffset] = React.useState(0)

  React.useEffect(() => {
      actualEndpoint({search: searchKey, activityType: 7, limit: 25, offset}).then(reviews => {
        const modifiedData = reviews?.data?.results?.filter(el => !el?.reviewTypeComment?.length)?.map(el => el?.id)        
        setCustomReviewsData(modifiedData)
      })
  }, [searchKey, activeTabName === "permitActivity", offset])
  

  const handleEditModal = data => {
    if (data) {
      setPermit(data?.permit);
      handleEdit(data);
    }
  };

  const openEditor = (inspection: InspectionItemType) => {
    dispatch(setSelectedInspection(inspection));
    dispatch(setModalOpen(true));
    dispatch(setDisabledGlobalSearch(true))
  };
  const modifiedGetTableData = params => {    
    setOffset(params?.offset)
    if (activeTabName === 'inspections') {
      return actualEndpoint(params).then(
        clientsResponse => {
          const payload = {
            ...clientsResponse,
            data: {
              ...clientsResponse.data,
              results: clientsResponse.data.results?.map(
                el => {
                  const payload = {
                  ...el,
                  contactEmail: `${el?.contactFirstName} ${el?.contactLastName}`.replace(/null null/,''),
                  assignedToEmail: `${el?.assignedToFirstName} ${el?.assignedToLastName}`.replace(/null null/,''),
                  inspectorEmail: `${el?.inspectorFirstName} ${el?.inspectorLastName}`.replace(/null null/,''),
                }
                delete(payload.inspectorFirstName);
                delete(payload.inspectorLastName);
                delete(payload.assignedToFirstName);
                delete(payload.assignedToLastName);
                   return payload;
                },
              ),
            },
          };
          // dispatch(setSearchInspections(payload));
          return payload;
        },
      );
    }
    if (activeTabName === 'clients') {
      return actualEndpoint(params).then(
        clientsResponse => {
          const payload = {
            ...clientsResponse,
            data: {
              ...clientsResponse.data,
              results: clientsResponse.data.results?.map(
                el => ({
                  ...el,
                  name: `${el?.firstName} ${el?.lastName}${
                    el?.companyName
                      ? ` (${el?.companyName})`
                      : ''
                  }`,
                }),
              ),
            },
          };
          dispatch(setSearchInspections(payload));
          return payload;
        },
      );
    }
    if (activeTabName === 'employee') {
      return actualEndpoint(params).then(
        employeeResponse => ({
          ...employeeResponse,
          data: {
            ...employeeResponse.data,
            results: employeeResponse.data.results?.map(
              el => ({
                ...el,
                userRole:
                  el?.userRole === 1 ? 'Employee' : '',
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'tickets') {
      return actualEndpoint(params).then(
        employeeResponse => ({
          ...employeeResponse,
          data: {
            ...employeeResponse.data,
            results: employeeResponse.data.results?.map(
              el => ({
                ...el,
                createdAt: formatDateTime(
                  el.createdAt,
                  DEFAULT_DATETIME_FORMAT,
                ),
              }),
            ),
          },
        }),
      );
    }
    if (activeTabName === 'permitActivity') {      
      return actualEndpoint(params).then(
        reviewsResponse => ({
          ...reviewsResponse,
          data: {
            ...reviewsResponse.data,
            results: reviewsResponse.data.results?.map(
              el => {
                const payload = {
                ...el,
                projectPermit: `${el?.projectName} - ${el?.permitName}`,
                jurisdiction: el?.projectJurisdiction,
                permitNumber: el?.permitNumber,
                reviewType: el?.reviewType,
                activityTitle: el?.activityTitleName,
                assignedTo: `${el?.assignedToFirstName} ${el?.assignedToLastName}`
                }
                delete(payload.activityType);
                return payload;
              },
            ),
          },
        }),
      );
    }
    if (activeTabName === 'projectManager') {
      return actualEndpoint(params).then(pmResponse => {
        const payload = {
          ...pmResponse,
          data: {
            ...pmResponse.data,
            results: pmResponse.data.results?.sort((a, b) => {
              if (a?.managerEmail > b?.managerEmail) {
                return 1;
              }
              if (a?.managerEmail < b?.managerEmail) {
                return -1;
              }
              return 0;
            }),
          },
        };
        dispatch(setSearchInspections(payload));
        return payload;
      });
    }
    return actualEndpoint(params);
  };

  const additionalActions = rowValue => {
    if(activeTabName === "permitActivity") {      
      return [
        {
          title: 'View Documents',
          handler: () => showDocuments(rowValue),
          Icon: LibraryBooks,
        },
      ];
    } else {
      return null
    }
  };

  const closeInfoModal = () => {
    // setInitialValues(null);
    showDocuments(null);
    updateTable();
    handleCloseModalInfo();
  };

  const handleCellClick = rowValue => {
    dispatch(setShowPermitTable(true));
    dispatch(setPermitInspectionsId(rowValue.permit));
    dispatch(setPermitId(rowValue.permit));
  };
  return (
    <>
      <SearchInspections />
      <PopupInspections />
      <Container>
        {customReviewsData && <Table
          customSearch
          useViewModeInsteadEdit
          initialUseSortBy={false}
          columns={setColumns(activeTabName, 'columns', 'inspector')}
          columnsOrder={setColumns(activeTabName, 'order','inspector')}
          manuallyUpdate={tableManuallyUpdate}
          rows={modifiedGetTableData}
          additionalActions={additionalActions}
          additionalRequestParams={
            dates?.fromDate && dates?.toDate
              ? {
                  search: searchKey,
                  activityType: 7,
                  dateFrom: formatDate(
                    dates?.fromDate,
                    'yyyy-MM-dd',
                  ),
                  dateTo: formatDate(
                    dates?.toDate,
                    'yyyy-MM-dd',
                  ),
                }
              : { search: searchKey,
                  activityType: 7
                }
          }
          onConfigure={null}
          // onEdit={handleEditModal}
          onEdit={openEditor}
          onDelete={null}
          formatValue={convertTableBoolean}
          reviewerCommentData={customReviewsData}
          handleCellClick={handleCellClick}
          cellCallbackFnIsOn={(['projectPermit','projectPermitName'])}
        />}
        <InspectionPermitsTable />
        {selectedInspection.id && <InspectionEditor />}
        {documents &&(
        <ModalFullScreen
          title={'Review Comments'}
          onClose={closeInfoModal}
        >
        <DocumentsDetailedPage
              permit={documents.permitId}
              work={null}
              building={null}
              isAddDocument={false}
              setAddDocument={setAddDocument}
              isDownloadAllDocs={false}
              setDocsAvailable={setDocsAvailable}
              setDownloadAllDocs={setDownloadAllDocs}
              setDocsWaitingOnClient={setDocsWaitingOnClient}
              isSendDocs={false}
              setSendDocs={setSendDocs}
              isInspector={true}
              initialValues={documents} 
              onSubmit={function (data: any): void {
                throw new Error('Function not implemented.');
              }} 
              isViewMode
              />
        </ModalFullScreen>
      )}

      {isModalOpen && activeTabName === 'inspections' && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseForm}
        >
          <PermitInspectionsForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            isSimulateDeleted={true}
          />
        </ModalDefaultDraggable>
      )}
      {isModalOpen && activeTabName === 'permitActivity' && (
        <ModalDefaultDraggable
          title={'Reviews'}
          onClose={handleCloseForm}
          // extraWide
        >
        
          <PermitActivitiesForm
              onSubmit={createOrUpdate}
              initialValues={values}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={permit}
              isReadOnly={true}
          />
        </ModalDefaultDraggable>
      )}
      </Container>
    </>
  );
};

const Container = styled.div`
  @media only screen and (max-width: 840px) {
    display: none;
  }
  margin: 0;
  width: 100%;
`;

interface SearchTableProps {
  actualEndpoint?: Promise<AxiosPromise>;
  searchKey: string;
  activeTabName: string;
  dates: { fromDate: Date; toDate: Date };
  setDates: (data: any) => void;
}

export default SearchTableInspector;
