/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  ErrorMessages,
  ButtonWithLoader,
} from '../molecules';
import { FormServerError } from '../types';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { getFranchiseList } from '../api/franchise';
import { USER_TYPES } from '../constants/forms';
import { MessagesType } from '../api/messages';
import { getUsers, UserType } from '../api/users';

const labels = {
  text: 'Message',
  franchise: 'Franchise',
  userRole: 'User type',
  addresses: 'Addresses',
};

const schema = yup.object().shape({
  text: yup.string().required().label(labels.text),
  franchise: yup.string(),
  userRole: yup.number(),
  addresses: yup
    .array()
    .required()
    .typeError('Select a Addresses from the list')
    .label(labels.addresses),
});

const MessagesForm = ({
  onSubmit,
  modalError,
  selectedUserRole,
  initialValues,
  addButtonText = 'Send',
  updateButtonText = 'Send',
  initialErrors,
}: Props) => {
  const ALL = {
    firstName: 'All',
    lastName: USER_TYPES.find(
      item => item.value === selectedUserRole,
    )?.label,
    id: 0,
  };

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    formState,
  } = useForm<MessagesType>({
    resolver: yupResolver(schema),
    defaultValues: {
      userRole: selectedUserRole,
      addresses: [ALL],
    } as MessagesType,
  });

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [usersData, setUsersData] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getFranchiseList({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
      getUsers({
        ...GET_ALL_QUERY_PARAMS,
        userRole: selectedUserRole,
      }),
    ])
      .then(([franchisesResponse, usersResponse]) => {
        const franchises = franchisesResponse.data.results.map(
          item => item.id,
        );

        setValue(
          'franchise',
          franchises.filter(Boolean).length
            ? franchises
            : undefined,
        );

        setUsersData([ALL, ...usersResponse.data.results]);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<MessagesType>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (
    submitFn: (data: MessagesType) => void,
  ) => (data: MessagesType) => {
    submitFn({
      ...data,
      franchise: data.franchise
        ? (data.franchise as string)
            .split(',')
            .map(parseFloat)
        : undefined,
      addresses: (data.addresses as UserType[])
        ?.map(item => item.id)
        .filter((item: number) => item !== ALL.id),
    });
  };

  return (
    <Container disabled={isSubmitting && !modalError}>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="addresses"
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                placeholder=""
                label={labels.addresses}
                error={errors.addresses}
                isLoading={isLoading}
                isDisabled={isLoading}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) =>
                  `${item.firstName} ${item.lastName}`
                }
                isRequired={isYupFieldRequired(
                  'addresses',
                  schema,
                )}
                options={usersData}
                {...props}
                onChange={(value: any[], action: any) => {
                  if (
                    action.option &&
                    action.option.id === ALL.id
                  ) {
                    props.onChange([ALL]);
                  } else {
                    props.onChange(
                      value?.filter(
                        item => item.id !== ALL.id,
                      ),
                    );
                  }

                  if (
                    value.length ===
                    usersData.length - 1
                  ) {
                    props.onChange([ALL]);
                  }
                }}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="text"
            inputRef={register}
            error={errors.text}
            label={labels.text}
            rows={2}
            rowsMax={4}
            multiline
            isRequired={isYupFieldRequired('text', schema)}
          />
        </InputBaseContainer>

        <input
          type="hidden"
          name="userRole"
          ref={register}
        />
        <input
          type="hidden"
          name="franchise"
          ref={register}
        />

        {errors?.franchise && (
          <ErrorMessages error={errors.franchise} />
        )}

        <ButtonWithLoader
          disabled={isSubmitting && !modalError}
          loading={isSubmitting && !modalError}
          type="submit"
        >
          {initialValues ? updateButtonText : addButtonText}
        </ButtonWithLoader>
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  selectedUserRole: number;
  updateButtonText?: string;
  initialValues?: MessagesType;
  onSubmit: (data: MessagesType) => void;
  initialErrors?: FormServerError<MessagesType>;
  modalError: boolean;
}

export default MessagesForm;
