import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import CanvasDraw from 'react-canvas-draw';
import { debounce } from 'throttle-debounce';
import { saveSignature } from '../../api/userAuth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { setUserInfo } from '../../redux/ducks/userAuth';

const SignatureEditor = ({ setShowEditor }) => {
  const [canvas, setCanvas] = useState<any>();
  const info = useSelector((s:RootState) => s.userAuth.info);
  const dispatch = useDispatch();
  const onSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEditor(false);
    //@ts-ignore
    const url = canvas.getDataURL('PNG', false, '#fff');
    const sigData = url.replace(/data:image\/png;base64,/, '');
    const result = await saveSignature(info.id,sigData);
    dispatch(setUserInfo({...info,signature: sigData}));
    return false;
  };

  const onCancel = () => {
    setShowEditor(false);
  }
  const onClose = e => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const handleOnClose = React.useCallback(debounce(300, onClose), []);
  const onReset = () => {
    canvas.clear();
  };
  return (
    <>
      <Header>Update your signature</Header>
      <PadWrapper>
        <Content>
          {' '}
          <CanvasDraw
            ref={cvs => setCanvas(cvs)}
            brushRadius={2}
            lazyRadius={0}
            canvasWidth={400}
            canvasHeight={100}
            hideInterfact={true}
            hideGrid
            brushColor="#000"
            catenaryColor="#fff"
          />
        </Content>
      </PadWrapper>
      <Buttons>
      <Button color="primary" variant="contained" onClick={onCancel} onTouchStart={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onTouchEnd={onReset} onMouseUp={onReset}>
          Clear Signature
        </Button>
        <Button color="primary" variant="contained" onClick={onSave} onTouchStart={onSave}>
          Save
        </Button>
      </Buttons>
    </>
  );
};

export default SignatureEditor;

const PadWrapper = styled.div`
  width: fit-content;
  background-color: grey;
`;

const Content = styled.div`
  margin: 2px;
  border-radius: 6px;
  border-top: 11px solid #555;
  border-left: 11px solid #555;
  border-bottom: 11px solid #888;
  border-right: 11px solid #888;
  background-color: #aaa;
  position: relative;
`;

const Header = styled.div`
  font-size: 12px;
`;

export const Buttons = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 74%;
  padding-top: 18px;
`;
