/* eslint-disable no-console */
import * as React from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

import { SelectInputWithSelectAll } from '../../atoms';
import { getFranchiseActivities } from '../../api/dashboard';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import { getUsers } from '../../api/users';
import { USER_TYPES_VALUES } from '../../constants/forms';

const FATodayTaskWidget = () => {
  const [employees, setEmployees] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [
    selectedEmployees,
    setSelectedEmployees,
  ] = React.useState(null);

  React.useEffect(() => {
    // Show all employees on initial load
    setSelectedEmployees([]);

    getUsers({
      ...GET_ALL_QUERY_PARAMS,
      userRole: USER_TYPES_VALUES.EMPLOYEE,
    })
      .then(response => {
        const result = response.data.results.map(el => ({
          ...el,
          fullName: `${el.firstName} ${el.lastName}`,
        }));
        setEmployees(result);
      })
      .catch(e => console.error(e));
  }, []);

  React.useEffect(() => {
    getFranchiseActivities(selectedEmployees || [])
      .then(response => {
        const result = response.data.map(el => ({
          ...el,
          id: el.title,
          value: el.count,
        }));
        setData(result);
      })

      .catch(e => console.error(e));
  }, [selectedEmployees]);

  const onSelectChange = (
    selected: any[],
    isCanceled: any,
  ) => {
    if (!isCanceled) {
      setSelectedEmployees(
        selected?.map((item: { id: any }) => item.id),
      );
    }
  };

  return (
    <Wrapper>
      <Header>Today&apos;s Tasks</Header>
      {employees && employees?.length && (
        <SelectContainer>
          <SelectInputWithSelectAll
            data={employees}
            valueKey="id"
            labelKey="fullName"
            onMenuClose={onSelectChange}
            placeholder="Select Employees from the list..."
          />
        </SelectContainer>
      )}
      {data && data.length && (
        <Pie>
          <ResponsivePie
            data={data}
            colors={{ scheme: 'pastel2' }}
            margin={{ top: 40, bottom: 40 }}
            innerRadius={0.5}
            padAngle={0.2}
            cornerRadius={4}
            enableRadialLabels={false}
            sortByValue
          />
        </Pie>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 6px;
  width: 520px;
  min-height: 300px;
  border: 1px solid var(--border);
  border-radius: 5px;
  flex: 1;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const SelectContainer = styled.div`
  margin: 16px 0;
`;

const Pie = styled.div`
  position: relative;
  width: 100%;
  height: 420px;
`;

const Header = styled.div`
  background: var(--primary);
  color: var(--defaultBackground);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
`;

export default FATodayTaskWidget;
