import * as React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import {
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';

import { Checkbox } from '../atoms';
import ErrorMessages from './ErrorMessages';

const CheckboxWithLabel = ({
  disabled,
  error,
  label,
  isRequired,
  labelPlacement = 'start',
  ...props
}: Props) => {
  const labelWithRequired = (
    <>
      {label}
      {isRequired && <span>*</span>}
    </>
  );

  return (
    <InputContainer>
      <CheckboxLabel
        error={error}
        disabled={disabled}
        label={labelWithRequired}
        labelPlacement={labelPlacement}
        control={
          <StyledCheckbox
            error={error}
            readonly={disabled}
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {error && <ErrorMessages error={error} />}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const CheckboxLabel = styled(({ error, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <FormControlLabel {...rest} />
))<CheckboxProps>`
  margin: 0;

  .MuiTypography-body1 {
    color: ${props =>
      props.error
        ? 'var(--mainRequired)'
        : 'var(--mainText)'};
  }

  .MuiButtonBase-root {
    padding: 0;
  }

  .MuiFormControlLabel-label {
    font-size: inherit;
    padding: ${({ labelPlacement }) =>
      labelPlacement === 'start'
        ? '0 9px 0 0'
        : '0 0 0 9px'};

    span {
      color: var(--mainRequired);
      margin: 0 0 0 4px;
    }
  }
`;

const StyledCheckbox = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({ error, readonly, ...rest }) => <Checkbox {...rest} />,
)<CheckboxProps>`
  .MuiSvgIcon-root {
    fill: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.error
        ? 'var(--mainRequired)'
        : props.readonly
        ? 'var(--border)'
        : 'var(--primary)'};
  }
`;

interface CheckboxProps {
  error: FieldError;
  readonly: boolean;
}

type Props = Omit<FormControlLabelProps, 'control'> &
  Partial<CheckboxProps> & {
    isRequired?: boolean;
  };

export default CheckboxWithLabel;
