import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Loader, SuccessCheckmark } from '../../atoms';
import PopupMessage from '../../pages/Reports/DailyInspectionReport/InspectionEditor/MessagePopups/PopupMessage';
import { downloadInspectionReport } from '../../api/inspectionTypes';
import { handleMerge } from './utils';
import { RootState } from '../../redux/root';
import { dispatch } from '../../redux/store';
import { setShowMergingForms } from '../../redux/slices/inspectionsSlice';
import { createPermitInspectionsComment } from '../../api/inspections';
import { createNote } from '../../api/notes';

const DownloadingPopup = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [link, setLink] = useState<any>();
  const [downloadErr, setDownloadErr] = useState<string[]>([]);
  const { mergingFormsArray: inspections } = useSelector((s: RootState) => s.inspections);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const startDownload = async () => {
      await downloadAllReports();
      setShowSuccess(true);
    };
    startDownload();
  }, []);

  useEffect(() => {
    if (ref.current) {
      const child: any = ref.current.firstChild;
      child.style.top = '0';
      child.style.left = '0';
    }
  }, [ref]);
  const saveReport = (event: any): void => {
    if (link && link.download) {
      link.click();
    }
  };

  const downloadAllReports = async () => {
    const dataArr = [];
    for (let i = 0; i < inspections.length; i++) {
      try {
        const result = await downloadInspectionReport(inspections[i].id);
        const binaryString = atob(result.data.file);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let j = 0; j < len; j++) {
          bytes[j] = binaryString.charCodeAt(j);
        }
        dataArr.push(bytes.buffer);
      } catch (e) {
        const errArr = [...downloadErr];
        const name = inspections[i].permitNumber ? inspections[i].permitNumber : inspections[i].projectName;
        errArr.push(name);
        setDownloadErr(errArr);
      }
    }

    if (inspections && inspections.length > 0) {
        const { permitNumber, projectName,permit} = inspections[0];
      const _name = permitNumber ? permitNumber : projectName;
      const _link = await handleMerge(_name, dataArr);
      setLink(_link);
      const notePayload = { text: 'Inspection reports were downloaded', permit };
      await createNote(notePayload);
    }
  };
  return (
    <div ref={ref}>
      <PopupMessage caption="Merging Inspections Forms">
        {showSuccess && (
          <>
            <MessageLine>
              <Message>Success !!!</Message>
              <CheckWrapper>
                <SuccessCheckmark />
              </CheckWrapper>
            </MessageLine>

            <PrintLine>
              <MessageLink onClick={saveReport}>Print Report </MessageLink>
              <PrintIconWrapper>
                <PrintIcon onClick={saveReport} />
              </PrintIconWrapper>
            </PrintLine>

            {downloadErr.length > 0 && (
              <ErrorsBlock>
                There was an issue downloading reports:
                {downloadErr.map((item, index) => (
                  <ReportId key={index}>{item},</ReportId>
                ))}
                Please retry later.
              </ErrorsBlock>
            )}
          </>
        )}
        {!showSuccess && (
          <MessageLine>
            <Message>Merging...</Message>
            <div>
              <Loader />
            </div>
          </MessageLine>
        )}
        <Buttons>
          <Button
            disabled={!showSuccess}
            variant="contained"
            color="primary"
            style={{ width: '200px' }}
            onClick={() => dispatch(setShowMergingForms(false))}
          >
            Close
          </Button>
        </Buttons>
      </PopupMessage>
    </div>
  );
};

export default DownloadingPopup;

const ErrorsBlock = styled.div`
  color: red;
  display: flex;
  flex-direction: row;
  margin: auto;
`;
const ReportId = styled.div`
  margin-right: 7px;
`;
const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
`;

const MessageLine = styled.div`
  display: flex;
  margin: 0 220px;
  font-size: 25px;
`;

const PrintLine = styled.div`
  display: flex;
  margin: 0 220px;
  font-size: 20px;
`;

const Message = styled.div`
  flex-grow: 1;
`;

const CheckWrapper = styled.div`
  zoom: 0.4;
`;

const MessageLink = styled.div`
  text-decoration: underline;
  color: blue;
  flex-grow: 1;
  cursor: pointer;
`;

const PrintIconWrapper = styled.div`
  color: var(--primary);
  svg {
    zoom: 1.8;
  }
`;

const Wrapper = styled.div`
  top: 300px;
`;
