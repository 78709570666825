import React, { FC, useState } from 'react';
import styled from 'styled-components';
import HeadLine from '../../../organisms/HeadLine';
import { Hamburger } from '../../../organisms/InspectorSidebar/InspectorSidebar';
import Menu from '@material-ui/icons/Menu';
import ClientSidebar from './ClientSidebar';
import { Logo_Sky } from '../../../constants/menu';

type Props = {
  topCaption?: string;
};
const ClientContainer: FC<Props> = ({ topCaption, children }) => {
  document.body.style['height'] = '100%';
  const app = document.getElementById('app');
  app.style['height'] = '100%';
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const openSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <Wrapper>
      <Layout>
      <LayoutWithNav>
        <Hamburger>
          <Menu onClick={() => openSidebar()} />
        </Hamburger>
        <SidebarDesktopContainer>
          <ClientSidebar />
        </SidebarDesktopContainer>
        {showSidebar && (
          <SidebarMobileContainer>
            <ClientSidebar isMobile={true} />
          </SidebarMobileContainer>
        )}
        <MainArea>
          <Header>
            <TopCaption>{topCaption ? `${topCaption}` : 'ELITE PERMITS CLIENT PORTAL'}</TopCaption>
            <HeadLine />
          </Header>
          <BodyArea>{children}</BodyArea>
        </MainArea>
      </LayoutWithNav>
      <Footer></Footer>
      </Layout>
    </Wrapper>
  );
};

export default ClientContainer;

const Layout = styled.div`
 display: grid;
 grid-template-rows: 1fr 50px;
`;

const Footer = styled.div`
  width: 100%;
  height: 50px;
  background-color: red;
`;

const TopCaption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 39px;
  color: ${Logo_Sky};
  font-weight: bold;
  font-size: 23px;
  padding-left: 87px;
  @media only screen and (max-width: 540px) {
    font-size: 16px;
    padding-left: 77px;
  }
`;
const SidebarDesktopContainer = styled.div`
  height: 100%;
`;

const SidebarMobileContainer = styled.div`
  padding-top: 84px;
  position: absolute;
`;

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const Header = styled.div`
  display: flex;
  border-top: 1px solid grey;
`;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  /* border: 1px solid green; */
  flex-direction: column;
`;

const LayoutWithNav = styled.div`
  display: flex;
  /* height: 100svh; */
  /* height: 96%; */
  /* border: 1px solid red; */
`;
const BodyArea = styled.div`
  flex-grow: 1;
  border-top: 1px solid grey;
  height: 100%;
  @media only screen and (max-width: 840px) {
    height: 100%;
    overflow-y: auto;
  }
`;
