import * as React from 'react';
import styled from 'styled-components';
import { ButtonMain } from '../atoms';

const ProjectDetails = ({
  initial,
  changeEditMode,
  hideButtons
}: Props) => {
  return (
    <>
      <Line>
        <Title>Project Name</Title>
        <Body>{initial.name}</Body>
      </Line>
      <Line>
        <Title>Address</Title>
        <Body>{initial.address}</Body>
      </Line>
      <Box>
        <Line marginRight>
          <Title>City</Title>
          <Body>{initial.city}</Body>
        </Line>
        <Line>
          <Title>State</Title>
          <Body>{initial.state}</Body>
        </Line>
      </Box>
      <Box>
        <Line marginRight>
          <Title>Street</Title>
          <Body>{initial.street}</Body>
        </Line>
        <Line>
          <Title>Zip</Title>
          <Body>{initial.zipCode}</Body>
        </Line>
      </Box>
      <Line>
        <Title>Contacts</Title>
        <Body>
          {initial.contacts?.length
            ? initial.contacts
                .map(
                  el => `${el?.firstName} ${el?.lastName}`,
                )
                ?.join(', ')
            : 'Project has no contacts'}
        </Body>
      </Line>
      <Box>
        <Line marginRight>
          <Title>Franchise</Title>
          <Body>{initial.franchiseName}</Body>
        </Line>
        <Line>
          <Title>Jurisdiction</Title>
          <Body>{initial.jurisdictionName}</Body>
        </Line>
      </Box>
      <Line>
        <Title>Project Manager</Title>
        <Body>{initial.managerName}</Body>
      </Line>
      {!hideButtons && <ButtonContainer>
        <StyledButtonMain
          variant="outlined"
          onClick={() => changeEditMode(true)}
        >
          Edit
        </StyledButtonMain>
      </ButtonContainer>}
    </>
  );
};

interface Props {
  initial: any;
  changeEditMode: (data: boolean) => void;
  hideButtons?: boolean;
}

interface LineProps {
  marginRight?: boolean;
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Line = styled.div<LineProps>`
  width: 100%;
  ${({ marginRight }) =>
    marginRight ? 'margin-right: 10px;' : ''}
`;

const Title = styled.p`
  margin: 0;
  padding: 5px 5px 0px 5px;
`;

const Body = styled.div`
  padding: 10px;
  border-radius: 1px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin: 10px 0px 15px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

const StyledButtonMain = styled(ButtonMain)`
  &.MuiButton-root {
    line-height: 1.15;
  }
`;

export default ProjectDetails;
