import axios from 'axios';
import { PaginatedList, PaginatedRequest } from '../types';
import { api } from './api';
import { FranchiseType } from './franchise';
import {
  onResponseErrorInterceptor,
  onResponseInterceptor,
} from './interceptors';
import { getAccessToken } from '../auth';
import { DocumentType } from './document';
import { formatToFormData } from '../utils';

export const getPermitActivities = params =>
  api.request({
    method: 'GET',
    url: '/project_activities/',
    params,
  });

export const getCustomActivities = params =>
  api.request({
    method: 'GET',
    url: '/project_activities/custom/',
    params,
  });

export const getPermitDocuments = params =>
  api.request({
    method: 'GET',
    url: `/project_documents/?permit_id=${params?.permit}`,

  });
// EA-182
// mritunjoy
export const getActivityFees = (params,
  activityId) => {
  return api.request({
    method: 'GET',
    url: `project_activities/${activityId}/fees/`,
    params
  });
}

export const getUnLockDocuments = async (params, permit_id, cb) => {
  const formData = new FormData();
  formData.append('is_locked', params?.is_locked)
  const res = await api.patch(
    `projects/permits/${permit_id}/`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  cb(res)
}


export const getPermitActivitiesForUser = params => {
  return api.request({
    method: 'GET',
    url: `/project_activities/`,
    params,
  });
};

export const getTasksheetPermitActivitiesForUser = params => {
  return api.request({
    method: 'GET',
    url: `/project_activities/complete/permits`,
    params,
  });
};

export const getAllActivities = params => {
  return api.request({
    method: 'GET',
    url: '/project_activities/all/',
    params,
  });

}


export const getPermitActivityComments = (
  params,
  activityId,
) =>
  api.request({
    method: 'GET',
    url: `/project_activities/${activityId}/comments/`,
    params,
  });

export const getActivitiesList = (
  params: PaginatedRequest,
) =>
  api.request<ActivitiesTypeList>({
    method: 'GET',
    url: '/activities/',
    params,
  });

export const createPermitActivity = data => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData(data);

  if (data?.documentFile?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.documentFile?.length; i++) {
      body.append(
        'attachments_files',
        data?.documentFile[i],
      );
    }
  }

  return axios.request<DocumentType>({
    method: 'POST',
    url: '/api/v1/project_activities/',
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const createPermitActivityComment = (data: any) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const { activityId, id } = data;
  const body = formatToFormData({
    text: data.text,
    file: data.file,
    visibleFilename: data.file?.name,
  });
  return axios.request<DocumentType>({
    method: 'POST',
    url: `/api/v1/project_activities/${activityId || id
      }/comments/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

// Add reviewer comment
export const createReviewerComment = (data) => {
  const { reviewId } = data
  const body = {
    reviewerCommentForm: data?.reviewerCommentForm,
    // reviewerComment: data?.reviewerComment,
    // reference: data?.reference,
    // status: data?.status
  }
  return api.request<ActivitiesType>({
    method: 'POST',
    url: `/project_activities/review_comments/${reviewId}/add_review_comment/`,
    data: body,
  })
}

// Update reviewer comment
export const updateReviewerComment = (data) => {
  const { id } = data
  const body = {
    reviewerComment: data?.reviewerComment,
    reference: data?.reference,
    status: data?.status
  }
  return api.request<ActivitiesType>({
    method: 'PATCH',
    url: `/project_activities/review_comments/${id}/`,
    data: body,
  })
}


export const updatePermitActivity = data => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );

  const body = formatToFormData(data);

  return axios.request<DocumentType>({
    method: 'PATCH',
    url: `/api/v1/project_activities/${data.id}/`,
    data: body,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

export const createAttachmentCondition = (data: any) => {
  const formData = new FormData();

  formData.append('file', data.documentFile[0] || data.documentFile);

  return api.post<ActivitiesType>(
    `/project_activities/${data.id}/attachments/`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const createActivities = (data: ActivitiesType) =>
  api.request<ActivitiesType>({
    method: 'POST',
    url: '/activities/',
    data,
  });

export const deleteActivities = (id: number) =>
  api.request<ActivitiesType>({
    method: 'DELETE',
    url: `/activities/${id}/`,
  });

export const updateActivities = (data: ActivitiesType) =>
  api.request<ActivitiesType>({
    method: 'PATCH',
    url: `/activities/${data.id}/`,
    data,
  });

export const getActivitiesStatusList = (
  params: PaginatedRequest,
) =>
  api.request<ActivitiesStatusTypeList>({
    method: 'GET',
    url: '/activities/activity-statuses/',
    params,
  });

export const createActivitiesStatus = (
  data: ActivitiesStatusType,
) =>
  api.request<ActivitiesStatusType>({
    method: 'POST',
    url: '/activities/activity-statuses/',
    data,
  });

export const deleteActivitiesStatus = (id: number) =>
  api.request<ActivitiesStatusType>({
    method: 'DELETE',
    url: `/activities/activity-statuses/${id}/`,
  });

export const updateActivitiesStatus = (
  data: ActivitiesStatusType,
) =>
  api.request<ActivitiesStatusType>({
    method: 'PATCH',
    url: `/activities/activity-statuses/${data.id}/`,
    data,
  });

type ActivitiesTypeList = PaginatedList<ActivitiesType>;

type ActivitiesStatusTypeList = PaginatedList<
  ActivitiesStatusType
>;

export interface ActivitiesType {
  id?: number;
  name: string;
  isActive: boolean;
  isDefault?: boolean;
  isActiveStatus: string;
  isDeleted?: boolean;
  isFranchiseAll?: boolean;
  franchiseNames: string;
  franchise: number[] | FranchiseType[];
  activityType?: number | string;
}

export type ActivitiesTypeLink = ActivitiesType & {
  linkTo: string;
};

export interface ActivitiesStatusType {
  id: number;
  name: string;
  activity: string | number;
  isActive: boolean;
  isDeleted?: boolean;
  isActiveStatus: string;
}
