import * as React from 'react';
import styled from 'styled-components';

const InputBaseContainer = ({
  hidden,
  children,
}: InputBaseContainerTypes) => {
  if (Array.isArray(children)) {
    return (
      <InputContainer hidden={hidden}>
        {children.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MarginedInputContainer key={index}>
            {element}
          </MarginedInputContainer>
        ))}
      </InputContainer>
    );
  }

  return (
    <InputContainer hidden={hidden}>
      <MarginedInputContainer>
        {children}
      </MarginedInputContainer>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  width: calc(100% + 16px);
  padding: 8px 0;
  margin: 0 -8px;
  text-align: left;
`;

const MarginedInputContainer = styled.div`
  margin: 0 8px;
  width: 100%;
`;

interface InputBaseContainerTypes {
  hidden?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export default InputBaseContainer;
