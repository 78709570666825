import * as React from 'react';
import styled from 'styled-components';

import {
  ActionPanelMore,
  BreadCrumbs,
  InputSelect,
  SearchBar,
} from '../molecules';
import { ButtonMain } from '../atoms';
import { SelectOption } from '../types';
import {
  USER_TYPES,
  USER_TYPES_VALUES,
  CLIENT_TYPES_VALUES,
  CLIENT_TYPES,
  SUPER_ADMIN_ROLE,
} from '../constants/forms';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: '240px',
    minHeight: '30px',
    height: '30px',
    maxWidth: '400px',
    borderRadius: '5px !important',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: provided => ({
    ...provided,
    height: '30px',
    padding: '0 6px 2px 6px',
  }),
  placeholder: provided => ({
    ...provided,
    top: '45%',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),
  singleValue: provided => ({
    ...provided,
    top: '45%',
  }),
};

const ActionsPanelUsers = ({
  onSearch,
  onAdd,
  onSelectedAdditionalParamsChange,
}: Props) => {
  const USER_TYPE_ALL = {
    label: 'User role: All',
    value: undefined,
  };

  const selectOptions = USER_TYPES.reduce(
    (accumulator, currentValue) => {
      switch (currentValue.value) {
        case USER_TYPES_VALUES.CLIENT:
          return accumulator.concat(
            CLIENT_TYPES.map(item => ({
              label: `User role: ${currentValue.label}: ${item.label}`,
              value: {
                userRole: currentValue.value,
                clientType: item.value,
              },
            })),
          );

        case USER_TYPES_VALUES.FRANCHISE_ADMIN:
          return accumulator.concat({
            label: `User role: ${currentValue.label}`,
            value: {
              userRole: USER_TYPES_VALUES.FRANCHISE_ADMIN,
              isEpsuperadmin: false,
            },
          });

        case SUPER_ADMIN_ROLE:
          return accumulator.concat({
            label: `User role: ${currentValue.label}`,
            value: {
              isEpsuperadmin:
                currentValue.value === SUPER_ADMIN_ROLE,
            },
          });

        default:
          return accumulator.concat({
            label: `User role: ${currentValue.label}`,
            value: { userRole: currentValue.value },
          });
      }
    },
    [USER_TYPE_ALL],
  );

  return (
    <Container>
      <Part>
        <BreadCrumbs />
      </Part>
      <Part>
        {onSelectedAdditionalParamsChange && (
          <InputSelect
            placeholder=""
            options={selectOptions}
            isSearchable={false}
            styles={selectStyles}
            defaultValue={USER_TYPE_ALL}
            onChange={(selected: SelectOption) =>
              onSelectedAdditionalParamsChange(
                selected.value,
              )
            }
          />
        )}
        {onSearch && <SearchBar onChange={onSearch} />}
        {onAdd && (
          <ButtonMain onClick={onAdd}>Add</ButtonMain>
        )}
        <ActionPanelMore />
      </Part>
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% + 48px);
  background: var(--secondary);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  padding: 0 24px;
`;

const Part = styled.div`
  display: flex;

  > * {
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  .MuiTextField-root,
  .MuiInputBase-root {
    border-radius: 5px;
  }

  .MuiButton-root,
  .MuiInputBase-root {
    height: 30px;
  }
`;

export interface SelectedAdditionalParams {
  userRole?: USER_TYPES_VALUES;
  clientType?: CLIENT_TYPES_VALUES;
}

interface Props {
  onAdd?: () => void;
  onSearch?: (value: string) => void;
  onSelectedAdditionalParamsChange?: (
    data: SelectedAdditionalParams,
  ) => void;
}

export default ActionsPanelUsers;
