import { PaginatedList, PaginatedRequest } from '../types';
import { api } from './api';

export const getVocabulariesList = () =>
  api.request<VocabulariesTypeList>({
    method: 'GET',
    url: '/vocabularies/',
  });

export const getVocabulariesDataList = (
  params: PaginatedRequest,
) =>
  api.request<VocabulariesDataTypeList>({
    method: 'GET',
    url: '/vocabularies/vocabulary-data/',
    params,
  });

export const createVocabularyData = (
  data: VocabularyDataType,
) =>
  api.request<VocabularyDataType>({
    method: 'POST',
    url: '/vocabularies/vocabulary-data/',
    data,
  });

export const deleteVocabularyData = (id: number) =>
  api.request<VocabularyDataType>({
    method: 'DELETE',
    url: `/vocabularies/vocabulary-data/${id}/`,
  });

export const updateVocabularyData = (
  data: VocabularyDataType,
) =>
  api.request<VocabularyDataType>({
    method: 'PATCH',
    url: `/vocabularies/vocabulary-data/${data.id}/`,
    data,
  });

export interface VocabulariesType {
  id: number;
  name: string;
}

type VocabulariesTypeList = PaginatedList<VocabulariesType>;

type VocabulariesDataTypeList = PaginatedList<
  VocabularyDataType
>;

export interface VocabularyDataType {
  id?: number;
  title: string;
  isActive: boolean;
  isDeleted?: boolean;
  vocabularyEntity: number;
}
