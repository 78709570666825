import React, { FC } from 'react';
import { TicketItemType } from '../../../types';
import styled from 'styled-components';
import ReplyIcon from '@material-ui/icons/Reply';
import { IconWrapper } from './TicketsItems';

type Props = {
  ticket: TicketItemType;
  isOdd: boolean;
  key: number;
  onTicketClick: Function;
};

const TicketMobileRow: FC<Props> = ({ ticket, isOdd, key, onTicketClick }) => {
  return (
    <RowWrapper isOdd={isOdd} key={key}>
      <Cell>
        <Label>Ticket Name</Label>
        <Values>{ticket.subject}</Values>
      </Cell>
      <Cell>
        <Label>Details</Label>
        <Values>{ticket.description}</Values>
      </Cell>
      <Cell>
        <Label>Status</Label>
        <Values>{ticket.status}</Values>
      </Cell>
      <IconCell>
        <IconWrapper onClick={() => onTicketClick(ticket)}>
          <ReplyIcon />
        </IconWrapper>
      </IconCell>
    </RowWrapper>
  );
};

export default TicketMobileRow;

const RowWrapper = styled.div<{ isOdd: boolean }>`
  display: none;
  @media only screen and (max-width: 840px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid grey;
    background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  padding-left: 10px;
`;

const Values = styled.div`
  font-size: 14px;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 11px;
`;

const IconCell = styled.div`
 grid-column: 2 / 3;
`;
