import * as React from 'react';
import {
  IconButton as IconButtonMUI,
  IconButtonProps,
  withStyles,
} from '@material-ui/core';
import theme from '../theme';

const StyledIconButton = withStyles({
  root: {
    // color: theme.mainText,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.primary,
      backgroundColor: 'transparent',
    },
  },
})(IconButtonMUI);

const IconButton = ({
  size = 'small',
  ...props
}: IconButtonProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledIconButton size={size} {...props} />
);

export default IconButton;
