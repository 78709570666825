import { api } from './api';

import {
  PaginatedList,
  PaginatedRequest,
  SelectOption,
} from '../types';
import { TradeType } from './trades';
import { FranchiseType } from './franchise';

export const getUsers = (params: PaginatedRequest) =>
  api.request<UsersList>({
    method: 'GET',
    url: '/account/users/',
    params,
  });
// for getting all user with more faster way
export const getUsersCustom = (params: PaginatedRequest) =>
  api.request<UsersList>({
    method: 'GET',
    url: '/account/get-all-users/',
    params,
  });
// for getting the permit users
export const getPermitUsers = (params) =>
  api.request({
    method: 'GET',
    url: '/projects/project/permit/contacts/',
    params,
  });

  export const updateUsersAccountingFlag = (params) =>
    api.request({
      method: 'POST',
      url: '/projects/project/permit/contacts/',
      params,
    });

export const getEmployeesUsers = (params: PaginatedRequest) =>
  api.request<UsersList>({
    method: 'GET',
    url: 'projects/project/permit/contacts/',
    params,
  });

export const getUsersByProject = (projectId) =>
  api.request<UsersList>({
    method: 'GET',
    url: `projects/project/contacts/?project_id=${projectId}`
  });

export const getUserByID = (id: number) =>
  api.request<UserType>({
    method: 'GET',
    url: `/account/users/${id}/`,
  });

export const resendInvitation = (id: number) =>
  api.request<UserType>({
    method: 'POST',
    url: `/account/users/${id}/resend_invite/`,
  });

export const createUser = (data: UserType) =>
  api.request<UserType>({
    method: 'POST',
    url: `/account/users/?franchise=${data?.franchise}`,
    data,
  });

export const updateUser = (data: UserType) =>
  api.request<UserType>({
    method: 'PATCH',
    url: `/account/users/${data.id}/`,
    data,
  });

export const updatePermitUser = (data) => {
  const { id, newContactType, permitId } = data;
  return api.request<UserType>({
    method: 'PATCH',
    url: `/projects/project/permit/contacts/${id}/`,
    data: { newContactType, permitId },
  });
}

export const deleteUser = (id: number) =>
  api.request<UserType>({
    method: 'DELETE',
    url: `/account/users/${id}/`,
  });

type UsersList = PaginatedList<UserTableType>;

export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  isEpsuperadmin: boolean;
  isActive: boolean;
  isDeleted: boolean;
  userRole: number | SelectOption;
  prNumber: string;
  clientType: number | SelectOption;
  licenses: string;
  mobilePhoneNumber: string;
  parentCompany: number | UserType;
  companyPhone: string;
  trade: number[] | TradeType[];
  franchise: number[] | FranchiseType[];
  avatar: string;
  createdAt: string;
  companyName: string;
  contactType?: number;
  contactPerson?: string;
  defaultFranchise: any;
  defaultFranchiseId?: number;
  selectedFranchise: number;
  isAccounting: boolean;
}

export interface UserTableType extends UserType {
  tradesNames: string;
  franchiseNames: string;
}
