import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCustomActivities, updatePermitActivity } from '../../api/activities';
import { useDispatch, useSelector } from 'react-redux';
import { LibraryBooks } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { RootState } from '../../redux/root';
import MobileRecord from './MobileRecord';
import PaginationMobile from './PaginationMobile';
import { setRowsCount } from '../../redux/slices/paginationSlice';
import useTablePage from '../../hooks/useTablePage';
import { LoaderWithContainer, ModalDefaultDraggable } from '../../molecules';
import PermitActivitiesForm from '../../forms/PermitActivitiesForm';
import useManuallyUpdate from '../../hooks/useManuallyUpdate';
import DocumentsPage from '../Permits/DocumentsPage';
import MobileFilter from './MobileFilter';
import useFilterActions from './useFilterActions';
import ModalInfo from '../../molecules/ModalInfo';
import MobileDocumentsDetails from './Documents/MobileDocumentsDetails/MobileDocumentsDetails';

const PendingReviewsMobile: FC = () => {
  const { pageSize, pageIndex } = useSelector((state: RootState) => state.pagination);
  const currentUserId = useSelector((state: RootState) => state.me.currentUserId);
  const [records, setRecords] = useState([]);
  const filteredRecords = useFilterActions(records);
  const [initialValues, setInitialValues] = useState([]);
  const [permit, setPermit] = useState<number>(-1);
  const [errors] = useState<any>(null);
  const [documents, showDocuments] = React.useState(null);
  // const [closeDocumentDetails, setCloseDocumentDetails]
  const [tableManuallyUpdate, updateTable] = useManuallyUpdate();
  const dispatch = useDispatch();
  const {
    isModalOpen,
    modalSuccess,
    modalError,
    modalTitle,
    modalErrorTitle,
    createOrUpdate,
    handleEdit,
    handleCloseModalInfo,
  } = useTablePage({
    updateEndpoint: updatePermitActivity,
    modalUpdateTitle: 'Update Review',
  });

  useEffect(() => {
    if (currentUserId > 0) {
      getCustomActivities({
        is_deleted: false,
        limit: pageSize,
        offset: pageIndex,
        inspector_id: currentUserId,
        event_type: 'Activity',
        activity_type_name: 'Reviews',
      }).then(result => {
        setRecords(result.data.results);
        dispatch(setRowsCount(result.data.results.length));
      });
    }
  }, [currentUserId, pageSize, pageIndex, modalSuccess, modalError]);

  const handleEditModal = data => {
    if (data) {
      setPermit(data?.permitId);
      setInitialValues(data);
      handleEdit(data);
    }
  };

  const closeInfoModal = () => {
    setInitialValues(null);
    showDocuments(false);
    updateTable();
    handleCloseModalInfo();
  };
  const data = [
    { label: 'Project Name', key1: 'projectName' },
    { label: 'Jurisdiction', key1: 'projectJurisdiction' },
    { label: 'Review Type', key1: 'reviewType' },
    { label: 'Permit Name', key1: 'permitName' },
    { label: 'Follow Up', key1: 'followUp', verifyDate: 'followUp' },
    { label: 'Address', key1: 'address' },
    { label: 'Activity Title', key1: 'activityTitleName' },
  ];
  const filterMeta = [
    { label: 'Project Name', name: 'projectName' },
    { label: 'Juristriction', name: 'projectJurisdiction' },
    { label: 'Permit Name', name: 'permitName' },
    { label: 'Review Type', name: 'reviewType' },
  ];
  const viewComments = (record: any): void => {
    if (!record.reviewTypeComment) {
      return;
    }
    showDocuments(record);
  };

  return (
    <>
      <ReviewPageContainer>
        <MobileFilter filterMeta={filterMeta} />
        {filteredRecords.map((record, index) => {
          return (
            <RecordWithActions>
              <MobileRecord item={record} fields={data} shaded={index % 2 === 0} />
              <Actions shaded={index % 2 === 0} isEmergency={record.isEmergency}>
                <EditIcon onClick={() => handleEditModal(record)} />
                <ViewCommentWrapper disabled={record.reviewTypeComment}>
                  <LibraryBooks onClick={e => viewComments(record)} />
                </ViewCommentWrapper>
                
              </Actions>
            </RecordWithActions>
          );
        })}
      </ReviewPageContainer>
      <PaginationMobile />
      {isModalOpen && initialValues && (
        <ModalDefaultDraggable title={modalTitle} onClose={closeInfoModal}>
          <PermitActivitiesForm
            onSubmit={createOrUpdate}
            initialValues={initialValues}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            // isReadOnly={true}
          />
        </ModalDefaultDraggable>
      )}

      {documents && (
        <ModalDefaultDraggable title={'Permit Documents'} onClose={closeInfoModal} extraWide>
          <MobileDocumentsDetails documentId={documents.id} permitId={documents.permit} />
        </ModalDefaultDraggable>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo timeToClose={5000} isError={modalError} onClose={closeInfoModal} errorMsg={modalErrorTitle} />
      )}
    </>
  );
};

const Actions = styled.div<{ shaded: boolean; isEmergency: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 21px;
  border-bottom: 1px solid gray;
  background-color: ${props => {
    if (props.isEmergency) {
      return 'rgb(250,181,168)';
    }

    return props.shaded ? 'white' : 'rgb(252,249,252)';
  }};
`;

const RecordWithActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
`;

export const ReviewPageContainer = styled.div`
  @media only screen and (max-width: 840px) {
    height: 82vh;
  }

  @media only screen and (max-width: 390px) {
    height: 80vh;
  }
  overflow-y: auto;
  @media only screen and (min-width: 840px) {
    display: none;
  }
`;

const ViewCommentWrapper = styled.div<{disabled:boolean}>`
   color: ${({disabled}) => !disabled ? 'rgb(220,220,220)':'inherit'}
`;

export default PendingReviewsMobile;
