import { api } from './api';
import { FranchiseType } from './franchise';
import { PaginatedList, PaginatedRequest } from '../types';

export const getTrades = (params: PaginatedRequest) =>
  api.request<TradesList>({
    method: 'GET',
    url: '/trades/',
    params,
  });

export const getTradeByID = (id: number) =>
  api.request<TradeType>({
    method: 'GET',
    url: `/trades/${id}/`,
  });

export const createTrade = (data: TradeType) =>
  api.request<TradeType>({
    method: 'POST',
    url: '/trades/',
    data,
  });

export const updateTrade = (data: TradeType) =>
  api.request<TradeType>({
    method: 'PATCH',
    url: `/trades/${data.id}/`,
    data,
  });

export const deleteTrade = (id: number) =>
  api.request<TradeType>({
    method: 'DELETE',
    url: `/trades/${id}/`,
  });

type TradesList = PaginatedList<TradeType>;

export interface TradeType {
  id: number;
  name: string;
  isActive: boolean;
  isActiveStatus: string;
  isDeleted: boolean;
  franchise: number[] | string | FranchiseType[];
  isDefault?: boolean;
}
