import { useSelector } from 'react-redux';

import { USER_TYPES_VALUES } from '../constants/forms';
import { userInfoSelector } from '../redux/ducks/userAuth';

const useUserRole: useUserRoleType = () => {
  const user = useSelector(userInfoSelector);

  if (!user) {
    return {};
  }

  return {
    isSuperAdmin: user.isEpsuperadmin,
    isFranchiseAdmin:
      user.userRole === USER_TYPES_VALUES.FRANCHISE_ADMIN &&
      !user.isEpsuperadmin,
    isEmployee:
      user.userRole === USER_TYPES_VALUES.EMPLOYEE &&
      !user.isEpsuperadmin,
    isInspector:
      user.userRole === USER_TYPES_VALUES.INSPECTOR &&
      !user.isEpsuperadmin,
    isClient:
      user.userRole === USER_TYPES_VALUES.CLIENT &&
      !user.isEpsuperadmin,
  };
};

type useUserRoleType = () => {
  isSuperAdmin?: boolean;
  isFranchiseAdmin?: boolean;
  isEmployee?: boolean;
  isInspector?: boolean;
  isClient?: boolean;
};

export default useUserRole;
