import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;

  @page {
    size: A4;
    margin-top: 50px;
    margin-bottom: 50px;
}
  @media print {
    margin: 50px;
    padding: 10px;
    display: block;
    width: 88%;
    height: auto;

    & h1 {
      font-size: 18px;
    }
    & ul li {
      font-size: 14px;
    }

    & table {
      font-size: 11px;
    }
  }
`;

export default Wrapper;