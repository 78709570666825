import * as React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { getPermitUsers } from '../api/users';
import {
  ButtonWithLoader,
  InputBaseContainer,
  InputSelectWithLabel,
} from '../molecules';
import isYupFieldRequired from '../utils/isYupFieldRequired';

const labels = {
  contacts: 'Permit Contacts',
};

const schema = yup.object().shape({
  contacts: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a Contact Names from the list')
    .nullable()
    .label(labels.contacts),
});

const PermitDocsSendForm = ({
  permit,
  documents,
  onSubmit,
  statuses,
  modalError,
}: Props) => {
  const {
    handleSubmit,
    formState,
    errors,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(
    false,
  );
  const [
    permitContacts,
    setPermitContacts,
  ] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      getPermitUsers({user_role: 3,
        permit_id: permit})
    ])
      .then(([usersResponse]) => {
        
        setPermitContacts(usersResponse?.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors]);

  const middleware = (submitFn: (data: any) => void) => (
    data: any,
  ) => {
    const waitingOnClientStatus = statuses.find(
      el => el?.title === 'Waiting on client',
    );
    submitFn({
      documents: documents
        ?.filter(
          el => el?.isClientAvailable,
        )
        .map(doc => doc?.pk),
      contacts: data.contacts
        ?.map(el => el?.id)
        .filter(id => id),
    });
  };

  return (
    <Container>
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <Controller
            name="contacts"
            control={control}
            defaultValue=""
            render={props => (
              <InputSelectWithLabel
                isMulti
                selectAllActive
                showCompany
                isLoading={isLoading}
                label={labels.contacts}
                error={errors.contacts}
                getOptionValue={(item: any) =>
                  item?.id || item?.value
                }
                getOptionLabel={(item: any) =>
                  item?.name ||
                  `${item?.firstName} ${item?.lastName}${
                    item?.companyName || item?.parentCompany
                      ? ` - ${
                          item?.companyName ||
                          item?.parentCompany
                        }`
                      : ''
                  }`
                }
                isRequired={isYupFieldRequired(
                  'contacts',
                  schema,
                )}
                options={permitContacts}
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <ButtonWithLoader
          disabled={isSubmitting && !modalError}
          loading={isSubmitting && !modalError}
          type="submit"
        >
          Send
        </ButtonWithLoader>
      </FormContainer>
    </Container>
  );
};

interface Props {
  permit: number;
  documents: Array<any>;
  statuses: Array<any>;
  onSubmit: (data) => void;
  modalError: boolean;
}

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

export default PermitDocsSendForm;
