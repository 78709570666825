import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface FilterEntry {
    filterName: string;
    filterValue: string;
}
export interface FilterState {
    filters: FilterEntry[],
    searchToken: string;
    searchButton: string;
    globalSearch?: string;
  }

  const initialState: FilterState = {
    filters: [],
    searchToken: '',
    searchButton: '',
    globalSearch: undefined,
  };

  export const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilters: (state, action:PayloadAction<FilterEntry[]>) => {
            state.filters = action.payload;
        },
        clearFilter: (state, action:PayloadAction<string>) => {
          const newFilters = state.filters.filter(f => f.filterName !== action.payload);
          state.filters = newFilters;
        },
        removeFilters: (state, action:PayloadAction<any>) => {
          const emptyFilters = [];
          state.filters = emptyFilters;
        },
        setSearchToken: (state, action:PayloadAction<string>) => {          
          state.searchToken = action.payload;
        },
        setSearchButton: (state, action:PayloadAction<string>) => {          
          state.searchButton = action.payload;
        },
        setGlobalSearch: (state, action:PayloadAction<string | undefined>) => {          
          state.globalSearch = action.payload;
        },
        resetFilters: (state) => {          
          state.filters = [];
          state.searchButton = '';
          state.searchToken = '';
          state.globalSearch = undefined;
        },
    },
  });

  // Action creators are generated for each case reducer function
export const {
  setFilters,
  clearFilter,
  removeFilters,
  setSearchToken,
  setSearchButton,
  setGlobalSearch,
  resetFilters
} = filterSlice.actions;

export default filterSlice.reducer;
