import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const SuccessCheckmark = styled(CheckCircleIcon)`
  color: var(--primary);
  width: 128px;
  height: 128px;

  &.MuiSvgIcon-root {
    fill: var(--primary);
  }
`;

export default SuccessCheckmark;
