import * as React from 'react';
import styled from 'styled-components';

const PageNotFound = ({ children }: PageNotFoundProps) => {
  return (
    <Root>
      <WrapperPosition>{children}</WrapperPosition>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const WrapperPosition = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

interface PageNotFoundProps {
  children: React.ReactNode | React.ReactNodeArray;
}

export default PageNotFound;
