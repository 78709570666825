import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
    index: number;
    gridTemplateCols: string;
    status: string;
}
const TableRow:FC<Props> = ({index,gridTemplateCols,status,children}) => {
    return (
        <SingleTableRow
            isOdd={index % 2 !== 0}
            gridTemplateCols={gridTemplateCols}
            key={index}
            status={status}
          >
            {children}
            </SingleTableRow>
    );
};

export default TableRow;

const SingleTableRow = styled.div<{
    isOdd: boolean;
    status: string;
    gridTemplateCols: string;
  }>`
    display: grid;
    position: relative;
    grid-template-columns: ${({ gridTemplateCols }) =>
      gridTemplateCols};
    border-bottom: 1px solid grey;
    background-color: ${({ isOdd, status }) =>
      isOdd ? '#FFFFF' : '#FCF9FC'};
  
    @media only screen and (max-width: 840px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 400px) {
      grid-template-columns: repeat(2, 1fr);
    }
    :hover {
      background-color: #efefef;
    }
  `;