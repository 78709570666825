import React, { FC } from 'react';
import { updatePermitInspection } from '../../../api/inspections';
import PermitInspectionsForm from '../../../forms/permitInspectionForm/PermitInspectionsForm';
import useTablePage from '../../../hooks/useTablePage';
import { ModalDefaultDraggable } from '../../../molecules';

type MyProps = {
    reportOpened: boolean,
    setReportOpened: Function,
    item: any
}
const DailyInspectionReportPopup:FC<MyProps> = ({reportOpened, setReportOpened, item}) => {
  const {
    isModalOpen,
    modalError,
    modalSuccess,
    modalTitle,
    modalErrorTitle,
    values,
    createOrUpdate,
    handleEdit,
    handleCloseModalInfo,
    handleCloseModal,
    setValues,
    updateTable,
    setModalTitle,
    openModal,
  } = useTablePage({
    updateEndpoint: updatePermitInspection,
    modalUpdateTitle: 'Update Inspection',
  });
  return (
    <>
      {reportOpened && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={()=>setReportOpened(false)}
        >
          <PermitInspectionsForm
            // <InspectorPermitInspectionsForm
            onSubmit={createOrUpdate}
            initialValues={item}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={null}
            permit={item?.permit}
            // isReadOnly={true}
            setInitialValues={setValues}
            updateTable={updateTable}
          />
        </ModalDefaultDraggable>
      )}
    </>
  );
};

export default DailyInspectionReportPopup;
