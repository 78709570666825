import { FieldError } from 'react-hook-form';
import { FormServerError } from '../types';

const transformServerErrorsToForm = <T extends object>(
  errors: FormServerError<T>,
): TransformedServerError<T>[] => {
  if (typeof errors === 'object') {
    return errors
      ? Object.entries(errors).reduce(
          (acc, current) => [
            ...acc,
            {
              name: current[0],
              types:
                current[1] && Array.isArray(current[1])
                  ? (current[1] as string[]).reduce(
                      (a, c, i) => ({
                        ...a,
                        [`server${i}`]: c,
                      }),
                      {},
                    )
                  : { server: current[1] },
            },
          ],
          [],
        )
      : [];
  }

  return [];
};

interface TransformedServerError<T> extends FieldError {
  name: keyof T;
}

export default transformServerErrorsToForm;
