import * as React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import UserMenu from './UserMenu';
import { ROUTES } from '../constants/routes';
import SubMenuHeadLine from './SubMenuHeadLine';
import getModuleName from '../utils/getModuleName';
import isRoutePathExact from '../utils/isRoutePathExact';
import {
  reportsSubMenuLinks,
  settingsSubMenuLinks,
  franchiseSubMenuLinks,
} from '../constants/menu';
import useUserRole from '../hooks/useUserRole';

const HeadLine:React.FC<{NoSubMenu?:boolean}> = ({NoSubMenu=false}) => {
  const { pathname } = useLocation();

  const {
    isFranchiseAdmin,
    isSuperAdmin,
    isEmployee,
  } = useUserRole();

  const subMenu = React.useMemo(() => {
    switch (true) {
      case isRoutePathExact(pathname, ROUTES.SETTINGS):
        return (
          <SubMenuHeadLine
            basePath={ROUTES.SETTINGS}
            isSuperAdmin={isSuperAdmin}
            menuLinks={
              !isFranchiseAdmin
                ? settingsSubMenuLinks.filter(
                    link => link.name !== 'Messages',
                  )
                : settingsSubMenuLinks.filter(
                    link =>
                      link.name !== 'Report Setup' &&
                      link.name !== 'Vocabulary',
                  )
            }
          />
        );

      case isRoutePathExact(pathname, ROUTES.REPORTS):
        if (isEmployee || NoSubMenu) return null;

        return (
          <SubMenuHeadLine
            menuLinks={reportsSubMenuLinks}
          />
        );

      case isRoutePathExact(
        pathname,
        ROUTES.FRANCHISE_CONFIGURATION,
      ):
        return (
          <SubMenuHeadLine
            menuLinks={franchiseSubMenuLinks}
          />
        );

      // case isRoutePathExact(
      //   pathname,
      //   ROUTES.ALL_ACTIVITIES,
      // ):
      //   return <strong>ALL ACTIVITIES</strong>;

      case isRoutePathExact(pathname, ROUTES.INSPECTIONS):
        if (pathname.indexOf('client') > -1) {
          return <HeaderMobile></HeaderMobile>; 
        }
        return (
          <HeaderMobile>
            PENDING INSPECTIONS
          </HeaderMobile>
        );

      case isRoutePathExact(pathname, ROUTES.REVIEWS):
        return (
          <HeaderMobile>
            PENDING REVIEWS
          </HeaderMobile>
        );

      default:
        return null;
    }
  }, []);

  const moduleName = React.useMemo(
    () =>
      (
        <HeaderMobile>
          {getModuleName()}
        </HeaderMobile>
      ),
    [],
  );

  return (
    <Wrapper>
      <SubMenuContainer>
        {subMenu || moduleName}
      </SubMenuContainer>
      <RightSide>
        <UserMenu />
      </RightSide>
    </Wrapper>
  );
};

const HeaderMobile = styled.div`
    @media only screen and (max-width: 840px) {
      margin-left:60px;
      font-weight: bold;
    }
    @media only screen and (max-width: 390px) {
      font-size: 14px;
    }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 25px;
  align-items: center;
  background-color: var(--defaultBackground);
`;

const RightSide = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const SubMenuContainer = styled.div`
  // width: 100%;
  // position: relative;
  // margin-right: 50px;
`;

export default HeadLine;
