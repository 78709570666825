import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserType } from '../../api/users';

export interface InspectionsState {
  users: UserType[];
}

const initialState: InspectionsState = {
  users: [],
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserType[]>) => {
      state.users = action.payload;
    },
    resetClient: state => {
      state.users = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUsers, resetClient } = clientSlice.actions;

export default clientSlice.reducer;
