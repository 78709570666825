import * as locals from 'date-fns/locale';
import { format, parseJSON } from 'date-fns';

import { DEFAULT_DATE_FORMAT } from '../constants';

export const formatDateTime = (
  date: Date | string | null = new Date(),
  dateFormat: string = DEFAULT_DATE_FORMAT,
): string | null => {
  const timeZone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.toString();

  const dateOptions = {
    timeZone,
    locale: locals.enUS,
  } as any;

  if (!date) {
    return null;
  }

  const parsedDate = parseJSON(
    typeof date === 'string'
      ? new Date(date)
      : date.toISOString(),
  );

  return parsedDate
    ? format(parsedDate, dateFormat, dateOptions)
    : null;
};

const formatDate = (
  date: Date | string | null = new Date(),
  dateFormat: string = DEFAULT_DATE_FORMAT,
): string | null => {
  const timeZone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.toString();

  const dateOptions = {
    timeZone,
    locale: locals.enUS,
  } as any;

  if (!date) {
    return null;
  }

  const parsedDate = parseJSON(
    typeof date === 'string' ? date : date.toISOString(),
  );

  return parsedDate
    ? format(parsedDate, dateFormat, dateOptions)
    : null;
};

export default formatDate;
