import * as React from 'react';
import styled from 'styled-components';
import useTablePage from '../hooks/useTablePage';
import {
  updatePermitActivity,
  getPermitActivities,
  getCustomActivities,
  createPermitActivityComment,
  getActivityFees,
} from '../api/activities';
import tableColumnsFormatter from '../utils/tableColumnsFormatter';
import Table from './Table';
import { convertTableBoolean } from '../utils/convertTableValues';
import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import PermitActivitiesForm from '../forms/PermitActivitiesForm';
import ModalInfo from '../molecules/ModalInfo';
import {
  DATETIME_FORMAT, DEFAULT_DATE_FORMAT,
} from '../constants';
import { formatDateTime } from '../utils/formatDate';
import openInNewTab from '../utils/openInNewTab';
import {LibraryBooks, MonetizationOnOutlined} from '@material-ui/icons';
import { getVocabulariesDataList } from '../api/vocabulary';
import ModalFullScreen from '../molecules/ModalFullScreen';
import DocumentsDetailedPage from '../pages/Permits/DocumentsDetailedPage';
import { getReviewerComments } from '../api/projectDocuments';
import ReviewCommentLetter from '../pages/Reports/ReviewCommentLetter';
import { getContactString } from '../utils/setInspectionData';


const PermitActionsTable = ({
  projectId,
  permit,
  activityType,
  activityCreated,
  setActivityCreated,
  cameFromSearch,
  setDisableReviewCommentBtn,
  showReviewCommentLtr,
  setShowReviewCommentLtr,
}: Props) => {
  
  const {
    tableManuallyUpdate,
    updateTable,
    search,
    handleEdit,
    isModalOpen,
    createOrUpdate,
    modalSuccess,
    modalError,
    modalTitle,
    modalErrorTitle,
    errors,
    values,
    handleCloseModalInfo,
    handleCloseModal,
  } = useTablePage({
    updateEndpoint: updatePermitActivity,
    createEndpoint: createPermitActivityComment,
    modalCreateTitle: 'Add Comment',
  });

  // EA-182
  // mritunjoy 
  const [currentActivity, setCurrentActivity] = React.useState({})
  const [isViewFeesModalOpen, setIsViewFeesModalOpen] = React.useState(false)
  const [isEmptyFees, setIsEmptyFees] = React.useState(false)
  React.useEffect(() => {
    updateTable();
  }, [
    activityType,
    modalSuccess,
    modalError,
    activityCreated,
  ]);

  const [documents, showDocuments] = React.useState(null);
  const closeInfoModal = () => {
    showDocuments(false);
    updateTable();
    handleCloseModalInfo();
  };
const [activityTypes, setActivityTypes] = React.useState([])
const [reviews, setReviews] = React.useState(null)
const [customReviewsData, setCustomReviewsData] = React.useState(null)
// project_activities/custom/?is_deleted=false&event_type=Activity&activity_type_name=Reviews&permit_id=13700
React.useEffect(() => {
  Promise.all([
    getCustomActivities({
      is_deleted: false,
      permit_id:permit,
      event_type: "Activity",
      activity_type_name: "Reviews"
    }),
     getVocabulariesDataList({
        // ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 3,
        ordering: 'title',
      }),
  ]).then(([reviewsResponse, activityTypesResponse]) => {
    setReviews(reviewsResponse?.data?.results)
    const customReviews = reviewsResponse?.data?.results?.filter(el => !el?.reviewTypeComment?.length).map(item => item?.id)
    setCustomReviewsData(customReviews)
    setActivityTypes(activityTypesResponse?.data?.results)
  })
}, [])

  const modifiedGetPermitActivities = params => {
    const activityTypeTitle = activityTypes?.find(type => type?.id === activityType)?.title
   return Promise.all([
      // getVocabulariesDataList({
      //   ...GET_ALL_QUERY_PARAMS,
      //   vocabularyEntity: 3,
      //   ordering: 'title',
      // }),
      // getPermitActivities({
      //   ...params,
      //   permit,
      //   activityType,
      // }),
      getCustomActivities({
        ...params,
        permit_id:permit,
        // activityType,
        sort_by: params?.ordering,
        ordering:null,
        event_type: "Activity",
        activity_type_name: activityTypeTitle === "Fee" ? "Fee_Activity" : activityTypeTitle
      }),
      // getActivitiesList({ ...params, limit: 1000000 }),
      // getActivitiesStatusList({
      //   ...params,
      //   limit: 1000000,
      // }),
      // getUsersCustom({}),
    ])
      .then(
        ([
          // activityTypesResponse,
          activitiesResponse,
          // activityTitlesResponse,
          // activityStatusesResponse,
          // usersResponse,
        ]) => {       
          const reviewActivities = activitiesResponse?.data?.results?.filter(el => el?.activityTypeName === "Reviews")                       
          setReviews(reviewActivities)
          if(reviewActivities?.length) {
            const reviewCommentPromises = []
            for(let i = 0; i < reviewActivities.length; i++) {
              reviewCommentPromises.push(getReviewerComments(reviewActivities[i]?.id))
            }
            Promise.all(reviewCommentPromises).then(responseList => {
              const arr = responseList?.map(el => el?.data)?.filter(el => el?.length > 0)
              if(arr.length) {
                setDisableReviewCommentBtn(false)
              } else {
                setDisableReviewCommentBtn(true)
              }
            }
            )
          } else {
            setDisableReviewCommentBtn(true)
          }
          return {
            ...activitiesResponse,
            data: {
              ...activitiesResponse.data,
              results: activitiesResponse.data.results.map(
                el => {
                  // const contactEmployee = usersResponse.data.find(
                  //   user => user?.id === el.contact,
                  // );
                  
                  // const assignedEmployee = usersResponse.data.find(
                  //   user => user?.id === el.assignedTo,
                  // );
                  // const typeName = activityTypesResponse.data.results.find(
                  //   type => type?.id === el.activityType,
                  // )?.title;
                  return {
                    ...el,
                    activityType: el?.activityTypeName,
                    title: el?.activityTitleName,
                    status: el?.statusName,
                    // contact: `${
                    //   contactEmployee?.firstName
                    // } ${contactEmployee?.lastName}${
                    //   contactEmployee?.companyName ||
                    //   contactEmployee?.parentCompany
                    //     ? ` - ${
                    //         contactEmployee?.companyName ||
                    //         contactEmployee?.parentCompany
                    //       }`
                    //     : ''
                    // }`,
                    contact: getContactString(el),
                    assignedTo: `${el?.assignedToFirstName} ${el?.assignedToLastName}`,
                    followUp: formatDateTime(
                      el.followUp,
                      DATETIME_FORMAT,
                    ),
                    attachments:
                      el.attachments &&
                      el.attachments?.map(
                        file => file.visibleFilename,
                      ),
                    documentFile:
                      el.attachments &&
                      el.attachments?.map(doc => {
                        return doc?.file;
                      }),
                      isDeleted: cameFromSearch,
                  };
                },
              ),
            },
          };
        },
      )
      .catch(err => console.log(err))
      .finally(() => {});
}

  const columns = column => {
    if(activityType === 7) {
      if (
        [
          'permit',
          'amount',
          'id',
          'inspector',
          'isEmergency',
          'isCompleted',
          'information',
          'lastComment',
          'reviewTypeName',
          'fees',
          'feeNames',
          'documentFile',
          'isDeleted',
          'permitName',
          'permitNumber',
          'permitId',
          'contactFirstName',
          'contactLastName',
          'assignedToFirstName',
          'assignedToLastName',
          'companyName',
          'activityTitleName',
          'activityTypeName',
          'projectName',
          'projectJurisdiction',
          'statusName',
          'contactId',
          'type',
          'attachments',
          'projectId',
          'createdAt',
          'createdBy',
          'completedAt',
          'completedBy',
          'selectedDocuments',
          'inspectorFirstName',
          'inspectorLastName',
          'franchisePhoneNumber',
          'inspectorEmail',
          'projectAddress',
          'franchiseName',
          'reviewTypeComment',
        ].includes(column)
      ) {
        return null;
      }
    } else {
      if (
        [
          'permit',
          'amount',
          'id',
          'inspector',
          'isEmergency',
          'isCompleted',
          'reviewType',
          'information',
          'lastComment',
          'reviewTypeName',
          'fees',
          'feeNames',
          'documentFile',
          'isDeleted',
          'permitName',
          'permitNumber',
          'permitId',
          'contactFirstName',
          'contactLastName',
          'assignedToFirstName',
          'assignedToLastName',
          'companyName',
          'activityTitleName',
          'activityTypeName',
          'projectName',
          'projectJurisdiction',
          'statusName',
          'contactId',
          'type',
          'attachments',
          'projectId',
          'createdAt',
          'createdBy',
          'completedAt',
          'completedBy',
          'selectedDocuments',
          'inspectorFirstName',
          'inspectorLastName',
          'franchisePhoneNumber',
          'inspectorEmail',
          'projectAddress',
          'franchiseName',
          'reviewTypeComment',
        ].includes(column)
      ) {
        return null;
      }
    }

    switch (column) {
      case 'activityType':
        return 'Activity Type';
      case 'title':
        return 'Activity Title';
      case 'status':
        return 'Activity Status';
      case 'contact':
        return 'Contact';
      case 'reviewType':
        return 'Trade';
      case 'assignedTo':
        return 'Assigned to';
      case 'followUp':
        return 'Follow Up';
      case 'createdAt':
        return 'Date Created';
      case 'completedAt':
        return 'Date Completed'
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'activityType',
    'title',
    'status',
    'contact',
    'followUp',
    'assignedTo',
    'createdAt',
    'createdBy',
    'completedAt',
    'completedBy'
  ];

  const handleCloseActivitiesModal = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  const handleCellClick = rowValue => {
    if (
      rowValue.documentFile &&
      Array.isArray(rowValue.documentFile)
    ) {
      const link = rowValue.documentFile[0];
      openInNewTab(link);
    } else {
      openInNewTab(rowValue.documentFile);
    }
  };

  const cellCallbackFnIsOn = ['attachments'];

  // EA-182
  // mritunjoy 
  const handleCloseViewFeesModal = () => {
    setCurrentActivity({})
    setIsViewFeesModalOpen(false)
  }
  const viewFeesActivity = (row) => {
    setIsViewFeesModalOpen(true)
      setCurrentActivity(row)
  }

  const additionalActions = (row) => {
    if((row?.activityType === "Fee" && row?.title === "Invoice") || row?.fees?.length > 0) {
      return [
        {
          title: 'View Fees',
          handler: () => viewFeesActivity(row),
          Icon: MonetizationOnOutlined,
        },
      ];
    }
    if(row?.activityType === "Reviews") {
      return [
        {
          title: 'View Documents',
          handler: () => showDocuments(row),
          Icon: LibraryBooks,
        },
      ];
    }
    return undefined;
  };

  const modifiedGetFees = params =>{    
    return Promise.all([
      getActivityFees(params, currentActivity?.id),
    ])
      .then(
        ([
          feesResponse,
        ]) => {
          if(feesResponse?.data?.length > 0){       
            setIsEmptyFees(false)
          } else {
            setIsEmptyFees(true)
          }             
          return {
            ...feesResponse,
            data: {
              results: feesResponse.data.map(el => {
                return {
                  ...el,
                  dateInvoiced: formatDateTime(el?.dateInvoiced, DEFAULT_DATE_FORMAT),
                  datePaid: formatDateTime(el?.datePaid, DEFAULT_DATE_FORMAT),
                }
              })
            },
          };
        },
      )
      .catch(err => console.log(err))
      .finally(() => {});
  }
if(currentActivity?.id) {
  modifiedGetFees(currentActivity.id)
}    

  const ViewFeesColumns = column => {
  if (
    [
      'permit',
      'creator',
      'feeSettings',
      'feeCheckbox',
      'invoicePdf',
      'lastComment',
      'actions',
      'isPaidManual',
      'isVoid',
      'completedAt',
      'completedBy',
      'invoiceId'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'id':
      return 'ID';
    case 'name':
      return 'Fee Name';
    case 'amount':
      return 'Amount';
    case 'quantity':
      return 'Quantity';
    case 'total':
      return 'Total';
    case 'status':
      return 'Status';
    case 'dateInvoiced':
      return 'Date Invoiced';
    case 'datePaid':
      return 'Date Paid';
    case 'invoiceNumber':
      return 'Invoice Number';
    default:
      return tableColumnsFormatter(column);
  }
};

const viewFeesColumnsOrder = [
  'id',
    'invoiceNumber',
    'name',
    'amount',
    'quantity',
    'total',
    'status',
    'dateInvoiced',
    'datePaid',
];

const NoFees = () => {
  return (
    <InfoText>
      No Fees found to view.
    </InfoText>
  )
}

  return (
    <>
    {reviews ? (customReviewsData ? <Table
        customSearch
        initialUseSortBy
        permitActivityTable
        searchQuery={search}
        columnsOrder={columnsOrder}
        colorSchema
        columns={columns}
        rows={modifiedGetPermitActivities}
        onEdit={handleEdit}
        onDelete={null}
        onConfigure={null}
        // initialSortBy={[{ id: 'createdAt', desc: true }]}
        additionalActions={additionalActions}
        handleCellClick={handleCellClick}
        cellCallbackFnIsOn={cellCallbackFnIsOn}
        isMultipleDocuments
        formatValue={convertTableBoolean}
        manuallyUpdate={tableManuallyUpdate}
        isPermitActivitiesPage={true}
        italic
        reviewerCommentData={customReviewsData}
      /> : null) : <Table
      customSearch
      initialUseSortBy
      permitActivityTable
      searchQuery={search}
      columnsOrder={columnsOrder}
      colorSchema
      columns={columns}
      rows={modifiedGetPermitActivities}
      onEdit={handleEdit}
      onDelete={null}
      onConfigure={null}
      // initialSortBy={[{ id: 'createdAt', desc: true }]}
      additionalActions={additionalActions}
      handleCellClick={handleCellClick}
      cellCallbackFnIsOn={cellCallbackFnIsOn}
      isMultipleDocuments
      formatValue={convertTableBoolean}
      manuallyUpdate={tableManuallyUpdate}
      isPermitActivitiesPage={true}
      italic
    />}
      
      {/* EA-182
      mritunjoy */}
      {isViewFeesModalOpen && (
        <ModalDefaultDraggable
        extraWide={true}
        title="View Fees"
        onClose={handleCloseViewFeesModal}
      >
        {!isEmptyFees ? <Table
        customSearch
        searchQuery={search}
        columnsOrder={viewFeesColumnsOrder}
        columns={ViewFeesColumns}
        rows={modifiedGetFees}
        onEdit={null}
        onDelete={null}
        onConfigure={null}
        additionalActions={null}
      /> : <NoFees />}
        
      </ModalDefaultDraggable>
      )}
      {isModalOpen && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseActivitiesModal}
        >
          <PermitActivitiesForm
            onSubmit={createOrUpdate}
            initialValues={values}
            modalError={modalError}
            addButtonText="Create"
            updateButtonText="Update"
            initialErrors={errors}
            permit={permit}
            setActivityCreated={setActivityCreated}
            cameFromSearch={cameFromSearch}
          />
        </ModalDefaultDraggable>
      )}
      {/* Reviews comment data EA-551 */}
      {documents &&(
        <ModalFullScreen
          title={'Review Comments'}
          onClose={closeInfoModal}
        >
        <DocumentsDetailedPage
              permit={documents.permitId}
              work={null}
              building={null}
              isAddDocument={false}
              setAddDocument={null}
              isDownloadAllDocs={false}
              setDocsAvailable={null}
              setDownloadAllDocs={null}
              setDocsWaitingOnClient={null}
              isSendDocs={false}
              setSendDocs={null}
              isInspector={true}
              initialValues={documents} 
              onSubmit={function (data: any): void {
                throw new Error('Function not implemented.');
              } }        />
        </ModalFullScreen>
      )}

      {showReviewCommentLtr && <ModalDefaultDraggable
          title=""
          onClose={() => setShowReviewCommentLtr(false)}
          showPrintButton
          disabledDraggable
          onClickAwayClose={false}
        ><ReviewCommentLetter reviews={reviews} /></ModalDefaultDraggable>}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={closeInfoModal}
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

const InfoText = styled.div`
  margin: 0 auto;
  font-size: 24px;
  color: var(--mainText);
  text-align: center
`;

interface Props {
  permit: number;
  activityType: number;
  activityCreated: boolean;
  setActivityCreated: (data: boolean) => void;
}

export default PermitActionsTable;
