import * as React from 'react';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { UserType } from '../api/users';
import { ContactTypesType } from '../api/contactType';
import { IconButton, Tooltip } from '../atoms';
import {
  InputBaseContainer,
  InputSelect,
} from '../molecules';

const ContactsTable = ({
  contacts,
  setContacts,
  contactTypes,
  error,
}: Props) => {
  const handleDeleteContact = id => {
    const filteredContacts = contacts.filter(
      contact => contact.id !== id,
    );
    setContacts(filteredContacts);
  };

  const handleContactType = (type, id) => {
    const currentContact = contacts.filter(
      contact => contact.id === id,
    );
    if (currentContact) {
      currentContact[0].contactType = type;
      const contactsAll = [...contacts, ...currentContact];
      const uniqueContacts = contactsAll.reduce(
        (x, y) =>
          x.findIndex(e => e.id === y.id) < 0
            ? [...x, y]
            : x,
        [],
      );
      setContacts(uniqueContacts);
    }
  };
  return (
    <Wrapper>
      <CustomTable>
        <CustomHead>
          <CustomRow error={error}>
            <CustomCell>Contact Name</CustomCell>
            <CustomCell>Contact Type</CustomCell>
            <CustomCell>Actions</CustomCell>
          </CustomRow>
        </CustomHead>

        <CustomBody>
          {contacts?.map(contact => {
            if (!contact.name) {
              return (
                <CustomRow
                  key={`${contact.id}${contact.firstName}`}
                >
                  <CustomCell>
                    {`${contact.firstName} ${
                      contact.lastName
                    }${
                      contact?.companyName ||
                      contact?.parentCompany
                        ? ` - ${
                            contact?.companyName ||
                            contact?.parentCompany
                          }`
                        : ''
                    }`}
                  </CustomCell>
                  <CustomCell>
                    <InputBaseContainer>
                      <InputSelect
                        placeholder=""
                        options={contactTypes}
                        onChange={selected =>
                          handleContactType(
                            selected.id,
                            contact.id,
                          )
                        }
                        getOptionValue={(item: any) =>
                          item.id
                        }
                        getOptionLabel={(item: any) =>
                          item.name
                        }
                      />
                    </InputBaseContainer>
                  </CustomCell>
                  <CustomActionCell>
                    <IconButton
                      onClick={() =>
                        handleDeleteContact(contact.id)
                      }
                    >
                      <Tooltip title="Delete">
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </CustomActionCell>
                </CustomRow>
              );
            }
            return null;
          })}
        </CustomBody>
      </CustomTable>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const CustomTable = styled.table`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-collapse: collapse;
  font-size: 14px;
`;

const CustomHead = styled.thead`
  color: #949494;
  font-weight: bold;
`;

const CustomBody = styled.tbody``;

const CustomRow = styled.tr<HeaderProps>`
  border: 1px solid
    ${({ error }) => (error ? 'red' : '#f0f0f0')};
`;

const CustomCell = styled.td`
  width: 50%;
  padding: 10px;
`;

const CustomActionCell = styled.td`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 23px 10px 10px 10px;
`;

interface Props {
  contacts: Array<UserType>;
  contactTypes: Array<ContactTypesType>;
  setContacts: (data: UserType[]) => void;
  error?: string;
}

interface HeaderProps {
  error?: string;
}

export default ContactsTable;
