import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DocumentFileItem,
  DocumentConditionItem,
  InspectionItemType,
  CommentItem,
  ConditionItemType,
} from '../../modules/clientModule/types';
import { InspectionStats } from '../../api/dashboard';

export interface DashboardState {
  showCompleteInspectionsPopup: boolean;
  barsData: InspectionStats[];
  inspections: InspectionItemType[];
  selectedMonthYear: string;
}

const initialState: DashboardState = {
  showCompleteInspectionsPopup: false,
  barsData: [],
  inspections: [],
  selectedMonthYear: '',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setShowCompleteInspectionsPopup: (state, action: PayloadAction<boolean>) => {
      state.showCompleteInspectionsPopup = action.payload;
    },
    setBarsData: (state, action: PayloadAction<InspectionStats[]>) => {
      state.barsData = action.payload;
    },
    setInspections: (state, action: PayloadAction<InspectionItemType[]>) => {
      state.inspections = action.payload;
    },
    setSelectedMonthYear: (state, action: PayloadAction<string>) => {
      state.selectedMonthYear = action.payload;
    },
    resetDashboard: state => {
      state.showCompleteInspectionsPopup = false;
      state.barsData = [];
      state.inspections = [];
      state.selectedMonthYear = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setShowCompleteInspectionsPopup,
  resetDashboard,
  setBarsData,
  setInspections,
  setSelectedMonthYear,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
