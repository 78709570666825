import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import ActionsPanel from '../../organisms/ActionsPanel';
import ModalActionDialog from '../../molecules/ModalActionDialog';
import ModalInfo from '../../molecules/ModalInfo';
import TableWithDynamicCell from '../../organisms/TableWithDynamicCell';
import { franchiseInfoSelector } from '../../redux/ducks/franchiseSettings';
import { capitalCase, camelCase } from 'change-case';

import {
  getFranchiseStripeList,
  updateFranchiseStripe,
  deleteFranchiseUsers,
} from '../../api/franchise';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import { convertTableFranchiseBoolean } from '../../utils/convertTableValues';
import useTableWithDynamicCell from '../../hooks/useTableWithDynamicCell';
import usePrevious from 'react-use-previous-hook';

const FranchiseConfigurationStripePage = () => {
  const {
    setSearch,
    handleAdd,
    isModalOpen,
    handleCloseModal,
    successRequest,
    handleErrorRequest,
    search,
    setTargetId,
    modalSuccess,
    handleListChange,
    handleCancelUpdate,
    updateDataRequest,
    modalError,
    values,
    handleCloseModalInfo,
    updateTable,
    modalErrorTitle,
  } = useTableWithDynamicCell<any>({
    deleteAllEndpoint: deleteFranchiseUsers,
    // updateEndpoint: updateFranchiseRelations,
    updateTarget: 'connectedUsers',
  });
  const { id, name } = useSelector(franchiseInfoSelector);
  const [rows, setRows] = React.useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(false);
  const [modifiedInput, setModifiedInput] = React.useState(
    [],
  );
  React.useEffect(()=>{
    if(modifiedInput.length){
      const v=[...rows]
      const i=v.findIndex(item=>item.name===modifiedInput[0].name)
          const temp = {name:modifiedInput[0].name,value:modifiedInput[0].value}
          const tempModified=structuredClone(modifiedInput)
          if(i==-1){
            v.push(temp)
          }else{
            v.splice(i,1,temp)
          }
      setRows(v)
    }

  },[modifiedInput])
  const [responseData, setResponseData] = React.useState(
    {},
  );
  const handleInputChange = (event,cellInfo,value,name) => {
    const i=modifiedInput.findIndex(item=>item.name===name)
    const temp = {name:name,value:value}
    const tempModified=structuredClone(modifiedInput)
    if(i>-1){
      tempModified.push(temp)
    }else{
      tempModified.splice(i,1,temp)
    }
    setModifiedInput(tempModified)
  };

  React.useEffect(() => {
    setTargetId(id);
  }, []);
  React.useEffect(() => {
    const i = rows.findIndex(item=>item.value==="")
    if(i>-1){
      setIsSaveDisabled(true)
    }else{
      setIsSaveDisabled(false)
    }
  }, [rows]);
  const updateStripe =()=>{
    const tempPayload=rows.reduce((acc,val)=>{
      acc[camelCase(val.name)]=val.value
      return acc
    },{})
    const payload={
      ...responseData,
      franchiseStripeSetting:{
        id,
        ...tempPayload
      }
    }
    Promise.all([updateFranchiseStripe(payload,id)])
    .then(([response]) => {
      successRequest()
    })
    .catch(err =>{
      handleErrorRequest(err)
    })
      .finally(() => {});
  }
  const columns = column => {
    if (['id'].includes(column)) {
      return null;
    }

    return tableColumnsFormatter(column);
  };

  const modifiedGetListFees = params =>
    Promise.all([getFranchiseStripeList(id)])
      .then(([response]) => {
        const stripeSetting = ["stripeSecretKey","stripeEndpointSecret","stripeFeeProductId","stripeMonthlyPaymentProductId","stripeProductId","stripeProductPriceId","defaultPayMethod"]
        const tableData=stripeSetting.reduce((acc,val)=>{
            acc.push({
              name: capitalCase(val),
              value:
                response.data?.franchiseStripeSetting?response.data?.franchiseStripeSetting[val]||"":"",
            });
            return acc
        },[])
        // const data = response.data.franchiseStripeSetting?Object.keys(
        //   response.data.franchiseStripeSetting,
        // ).reduce((acc, val) => {
        //   if(val!=="id"){
        //     acc.push({
        //       name: capitalCase(val),
        //       value:
        //         response.data.franchiseStripeSetting[val],
        //     });
        //   }
        //   return acc;
        // }, []):[]
        setRows(tableData);
        setResponseData(response.data);
        return {
          ...response,
          data: {
            ...response.data,
            results: stripeSetting.reduce((acc,val)=>{
            acc.push({
              name: capitalCase(val),
              value:
                response.data?.franchiseStripeSetting?response.data?.franchiseStripeSetting[val]||"":"",
            });
            return acc
        },[])
            // results: response.data.franchiseStripeSetting?Object.keys(
            //   response.data.franchiseStripeSetting,
            // ).reduce((acc, val) => {
            //   if(val!=="id"){
            //     acc.push({
            //       name: capitalCase(val),
            //       value:
            //         response.data.franchiseStripeSetting[val],
            //     });
            //   }

            //   return acc;
            // }, []):[]
          },
        };
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => {});
  const handleCloseErrorModal = () => {
    handleCloseModal();
    updateTable();
    handleCloseModalInfo();
  };
  
  return (
    <DashboardLayout>
      <ActionsPanel
        isSaveDisabled={isSaveDisabled}
        buttonTitle="Save"
        onAdd={handleAdd}
        onSearch={setSearch}
        isActionPanelMoreVisible={false}
      />
      <StyledTitle>
        {' '}
        Configure <span>{name}</span> Branch
      </StyledTitle>

      <Container>
        <TableWithDynamicCell
          customSearch
          searchQuery={search}
          handleInputChange={handleInputChange}
          rows={modifiedGetListFees}

          additionalRequestParams={{ franchiseId: id }}
          columns={columns}
          // manuallyUpdate={tableManuallyUpdate}
          handleListChanged={handleListChange}
          onSelectRowHeader="Change status"
          // endPositionOfSelectionColumn
          formatValue={convertTableFranchiseBoolean}
          disableSortBy
          // bindingCheckboxesTo={{
          //   field: 'status',
          //   value: 'link',
          // }}
          // initialSortBy={[{ id: 'status', desc: true }]}
        />
      </Container>

      {isModalOpen && (
        <ModalActionDialog
          title="Do you want to save changes?"
          onCancel={handleCloseModal}
          actionFirst={updateStripe}
          actionSecond={handleCancelUpdate}
        >
          {/* <p>
            If you don’t save the document, all changes will
            be lost!
          </p> */}
        </ModalActionDialog>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseErrorModal}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
  width: 50%;
`;

const StyledTitle = styled.p`
  margin-bottom: 0;

  span {
    font-weight: bold;
  }
`;

export default FranchiseConfigurationStripePage;
