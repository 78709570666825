const isRoutePathExact = (
  routePath: string,
  pathToFind: string,
  isRoot?: boolean,
) => {
  if (routePath && pathToFind) {
    const routeArr = routePath.split('/');
    const fullRoutePath = isRoot
      ? routeArr[routeArr.length - 1]
      : routePath;
    return (
      fullRoutePath
        .toLowerCase()
        .indexOf(pathToFind.toLowerCase()) !== -1
    );
  }

  return false;
};

export default isRoutePathExact;
