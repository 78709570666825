import * as React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon';

import UsersIcon from '../resources/icons/users_page.svg';
import SearchIcon from '../resources/icons/search_page.svg';
import UserPageIcon from '../resources/icons/user_page.svg';
import DashboardIcon from '../resources/icons/home_page.svg';
import ReportsIcon from '../resources/icons/report_page.svg';
import InvoiceIcon from '../resources/icons/invoices_page.svg';
import SettingsIcon from '../resources/icons/settings_page.svg';
import ProjectsIcon from '../resources/icons/project_page.svg';
import FranchiseIcon from '../resources/icons/franchise_page.svg';
import useUserRole from '../hooks/useUserRole';

import { Logo } from '../atoms';
import { SidebarMenuLink } from '../molecules';
import { ROUTES } from '../constants/routes';

const createIcon = (src: string) => (
  <IconWrapper>
    <Icon>
      <ReactSVG src={src} />
    </Icon>
  </IconWrapper>
);

const Sidebar = ({ logoClassName }: Props) => {
  const { isEmployee } = useUserRole();

  const MenuItems = {
    Dashboard: 'Dashboard',
    Account: 'Account',
    Projects: 'Projects',
    Settings: 'Settings',
    Franchise: 'Branch',
    Reports: 'Reports',
    Users: 'Users',
    Search: 'Search',
    Invoices: 'Invoices',
    Inspections: 'Inspections',
    Reviews: 'Reviews',
  };

  return (
    <Wrapper>
      <RootLogo className={logoClassName}>
        <Logo />
      </RootLogo>

      <MenuWrapper>
        <SidebarMenuLink
          icon={() => createIcon(DashboardIcon)}
          to={ROUTES.DASHBOARD}
          title={MenuItems.Dashboard}
        />
        <SidebarMenuLink
          icon={() => createIcon(SettingsIcon)}
          to={ROUTES.SETTINGS_JURISDICTION}
          title={MenuItems.Settings}
        />
        {isEmployee && (
          <SidebarMenuLink
            icon={() => createIcon(ProjectsIcon)}
            to={ROUTES.PROJECTS}
            title={MenuItems.Projects}
          />
        )}
        <SidebarMenuLink
          icon={() => createIcon(ReportsIcon)}
          to={ROUTES.REPORTS_SALES}
          title={MenuItems.Reports}
        />
        <SidebarMenuLink
          icon={() => createIcon(FranchiseIcon)}
          to={ROUTES.FRANCHISE}
          title={MenuItems.Franchise}
        />
        <SidebarMenuLink
          icon={() => createIcon(UsersIcon)}
          to={ROUTES.USERS}
          title={MenuItems.Users}
        />
        <SidebarMenuLink
          icon={() => createIcon(SearchIcon)}
          to={ROUTES.SEARCH}
          title={MenuItems.Search}
        />
        <SidebarMenuLink
          icon={() => createIcon(InvoiceIcon)}
          to={ROUTES.INVOICES}
          title={MenuItems.Invoices}
        />
        <SidebarMenuLink
          icon={() => createIcon(UserPageIcon)}
          to={ROUTES.ACCOUNT}
          title={MenuItems.Account}
        />
      </MenuWrapper>
    </Wrapper>
  );
};

const RootLogo = styled.div`
  padding: 20px 14px;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 80px;
  background: var(--primary);
  padding-top: 15px;
  transition: width 0.5s;
`;

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  min-height: 500px;
`;

const IconWrapper = styled.div`
  .MuiIcon-root {
    margin: 0;
    display: block;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

interface Props {
  logoClassName?: string;
}

export default Sidebar;
