import * as yup from 'yup';
import {
  emailRegex,
  phoneRegex,
  zipCode,
} from '../../regex';
import {
  CLIENT_TYPES_VALUES,
  USER_TYPES_VALUES,
} from '../../constants/forms';

export const labels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone',
  franchise: 'Branch',
  defaultFranchise: 'Default Branch',
  address: 'Address',
  street: 'Street',
  city: 'City',
  state: 'State',
  zipCode: 'Zip',
  userRole: 'Role',
  trade: 'Trades',
  prNumber: 'PR Number',
  clientType: 'Client Type',
  licenses: 'License(s)',
  parentCompany: 'Parent Company',
  mobilePhoneNumber: 'Cell Phone',
  isActive: 'Active',
  companyName: 'Company Name',
};

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(30)
    .required()
    .label(labels.firstName),
  lastName: yup
    .string()
    .max(30)
    .required()
    .label(labels.lastName),
  email: yup
    .string()
    .matches(emailRegex, 'Enter a valid email address')
    .required()
    .label(labels.email),
  phoneNumber: yup
    .string()
    .when('phoneNumber', (val, schema) => {
      if (val?.length > 0) {
        return yup.string().matches(phoneRegex,
          'Invalid phone number. The phone number must be in format 2XX-XXX-XXXX',)
      } else {
        return yup.string().notRequired();
      }
    })

    .label(labels.phoneNumber),
  franchise: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .nullable()
    .typeError('Select a Franchise from the list')
    .label(labels.franchise),
  address: yup.string().required().label(labels.address),
  street: yup.string().required().label(labels.street),
  state: yup.string().required().label(labels.state),
  city: yup.string().required().label(labels.city),
  zipCode: yup
    .string()
    .required()
    .matches(
      zipCode,
      'Invalid Code number. The code number must be in format 55555 or 55555-5555',
    )
    .label(labels.zipCode),
  userRole: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Role from the list')
    .label(labels.userRole),
  licenses: yup.string().label(labels.licenses),
  prNumber: yup.string().label(labels.prNumber),
  trade: yup
    .array()
    .when('userRole', {
      is: userRole =>
        userRole?.value ===
        String(USER_TYPES_VALUES.INSPECTOR),
      then: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            label: yup.string(),
          }),
        )
        .required(),
    })
    .nullable()
    .typeError('Select a Trades from the list')
    .label(labels.trade),
  clientType: yup
    .object()
    .when('userRole', {
      is: userRole =>
        userRole?.value ===
        String(USER_TYPES_VALUES.CLIENT),
      then: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(),
    })
    .nullable()
    .typeError('Select a Client Type from the list')
    .label(labels.clientType),
  parentCompany: yup
    .object()
    .when(['userRole', 'clientType'], {
      is: (userRole, clientType) =>
        userRole?.value ===
        String(USER_TYPES_VALUES.CLIENT) &&
        clientType?.value !==
        String(CLIENT_TYPES_VALUES.COMPANY),
      then: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(),
    })
    .nullable()
    .typeError('Select a Parent Company from the list')
    .label(labels.parentCompany),
  // EA-77
  // mritunjoy

  // mobile: yup.string().when(['userRole', 'clientType'], {
  //   is: (userRole, clientType) => {
  //     return userRole?.value === 'Client' && clientType?.value === 'Individual'
  //   },
  //   then: yup.string().required()
  // }) ,
  mobilePhoneNumber: yup
    .string()
    .when('mobilePhoneNumber', (val, schema) => {
      if (val?.length > 0) {
        return yup.string().matches(phoneRegex,
          'Invalid mobile phone number. The mobile phone number must be in format 2XX-XXX-XXXX',)
      } else {
        return yup.string().notRequired();
      }
    })
    .label(labels.mobilePhoneNumber),
  companyName: yup
    .string()
    .when('clientType', {
      is: clientType =>
        clientType?.value ===
        String(CLIENT_TYPES_VALUES.COMPANY),
      then: yup
        .string()
        .required()
        .label(labels.companyName),
    })
    .nullable()
    .label(labels.mobilePhoneNumber),
  isActive: yup.boolean().required().label(labels.isActive),
}, [
  ["phoneNumber", "phoneNumber"],
  ["mobilePhoneNumber", "mobilePhoneNumber"],
]);

export const SaFaSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(30)
    .required()
    .label(labels.firstName),
  lastName: yup
    .string()
    .max(30)
    .required()
    .label(labels.lastName),
  email: yup
    .string()
    .matches(emailRegex, 'Enter a valid email address')
    .required()
    .label(labels.email),
  phoneNumber: yup
    .string()
    .when('phoneNumber', (val, schema) => {
      if (val?.length > 0) {
        return yup.string().matches(phoneRegex,
          'Invalid phone number. The phone number must be in format 2XX-XXX-XXXX',)
      } else {
        return yup.string().notRequired();
      }
    })

    .label(labels.phoneNumber),
  franchise: yup
    .mixed()
    .notOneOf(
      ['', null, undefined, [], {}],
      'Franchise is a required field',
    )
    .required()
    // .when('userRole', {
    //   is: userRole => {
    //     return (
    //       Number(userRole?.value) !==
    //       USER_TYPES_VALUES.EMPLOYEE
    //     );
    //   },
    //   then: yup
    //     .array()
    //     .of(
    //       yup.object().shape({
    //         value: yup.string(),
    //         label: yup.string(),
    //       }),
    //     )
    //     .required()
    //     .nullable(),
    //   otherwise: yup
    //     .object()
    //     .shape({
    //       value: yup.string(),
    //       label: yup.string(),
    //     })
    //     .required(),
    // })
    .nullable(),
  // adding default franchise in schema -- EA-657 - mritunjoy
  defaultFranchise: yup
    .mixed()
    .notOneOf(
      ['', null, undefined, [], {}],
      'Default Franchise is a required field',
    )
    .required().nullable(),
  address: yup.string().required().label(labels.address),
  street: yup.string().required().label(labels.street),
  state: yup.string().required().label(labels.state),
  city: yup.string().required().label(labels.city),
  zipCode: yup
    .string()
    .required()
    .matches(
      zipCode,
      'Invalid Code number. The code number must be in format 55555 or 55555-5555',
    )
    .label(labels.zipCode),
  userRole: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .required()
    .typeError('Select a Role from the list')
    .label(labels.userRole),
  licenses: yup.string().label(labels.licenses),
  prNumber: yup.string().label(labels.prNumber),
  trade: yup
    .array()
    .when('userRole', {
      is: userRole =>
        userRole?.value ===
        String(USER_TYPES_VALUES.INSPECTOR),
      then: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            label: yup.string(),
          }),
        )
        .required(),
    })
    .nullable()
    .typeError('Select a Trades from the list')
    .label(labels.trade),
  clientType: yup
    .object()
    .when('userRole', {
      is: userRole =>
        userRole?.value ===
        String(USER_TYPES_VALUES.CLIENT),
      then: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(),
    })
    .nullable()
    .typeError('Select a Client Type from the list')
    .label(labels.clientType),
  parentCompany: yup
    .object()
    .when(['userRole', 'clientType'], {
      is: (userRole, clientType) =>
        userRole?.value ===
        String(USER_TYPES_VALUES.CLIENT) &&
        clientType?.value !==
        String(CLIENT_TYPES_VALUES.COMPANY),
      then: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(),
    })
    .nullable()
    .typeError('Select a Parent Company from the list')
    .label(labels.parentCompany),
  // EA-77
  // mritunjoy
  mobilePhoneNumber: yup
    .string()
    .when('mobilePhoneNumber', (val, schema) => {
      if (val?.length > 0) {
        return yup.string().matches(phoneRegex,
          'Invalid mobile phone number. The mobile phone number must be in format 2XX-XXX-XXXX',)
      } else {
        return yup.string().notRequired();
      }
    })
    .label(labels.mobilePhoneNumber),
  companyName: yup
    .string()
    .when('clientType', {
      is: clientType =>
        clientType?.value ===
        String(CLIENT_TYPES_VALUES.COMPANY),
      then: yup
        .string()
        .required()
        .label(labels.companyName),
    })
    .nullable()
    .label(labels.companyName),
  isActive: yup.boolean().required().label(labels.isActive),
}, [
  ["phoneNumber", "phoneNumber"],
  ["mobilePhoneNumber", "mobilePhoneNumber"],
]);
