import React, { useEffect } from 'react';
import { getFranchiseUsersList } from '../../../api/franchise';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { setFranchiseUsers } from '../../../redux/slices/projectSlice';
import { FranchiseUser, FranchiseUsersType } from '../types';

const useGetFranchiseUsers = () => {
  const dispatch = useDispatch();
  const franchiseUsers = useSelector(
    (state: RootState) =>
      state.projectsSlice.franchiseUsers,
  );
  const loadFranchiseUsers = async (id: string) => {
    if (franchiseUsers.find(i => i.id === id) ===  undefined) {
      const payload = await getFranchiseUsersList({
        franchise_id: id,
        user_role: 1,
        limit : 100000
      });
      const data = await payload;
     
      dispatch(
        setFranchiseUsers(
          {
            id,
            //@ts-ignore
            value: payload.data.results,
          }),
      );
    }

    //`/account/complex-views/users-by-franchise/`,
  };
  return { loadFranchiseUsers };
};

export default useGetFranchiseUsers;
