import React, { useEffect, useState } from 'react';
import PopupMessage from './PopupMessage';
import { Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import styled from 'styled-components';
import { setSelectedInspection, setShowPopup } from '../../../../../redux/slices/inspectionsSlice';

const ContactsPopup = () => {
  const { selectedInspection, permitUsers } = useSelector((s: RootState) => s.inspections);
  const [showContacts, setShowContacts] = useState(false);
  const dispatch = useDispatch();
  const selectUser = user => {
    if(!user.mobilePhoneNumber){
        return;
    }
    const _inspection = {
      ...selectedInspection,
      contactFirstName: user.firstName,
      contactLastName: user.lastName,
      contact: user.id,
      phoneNumber: user.phoneNumber,
      cellPhone: user.mobilePhoneNumber,
      contactId: user.id,
    };
    dispatch(setSelectedInspection(_inspection));
    dispatch(setShowPopup('none'));
  };

  return (
    <PopupMessage caption="Contacts">
      <TableWrapper>
        {permitUsers.map((user, index) => {
          return (
            <ContactRow onClick={e => selectUser(user)}>
              <Row1>
                <Name>{`${user.firstName} ${user.lastName}`}</Name>
                <Address>{user.address}</Address>
              </Row1>
              <Row2>
                <div>Phone Number: {user.phoneNumber}</div>
                <CellPhone cell={user.mobilePhoneNumber}> Cellphone: {user.mobilePhoneNumber}</CellPhone>
              </Row2>
            </ContactRow>
          );
        })}
      </TableWrapper>

      <Buttons>
        <Button variant="contained" color="primary" style={{ width: '200px' }} onClick={() => dispatch(setShowPopup('none'))}>
          Close
        </Button>
      </Buttons>
    </PopupMessage>
  );
};

export default ContactsPopup;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 130px;
  border: 3px solid grey;
  overflow-y: auto;
  gap: 10px;
`;

const Row1 = styled.div`
  display: flex;
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 8px;
`;

const ContactRow = styled.div`
  border: 1px solid grey;
  padding: 10px;
`;

const Name = styled.div`
  width: 200px;
`;

const Address = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const CellPhone = styled.div<{cell: string}>`
  color: ${({cell})=> cell ? 'inherit':'red'};
  font-weight: ${({cell})=> cell ? 'inherit':'bold'};
`;