/* eslint-disable react/no-array-index-key,no-console */
import * as React from 'react';
import styled from 'styled-components';

import DashboardLayout from '../layouts/DashboardLayout';

import IncomeBlock from '../organisms/Dashboard/IncomeBlock';
import FAProposalGraph from '../organisms/Dashboard/FAProposalGraph';
import FAFranchiseSalesGraph from '../organisms/Dashboard/FAFranchiseSalesGraph';
import FATodayTaskWidget from '../organisms/Dashboard/FATodayTaskWidget';
import FADailyMessage from '../organisms/Dashboard/FADailyMessage';

const DashboardPageFA = () => {
  return (
    <DashboardLayout>
      <Container>
        <Block>
          <IncomeBlock />
          <FATodayTaskWidget />
          <FADailyMessage />
        </Block>
        <Block>
          <FAFranchiseSalesGraph />
          <FAProposalGraph />
        </Block>
      </Container>
    </DashboardLayout>
  );
};

const Container = styled.div`
  display: flex;
  background: var(--defaultBackground);
  flex-direction: column;
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

export default DashboardPageFA;
