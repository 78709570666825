import React, { FC } from 'react';
import Sidebar from '../../organisms/Sidebar';
import EmployeeSidebar from '../../organisms/EmployeeSidebar';
import InspectorSidebar from '../../organisms/InspectorSidebar/InspectorSidebar';

type MyProps = {
  isEmployee: boolean;
  isInspector: boolean;
};
const ResponsiveSidebar: FC<MyProps> = ({
  isEmployee,
  isInspector,
}) => {
  return (
    <>
      {isEmployee && <EmployeeSidebar />}
      {!isInspector && !isEmployee && <Sidebar />}
      {isInspector && <InspectorSidebar />}
    </>
  );
};

export default ResponsiveSidebar;
