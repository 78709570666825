import styled from 'styled-components';
import WarningIcon from '@material-ui/icons/Warning';

const ErrorCheckmark = styled(WarningIcon)`
  color: var(--mainRequired);
  width: 128px;
  height: 128px;

  &.MuiSvgIcon-root {
    fill: var(--mainRequired);
  }
`;

export default ErrorCheckmark;
