import { snakeCase, camelCase } from 'change-case';
import * as R from 'ramda';

import mapKeys from './mapKeys';

const objectKeysTo = (
  transformStrategy: TransformStrategy,
) => {
  const converter: ObjectKeysConverter = R.ifElse(
    R.is(Array),
    R.map(value => converter(value)),
    R.when(
      R.is(Object),
      R.pipe(
        mapKeys(transformStrategy),
        R.map(value => converter(value)),
      ),
    ),
  );

  return converter;
};

type TransformStrategy = (s: string) => string;

type ObjectKeysConverter = (param: any) => any;

export const objectKeysToSnakeCase = objectKeysTo(
  snakeCase,
);
export const objectKeysToCamelCase = objectKeysTo(
  camelCase,
);
