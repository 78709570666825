import React, { useState } from 'react';
import styled from 'styled-components';
import { DocumentFileItem } from '../../../../types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AdjustIcon from '@material-ui/icons/Adjust';
import TripOriginIcon from '@material-ui/icons/RadioButtonUnchecked';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { setOpenedTabIndex, setSelectedDocument } from '../../../../../../redux/slices/documentsSlice';

const FilesSection = () => {
  const permitDocuments = useSelector((s: RootState) => s.documents.PermitDocuments);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const dispatch = useDispatch();
  const onClick = (e, item, index) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedIndex(index);
    dispatch(setSelectedDocument(item));
    dispatch(setOpenedTabIndex(2));    
  };
  return (
    <Files>
      {permitDocuments.map((item: DocumentFileItem, index: number) => {
        return (
          <FileItem key={index}>
            <IconWrapper status={item.status}>
              {item.status === 43 && <CheckCircleIcon />}
              {item.status === 47 && <AdjustIcon />}
              {item.status === 45 && <TripOriginIcon />}
              {item.status === 46 && <NotInterestedIcon />}
            </IconWrapper>
            <FileName index={index} status={item.status} selectedIndex={selectedIndex} onClick={e => onClick(e, item, index)}>
              {item.name}
            </FileName>
          </FileItem>
        );
      })}
    </Files>
  );
};

export default FilesSection;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 42vh;
  overflow: auto;
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div<{ status: number }>`
  color: ${({ status }) => {
    if (status === 43) return 'green';
    if (status === 47) return 'blue';
    if (status === 45) return 'grey';
    return 'red';
  }};
`;

const FileName = styled.div<{ index: number; status: number; selectedIndex: number }>`
  color: ${({ status }) => {
    if (status === 43) return 'green';
    if (status === 47) return 'blue';
    if (status === 45) return 'grey';
    return 'red';
  }};
  border: ${({ index, selectedIndex }) => {
    if (index === selectedIndex) {
      return '3px solid grey;';
    }
    return 'none';
  }};
  font-weight: ${({ index, selectedIndex }) => {
    if (index === selectedIndex) {
      return 'bold';
    }
    return 'inherit';
  }};
  border-left: none;
  border-right: none;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
`;
