import * as React from 'react';
import styled from 'styled-components';
import ArrowPointerBlock from '../../molecules/ArrowPointerBlock';

const PermitProcessBlock = ({
  processes,
  currentStatus,
}: Props) => {
  return (
    <>
      <ProcessWrapper>
        {processes.map((process, index) => (
          <ArrowPointerBlock
            process={process}
            currentStatus={currentStatus}
            key={process.title}
            index={index}
          />
        ))}
      </ProcessWrapper>
    </>
  );
};

const ProcessWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 540px;
  margin-top: 6px;
`;

interface Props {
  processes: Process[];
  currentStatus: number;
}

interface Process {
  title: string;
  subProcesses: SubProcess[];
  statuses: number[];
}

interface SubProcess {
  title: string;
  id: number;
}

export default PermitProcessBlock;
