import * as React from 'react';
import styled from 'styled-components';

import DailyMessage from '../organisms/Dashboard/DailyMessage';
import TaskSheetBlock from '../organisms/Dashboard/TaskSheetBlock';
import MonthlySales from '../organisms/Dashboard/MonthlySales';
import ActivePermitsBlock from '../molecules/ActivePermitsBlock';
import MonthlyIncomeChart from '../organisms/Dashboard/MonthlyIncomeChart';

const DashboardBlocks = () => {
  return (
    <Wrapper>
      <ActivePermitsBlock />
      <TaskSheetBlock />
      <DailyMessage />
      <StatsBlock>
        <MonthlySales />
        <MonthlyIncomeChart />
      </StatsBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const StatsBlock = styled.div`
  width: 100%;
  display: flex;
`;

export default DashboardBlocks;
