import {
  createMuiTheme,
  ThemeOptions,
} from '@material-ui/core';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  linkText: '#111',
  mainText: '#414141',
  secondaryText: '#888',
  primary: '#0b6b9b',
  secondary: '#f5f5f5',
  border: '#f0f0f0',
  defaultBackground: '#ffffff',
  defaultTableCell: '#fcf9fc',
  defaultShadow: '#000000',
  disabledButton: '#ddd',
  defaultTableCellTitle: '#949494',
  mainRequired: '#FF3D3D',

  marker1: '#a7e0f4',
  marker2: '#dfb9c9',
  marker3: '#d73965',
  marker4: '#48a9ee',
  marker5: '#e25241',
  marker6: '#6b77c4',
};

export const fontFamily = [
  '"Segoe UI"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Helvetica Neue"',
  'Arial',
  'Roboto',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.primary,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shadows: new Array(25).fill(
    'none',
  ) as ThemeOptions['shadows'],
  typography: {
    fontFamily,
  },
});

export default theme;
