import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch as MaterialSwitch } from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch';
import theme from '../theme';

const { disabledButton, primary } = theme;

const Switch = withStyles({
  root: {
    width: 56,
    paddingTop: 14,
    paddingBottom: 14,
  },
  switchBase: {
    color: disabledButton,
    '& + $track': {
      backgroundColor: disabledButton,
      opacity: 0.4,
    },
    '&$checked': {
      color: primary,
    },
    '&$checked + $track': {
      backgroundColor: primary,
      opacity: 0.1,
    },
  },
  thumb: {
    width: 16,
    height: 16,
    marginTop: 1,
  },
  checked: {},
  track: {},
})(MaterialSwitch);

export default Switch as React.ComponentType<SwitchProps>;
