import * as React from 'react';
import { CheckboxProps } from '@material-ui/core/Checkbox';

import CircleChecked from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleIndeterminate from '@material-ui/icons/RemoveCircle';

import Checkbox from './Checkbox';

const CheckboxRounded = (props: CheckboxProps) => (
  <Checkbox
    icon={<CircleUnchecked />}
    checkedIcon={<CircleChecked />}
    indeterminateIcon={<CircleIndeterminate />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default CheckboxRounded;
