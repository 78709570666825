export const updatePMResults = (results: any, params: any) => {
  if (!results) {
    return [];
  }
  const token = params.search;
  const _results = results.reduce((arr, item) => {
    let inspectionCount = 0;
    const inspArr = item.inspections.split(',');
    if (inspArr.length === 1) {
      if (item.inspections !== '') {
        inspectionCount = inspArr.length;
      }
    } else {
      inspectionCount = inspArr.length;
    }
    if (item.managerName?.toLowerCase().indexOf(token.toLowerCase()) > -1) {
      arr.push({ ...item, inspections: `${inspectionCount}` });
    }
    return arr;
  }, []);
  return _results.sort((a, b) => {
    if (a?.managerEmail > b?.managerEmail) {
      return 1;
    }
    if (a?.managerEmail < b?.managerEmail) {
      return -1;
    }
    return 0;
  });
};

export const getAccountingInfo = (contacts, permitContactsRedux) => {
  return contacts.map(c => {    
    const contact = permitContactsRedux.find(contact => {
      const id = contact.user ? contact.user : contact.id;
      return id === c.id;
    });
    return {
      ...c,
      isAccounting: contact ? contact.isAccounting : c.isAccounting,
    };
  });
};
