import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { AgReportBucket } from './types';
import { currencyFormatCents } from '../DailyInspectionReport/Utils';
import AgingReportTableHeader from './AgingReportTableHeader';

type Props = {
  bucket: AgReportBucket;
  lineCounter: any;
  linesPerPage: number;
};
const BucketRecord: FC<Props> = ({ bucket, lineCounter, linesPerPage }) => {
  lineCounter.current = lineCounter.current + 3;
  return (
    <>
      <TableRecords>
        <BucketLabel>{bucket.label}</BucketLabel>
        {bucket.records.map((rec, index) => {
          lineCounter.current = lineCounter.current + 1;
          return (
            <Fragment key={index}>
              {/* {lineCounter % linesPerPage} */}
              {lineCounter.current % linesPerPage === 0 && (
                <HeaderWrapper>
                  <AgingReportTableHeader lineCounter={lineCounter} />
                </HeaderWrapper>
              )}
              <DateField>{rec.date}</DateField>
              <div className="">{rec.invoiceNumber}</div>
              <div className="">{rec.contactName}</div>
              <div className="">{`${rec.projectName}-${rec.permitName}`}</div>
              <CurrencyWrapper>
                <Currency>{currencyFormatCents(rec.amount)}</Currency>
              </CurrencyWrapper>
            </Fragment>
          );
        })}
      </TableRecords>
      <TotalBucket>
        <TotalBucketSubject>Total for {bucket.label}</TotalBucketSubject>
        <ButcketTotal>{currencyFormatCents(bucket.totals)}</ButcketTotal>
      </TotalBucket>
      <BottomSpacer></BottomSpacer>
    </>
  );
};

export default BucketRecord;

const TableRecords = styled.div`
  display: grid;
  grid-template-columns: 10% 15% 20% 1fr 15%;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  grid-gap: 5px 0;
`;

const BucketLabel = styled.div`
  grid-column: 1/6;
  font-size: 20px;
`;

const DateField = styled.div`
  padding-left: 10px;
`;

const HeaderWrapper = styled.div`
  grid-column: 1/6;
  display: none;
  @media print {
    display: inherit;
    break-before: page;
  }
`;

const TotalBucket = styled.div`
  display: grid;
  grid-template-columns: 10% 15% 20% 1fr 15%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  font-weight: bold;
  font-size: 18px;
  padding-right: 10px;
`;

const TotalBucketSubject = styled.div`
  grid-column: 1/5;
`;

const ButcketTotal = styled.div`
  padding-right: 5px;
`;

const BottomSpacer = styled.div`
  height: 20px;
`;

const CurrencyWrapper = styled.div`
  width: fit-content;
`;
const Currency = styled.div`
  text-align: right;
`;
