/* eslint-disable function-paren-newline */
import * as React from 'react';
import styled, { css } from 'styled-components';
import useTablePage from '../../hooks/useTablePage';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { ModalDefaultDraggable, InputDatepickerRange, InputBaseContainer, ButtonWithLoader, SearchBar } from '../../molecules';
import { DEFAULT_DATE_FORMAT, GET_ALL_QUERY_PARAMS } from '../../constants';
import InspectorDashboardBlocks from '../InspectorDashboardBlocks';
import { Controller, useForm } from 'react-hook-form';
import { FormsReportType, ReportTableType } from '../../api/reports';
import { ButtonMain, Loader } from '../../atoms';
import { getActivitiesList, getActivitiesStatusList } from '../../api/activities';
import { getCurrentUser } from '../../api/userSettings';
import formatDate, { formatDateTime } from '../../utils/formatDate';
import useManuallyUpdate from '../../hooks/useManuallyUpdate';
import DashboardLayout from '../../layouts/DashboardLayout';
import Table from '../../organisms/Table';
import { updatePermitInspection } from '../../api/inspections';
import PermitInspectionsForm from '../../forms/permitInspectionForm/PermitInspectionsForm';
import ModalInfo from '../../molecules/ModalInfo';
import InspectionsPage from '../Permits/InspectionsPage';
import useDevice from '../../hooks/useDevice';
import useUserRole from '../../hooks/useUserRole';
import PendingInspectionMobile from '../Responsive/pendingInspectionMobile';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setSelectedInspection } from '../../redux/slices/inspectionsSlice';
import { InspectionItemType } from '../../modules/clientModule/types';
import { RootState } from '../../redux/root';
import InspectionEditor from '../Reports/DailyInspectionReport/InspectionEditor/InspectionEditor';
import ShowAllInspectionSwitch from '../Reports/DailyInspectionReport/InspectionEditor/Controls/ShowAllInspectionSwitch';
import { useState } from 'react';
import InspectionsTable from './InspectionsTable';
import useInspectionFilters from './useInspectionFilters';
import { useGetAllInspectorsQuery } from '../../redux/rtkQuery/apiSlice';

const InspectorInspectionsPage = () => {
  const [dates, setDates] = React.useState({} as { fromDate: Date; toDate: Date });
  const [toggleControl, setToggleControl] = useState(false);
  const { selectedInspection, showAllPendingInspections } = useSelector((s: RootState) => s.inspections);
  const dispatch = useDispatch();
  const { setParamsValue,rows, isFetching } = useInspectionFilters(dates,toggleControl);
  const { data = [] } = useGetAllInspectorsQuery(null);
  const { errors, control, handleSubmit } = useForm<ReportTableType>({
    mode: 'onChange',
  });
  const [tableManuallyUpdate, updateTable] = useManuallyUpdate();
  const [isValidForExport, setIsValidForExport] = React.useState(false);
  const currentUser = useSelector((s: RootState) => s.userAuth.info.id);

  React.useEffect(() => {
    setToggleControl(!toggleControl);
  }, [showAllPendingInspections]);


  var inspectionActivityID = [];
  const activityIds = data => {
    inspectionActivityID.push(data);
  };

  var activityStatus = [];
  const [key, setKey] = React.useState(1);
  const onChangeKey = () => {
    setKey(key + 1);
  };

  const [isPermitModalOpen, setPermitModal] = React.useState(false);

  const [permit, setPermit] = React.useState(null);

  const {
    isModalOpen,
    modalError,
    modalSuccess,
    modalTitle,
    modalErrorTitle,
    values,
    createOrUpdate,
    handleEdit,
    handleCloseModalInfo,
    handleCloseModal,
    setValues,
  } = useTablePage({
    updateEndpoint: updatePermitInspection,
    modalUpdateTitle: 'Update Inspection',
  });
  const openEditor = (inspection: InspectionItemType) => {
    dispatch(setSelectedInspection(inspection));
    dispatch(setModalOpen(true));
  };
  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      setPermitModal(false);
      handleCloseModal();
    }
  };
  const [isIpad] = useDevice();
  const { isSuperAdmin, isFranchiseAdmin, isEmployee, isInspector } = useUserRole();
  const handleCellClick = rowValue => {
    if ((!isIpad && !isInspector) || (!isIpad && isInspector)) {
      setPermit(rowValue.permit);
      setPermitModal(true);
    }
  };

  const [addInspection, setAddInspection] = React.useState(null);
  const [projectId, setProjectId] = React.useState('');
  const closeInfoModal = () => {
    setPermitModal(false);
    updateTable();
    handleCloseModalInfo();
  };

  React.useEffect(() => {
    Promise.all([
      getActivitiesList({
        ...GET_ALL_QUERY_PARAMS,
        activityType: 8,
      }),
      getActivitiesStatusList({
        ...GET_ALL_QUERY_PARAMS,
      }),
    ]).then(([activitiesList, activitiesStatusList]) => {
      const activitiesTitles = activitiesList.data.results?.filter(el => el?.isActiveStatus === 'True');

      activitiesTitles.map(item => {
        for (let key in item) {
          if (`${key}` === 'id') {
            activityIds(`${item[key]}`);
          }
        }
      });

      const activitiesStatusLists = activitiesStatusList.data.results?.filter(
        el => el?.isActiveStatus === 'True' && el?.name === 'Pending',
      );

      //updating params to trigger RTKQLuery call
      setParamsValue(currentUser);
    });
  }, []);

  return (
    <DashboardLayout>
      <Container>
        <InnerContainer>
          {selectedInspection.id && <InspectionEditor />}
          <FormContainer autoComplete="off" aria-autocomplete="none">
            <InputBaseContainer>
              <Controller
                name="range"
                defaultValue=""
                control={control}
                render={({ value, name, onChange, onBlur }) => (
                  <InputDatepickerRange
                    name={name}
                    placeholderText="Select Date"
                    error={errors.range || errors.fromDate || errors.toDate}
                    dateFormat={DEFAULT_DATE_FORMAT}
                    onRangeChange={(start, end) => {
                      if (start && end) {
                        onChange([start, end]);
                        setDates({ fromDate: start, toDate: end });

                        updateTable();
                      } else {
                        setDates({} as { fromDate: Date; toDate: Date });
                      }
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </InputBaseContainer>
          </FormContainer>
          <SwitchWrapper>
            <LoaderWrapper>{isFetching && <Loader />}</LoaderWrapper>

            <ShowAllInspectionSwitch disabled={false} />
          </SwitchWrapper>
        </InnerContainer>
        {currentUser && <PendingInspectionMobile openEditor={openEditor} />}
        {currentUser && (
          <InspectorTableWrapper>
            {<InspectionsTable rows={rows} isFetching={isFetching} onCellClick={handleCellClick} openEditor={openEditor}/>}
          </InspectorTableWrapper>
        )}
        {isModalOpen && !isPermitModalOpen && (
          <ModalDefaultDraggable title={modalTitle} onClose={handleCloseForm}>
            <PermitInspectionsForm
              // <InspectorPermitInspectionsForm
              onSubmit={createOrUpdate}
              initialValues={values}
              modalError={modalError}
              addButtonText="Create"
              updateButtonText="Update"
              initialErrors={errors}
              permit={permit}
              // isReadOnly={true}
              setInitialValues={setValues}
              updateTable={updateTable}
            />
          </ModalDefaultDraggable>
        )}
        {isPermitModalOpen && (
          <ModalDefaultDraggable title={'Permit Inspections'} onClose={handleCloseForm} extraWide>
            <InspectionsPage
              projectId={projectId}
              permit={permit}
              isAddInspection={false}
              setAddInspection={setAddInspection}
              isReadOnly={true}
            />
          </ModalDefaultDraggable>
        )}
        {(modalSuccess || modalError) && (
          <ModalInfo timeToClose={5000} isError={modalError} onClose={closeInfoModal} errorMsg={modalErrorTitle} />
        )}
      </Container>
    </DashboardLayout>
  );
};

const InspectorTableWrapper = styled.div`
  @media only screen and (max-width: 840px) {
    display: none;
  }
  @media only screen and (max-width: 390px) {
    font-size: 14px;
  }
`;

const Container = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoaderWrapper = styled.div`
  zoom: 0.6;
  position: relative;
  top: 21px;
`;

const InnerContainer = styled.div`
  width: calc(100% + 4px);
  background: var(--secondary);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -2px;
  padding: 0 24px;
`;

const FormContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  > div {
    width: auto;
    padding: 0;
    margin: 0 24px 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

export default InspectorInspectionsPage;
