import * as React from 'react';
import styled from 'styled-components';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getReportsTypes,
  createReportsType,
  updateReportsType,
  deleteReportsType,
  ReportType,
} from '../../api/reportSetup';
import Table from '../../organisms/Table';
import ActionsPanel from '../../organisms/ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import { convertTableBoolean } from '../../utils/convertTableValues';
import ReportSetupForm from '../../forms/ReportSetupForm';

const modifiedGetReportsRequest = params =>
  getReportsTypes(params).then(response => ({
    ...response,
    data: {
      ...response.data,
      results: response.data.results.map(item => {
        if (item.isFranchiseAll) {
          return {
            ...item,
            franchiseNames: 'All',
          };
        }
        if( item.userModuleReadable === 'Franchise admin'){
          item.userModuleReadable = 'Branch admin';
        }
        return item;
      }),
    },
  }));

const ReportSetupPage = () => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    modalErrorTitle,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<ReportType>({
    createEndpoint: createReportsType,
    updateEndpoint: updateReportsType,
    deleteEndpoint: deleteReportsType,
    modalCreateTitle: 'Add Report setup',
    modalUpdateTitle: 'Update Report setup',
  });

  const columns = column => {
    if (
      [
        'userModule',
        'franchise',
        'isDeleted',
        'isFranchiseAll',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'franchiseNames':
        return 'Branches';
      case 'userModuleReadable':
        return 'User module';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={handleAdd}
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          rows={modifiedGetReportsRequest}
          onEdit={handleEdit}
          onDelete={handleDelete}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <ReportSetupForm
            initialValues={values}
            initialErrors={errors}
            modalError={modalErrorTitle.length > 0}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

export default ReportSetupPage;
