import styled from 'styled-components';

const SocialLink = styled.a`
  text-decoration: none;
  font-weight: bold;
  padding: 0 8px;
  color: var(--mainText);
  letter-spacing: 0.021px;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }
`;

export default SocialLink;
