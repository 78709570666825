import * as React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';

import hex2rgba from '../utils/hex2rgba';
import theme from '../theme';

const ButtonMain = ({
  variant = 'contained',
  color = 'primary',
  ...props
}: ButtonProps) => (
  <StyledButton
    variant={variant}
    color={color}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

const StyledButton = styled(Button)`
  min-width: 112px;
  min-height: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
  color: var(--mainText);

  &:hover {
    color: var(--mainText);
    background-color: ${hex2rgba(
      theme.disabledButton,
      0.8,
    )};
  }

  &.MuiButton-containedPrimary {
    color: var(--defaultBackground);
    background-color: var(--primary);

    &:hover {
      color: var(--defaultBackground);
      background-color: ${hex2rgba(theme.primary, 0.8)};
    }
  }

  &.MuiButton-containedSecondary {
    color: var(--defaultBackground);
    background-color: var(--mainRequired);

    &:hover {
      color: var(--defaultBackground);
      background-color: ${hex2rgba(
        theme.mainRequired,
        0.8,
      )};
    }
  }

  &.MuiButton-root {
    line-height: 0.75;
  }

  &.MuiButton-root.Mui-disabled {
    background-color: var(--disabledButton);
    color: var(--defaultBackground);
  }
`;

export default ButtonMain;
