import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

import {
  TextInput,
  ButtonMain,
  UnderlinedLink,
} from '../atoms';
import PasswordInput from '../molecules/PasswordInput';
import { userErrorsSelector } from '../redux/ducks/userAuth';
import {
  addErrorsForEmptyFields,
  isValidationSucceed,
} from '../utils/validation';
import { APIFormErrors } from '../types';
import { LogInDataType } from '../api/userAuth';

const initialErrors: Errors = {};

const LogInForm = ({
  fields,
  setFields,
  onLogIn,
}: Props) => {
  const apiErrors: APIFormErrors = useSelector(
    userErrorsSelector,
  );

  const history = useHistory();

  const [errors, setErrors] = React.useState(initialErrors);

  const handleInputChange = (name: string) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFields(R.assoc(name, e.target.value, fields));
    setErrors(R.dissoc(name));
  };

  const validateForm = () => {
    const newErrors = addErrorsForEmptyFields(
      fields,
      initialErrors,
      'This field can not be empty',
    );

    setErrors(newErrors);

    return isValidationSucceed(newErrors);
  };

  const handleChangePassword = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      onLogIn();
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <InputBox>
          <TextInputStyled
            label="Email"
            value={fields.email}
            onChange={handleInputChange('email')}
            error={!!(apiErrors.email || errors.email)}
            helperText={
              (apiErrors.email && apiErrors.email[0]) ||
              errors.email
            }
          />
          <PasswordInput
            id="password_test"
            label="Password"
            value={fields.password}
            onChange={handleInputChange('password')}
            isValid={
              !errors.password && !apiErrors.password
            }
            helperText={errors.password}
          />
        </InputBox>
        <ActionLinkContainer>
          <ActionLink onClick={handleChangePassword}>
            Forgot Password?
          </ActionLink>
        </ActionLinkContainer>
        <Buttons>
          <ButtonMain type="submit">Login</ButtonMain>
        </Buttons>
      </Form>

      {apiErrors.nonFieldErrors && (
        <ErrorsBlock>
          {apiErrors.nonFieldErrors}
        </ErrorsBlock>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
`;

const ErrorsBlock = styled.p`
  width: 100%;
  color: red;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

const TextInputStyled = styled(TextInput)`
  margin-bottom: 25px;
`;

const ActionLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ActionLink = styled(UnderlinedLink).attrs({
  as: 'span',
})`
  align-self: flex-start;
`;

const InputBox = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  fields: LogInDataType;
  setFields: (arg: LogInDataType) => void;
  onLogIn: () => void;
}

interface Errors {
  [s: string]: string;
  email?: string;
  password?: string;
}

export default LogInForm;
