import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TopHeader from './TopHeader';
import BucketRecord from './BucketRecord';
import { AgReportBucket } from './types';
import { convertAgingDetailsData, currencyFormatCents } from '../DailyInspectionReport/Utils';
import { useGetAgingDetailsReportQuery } from '../../../redux/rtkQuery/apiSlice';
import AgingReportTableHeader from './AgingReportTableHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { Loader } from '../../../atoms';

const AgingReportDetails = () => {
  const [report, setReport] = useState<AgReportBucket[]>([]);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [linesPerPage] = useState<number>(40);
  const lineCounter = useRef<number>(0);
  const franchiseIds = useSelector((s: RootState) => s.inspectionReports.franchiseIds);
  const { data, isFetching } = useGetAgingDetailsReportQuery(franchiseIds, { skip: franchiseIds === '' });

  useEffect(() => {
    if (!isFetching) {
      const result = convertAgingDetailsData(data);
      const _gt = data[1].grandTotal.grandTotal;
      setReport(result);
      setGrandTotal(_gt);
    }
  }, [isFetching]);
  return (
    <ReportWrapper>
      {isFetching ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <TopHeader />
          <AgingReportTableHeader lineCounter={lineCounter} />
          {report.map((r, index) => (
            <BucketRecord key={index} bucket={r} lineCounter={lineCounter} linesPerPage={linesPerPage} />
          ))}
          <TableFooter>
            <div>TOTAL</div>
            <TotalFigure>{currencyFormatCents(grandTotal)}</TotalFigure>
          </TableFooter>
        </>
      )}
    </ReportWrapper>
  );
};

export default AgingReportDetails;

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

const TableFooter = styled.div`
  display: grid;
  grid-template-columns: 10% 15% 20% 1fr 15%;
  border-top: 3px solid grey;
  border-bottom: 6px double grey;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
`;

const TotalFigure = styled.div`
  grid-column: 5 / 6;
  font-size: 20px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
