import { api } from './api';

const paramsSerializer = (p: object): string => {
  const params = new URLSearchParams();

  // @ts-ignore
  Object.entries(p).forEach(
    ([key, values]) =>
      values &&
      values.forEach(value => {
        params.append(key, value);
      }),
  );

  return params.toString();
};

export const getMonthlySalesReport = () =>
  api.request<FranchiseSales>({
    method: 'GET',
    url: '/reports/monthly-sales-report',
  });

export const getEpicVersion = () =>
  api.request<FranchiseSales>({
    method: 'GET',
    url: '/dashboards/epic-version/',
  });

export const getEmployeeSales = () =>
  api.request<FranchiseSales[]>({
    method: 'GET',
    url: '/reports/income-report',
  });

export const getFranchiseSales = (franchise?: number[]) =>
  api.request<FranchiseSales[]>({
    method: 'GET',
    url: '/dashboards/franchise-sales/',
    params: { franchise },
    paramsSerializer,
  });

export const getFranchiseProposals = () =>
  api.request<FranchiseSales[]>({
    method: 'GET',
    url: '/dashboards/franchise-proposals/',
    paramsSerializer,
  });

export const getFranchiseRevenue = () =>
  api.request({
    method: 'GET',
    url: '/dashboards/fa-revenue/',
  });

export const getFranchiseIncome = (franchise?: number[]) =>
  api.request<FranchiseIncome>({
    method: 'GET',
    url: '/dashboards/franchise-income/',
    params: { franchise },
    paramsSerializer,
  });

export const getFranchiseActivities = (
  employees: number[] | [],
) =>
  api.request<FranchiseIncome>({
    method: 'GET',
    url: '/dashboards/fa-stats/',
    params: { employees },
    paramsSerializer,
  });

export const getFranchiseMonthRevenue = () =>
  api.request<FranchiseRevenue>({
    method: 'GET',
    url: '/dashboards/franchise-revenue/',
  });

export const getInspectionStats = (inspector: number) =>
  api.request<InspectionStats[]>({
    method: 'GET',
    url: '/dashboards/inspection-stats/',
    params: { inspector },
  });

export interface FranchiseSales {
  month: number;
  year: number;
  sales: string;
  projectedSales: string;
}

export interface FranchiseIncome {
  projectedSales: number;
  sales: number;
  total: number;
}
export interface FranchiseRevenue {
  monthly: number;
  yearly: number;
}
export interface InspectionStats {
  month: number;
  year: number;
  inspectionCount: number;
}
